import React from "react";
import FNF from "../../../../../assets/images/no_result_icon_flight.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

const FlightNotFound = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 8px",
      }}
    >
      <ReactSVG src={FNF} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#000",
            fontFamily: "Lato, sans-serif",
            fontSize: "18px",
            fontWeight: "600",
            textTransform: "capitalize",
            letterSpacing: "1px",
          }}
        >
          {t("flightSearch.noMatchFound")}
        </p>
        <p
          style={{
            color: "#667085",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          {t("flightSearch.noMatchingFlights")}
        </p>
      </div>
    </div>
  );
};

export default FlightNotFound;
