import React, { useState } from "react";
import useStyles from "./styles";
import { forEach, get, map, split } from "lodash";
import eventBus from "../../../core/eventBus";
import { Close } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const PropertyRulesDialogView = (props) => {
  const rules = get(props, "rules", []);
  const setOpenAllRulesModal = get(
    props,
    "setOpenAllRulesModal",
    function () {}
  );
  const styles = useStyles();
  return (
    <div
      className={styles.hotelDetailsPropertyRulesDialog}
      style={{ zIndex: "11000", width: "576px !important" }}
    >
      <div className={styles.hotelDetailsPropertyRulesDialogHeader}>
        <h2 className={styles.hotelDetailsPropertyRulesDialogTitle}>
          Property Rules
        </h2>
        <div
          className={styles.hotelDetailsPropertyRulesDialogCloseIconWrapper}
          // onClick={() => eventBus.emit("close-dialog")}
          onClick={() => setOpenAllRulesModal(false)}
        >
          <Close style={{ fontSize: "20px" }} />
        </div>
      </div>
      {/* {map(rules, (rule) => {
        return /<ul>|<li>/i.test(rule) ? (
          <span dangerouslySetInnerHTML={{ __html: rule }} />
        ) : (
          <p>{rule}</p>
        );
      })} */}
      <div style={{ overflowY: "scroll" }}>
        <div dangerouslySetInnerHTML={{ __html: rules }}></div>
      </div>
    </div>
  );
};

export const HotelDetailsPropertyRulesView = (props) => {
  const styles = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [openAllRulesModal, setOpenAllRulesModal] = useState(false);

  const separateRulesFromHTMLMarkup = (rules) => {
    const rulesWithoutHTML = [];
    const rulesWithHTML = [];
    forEach(rules, (rule) => {
      if (rule) {
        if (/<ul>|<li>/i.test(rule)) {
          rulesWithHTML.push(rule);
        } else {
          rulesWithoutHTML.push(rule);
        }
      }
    });

    return [rulesWithoutHTML, rulesWithHTML];
  };

  const rulesString = get(props, "rules", "");
  const rules = split(rulesString, "|");

  let separatedRules = [];
  if (rulesString) {
    const rules = split(rulesString, "|");
    separatedRules = [...separateRulesFromHTMLMarkup(rules)];
  }

  const handleAllRulesView = () => {
    // eventBus.emit(
    //   "open-dialog",
    //   <div style={{ maxHeight: "80vh", borderRadius: "5px", maxWidth: "90vw" }}>
    //     <PropertyRulesDialogView rules={split(rulesString, "|")} />
    //   </div>
    // );
    setOpenAllRulesModal(true);
  };

  return (
    <div className={styles.hotelDetailsPropertyRules} style={{ zIndex: "-10" }}>
      <p className={styles.hotelDetailsPropertyRulesTitle}>
        {t("hotelDetails.propertyRules")}
      </p>
      {map(separatedRules[0], (rule) => (
        <p className={styles.hotelDetailsPropertyRulesRule} key={rule}>
          {rule}
        </p>
      ))}
      <div className={styles.hotelDetailsPropertyRulesSeeAllWrapper}>
        <button
          className={styles.hotelDetailsPropertyRulesSeeAll}
          style={{ zIndex: "5" }}
          onClick={handleAllRulesView}
        >
          {t("hotelDetails.seeAllRules")}
        </button>
      </div>
      <Modal
        open={openAllRulesModal}
        style={{
          maxWidth: "content-width",
          verticalAlign: "center",
          display: "flex",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            // maxWidth: "max-content",
            background: "white",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            margin: "0px auto",
            maxHeight: "90vh",
            top: "5vh",
            bottom: "5vh",
            width: isMobile ? "300px" : "500px",
            paddingBottom: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              height: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <div style={{ fontWeight: "bolder", paddingLeft: "12px" }}>
              {" "}
              {t("hotelDetails.propertyRules")}
            </div>{" "}
            <CloseIcon
              fontSize="medium"
              onClick={() => {
                setOpenAllRulesModal(false);
              }}
            />
          </div>
          <div style={{ overflowY: "auto", paddingLeft: "25px" }}>
            {map(rules, (rule) => {
              return /<ul>|<li>/i.test(rule) ? (
                <span dangerouslySetInnerHTML={{ __html: rule }} />
              ) : (
                <p>{rule}</p>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
