import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  addContactDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px",
  },

  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },

  contactsDetailsCard: {
    display: "inline-flex",
    gap: "16px",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  },
  fieldContainer: {
    width: "50% !important",
    display: "flex",
    flexDirection: "column",
    gap: "6px",

    [theme.breakpoints.down("lg")]: {
      width: "100% !important",
    },
  },
  fieldContainer2: {
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  labelStyle: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },

  countryCode: {
    borderRadius: "10px",
    padding: "10px 4px",
    width: "inherit",
    borderColor: "red",
  },
}));
export default useStyles;
