import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FFF",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  heading: {
    background: "#F2F7FB",
    padding: "16px",
    borderRadius: "10px 10px 0px 0px",
    color: "#344054",
    fontSize: "18px",
    fontWeight: 700,
  },
  feeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px",
  },
  feeContainerTitleMain: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "700",
    textTransform: "uppercase"
  },
  feeContainerPriceMain: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontWeight: "700",
  },
}));

export default useStyles;
