import * as yup from "yup";

export const signupIn = (t) =>
  yup.object().shape({
    number: yup
      .string()
      .matches(/^5\d{8}$/, t("validations.signup/login.validPhoneNumber"))
      .required(t("validations.signup/login.phoneNumberRequired")),
    email: yup
      .string()
      .matches(
        /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/,
        t("validations.signup/login.validEmail")
      )
      .required(t("validations.signup/login.emailRequired")),
    firstName: yup
      .string()
      .required(t("validations.signup/login.firstNameRequired"))
      .min(1, t("validations.signup/login.minFirstNameLength"))
      .max(20, t("validations.signup/login.maxFirstNamelength"))
      .matches(/^[A-Za-z\u0600-\u06FF ]+$/, t("validations.signup/login.validFirstName")),
    lastName: yup
      .string()
      .required(t("validations.signup/login.lastNameRequired"))
      .min(1, t("validations.signup/login.minLastNameLength"))
      .max(20, t("validations.signup/login.maxLastNamelength"))
      .matches(/^[A-Za-z\u0600-\u06FF]+$/, t("validations.signup/login.validLastName")),
    password: yup
      .string()
      .required(t("validations.signup/login.passwordRequired"))
      .min(8, t("validations.signup/login.minPasswordLength"))
      .max(20, t("validations.signup/login.maxPasswordlength"))
      .matches(
        /^(?=.*[a-zA-Z\u0600-\u06FF])(?=.*[a-z\u0600-\u06FF])(?=.*[A-Z\u0600-\u06FF])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&\u0600-\u06FF]+$/,
        t("validations.signup/login.validPassword")
      ),
    confirmPassword: yup
      .string()
      .required(t("validations.signup/login.confirmPasswordRequired"))
      .min(8, t("validations.signup/login.minPasswordLength"))
      .max(20, t("validations.signup/login.maxPasswordlength"))
      .oneOf([yup.ref('password')], t("validations.signup/login.passwordMustMatch")),
  });

export const signup = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(
        /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/,
        t("validations.signup/login.validEmail")
      )
      .required(t("validations.signup/login.emailRequired")),
  });
