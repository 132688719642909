import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "inline-flex",
    padding: "48px 150px",
    flexDirection: "column",
    gap: "38px",
    width: "100%",
    background: "#E6E6FA",
    flexWrap: "wrap",

    [theme.breakpoints.down("lg")]: {
      padding: "48px 100px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "50px",
    },

    [theme.breakpoints.down("sm")]: {
      zIndex: 11,
    },
  },

  footerContent: {
    display: "flex",
    gap: "49px",
    justifyContent: "space-between",
    flexWrap: "wrap",

    [theme.breakpoints.down("lg")]: {
      gap: "28px",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "20px",
    },
  },

  line: {
    width: "1px",
    height: "128px",
    [theme.breakpoints.down("md")]: {
      height: "0px",
    },
  },
  needAnyHelp: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    flex: "1",
  },
  needAnyHelpTitle: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  contactList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
  },
  contactMessage: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    opacity: "0.7",
  },
  contactNumber: {
    opacity: "0.9",
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    "@media (max-width: 1444px)": {
      fontSize: "16px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      fontStyle: "normal",
    },
  },

  ourServices: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    flex: "1",
  },

  serviceList: {
    display: "flex",
    flexDirection: "column",
    alignItems: " flex-start",
    gap: "12px",
  },

  ourServiceTitle: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },

  service: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    opacity: 0.7,
    cursor: "pointer",
  },
  followUsOn: {
    gap: "10px",
    flex: "1",
  },
  followUsOnTitle: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  socialIcons: {
    display: " inline-flex",
    alignItems: "flex-start",
    gap: "12px",
    marginTop: "10px",
  },
  SocialIcon: {
    color: "#1b1d52",
  },

  divider: {
    background: "#1b1d52",
  },
  copyrightContent: {
    display: "flex",
    flexDirection: "column",
  },

  copyrightText: {
    marginTop: "32px",
    alignSelf: "center",
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
}));

export default useStyles;
