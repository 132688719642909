import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Rings } from "react-loader-spinner";
import CartFlightDetails from "../../../components/flight-module/cards/selected-flight-details";
import ChooseSeatInfo from "../../../components/flight-module/cards/choose-seat-info";
import ArrowSvg from "../../../assets/images/Line arrow-left.svg";
import useStyles from "./styles";
import Tippy from "@tippy.js/react";
import PlaneFront from "../../../assets/images/flightFront.png";
import { Box } from "@mui/material";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import BookingSummary from "../../../components/flight-module/personal-details/booking-summary-mobile";
import eventBus from "../../../core/eventBus";
import Coupon from "../../../components/flight-module/cards/coupons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";

const windowSeatsArray = [1, 4, 25, 26, 27];
const aisleSeatsArray = [2, 10, 31, 32, 33, 23, 49];
const middleSeatsArray = [3, 16, 28, 29, 30];
const windowWingsArray = [5, 7, 42];
const middleWingsArray = [17, 19, 44];
const aisleWingsArray = [11, 13, 46];
const windowExitRowArray = [6, 8, 43];
const middleExitRowArray = [18, 20, 45];
const aisleExitRowArray = [12, 14, 47, 15];
// const aisleExitRowArray = [];
const middleCenterArray = [35, 39, 40, 41];
const aisleCenterArray = [34, 36, 37, 38];
const noSeatRow = [50, 51, 52, 53];

const SeatDetails = (props) => {
  const {
    flightDetails,
    tripType,
    markup,
    markup2,
    setScreenNo,
    seats,
    segmentSeats,
    setSeatPrice,
    setSeats,
    couponDetails,
    traceId,
    setIsCouponApplied,
    setDiscountValue,
    message,
    setMessage,
    isCouponApplied,
    seatsArrayBackup,
  } = props;

  console.log("segmentSeats", segmentSeats);
  const user = useSelector((state) => state.user);
  const { adultCount, childrenCount, infantCount } = JSON.parse(
    window.localStorage.getItem("flightTravellerDetails")
  );

  const { t } = useTranslation();
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [index, setIndex] = useState(0);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const isMobile = useIsMobile();

  useEffect(() => {
    calculateSeatPrice();
  }, [segmentSeats, toggle]);

  const calculateSeatPrice = () => {
    let value = 0;
    for (const key in seats) {
      const seatPrices = seats[key];
      for (let j = 0; j < seatPrices.length; j++) {
        value += seatPrices[j].Price;
      }
    }
    setSeatPrice(value);
  };

  const handleSeatSelection = ({ row, seatNo, seat, segmentNo }) => {
    const totalPax = Number(adultCount) + Number(childrenCount);
    let seatsArray = seats;
    let updatedArray = seats[segmentNo];
    const index = updatedArray.findIndex(
      (seat) => seat.RowNo === row && seat.SeatNo === seatNo
    );
    if (index !== -1) {
      updatedArray.splice(index, 1);
      seatsArray[segmentNo] = updatedArray;
      setSeats(seatsArray);
    } else if (updatedArray.length < totalPax) {
      seatsArray[segmentNo] = [...updatedArray, { ...seat }];
      setSeats(seatsArray);
    } else {
      updatedArray.shift();
      seatsArray[segmentNo] = [...updatedArray, { ...seat }];
      setSeats(seatsArray);
    }
    setToggle(!toggle);
  };

  const handleNavigate = () => {
    setScreenNo(3);
  };
  function maxRowsLength(RowSeats) {
    let max = -1;
    for (let i = 0; i < RowSeats.length; i++) {
      max = Math.max(max, RowSeats[i].Seats?.length);
    }
    return max;
  }
  const handelSkipSeatSelection = () => {
    setSeatPrice(0);
    setSeats(seatsArrayBackup);
    setScreenNo(3);
  };

  const FlightDetailsComponent = () => {
    return flightDetails.map((flight, i) => {
      return (
        <Box>
          <CartFlightDetails
            flight={flight}
            key={i}
            selected={i}
            markup={tripType === "DRT" ? (i == 0 ? markup : markup2) : markup}
          />
        </Box>
      );
    });
  };

  const handleScroll = (value) => {
    setIndex(value);
  };

  return (
    <>
      {isMobile && (
        <Box
          sx={{
            padding: "0px 10px",
          }}
          onClick={() =>
            flightDetails.map(() => {
              return eventBus.emit(
                "open-bottomSheet",
                <>
                  <FlightDetailsComponent />
                </>
              );
            })
          }
        >
          <BookingSummary />
        </Box>
      )}
      {segmentSeats && segmentSeats.length > 0 ? (
        <div className={classes.leftContainer}>
          {segmentSeats && segmentSeats.length > 1 && (
            <div className={classes.arrowsContainer}>
              <div className={classes.outerArrowContainer}>
                {index !== 0 && (
                  <div
                    className={classes.arrowIcons}
                    onClick={() => handleScroll(index - 1)}
                  >
                    <ArrowBackIcon sx={{ width: "24px", height: "24px" }} />
                  </div>
                )}
              </div>
              <div className={classes.outerArrowContainer}>
                {index !== segmentSeats.length - 1 && (
                  <div
                    className={classes.arrowIcons}
                    onClick={() => handleScroll(index + 1)}
                  >
                    <ArrowForwardIcon sx={{ width: "24px", height: "24px" }} />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={classes.segmentSeatsContainer}>
            {segmentSeats &&
              segmentSeats.slice(index, index + 1).map((seat, segmentNo) => {
                const originDestination = Object.keys(seats);
                const { RowSeats } = seat;
                const { SeatInfo } = RowSeats[1];
                return (
                  <div className={classes.seatMainContainer}>
                    <div className={classes.seatInfoContainer}>
                      <ChooseSeatInfo
                        origin={SeatInfo[0].Origin}
                        destination={SeatInfo[0].Destination}
                        key={segmentNo}
                      />
                      <div className={classes.seatsDetailsContainer}>
                        <div className={classes.seatsDetailsSideContainer}>
                          <div className={classes.seatsDetailsSideInfo}>
                            <div
                              className={classes.seatsDetailsSideCheckbox}
                              style={{
                                border: "1px solid black",
                                background: "white",
                              }}
                            ></div>
                            <p className={classes.seatsDetailsInfoText}>
                              {/* Available Seats */}
                              {t("seatSelection.availableSeats")}
                            </p>
                          </div>
                          <div className={classes.seatsDetailsSideInfo}>
                            <div
                              className={classes.seatsDetailsSideCheckbox}
                              style={{
                                border: "1px solid gray",
                                background: "gray",
                              }}
                            ></div>
                            <p className={classes.seatsDetailsInfoText}>
                              {/* Reserved Seats */}
                              {t("seatSelection.reservedSeats")}
                            </p>
                          </div>
                          <div className={classes.seatsDetailsSideInfo}>
                            <div
                              className={classes.seatsDetailsSideCheckbox}
                              style={{
                                border: "1px solid green",
                                background: "green",
                              }}
                            ></div>
                            <p className={classes.seatsDetailsInfoText}>
                              {/* Selected Seats */}
                              {t("seatSelection.selectedSeats")}
                            </p>
                          </div>
                        </div>
                        <div className={classes.plainFrontContainer}>
                          <img
                            src={PlaneFront}
                            className={classes.plainFrontImage}
                            style={{
                              width:
                                maxRowsLength(RowSeats) > 6 ? "350px" : "250px",
                            }}
                          />
                          <div
                            className={classes.rowSeatsContainer}
                            style={{
                              width:
                                maxRowsLength(RowSeats) > 6 ? "350px" : "250px",
                            }}
                          >
                            {RowSeats.map((row) => {
                              const { SeatInfo } = row;
                              return (
                                <div className={classes.seatsContainer}>
                                  {SeatInfo.map((seat, i) => {
                                    const {
                                      RowNo,
                                      Code,
                                      Price,
                                      Currency,
                                      SeatNo,
                                      AvailablityType,
                                      SeatType,
                                    } = seat;
                                    return Code == "NoSeat" ||
                                      noSeatRow.includes(SeatType) ? (
                                      <></>
                                    ) : (i !== 0 && SeatNo === "D") ||
                                      (SeatNo === "H" &&
                                        ((aisleSeatsArray.includes(
                                          SeatInfo[i]?.SeatType
                                        ) &&
                                          aisleSeatsArray.includes(
                                            SeatInfo[i - 1]?.SeatType
                                          )) ||
                                          (aisleWingsArray.includes(
                                            SeatInfo[i]?.SeatType
                                          ) &&
                                            aisleWingsArray.includes(
                                              SeatInfo[i - 1]?.SeatType
                                            )) ||
                                          (aisleExitRowArray.includes(
                                            SeatInfo[i]?.SeatType
                                          ) &&
                                            aisleExitRowArray.includes(
                                              SeatInfo[i - 1]?.SeatType
                                            )) ||
                                          (aisleCenterArray.includes(
                                            SeatInfo[i]?.SeatType
                                          ) &&
                                            aisleCenterArray.includes(
                                              SeatInfo[i - 1]?.SeatType
                                            )))) ? (
                                      <Tippy
                                        arrow={true}
                                        placement="bottom-end"
                                        animation="shift-away"
                                        content={
                                          <div
                                            className={classes.tippyContainer}
                                          >
                                            <div
                                              className={
                                                classes.priceContainerNew
                                              }
                                            >
                                              <p className={classes.priceNew}>
                                                {/* Row No */}
                                                {t("seatSelection.rowNo")}
                                              </p>
                                              <p
                                                className={
                                                  classes.priceCurrency
                                                }
                                              >
                                                {RowNo}
                                              </p>
                                            </div>
                                            <div
                                              className={
                                                classes.priceContainerNew
                                              }
                                            >
                                              <p className={classes.priceNew}>
                                                {/* Seat No */}
                                                {t("seatSelection.seatNo")}
                                              </p>
                                              <p
                                                className={
                                                  classes.priceCurrency
                                                }
                                              >
                                                {SeatNo}
                                              </p>
                                            </div>
                                            <div
                                              className={
                                                classes.priceContainerNew
                                              }
                                            >
                                              <p className={classes.priceNew}>
                                                {/* Price */}
                                                {t("seatSelection.price")}
                                              </p>
                                              <p
                                                className={
                                                  classes.priceCurrency
                                                }
                                              >
                                                {Math.ceil(
                                                  Price * rateOfExchange
                                                )}{" "}
                                                SAR
                                              </p>
                                            </div>
                                          </div>
                                        }
                                      >
                                        <div
                                          onClick={() => {
                                            if (AvailablityType === 1) {
                                              handleSeatSelection({
                                                row: RowNo,
                                                seatNo: SeatNo,
                                                seat,
                                                segmentNo:
                                                  originDestination[index],
                                              });
                                            }
                                          }}
                                          className={classes.codeContainer}
                                          style={{
                                            color: seats[
                                              originDestination[index]
                                            ]?.some(
                                              (seat) =>
                                                seat.RowNo === RowNo &&
                                                seat.SeatNo === SeatNo
                                            )
                                              ? "white"
                                              : AvailablityType == 1
                                              ? "black"
                                              : "white",
                                            backgroundColor: seats[
                                              originDestination[index]
                                            ]?.some(
                                              (seat) =>
                                                seat.RowNo === RowNo &&
                                                seat.SeatNo === SeatNo
                                            )
                                              ? "green"
                                              : AvailablityType == 1
                                              ? "white"
                                              : "gray",
                                            borderColor:
                                              AvailablityType == 1
                                                ? "1px solid black"
                                                : "1px solid white",
                                            marginLeft:
                                              i != 0 &&
                                              SeatInfo[i].SeatNo == "H" &&
                                              (SeatInfo[i - 1].SeatNo == "A" ||
                                                SeatInfo[i - 1].SeatNo == "B" ||
                                                SeatInfo[i - 1].SeatNo == "C")
                                                ? "140px"
                                                : SeatInfo[0].SeatNo == "D" &&
                                                  SeatNo == "D"
                                                ? "115px"
                                                : "30px",
                                          }}
                                        >
                                          {Code}
                                        </div>
                                      </Tippy>
                                    ) : (
                                      <Tippy
                                        arrow={true}
                                        placement="bottom-end"
                                        animation="shift-away"
                                        content={
                                          <div
                                            className={classes.tippyContainer}
                                          >
                                            <div
                                              className={
                                                classes.priceContainerNew
                                              }
                                            >
                                              <p className={classes.priceNew}>
                                                {/* Row No */}
                                                {t("seatSelection.rowNo")}
                                              </p>
                                              <p
                                                className={
                                                  classes.priceCurrency
                                                }
                                              >
                                                {RowNo}
                                              </p>
                                            </div>
                                            <div
                                              className={
                                                classes.priceContainerNew
                                              }
                                            >
                                              <p className={classes.priceNew}>
                                                {/* Seat No */}
                                                {t("seatSelection.seatNo")}
                                              </p>
                                              <p
                                                className={
                                                  classes.priceCurrency
                                                }
                                              >
                                                {SeatNo}
                                              </p>
                                            </div>
                                            <div
                                              className={
                                                classes.priceContainerNew
                                              }
                                            >
                                              <p className={classes.priceNew}>
                                                {/* Price */}
                                                {t("seatSelection.price")}
                                              </p>
                                              <p
                                                className={
                                                  classes.priceCurrency
                                                }
                                              >
                                                {Math.ceil(
                                                  Price * rateOfExchange
                                                )}{" "}
                                                SAR
                                              </p>
                                            </div>
                                          </div>
                                        }
                                      >
                                        <div
                                          onClick={() => {
                                            if (AvailablityType === 1) {
                                              handleSeatSelection({
                                                row: RowNo,
                                                seatNo: SeatNo,
                                                seat,
                                                segmentNo:
                                                  originDestination[index],
                                              });
                                            }
                                          }}
                                          className={classes.codeThirdContainer}
                                          style={{
                                            color: seats[
                                              originDestination[index]
                                            ]?.some(
                                              (seat) =>
                                                seat.RowNo === RowNo &&
                                                seat.SeatNo === SeatNo
                                            )
                                              ? "white"
                                              : AvailablityType == 1
                                              ? "black"
                                              : "white",
                                            backgroundColor: seats[
                                              originDestination[index]
                                            ]?.some(
                                              (seat) =>
                                                seat.RowNo === RowNo &&
                                                seat.SeatNo === SeatNo
                                            )
                                              ? "green"
                                              : AvailablityType == 1
                                              ? "white"
                                              : "gray",
                                            borderColor:
                                              AvailablityType == 1
                                                ? "1px solid black"
                                                : "1px solid white",

                                            marginLeft:
                                              SeatInfo[0].SeatNo == "B" &&
                                              SeatNo == "B"
                                                ? "30px"
                                                : SeatInfo[0].SeatNo == "C" &&
                                                  SeatNo == "C"
                                                ? "60px"
                                                : SeatInfo[0].SeatNo == "D" &&
                                                  SeatNo == "D"
                                                ? "115px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "K" &&
                                                  SeatInfo[i - 1].SeatNo == "H"
                                                ? "30px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "J" &&
                                                  SeatInfo[i - 1].SeatNo != "H"
                                                ? "55px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "F" &&
                                                  SeatInfo[i - 1].SeatNo != "E"
                                                ? "30px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "F" &&
                                                  (SeatInfo[i - 1].SeatNo ==
                                                    "A" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "B" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "C")
                                                ? "85px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "E" &&
                                                  (SeatInfo[i - 1].SeatNo ==
                                                    "A" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "B" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "C")
                                                ? "55px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "G" &&
                                                  (SeatInfo[i - 1].SeatNo ==
                                                    "A" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "B" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "C")
                                                ? "115px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "G" &&
                                                  SeatInfo[i - 1].SeatNo != "F"
                                                ? "30px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "G" &&
                                                  SeatInfo[i - 1].SeatNo != "E"
                                                ? "60px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "H" &&
                                                  (SeatInfo[i - 1].SeatNo ==
                                                    "A" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "B" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "C")
                                                ? "140px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "J" &&
                                                  (SeatInfo[i - 1].SeatNo ==
                                                    "A" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "B" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "C")
                                                ? "170px"
                                                : i != 0 &&
                                                  SeatInfo[i].SeatNo == "K" &&
                                                  (SeatInfo[i - 1].SeatNo ==
                                                    "A" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "B" ||
                                                    SeatInfo[i - 1].SeatNo ==
                                                      "C")
                                                ? "200px"
                                                : SeatNo == "H"
                                                ? "30px"
                                                : SeatNo == "C" && i == 1
                                                ? "24px"
                                                : "0px",
                                          }}
                                        >
                                          {Code}
                                        </div>
                                      </Tippy>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {isMobile &&
            couponDetails &&
            Object.keys(couponDetails).length > 0 &&
            couponDetails?.lowestAmountCoupon?.length > 0 && (
              <Coupon
                couponDetails={couponDetails}
                traceId={traceId}
                setIsCouponApplied={setIsCouponApplied}
                tripType={tripType}
                flightDetails={flightDetails}
                markup={markup}
                markup2={markup2}
                setDiscountValue={setDiscountValue}
                isCouponApplied={isCouponApplied}
                message={message}
                setMessage={setMessage}
              />
            )}
          <div className={classes.previousSkipContinueContainer}>
            <div
              className={classes.previousContainer}
              onClick={() => setScreenNo(1)}
            >
              <ReactSVG src={ArrowSvg} style={{ marginTop: "4px" }} />
              <p className={classes.previousText}>
                {" "}
                {t("seatSelection.previous")}
              </p>
            </div>
            <div className={classes.skipSeatAndContinueBtnContainer}>
              <p
                className={classes.skipSeatText}
                onClick={() => handelSkipSeatSelection()}
              >
                {t("seatSelection.skipSeatSelection")}
              </p>
              {!isMobile && (
                <div
                  className={classes.continueButtonCotainer}
                  onClick={() => {
                    handleNavigate();
                  }}
                >
                  <p className={classes.continueText}>
                    {t("seatSelection.continueToFinalDetails")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25%",
            width: "60%",
          }}
        >
          <Rings
            height="80"
            width="80"
            color="#1B1D52"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />{" "}
        </div>
      )}
    </>
  );
};

export default SeatDetails;
