import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modifySearchHotelMobileContainer: {
    display: "flex",
    padding: "16px 16px",
    alignItems: "center",
    gap: "24px",
    background: "#FFF",
    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.10)",
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },

  modifySearchHotelDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
    width: "90%",
  },

  cityName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },

  roomDetails: {
    display: "flex",
    alignItems: "flex-start",
    gap: "6px",
    flexWrap: "wrap",
  },

  checkInCheckoutDate: {
    display: "flex",
    paddingRight: "8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRight: "1px solid #D0D5DD",
  },

  dates: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },

  guestNumber: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },

  guestDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
  },

  cityContainer: {
    display: "inline-flex",
    gap: "3px",
    paddingRight: "0px",
    alignItems: "center",
    width: "90%",
  },
}));

export default useStyles;
