import React, { useEffect, useState } from "react";
import {
  stops,
  timings,
  refundStatus,
  stopsArabic,
  refundStatusArabic,
  timingsArabic,
} from "../../../../../config";
import SidebarTimings from "../cards/sidebar-timings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Slider } from "@mui/material";
import { Box, Typography, Checkbox, Divider } from "@mui/material";
import {
  handleAirlineFilter,
  handleClearAllFilters,
  handleDurationFilter,
  handleOutBoundArrivalDurationFilter,
  handleOutBoundDepartureDurationFilter,
  handlePriceFilter,
  handleQuickFilters,
  handleRefundFilter,
  handleStopsFilter,
} from "../../../../../utils/slices/flightFiltersSlice";
import { useDispatch, useSelector } from "react-redux";
import { timeStringToMinutes } from "../../../../../utils/helpers";
import { ReactSVG } from "react-svg";
import closeIcon from "../../../../../assets/images/x-close.svg";
import useStyles from "./style";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../core/eventBus";
import { handleInBoundArrivalDurationFilter } from "../../../../../utils/slices/flightFiltersSlice";
import { handleInBoundDepartureDurationFilter } from "../../../../../utils/slices/flightFiltersSlice";
import "./style.css";
import { useTranslation } from "react-i18next";

const SearchFlightSidebar = ({
  inboundFilters = {},
  outboundFilters = {},
  from,
  to,
  showPriceFilter,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isMobile = useIsMobile();
  const {
    airlineCount = [],
    mintotalFares,
    maxtotalFares,
    minduration,
    maxduration,
    minPriceBefore6amDeparture: minPriceD1,
    minPriceBefore6amArrival: minPriceA1,
    minPrice6amTo12pmDeparture: minPriceD2,
    minPrice6amTo12pmArrival: minPriceA2,
    minPrice12pmTo6pmDeparture: minPriceD3,
    minPrice12pmTo6pmArrival: minPriceA3,
    minPriceAfter6pmDeparture: minPriceD4,
    minPriceAfter6pmArrival: minPriceA4,
  } = outboundFilters;

  const {
    minPriceBefore6amDeparture,
    minPriceBefore6amArrival,
    minPrice6amTo12pmDeparture,
    minPrice6amTo12pmArrival,
    minPrice12pmTo6pmDeparture,
    minPrice12pmTo6pmArrival,
    minPriceAfter6pmDeparture,
    minPriceAfter6pmArrival,
  } = inboundFilters;

  const {
    airlineFilter: airlineF,
    refundFilter: refundF,
    stopsFilter: stopsF,
    durationFilter: durationF,
    priceFilter: priceF,
    outBoundDepartureDurationFilter: outBoundDepartureDurationF,
    outBoundArrivalDurationFilter: outBoundArrivalDurationF,
    inBoundDepartureDurationFilter: inBoundDepartureDurationF,
    inBoundArrivalDurationFilter: inBoundArrivalDurationF,
    totalFlights,
    filteredFlights,
    filteredFlightsInbound,
  } = useSelector((state) => state.flightFilters);

  console.log("filteredFlights----->", priceF);

  const [outBoundDeparturePrices, setOutBoundDeparturePrices] = useState([]);
  const [outBoundArrivalPrices, setOutBoundArrivalPrices] = useState([]);
  const [inBoundDeparturePrices, setInBoundDeparturePrices] = useState([]);
  const [inBoundArrivalPrices, setInBoundArrivalPrices] = useState([]);
  const [showStops, setShowStops] = useState(false);
  const [showRefundStatus, setShowRefundStatus] = useState(false);
  const [showAirlines, setShowAirlines] = useState(false);
  const [showTripDuration, setShowTripDuration] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [tripDuration, setTripDuration] = useState();
  const [price, setPrice] = useState();
  const [stopsFilter, setStopsFilter] = useState(stopsF);
  const [airlinesFilter, setAirlinesFilter] = useState(airlineF);
  const [refundFilter, setRefundFilter] = useState(refundF);
  const [inBoundDepartureDuration, setInBoundDepartureDuration] = useState([]);
  const [inBoundArrivalDuration, setInBoundArrivalDuration] = useState([]);
  const [outBoundDepartureDuration, setOutBoundDepartureDuration] = useState(
    []
  );
  const [outBoundArrivalDuration, setOutBoundArrivalDuration] = useState([]);
  const getLocale = useSelector((state) => state.locale);
  const { t } = useTranslation();
  const handleChangeStops = (id) => {
    if (stopsFilter.includes(id)) {
      const arr = stopsFilter.filter((stops) => stops != id);
      setStopsFilter([...arr]);
      dispatch(handleStopsFilter([...arr]));
    } else {
      setStopsFilter([...stopsFilter, id]);
      dispatch(handleStopsFilter([...stopsFilter, id]));
    }
  };

  const handleChangeAirlines = (name) => {
    if (airlinesFilter.includes(name)) {
      const arr = airlinesFilter.filter((airline) => airline != name);
      setAirlinesFilter([...arr]);
      dispatch(handleAirlineFilter([...arr]));
    } else {
      setAirlinesFilter([...airlinesFilter, name]);
      dispatch(handleAirlineFilter([...airlinesFilter, name]));
    }
  };

  const handleChangeRefundOption = (id) => {
    if (refundFilter.includes(id)) {
      const arr = refundFilter.filter((refund) => refund != id);
      setRefundFilter([...arr]);
      dispatch(handleRefundFilter([...arr]));
    } else {
      setRefundFilter([...refundFilter, id]);
      dispatch(handleRefundFilter([...refundFilter, id]));
    }
  };

  const handleTripDuration = (_, newValue) => {
    if (!Array.isArray(newValue)) return;
    setTripDuration(newValue);
    if (newValue[0] == minduration && newValue[1] == maxduration) {
      dispatch(handleDurationFilter([]));
    } else dispatch(handleDurationFilter(newValue));
  };

  const handlePrice = (_, newValue) => {
    console.log("new_value", newValue);
    if (!Array.isArray(newValue)) return;
    setPrice(newValue);
    if (newValue[0] == mintotalFares && newValue[1] == maxtotalFares) {
      dispatch(handlePriceFilter([]));
    } else dispatch(handlePriceFilter(newValue));
  };

  const handleOutBoundDepartureDuration = (index) => {
    let updatedArray = outBoundDepartureDuration;
    const findIndex = updatedArray.findIndex((arr) => arr == index);
    if (findIndex === -1) {
      updatedArray.push(index);
    } else {
      updatedArray.splice(findIndex, 1);
    }
    setOutBoundDepartureDuration([...updatedArray]);
    dispatch(handleOutBoundDepartureDurationFilter([...updatedArray]));
  };

  const handleOutBoundArrivalDuration = (index) => {
    let updatedArray = outBoundArrivalDuration;
    const findIndex = updatedArray.findIndex((arr) => arr == index);
    if (findIndex === -1) {
      updatedArray.push(index);
    } else {
      updatedArray.splice(findIndex, 1);
    }
    setOutBoundArrivalDuration([...updatedArray]);
    dispatch(handleOutBoundArrivalDurationFilter([...updatedArray]));
  };

  const handleInBoundDepartureDuration = (index) => {
    let updatedArray = inBoundDepartureDuration;
    const findIndex = updatedArray.findIndex((arr) => arr == index);
    if (findIndex === -1) {
      updatedArray.push(index);
    } else {
      updatedArray.splice(findIndex, 1);
    }
    setInBoundDepartureDuration([...updatedArray]);
    dispatch(handleInBoundDepartureDurationFilter([...updatedArray]));
  };

  const handleInBoundArrivalDuration = (index) => {
    let updatedArray = inBoundArrivalDuration;
    const findIndex = updatedArray.findIndex((arr) => arr == index);
    if (findIndex === -1) {
      updatedArray.push(index);
    } else {
      updatedArray.splice(findIndex, 1);
    }
    setInBoundArrivalDuration([...updatedArray]);
    dispatch(handleInBoundArrivalDurationFilter([...updatedArray]));
  };

  const handleClearAll = () => {
    dispatch(handleClearAllFilters());
    setShowStops(false);
    setShowRefundStatus(false);
    setShowAirlines(false);
    setShowTripDuration(false);
    setShowPrice(false);
  };

  useEffect(() => {
    setPrice([Math.ceil(mintotalFares), Math.ceil(maxtotalFares)]);
  }, [mintotalFares, maxtotalFares]);

  useEffect(() => {
    if (minduration && maxduration) {
      const minDuration = timeStringToMinutes(minduration);
      const maxDuration = timeStringToMinutes(maxduration);
      setTripDuration([minDuration, maxDuration]);
      setOutBoundDeparturePrices([
        minPriceD1,
        minPriceD2,
        minPriceD3,
        minPriceD4,
      ]);
      setOutBoundArrivalPrices([
        minPriceA1,
        minPriceA2,
        minPriceA3,
        minPriceA4,
      ]);
      setInBoundDeparturePrices([
        minPriceBefore6amDeparture,
        minPrice6amTo12pmDeparture,
        minPrice12pmTo6pmDeparture,
        minPriceAfter6pmDeparture,
      ]);
      setInBoundArrivalPrices([
        minPriceBefore6amArrival,
        minPrice6amTo12pmArrival,
        minPrice12pmTo6pmArrival,
        minPriceAfter6pmArrival,
      ]);
    }
  }, [minduration, maxduration]);

  useEffect(() => {
    setStopsFilter(stopsF);
    setAirlinesFilter(airlineF);
    setRefundFilter(refundF);
    setInBoundDepartureDuration([...inBoundDepartureDurationF]);
    setInBoundArrivalDuration([...inBoundArrivalDurationF]);
    setOutBoundDepartureDuration([...outBoundDepartureDurationF]);
    setOutBoundArrivalDuration([...outBoundArrivalDurationF]);
    if (durationF.length === 0 && minduration && maxduration) {
      const minDuration = timeStringToMinutes(minduration);
      const maxDuration = timeStringToMinutes(maxduration);
      setTripDuration([minDuration, maxDuration]);
    }
    if (priceF.length === 0 && mintotalFares && maxtotalFares) {
      setPrice([Math.ceil(mintotalFares), Math.ceil(maxtotalFares)]);
    }

    if (priceF.length !== 0) {
      setPrice([priceF[0], priceF[1]]);
    }
  }, [
    refundF,
    airlineF,
    stopsF,
    durationF,
    priceF,
    outBoundDepartureDurationF,
    outBoundArrivalDurationF,
    inBoundDepartureDurationF,
    inBoundArrivalDurationF,
  ]);

  useEffect(() => {
    handleQickFiltersAirlines();
  }, [airlineCount]);

  const handleQickFiltersAirlines = () => {
    const airlines = Object.keys(airlineCount)
      .sort((a, b) => airlineCount[b] - airlineCount[a])
      .slice(0, 3);

    dispatch(handleQuickFilters([...airlines]));
  };

  return (
    <>
      {isMobile && (
        <div className={styles.flightHeaderDetails}>
          <div>
            <ReactSVG
              src={closeIcon}
              style={{ marginTop: "-2px", height: "18px", width: "12px" }}
              onClick={() => eventBus.emit("close-bottomSheet")}
            />
          </div>
          <div className={styles.flightHeaderDetailsLayout}>
            <div className={styles.filtersText1}>
              {t("flightSearch.filters")}
            </div>
            <div style={{ display: "inline-flex", gap: "8px" }}>
              <div
                className={styles.applyText}
                onClick={() => {
                  eventBus.emit("close-bottomSheet");
                }}
              >
                {t("flightSearchWidgetMobile.apply")}
              </div>
              <div
                className={styles.filtersText}
                onClick={() => {
                  handleClearAll();
                }}
              >
                {t("flightSearchWidgetMobile.clearAll")}
              </div>
            </div>
          </div>
        </div>
      )}

      <Box
        sx={{
          padding: "24px 16px",
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {!isMobile &&
        (filteredFlights.length > 0 || filteredFlightsInbound?.length) ? (
          <Box sx={{ display: "inline-flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                color: "344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                wordSpacing: "2px",
              }}
            >
              {filteredFlights.length > 0 && filteredFlightsInbound.length > 0
                ? filteredFlights.length + filteredFlightsInbound.length
                : filteredFlights.length > 0
                ? filteredFlights.length
                : filteredFlightsInbound?.length}{" "}
              {t("flightSearch.of")} {totalFlights} {t("flightSearch.flights")}
            </Typography>
            <Typography
              sx={{
                color: "#1b1d52",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              onClick={handleClearAll}
            >
              {t("flightSearch.clearAll")}
            </Typography>
          </Box>
        ) : (
          <></>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setShowStops(!showStops)}
          >
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              {t("flightSearch.stops")}
            </Typography>
            {showStops ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          {localStorage.getItem("language") == "AR"
            ? showStops &&
              stopsArabic.map((stop) => {
                const { key, name, id } = stop;
                return (
                  <Box
                    sx={{ display: "inline-flex", gap: "8px" }}
                    key={key}
                    onClick={() => handleChangeStops(id)}
                  >
                    <Checkbox
                      checked={stopsFilter.includes(id)}
                      sx={{ width: "16px", height: "16px", color: "#D0D5DD" }}
                    />
                    <Typography
                      sx={{
                        color: "#525A6A",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                );
              })
            : showStops &&
              stops.map((stop) => {
                const { key, name, id } = stop;
                return (
                  <Box
                    sx={{ display: "inline-flex", gap: "8px" }}
                    key={key}
                    onClick={() => handleChangeStops(id)}
                  >
                    <Checkbox
                      checked={stopsFilter.includes(id)}
                      sx={{ width: "16px", height: "16px", color: "#D0D5DD" }}
                    />
                    <Typography
                      sx={{
                        color: "#525A6A",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                );
              })}
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              {t("flightSearch.departureFrom")} {from?.city}
            </Typography>
          </Box>
          <Box sx={{ display: "inline-flex", gap: "12px", flexWrap: "wrap" }}>
            {localStorage.getItem("language") != "AR" &&
              timings?.map((timing, i) => {
                const { id } = timing;
                return (
                  outBoundDeparturePrices[i] != 0 && (
                    <div
                      onClick={() => handleOutBoundDepartureDuration(i + 1)}
                      className={
                        outBoundDepartureDuration.includes(i + 1)
                          ? "sidebar-timings-card-active"
                          : "sidebar-timings-card"
                      }
                      key={id}
                    >
                      <SidebarTimings
                        timing={timing}
                        price={outBoundDeparturePrices[i]}
                      />
                    </div>
                  )
                );
              })}

            {localStorage.getItem("language") == "AR" &&
              timingsArabic?.map((timing, i) => {
                const { id } = timing;
                return (
                  outBoundDeparturePrices[i] != 0 && (
                    <div
                      onClick={() => handleOutBoundDepartureDuration(i + 1)}
                      className={
                        outBoundDepartureDuration.includes(i + 1)
                          ? "sidebar-timings-card-active"
                          : "sidebar-timings-card"
                      }
                      key={id}
                    >
                      <SidebarTimings
                        timing={timing}
                        price={outBoundDeparturePrices[i]}
                      />
                    </div>
                  )
                );
              })}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              {t("flightSearch.arrivalAt")} {to?.city}
            </Typography>
          </Box>
          <Box sx={{ display: "inline-flex", gap: "12px", flexWrap: "wrap" }}>
            {localStorage.getItem("language") != "AR" &&
              timings?.map((timing, i) => {
                const { id } = timing;
                return (
                  outBoundArrivalPrices[i] != 0 && (
                    <div
                      onClick={() => handleOutBoundArrivalDuration(i + 1)}
                      className={
                        outBoundArrivalDuration.includes(i + 1)
                          ? "sidebar-timings-card-active"
                          : "sidebar-timings-card"
                      }
                      key={id}
                    >
                      <SidebarTimings
                        timing={timing}
                        price={outBoundArrivalPrices[i]}
                      />
                    </div>
                  )
                );
              })}

            {localStorage.getItem("language") == "AR" &&
              timingsArabic?.map((timing, i) => {
                const { id } = timing;
                return (
                  outBoundArrivalPrices[i] != 0 && (
                    <div
                      onClick={() => handleOutBoundArrivalDuration(i + 1)}
                      className={
                        outBoundArrivalDuration.includes(i + 1)
                          ? "sidebar-timings-card-active"
                          : "sidebar-timings-card"
                      }
                      key={id}
                    >
                      <SidebarTimings
                        timing={timing}
                        price={outBoundArrivalPrices[i]}
                      />
                    </div>
                  )
                );
              })}
          </Box>
        </Box>
        <Divider />
        {Object.keys(inboundFilters).length > 0 && (
          <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: "#4037acd6",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                >
                  {t("flightSearch.departureFrom")} {to?.city}
                </Typography>
              </Box>
              <Box
                sx={{ display: "inline-flex", gap: "12px", flexWrap: "wrap" }}
              >
                {localStorage.getItem("language") != "AR" &&
                  timings?.map((timing, i) => {
                    const { id } = timing;
                    return (
                      inBoundDeparturePrices[i] != 0 && (
                        <div
                          onClick={() => handleInBoundDepartureDuration(i + 1)}
                          className={
                            inBoundDepartureDuration.includes(i + 1)
                              ? "sidebar-timings-card-active"
                              : "sidebar-timings-card"
                          }
                          key={id}
                        >
                          <SidebarTimings
                            timing={timing}
                            price={inBoundDeparturePrices[i]}
                          />
                        </div>
                      )
                    );
                  })}

                {localStorage.getItem("language") == "AR" &&
                  timingsArabic?.map((timing, i) => {
                    const { id } = timing;
                    return (
                      inBoundDeparturePrices[i] != 0 && (
                        <div
                          onClick={() => handleInBoundDepartureDuration(i + 1)}
                          className={
                            inBoundDepartureDuration.includes(i + 1)
                              ? "sidebar-timings-card-active"
                              : "sidebar-timings-card"
                          }
                          key={id}
                        >
                          <SidebarTimings
                            timing={timing}
                            price={inBoundDeparturePrices[i]}
                          />
                        </div>
                      )
                    );
                  })}
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: "#4037acd6",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                >
                  {t("flightSearch.arrivalAt")} {from?.city}
                </Typography>
              </Box>
              <Box
                sx={{ display: "inline-flex", gap: "12px", flexWrap: "wrap" }}
              >
                {localStorage.getItem("language") != "AR" &&
                  timings?.map((timing, i) => {
                    const { id } = timing;
                    return (
                      inBoundArrivalPrices[i] != 0 && (
                        <div
                          onClick={() => handleInBoundArrivalDuration(i + 1)}
                          className={
                            inBoundArrivalDuration.includes(i + 1)
                              ? "sidebar-timings-card-active"
                              : "sidebar-timings-card"
                          }
                          key={id}
                        >
                          <SidebarTimings
                            timing={timing}
                            price={inBoundArrivalPrices[i]}
                          />
                        </div>
                      )
                    );
                  })}

                {localStorage.getItem("language") == "AR" &&
                  timingsArabic?.map((timing, i) => {
                    const { id } = timing;
                    return (
                      inBoundArrivalPrices[i] != 0 && (
                        <div
                          onClick={() => handleInBoundArrivalDuration(i + 1)}
                          className={
                            inBoundArrivalDuration.includes(i + 1)
                              ? "sidebar-timings-card-active"
                              : "sidebar-timings-card"
                          }
                          key={id}
                        >
                          <SidebarTimings
                            timing={timing}
                            price={inBoundArrivalPrices[i]}
                          />
                        </div>
                      )
                    );
                  })}
              </Box>
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setShowRefundStatus(!showRefundStatus)}
          >
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              {t("flightSearch.refundStatus")}
            </Typography>
            {showRefundStatus ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          {localStorage.getItem("language") == "AR"
            ? showRefundStatus &&
              refundStatusArabic.map((status, i) => {
                const { key, name, id } = status;
                return (
                  <Box
                    sx={{ display: "inline-flex", gap: "8px" }}
                    key={key}
                    onClick={() => handleChangeRefundOption(id)}
                  >
                    <Checkbox
                      checked={refundFilter.includes(id)}
                      sx={{ width: "16px", height: "16px", color: "#D0D5DD" }}
                    />
                    <Typography
                      sx={{
                        color: "#525A6A",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                );
              })
            : showRefundStatus &&
              refundStatus.map((status, i) => {
                const { key, name, id } = status;
                return (
                  <Box
                    sx={{ display: "inline-flex", gap: "8px" }}
                    key={key}
                    onClick={() => handleChangeRefundOption(id)}
                  >
                    <Checkbox
                      checked={refundFilter.includes(id)}
                      sx={{ width: "16px", height: "16px", color: "#D0D5DD" }}
                    />
                    <Typography
                      sx={{
                        color: "#525A6A",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                      }}
                    >
                      {name}
                    </Typography>
                  </Box>
                );
              })}
        </Box>
        <Divider />
        {showPriceFilter && (
          <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setShowPrice(!showPrice)}
              >
                <Typography
                  sx={{
                    color: "#4037acd6",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    textTransform: "uppercase",
                  }}
                >
                  {t("flightSearch.price")}
                </Typography>
                {showPrice ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
              {showPrice && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Slider
                    size="small"
                    value={price}
                    onChange={handlePrice}
                    sx={{ color: "#1b1d52" }}
                    valueLabelDisplay="auto"
                    disableSwap
                    min={Math.ceil(mintotalFares)}
                    max={Math.ceil(maxtotalFares)}
                  />
                  <Box
                    sx={{
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#525A6A",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                      }}
                    >
                      {localStorage.getItem("language") == "AR"
                        ? price[1].toLocaleString("en-IN")
                        : price[0].toLocaleString("en-IN")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#525A6A",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                      }}
                    >
                      {localStorage.getItem("language") == "AR"
                        ? price[0].toLocaleString("en-IN")
                        : price[1].toLocaleString("en-IN")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setShowAirlines(!showAirlines)}
          >
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              {t("flightSearch.airlines")}
            </Typography>
            {showAirlines ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          {showAirlines &&
            Object.keys(airlineCount).length > 0 &&
            Object.keys(airlineCount).map((key) => {
              return (
                <Box
                  sx={{ display: "inline-flex", gap: "8px" }}
                  key={key}
                  onClick={() => handleChangeAirlines(key)}
                >
                  <Checkbox
                    checked={airlinesFilter.includes(key)}
                    sx={{ width: "16px", height: "16px", color: "#D0D5DD" }}
                  />
                  <Typography
                    sx={{
                      color: "#525A6A",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      textTransform: "capitalize",
                    }}
                  >
                    {key} ({airlineCount[key]})
                  </Typography>
                </Box>
              );
            })}
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setShowTripDuration(!showTripDuration)}
          >
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                textTransform: "uppercase",
              }}
            >
              {t("flightSearch.tripDuration")}
            </Typography>
            {showTripDuration ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          {showTripDuration && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{ display: "inline-flex", justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    color: "#525A6A",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {localStorage.getItem("language") == "AR"
                    ? `${tripDuration[1]} ${t("clockTimings.minutes")}`
                    : `${tripDuration[0]} ${t("clockTimings.minutes")}`}
                </Typography>
                <Typography
                  sx={{
                    color: "#525A6A",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                >
                  {localStorage.getItem("language") == "AR"
                    ? `${tripDuration[0]} ${t("clockTimings.minutes")}`
                    : `${tripDuration[1]} ${t("clockTimings.minutes")}`}
                </Typography>
              </Box>
              <Slider
                size="small"
                value={tripDuration}
                onChange={handleTripDuration}
                valueLabelDisplay="auto"
                disableSwap
                sx={{ color: "#1b1d52" }}
                min={timeStringToMinutes(minduration)}
                max={timeStringToMinutes(maxduration)}
                step={10}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SearchFlightSidebar;
