import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    width: "100%",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]:{
      width: '95vw',
      height: '70vh'
    }
  },
}));

export default useStyles;
