import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { useLocation } from "react-router-dom";
import useStyles from "./styles";
import { getTermsAndConditions } from "../../modules/home";
import { useSelector } from "react-redux";
import "./index.css";
import { ReactSVG } from "react-svg";
import ArrowRightSvg from "../../assets/images/arrow-right.svg";

import { Rings } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import handleUnauthorisedLogout from "../../modules/unauthorised";
import { setUserInfo } from "../../utils/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const TermsAndConditions = () => {
  const location = useLocation();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [terms, setTerms] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t}  =  useTranslation()

  // const { terms } = location.state;

  useEffect(() => {
    handleGetTermsAndConditions();
  }, []);

  const handleGetTermsAndConditions = async () => {
    const data = await getTermsAndConditions(user?.token);
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    setTerms(data?.termsConditions);
  };
  return (
    <>
      <div className={classes.mainRootContainer}>
        <div
          style={{
            display: "inline-flex",
            gap: "8px",
            width: "80%",
            marginTop: "20px",
            // padding: '10px'
          }}
        >
          <p
            style={{
              color: "#667085",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            {/* Home */}
            {t("navigationDrawer.home")}
          </p>
          <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
          <p
            style={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
          >
            {/* Terms and Conditions */}
            {t("navigationDrawer.termsAndConditions")}
          </p>
        </div>
        {terms ? (
          <div className={classes.mainContainer}>
            <h3 className="termsAndConditionsHeading">
              {/* Terms & Conditions */}
              {t("navigationDrawer.termsAndConditions")}
              </h3>
            <div className={classes.termsAndCondition}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    terms || "<h1>Page not found</h1>"
                  ),
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <Rings height={80} width={80} color="#1B1D52" />
          </div>
        )}
      </div>
    </>
  );
};

export default TermsAndConditions;
