import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  recentArticles: {
    padding: "0px 150px 90px 150px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      padding: "30px 12px 90px 12px !important",
    },

    [theme.breakpoints.down("lg")]: {
      padding: "30px 100px 90px 100px",
    },
  },
  recentArticlesHeader: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
  },
  recentArticlesHeaderLeftSection: {
    display: "flex",
    justifyContent: "space-between",
  },

  recentArticlesTitle: {
    display: "flex",
    fontSize: theme.fontSizes.fontSizeJumbo,
    fontWeight: "700",
    columnGap: "6px",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.fontSizes.fontSize1,
    },
  },
  recentArticlesTitleActive: {
    color: theme.palette.entityColors.entityColorPrimary,
  },
  recentArticlesTitleInActive: {
    color: theme.palette.textColors.textColorPrimary,
  },
  recentArticlesDescription: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize1,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.fontSizes.fontSize2,
      marginBottom: "10px",
    },
  },
  recentArticlesHeaderRightSection: {
    fontSize: theme.fontSizes.fontSize1,
    color: "#4037acd6",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  recentArticlesBody: {
    display: "flex",
    columnGap: "16px",
  },
  recentArticlesBodyDummy: {
    display: "flex",
    columnGap: "16px",
    flex: 3
  },
  recentArticleCard: {
    marginTop: "32px",
    display: "flex",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  recentArticleCardDummy: {
    marginTop: "32px",
    display: "flex",
    gap: "15px",
    // flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  cardContainer: {
    scrollbarWidth: "none",

    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      marginTop: "20px",

      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
      overflowY: "hidden",
    },
  },

  card: {
    flex: "0 0 auto",
    width: "370px",
    height: "533px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
}));

export default useStyles;
