import React, { useEffect, useState } from "react";
import { MobileCitySelectionField } from "../../components/MobileCitySelectionField";
import useStyles from "./styles";
import { MobileCalender } from "../../components/MobileCalender";
import { PersonSelection } from "../../components/PersonSelection";
import { Button } from "../../components/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateHotelFilters } from "../../../utils/slices/globalFiltersSlice";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { updateHotelCity } from "../../../utils/slices/searchHotelSlice";
import { updateHotelDates } from "../../../utils/slices/searchHotelSlice";
import HotelTotalPerson from "../../../components/search-widget/components/hotel-search-widget/dropdown/total-persons";
import { updatePriceSorting } from "../../../utils/slices/localFiltersSlice";
import eventBus from "../../../core/eventBus";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { calculateNumberOfNights } from "../../../utils/helpers";
import nightStay from "../../../assets/images/nights_stay.svg";

export const MobileHotelFilterView = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rooms = useSelector((state) => state.hotelRooms.rooms);
  const { hotelCheckInDate, hotelCheckOutDate } = useSelector(
    (state) => state.searchHotel
  );
  const { hotelCity } = useSelector((state) => state.searchHotel);
  const [hotelFilterState, setHotelFilterState] = useState({
    cities: { hotelCity },
    checkInDate: hotelCheckInDate,
    checkOutDate: hotelCheckOutDate,
    roomDetails: rooms,
  });

  const [minDate, setMinDate] = useState(hotelCheckInDate);
  const [showCheckInDateError, setShowCheckInDateError] = useState(false);
  const [showCheckOutDateError, setShowCheckOutDateError] = useState(false);
  const [showCityEnterError, setShowCityEnterError] = useState(false);
  const [
    ShowCheckOutMustBeGreaterThanCheckInError,
    setShowCheckOutMustBeGreaterThanCheckInError,
  ] = useState(false);

  const [showPersonSelector, setShowPersonSelector] = useState(false);
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  let checkInParts = hotelCheckInDate.split("/");
  let checkOutParts = hotelCheckOutDate.split("/");

  if (typeof hotelFilterState.checkInDate == 'object') {
    checkInParts = hotelFilterState?.checkInDate?.formattedValue?.split("/")
  } else if (typeof hotelFilterState.checkInDate == 'string') {
    checkInParts = hotelFilterState.checkInDate.split("/");
  }

  if (typeof hotelFilterState.checkOutDate == 'object') {
    checkOutParts = hotelFilterState?.checkOutDate?.formattedValue?.split("/")
  } else if (typeof hotelFilterState.checkOutDate == 'string') {
    checkOutParts = hotelFilterState.checkOutDate.split("/")
  }
  let oldCheckInDate = new Date(
    checkInParts[2],
    checkInParts[1] - 1,
    checkInParts[0]
  );
  oldCheckInDate.setHours(0, 0, 0, 0)
  let oldCheckOutDate = new Date(
    checkOutParts[2],
    checkOutParts[1] - 1,
    checkOutParts[0]
  );
  oldCheckOutDate.setHours(0, 0, 0, 0)
  const handleClick = () => {
    const totalGuests = [];
    const length = rooms?.length;
    for (let i = 0; i < length; i++) {
      const { adultCounter, childrenCounter, childrenAges } = rooms[i];
      totalGuests.push({
        adultCounter,
        childrenCounter,
        childrenAges,
      });
    }

    const data = {
      hotelCity: hotelCity,
      roomGuests: totalGuests,
      hotelCheckInDate: hotelCheckInDate,
      hotelCheckOutDate: hotelCheckOutDate,
    };

    window.localStorage.setItem("hotelDetails", JSON.stringify(data));
    window.localStorage.setItem(
      "modifySearchHotelDetails",
      JSON.stringify(data)
    );

    dispatch(updateHotelFilters(data));
    let isEveryThingOk = true;
    if (
      Object.keys(hotelFilterState.cities).length == 1 &&
      !hotelFilterState.checkInDate &&
      !hotelFilterState.checkOutDate
    ) {
      setShowCityEnterError(true);
      setShowCheckInDateError(true);
      setShowCheckOutDateError(true);
      isEveryThingOk = false;
    } else if (oldCheckInDate < currentDate && oldCheckOutDate < currentDate) {
      setShowCheckInDateError(true);
      setShowCheckOutDateError(true);
      isEveryThingOk = false;
    } else if (oldCheckInDate < currentDate) {
      setShowCheckInDateError(true);
      isEveryThingOk = false;
    } else if (oldCheckOutDate < currentDate) {
      setShowCheckOutDateError(true);
      isEveryThingOk = false;
    } else if (!Object.keys(hotelFilterState.cities).length) {
      setShowCityEnterError(true);
      isEveryThingOk = false;
    } else if (!hotelFilterState.checkInDate) {
      setShowCityEnterError(false);
      setShowCheckInDateError(true);
      isEveryThingOk = false;
    } else if (!hotelFilterState.checkOutDate) {
      setShowCityEnterError(false);
      setShowCheckInDateError(false);
      setShowCheckOutDateError(true);
      isEveryThingOk = false;
    }
    if (isEveryThingOk) {
      let adultCounter = 0;
      let childrenCounter = 0;
      const numberOfRooms = totalGuests?.length;
      totalGuests?.map((room) => {
        adultCounter += room?.adultCounter;
        childrenCounter += room?.childrenCounter;
      });
      const { _id, alias, cityId, country, name } = hotelCity;
      const uuid = localStorage.getItem("uuid");
      navigate(
        `/hotel/search?hi=${hotelCheckInDate}&hco=${hotelCheckOutDate}&tg=${JSON.stringify(
          totalGuests
        )}&hc=${JSON.stringify(hotelCity)}&sharedUUId=${uuid}`,
        { state: data }
      );
      eventBus.emit("close-dialog");
      // navigate("/hotel/search", { state: data });
      localStorage.removeItem("hotelGuestDetails");
      dispatch(
        updatePriceSorting({
          value: "",
        })
      );
    }
  };

  const updateCities = (payload) => {
    setHotelFilterState((prev) => ({ ...prev, cities: payload }));
    dispatch(updateHotelCity(payload.from));
  };

  const handleShowPersonSelector = () => {
    setShowPersonSelector(!showPersonSelector);
  };
  const handleCheckInDateChange = (checkInDate) => {
    setHotelFilterState((prev) => ({
      ...prev,
      checkInDate: checkInDate,
    }));
    dispatch(
      updateHotelDates({
        checkInDate: checkInDate.formattedValue,
        checkOutDate: "",
      })
    );
  };

  const handleCheckOutDateChange = (checkOutDate) => {
    setHotelFilterState((prev) => ({
      ...prev,
      checkOutDate: checkOutDate,
    }));
    dispatch(
      updateHotelDates({
        checkOutDate: checkOutDate.formattedValue,
        checkInDate: hotelCheckInDate,
      })
    );
  };

  const formatDate = (rawDate) => {
    const formattedValue = moment(rawDate).format("DD/MM/YYYY");
    return formattedValue;
  };

  const formattedRoomsValue = (roomDetails) => {
    if (roomDetails) {
      const noOfRooms = roomDetails.length;
      const roomsLabel =
        roomDetails.length > 1
          ? `${noOfRooms} ${t("hotelSearchWidgetMobile.rooms")}`
          : `${noOfRooms} ${t("hotelSearchWidgetMobile.room")}`;
      const adultCounter = roomDetails.reduce(
        (acc, curr) => acc + curr.adultCounter,
        0
      );
      const adultsLabel = `${adultCounter} ${t(
        "hotelSearchWidgetMobile.adults"
      )}`;
      const childrenCounter = roomDetails.reduce(
        (acc, curr) => acc + curr.childrenCounter,
        0
      );
      const childrenLabel = `${childrenCounter} ${t(
        "hotelSearchWidgetMobile.children"
      )}`;
      const finalLabel = `${roomsLabel}, ${adultsLabel}, ${childrenLabel}`;
      return finalLabel;
    } else {
      return "";
    }
  };

  useEffect(() => {
    addDaysToDate(hotelCheckInDate);
  }, [hotelCheckInDate]);

  const addDaysToDate = (inputDate) => {
    const dateParts = inputDate.split("/");
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    const maxDaysInMonth = new Date(year, month, 0).getDate();

    let newDay = day + 1;
    let newMonth = month;
    let newYear = year;

    if (newDay > maxDaysInMonth) {
      newDay -= maxDaysInMonth;
      newMonth++;

      if (newMonth > 12) {
        newMonth = 1;
        newYear++;
      }
    }

    const formattedDay = String(newDay).padStart(2, "0");
    const formattedMonth = String(newMonth).padStart(2, "0");

    setMinDate(`${formattedDay}/${formattedMonth}/${newYear}`);
  };

  return (
    <div className={styles.mobileHotelFilter}>
      <div
        className="selectCity"
        onClick={() => {
          if (showCityEnterError) {
            setShowCityEnterError(false);
          }
        }}
      >
        <MobileCitySelectionField
          for={"hotel"}
          placeholder={t("hotelSearchWidgetMobile.selectCity")}
          type={"single"}
          onChange={updateCities}
        />
        {showCityEnterError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
            {t("hotelSearchWidgetMobile.selectDestinationCity")}
          </p>
        )}
      </div>
      <div
        className="checkInDate"
        onClick={() => {
          if (showCheckInDateError) {
            setShowCheckInDateError(false);
          }
        }}
      >
        <MobileCalender
          placeholder={t("hotelSearchWidgetMobile.enterCheckInDate")}
          formatDate={formatDate}
          onDateChange={handleCheckInDateChange}
          selectedDate={oldCheckInDate < currentDate ? "" : hotelCheckInDate}
        />
        {showCheckInDateError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
             {t("hotelSearchWidgetMobile.enterCheckInDate")}
          </p>
        )}
      </div>
      <div className={styles.stayNights}>
        <ReactSVG
          style={{ color: "#1B1D52" }}
          src={nightStay}
          beforeInjection={(svg) => {
            svg.classList.add("nightsSvg");
            svg.setAttribute("style", "fill: #1B1D52");
          }}
        />
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate)}{" "}
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate) > 1
          ? t("hotelSearchWidget.nights")
          : t("hotelSearchWidget.night")}
      </div>
      <div
        className="checkOutDate"
        onClick={() => {
          if (showCheckOutDateError) {
            setShowCheckOutDateError(false);
          }

          if (ShowCheckOutMustBeGreaterThanCheckInError) {
            setShowCheckOutMustBeGreaterThanCheckInError(false);
          }
        }}
      >
        <MobileCalender
          placeholder={t("hotelSearchWidgetMobile.enterCheckOutDate")}
          formatDate={formatDate}
          onDateChange={handleCheckOutDateChange}
          selectedDate={oldCheckOutDate < currentDate ? "" : hotelCheckOutDate}
          minDate={minDate}
        />
        {showCheckOutDateError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
            {t("hotelSearchWidgetMobile.enterCheckOutDate")}
          </p>
        )}
      </div>

      <div onClick={handleShowPersonSelector}>
        <PersonSelection
          value={formattedRoomsValue(rooms)}
          placeholder={t("hotelSearchWidgetMobile.roomsAndGuests")}
        />
      </div>
      {showPersonSelector && <HotelTotalPerson />}
      <Button
        onClick={handleClick}
        label={`${t("hotelSearchWidgetMobile.search")}`}
      />
    </div>
  );
};
