import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import BookFlight from "./book-flight";
import BookFlightMobile from "./booking-flight-mobile";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import "./style.css";
import useStyles from "./style";
import { useTranslation } from "react-i18next";

const SelectedDepartureFlight = ({ index, flight, handleChangeFlight }) => {
  const { flightFrom, flightTo } = useSelector(
    (state) => state.searchFlightQuery
  );
  const styles = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <div className={styles.selectedDepartureFlightContainer}>
      <div className={styles.content1}>
        <div className={styles.section1}>
          <div className={styles.selectedDepartureHeading}>
            {t("flightSearch.selectedDepartureFlight")}
          </div>
          {Object.keys(flightFrom[index]).length > 0 &&
            Object.keys(flightTo[index]).length > 0 && (
              <div className={styles.selectedDeparturePlace}>
                {flightFrom?.[index]?.city.split(",")[0]} {t("flightSearch.to")}{" "}
                {flightTo?.[index]?.city.split(",")[0]}
              </div>
            )}
        </div>
        <div
          className={styles.selectedDepartureFlightChangeFlight}
          onClick={() => handleChangeFlight(flight)}
        >
          {t("flightSearch.changeFlight")}
        </div>
      </div>
      {isMobile ? (
        <BookFlightMobile key={flight?.id} flight={flight} />
      ) : (
        <BookFlight key={flight?.id} flight={flight} showButton={false} />
      )}
    </div>
  );
};

export default SelectedDepartureFlight;

SelectedDepartureFlight.propTypes = {
  index: PropTypes.number,
  flight: PropTypes.object,
  handleChangeFlight: PropTypes.func,
};
