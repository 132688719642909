import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import circlecheck from "../../../assets/images/circlecheck.svg";
import oneunselect from "../../../assets/images/1.svg";
import twounselect from "../../../assets/images/2.svg";
import threeunselect from "../../../assets/images/3.svg";
import fourunselect from "../../../assets/images/4.svg";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import useStyles from "./style";
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
      background: "#1b1d52",
      margin: 4,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#1b1d52",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    margin: 4,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#E6EAEE",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#E6EAEE",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#1b1d52",
  }),
  ...(ownerState.completed && {
    background: "#1b1d52",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const iconscomleted = {
    1: <img src={circlecheck} />,
    2: <img src={circlecheck} />,
    3: <img src={circlecheck} />,
    4: <img src={circlecheck} />,
  };
  const iconsinact = {
    1: <img src={oneunselect} />,
    2: <img src={twounselect} />,
    3: <img src={threeunselect} />,
    4: <img src={fourunselect} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed
        ? iconscomleted[String(props.icon)]
        : active
        ? iconsinact[String(props.icon)]
        : iconsinact[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export default function FlightStepper({ activeStep, steps }) {
  const styles = useStyles();
  const language = localStorage.getItem("language");
  return (
    <div className={styles.stepperContainer}>
      <Stack className={styles.stepperStacklayout}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step
              key={label}
              className={language === "AR" ? styles.muiStepper : ""}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <div className={styles.stepperLabel}>{label}</div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </div>
  );
}

FlightStepper.propTypes = {
  activeStep: PropTypes.number,
};
