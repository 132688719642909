import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  citySuggestions: {
    position: "absolute",
    /* width: 392px; */
    height: "312px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "55px",
    overflow: "scroll",
    zIndex: 152,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    width: "100%",
  },

  citySuggestionsMulticity: {
    position: "absolute",
    /* width: 392px; */
    height: "312px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "55px",
    overflow: "scroll",
    zIndex: 152,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    width: "100%",
    // bottom: 0,
    // marginBottom: "15%",
    [theme.breakpoints.down("lg")]: {
      bottom: 0,
      marginBottom: "15%",
    },
  },

  citySuggestionsContain: {
    height: "300px",
    // margin: "16px 0 0 16px",
    // gap: "24px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },

  cityListContainer: {
    display: "flex",
    flexDirection: "column",
  },

  cityListLayout: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0px",

    //   "&:hover": {
    //     backgroundColor: "#EAF4FD",
    //     color: "#1B1D52",
    //     borderRadius: "4px",
    //   },
  },
  cityName: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  countryName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },

  airport: {
    color: "#525A6A",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },

  airportCode: {
    display: "flex",
    padding: "4px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "2px",
    background: "rgb(230, 230, 250)",
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  swapIconWrapper: {
    width: "34px",
    position: "absolute",
    right: "30px",
    top: "calc(50% - 17px)",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid 1b1d52",
    // backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    color: "#fff",
  },

  swapIconWrapperArabic: {
    width: "34px",
    position: "absolute",
    left: "30px",
    top: "calc(50% - 17px)",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid 1b1d52",
    // backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    color: "#fff",
  },
}));

export default useStyles;
