import React from "react";
import { chunk, get, map, split } from "lodash";
import useStyles from "./styles";

const Amenities = (props) => {
  let amenities = get(props, "amenities", []);
  if (amenities.length === 1) {
    amenities = split(amenities[0], ",");
  }
  const [amenitiesList1, amenitiesList2] = chunk(amenities, 6);
  const styles = useStyles();
  return (
    <>
      <div className={styles.hotelAmenities}>
        <h1 className={styles.hotelAmenitiesTitle}>Amenities</h1>
        <div className={styles.hotelAmenitiesListsWrapper}>
          <ul className={styles.hotelAmenitiesList}>
            {map(amenitiesList1, (value) => {
              return (
                <li key={value} className={styles.hotelAmenitiesListItem}>
                  {value}
                </li>
              );
            })}
          </ul>
          <ul className={styles.hotelAmenitiesList}>
            {map(amenitiesList2, (value) => {
              return (
                <li key={value} className={styles.hotelAmenitiesListItem}>
                  {value}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.hotelAmenitiesViewMoreWrapper}>
          {amenities.length > 6 && (
            <button className={styles.hotelAmenitiesViewMore}>
              View All Amenities
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Amenities;
