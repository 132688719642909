import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  packageDetailsMain: {
    borderRadius: "10px",
    background: "#FFF",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "26px",
  },

  ItineraryMainContainer: {
    display: "inline-flex",
    gap: "30px",
    width: "90%",
    borderLeft: "1px solid #1b1d52",
  },

  ItineraryMainContainerArabic: {
    display: "inline-flex",
    gap: "30px",
    width: "90%",
    borderRight: "1px solid #1b1d52",
  },

  ItineraryContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "95%",
    paddingLeft: "30px",
    position: "relative",
  },

  ItineraryContentContainerArabic: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "95%",
    position: "relative",
    paddingRight: "30px",
  },

  ItineraryBluePointers: {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    position: "absolute",
    marginLeft: "-39px",
  },

  ItineraryBluePointersArabic: {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    position: "absolute",
    marginRight: "-39px",
  },

  itineraryDotOpen: {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#FFF",
    border: "2px solid #1b1d52",
    position: "absolute",
    marginLeft: "-39px",
  },

  itineraryDotOpenArabic: {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#FFF",
    border: "2px solid #1b1d52",
    position: "absolute",
    marginRight: "-39px",
  },
}));

export default useStyles;
