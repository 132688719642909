import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "16px",
    background: "#FFF",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  headingContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  heading: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    color: "#344054",
  },
  flightHeading: {
    display: "inline-flex",
    gap: "16px",
  },
  flightDetailInfo: {
    display: "inline-flex",
    gap: "32px",

    [theme.breakpoints.down("sm")]: {
     display:"flex",
     flexDirection: "column",
     gap: "8px"
    },
  },
  dummyContainer: {
    display: "inline-flex", 
    justifyContent: "space-between", 
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  airlineDetailsMobile: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  airlineDetails: {
    display: "inline-flex",
    gap: "16px",
  },
  airlineDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    [theme.breakpoints.down("sm")]: {
      gap: "2px",
    },
  },
  airlineName: {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    color: "#344054",

    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      fontSize: "12px",
    },
  },
  airlineCode: {
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Lato, sans-serif",
    color: "#667085",
  },
  cityDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  city: {
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    color: "#000",
  },
  time: {
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Lato, sans-serif",
    color: "#000",
  },
  durationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
  },
  duration: {
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Lato, sans-serif",
    color: "#667085",
    display: "inline-flex",
    gap: "4px",
  },
  planeContainer: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "center",
  },
  lineDetails: {
    width: "24px",
    height: "1px",
    border: "1px dashed #D0D5DD",
  },
  airportDetail: {
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Lato, sans-serif",
    color: "#667085",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  travellerDetailsContainer: {
    background: "#F5F6F6",
    borderRadius: "3px",
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
  },
  tableHeadingContent: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
  },
  tableBodyContent: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },
  tableContainer: {
    overflowX: "auto",
    width: "100%",
  },
  nameDetailsContainer: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "center",
  },
  nameInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },
  baggageContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
  },
  baggageHeading: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
  },
  baggageContentContainer: {
    display: "inline-flex",
    gap: "24px",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
  },
  baggageContent: {
    display: "inline-flex",
    gap: "8px",
  },
  baggageContentHeading: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
  },
  baggageContentValue: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
  },
}));

export default useStyles;
