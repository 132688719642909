import { createSlice } from "@reduxjs/toolkit";

const flightFiltersSlice = createSlice({
  name: "flightFilters",
  initialState: {
    outboundFilters: {},
    inboundFilters: {},
    clubbedFiltersForSecondCity: {},
    clubbedFiltersForThirdCity: {},
    filteredFlights: [],
    stopsFilter: [],
    priceFilter: [],
    refundFilter: [],
    airlineFilter: [],
    filteredFlightsInbound: [],
    durationFilter: [],
    clearAllFilters: [],
    outBoundDepartureDurationFilter: [],
    outBoundArrivalDurationFilter: [],
    inBoundDepartureDurationFilter: [],
    inBoundArrivalDurationFilter: [],
    sort: true,
    sortInbound: true,
    totalFlights: 0,
    quickFilters: [],
  },
  reducers: {
    handleOutboundFilters: (state, action) => {
      state.outboundFilters = action.payload;
    },
    handleInboundFilters: (state, action) => {
      state.inboundFilters = action.payload;
    },
    handleClubbedFiltersForSecondCity: (state, action) => {
      state.clubbedFiltersForSecondCity = action.payload;
    },
    handleClubbedFiltersForThirdCity: (state, action) => {
      state.clubbedFiltersForThirdCity = action.payload;
    },
    handleFilteredFlights: (state, action) => {
      state.filteredFlights = action.payload;
    },
    handleStopsFilter: (state, action) => {
      state.stopsFilter = action.payload;
    },
    handlePriceFilter: (state, action) => {
      state.priceFilter = action.payload;
    },
    handleRefundFilter: (state, action) => {
      state.refundFilter = action.payload;
    },
    handleAirlineFilter: (state, action) => {
      state.airlineFilter = action.payload;
    },
    handleDurationFilter: (state, action) => {
      state.durationFilter = action.payload;
    },
    handleInboundFilteredFlights: (state, action) => {
      state.filteredFlightsInbound = action.payload;
    },
    handleClearAllFilters: (state) => {
      state.airlineFilter = [];
      state.filteredFlights = [];
      state.stopsFilter = [];
      state.priceFilter = [];
      state.refundFilter = [];
      state.airlineFilter = [];
      state.durationFilter = [];
      state.outBoundDepartureDurationFilter = [];
      state.outBoundArrivalDurationFilter = [];
      state.inBoundDepartureDurationFilter = [];
      state.inBoundArrivalDurationFilter = [];
    },
    handleOutBoundDepartureDurationFilter: (state, action) => {
      state.outBoundDepartureDurationFilter = action.payload;
    },
    handleOutBoundArrivalDurationFilter: (state, action) => {
      state.outBoundArrivalDurationFilter = action.payload;
    },
    handleInBoundDepartureDurationFilter: (state, action) => {
      state.inBoundDepartureDurationFilter = action.payload;
    },
    handleInBoundArrivalDurationFilter: (state, action) => {
      state.inBoundArrivalDurationFilter = action.payload;
    },
    handleTotalFlights: (state, action) => {
      state.totalFlights = action.payload;
    },
    handleQuickFilters: (state, action) => {
      state.quickFilters = action.payload;
    },
    handleSortFilter: (state, action) => {
      state.sort = action.payload;
    },
    handleSortInboundFilter: (state, action) => {
      state.sortInbound = action.payload;
    },
  },
});

export const {
  handleOutboundFilters,
  handleInboundFilters,
  handleClubbedFiltersForSecondCity,
  handleClubbedFiltersForThirdCity,
  handleFilteredFlights,
  handleStopsFilter,
  handleAirlineFilter,
  handlePriceFilter,
  handleRefundFilter,
  handleDurationFilter,
  handleInboundFilteredFlights,
  handleClearAllFilters,
  handleOutBoundDepartureDurationFilter,
  handleOutBoundArrivalDurationFilter,
  handleInBoundDepartureDurationFilter,
  handleInBoundArrivalDurationFilter,
  handleTotalFlights,
  handleQuickFilters,
  handleSortFilter,
  handleSortInboundFilter,
} = flightFiltersSlice.actions;

export default flightFiltersSlice.reducer;
