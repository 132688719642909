import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileHotelFilter: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  stayNights: {
    backgroundColor: '#E6E6FA',
    borderRadius: '1.2rem',
    padding: '0.3rem 0.9rem',
    height: '2.5rem',
    width: '8rem',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  }
}));

export default useStyles;
