import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320, // Common small smartphone width
      xxs: 400, // Common small smartphone width
      sm: 768, // Common smartphone and tablet portrait width
      md: 1024, // Common tablet landscape and small desktop width
      lg: 1370, // Common laptop and larger desktop width
      xl: 1920, // Full HD resolution width
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
  palette: {
    textColors: {
      textColorPrimary: "#344054",
      textColorSecondary: "#525A6A",
    },
    entityColors: {
      entityColorPrimary: "#1b1d52",
      entityColorSecondary: "#667085",
      entityColorBorder: "#D0D5DD",
      entityColorBackground1: "#FFFFFF",
      entityColorBackground2: "#FAFAFA",
      entityColorBackground3: "#E6E6FA",
      entityColorBackgroundLightGreen: "#EBFFE0",
      entityColorBackgroundGreen: "#46991A",
      entityColorBackgroundLightRed: "#FFECDF",
      entityColorBackgroundRed: "#EA6810",
      entityColorBackgroundLightBlue: "#F2F7FB",
      entityColorBackgroundGolden: "#FFA400",
    },
  },
  fontSizes: {
    fontSizeJumbo: "26px",
    fontSizeJumbo2: "32px",
    fontSize0: "24px",
    fontSize1: "16px",
    fontSize2: "14px",
    fontSize3: "12px",
    fontSize4: "20px",
  },
});

export default theme;
