import React, { useEffect, useState, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import SearchIcon from "@mui/icons-material/Search";
import SearchIcon from "../../../../assets/images/search-lg.svg";
import HotelTotalPerson from "./dropdown/total-persons";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { getHotelCities } from "../../../../modules/hotels";
import { useDispatch, useSelector } from "react-redux";
import {
  addSearchedHotels,
  updateHotelCity,
  updateHotelDates,
} from "../../../../utils/slices/searchHotelSlice";
import TravelDate from "../cards/travel-date";
import {
  formatDate,
  formatDateToDdMmYyyy,
  formatDateToDdMmmYyyy,
  formatDay,
  formatDayOfFormattedDate,
} from "../../../../utils/helpers";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import {
  addModifySearchedHotels,
  updateModifySearchHotelObject,
} from "../../../../utils/slices/modifySearchHotelSlice";
import { updateModifyHotelRoomObject } from "../../../../utils/slices/modifyHotelRoomSlice";
import { updateHotelFilters } from "../../../../utils/slices/globalFiltersSlice";
import eventBus from "../../../../core/eventBus";
import { ReactSVG } from "react-svg";
import useStyles from "./styles";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Calendar } from "react-date-range";
import { useTranslation } from "react-i18next";
import nightstay from '../../../../../src/assets/images/nights_stay.svg'
import { calculateNumberOfNights } from "../../../../utils/helpers";
import * as locales from 'react-date-range/dist/locale';
import { convertToArabicDateInDDMMYYY } from "../../../../utils/helpers";
import { getArabicDayName } from "../../../../utils/helpers";

console.log('locales', locales)
const HotelSearchWidget = ({
  setOpenModal,
  setModifySearchEnabled,
  setShowFilters,
}) => {
  const calendarRef = useRef();
  const travelDateRef = useRef();
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const dropdownContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { hotelCity, hotelCheckInDate, hotelCheckOutDate } = useSelector(
    (state) => state.searchHotel
  );

  // console.log("hotelCity", hotelCity);
  const { rooms } = useSelector((state) => state.hotelRooms);
  const adultCounter =
    rooms && rooms.reduce((acc, curr) => acc + curr.adultCounter, 0);
  const childrenCounter =
    rooms && rooms.reduce((acc, curr) => acc + curr.childrenCounter, 0);

  // const searchHotels = useState((state)=>{ state.searchHotel})
  const searchHotel = useSelector((state) => state.searchHotel);
  const [city, setCity] = useState("");
  const [hotelCities, setHotelCities] = useState([]);
  const [showHotelCitiesOptions, setShowHotelCitiesOptions] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showRoundCalendar, setShowRoundCalendar] = useState(false);
  const [checkOutDate, setCheckOutDate] = useState(
    formatDateToDdMmmYyyy(hotelCheckOutDate)
  );
  const [checkOutDay, setCheckOutDay] = useState(
    formatDayOfFormattedDate(hotelCheckOutDate)
  );
  const [checkInDate, setCheckInDate] = useState(
    formatDateToDdMmmYyyy(hotelCheckInDate)
  );
  console.log('hotelCheckInDate', hotelCheckInDate)
  console.log('checkInDate', checkInDate)
  const [checkInDay, setCheckInDay] = useState(
    formatDayOfFormattedDate(hotelCheckInDate)
  );

  const [checkInParts, setCheckInParts] = useState();

  useEffect(() => {
    if (hotelCheckInDate) {
      const inputDateString = hotelCheckInDate;
      const date = moment(inputDateString, "DD/MM/YYYY");
      const formattedDate = date.toDate();
      const check = formatDateToDdMmYyyy(formattedDate);
      const checkInDateParts = check.split("/");
      const checkInDay = parseInt(checkInDateParts[0], 10);
      const checkInMonth = parseInt(checkInDateParts[1], 10) - 1;
      const checkInYear = parseInt(checkInDateParts[2], 10);
      const checkIntime = new Date(checkInYear, checkInMonth, checkInDay);
      const todayDatetime = new Date();
      const checkInDayComponent = checkIntime.getDate();
      const checkInMonthComponent = checkIntime.getMonth();
      const checkInYearComponent = checkIntime.getFullYear();
      const todayDayComponent = todayDatetime.getDate();
      const todayMonthComponent = todayDatetime.getMonth();
      const todayYearComponent = todayDatetime.getFullYear();
      if (
        checkInYearComponent < todayYearComponent ||
        (checkInYearComponent === todayYearComponent &&
          (checkInMonthComponent < todayMonthComponent ||
            (checkInMonthComponent === todayMonthComponent &&
              checkInDayComponent < todayDayComponent)))
      ) {
        setCheckInDate("");
        setCheckInParts("");
        setCheckOutDate("");
      } else {
        const updatedMinDate = addDays(formattedDate, 1);
        const updateMinDateState = formatDateToDdMmYyyy(updatedMinDate);
        setCheckInParts(updateMinDateState.split("/"));
      }
    }
  }, [hotelCheckInDate]);

  useEffect(()=>{
    if(localStorage.getItem("language") == "AR"){
      setCheckInDate(convertToArabicDateInDDMMYYY(hotelCheckInDate))
      setCheckOutDate(convertToArabicDateInDDMMYYY(hotelCheckOutDate))
      setCheckInDay(getArabicDayName(hotelCheckInDate))
      setCheckOutDay(getArabicDayName(hotelCheckOutDate))
    }
  }, [])

  const globalHotelFilters = useSelector(
    (state) => state.globalFilters.hotelFilters
  );

  const [date, setDate] = useState(null);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const [showCheckInError, setShowCheckInError] = useState(false);
  const [showCheckOutError, setShowCheckOutError] = useState(false);
  const [showCityError, setShowCityError] = useState(false);
  const [dateSource, setDateSource] = useState(0);
  const styles = useStyles();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closeDropdown]);

  const handleCheckInDataChange = (item) => {
    if (dateSource == 0) {
      const startDateState = formatDateToDdMmYyyy(item);
      setCheckInDay(formatDay(item));
      setCheckInDate(formatDate(item));
      setCheckOutDay("");
      setCheckOutDate("");
      dispatch(
        updateHotelDates({
          checkOutDate: "",
          checkInDate: startDateState,
        })
      );
      setShowCalendar(false);
      if (calendarRef.current && travelDateRef.current) {
        calendarRef.current.click();
        travelDateRef.current.click();
        setShowCalendar(true);
      }
    } else if (dateSource == 1) {
      const endDateState = formatDateToDdMmYyyy(item);
      setCheckOutDay(formatDay(item));
      setCheckOutDate(formatDate(item));
      dispatch(
        updateHotelDates({
          checkOutDate: endDateState,
          checkInDate: searchHotel?.hotelCheckInDate,
        })
      );
      setShowCalendar(false);
    }
  };


  useEffect(() => {
    handleGetHotelCities();
  }, []);

  const handleGetHotelCities = async (search = "") => {
    const data = await getHotelCities({ limit: 100, skip: 0, search });
    if (data.length > 0) {
      setHotelCities(data);
    }
  };

  const handleCityValue = (id) => {
    setShowCityError(false);
    setCity("");
    const [hotelCity] = hotelCities.filter((hotelCity) => hotelCity._id === id);
    dispatch(updateHotelCity(hotelCity));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (showHotelCitiesOptions &&
          !event.target.closest(".hotel-city-component")) ||
        city
      ) {
        setCity("");
        setShowHotelCitiesOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showHotelCitiesOptions, city]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCalendar && !event.target.closest(".calendar")) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCalendar]);

  const handleSearch = async () => {
    addSearchHotelDetailsToLocalStorage();
    dispatch(addSearchedHotels([]));
    dispatch(addModifySearchedHotels([]));
    // addSearchHotelDetailsToLocalStorage();
    localStorage.removeItem("hotelGuestDetails");
    localStorage.removeItem("contactDetailsForHotel");
    eventBus.emit("close-dialog");
    // if (setOpenModal) {
    //   setOpenModal(false);
    // }
    if (setModifySearchEnabled) {
      // setModifySearchEnabled(false);
    }
    if (setShowFilters) {
      // setShowFilters(false);
    }
  };

  const addSearchHotelDetailsToLocalStorage = () => {
    const totalGuests = [];
    const length = rooms?.length;
    for (let i = 0; i < length; i++) {
      const { adultCounter, childrenCounter, childrenAges } = rooms[i];
      totalGuests.push({
        adultCounter,
        childrenCounter,
        childrenAges,
      });
    }

    const data = {
      hotelCity: hotelCity,
      roomGuests: totalGuests,
      hotelCheckInDate,
      hotelCheckOutDate,
    };

    window.localStorage.setItem("hotelDetails", JSON.stringify(data));
    window.localStorage.setItem(
      "modifySearchHotelDetails",
      JSON.stringify(data)
    );
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time components to midnight
    let checkInParts = hotelCheckInDate.split("/");
    let checkOutParts = hotelCheckOutDate.split("/");
    let oldCheckInDate = new Date(
      checkInParts[2],
      checkInParts[1] - 1,
      checkInParts[0]
    );
    oldCheckInDate.setHours(0, 0, 0, 0)
    let oldCheckOutDate = new Date(
      checkOutParts[2],
      checkOutParts[1] - 1,
      checkOutParts[0]
    );
    oldCheckOutDate.setHours(0, 0, 0, 0)
    if (
      Object.keys(data.hotelCity).length === 0 &&
      !data.hotelCheckInDate &&
      !data.hotelCheckOutDate
    ) {
      setShowCityError(true);
      setShowCheckInError(true);
      setShowCheckOutError(true);
    } else if (oldCheckInDate < currentDate && oldCheckOutDate < currentDate) {
      setShowCheckInError(true);
      setShowCheckOutError(true);
    } else if (oldCheckInDate < currentDate) {
      setShowCheckInError(true);
    } else if (oldCheckOutDate < currentDate) {
      setShowCheckOutError(true);
    } else if (Object.keys(data.hotelCity).length === 0) {
      setShowCityError(true);
    } else if (!data.hotelCheckInDate) {
      setShowCheckInError(true);
    } else if (!data.hotelCheckOutDate) {
      setShowCheckOutError(true);
    } else {
      dispatch(
        updateModifySearchHotelObject({
          hotelCheckInDate,
          hotelCheckOutDate,
          hotelCity,
        })
      );
      dispatch(updateModifyHotelRoomObject({ totalGuests }));
      dispatch(updateHotelFilters(data));
      let adultCounter = 0;
      let childrenCounter = 0;
      const numberOfRooms = totalGuests?.length;
      totalGuests?.map((room) => {
        adultCounter += room?.adultCounter;
        childrenCounter += room?.childrenCounter;
      });
      const { _id, alias, cityId, country, name } = hotelCity;
      const uuid = localStorage.getItem("uuid");
      if (setOpenModal) {
        setOpenModal(false);
      }
      navigate(
        `/hotel/search?hi=${hotelCheckInDate}&hco=${hotelCheckOutDate}&tg=${JSON.stringify(
          totalGuests
        )}&hc=${JSON.stringify(hotelCity)}&sharedUUId=${uuid}`,
        { state: data }
      );

      setShowCityError(false);
      setShowCheckInError(false);
      setShowCheckOutError(false);
    }
  };

  console.log('locales', locales)

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.transform = "rotate(180deg)"
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }else{
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }
  });
  

  return (
    <>
      <div
        className="mainSection"
        style={{
          boxShadow:
            path == "/" ? "0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.16)" : "0",

        }}
      >
        {path != "/" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "30px 30px 0px 30px",
            }}
          >
            <div
              style={{
                color: "#344054",
                fontFamily: "'Lato', sans-serif",
                fontSize: "16px",
                fontSstyle: "normal",
                fontWeight: 700,
              }}
            >
              {t("hotelSearchResult.modifySearch")}
            </div>
            <CloseIcon
              fontSize="medium"
              onClick={() => setOpenModal(false)}
              className={
                localStorage.getItem("language") == "AR"
                  ? styles.closeIconArabic
                  : styles.closeIcon
              }
            ></CloseIcon>
          </div>
        )}

        <div
          className="sectionHotel"

        >
          <div className="search-hotels">
            <div className="title">{t("hotelSearchWidget.destination")}</div>
            <div className="input-search-hotel">
              <input
                type="text"
                className="text hotel-city-component"
                placeholder={t("hotelSearchWidget.whereAreYouGoing")}
                value={
                  hotelCity && Object.keys(hotelCity).length > 0
                    ? hotelCity.name + ", " + hotelCity.country
                    : city
                }
                onFocus={() => {
                  setShowCityError(false);
                  setShowHotelCitiesOptions(true);
                }}
                onChange={(e) => {
                  setCity(e.target.value);
                  handleGetHotelCities(e.target.value);
                  dispatch(updateHotelCity({}));
                }}
              />
              {showHotelCitiesOptions && (
                <div className="hotel-class-options-group">
                  <div className="hotel-class-option-group-card">
                    {hotelCities &&
                      hotelCities.map((hotelcity, index) => {
                        const { name, alias, _id, country } = hotelcity;

                        if (index < 15) {
                          return (
                            <div
                              className="hotel-class-option"
                              key={_id}
                              onClick={() => handleCityValue(_id)}
                            >
                              <div
                                className="hotel-text-box"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexDirection: "row",
                                }}
                              >
                                <span>{name}</span>{" "}
                                <span>
                                  {", "} {country}
                                </span>
                              </div>
                              <div className="hotel-code">
                                <div>{alias}</div>
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              )}
            </div>
            {showCityError && (
              <p
                style={{
                  color: "red",
                  position: "relative",
                  top: "48px",
                  left: "5px",
                  fontSize: "14px",
                }}
              >
                {t("hotelSearchWidget.enterDestinationCity")}
              </p>
            )}
          </div>
          <div
            style={{ flex: 0.75 }}
            className="calendar"
            onClick={() => setDateSource(0)}
          >
            <TravelDate
              title={t("hotelSearchWidget.checkIn")}
              metaTitle={t("hotelSearchWidget.selectCheckInDate")}
              enableDateRange={true}
              date={checkInDate}
              day={checkInDay}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              setShowCheckInError={setShowCheckInError}
              setShowCheckOutError={setShowCheckOutError}
              sentFrom={"hotel"}
              onClick={() => {
                setShowCheckInError(false);
                setShowCheckOutError(false);
                setShowCalendar(!showCalendar);
              }}
            />
            {showCheckInError && (
              <p style={{ color: "red", fontSize: "14px " }}>
                {t("hotelSearchWidget.enterCheckInDate")}
              </p>
            )}
          </div>
          <div className="stay-nights">
            <ReactSVG
              style={{ color: "#1B1D52" }}
              src={nightstay}
              beforeInjection={(svg) => {
                svg.classList.add('nightsSvg')
                svg.setAttribute('style', 'fill: #1B1D52')
              }} />
            {calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate)} {" "}
            {calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate) > 1 ?
              // "Nights" 
              t("hotelSearchWidget.nights")
              :
              // "Night"
              t("hotelSearchWidget.night")
            }
          </div>
          <div
            ref={calendarRef}
            style={{ flex: 0.75 }}
            className="calendar"
            onClick={() => setDateSource(1)}
          >
            <TravelDate
              title={t("hotelSearchWidget.checkOut")}
              metaTitle={t("hotelSearchWidget.selectCheckOutDate")}
              enableDateRange={true}
              date={checkOutDate}
              day={checkOutDay}
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              setShowCheckInError={setShowCheckInError}
              setShowCheckOutError={setShowCheckOutError}
              sentFrom={"hotel"}
              onClick={() => {
                setShowCheckInError(false);
                setShowCheckOutError(false);
                setShowCalendar(true);
              }}
              travelRef={travelDateRef}
            />
            {showCheckOutError && (
              <p style={{ color: "red", fontSize: "14px" }}>
                {t("hotelSearchWidget.enterCheckOutDate")}
              </p>
            )}
          </div>
          <div
            className="user-information"
            style={{ cursor: "pointer" }}
            ref={dropdownContainerRef}
            onClick={toggleDropdown}
          >
            <div className="dropdown">
              <div className="content1">
                <label className="dropdown-title">
                  {t("hotelSearchWidget.roomsAndGuests")}
                </label>
                <KeyboardArrowDownIcon className="dropdown-icon" />
              </div>
              <div className="content2">
                <div style={{ cursor: "pointer" }}>
                  {rooms.length > 1 ? (
                    <>
                      {rooms.length}{" "}
                      <span className="text">
                        {" "}
                        {t("hotelSearchWidget.rooms")}{" "}
                      </span>{" "}
                    </>
                  ) : (
                    <>
                      {rooms.length}{" "}
                      <span className="text">
                        {" "}
                        {t("hotelSearchWidget.room")}{" "}
                      </span>
                    </>
                  )}
                  {adultCounter > 1 ? (
                    <>
                      {" "}
                      {adultCounter}{" "}
                      <span className="text">
                        {" "}
                        {t("hotelSearchWidget.adults")}
                      </span>{" "}
                    </>
                  ) : (
                    <>
                      {adultCounter}{" "}
                      <span className="text">
                        {" "}
                        {t("hotelSearchWidget.adult")}
                      </span>
                    </>
                  )}
                  {childrenCounter > 0 ? (
                    childrenCounter > 1 ? (
                      <div>
                        {" "}
                        {childrenCounter}{" "}
                        <span className="text">
                          {" "}
                          {t("hotelSearchWidget.children")}
                        </span>{" "}
                      </div>
                    ) : (
                      <div>
                        {childrenCounter}{" "}
                        <span className="text">
                          {" "}
                          {t("hotelSearchWidget.child")}
                        </span>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {isDropdownOpen && (
                  <div
                    className="dropdown-content"
                    style={{ zIndex: "1000000", background: "#fff" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <HotelTotalPerson />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="search-hotel-button" onClick={() => handleSearch()}>
            <ReactSVG src={SearchIcon} />
          </div>
        </div>

        {showCalendar && (
          <div
            className="calendarWrapHotel calendar hotelCalenderWrap"
            style={{
              marginLeft: dateSource == 0 ? "450px" : "620px",

              marginRight:
                localStorage.getItem('language') == "AR" && dateSource == 0
                  ? "30vw"
                  : localStorage.getItem('language') == "AR"
                    ? "40vw"
                    : "0px"
            }}
          >
            <div className="range-calendar">
              <Calendar
                onChange={(item) => {
                  setDate(item);
                  handleCheckInDataChange(item);
                }}
                date={date}
                showSelectionPreview={true}
                showMonthAndYearPickers={false}
                locale={
                  localStorage.getItem("language") == "AR"
                    ? locales["arSA"]
                    : locales["enUS"]
                }
                minDate={
                  dateSource == 0
                    ? new Date()
                    : checkInParts
                      ? new Date(
                        parseInt(checkInParts[2], 10),
                        parseInt(checkInParts[1], 10) - 1,
                        parseInt(checkInParts[0], 10)
                      )
                      : new Date()
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HotelSearchWidget;
