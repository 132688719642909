import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelDetailsImages: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "2fr 1fr 1fr",
    gridTemplateRows: "repeat(2,minmax(120px,220px))",
    gap: "4px",
    gridTemplateAreas: `"firstImage secondImage thirdImage"
    "firstImage fourthImage fifthImage"
    `,
  },
  hotelDetailsImagesFirst: {
    gridArea: "firstImage",
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  hotelDetailsImagesSecond: {
    gridArea: "secondImage",
    width: "100%",
    height: "100%",
  },
  hotelDetailsImagesThird: {
    gridArea: "thirdImage",
    width: "100%",
    height: "100%",
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  hotelDetailsImagesFourth: {
    gridArea: "fourthImage",
    width: "100%",
    height: "100%",
  },
  hotelDetailsImagesFifth: {
    gridArea: "fifthImage",
    width: "100%",
    height: "100%",
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  hotelDetailsImagesButton: {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    padding: "16px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  hotelDetailsImagesCarousel: {
    width: "100%",
    height: "100%",
    maxHeight: "300px",
  },
  allImagesContainer: {
    height: "600px",
    width: "700px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    paddingBottom: "20px",
    maxHeight: "80%",
    // scrollbarColor: "unset",
    // overflow: "hidden"
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
    overflowY: "hidden",
    [theme.breakpoints.down("lg")]: {
      width: "1000px",
    },
  },
}));

export default useStyles;
