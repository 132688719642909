import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "16px",
    background: "#FFF",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "Lato, sans-serif",
    color: "#344054",
  },
  metaHeading: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    color: "#525A6A",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  content: {
    display: "inline-flex",
    gap: "8px",
    alignItems: "center",
  },
  info: {
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    color: "#344054",
  },
  icon: {
    marginTop: "4px",
  },
  optionsText: {
    color: "#4037acd6",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Lato, sans-serif",
    display: "inline-flex",
    gap: "8px",
    justifyContent: "flex-end",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 24px",
    backgroundColor: "#fff",
    marginTop: "18%",
    marginLeft: "34%",
    width: "32%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "none",

    [theme.breakpoints.down("sm")]: {
      width: "70%",
      marginTop: "35%",
      marginLeft: "15%",
    },
  },
}));

export default useStyles;
