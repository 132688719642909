import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  packageFlipCard: {
    backgroundColor: "transparent",
    width: "100%",
    height: "400px",
    perspective: "1000px",
    borderRadius: "10px",
    margin: "10px",
    cursor: "pointer",
  },
  packageFlipCardInner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.8s",
    transformStyle: "preserve-3d",
    borderRadius: "10px",
  },
  rotateCard: {
    transform: "rotateY(180deg)",
  },
  packageFlipCardImage: {
    borderRadius: "10px",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
  },
  packageFlipCardFront: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
  },
  packageFlipCardFrontHotelDetails: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    textAlign: "left",
  },
  packageFlipCardFrontHotelDetailsHidden: {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    textAlign: "left",
    visibility: "hidden",
  },
  packageHotelDetailsTitle: {
    color: theme.palette.entityColors.entityColorBackground1,
    fontSize: theme.fontSizes.fontSize1,
    fontWeight: "500",
  },
  packageHotelDetailsPrice: {
    color: theme.palette.entityColors.entityColorBackground1,
    fontSize: theme.fontSizes.fontSize4,
    fontWeight: "800",
  },
  packageFlipCardBack: {
    position: "absolute",
    width: "100%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    textAlign: "left",
    height: "100%",
    backfaceVisibility: "hidden",
    padding: "32px 16px",
    transform: "rotateY(180deg)",
    border: " 1px solid #D0D5DD",
  },
  packageFlipCardFrontCity: {
    position: "absolute",
    top: "20px",
    left: "20px",
    textTransform: "capitalize",
    padding: "12px 8px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    fontSize: theme.fontSizes.fontSize2,
    backfaceVisibility: "hidden",
  },
  packageFlipCardFrontCityHidden: {
    position: "absolute",
    top: "20px",
    left: "20px",
    textTransform: "capitalize",
    padding: "12px 8px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    fontSize: theme.fontSizes.fontSize2,
    backfaceVisibility: "hidden",
    visibility: "hidden",
  },

  packageFlipCardBackTitle: {
    fontSize: theme.fontSizes.fontSize1,
    fontWeight: "700",
    display: "-webkit-Box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    paddingBottom: "2px",
    color: theme.palette.textColors.textPrimaryColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.fontSizes.fontSize2,
    },
  },
  packageFlipCardBackDescWrapper: {
    width: "100%",
    lineHeight: "1.4",
    display: "-webkit-Box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    paddingBottom: "4px",
  },
  packageFlipCardBackDesc: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "500",
    color: theme.palette.textColors.textPrimaryColor,
  },
  packageDetails: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    columnGap: "8px",
    marginTop: "16px",
  },
  packageDetail: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  packageDetailLabelIcon: {
    fontSize: "20px !important",
  },
  packageDetailLabel: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "500",
    color: theme.palette.textColors.textPrimaryColor,
  },
  verticalDivider: {
    width: "1px",
    height: "80%",
    backgroundColor: theme.palette.entityColors.entityColorBorder,
  },
  packageDetailBenefits: {
    display: "flex",
    columnGap: "8px",
    marginTop: "8px",
    alignItems: "center",
    justifyContent: "left",
  },
  packageDetailBenefit: {
    padding: "8px",
    maxWidth: "100px",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "rgba(0,0,0,0.1)",
    fontSize: theme.fontSizes.fontSize3,
  },
  packageDetailItineraries: {
    marginTop: "8px",
    fontSize: theme.fontSizes.fontSize2,
    listStylePosition: "inside",
    height: "100px",
    overflow: 'hidden'
  },
  packageDetailPriceWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
    alignSelf: "flex-end",
  },
  packageDetailPrice: {
    fontSize: theme.fontSizes.fontSize1,
    color: theme.palette.entityColors.entityColorPrimary,
  },
  packageDetailPriceLabel: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textSecondaryColor,
    fontWeight: "400",
  },

  priceContainer: {
    display: "flex",
    gap: "10px",
  },

  packageDetailDiscountedPrice: {
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "Lato",
    fontWeight: 500,
    textDecoration: "line-through",
    color: "#667085",
  },
}));

export default useStyles;
