import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelAdultPersonalDetailsForm: {
    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2,minmax(200px,1fr))",
      gridTemplateRows: "1fr",
      gap: "16px",
    },
    gap: "16px",
  },
}));

export default useStyles;
