import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  multiCityCitySearch: {
    display: "flex",
    flexDirection: "column",
    flex: 2.6,

    [theme.breakpoints.up("lg")]: {
      flex: 2.7,
    },
  },
}));

export default useStyles;
