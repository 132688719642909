import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelSearchQuickFilters: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  hotelSearchQuickFiltersTitle: {
    fontSize: theme.fontSizes.fontSize1,
    fontWeight: "600",
    color: theme.palette.textColors.textColorPrimary,
  },
  hotelSearchQuickFiltersChips: {
    display: "flex",
    columnGap: "8px",
  },
  hotelSearchQuickFiltersSortTab: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    padding: "16px",
    borderRadius: "2px",
    boxShadow: " 0px 4px 16px 0px rgba(0, 0, 0, 0.08);",
    gap: "30px",
    justifyContent: "space-between",
  },
  hotelSearchQuickFiltersSortTabOption: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
    cursor: "pointer",
    color: theme.palette.textColors.textColorPrimary,
  },

  hotelSearchQuickFiltersSortTabOptionSelected: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
    cursor: "pointer",
    color: '#5F57B9'
  },
}));

export default useStyles;
