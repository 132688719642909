import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  mainRootContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: '100vw',
    padding: '10px 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '10px 50px',
      paddingBottom: '50px',

    },
    [theme.breakpoints.up('md')]: {
      padding: '10px 150px',
      paddingBottom: '50px',

    },
    paddingBottom: '50px'
  }
}));

export default useStyles;
