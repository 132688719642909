import React from "react";
import { useSelector } from "react-redux";

export const withGlobalFilters = (Component)=>{

  const Wrapper =(props)=>{
    const globalFilters = useSelector(state=>state.globalFilters);
    return <Component {...props} globalFilters={globalFilters}/>;
  };

  Wrapper.displayName= "WithGlobalFilters";
  return Wrapper;

};
