import { createSlice } from "@reduxjs/toolkit";

const searchFlightQuerySlice = createSlice({
  name: "searchFlightQuery",
  initialState: {
    flightTravelType: Number(1),
    flightType: "",
    flightFrom: [{}, {}, {}],
    flightTo: [{}, {}, {}],
    flightDepartureDate: "",
    flightReturnDate: "",
    flightClassType: Number(2),
    multiCityDates: [],
    flightTotalTravellers: {
      totalCount: 1,
      flightAdultsCount: 1,
      flightChildrenCount: 0,
      flightInfantsCount: 0,
    },
    querySearchApplicable: true,
  },
  reducers: {
    updateQueryTravellersCount: (state, action) => {
      const { totalTravellers, adultCount, childrenCount, infantCount } =
        action.payload;
      Object.assign(state.flightTotalTravellers, {
        totalCount: totalTravellers,
        flightAdultsCount: adultCount,
        flightChildrenCount: childrenCount,
        flightInfantsCount: infantCount,
      });
    },
    updateQueryFlightClassType: (state, action) => {
      state.flightClassType = action.payload;
    },
    updateQueryFlightTravelType: (state, action) => {
      state.flightTravelType = action.payload;
    },
    updateQueryFlightType: (state, action) => {
      state.flightType = action.payload;
    },
    updateQueryFlightTo: (state, action) => {
      const { id, data } = action.payload;
      state.flightTo[id] = data;
    },
    updateQueryFlightFrom: (state, action) => {
      const { id, data } = action.payload;
      state.flightFrom[id] = data;
    },
    updateQueryFlightDates: (state, action) => {
      const { departureDate, returnDate } = action.payload;
      state.flightDepartureDate = departureDate;
      state.flightReturnDate = returnDate;
    },
    handleQueryMultiCityDates: (state, action) => {
      state.multiCityDates = action.payload;
    },
    handleQuerySearchApplicable: (state, action) => {
      state.querySearchApplicable = action.payload;
    },
  },
});

export const {
  updateQueryTravellersCount,
  updateQueryFlightClassType,
  updateQueryFlightTravelType,
  updateQueryFlightType,
  updateQueryFlightTo,
  updateQueryFlightFrom,
  updateQueryFlightDates,
  handleQueryMultiCityDates,
  handleQuerySearchApplicable,
} = searchFlightQuerySlice.actions;

export default searchFlightQuerySlice.reducer;
