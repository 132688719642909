import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    justifyContent: "center",
    display: "flex",
  },

  stepperStacklayout: {
    width: "60%",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  stepperLabel: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  muiStepper: {
    "&:nth-child(1)": {
      order: "1",
    },
    "&:nth-child(3)": {
      order: "-2",
    },
    "&:nth-child(4)": {
      order: "-3",
    },
  },
}));

export default useStyles;
