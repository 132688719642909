import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty, get, reduce } from "lodash";
import moment from "moment";
import { SearchPageHeader } from "../../containers/SearchPageHeader";
import useStyles, { useHeaderLeftViewStyles } from "./styles";
import { HotelSearchFilters } from "../../containers/HotelSearchFilters";
import { HotelQuickFilters } from "../../containers/HotelQuickFilters";
import { HotelListing } from "../../containers/HotelListing";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import location from "../../../assets/images/location.png";
import arrow from "../../../assets/images/arrow-3.png";
import percentage from "../../../assets/images/percentage-circlepercentageCircle.png";
import star from "../../../assets/images/Style=linearstar.png";
import { Box } from "@mui/system";
import { ReactSVG } from "react-svg";
import { Typography } from "@mui/material";
import filterIcon from "../../../assets/images/arrow-3.svg";
import sortIcon from "../../../assets/images/sort.svg";
import eventBus from "../../../core/eventBus";
import { updatePriceSorting } from "../../../utils/slices/localFiltersSlice";
import { useDispatch } from "react-redux";
import ModifySearchHotelMobile from "../../components/modifySearchMobile";
import SearchingSortHotelMobile from "../../views/HotelListing/hotelSortByMobile";
import { useTranslation } from "react-i18next";
import { calculateNumberOfNights } from "../../../utils/helpers";
import nightStay from "../../../assets/images/nights_stay.svg";
import { formatDateToArabic } from "../../../utils/helpers";
const HeaderLeftView = (props) => {
  // const hotelFilters = get(props, "filterValues.hotelFilters", {});
  const { t } = useTranslation();
  const hotelFilters = get(props, "filterValues", {});
  let checkInDate = moment(
    get(hotelFilters, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  ).format("dddd, DD/MM");
  if(localStorage.getItem("language") == "AR"){
    checkInDate = formatDateToArabic(get(hotelFilters, "hotelCheckInDate", ""))
  }
  let checkOutDate = moment(
    get(hotelFilters, "hotelCheckoutDate", ""),
    "DD/MM/YYYY"
  ).format("dddd, DD/MM");
  if(localStorage.getItem("language") == "AR"){
    checkOutDate = formatDateToArabic(get(hotelFilters, "hotelCheckoutDate", ""))
  }
  const hotelCheckInDate = moment(
    get(hotelFilters, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  ).format("DD/MM/YYYY");
  const hotelCheckoutDate = moment(
    get(hotelFilters, "hotelCheckoutDate", ""),
    "DD/MM/YYYY"
  ).format("DD/MM/YYYY");
  const cityName = get(hotelFilters, "hotelCity.name", "");
  const countryName = get(hotelFilters, "hotelCity.country", "");
  const rooms = get(hotelFilters, "totalGuests", []);
  const adultsAndChildCount = reduce(
    rooms,
    (accumulator, booking) => {
      accumulator.totalAdults += booking.adultCounter;
      accumulator.totalChildren += booking.childrenCounter;

      return accumulator;
    },
    { totalAdults: 0, totalChildren: 0 }
  );
  const childrenCount = get(adultsAndChildCount, "totalChildren", 0);
  const leftViewStyles = useHeaderLeftViewStyles();
  return (
    <div className={leftViewStyles.wrapper}>
      <div
        className={
          localStorage.getItem("language") == "AR"
            ? leftViewStyles.locationArabic
            : leftViewStyles.location
        }
      >
        <span className={leftViewStyles.locationLabel}>
          {t("hotelSearchResult.location")}
        </span>
        <span className={leftViewStyles.locationValue}>
          {countryName ? `${cityName} , ${countryName}` : cityName}
        </span>
      </div>
      <div className={leftViewStyles.checkIn}>
        <span className={leftViewStyles.checkInLabel}>
          {t("hotelSearchResult.checkIn")}
        </span>
        <span className={leftViewStyles.checkInValue}>{checkInDate}</span>
      </div>
      <div className="stay-nights">
        <ReactSVG
          style={{ color: "#1B1D52" }}
          src={nightStay}
          beforeInjection={(svg) => {
            svg.classList.add("nightsSvg");
            svg.setAttribute("style", "fill: #1B1D52");
          }}
        />
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckoutDate)}{" "}
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckoutDate) > 1
          ? t("hotelSearchWidget.nights")
          : t("hotelSearchWidget.nights")}
      </div>
      <div className={leftViewStyles.checkIn}>
        <span className={leftViewStyles.checkInLabel}>
          {t("hotelSearchResult.checkOut")}
        </span>
        <span className={leftViewStyles.checkInValue}>{checkOutDate}</span>
      </div>
      <div className={leftViewStyles.checkIn}>
        <span className={leftViewStyles.checkInLabel}>
          {t("hotelSearchResult.roomAndGuest")}
        </span>
        <span className={leftViewStyles.checkInValue}>
 
          {rooms.length > 1
            ? `${rooms.length} ${t("hotelSearchResult.rooms")}`
            : `${rooms.length} ${t("hotelSearchResult.room")},`}{" "}
          {get(adultsAndChildCount, "totalAdults", 0) > 1
            ? `${get(adultsAndChildCount, "totalAdults", 0)} ${t(
              "hotelSearchResult.adults"
            )}`
            : `${get(adultsAndChildCount, "totalAdults", 0)} ${t(
              "hotelSearchResult.adult"
            )}`}{" "}
          {childrenCount
            ? `, ${childrenCount} ${t("hotelSearchResult.children")}`
            : ""}
        </span>
      </div>
    </div>
  );
};

export const HotelSearchPage = (props) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const styles = useStyles();
  let { state: filtersFromLocation } = useLocation();
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const hotelCheckInDate = searchParams.get("hi"); // Returns "07/11/2023"
  const hotelCheckoutDate = searchParams.get("hco"); // Returns "09/11/2023"
  const hotelCity = JSON.parse(searchParams.get("hc")); // Returns "09/11/2023"
  const totalGuests = JSON.parse(searchParams.get("tg")); // Returns "09/11/2023"

  // {hotelCity}$totalGuests=${totalGuests}`,
  const searchQueryParams = {
    hotelCheckInDate,
    hotelCheckoutDate,
    hotelCity,
    totalGuests,
  };
  filtersFromLocation = searchQueryParams;
  const filtersFromHOC = get(props, "globalFilters", {});
  const [showFilters, setShowFilters] = useState(false);
  const [modifySearchEnabled, setModifySearchEnabled] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const handleLoader = () =>{
  const [selectedStarFilter, setSelectedStarFilter] = useState(false);
  const [selectedPriceFilter, setSelectedPriceFilter] = useState(false);
  // }

  const handleFiltersBottomSheet = () => {
    eventBus.emit("open-bottomSheet", <HotelSearchFilters />);
  };

  const handleSortByBottomSheet = () => {
    eventBus.emit("open-bottomSheet", <SearchingSortHotelMobile />);
  };

  const handleSetFilter = () => {
    setSelectedStarFilter(!selectedStarFilter);
  };

  const handlePriceFilter = () => {
    setSelectedPriceFilter(!selectedPriceFilter);
  };

  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, [location]);
  return (
    <div className={styles.hotelSearchPage}>
      {!isMobile && (
        <SearchPageHeader
          leftView={HeaderLeftView}
          // globalFilters={
          //   isEmpty(filtersFromHOC) ? filtersFromLocation : filtersFromHOC
          // }
          globalFilters={filtersFromLocation}
          setModifySearchEnabled={setModifySearchEnabled}
          modifySearchEnabled={modifySearchEnabled}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      )}

      <div className={styles.hotelSearchPageBody}>
        {!isMobile && showFilters && (
          <div className={styles.hotelSearchPageBodyFilters}>
            <HotelSearchFilters />
          </div>
        )}
        <div className={styles.hotelSearchPageBodyListing}>
          {!isMobile && showFilters && <HotelQuickFilters />}
          {isMobile && (
            <div style={{ display: "flex", gap: "6px" }}>
              
            
            </div>
          )}
          <HotelListing
            setShowFilters={setShowFilters}
            showFilters={showFilters}
            modifySearchEnabled={modifySearchEnabled}
           
            globalFilters={filtersFromLocation}
            searchURL={searchURL}
            setModifySearchEnabled={setModifySearchEnabled}
          />

          {isMobile && showFilters && (
            <Box className={styles.bottomButtons}>
              <Box
                className={
                  localStorage.getItem("language") == "AR"
                    ? styles.filterLayoutArabic
                    : styles.filterLayout
                }
                onClick={handleSortByBottomSheet}
              >
                <ReactSVG src={filterIcon} />
                <Typography>{t("flightSearch.sortBy")}</Typography>
              </Box>
              <Box
                className={
                  localStorage.getItem("language") == "AR"
                    ? styles.sortLayoutArabic
                    : styles.sortLayout
                }
                onClick={handleFiltersBottomSheet}
              >
                <ReactSVG src={sortIcon} />
                <Typography>{t("flightSearch.filter")}</Typography>
              </Box>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};
