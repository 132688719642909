import React from "react";
import PlaceholderImage from "../../../../assets/images/no_result_icon_flight.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SessionExpired = ({ message }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100vw",
          minHeight: "90vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactSVG src={PlaceholderImage} />
        <p
          style={{
            fontSize: "16px",
            color: "#344054",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {message}
        </p>
        <div
          style={{
            justifyContent: "flex-end",
            borderRadius: "8px",
            background: "#1b1d52",
            alignItems: "center",
            padding: "10px 12px",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Lato",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <p>{t("hotelDetails.searchAgain")}</p>
        </div>
      </div>
    </>
  );
};

export default SessionExpired;
