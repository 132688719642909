import React, { useEffect, useState } from "react";
import useStyles from "./style";
import TickGif from "../../../../../../../../../assets/images/insurance-tick.gif";
import UserSvg from "../../../../../../../../../assets/images/user.svg";
import PhoneSvg from "../../../../../../../../../assets/images/phone-view.svg";
import EmailSvg from "../../../../../../../../../assets/images/mail-view.svg";
import TicketSvg from "../../../../../../../../../assets/images/ticket-01.svg";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { sendTicketsOrVouchersToMail } from "../../../../../../../../../modules/home";
import { Modal } from "@mui/material";
import LoadingBackdrop from "../../../../../../../../flight-module/cards/loading-backdrop";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile.hook";
import { toast } from "react-toastify";

const ContactInformation = ({ ticket, id, setLoader, loader, bookingStatus }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [desc, setDesc] = useState("");
  const user = useSelector((state) => state.user);
  const {
    ticket: {
      Results: {
        Itinerary: { Passenger },
      },
    },
  } = ticket;
  const { FirstName, LastName, Mobile1, Mobile1CountryCode, Email } =
    Passenger[0];

  const handleSendTicketsOrVouchersToMail = async () => {
    setLoader(true);
    const res = await sendTicketsOrVouchersToMail({
      token: user.token,
      product: "FLIGHTS",
      bookingId: id,
    });

    if (res.statusCode === 200) {
      setLoader(false);
      setShowSuccessModal(true);
      setDesc(`${t("errorMessages.ticketSentOn")} ${Email}!`);
    } else {
      setLoader(false);
      toast.error(res?.message)

      // setShowSuccessModal(true);
      // setDesc(`${"errorMessages.errorTryAgain"}`);
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSuccessModal]);

  const isMobile = useIsMobile()
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.headingContainer}>
          <p className={styles.heading}>{t("myProfile.contactInformation")}</p>
          <p className={styles.metaHeading}>
            {t("myProfile.primaryTravellerInfo")}
          </p>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <ReactSVG src={UserSvg} className={styles.icon} />
            <p className={styles.info}>
              {FirstName.charAt(0).toUpperCase() + FirstName.slice(1)}{" "}
              {LastName.charAt(0).toUpperCase() + LastName.slice(1)}
            </p>
          </div>
          <div className={styles.content}>
            <ReactSVG src={PhoneSvg} className={styles.icon} />
            <p className={styles.info}>{Mobile1}</p>
          </div>
          <div className={styles.content}>
            <ReactSVG src={EmailSvg} className={styles.icon} />
            <p className={styles.info}>{Email}</p>
          </div>
        </div>
        {bookingStatus != "CANCELLED"
          && <p className={styles.optionsText}>
            <ReactSVG
              src={TicketSvg}
              style={{ cursor: "pointer" }}
              onClick={() => handleSendTicketsOrVouchersToMail()}
            />
            <p
              style={{ cursor: "pointer" }}
              onClick={() => handleSendTicketsOrVouchersToMail()}
            >
              {/* Send E-Ticket on Email */}
              {t("myProfile.sendETicket")}
            </p>
          </p>}

      </div>
      {loader ? (
        <LoadingBackdrop open={loader} />
      ) : (
        showSuccessModal && (
          <Modal
            open={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            sx={{ outline: "none" }}
          >
            <div
              className={styles.modalContainer}
              style={{
                marginRight: localStorage.getItem("language") == "AR"
                  ? isMobile
                    ? '15%'
                    : "34%"
                  : "0"
              }}
            >
              <img src={TickGif} />
              <p
                style={{
                  color: "#344054",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {desc}
              </p>
            </div>
          </Modal>
        )
      )}
    </>
  );
};

export default ContactInformation;
