import axios from "axios";
import APIs from "../api";
const config = {
  headers: {
    "Content-Type": "application/json",
    authorization: "0",
  },
};
export const getHotelCities = async ({ limit, skip, search = "" }) => {
  try {
    const {
      data: {
        data: { hotelCities },
      },
    } = await axios.get(
      `${APIs.getHotelCities}?limit=${limit}&skip=${skip}&search=${search}`,
      config
    );
    return hotelCities;
  } catch (error) {
    console.log("Get Hotel Cities API Error ", error);
  }
};
export const searchHotels = async (payload, token = "") => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleSearchHotelsAuth(payload, config);
  } else {
    return handleSearchHotelsNoAuth(payload);
  }
};
const handleSearchHotelsAuth = async (payload, config) => {
  try {
    const {
      data: { data },
    } = await axios.post(`${APIs.searchHotelAuth}`, payload, config);
    return data;
  } catch (error) {
    console.log("Search Hotel Auth API Error: ", error);
    const response = error?.response?.data;
    return response;
  }
};
const handleSearchHotelsNoAuth = async (payload) => {
  try {
    const {
      data: { data },
    } = await axios.post(`${APIs.searchHotel}`, payload);
    return data;
  } catch (error) {
    console.log("Search Hotel No Auth API Error: ", error);
    const response = error?.response?.data;
    return response;
  }
};
export const getHotelDetails = async ({
  hotelCode,
  token = "",
  searchId,
  additionalDetails,
}) => {
  console.log('searchId')
  const payload = {
    // ResultIndex: resultIndex,
    HotelCode: hotelCode,
    searchId: searchId,
    // TraceId: traceId,
    additionalDetails: additionalDetails,
  };
  console.log('paylod', payload)
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleGetHotelDetailsAuth(payload, config);
  } else {
    return handleGetHotelDetailsNoAuth(payload);
  }
};
const handleGetHotelDetailsAuth = async (payload, config) => {
  const { data: response } = await axios
    .post(`${APIs.hotelDetailsAuth}`, payload, config)
    .catch((e) => {
      console.log(
        "Catch error in hotel details Auth API -> ",
        JSON.stringify(e.data)
      );
      const response = e?.response?.data;
      return response;
      // return null;
    });
  return response;
};
const handleGetHotelDetailsNoAuth = async (payload) => {
  const { data: response } = await axios
    .post(`${APIs.hotelDetails}`, payload)
    .catch((e) => {
      console.log(
        "Catch error in hotel details No Auth API -> ",
        JSON.stringify(e.data)
      );
      const response = e?.response?.data;
      return response;
      // return null;
    });
  return response;
};
export const getRoomsDetails = async ({
  hotelCode,
  searchId,
  token = "",
  additionalDetails,
}) => {
  const payload = {
    // ResultIndex: resultIndex,
    HotelCode: hotelCode,
    searchId: searchId,
    additionalDetails: additionalDetails,
  };
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleGetRoomDetailsAuth(payload, config);
  } else {
    return handleGetRoomDetailsNoAuth(payload);
  }
};
const handleGetRoomDetailsAuth = async (payload, config) => {
  const { data: response } = await axios
    .post(`${APIs.hotelRoomsDetailsAuth}`, payload, config)
    .catch((e) => {
      console.log(
        "Catch error in hotel rooms details Auth API -> ",
        JSON.stringify(e.data)
      );
      const response = e?.response?.data;
      return response;
      // return null;
    });
  return response;
};
const handleGetRoomDetailsNoAuth = async (payload) => {
  const { data: response } = await axios
    .post(`${APIs.hotelRoomsDetails}`, payload)
    .catch((e) => {
      console.log(
        "Catch error in hotel rooms details No Auth API -> ",
        JSON.stringify(e?.data)
      );
      // return null;
      const response = e?.response?.data;
      return response;
    });
  return response;
};
export const getHotelBookingDetails = async (token, id, cn) => {
  try {
    const body = {
      "ConfirmationNumber": cn,
      "PaymentMode": "Limit",
      "_id": id
    }
    console.log('body', body)
    const res = await fetch(`${APIs.getHotelBookingDetails}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        authorization: token,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err ->", err);
    const response = err?.response?.data;
    return response;
  }
};
export const cancelHotelBooking = async (token, payload) => {
  try {
    const res = await fetch(`${APIs.cancelHotel}`, {
      method: "POST",
      headers: {
        authorization: token,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err ->", err);
    const response = err?.response?.data;
    return response;
  }
};
export const reissueHotelBooking = async (token, payload) => {
  try {
    const res = await fetch(`${APIs.reissueHotel}`, {
      method: "POST",
      headers: {
        authorization: token,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err ->", err);
    const response = err?.response?.data;
    return response;
  }
};
export const getMarkup = async (markup = "") => {
  try {
    const res = await fetch(`${APIs.getMarkup}?markup=${markup}`, {
      method: "GET",
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("err ->", err);
    const response = err?.response?.data;
    return response;
  }
};

export const addPersonalDetails = async ({ token = "", payload }) => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleAddDetailsAuth(payload, config);
  } else {
    return handleAddDetailsNoAuth(payload);
  }
};

const handleAddDetailsAuth = async (payload, config) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(`${APIs.addDetailsHotelAuth}`, payload, config);

    if (statusCode === 200) {
      return data;
    }
  } catch (error) {
    console.log("Add Hotel Details Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleAddDetailsNoAuth = async (payload) => {
  try {
    const {
      data: { statusCode, data },
    } = await axios.post(`${APIs.addDetailsHotelNoAuth}`, payload);

    if (statusCode === 200) {
      return data;
    }
  } catch (error) {
    console.log("Add Hotel Details No Auth API Error ", error);
  }
};
