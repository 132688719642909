import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

function FrontCard({ item }) {
  const { thumbnail, personPrice, name } = item;
  const city = item?.city?.toLowerCase();
  const styles = useStyles();
  return (
    <div className={styles.frontCard}>
      <img className={styles.frontCardImage} src={`${S3Bucket}/${thumbnail}`} />
    </div>
    // <Box sx={{ position: "absolute", height: "100%", backfaceVisibility: "hidden", }}>
    //   <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
    //     <img src={`${S3Bucket}/${thumbnail}`} style={{ width: "370px", height: "410px", position: "relative" }} />
    //     <Box sx={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "space-between", zIndex: "100", padding: "16px 16px", height: "100%" }}>
    //       <Box sx={{ borderRadius: "5px", background: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", width: "100px" }}>
    //         <Typography sx={{ color: "#000", fontFamily: "Lato, sans-serif", fontSize: "16px", fontWeight: 500, padding: "8px 12px", textTransform: "capitalize" }}>{city?.charAt(0).toUpperCase() + city?.slice(1)}</Typography>
    //       </Box>
    //       <Box>
    //         <Typography fontWeight={500} fontSize='1rem' color='#FFF' fontFamily='Lato'>{name}</Typography>
    //         <Typography fontWeight={800} fontSize='1.25rem' color='#FFF' fontFamily='Lato'>₹{personPrice}</Typography>
    //       </Box>
    //     </Box>
    //   </Box>
    // </Box>
  );
}

export default FrontCard;

FrontCard.propTypes = {
  item: PropTypes.object,
};
