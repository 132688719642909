/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  flightClassData,
  flightClassDataArabic,
} from "../../../../../../config";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { updateFlightClassType } from "../../../../../../utils/slices/searchFlightSlice";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import "./style.css";
import { updateModifyFlightClassType } from "../../../../../../utils/slices/modifySearchFlightSlice";
import useStyles from "./styles";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../../core/eventBus";
import useIsTablet from "../../../../../../hooks/useIsTablet.hook";

const FlightClassOptions = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.flightSelectionOptions}>
      {localStorage.getItem("language") == "AR"
        ? Object.entries(flightClassDataArabic).map(([key, value]) => {
            return (
              <div
                className={styles.flightClassOption}
                key={key}
                onClick={() => {
                  props.onSelection(key);
                }}
              >
                <div>{value}</div>
              </div>
            );
          })
        : Object.entries(flightClassData).map(([key, value]) => {
            return (
              <div
                className={styles.flightClassOption}
                key={key}
                onClick={() => {
                  props.onSelection(key);
                }}
              >
                <div>{value}</div>
              </div>
            );
          })}
    </div>
  );
};

const FlightClass = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { flightClassType } = useSelector((state) => state.modifySearchFlight);

  const [showFlightClassDropdown, setShowFlightClassDropdown] = useState(false);
  const [flightClass, setFlightClass] = useState(flightClassType);

  const handleFlightClassType = (value) => {
    setFlightClass(value);
    dispatch(updateFlightClassType(value));
    dispatch(updateModifyFlightClassType(value));
    setShowFlightClassDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (flightClass && !event.target.closest(".cabin-class")) {
        setShowFlightClassDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showFlightClassDropdown, flightClass]);

  return (
    <>
      {isMobile || isTablet ? (
        <>
          <div
            className={`${styles.flightSelection} cabin-class`}
            onClick={() => {
              setShowFlightClassDropdown(!showFlightClassDropdown);
            }}
          >
            <div className="seat-icon">
              <AirlineSeatReclineExtraIcon />
            </div>
            <div className="flight-class-dropdown">
              <div className="dropdown-value">
                {localStorage.getItem("language") == "AR"
                  ? flightClassDataArabic[Number(flightClass)]
                  : flightClassData[Number(flightClass)]}
              </div>
              <KeyboardArrowDownIcon className="flight-class-dropdown-icon" />
            </div>
          </div>
          {showFlightClassDropdown && (
            <FlightClassOptions
              onSelection={(payload) => handleFlightClassType(payload)}
            />
          )}
        </>
      ) : (
        <div
          className={`${styles.flightSelection} cabin-class`}
          onClick={() => {
            setShowFlightClassDropdown(!showFlightClassDropdown);
          }}
        >
          <div className="seat-icon">
            <AirlineSeatReclineExtraIcon />
          </div>
          <div className="flight-class-dropdown">
            <div className="dropdown-value">
              {localStorage.getItem("language") == "AR"
                ? flightClassDataArabic[Number(flightClass)]
                : flightClassData[Number(flightClass)]}
            </div>
            <KeyboardArrowDownIcon className="flight-class-dropdown-icon" />
          </div>
          {showFlightClassDropdown && (
            <FlightClassOptions
              onSelection={(payload) => handleFlightClassType(payload)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FlightClass;
