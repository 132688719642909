import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#344054",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Lato",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: 600,
    },
  },
}));

export default useStyles;
