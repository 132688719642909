import { configureStore } from "@reduxjs/toolkit";
import searchFlightSlice from "./slices/searchFlightSlice";
import globalSlice from "./slices/globalSlice";
import bookFlightSlice from "./slices/bookedFlightSlice";
import modifySearchFlightSlice from "./slices/modifySearchFlightSlice";
import popupSlice from "./slices/popupSlice";
import userSlice from "./slices/userSlice";
import searchHotelSlice from "./slices/searchHotelSlice";
import searchPackageSlice from "./slices/packageSlice";
import hotelRoomSlice from "./slices/hotelRoomSlice";
import modifySearchHotelSlice from "./slices/modifySearchHotelSlice";
import modifyHotelRoomSlice from "./slices/modifyHotelRoomSlice";
import onewaySlice from "./slices/onewaySlice";
import domesticRoundTripSlice from "./slices/domesticRoundTripSlice";
import internationalRoundTripSlice from "./slices/internationalRoundTripSlice";
import multicitySlice from "./slices/multicitySlice";
import globalFiltersSlice from "./slices/globalFiltersSlice";
import flightFiltersSlice from "./slices/flightFiltersSlice";
import localFiltersSlice from "./slices/localFiltersSlice";
import searchFlightQuerySlice from "./slices/searchFlightQuerySlice";
import hotelSearchingSlice from "./slices/hotelSearchingSlice";
import localeSlice from "./slices/localeSlice";
import rateOfExchangeSlice from "./slices/rateOfExchangeSlice";
import bookingStatusSlice from "./slices/bookingStatusSlice";

const store = configureStore({
  reducer: {
    globalFilters: globalFiltersSlice,
    global: globalSlice,
    searchFlight: searchFlightSlice,
    bookedFlight: bookFlightSlice,
    modifySearchFlight: modifySearchFlightSlice,
    popup: popupSlice,
    user: userSlice,
    searchHotel: searchHotelSlice,
    searchPackage: searchPackageSlice,
    hotelRooms: hotelRoomSlice,
    modifySearchHotel: modifySearchHotelSlice,
    modifyHotelRooms: modifyHotelRoomSlice,
    oneWayTripDetails: onewaySlice,
    domesticRoundTripDetails: domesticRoundTripSlice,
    internationalRoundTripDetails: internationalRoundTripSlice,
    multiCityTripDetails: multicitySlice,
    flightFilters: flightFiltersSlice,
    localFilters: localFiltersSlice,
    searchFlightQuery: searchFlightQuerySlice,
    hotelSearchState: hotelSearchingSlice,
    locale: localeSlice,
    rateOfExchange: rateOfExchangeSlice,
    bookingStatus: bookingStatusSlice,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== "production",
});

export default store;
