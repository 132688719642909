import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Passenger from "../../passenger";
import { useSelector } from "react-redux";
import "./style.css";
import { useTranslation } from "react-i18next";

const TotalTravellers = ({
  showPassengersComponent,
  setShowPassengersComponent,
}) => {
  const {
    flightTotalTravellers: { totalCount },
  } = useSelector((state) => state.modifySearchFlight);

  const closePassengersComponent = () => {
    setShowPassengersComponent(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showPassengersComponent &&
        !event.target.closest(".passenger-component")
      ) {
        setShowPassengersComponent(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPassengersComponent]);

  const getLocale = useSelector((state) => state.language);
  const { t } = useTranslation();

  return (
    <div
      className="total-persons passenger-component"
      onClick={() => setShowPassengersComponent(true)}
    >
      <div className="total-persons-icon">
        <PermIdentityIcon />
      </div>
      <div className="total-persons-dropdown">
        <label className="total-persons-dropdown-label">
          {totalCount > 1 ? (
            <span>
              {totalCount} {t("flightSearchWidget.travellers")}
            </span>
          ) : (
            <span>
              {totalCount} {t("flightSearchWidget.travellers")}
            </span>
          )}
        </label>
        <KeyboardArrowDownIcon className="total-persons-dropdown-icon" />
      </div>
      {showPassengersComponent && (
        <Passenger closePassengersComponent={closePassengersComponent} />
      )}
    </div>
  );
};

export default TotalTravellers;
