import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    width: "100%",
    padding: "40px 150px",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
      maxWidth: "100vw",
    },
  },
  customTable: {
    borderCollapse: "collapse",
    width: "100%",
  },
  customTableHeading: {
    border: "1px solid #D0D5DD",
    padding: "16px",
    width: "50%",
    color: "#344054",
    fontFamily: "'Lato', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  customTableMetaHeading: {
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  customTableColumn: {
    border: "1px solid #D0D5DD",
    padding: "16px",
    width: "50%",
    color: "#344054",
    fontFamily: "'Lato', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  customTableColumnPrice: {
    border: "1px solid #D0D5DD",
    padding: "16px",
    width: "50%",
    color: "#344054",
    fontFamily: "'Lato', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default useStyles;
