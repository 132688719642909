import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainSideBarContainer: {
    borderRadius: "12px",
    background: "#FFF",
    padding: "32px 16px",
    width: "100%",
  },
  innerSideBarContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  profileImageNameAndEmailContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  profileImageContainer: {
    position: "relative",
    display: "inline-block",
  },
  profileImage: {
    width: "104px",
    height: "104px",
    borderRadius: "50%",
    position: "relative",
  },
  avatarCharacterContainer: {
    position: "relative",
  },
  avatarCharacter: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "700",
    width: "100%",
    height: "100%",
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "52px",
    display: "flex",
  },
  uploadPicContainer: {
    width: "32px",
    height: "32px",
    backgroundColor: "#1b1d52",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "absolute",
    bottom: "0",
    right: "0",
    cursor: "pointer",
  },
  nameAndEmailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "12px",
  },
  fullName: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    cursor: "pointer",
    textTransform: "capitalize",
  },
  email: {
    color: "#525A6A",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  profileBookingsAndLogoutContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
  },
  profileContainer: {
    display: "inline-flex",
    gap: "8px",
    borderRadius: "8px",
    backgroundColor: "#E6E6FA",
    padding: "16px 8px",
    width: "100%",
  },
  profileText: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  profile2ndContainer: {
    display: "inline-flex",
    gap: "8px",
    borderRadius: "8px",
    backgroundColor: "#FFFF",
    padding: "16px 8px",
    width: "100%",
  },
  profile2ndText: {
    color: "#000000",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  bookingsContainer: {
    display: "inline-flex",
    gap: "8px",
    borderRadius: "8px",
    backgroundColor: "#E6E6FA",
    padding: "16px 8px",
    width: "100%",
  },
  bookingsText: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  bookings2Container: {
    display: "inline-flex",
    gap: "8px",
    borderRadius: "8px",
    backgroundColor: "#FFF",
    padding: "16px 8px",
    width: "100%",
  },
  bookings2Text: {
    color: "#000000",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  logoutContainer: {
    display: "inline-flex",
    gap: "8px",
    borderRadius: "8px",
    backgroundColor: "#FFF",
    padding: "16px 8px",
    width: "100%",
  },
  logoutText: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
}));

export default useStyles;
