import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  baggageAllowanceText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  baggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  baggageAllowedContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  checkContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  checkContainer2: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cabinBaggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  cabinBaggageContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  baggageAndCabinBaggageContainer:{
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
    width: "240px",
  }
}));

export default useStyles;
