import {
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import useStyles from "./style";
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ReissueSuccess = ({
  reissueSuccessModal,
  setReissueSuccessModal
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const styles = useStyles();
  const navigate = useNavigate();
  const {t} = useTranslation()


  return (
    <Dialog
      fullScreen={fullScreen}
      open={reissueSuccessModal}
      onClose={() => setReissueSuccessModal(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.mainContainer}>
        <DialogContent id="responsive-dialog-title">
          <p className={styles.metaTitle}>
            {/* Re-issue is in progress. Rest assured, we will notify you if your
            hotel is re-issued from our side. */}
            {t("cancellationReissueMessages.hotelReissueSuccess")}
          </p>
        </DialogContent>
        <DialogActions>
          <div
            className={styles.successButton}
            onClick={() => {
              // navigate('/bookings')
              window.location.reload(false)
              setReissueSuccessModal(false)
            }}
          >
            {t("cancellationReissueMessages.close")}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ReissueSuccess;
