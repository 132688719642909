import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { getOffers, getOffersNoAuth } from "../../../modules/home";
import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import copyIcon from "../../../assets/images/copy_icon_blue.svg";
import { ReactSVG } from "react-svg";
import useStyles from "./style";
import { TwoToneTitle } from "../../../ui/components/TwoToneTitle";
import Carousel from "react-elastic-carousel";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import useIsTablet from "../../../hooks/useIsTablet.hook";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import useIsTablet2 from "../../../hooks/useIsTablet.hook2";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const Offers = () => {
  const [offers, setOffers] = useState([]);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = useStyles();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isTablet2 = useIsTablet2();
  const { t } = useTranslation();

  useEffect(() => {
    handleOffers();
  }, []);
  const user = useSelector((state) => state.user);
  const handleScroll = (value) => {
    setIndex(value);
  };

  const handleOffers = async () => {
    const data = await getOffers({ limit: 100, skip: 0, token: user?.token });
    setOffers(data?.offers);

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
  };

  const handleViewAllClick = (htmlContent) => {
    navigate("/offers-details", { state: { htmlContent } });
  };

  const handleCopyClick = (couponCode) => {
    try {
      navigator.clipboard
        .writeText(couponCode)
        .then(() => {
          toast.success(`${t("offers.couponCodeCopied")}`);
        })
        .catch((error) => {
          console.error("Unable to copy coupon code:", error);
        });
    } catch (error) {
      console.error("Clipboard API not available:", error);
    }
  };

  return (
    offers &&
    offers.length > 0 && (
      <div className={style.offer}>
        <div className={style.info}>
          <div className={style.headerLeftSection}>
            <TwoToneTitle
              firstPart={t("offers.exclusive")}
              secondPart={t("offers.offers")}
            />
            <div
              className={style.viewAllOffers}
              onClick={() => navigate("/offers")}
            >
              {t("offers.viewAllOffers")}
            </div>
          </div>
          <div className={style.subHeading}>{t("offers.unlockPromotions")}</div>
        </div>

        {!isEmpty(offers) && offers.length > 3 && (
          <div className="Carousel">
            <Carousel
              itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
              className="offerCards"
              enableSwipe={false}
            >
              {offers &&
                offers.map((offer) => {
                  let {
                    _id,
                    name,
                    description,
                    imageUrl,
                    couponCode,
                    product,
                    validEndDate,
                    html,
                  } = offer;
                  const isoDate = new Date(validEndDate);
                  const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                  const fullDateArabic = isoDate.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")
                  return (
                    <div className={style.card} key={_id}>
                      <div
                        className={style.cardImage}
                        style={{
                          backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                          width: "inherit",
                        }}
                      ></div>

                      <div className={style.cardDescription}>
                        <div className={style.cardDescriptionLayout}>
                          <div className={style.cardContent}>
                            <div className={style.cardDate}>
                              <div className={style.productType}>{product}</div>
                              <div className={style.offerDate}>
                                {/* Valid till */}
                                {t("offers.validTill")}: {
                                  localStorage.getItem('language') == "AR"
                                    ? fullDateArabic
                                    : istDateOnlyString
                                }
                              </div>
                            </div>
                            <div className={style.nameAndDescription}>
                              <div className={style.offersName}>{name}</div>
                              <div className={style.offersDescription}>
                                {description}
                              </div>
                            </div>
                          </div>
                          <div className={style.couponCodeLayout}>
                            <div className={style.couponCode}>
                              <div className={style.cuponCodeLayout}>
                                {couponCode}
                              </div>
                              <div className={style.dashedLine}></div>
                              <div
                                className="copy-icon"
                                onClick={() => handleCopyClick(couponCode)}
                              >
                                <ReactSVG src={copyIcon} />
                              </div>
                            </div>
                            <div
                              className={style.viewDetails}
                              onClick={() => handleViewAllClick(html)}
                            >
                              {/* View Details */}
                              {t("offers.viewDetails")}
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer />
                    </div>
                  );
                })}
            </Carousel>
          </div>
        )}
        {!isEmpty(offers) && offers.length == 3 && !isTablet2 && !isMobile && (
          <>
            <div className="Carousel">
              {offers &&
                offers.map((offer) => {
                  let {
                    _id,
                    name,
                    description,
                    imageUrl,
                    couponCode,
                    product,
                    validEndDate,
                    html,
                  } = offer;
                  const isoDate = new Date(validEndDate);
                  const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                  const fullDateArabic = isoDate.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")
                  return (
                    <div
                      style={{
                        width: "370px",
                        // flex: 1,
                        marginTop: "80px",
                      }}
                    >
                      <div className={style.card} key={_id}>
                        <div
                          className={style.cardImage}
                          style={{
                            backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                            width: "inherit",
                          }}
                        ></div>

                        <div className={style.cardDescription}>
                          <div className={style.cardDescriptionLayout}>
                            <div className={style.cardContent}>
                              <div className={style.cardDate}>
                                <div className={style.productType}>
                                  {product}
                                </div>
                                <div className={style.offerDate}>
                                  {/* Valid till: {istDateOnlyString} */}
                                  {t("offers.validTill")}: {
                                    localStorage.getItem('language') == "AR"
                                      ? fullDateArabic
                                      : istDateOnlyString
                                  }
                                </div>
                              </div>
                              <div className={style.nameAndDescription}>
                                <div className={style.offersName}>{name}</div>
                                <div className={style.offersDescription}>
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className={style.couponCodeLayout}>
                              <div className={style.couponCode}>
                                <div className={style.cuponCodeLayout}>
                                  {couponCode}
                                </div>
                                <div className={style.dashedLine}></div>
                                <div
                                  className="copy-icon"
                                  onClick={() => handleCopyClick(couponCode)}
                                >
                                  <ReactSVG src={copyIcon} />
                                </div>
                              </div>
                              <div
                                className={style.viewDetails}
                                onClick={() => handleViewAllClick(html)}
                              >
                                {/* View Details */}
                                {t("offers.viewDetails")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {!isEmpty(offers) && offers.length == 3 && (isTablet2 || isMobile) && (
          <div className="Carousel">
            <Carousel
              itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
              className="offerCards"
              enableSwipe={false}
            >
              {offers &&
                offers.map((offer) => {
                  let {
                    _id,
                    name,
                    description,
                    imageUrl,
                    couponCode,
                    product,
                    validEndDate,
                    html,
                  } = offer;
                  const isoDate = new Date(validEndDate);
                  const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                  const fullDateArabic = isoDate.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")
                  return (
                    <div className={style.card} key={_id}>
                      <div
                        className={style.cardImage}
                        style={{
                          backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                          width: "inherit",
                        }}
                      ></div>

                      <div className={style.cardDescription}>
                        <div className={style.cardDescriptionLayout}>
                          <div className={style.cardContent}>
                            <div className={style.cardDate}>
                              <div className={style.productType}>{product}</div>
                              <div className={style.offerDate}>
                                {/* Valid till: {istDateOnlyString} */}
                                {t("offers.validTill")}: {
                                  localStorage.getItem('language') == "AR"
                                    ? fullDateArabic
                                    : istDateOnlyString
                                }
                              </div>
                            </div>
                            <div className={style.nameAndDescription}>
                              <div className={style.offersName}>{name}</div>
                              <div className={style.offersDescription}>
                                {description}
                              </div>
                            </div>
                          </div>
                          <div className={style.couponCodeLayout}>
                            <div className={style.couponCode}>
                              <div className={style.cuponCodeLayout}>
                                {couponCode}
                              </div>
                              <div className={style.dashedLine}></div>
                              <div
                                className="copy-icon"
                                onClick={() => handleCopyClick(couponCode)}
                              >
                                <ReactSVG src={copyIcon} />
                              </div>
                            </div>
                            <div
                              className={style.viewDetails}
                              onClick={() => handleViewAllClick(html)}
                            >
                              {/* View Details */}
                              {t("offers.viewDetails")}
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer />
                    </div>
                  );
                })}
            </Carousel>
          </div>
        )}
        {!isEmpty(offers) && offers.length < 3 && !isTablet2 && !isMobile && (
          <>
            <div className="Carousel">
              {offers &&
                offers.map((offer) => {
                  let {
                    _id,
                    name,
                    description,
                    imageUrl,
                    couponCode,
                    product,
                    validEndDate,
                    html,
                  } = offer;
                  const isoDate = new Date(validEndDate);
                  const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                  const fullDateArabic = isoDate.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")
                  return (
                    <div
                      style={{
                        width: "370px",
                        // flex: 1,
                        marginTop: "80px",
                      }}
                    >
                      <div className={style.card} key={_id}>
                        <div
                          className={style.cardImage}
                          style={{
                            backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                            width: "inherit",
                          }}
                        ></div>

                        <div className={style.cardDescription}>
                          <div className={style.cardDescriptionLayout}>
                            <div className={style.cardContent}>
                              <div className={style.cardDate}>
                                <div className={style.productType}>
                                  {product}
                                </div>
                                <div className={style.offerDate}>
                                  {/* Valid till: {istDateOnlyString} */}
                                  {t("offers.validTill")}: {
                                    localStorage.getItem('language') == "AR"
                                      ? fullDateArabic
                                      : istDateOnlyString
                                  }
                                </div>
                              </div>
                              <div className={style.nameAndDescription}>
                                <div className={style.offersName}>{name}</div>
                                <div className={style.offersDescription}>
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className={style.couponCodeLayout}>
                              <div className={style.couponCode}>
                                <div className={style.cuponCodeLayout}>
                                  {couponCode}
                                </div>
                                <div className={style.dashedLine}></div>
                                <div
                                  className="copy-icon"
                                  onClick={() => handleCopyClick(couponCode)}
                                >
                                  <ReactSVG src={copyIcon} />
                                </div>
                              </div>
                              <div
                                className={style.viewDetails}
                                onClick={() => handleViewAllClick(html)}
                              >
                                {/* View Details */}
                                {t("offers.viewDetails")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {!isEmpty(offers) && offers.length <= 2 && isTablet2 && (
          <>
            <div className="Carousel">
              {offers &&
                offers.map((offer) => {
                  let {
                    _id,
                    name,
                    description,
                    imageUrl,
                    couponCode,
                    product,
                    validEndDate,
                    html,
                  } = offer;
                  const isoDate = new Date(validEndDate);
                  const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                  const fullDateArabic = isoDate.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")
                  return (
                    <div
                      style={{
                        width: "370px",
                        // flex: 1,
                        marginTop: "80px",
                      }}
                    >
                      <div className={style.card} key={_id}>
                        <div
                          className={style.cardImage}
                          style={{
                            backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                            width: "inherit",
                          }}
                        ></div>

                        <div className={style.cardDescription}>
                          <div className={style.cardDescriptionLayout}>
                            <div className={style.cardContent}>
                              <div className={style.cardDate}>
                                <div className={style.productType}>
                                  {product}
                                </div>
                                <div className={style.offerDate}>
                                  {/* Valid till: {istDateOnlyString} */}
                                  {t("offers.validTill")}: {
                                    localStorage.getItem('language') == "AR"
                                      ? fullDateArabic
                                      : istDateOnlyString
                                  }
                                </div>
                              </div>
                              <div className={style.nameAndDescription}>
                                <div className={style.offersName}>{name}</div>
                                <div className={style.offersDescription}>
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className={style.couponCodeLayout}>
                              <div className={style.couponCode}>
                                <div className={style.cuponCodeLayout}>
                                  {couponCode}
                                </div>
                                <div className={style.dashedLine}></div>
                                <div
                                  className="copy-icon"
                                  onClick={() => handleCopyClick(couponCode)}
                                >
                                  <ReactSVG src={copyIcon} />
                                </div>
                              </div>
                              <div
                                className={style.viewDetails}
                                onClick={() => handleViewAllClick(html)}
                              >
                                {/* View Details */}
                                {t("offers.viewDetails")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {!isEmpty(offers) &&
          offers.length >= 2 &&
          offers.length < 3 &&
          isMobile &&
          !isTablet2 && (
            <div className="Carousel">
              <Carousel
                itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
                className="offerCards"
                enableSwipe={false}
              >
                {offers &&
                  offers.map((offer) => {
                    let {
                      _id,
                      name,
                      description,
                      imageUrl,
                      couponCode,
                      product,
                      validEndDate,
                      html,
                    } = offer;
                    const isoDate = new Date(validEndDate);
                    const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    });
                    const fullDateArabic = isoDate.toLocaleString("ar", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                    product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")
                    return (
                      <div className={style.card} key={_id}>
                        <div
                          className={style.cardImage}
                          style={{
                            backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                            width: "inherit",
                          }}
                        ></div>

                        <div className={style.cardDescription}>
                          <div className={style.cardDescriptionLayout}>
                            <div className={style.cardContent}>
                              <div className={style.cardDate}>
                                <div className={style.productType}>
                                  {product}
                                </div>
                                <div className={style.offerDate}>
                                  {/* Valid till: {istDateOnlyString} */}
                                  {t("offers.validTill")}: {
                                    localStorage.getItem('language') == "AR"
                                      ? fullDateArabic
                                      : istDateOnlyString
                                  }
                                </div>
                              </div>
                              <div className={style.nameAndDescription}>
                                <div className={style.offersName}>{name}</div>
                                <div className={style.offersDescription}>
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className={style.couponCodeLayout}>
                              <div className={style.couponCode}>
                                <div className={style.cuponCodeLayout}>
                                  {couponCode}
                                </div>
                                <div className={style.dashedLine}></div>
                                <div
                                  className="copy-icon"
                                  onClick={() => handleCopyClick(couponCode)}
                                >
                                  <ReactSVG src={copyIcon} />
                                </div>
                              </div>
                              <div
                                className={style.viewDetails}
                                onClick={() => handleViewAllClick(html)}
                              >
                                {/* View Details */}
                                {t("offers.viewDetails")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          )}
        {!isEmpty(offers) && offers.length == 1 && isMobile && (
          <>
            <div className="Carousel">
              {offers &&
                offers.map((offer) => {
                  let {
                    _id,
                    name,
                    description,
                    imageUrl,
                    couponCode,
                    product,
                    validEndDate,
                    html,
                  } = offer;
                  const isoDate = new Date(validEndDate);
                  const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });

                  const fullDateArabic = isoDate.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                  product = product == "FLIGHT" ? t("offers.productTypeFlight") : t("offers.productTypeHotel")

                  return (
                    <div
                      style={{
                        // width: '370px',
                        flex: 1,
                        marginTop: "80px",
                        maxWidth: "88vw",
                        // marginLeft: '-10px'
                      }}
                    >
                      <div className={style.card} key={_id}>
                        <div
                          className={style.cardImage}
                          style={{
                            backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                            width: "inherit",
                          }}
                        ></div>

                        <div className={style.cardDescription}>
                          <div className={style.cardDescriptionLayout}>
                            <div className={style.cardContent}>
                              <div className={style.cardDate}>
                                <div className={style.productType}>
                                  {product}
                                </div>
                                <div className={style.offerDate}>
                                  {/* Valid till: {istDateOnlyString} */}
                                  {t("offers.validTill")}: 
                                  {
                                    localStorage.getItem('language') == "AR"
                                      ? fullDateArabic
                                      : istDateOnlyString
                                  }
                                </div>
                              </div>
                              <div className={style.nameAndDescription}>
                                <div className={style.offersName}>{name}</div>
                                <div className={style.offersDescription}>
                                  {description}
                                </div>
                              </div>
                            </div>
                            <div className={style.couponCodeLayout}>
                              <div className={style.couponCode}>
                                <div className={style.cuponCodeLayout}>
                                  {couponCode}
                                </div>
                                <div className={style.dashedLine}></div>
                                <div
                                  className="copy-icon"
                                  onClick={() => handleCopyClick(couponCode)}
                                >
                                  <ReactSVG src={copyIcon} />
                                </div>
                              </div>
                              <div
                                className={style.viewDetails}
                                onClick={() => handleViewAllClick(html)}
                              >
                                {/* View Details */}
                                {t("offers.viewDetails")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    )
  );
};

export default Offers;
