// InputFieldComp.js
import React from "react";
import { useFormik } from "formik";
import { capitalize, get, isEmpty, noop } from "lodash";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const InputFieldComp = (props) => {
  const { t } = useTranslation();
  const validation = get(props, "validation", "");
  const label = get(props, "label", "");
  const name = get(props, "name", "");
  const placeholder = get(props, "placeholder", "");
  const type = get(props, "type", "");
  const age = get(props, "age", "");
  const value = get(props, "value", "");
  const onChange = get(props, "onChange", noop);
  const min = get(props, "min", 0);
  const onKeyPress = get(props, "onKeyPress", function () {});
  const styles = useStyles();
  const formik = useFormik({
    initialValues: {
      // Define your field and initial value here
      [name]: age ? age : value,
    },
    validate: (values) => {
      const errors = {};

      const fieldErrorMessages = {
        firstName: `${t("errorMessages.firstNameIsRequired")}`,
        lastName: `${t("errorMessages.lastNameIsRequired")}`,
        panCard: "Pan card is required",
      };
      const fieldErrorMessagesArabic = {
        firstName: "First name is required",
        lastName: "Last name is required",
        panCard: "Pan card is required",
      };
      // Perform your field-level validation here
      if (!values[name]) {
        const fieldName = capitalize(name);
        errors[name] = fieldErrorMessages[name] || `${fieldName} is required`;
      }

      if (name === "age" && parseInt(values[name]) <= 12) {
        errors[name] = "Age must be greater than 12 years";
      }

      if (name === "age" && values[name]?.toString()?.length > 0) {
        const ageValue = parseFloat(values[name]);
        const ageValue2 = values[name];
        if (isNaN(ageValue2)) {
          errors[name] = "Age must be a number";
        } else if (ageValue !== Math.floor(ageValue)) {
          errors[name] = "Age cannot be a decimal number";
        } else if (ageValue2.toString().includes(".")) {
          errors[name] = "Age must be a number";
        }
      }

      if (name === "firstName" || name === "lastName") {
        const nameRegex = /^[A-Za-z\u0600-\u06FF ]+$/;
        if (values[name]?.length > 0 && !nameRegex.test(values[name])) {
          errors[name] = "Only alphabets allowed";
        }
      }

      if (name == "panCard" || name == "PAN") {
        const panCardRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
        if (values[name]?.length > 0 && !panCardRegex.test(values[name])) {
          errors[name] = "Enter valid pan card";
        }
      }

      if (
        !isEmpty(validation) &&
        validation.regex &&
        validation.regex.test(values[name])
      ) {
        errors[name] = `Invalid ${capitalize(name)}`;
      }

      return errors;
    },
  });

  return (
    <div className={styles.inputFieldWrapper}>
      {label && <label>{label}</label>}
      <div className={styles.inputField}>
        <input
          type={type}
          className={styles.inputFieldEntity}
          name={name}
          placeholder={placeholder}
          onChange={(e) => {
            formik.handleChange(e);
            onChange({ name, value: e.target.value });
          }}
          onBlur={formik.handleBlur}
          value={
            name == "panCard" || name == "PAN"
              ? formik.values[name].toUpperCase()
              : formik.values[name]
          }
          min={min}
          onKeyPress={onKeyPress}
          disabled={props?.disabled ? props.disabled : false}
        />
      </div>

      {formik.errors[name] && (
        <div className="error">{formik.errors[name]}</div>
      )}
    </div>
  );
};

export default InputFieldComp;
