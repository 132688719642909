import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularCheckSvg from "../../../../assets/images/circular_check_grey.svg";
import "./style.css";
import { get, noop } from "lodash";
import useStyles from "./styles";
import { InputField } from "../../../../ui/components/InputField";
import { t } from "i18next";

const validations = {
  nameValidations: {
    // regex: /^[A-Za-z]+$/,
  },
};
// const useStyles = makeStyles(() => ({
//   root: {
//     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#D0D5DD",
//     },
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "#D0D5DD",
//       },
//       "&:hover fieldset": {
//         borderColor: "#D0D5DD",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "#D0D5DD",
//       },
//     },
//   },
// }));

const titleData = [
  { key: "Mr", value: "Mr." },
  { key: "Ms", value: "Ms." },
  { key: "Mrs", value: "Mrs." },
];
const AdultTitleDataArabic = [
  { key: "Mr", value: "السيد" },
  { key: "Ms", value: "الآنسة" },
  { key: "Mrs", value: "السيدة" },
];
// const childrenTitleData = [
//   { key: "Mr", value: "Mr." },
//   { key: "Ms", value: "Ms." },
// ];
const childrenTitleData = [
  { key: "Master", value: "Mr." },
  { key: "Miss", value: "Ms." },
];
const childrenTitleDataArabic = [
  { value: "Mr", key: "السيد" },
  { value: "Ms", key: "الآنسة" },
];
const customStyles = {
  borderRadius: "10px",
  padding: "10px 4px",
};

const AddPersonalDetails = (props) => {
  const styles = useStyles();
  const label = get(props, "label", "Adult 1 :");
  const isForAdults = get(props, "isForAdult", true);
  const onDetailsChange = get(props, "onDetailsChange", noop);
  const isPanMandatory = props.isPanMandatory;
  const isPrimaryAdult = props?.isPrimaryAdult;
  const isPassportMandatory = props?.isPassportMandatory;
  const data = props?.data;
  const num = props?.num;
  const index = props?.index;
  const notPrimaryAdult = props?.notPrimaryAdult;
  const notAllChildDetailsRequired = props?.notAllChildDetailsRequired;
  const defaultTitle = data?.courtesyTitle ? data?.courtesyTitle : data?.title;
  // const [title, setTitle] = useState();
  const [title, setTitle] = useState(defaultTitle);
  const handleNameInputChange = (event, fieldName) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z\u0600-\u06FF]+$/;

    if (!value || regex.test(value)) {
      // Only update the value if it's empty or contains only letters (both lowercase and uppercase)
      const updatedDetails = {
        [fieldName]: value,
      };
      onDetailsChange(updatedDetails, index);
    }
  };

  const handleKeyPress = (event) => {
    const keyPressed = event.key;
    if (
      keyPressed === "." ||
      keyPressed === "e" ||
      keyPressed === "+" ||
      keyPressed === "-"
    ) {
      event.preventDefault();
    }
  };
  return (
    <Accordion
      // expanded={num == 0 ? true : false}
      defaultExpanded={num === 0 ? true : false}
      // sx={{
      //   border: "1px solid #D0D5DD",
      //   padding: "8px 4px 8px 0px",
      //   marginBottom: "10px",
      //   borderRadius: "10px",
      // }}

      style={{
        borderRadius: "10px",
        marginBottom: "12px",
        padding: "8px 4px 8px 0px",
        maxWidth: '98%'
      }}
    >
      <AccordionSummary sx={{ border: "none" }} expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: "inline-flex", gap: "10px" }}>
          <ReactSVG src={CircularCheckSvg} style={{ marginTop: "2px" }} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "8px 4px",
          }}
        >
          {isForAdults && (
            <Box sx={{ display: "inline-flex", gap: "16px" }}>
              {localStorage.getItem("language") == "AR"
                ? AdultTitleDataArabic.map((data) => {
                    const { key, value } = data;
                    return (
                      <Typography
                        key={key}
                        className={
                          title === key
                            ? "button-background-active"
                            : "button-background-non-active"
                        }
                        onClick={() => {
                          setTitle(key);
                          onDetailsChange(
                            { name: "courtesyTitle", value: key },
                            index
                          );
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  })
                : titleData.map((data) => {
                    const { key, value } = data;
                    return (
                      <Typography
                        key={key}
                        className={
                          title === key
                            ? "button-background-active"
                            : "button-background-non-active"
                        }
                        onClick={() => {
                          setTitle(key);
                          onDetailsChange(
                            { name: "courtesyTitle", value: key },
                            index
                          );
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  })}
            </Box>
          )}
          {!isForAdults && (
            <Box sx={{ display: "inline-flex", gap: "16px" }}>
              {childrenTitleData.map((data) => {
                const { key, value } = data;
                return (
                  <Typography
                    key={key}
                    className={
                      // title === key
                      title === value
                        ? "button-background-active"
                        : "button-background-non-active"
                    }
                    onClick={() => {
                      // setTitle(key);
                      setTitle(value);
                      onDetailsChange({ name: "title", value: value }, index);
                    }}
                  >
                    {key}
                  </Typography>
                );
              })}
            </Box>
          )}
          <div className={styles.hotelAdultPersonalDetailsForm}>
            <div>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    color: "#344054",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "20px",
                    marginBottom: "5px",
                    // padding: "5px",
                  }}
                >
                  {t("addContactDetailsScreenHotel.firstName")}
                </p>
                <p
                  style={{
                    color: "red",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    marginLeft: "4px",
                  }}
                >
                  *
                </p>
              </div>

              <InputField
                placeholder={t(
                  "addContactDetailsScreenHotel.enterYourFirstName"
                )}
                name="firstName"
                // validation={validations.nameValidations}
                onChange={(details) => {
                  onDetailsChange(details, index);
                }}
                value={data?.firstName}
              />
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    color: "#344054",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "20px",
                    marginBottom: "5px",
                    // padding: "5px",
                  }}
                >
                  {t("addContactDetailsScreenHotel.lastName")}
                </p>
                <p
                  style={{
                    color: "red",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    marginLeft: "4px",
                  }}
                >
                  *
                </p>
              </div>
              <InputField
                placeholder={t(
                  "addContactDetailsScreenHotel.enterYourLastName"
                )}
                name="lastName"
                // validation={validations.nameValidations}
                onChange={(details) => onDetailsChange(details, index)}
                value={data?.lastName}
              />
            </div>

            {/* <InputField
              placeholder="Enter First Name"
              name="firstName"
              onChange={(event) => handleInputChange(event, "firstName")}
            />

            <InputField
              placeholder="Enter Last Name"
              name="lastName"
              onChange={(event) => handleInputChange(event, "lastName")}
            /> */}
            {/* {!isForAdults && (
              <div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                      marginBottom: "5px",
                      // padding: "5px",
                    }}
                  >
                    {t("addContactDetailsScreenHotel.age")}
                  </p>
                  <p
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </p>
                </div>

                {!isForAdults && (
                  <InputField
                    placeholder="Enter Age"
                    type="number"
                    name="age"
                    disabled={true}
                    age={props.age}
                    onChange={(details) => onDetailsChange(details, index)}
                  />
                )}
              </div>
            )} */}
            {/* {isPanMandatory &&
              isPrimaryAdult &&
              !notPrimaryAdult &&
              !notAllChildDetailsRequired && (
                <div>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        color: "#344054",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "20px",
                        marginBottom: "5px",
                        // padding: "5px",
                      }}
                    >
                      PAN Card
                    </p>
                    <p
                      style={{
                        color: "red",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </p>
                  </div>
                  <InputField
                    placeholder="Enter Pan Card"
                    name={isForAdults ? "panCard" : "PAN"}
                    // validation={validations.nameValidations}
                    onChange={(details) => onDetailsChange(details, index)}
                    value={isForAdults ? data?.panCard : data?.PAN}
                  />
                </div>
              )} */}
            {/* {isPassportMandatory && (
              <div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                      marginBottom: "5px",
                      // padding: "5px",
                    }}
                  >
                    Passport
                  </p>
                  <p
                    style={{
                      color: "red",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </p>
                </div>
                <InputField
                  placeholder="Enter passport number"
                  name={"passport"}
                  // validation={validations.nameValidations}
                  onChange={(details) => onDetailsChange(details, index)}
                  value={data?.passport}
                />
              </div>
            )} */}
          </div>
          {/* <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "inherit",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                First Name
              </Typography>
              <TextField
                className={classes.root}
                size="small"
                placeholder="Enter Your First Name"
                InputProps={{ style: customStyles }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                width: "inherit",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
              }}
            >
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                Last Name
              </Typography>
              <TextField
                className={classes.root}
                size="small"
                placeholder="Enter Your Last Name"
                InputProps={{ style: customStyles }}
                fullWidth
              />
            </Box>
          </Box> */}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddPersonalDetails;
