import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectedDepartureFlight from "./selected-departure-flight";
import { handleIndexOfSelectedMultiCity } from "../../../../../../utils/slices/multicitySlice";
import { handleSelectedFlightForMultiCityTrip } from "../../../../../../utils/slices/multicitySlice";
import { handleClubbedFlightForFareCategoryForMultiCityTrip } from "../../../../../../utils/slices/multicitySlice";
import { handleClubbedFlightsForSecondCityForMultiCityTrip } from "../../../../../../utils/slices/multicitySlice";
import "./style.css";
import useStyles from "./style";
import { handleClearAllFilters } from "../../../../../../utils/slices/flightFiltersSlice";
import { useTranslation } from "react-i18next";

const MultiCityFlightSelection = () => {
  const dispatch = useDispatch();
  const { flightFrom, flightTo } = useSelector(
    (state) => state.searchFlightQuery
  );
  const { selectedFlight, indexOfMultiCity } = useSelector(
    (state) => state.multiCityTripDetails
  );

  const handleChangeFlight = (index) => {
    if (index === 1) {
      dispatch(handleIndexOfSelectedMultiCity(1));
      dispatch(handleSelectedFlightForMultiCityTrip([selectedFlight[0]]));
      dispatch(handleClubbedFlightForFareCategoryForMultiCityTrip([]));
    } else if (index === 0) {
      dispatch(handleIndexOfSelectedMultiCity(0));
      dispatch(handleSelectedFlightForMultiCityTrip([]));
      dispatch(handleClubbedFlightForFareCategoryForMultiCityTrip([]));
      dispatch(handleClubbedFlightsForSecondCityForMultiCityTrip([]));
    }
    dispatch(handleClearAllFilters());
  };

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {indexOfMultiCity === 0 ? (
        selectedFlight.length === 0 ? (
          <div className={styles.container}>
            <div className="select-departure-flight-multiCity-content1">
              <div className={styles.selectDepartureHeader}>
                {t("flightSearch.selectYourDepartureFlight")}
              </div>
              <div className={styles.selectDepartureTitle}>
                {t("flightSearch.nextStepChooseNextFlight")}
              </div>
            </div>
            {Object.keys(flightFrom[0]).length > 0 &&
              Object.keys(flightTo[0]).length > 0 && (
                <div className={styles.cityDetails}>
                  <span className="selected-departure-flight-multiCity-text">
                    {flightFrom?.[0]?.city.split(",")[0]} {t("flightSearch.to")}{" "}
                    {flightTo?.[0]?.city.split(",")[0]}
                  </span>
                </div>
              )}
          </div>
        ) : (
          selectedFlight?.length === 1 && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <SelectedDepartureFlight
                index={0}
                flight={selectedFlight[0]}
                handleChangeFlight={handleChangeFlight}
              />
              <div className={styles.selectReturnFlightContainer}>
                <div className={styles.RoundTripContent1}>
                  <div className={styles.selectDepartureHeader}>
                    {t("flightSearch.selectYourDepartureFlight")}
                  </div>
                  <div className={styles.roundTripMetaText}>
                    {t("flightSearch.nextStepTotalPrice")}
                  </div>
                </div>
                {Object.keys(flightFrom[1]).length > 0 &&
                  Object.keys(flightTo[1]).length > 0 && (
                    <div className={styles.RoundTripContent2}>
                      <span className={styles.flightRoundTripText}>
                        {flightFrom?.[1]?.city.split(",")[0]}{" "}
                        {t("flightSearch.to")}{" "}
                        {flightTo?.[1]?.city.split(",")[0]}
                      </span>
                    </div>
                  )}
              </div>
            </div>
          )
        )
      ) : (
        <></>
      )}

      {indexOfMultiCity === 1 || indexOfMultiCity === 2 ? (
        selectedFlight.length === 0 ? (
          <div className={styles.container}>
            <div className="select-departure-flight-multiCity-content1">
              <div className={styles.selectDepartureHeader}>
                {t("flightSearch.selectYourDepartureFlight")}
              </div>
              <div className={styles.selectDepartureTitle}>
                {t("flightSearch.nextStepChooseNextFlight")}
              </div>
            </div>
            {Object.keys(flightFrom[0]).length > 0 &&
              Object.keys(flightTo[0]).length > 0 && (
                <div className={styles.cityDetails}>
                  <span className="selected-departure-flight-multiCity-text">
                    {flightFrom?.[0]?.city.split(",")[0]} {t("flightSearch.to")}{" "}
                    {flightTo?.[0]?.city.split(",")[0]}
                  </span>
                </div>
              )}
          </div>
        ) : selectedFlight?.length === 1 ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <SelectedDepartureFlight
              index={0}
              flight={selectedFlight[0]}
              handleChangeFlight={handleChangeFlight}
            />
            <div className={styles.selectReturnFlightContainer}>
              <div className={styles.RoundTripContent1}>
                <div className={styles.selectDepartureHeader}>
                  {t("flightSearch.selectYourDepartureFlight")}
                </div>
                <div className={styles.roundTripMetaText}>
                  {t("flightSearch.nextStepChooseNextFlight")}
                </div>
              </div>
              {Object.keys(flightFrom[1]).length > 0 &&
                Object.keys(flightTo[1]).length > 0 && (
                  <div className={styles.RoundTripContent2}>
                    <span className={styles.flightRoundTripText}>
                      {flightFrom?.[1]?.city.split(",")[0]}{" "}
                      {t("flightSearch.to")} {flightTo?.[1]?.city.split(",")[0]}
                    </span>
                  </div>
                )}
            </div>
          </div>
        ) : (
          indexOfMultiCity === 2 &&
          selectedFlight?.length === 2 && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              {/* <div className="selected-departure-flight-multiCity"> */}
              <SelectedDepartureFlight
                index={0}
                flight={selectedFlight[0]}
                handleChangeFlight={handleChangeFlight}
              />
              {/* </div>
              <div className="selected-departure-flight-multiCity"> */}
              <SelectedDepartureFlight
                index={1}
                flight={selectedFlight[1]}
                handleChangeFlight={handleChangeFlight}
              />
              <div className={styles.selectReturnFlightContainer}>
                <div className={styles.RoundTripContent1}>
                  <div className={styles.selectDepartureHeader}>
                    {t("flightSearch.selectYourDepartureFlight")}
                  </div>
                  <div className={styles.roundTripMetaText}>
                    {t("flightSearch.nextStepChooseNextFlight")}
                  </div>
                </div>
                {Object.keys(flightFrom[2]).length > 0 &&
                  Object.keys(flightTo[2]).length > 0 && (
                    <div className={styles.RoundTripContent2}>
                      <span className={styles.flightRoundTripText}>
                        {flightFrom?.[2]?.city.split(",")[0]}{" "}
                        {t("flightSearch.to")}{" "}
                        {flightTo?.[2]?.city.split(",")[0]}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            // </div>
          )
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default MultiCityFlightSelection;
