import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import defaultImage1 from "../../../../assets/images/900 x 650.png";

const ImageCarousel2 = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setImageOpacity(0);
  //       setTimeout(() => {
  //         setCurrentImage((prevImage) =>
  //           prevImage === images.length - 1 ? 0 : prevImage + 1
  //         );

  //         setImageOpacity(1);
  //       }, 500);
  //     }, 3000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, [images]);
  const style = useStyles();

  return (
    <>
      {images && (
        <div
          style={{
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
            overflowY: "hidden",
          }}
          className={style.carouselContainer}
        >
          <ArrowBackIosIcon
            className={style.leftArrow}
            style={{
              display: `${currentImage == 0 ? "none" : "flex"}`,
            }}
            onClick={(e) => {
              setCurrentImage((prevCount) => {
                if (prevCount == 0) {
                  return images.length - 1;
                } else {
                  return prevCount - 1;
                }
              });
            }}
          />
          <img
            className={style.carouselImage}
            src={images[currentImage]}
            alt={`Image ${currentImage + 1}`}
            style={{ opacity: imageOpacity }}
            onError={(e) => {
              e.target.src = defaultImage1;
            }}
          />
          <ArrowForwardIosIcon
            className={style.rightArrow}
            style={{
              display: `${currentImage == images.length ? "none" : "flex"}`,
            }}
            onClick={(e) => {
              setCurrentImage((prevCount) => {
                if (prevCount == images.length - 1) {
                  return 0;
                } else {
                  return prevCount + 1;
                }
              });
            }}
          />
        </div>
      )}
    </>
  );
};

ImageCarousel2.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageCarousel2;
