import React, { useEffect, useState } from "react";
import {
  flightClassData,
  flightClassDataArabic,
} from "../../../../../../../../../config";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { updateModifyFlightClassType } from "../../../../../../../../../utils/slices/modifySearchFlightSlice";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import "./style.css";
import { useTranslation } from "react-i18next";

const FlightClass = ({
  showFlightClassDropdown,
  setShowFlightClassDropdown,
}) => {
  const dispatch = useDispatch();
  const { flightClassType } = useSelector((state) => state.modifySearchFlight);

  const [flightClass, setFlightClass] = useState(flightClassType);

  const handleFlightClassType = (value) => {
    setFlightClass(value);
    dispatch(updateModifyFlightClassType(value));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (flightClass && !event.target.closest(".cabin-class")) {
        setShowFlightClassDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showFlightClassDropdown, flightClass]);
  const getLocale = useSelector((state) => state.locale);
  const { t } = useTranslation();
  return (
    <div
      className="flight-classes cabin-class"
      onClick={() => setShowFlightClassDropdown(!showFlightClassDropdown)}
    >
      <div className="seat-icon">
        <AirlineSeatReclineExtraIcon />
      </div>
      <div className="flight-class-dropdown">
        <div className="dropdown-value">
          {localStorage.getItem("language") == "AR"
            ? flightClassDataArabic[Number(flightClass)]
            : flightClassData[Number(flightClass)]}
        </div>
        <KeyboardArrowDownIcon className="flight-class-dropdown-icon" />
      </div>
      {showFlightClassDropdown && (
        <div className="flight-class-options-group">
          {localStorage.getItem("language") === "AR"
            ? Object.entries(flightClassDataArabic).map(([key, value]) => {
                return (
                  <div
                    className="flight-class-option"
                    key={key}
                    onClick={() => handleFlightClassType(key)}
                  >
                    <div className="option-text">{value}</div>
                  </div>
                );
              })
            : Object.entries(flightClassData).map(([key, value]) => {
                return (
                  <div
                    className="flight-class-option"
                    key={key}
                    onClick={() => handleFlightClassType(key)}
                  >
                    <div className="option-text">{value}</div>
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
};

export default FlightClass;
