import { createSlice } from "@reduxjs/toolkit";

const initialFlightFilters =
  JSON.parse(localStorage.getItem("flightFilters")) || {};
const initialHotelFilters =
  JSON.parse(localStorage.getItem("hotelFilters")) || {};

const globalSlice = createSlice({
  name: "globalFilters",
  initialState: {
    flightFilters: initialFlightFilters,
    hotelFilters: initialHotelFilters,
  },
  reducers: {
    updateFlightFilters: (state, action) => {
      state.flightFilters = action.payload;
      localStorage.setItem("flightFilters", JSON.stringify(action.payload));
    },
    updateHotelFilters: (state, action) => {
      state.hotelFilters = action.payload;
      localStorage.setItem("hotelFilters", JSON.stringify(action.payload));
    },
  },
});

export const { updateFlightFilters, updateHotelFilters } = globalSlice.actions;

export default globalSlice.reducer;
