import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Passenger from "../../../passenger";
import { useSelector } from "react-redux";
import "./style.css";
import eventBus from "../../../../../../core/eventBus";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import useStyles from "./styles";
import PassengerMobile from "../../../passenger/mobile";
import useIsTablet from "../../../../../../hooks/useIsTablet.hook";
import { useTranslation } from "react-i18next";

const TotalTravellers = ({
  showPassengersComponent,
  setShowPassengersComponent,
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    flightTotalTravellers: { totalCount },
  } = useSelector((state) => state.searchFlight);

  // const [showPassengersComponent, setShowPassengersComponent] = useState(false);
  const closePassengersComponent = () => {
    setShowPassengersComponent(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showPassengersComponent &&
        !event.target.closest(".passenger-component")
      ) {
        setShowPassengersComponent(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPassengersComponent]);

  return (
    <>
      <div
        className={`${styles.totalPersons} passenger-component`}
        onClick={() => {
          setShowPassengersComponent(true);
        }}
      >
        <div className="total-persons-icon">
          <PermIdentityIcon />
        </div>
        <div className="total-persons-dropdown">
          <label className="total-persons-dropdown-label">
            {totalCount > 1 ? (
              <span>
                {totalCount} {t("flightSearchWidget.travellers")}
              </span>
            ) : (
              <span>
                {totalCount} {t("flightSearchWidget.travellers")}
              </span>
            )}
          </label>
          <KeyboardArrowDownIcon className="total-persons-dropdown-icon" />
        </div>
        {showPassengersComponent && !isMobile && !isTablet && (
          <Passenger closePassengersComponent={closePassengersComponent} />
        )}
      </div>
      <div className="passenger-component">
        {showPassengersComponent && (isMobile || isTablet) && (
          <PassengerMobile
            closePassengersComponent={closePassengersComponent}
          />
        )}
      </div>
    </>
  );
};

export default TotalTravellers;
