import { createSlice } from "@reduxjs/toolkit";

const bookingStatusSlice = createSlice({
  name: "bookingStatus",
  initialState: {
    bookingId: "",
  },
  reducers: {
    setBookingId: (state, action) => {
      state.bookingId = action.payload;
    },
  },
});

export const { setBookingId } = bookingStatusSlice.actions;

export default bookingStatusSlice.reducer;
