import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bookFlightContainer: {
    marginTop: "35px",
    width: "100%",
    display: "inline-flex",
    gap: "3%",

    [theme.breakpoints.down("sm")]: {
      padding: "0px ",
      minHeight: "100vH",
    },
  },

  bookFlightResult: {
    width: "72%",
    marginBottom: "80px",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  flightNotAvailableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    padding: "0px 16px",
    textAlign: "justify",
  },
  flightNotAvailable: {
    color: "#000",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  notAvailableDescription: {
    color: "#000",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  refreshText: {
    color: "#FFF",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    cursor: "pointer",
  },
  refreshTextContainer: {
    background: "#1b1d52",
    borderRadius: "8px",
    padding: "8px 12px",
  },
  refreshTextMainContainer: {
    display: "inline-flex",
    justifyContent: "center",
    width: "100%",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
  },
  mainContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    marginBottom: "60px",
  },
}));

export default useStyles;
