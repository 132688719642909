/* eslint-disable react/prop-types */
import React  from "react";
import { get } from "lodash";
import useStyles from "./styles";
import { OutsideClickHandler } from "../OutsideClickHandler";

export const DialogComp = (props) => {
  
  const styles = useStyles();
  const children = get(props, "children", <></>);
  return (
    <div className={ styles.dialogWrapper }>
      <OutsideClickHandler onOutsideClick={()=>props.toggleVisibility(false)}>
        <div className={styles.dialog}>
          {children}
        </div>
      </OutsideClickHandler>
    </div>
  );
};
