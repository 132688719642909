// import Logout from "../Auth/logout";
import { toast } from "react-toastify";
const handleUnauthorisedLogout = async () => {
  const userDetails =
    window.localStorage &&
    JSON.parse(window.localStorage.getItem("userDetails"));
  if (userDetails && userDetails.token) {
    window.localStorage.removeItem("userDetails");
    toast.error(t("errorMessages.pleaseLoginToContinue"), {
      position: "top-right",
    });
  }
};

export default handleUnauthorisedLogout;
