import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FAFAFA",
    display: "inline-flex",
    gap: "20px",
    justifyContent: "center",
    width: "100%",
    padding: "48px 128px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },
  },
  ringsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  totalPriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: "16px",
  },
  totalPriceText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  price: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
  },
  cartFairRulesAndPriceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px 0px 20px",
    boxShadow: "0 1px 4px 0 rgba(0,0,0,.21)",
    flex: 1,
  },
  couponContainer: {
    borderRadius: "9px 9px 0px 0px",
    border: "1px solid #D0D5DD",
  },
  drtMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px",
    boxShadow: "0 1px 4px 0 rgba(0,0,0,.21)",
    flex: 1,
  },
  drtPriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: "16px",
  },
  drtTotalPriceText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  drtPrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
  },
  priceContainerNew: {
    display: "inline-flex",
    gap: "2rem",
  },
  priceNew: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  priceCurrency: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  codeContainer: {
    fontSize: "10px",
    fontFamily: "Lato, sans-serif",
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    marginLeft: "40px",
    color: "#344054",
    borderRadius: "4px",
    fontWeight: 400,
    padding: "4px 6px",
    cursor: "pointer",
  },
  priceThirdContainer: {
    display: "inline-flex",
    gap: "2rem",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
  },
  priceThird: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  priceCurrencyThird: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  codeThirdContainer: {
    fontWeight: 400,
    padding: "4px",
    fontSize: "10px",
    fontFamily: "Lato, sans-serif",
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    color: "#344054",
    borderRadius: "4px",
    cursor: "pointer",
  },
  previousContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    cursor: "pointer",
  },
  insuranceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tripInsurance: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tripInsuranceAmount: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baggageContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  extraBaggage: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  extraBaggagePrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  contentContainer: {
    display: "flex",
    gap: "2rem",
    flexWrap: "wrap",
  },
  FlightSummaryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flex: 12,
  },
  flightDetailCard: {
    boxShadow: "0 1px 4px 0 rgba(0,0,0,.21)",
    borderRadius: "10px",
  },
  finalPaymentContainer: {
    backgroundColor: "#E6E6FA",
    padding: "12px 24px",
    borderRadius: "5px",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    marginBottom: "40px",
    backgroundColor: "white",
    padding: "30px 80px",
    width: "1000vw",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
      maxWidth: "100vw",
    },
  },
  mainHeading: {
    fontFamily: "Lato, sans-serif",
    color: "#344054",
    fontWeight: "600",
    letterSpacing: "0.5px",

    [theme.breakpoints.down("sm")]: {
      fontWeight: 600,
      fontSize: "14px",
    },
  },
  payNowButton: {
    background: "#1b1d52",
    borderRadius: "8px",
    padding: ".625rem 1rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  payNowButtonDisabled: {
    background: "#9E9E9E",
    borderRadius: "8px",
    padding: ".625rem 1rem",
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  payNowText: {
    fontFamily: "Lato, sans-serif",
    color: "#FFF",
    fontWeight: "600",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  headingContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  editDetails: {
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    fontWeight: "500",
    width: "inherit",
    fontSize: "14px",
    cursor: "pointer",
    padding: "12px",
    background: "#1b1d52",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  editDetailsMobile: {
    fontFamily: "Lato, sans-serif",
    color: "#4037acd6",
    fontWeight: "500",
    fontSize: "14px",
    cursor: "pointer",
  },
  finalPaymentTotalPrice: {
    fontFamily: "Lato, sans-serif",
    color: "#344054",
    fontWeight: "600",
    letterSpacing: "0.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
  fareRuleDivider: {
    color: "#08F",
    height: "1px",
    width: "100%",
  },
}));

export default useStyles;
