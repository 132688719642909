import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { get, map } from "lodash";
import { Flight, Hiking, Hotel, LocalShipping } from "@mui/icons-material";
import { useNavigate } from "react-router";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const PackageFlipCard = (props) => {
  const [flipCard, setFlipCard] = useState(false);
  const styles = useStyles();
  const item = get(props, "item", {});
  const imageUrl = get(item, "thumbnail", "");
  const city = item?.city?.toLowerCase();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const {
    _id,
    personPrice,
    flights,
    hotels,
    activities,
    transfers,
    benefits,
    itinerary,
    name,
    description,
    discountedPrice,
  } = item;

  const handleMouseOver = () => {
    if (!isMobile) {
      setFlipCard(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setFlipCard(false);
    }
  };
  return (
    <div
      className={styles.packageFlipCard}
      onClick={() => navigate(`/package/${_id}`)}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${styles.packageFlipCardInner} ${
          flipCard ? styles.rotateCard : ""
        }`}
      >
        <div className={styles.packageFlipCardFront}>
          <img
            className={styles.packageFlipCardImage}
            src={`${S3Bucket}/${imageUrl}`}
          />
          <div
            className={
              flipCard
                ? styles.packageFlipCardFrontCityHidden
                : styles.packageFlipCardFrontCity
            }
          >
            {city}
          </div>
          <div
            className={
              flipCard
                ? styles.packageFlipCardFrontHotelDetailsHidden
                : styles.packageFlipCardFrontHotelDetails
            }
          >
            <h2 className={styles.packageHotelDetailsTitle}>{name}</h2>
            <p className={styles.packageHotelDetailsPrice}>
              <span>SAR</span> {discountedPrice.toLocaleString("en-In")}
            </p>
          </div>
        </div>
        <div className={styles.packageFlipCardBack}>
          <h3 className={styles.packageFlipCardBackTitle}>{name}</h3>
          <div className={styles.packageFlipCardBackDescWrapper}>
            <div
              className={styles.packageFlipCardBackDesc}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description || ""),
              }}
            ></div>
          </div>
          <div className={styles.packageDetails}>
            {flights > 0 && (
              <div className={styles.packageDetail}>
                <Flight className={styles.packageDetailLabelIcon} />
                <p className={styles.packageDetailLabel}>
                  {flights} {t("packages.flights")}
                </p>
              </div>
            )}
            {flights > 0 && <div className={styles.verticalDivider} />}
            {hotels > 0 && (
              <div className={styles.packageDetail}>
                <Hotel className={styles.packageDetailLabelIcon} />
                <p className={styles.packageDetailLabel}>
                  {hotels} {t("packages.hotels")}
                </p>
              </div>
            )}
            {hotels > 0 && <div className={styles.verticalDivider} />}
            {activities > 0 && (
              <div className={styles.packageDetail}>
                <Hiking className={styles.packageDetailLabelIcon} />
                <p className={styles.packageDetailLabel}>
                  {activities} {t("packages.activities")}
                </p>
              </div>
            )}
            {transfers > 0 && <div className={styles.verticalDivider} />}
            {transfers > 0 && (
              <div className={styles.packageDetail}>
                <LocalShipping className={styles.packageDetailLabelIcon} />
                <p className={styles.packageDetailLabel}>
                  {transfers} {t("packages.transfers")}
                </p>
              </div>
            )}
          </div>
          <div className={styles.packageDetailBenefits}>
            {benefits.length > 4 ? (
              <>
                {benefits.slice(0, 4).map((benefit, index) => (
                  <span key={index} className={styles.packageDetailBenefit}>
                    {benefit}
                  </span>
                ))}
              </>
            ) : (
              benefits.map((benefit, index) => (
                <span key={index} className={styles.packageDetailBenefit}>
                  {benefit}
                </span>
              ))
            )}
          </div>
          <ul className={styles.packageDetailItineraries}>
            {/* {itinerary.length > 4 ?
              itinerary.slice(0, 4).map((it, index) =>{
                <li key={index}> {it.title} </li>;
              }): itinerary.map((it, index) =>{
                <li key={index}> {it.title}</li>;
              })

            } */}

            {itinerary.length > 4
              ? itinerary.slice(0, 4).map((_it) => (
                  <li key={get(_it, "title", "")}>
                    {get(_it, "title", "").substring(
                      0,
                      _it?.title?.length > 40 ? 40 : _it?.title?.length
                    )}
                    {_it?.title?.length > 40 && ".."}
                  </li>
                ))
              : itinerary.map((_it) => (
                  <li key={get(_it, "title", "")}>
                    {" "}
                    {get(_it, "title", "").substring(
                      0,
                      _it?.title?.length > 40 ? 40 : _it?.title?.length
                    )}
                    {_it?.title?.length > 40 && ".."}
                  </li>
                ))}
          </ul>
          <div className={styles.packageDetailPriceWrapper}>
            <div className={styles.priceContainer}>
              <h2 className={styles.packageDetailDiscountedPrice}>
                <span>SAR</span> {personPrice.toLocaleString("en-In")}
              </h2>
              <h2 className={styles.packageDetailPrice}>
                <span>SAR</span> {discountedPrice.toLocaleString("en-In")}
              </h2>
            </div>
            <p className={styles.packageDetailPriceLabel}>
              {" "}
              {t("packages.perPerson")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageFlipCard;
