import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { div, Typography } from "@mui/material";
import ProfileDetails from "./profile-details";
import ProfileSidebar from "../profile-sidebar";
import ArrowRightSvg from "../../../../assets/images/arrow-right.svg";
import Logout from "../../../../Auth/logout";
import useStyles from "./styles";
import { getUserProfile } from "../../../../modules/auth";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage?.userDetails)
  const token = userDetails?.token
  const {t} = useTranslation()
  const [isProfileActive, setIsProfileActive] = useState(true);
  const [isProfileBookingsActive, setIsProfileBookingsActive] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userData, setUserData] = useState({});
  const classes = useStyles();

  const handleIsProfileActive = () => {
    setIsProfileActive(true);
    setIsProfileBookingsActive(false);
    navigate("/profile");
  };

  const handleIsProfileBookingsActive = () => {
    setIsProfileActive(false);
    setIsProfileBookingsActive(true);
    navigate("/bookings");
  };

  const handleIsProfileLogoutActive = () => {
    handleLogout();
  };

  const handleLogout = async () => {
    setShowLogoutModal(true);
  };

  async function getuserProfileData() {
    try {
      const data = await getUserProfile(token);
      const { email = userDetails.email, firstName, lastName, phoneNo, profilePic } = data;
      const userdata = {
        email,
        firstName,
        lastName,
        phoneNo,
        profilePic,
        token,
      };
      setUserData(userdata);
      console.log('userData', userdata)
      localStorage.setItem("userDetails", JSON.stringify(userdata));
    } catch (err) {
      console.log("error", err);
    }
  }

  useEffect(() => {
    // get user data
    if (token) {
      getuserProfileData();
    } else {
      navigate('/')
    }
  }, []);

  return (
    <>
      {showLogoutModal && (
        <Logout
          showLogoutModal={showLogoutModal}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}
      <div className={classes.mainProfilePageContainer}>
        <div className={classes.breadCrumbContainer}>
          <p className={classes.homeBreadCrumb} onClick={() => navigate("/")}>
            {t("myProfile.home")}
          </p>
          <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
          <p
            className={classes.myProfileBreadCrumb}
            onClick={() => navigate("/profile")}
          >
            {t("myProfile.myProfile")}
          </p>
        </div>
        <div className={classes.sidebarAndProfileDetailsContainer}>
          <div className={classes.profileSideBarContainer}>
            <ProfileSidebar
              isProfileActive={isProfileActive}
              handleIsProfileActive={handleIsProfileActive}
              isProfileBookingsActive={isProfileBookingsActive}
              handleIsProfileBookingsActive={handleIsProfileBookingsActive}
              handleIsProfileLogoutActive={handleIsProfileLogoutActive}
              userData={userData}
              setUserData={setUserData}
            />
          </div>
          <div className={classes.profileDetailsContainer}>
            <ProfileDetails
              userData={userData}
              setUserData={setUserData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
