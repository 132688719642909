import React from "react";
import useStyles from "./styles";
import { get } from "lodash";
import { PersonAdd } from "@mui/icons-material";
import eventBus from "../../../core/eventBus";
import HotelTotalPerson from "../../../components/search-widget/components/hotel-search-widget/dropdown/total-persons";
import { useTranslation } from "react-i18next";

const PersonSelectionComp = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const placeholder = get(props, "placeholder", "");
  let value = get(props, "value", "");
  const formattedValue = value.split(", ");
  if (formattedValue[2] == `0 ${t("hotelSearchWidgetMobile.children")}`) {
    formattedValue.pop();
  }
  if (formattedValue[1] == `1 ${t("hotelSearchWidgetMobile.adults")}`) {
    formattedValue[1] = `1 ${t("hotelSearchWidgetMobile.adult")}`;
  }
  value = formattedValue.join(" ");

  // const handleClick = ()=>{
  //   eventBus.emit("open-bottomSheet", <HotelTotalPerson className={styles.mobileCalenderWrapper}/>);

  // };
  return (
    <div className={styles.personSelection}>
      <input
        value={value}
        className={styles.personSelectionInput}
        type="text"
        placeholder={placeholder}
      />
      <PersonAdd />
      {/* <HotelTotalPerson/>  */}
    </div>
  );
};

export default PersonSelectionComp;
