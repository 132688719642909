import React from "react";
import { formatSARCurrency } from "../../../../../../../../../utils/helpers";
import { useTranslation } from "react-i18next";

function AmountPaid({ payment_method_type, totalAmountPaid }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background: "#FFF",
        borderRadius: "10px",
        border: "1px solid #D0D5DD",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <p
        style={{
          background: "#F2F7FB",
          padding: "16px",
          borderRadius: "10px 10px 0px 0px",
          color: "#344054",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        {t("myProfile.amountPaid")}
      </p>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "16px",
        }}
      >
        <p
          style={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          {payment_method_type?.charAt(0).toUpperCase() +
            payment_method_type?.slice(1)}
        </p>
        <p
          style={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          {t("hotelDetails.currencySymbol")}
          {" "} {formatSARCurrency(totalAmountPaid)}
        </p>
      </div>
    </div>
  );
}

export default AmountPaid;
