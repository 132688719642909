import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    width: "900px",
    height: "650px",
    // [theme.breakpoints.down("xl")]: {
    //   height: "560px",
    //   padding: "0px 32px",
    // },

    // [theme.breakpoints.down("lg")]: {
    //   height: "470px",
    //   padding: "0px 32px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   height: "380px",
    //   padding: "0px 32px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   height: "250px",
    //   padding: "0px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height: "150px",
    //   padding: "0px",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "400px",
      position: "relative",
    },
  },

  carouselImage: {
    opacity: 0,
    // transition: "opacity 0.5s ease-in-out",
    width: "100%",
    height: "100%",

    // borderRadius: "48px",
    // [theme.breakpoints.down("xs")]: {
    //   borderRadius: "0px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   borderRadius: "0px",
    // },
  },
  leftArrow: {
    position: "absolute",
    top: "45%",
    left: "50px",
    color: "white",
    height: "50px",
    width: "50px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      left: "20px",
      fontSize: "30px",
    },
  },
  rightArrow: {
    position: "absolute",
    top: "45%",
    right: "50px",
    color: "white",
    height: "50px",
    width: "50px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      right: "20px",
      fontSize: "30px",
    },
  },
}));

export default useStyles;
