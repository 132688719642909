import React, { useState } from "react";
import PropTypes from "prop-types";
import BackCard from "./back-card";
import FrontCard from "./front-card";
import { useNavigate } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import useStyles from "./style";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import useIsTablet from "../../../hooks/useIsTablet.hook";
import "./style.css";
import PackageFlipCard from "./package-flip-card";
import { useDispatch } from "react-redux";
import { updateClearAll } from "../../../utils/slices/packageSlice";
import { isEmpty } from "lodash";
import useIsTablet2 from "../../../hooks/useIsTablet.hook2";
import { useTranslation } from "react-i18next";

const Packages = ({ packages }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const style = useStyles();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isTablet2 = useIsTablet2();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const handleScroll = (value) => {
  //   setIndex(value);
  // };

  return (
    packages &&
    packages.length > 0 && (
      <div className="packages">
        <div className={style.titleContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className={style.title}>
              {t("packages.bestTour")}
              <span className={style.packagesText}>
                {t("packages.packages")}
              </span>
            </div>

            {isMobile ? (
              <div
                className={style.viewAllPackageText}
                onClick={() => {
                  navigate("/packages/search");
                  dispatch(updateClearAll());
                }}
              >
                {t("packages.viewAllPackages")}
              </div>
            ) : (
              <div
                className={style.viewAllPackageText}
                onClick={() => {
                  navigate("/packages/search");
                  dispatch(updateClearAll());
                }}
              >
                {t("packages.viewAllPackages")}
              </div>
            )}
          </div>
          <div className={style.SubHeading}>
            {t("packages.journeyOfLifetime")}
          </div>
        </div>
        {/* {packages?.length > 3 && (
          <>
            
          </>
        )} */}
        {/* <div>
          <div className={style.cardScroll}>
            <Carousel
              itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
              className="offerCards"
              enableSwipe={false}
            >
              {packages &&
                packages.slice(index).map((item) => {
                  const id = item?._id;
                  return <PackageFlipCard key={id} item={item} />;
                })}
            </Carousel>
          </div>
        </div> */}
        {!isEmpty(packages) && packages.length > 3 && (
          <div className={style.cardScroll}>
            <Carousel
              itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
              className="offerCards"
              enableSwipe={false}
            >
              {packages &&
                packages.slice(index).map((item) => {
                  const id = item?._id;
                  return <PackageFlipCard key={id} item={item} />;
                })}
            </Carousel>
          </div>
        )}
        {!isEmpty(packages) &&
          packages.length == 3 &&
          !isTablet2 &&
          !isMobile && (
            <div className={style.cardScroll}>
              {packages &&
                packages.slice(index).map((item) => {
                  const id = item?._id;
                  return (
                    <div
                      style={{
                        // width: '370px',
                        flex: 1,
                        marginTop: "80px",
                      }}
                    >
                      {" "}
                      <PackageFlipCard key={id} item={item} />{" "}
                    </div>
                  );
                })}
            </div>
          )}
        {!isEmpty(packages) &&
          packages.length == 3 &&
          (isTablet2 || isMobile) && (
            <div className={style.cardScroll}>
              <Carousel
                itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
                className="offerCards"
                enableSwipe={false}
              >
                {packages &&
                  packages.slice(index).map((item) => {
                    const id = item?._id;
                    return <PackageFlipCard key={id} item={item} />;
                  })}
              </Carousel>
            </div>
          )}
        {!isEmpty(packages) &&
          packages.length < 3 &&
          !isTablet2 &&
          !isMobile && (
            <div className={style.cardScroll}>
              {packages &&
                packages.slice(index).map((item) => {
                  const id = item?._id;
                  return (
                    <div
                      style={{
                        width: "370px",
                        // flex: 1,
                        marginTop: "80px",
                      }}
                    >
                      {" "}
                      <PackageFlipCard key={id} item={item} />{" "}
                    </div>
                  );
                })}
            </div>
          )}
        {!isEmpty(packages) && packages.length <= 2 && isTablet2 && (
          <div className={style.cardScroll}>
            {packages &&
              packages.slice(index).map((item) => {
                const id = item?._id;
                return (
                  <div
                    style={{
                      width: "370px",
                      // flex: 1,
                      marginTop: "80px",
                    }}
                  >
                    {" "}
                    <PackageFlipCard key={id} item={item} />{" "}
                  </div>
                );
              })}
          </div>
        )}
        {!isEmpty(packages) &&
          packages.length >= 2 &&
          packages.length < 3 &&
          isMobile &&
          !isTablet2 && (
            <div className={style.cardScroll}>
              <Carousel
                itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
                className="offerCards"
                enableSwipe={false}
              >
                {packages &&
                  packages.slice(index).map((item) => {
                    const id = item?._id;
                    return <PackageFlipCard key={id} item={item} />;
                  })}
              </Carousel>
            </div>
          )}
        {!isEmpty(packages) && packages.length == 1 && isMobile && (
          <div className={style.cardScroll}>
            {packages &&
              packages.slice(index).map((item) => {
                const id = item?._id;
                return (
                  <div
                    style={{
                      // width: '370px',
                      flex: 1,
                      marginTop: "80px",
                      maxWidth: "88vw",
                    }}
                  >
                    {" "}
                    <PackageFlipCard key={id} item={item} />{" "}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    )
  );
};

export default Packages;

Packages.propTypes = {
  packages: PropTypes.array,
};
