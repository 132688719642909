import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  recentSearch: {
    display: "flex",
    flexDirection: "column",
  },

  title: {
    // mar: "50px",
  },

  recentSearchCards: {
    display: "flex",
    gap: "16px",
    zIndex: 1,
    paddingTop: "30px",
  },
  recentSearchCard: {
    display: "flex",
    justifyContent: "space-between",
    // width: "274px",
    // width: "100%",
    // minWidth: "270px",
    height: "174px",
    padding: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    boxShadow: "0px 2px 32px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("lg")]: {
      width: "274px",
      minWidth: "270px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },

  recentSearchCard1: {
    display: "flex",
    justifyContent: "space-between",
    width: "274px",
    // width: "100%",
    minWidth: "270px",
    height: "174px",
    padding: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    boxShadow: "0px 2px 32px 0px rgba(0, 0, 0, 0.12)",
  },
  recentSearchCardLeftSection: {
    height: "100%",
    display: "flex",
    rowGap: "6px",
    // justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "60%",
  },
  recentSearchCardRightSection: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  recentSearchCardFlightPath: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    marginTop: "16px",
  },
  flightType: {
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "400",
    color: theme.palette.textColors.textPrimaryColor,
  },
  cardCityName: {
    fontSize: "14px",
    fontWeight: "600",
    color: theme.palette.textColors.textPrimaryColor,
  },

  cardMultiCityName: {
    fontSize: "12px",
    fontWeight: "600",
    color: theme.palette.textColors.textPrimaryColor,
  },
  flightDateAndTime: {
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "400",
    color: theme.palette.entityColors.entityColorPrimary,
  },
  cardFlightType: {
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "400",
    color: theme.palette.textColors.textSecondaryColor,
  },
  flightIconWrapper: {
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    borderRadius: "50%",
  },
  cardSearchAction: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
    color: theme.palette.entityColors.entityColorPrimary,
    cursor: "pointer",
  },

  travelCities: {
    display: "flex",
    gap: "6px",
  },
}));

export default useStyles;
