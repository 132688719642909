import React from "react";
import { get } from "lodash";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

export const AboutHotel = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const details = get(props, "hotelDetails", {});
  const readMoreSeeLess = get(props, "readMoreSeeLess", "");
  const SetReadMoreSeeLess = get(props, "SetReadMoreSeeLess");
  let description = details?.Description;
  if (readMoreSeeLess == t("errorMessages.readMore")) {
    if (description?.length > 400) {
      description = description?.slice(0, 400) + "...";
    }
  }

  return (
    <div>
      <h2 className={styles.hotelDetailsAboutCaption}>{`${t(
        "hotelDetails.about"
      )} ${get(details, "HotelName", `${t("hotelDetails.hotel")}`)}`}</h2>
      {/* <div
        className={styles.hotelDetailsAboutDescriptionWrapper}
        dangerouslySetInnerHTML={{ __html: get(details, "Description", <></>) }}
      /> */}
      <div
        className={styles.hotelDetailsAboutDescriptionWrapper}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <span
        style={{
          color: "#4037acd6",
          cursor: "pointer",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
        onClick={() => {
          if (readMoreSeeLess == t("errorMessages.readMore")) {
            SetReadMoreSeeLess(t("errorMessages.seeLess"));
          } else {
            SetReadMoreSeeLess(t("errorMessages.readMore"));
          }
        }}
      >
        {readMoreSeeLess}
      </span>
    </div>
  );
};
