import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import MailSvg from "../../../../assets/images/mail.svg";
import CircularCheckSvg from "../../../../assets/images/circular_check_grey.svg";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
const muiStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
    "& .MuiInputBase-root .MuiNativeSelect-select ": {
      paddingRight: localStorage.getItem("language") != "AR" ? "3px !important" : '32px !important',
    },
  },
}));

const customStyles = {
  borderRadius: "10px",
  padding: "10px 4px",
};

const countryCode = [
  {
    value: "+966",
    label: "+966",
  },
  // {
  //   value: "+971",
  //   label: "+971",
  // },
];

const AddContactDetails = ({
  setContactDetailsValues,
  contactDetailsValues,
}) => {
  const { email, mobileNumber } = contactDetailsValues;
  const classes = muiStyles();
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const styles = useStyles();
  const { t } = useTranslation();
  const handleChange = (e) => {
    const value = e.target.value;
    setContactDetailsValues({ ...contactDetailsValues, email: value });
    const emailRegex = /^[A-Z0-9._%+-\u0600-\u06FF]+@[A-Z0-9.-\u0600-\u06FF]+\.[A-Z]{2,}$/i;

    if (!value) {
      setEmailError(`${t("errorMessages.emailRequired")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        emailErrorExists: true,
        email: value,
      });
    } else if (!emailRegex.test(value)) {
      setEmailError(`${t("addContactDetailsScreenHotel.enterValidEmail")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        emailErrorExists: true,
        email: value,
      });
    } else {
      setEmailError("");
      setContactDetailsValues({
        ...contactDetailsValues,
        emailErrorExists: false,
        email: value,
      });
    }
  };

  // const handleMobileNumberChange = (event) => {
  //   const value = event.target.value;
  //   const mobileNumberRegex = /^[0-9]*$/;

  //   if (!value) {
  //     setMobileNumberError("Mobile number is required.");
  //     setContactDetailsValues({
  //       ...contactDetailsValues,
  //       mobileNumberErrorExists: true,
  //       mobileNumber: value,
  //     });
  //   } else if (!mobileNumberRegex.test(value)) {
  //     setMobileNumberError("Only numbers are allowed.");
  //     setContactDetailsValues({
  //       ...contactDetailsValues,
  //       mobileNumberErrorExists: true,
  //       mobileNumber: value,
  //     });
  //   } else {
  //     setMobileNumberError("");
  //     setContactDetailsValues({
  //       ...contactDetailsValues,
  //       mobileNumberErrorExists: false,
  //       mobileNumber: value,
  //     });
  //   }
  // };
  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    const mobileNumberRegex = /^5\d{8}$/;

    if (!value) {
      setMobileNumberError(
        `${t("addContactDetailsScreenHotel.mobileNumberIsRequired")}`
      );
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: true,
        mobileNumber: value,
      });
    } else if (!mobileNumberRegex.test(value)) {
      // if (value.length > 10) {
      //   setMobileNumberError("Invalid mobile number. Should be 10 digits only.");
      // } else {
      //   setMobileNumberError("Only 10-digit numbers are allowed.");
      // }
      setMobileNumberError(
        `${t("addContactDetailsScreenHotel.enterValidNumber")}`
      );
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: true,
        mobileNumber: value,
      });
    } else {
      setMobileNumberError("");
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: false,
        mobileNumber: value,
      });
    }
  };

  const handleKeyPress = (event) => {
    const keyPressed = event.key;

    if (
      keyPressed === "+" ||
      keyPressed === "-" ||
      keyPressed === "e" ||
      keyPressed === "."
    ) {
      event.preventDefault();
    }
  };
  return (
    <Box className={styles.addContactDetailsContainer}>
      <Box sx={{ display: "inline-flex", gap: "10px" }}>
        <ReactSVG src={CircularCheckSvg} style={{ marginTop: "4px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography
            sx={{
              color: "#344054",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            {t("addContactDetailsScreenHotel.addContactDetails")}
          </Typography>
          <Typography
            sx={{
              color: "#525A6A",
              fontFamily: "Lato",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            {t("addContactDetailsScreenHotel.bookingDetailsNotification")}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.contentContainer}>
        <Box className={styles.contactsDetailsCard}>
          <Box className={styles.fieldContainer}>
            <div style={{ display: "flex" }}>
              <Typography className={styles.labelStyle}>
                {t("addContactDetailsScreenHotel.mobileNumber")}
              </Typography>
              <p
                style={{
                  color: "red",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                *
              </p>
            </div>
            <Box sx={{ display: "inline-flex", gap: "16px" }}>
              <TextField
                className={classes.root}
                fullWidth
                sx={{ width: "60%", minHeight: "0px !important" }}
                size="small"
                select
                SelectProps={{ native: true }}
                defaultValue="+966"
                InputLabelProps={{
                  style: { textAlign: "center", marginTop: "10px" },
                }}
                InputProps={{ style: customStyles }}
              >
                {countryCode.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                ;
              </TextField>
              <TextField
                className={classes.root}
                sx={{ minHeight: "0px !important" }}
                size="small"
                placeholder={t("addContactDetailsScreenHotel.mobileNumber")}
                type="number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                error={mobileNumberError !== ""}
                helperText={mobileNumberError}
                onKeyPress={handleKeyPress}
                InputProps={{
                  style: customStyles,
                }}
                fullWidth
              ></TextField>
            </Box>
          </Box>
          <Box className={styles.fieldContainer}>
            <div style={{ display: "flex" }}>
              <Typography className={styles.labelStyle}>
                {t("addContactDetailsScreenHotel.email")}
              </Typography>
              <p
                style={{
                  color: "red",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                *
              </p>
            </div>
            <TextField
              className={classes.root}
              sx={{ minHeight: "0px !important" }}
              size="small"
              placeholder={t("addContactDetailsScreenHotel.enterYourEmailId")}
              value={email}
              onChange={handleChange}
              error={emailError !== ""}
              helperText={emailError}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ padding: "4px 4px 0px 8px" }}
                  >
                    <ReactSVG src={MailSvg} />
                  </InputAdornment>
                ),
                style: customStyles,
              }}
              fullWidth
            ></TextField>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AddContactDetails;
