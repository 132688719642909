import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FFF",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  heading: {
    color: "#344054",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
  },
  optionsContainer: {
    display: "inline-flex",
    gap: "8px",
    cursor: "pointer",
  },
  optionsText: {
    color: "#4037acd6",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Lato, sans-serif",
  },
  optionsIcon: {
    width: "16px",
    height: "16px",
    color: "#4037acd6",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 24px",
    backgroundColor: "#fff",
    marginTop: "18%",
    marginLeft: "34%",
    width: "32%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "none",

    [theme.breakpoints.down("sm")]: {
      width: "70%",
      marginTop: "35%",
      marginLeft: "15%",
    },
  },
}));

export default useStyles;
