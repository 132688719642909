import { createSlice } from "@reduxjs/toolkit";

const getHotelDetailsFromLocalStorage = () => {
  const hotel = JSON.parse(
    window.localStorage.getItem("modifySearchHotelDetails")
  );
  return hotel;
};

const hotel = getHotelDetailsFromLocalStorage();

const modifySearchHotelSlice = createSlice({
  name: "modifySearchHotel",
  initialState: {
    bookingType: "Hotel",
    hotelCity: hotel ? hotel?.hotelCity : "",
    hotelCheckInDate: hotel ? hotel?.hotelCheckInDate : "",
    hotelCheckOutDate: hotel ? hotel?.hotelCheckOutDate : "",
    searchedHotels: [],
    traceId: "",
    hotelNotFound: false,
    searchApplicable: false,
  },
  reducers: {
    updateModifyHotelCity: (state, action) => {
      state.hotelCity = action.payload;
    },
    updateModifyHotelDates: (state, action) => {
      const { checkInDate, checkOutDate } = action.payload;
      state.hotelCheckInDate = checkInDate;
      state.hotelCheckOutDate = checkOutDate;
    },
    addModifySearchedHotels: (state, action) => {
      state.searchedHotels = action.payload;
    },
    updateTraceId: (state, action) => {
      state.traceId = action.payload;
    },
    updateModifyHotelNotFound: (state, action) => {
      state.hotelNotFound = action.payload;
    },
    updateModifySearchHotelObject: (state, action) => {
      const { hotelCity, hotelCheckInDate, hotelCheckOutDate } = action.payload;
      state.hotelCity = hotelCity;
      state.hotelCheckInDate = hotelCheckInDate;
      state.hotelCheckOutDate = hotelCheckOutDate;
    },
    handleSearchApplicable: (state, action) => {
      state.searchApplicable = action.payload;
    },
  },
});

export const {
  updateModifyHotelCity,
  updateModifyHotelDates,
  addModifySearchedHotels,
  updateModifyHotelNotFound,
  updateModifySearchHotelObject,
  handleSearchApplicable,
  updateTraceId,
} = modifySearchHotelSlice.actions;

export default modifySearchHotelSlice.reducer;
