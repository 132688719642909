import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import {
  TimeFormat,
  addDurationStrings,
  calculateFlightDuration,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDuration,
  flightDurationInHoursAndMinutes,
  timeString,
} from "../../../../../../utils/helpers";
import { Box, Typography } from "@mui/material";
import {
  flightClassData,
  flightClassDataArabic,
} from "../../../../../../config";
import PlaceholderImage from "../../../../../../assets/images/logo.png";
import CheckSvg from "../../../../../../assets/images/baggage-check.svg";
import DashedSvg from "../../../../../../assets/images/flight-result-dashed.svg";
import FlightDetailsSvg from "../../../../../../assets/images/flight-details.svg";
import eventBus from "../../../../../../core/eventBus";
import EastIcon from "@mui/icons-material/East";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import closeIcon from "../../../../../../assets/images/x-close.svg";
import CheckPriceOneWay from "../../search-flight-result/one-way/check-price";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const FlightDetailsMobile = ({
  flight,
  segmentIndex = 0,
  showTotalFare = true,
  flightIndex,
  markup,
}) => {
  const {
    Fare: { TotalFare, BaseFare, VATAmount, Tax, OtherCharges },
    Segments,
    NonRefundable,
  } = flight;
  const {
    Duration: arrivalDuration,
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      DepTime,
    },
    DepartureTime: startDate,
    Destination: { ArrTime: layoverDepTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[segmentIndex][0];
  const {
    Duration: departureDuration,
    Origin: { DepTime: layoverArrTime },
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      ArrTime,
    },
    GroundTime,
    AccumulatedDuration: lastAccumulatedDuration,
  } = Segments[segmentIndex][Segments[segmentIndex]?.length - 1];
  const length = Segments[segmentIndex]?.length;
  const [showPriceAccordian, setShowPriceAccordian] = useState(false);
  const styles = useStyles();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format("ddd, DD MMM YY");
    return formattedDate;
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        borderRadius: "5px",
        background: "#FFF",
        outline: "none",
        height: "700px",
      }}
    >
      <Box sx={{ padding: "16px 18px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "0.50px",
              }}
            >
              {t("flightSearch.flightDetails")}
            </Typography>

            <ReactSVG
              src={closeIcon}
              onClick={() => eventBus.emit("close-bottomSheet")}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Box sx={{ display: "inline-flex", gap: "8px" }}>
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}
                >
                  {origin}
                </Typography>
                <EastIcon sx={{ width: "16px", height: "16px" }} />
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}
                >
                  {destination}
                </Typography>
              </Box>
              <Box sx={{ display: "inline-flex", gap: "3px" }}>
                <Typography
                  sx={{
                    color: "#667085",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {t("flightSearch.totalDuration")}{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#525A6A",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {/* {firstAccumulatedDuration > 0
                    ? flightDurationInHoursAndMinutes(firstAccumulatedDuration)
                    : lastAccumulatedDuration > 0
                    ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                    : flightDurationInHoursAndMinutes(departureDuration)} */}
                  {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
                  {convertAccumulatedTime(lastAccumulatedDuration)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                textAlign: "end",
              }}
            >
              <Typography
                sx={{
                  color: "#525A6A",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {" "}
                {!NonRefundable
                  ? `${t("flightSearch.partiallyRefundable")}`
                  : `${t("flightSearch.nonRefundable")}`}
              </Typography>
              <Typography
                sx={{
                  color: "#667085",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {startDate && formatDate(startDate)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "auto",
          height: "1px",
          background: "#D0D5DD",
          marginTop: "6px",
        }}
      ></Box>
      {Segments[segmentIndex]?.length &&
        Segments[segmentIndex].map((flightDetail, i) => {
          const {
            CabinClass,
            CabinBaggage,
            IncludedBaggage,
            ArrivalTime,
            DepartureTime,
            Baggage,
            AirlineDetails: { AirlineName, FlightNumber, AirlineCode },
            Destination: {
              AirportName: DestAirportName,
              CityName: DestCityName,
              CountryName: DestCountryName,
              Terminal: DestTerminal,
            },
            Origin: {
              AirportName: OriginAirportName,
              CityName: OriginCityName,
              CountryName: OriginCountryName,
              Terminal: OriginTerminal,

              // DepTime,
            },
            Duration,
          } = flightDetail;

          return (
            <Box key={i}>
              {i !== 0 && (
                <Box
                  sx={{
                    display: "inline-flex",
                    gap: "8px",
                    width: "100%",
                    padding: "18px 18px",
                  }}
                >
                  {/* <ReactSVG src={DashedSvg} /> */}
                  <Box
                    sx={{
                      display: "inline-flex",
                      borderRadius: "2px",
                      background: "#FFF7EA",
                      padding: "8px 2px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFA400",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      {t("flightSearch.changeOfPlanes")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      {/* {flightDurationInHoursAndMinutes(GroundTime)}{" "} */}
                      {TimeFormat(GroundTime)} {t("flightSearch.layoverIn")}{" "}
                      {Segments[segmentIndex]?.[i - 1]?.Destination?.CityName}
                    </Typography>
                  </Box>
                  {/* <ReactSVG src={DashedSvg} /> */}
                </Box>
              )}
              <Box
                sx={{
                  padding: "18px",
                  display: "inline-flex",
                  justifyContent: "space-between",
                  width: "100%",

                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      height: "61px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {DepartureTime && timeString(DepartureTime)}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#667085",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {DepartureTime && formatDate(DepartureTime)}
                    </Typography>
                  </Box>

                  {/* {(i === 0 && Duration > 0) ||
                  (i !== 0 && Duration !== lastAccumulatedDuration) ? (
                    <Box sx={{ height: "41px" }}>
                      <Box
                        sx={{
                          display: "inline-flex",
                          padding: "6px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          borderRadius: "6px",
                          background: "rgba(0, 136, 255, 0.15)",
                          color: "#08F",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "20px",
                          width: "100%",
                        }}
                      >
                        {flightDuration(Duration)}
                      </Box>
                    </Box>
                  ) : (
                    <div style={{ height: "41px" }}></div>
                  )} */}
                  <Box
                    sx={{
                      display: "inline-flex",
                      padding: "6px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      borderRadius: "6px",
                      background: "#E6E6FA",
                      color: "#1b1d52",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "20px",
                      width: "100%",
                    }}
                  >
                    {TimeFormat(Duration)}
                  </Box>
                  {/* <Box sx={{ height: "41px" }}>
                    <Box
                      sx={{
                        display: "inline-flex",
                        padding: "6px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        borderRadius: "6px",
                        background: "rgba(0, 136, 255, 0.15)",
                        color: "#08F",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                        width: "100%",
                      }}
                    >
                      {flightDuration(Duration)}
                    </Box>
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      height: "61px",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {ArrivalTime && timeString(ArrivalTime)}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#667085",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {ArrivalTime && formatDate(ArrivalTime)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "8px" }}>
                  <ReactSVG src={FlightDetailsSvg} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "61px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {OriginAirportName}
                    </Typography>
                    {OriginTerminal && (
                      <Typography
                        sx={{
                          color: "#344054",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {" "}
                        Terminal {OriginTerminal}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        color: "#667085",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {OriginCityName}, {OriginCountryName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "inline-flex",
                      height: "41px",
                      gap: "16px",
                    }}
                  >
                    <img
                      src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
                      onError={(e) => {
                        e.target.src = PlaceholderImage;
                      }}
                      style={{ width: "42px", height: "36px" }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "inline-flex", gap: "6px" }}>
                        <Typography
                          sx={{
                            color: "#344054",
                            fontFamily: "Lato, sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "20px",
                          }}
                        >
                          {AirlineName}
                        </Typography>
                        <Box
                          sx={{
                            width: "1px",
                            height: "auto",
                            // background: "#D0D5DD",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            color: "#667085",
                            fontFamily: "Lato, sans-serif",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {AirlineCode}-{FlightNumber}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color: "#667085",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {localStorage.getItem("language") == "AR"
                          ? flightClassDataArabic[CabinClass]
                          : CabinClass}{" "}
                        {t("flightSearch.class")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "61px",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {DestAirportName}{" "}
                    </Typography>
                    {DestTerminal && (
                      <Typography
                        sx={{
                          color: "#344054",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "20px",
                        }}
                      >
                        {" "}
                        Terminal {DestTerminal}{" "}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        color: "#667085",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      {" "}
                      {DestCityName}, {DestCountryName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "0px 18px 8px 18px",
                  display: "inline-flex",
                  gap: "38px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: "#EEFFE5",
                      borderRadius: "50%",
                    }}
                  >
                    <ReactSVG src={CheckSvg} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontStyle: "normal",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Lato, sans-serif",
                        lineHeight: "20px",
                      }}
                    >
                      {t("flightSearch.checkInBaggage")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        fontFamily: "Lato, sans-serif",
                        lineHeight: "20px",
                      }}
                    >
                      {IncludedBaggage} / {t("flightSearch.adult")}
                    </Typography>
                  </Box>
                </Box>
                {CabinBaggage && (
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: "#EEFFE5",
                        borderRadius: "50%",
                      }}
                    >
                      <ReactSVG src={CheckSvg} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontStyle: "normal",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          fontFamily: "Lato, sans-serif",
                          lineHeight: "20px",
                        }}
                      >
                        {t("flightSearch.cabinBaggage")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          fontFamily: "Lato, sans-serif",
                          lineHeight: "20px",
                        }}
                      >
                        {CabinBaggage} / {t("flightSearch.adult")}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      {showPriceAccordian && (
        <Box
          className={
            Segments[segmentIndex]?.length > 1
              ? styles.fareDetailsMore
              : styles.faredetailsOne
          }
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                paddingBottom: "12px",
                borderBottom: "1px solid #D0D5DD",
              }}
            >
              {t("flightSearch.fareDetails")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {t("flightSearch.baseFare")}
                </Typography>
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {t("flightSearch.currencySymbol")}{" "}
                  {(
                    Math.ceil(TotalFare * rateOfExchange) -
                    (Math.ceil(VATAmount * rateOfExchange) +
                      Math.ceil(Tax * rateOfExchange)) +
                    Math.ceil(OtherCharges * rateOfExchange) +
                    markup
                  ).toLocaleString("en-IN")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {t("flightSearch.taxesAndFees")}{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {t("flightSearch.currencySymbol")}{" "}
                  {Math.ceil(Tax * rateOfExchange).toLocaleString("en-IN")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                 {t("flightSearch.VAT")}{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {t("flightSearch.currencySymbol")}{" "}
                  {Math.ceil(VATAmount * rateOfExchange).toLocaleString(
                    "en-IN"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          width: "auto",
          height: "1px",
          background: "#D0D5DD",
          marginTop: "6px",
        }}
      ></Box>
      <Box
        sx={{
          display: "inline-flex",
          padding: "16px 18px",
          justifyContent: showTotalFare ? "space-between" : "flex-end",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            gap: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showPriceAccordian ? (
            <ExpandMoreIcon
              style={{ color: "#1b1d52" }}
              onClick={() => setShowPriceAccordian(!showPriceAccordian)}
            />
          ) : (
            <ExpandLessIcon
              style={{ color: "#1b1d52" }}
              onClick={() => setShowPriceAccordian(!showPriceAccordian)}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: "#667085",
                fontFamily: "Lato, sans-serif",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {t("flightSearch.totalPriceIncludingTax")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "normal",
              }}
            >
              {t("flightSearch.currencySymbol")}{" "}
              {(Math.ceil(TotalFare * rateOfExchange) + markup).toLocaleString(
                "en-IN"
              )}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              padding: "12px 16px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "8px",
              background: "#1b1d52",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => eventBus.emit("close-bottomSheet")}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {t("flightSearch.continue")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FlightDetailsMobile;

FlightDetailsMobile.propTypes = {
  segmentIndex: PropTypes.bool,
  handleBookNow: PropTypes.func,
  flight: PropTypes.object,
  showTotalFare: PropTypes.bool,
  totalNumberFlights: PropTypes.number,
  totalFLights: PropTypes.array,
  flightIndex: PropTypes.number,
};
