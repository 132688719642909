import React from "react";
import closeIcon from "../../../../../assets/images/x-close.svg";
import PropTypes from "prop-types";
import "./style.css";
import BaggageAccordianContant from "./baggage-info-accordian-content";
import { Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

const BaggageInfoPopUp = ({
  handleModalClose,
  baggage,
  setExtraBaggage,
  extraBaggage,
  index,
  toggle,
  setToggle,
}) => {
  const { adultCount, childrenCount } = JSON.parse(
    window.localStorage.getItem("flightTravellerDetails")
  );
  const { t } = useTranslation();
  const renderAdultAccordian = () => {
    const accordians = [];
    for (let i = 0; i < adultCount; i++) {
      accordians.push(
        <BaggageAccordianContant
          title={`${t("addBaggagePopUp.adult")} ${i + 1}`}
          baggage={baggage}
          selected={i}
          type={"AD"}
          setExtraBaggage={setExtraBaggage}
          extraBaggage={extraBaggage}
          index={index}
          toggle={toggle}
          setToggle={setToggle}
        />
      );
    }
    return accordians;
  };

  const renderChildrenAccordian = () => {
    const accordians = [];
    for (let i = 0; i < childrenCount; i++) {
      accordians.push(
        <BaggageAccordianContant
          title={`${t("addBaggagePopUp.children")} ${i + 1}`}
          baggage={baggage}
          selected={i}
          type={"CH"}
          setExtraBaggage={setExtraBaggage}
          extraBaggage={extraBaggage}
          index={index}
          toggle={toggle}
          setToggle={setToggle}
        />
      );
    }
    return accordians;
  };

  return (
    <Box sx={{ outline: "none" }}>
      <div className="baggage-info-parent-div">
        <div className="baggage-info-head-div">
          <div>{t("addBaggagePopUp.baggageInfoAddBaggage")}</div>
          <img
            src={closeIcon}
            alt="close icon"
            onClick={handleModalClose}
            style={{ cursor: "pointer" }}
          ></img>
        </div>
        <div>
          {renderAdultAccordian()}
          {renderChildrenAccordian()}
        </div>
      </div>
    </Box>
  );
};

export default BaggageInfoPopUp;

BaggageInfoPopUp.propTypes = {
  handleModalClose: PropTypes.func,
  baggage: PropTypes.any,
  setExtraBaggage: PropTypes.any,
  extraBaggage: PropTypes.any,
};
