import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

const NameInputField = ({
  classes,
  values,
  setValues,
  valid,
  setValid,
  customStyles,
  UserSvg,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const reg = new RegExp("^[a-zA-Z\u0600-\u06FF ]{0,19}$");
    let validStatuses = valid;
    validStatuses[0] = reg.test(e.target.value);
    setValid([...validStatuses]);
    setValues({ ...values, name: e.target.value });
  };

  return (
    <div className={`NameInputField`} style={{ width: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', top: '11px', left: '12px', padding: '0px 4px 0px 0px' }}>
        <ReactSVG src={UserSvg} />
      </div>
      <input
        type="text"
        size="small"
        placeholder={t("packages.enterName")}
        style={{
          ...customStyles,
          minHeight: '0px',
          alignItems: 'normal',
          width: '100%',
          paddingLeft: '36px', // Adjust padding to make room for the icon
          border: '1px solid #D1D5DD'
        }}
        value={values.name}
        onChange={handleChange}
        className={!valid[0] ? 'input-error' : ''}
      />
    </div>
  );
};

export default NameInputField;
