import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { Modal, Divider } from "@mui/material";
import FeeSvg from "../../../../assets/images/feemark.svg";
import CheckSvg from "../../../../assets/images/check_green.svg";
import CancelSvg from "../../../../assets/images/cancelmark.svg";
import ArrowSvg from "../../../../assets/images/right-arrow-black.svg";
import FairRulePopUp from "../../personal-details/cards/fair-rules-pop-up";
import "./styles.css";
import useStyles from "./styles";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../core/eventBus";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const paxMappingPlural = ["Adults", "Children", "Infants"];
const paxMapping = ["Adult", "Child", "Infant"];

const CartFareRules = ({
  flight = {},
  setTotalAmount,
  showWay,
  setBaseAmount,
  markup = 0,
}) => {
  if (Object.keys(flight).length === 0) return <></>;
  const [open, setOpen] = useState(false);
  const [showFare, setShowFare] = useState([true, false, false]);
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const handleMobileOpen = () => {
    eventBus.emit("open-bottomSheet", <FairRulePopUp flight={flight} />);
  };

  let {
    Segments,
    Fare: { TotalFare, BaseFare, Tax, OtherCharges, VATAmount },
    FareBreakdown,
    IsRefundable,
    MiniFareRules,
  } = flight;

  console.log("TotalFare", TotalFare);
  BaseFare = Math.ceil(TotalFare) - Math.ceil(Tax) - Math.ceil(VATAmount);
  const {
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
    },
  } = Segments[0][0];

  const {
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
    },
  } = Segments[0][Segments[0]?.length - 1];

  const handleShowFareClick = (index) => {
    const updatedShowFare = showFare.map((value, i) =>
      i === index ? !value : value
    );
    setShowFare(updatedShowFare);
  };

  useEffect(() => {
    setTotalAmount((prev) => prev + Math.ceil(TotalFare));
    setBaseAmount(
      (prev) => prev + Math.ceil(BaseFare) + Math.ceil(OtherCharges)
    );
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.fairRulesCancelContainer}>
        <div className={classes.fairRulesContainer}>
          <p className={classes.fairRulesText}>{t("fareRuleCard.fareRules")}</p>
          {showWay && (
            <div className={classes.originDestinationContainer}>
              <p className={classes.origin}>{origin}</p>
              <ReactSVG src={ArrowSvg} style={{ marginTop: "2px" }} />
              <p className={classes.destination}>{destination}</p>
            </div>
          )}
        </div>
        <div>
          <p
            className={classes.cancelAndChanges}
            onClick={isMobile ? handleMobileOpen : handleOpen}
          >
            {t("fareRuleCard.cancelAndChanges")}
          </p>

          <Modal open={open} onClose={handleClose}>
            <div className="fair-rule-pop-up-box" style={{ outline: "none" }}>
              <FairRulePopUp handleModalClose={handleClose} flight={flight} />
            </div>
          </Modal>
        </div>
      </div>
      <div className={classes.refundableNonRefundableContainer}>
        {IsRefundable ? (
          <div className={classes.refundableContainer}>
            <ReactSVG src={CheckSvg} />
            <p className={classes.refundableText}>
              {t("fareRuleCard.refundable")}
            </p>
          </div>
        ) : (
          <div className={classes.nonRefundable}>
            <ReactSVG src={CancelSvg} />
            <p className={classes.nonRefundableText}>
              {t("fareRuleCard.nonRefundable")}
            </p>
          </div>
        )}
        {MiniFareRules && MiniFareRules?.length > 0 && (
          <div className={classes.changeableWithFeeContainer}>
            <ReactSVG src={FeeSvg} />
            <p className={classes.changeableWithFee}>
              {" "}
              {t("fareRuleCard.changeableWithFees")}
            </p>
          </div>
        )}
      </div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <>
        <div className={classes.pricesContainer}>
          <p className={classes.baseFare}> {t("fareRuleCard.baseFare")}</p>
          <p className={classes.baseFarePrice}>
            {t("fareRuleCard.currencySymbol")}{" "}
            {(
              
                Math.ceil(TotalFare * rateOfExchange) -
                ((Math.ceil(VATAmount * rateOfExchange) +
                  Math.ceil(Tax * rateOfExchange)))
               + markup
            ).toLocaleString("en-IN")}
            {/* {(Math.ceil(BaseFare) + Math.ceil(OtherCharges)).toLocaleString(
              "en-IN"
            )} */}
          </p>
        </div>
        <div className={classes.taxesAndFeeContainer}>
          <p className={classes.taxesAndFees}>
            {t("fareRuleCard.taxesAndFees")}{" "}
          </p>
          <p className={classes.taxesAndFeesPrice}>
            {t("fareRuleCard.currencySymbol")}{" "}
            {Math.ceil(Tax * rateOfExchange).toLocaleString("en-IN")}
          </p>
        </div>
        <div className={classes.taxesAndFeeContainer}>
          <p className={classes.taxesAndFees}>
            {t("fareRuleCard.vat")}{" "}
            {/* VAT */}
          </p>
          <p className={classes.taxesAndFeesPrice}>
            {t("fareRuleCard.currencySymbol")}{" "}
            {Math.ceil(VATAmount * rateOfExchange).toLocaleString("en-IN")}
          </p>
        </div>
      </>
      {/* <p className={classes.fareBreakdown}>Fare Breakdown</p>
      {FareBreakdown &&
        FareBreakdown?.map((fare, index) => {
          const { PassengerCount, PassengerType, Tax, BaseFare } = fare;
          return (
            <div style={{ width: "100%" }} key={index}>
              <div className={classes.innerContainer}>
                <div className={classes.passengerCountAndFairContainer}>
                  <div className={classes.passengerCountContainer}>
                    <p className={classes.passengerCount}>
                      {PassengerCount > 1
                        ? paxMappingPlural[PassengerType - 1]
                        : paxMapping[PassengerType - 1]}{" "}
                      ({PassengerCount})
                    </p>
                    {showFare[index] === true ? (
                      <ExpandLess
                        style={{ color: "#1B1D52" }}
                        onClick={() => handleShowFareClick(index)}
                      />
                    ) : (
                      <ExpandMore
                        style={{ color: "#1B1D52" }}
                        onClick={() => handleShowFareClick(index)}
                      />
                    )}
                  </div>
                  <p className={classes.price}>
                    ₹ {Math.ceil(BaseFare) + Math.ceil(Tax)}
                  </p>
                </div>
                {showFare[index] === true && (
                  <>
                    <div className={classes.pricesContainer}>
                      <p className={classes.baseFare}>Base Fare</p>
                      <p className={classes.baseFarePrice}>
                        ₹ {Math.ceil(BaseFare)}
                      </p>
                    </div>
                    <div className={classes.taxesAndFeeContainer}>
                      <p className={classes.taxesAndFees}>Taxes & fees </p>
                      <p className={classes.taxesAndFeesPrice}>
                        ₹ {Math.ceil(Tax)}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })} */}
    </div>
  );
};
export default CartFareRules;

CartFareRules.propTypes = {
  flight: PropTypes.object,
  insurance: PropTypes.object,
  applyInsurance: PropTypes.bool,
  setTotalAmount: PropTypes.func,
  setBaseAmount: PropTypes.func,
  showWay: PropTypes.bool,
  calculateExtraBaggagePriceValue: PropTypes.any,
  setPrice: PropTypes.any,
};
