import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
// import FaqBanner from '../../../assets/images/faq.png';
import AddSvg from "../../../assets/images/faq-add.svg";
import MinusSvg from "../../../assets/images/faq-minus.svg";
import SearchSvg from "../../../assets/images/search-normal.svg";
import ArrowRightSvg from "../../../assets/images/arrow-right.svg";
import { getFaqs } from "../../../modules/home";
import { useSelector } from "react-redux";
import "./index.css";
import { Rings } from "react-loader-spinner";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import useStyles from "./style";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";
const useStylesCustom = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
}));

const customStyles = {
  borderRadius: "12px",
  padding: "4px 2px",
  background: "#FFF",
  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#525A6A",
  fontFamily: "Lato, sans-serif",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
};

const Faq = () => {
  const classes = useStylesCustom();
  const navigate = useNavigate();
  const {t}  = useTranslation()
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const styles = useStyles();
  const isMobile = useIsMobile();
  const [faqs, setFaqs] = useState([]);
  const [faqsMap, setFaqsMap] = useState([]);

  const [categoryTab, setCategoryTab] = useState("");
  const faqsCategories = Object.keys(faqsMap);
  const [showDetails, setShowDetails] = useState([]);
  const handleClick = (index) => {
    showDetails[index] = !showDetails[index];
    setShowDetails([...showDetails]);
  };

  useEffect(() => {
    handleGetFaqs();
  }, []);

  const handleGetFaqs = async () => {
    const data = await getFaqs(user?.token);
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    } else {
      setFaqs(data?.faqs);
      const faqs = data?.faqs;
      const faqsMap = {};
      data?.faqs?.forEach((faq) => {
        const faqCategory = faq?.categoryName;
        if (faqsMap[faqCategory]) {
          faqsMap[faqCategory].push(faq);
        } else {
          faqsMap[faqCategory] = [faq];
        }
      });

      setFaqsMap(faqsMap);

      const faqsCategories = Object.keys(faqsMap);
      const initialCategoryToShow = faqsCategories[0];
      if (faqs.length != 0) {
        setCategoryTab(initialCategoryToShow);
      }
      setShowDetails(
        faqsMap[initialCategoryToShow]?.map((category, index) => {
          if (index == 0) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "#FAFAFA",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            gap: "8px",
            width: "80%",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              color: "#667085",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            {/* Home */}
            {t("navigationDrawer.home")}
          </Typography>
          <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => navigate("/faq")}
          >
            {/* FAQ */}
            {t("navigationDrawer.faq")}
          </Typography>
        </Box>

        {faqsCategories?.length ? (
          <div style={{ paddingBottom: "50px", width: "80%" }}>
            <h4
              style={{
                textAlign: "center",
                pointerEvents: "initial",
                boxSizing: "border-box",
                color: "inherit",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "bolder",
                fontSize: isMobile ? "1rem" : "1.888rem",
                lineHeight: "1.2",
                marginTop: "20px",
                marginBottom: "1rem",
              }}
            >
              {/* Frequently Asked Questions */}
              {t("navigationDrawer.faqQuestion")}
            </h4>
            <div className="u-tabs-1 tabHead">
              <ul
                className="u-unstyled"
                role="tablist"
                style={{
                  display: "flex",
                  flexGrow: "1",
                  listStyle: "none",
                  margin: "20px 0",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {faqsCategories.map((faqCategory) => {
                  return (
                    <li
                      style={{
                        // flex: 1,
                        textAlign: "center",
                        background:
                          faqCategory == categoryTab ? "#1b1d52" : "grey",
                        // height: "40px",
                        marginRight: "5px",
                        color: "white",
                        fontSize: isMobile ? "12px" : "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "8px 12px",
                        // minWidth: "70px",
                      }}
                      className={styles.faqCategory}
                      key={faqCategory}
                      onClick={() => {
                        setCategoryTab(faqCategory);
                        setShowDetails(
                          faqsMap[faqCategory].map((category, index) => {
                            if (index == 0) {
                              return true;
                            } else {
                              return false;
                            }
                          })
                        );
                      }}
                    >
                      {faqCategory}
                    </li>
                  );
                })}
              </ul>
              <div className="u-tab-content">
                {faqsCategories.map((faqCategory) => {
                  return faqsMap[faqCategory].map((faq, i) => {
                    const { _id, question, answer } = faq;
                    return (
                      <div
                        key={_id}
                        style={{
                          display: faqCategory == categoryTab ? "flex" : "none",
                          flexDirection: "column",
                          borderBottom: "1px solid #E5E5E5",
                          background: "#FFF",
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline-flex",
                            gap: "16px",
                            padding: "16px 16px 16px 16px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick(i)}
                        >
                          {showDetails[i] ? (
                            <ReactSVG
                              src={MinusSvg}
                              style={{ marginTop: "4px" }}
                            />
                          ) : (
                            <ReactSVG
                              src={AddSvg}
                              style={{ marginTop: "4px" }}
                            />
                          )}
                          <Typography
                            sx={{
                              color: "#344054",
                              fontFamily: "Lato, sans-serif",
                              fontSize: isMobile ? "12px" : "16px",
                              fontWeight: "600",
                              fontStyle: "normal",
                            }}
                          >
                            {question}
                          </Typography>
                        </Box>
                        {showDetails[i] && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                              padding: "0px 16px 24px 16px",
                            }}
                          >
                            <Divider />
                            <Typography
                              sx={{
                                color: "#525A6A",
                                fontFamily: "Lato, sans-serif",
                                fontSize: isMobile ? "12px" : "14px",
                                fontWeight: "400",
                                fontStyle: "normal",
                                lineHeight: "18px",
                                textAlign: "justify",
                              }}
                            >
                              {answer}
                            </Typography>
                          </Box>
                        )}
                      </div>
                    );
                  });
                })}
                <div className="u-tab-active">
                  <div className="u-container-layout-1">
                    <div className="u-accordion u-spacing-10 u-accordion-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!faqs ? (
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  // minHeight: "50vh",
                }}
              >
                <p>Not found</p>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "50vh",
                }}
              >
                <Rings height={80} width={80} color="#1B1D52" />
              </div>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Faq;
