import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { get } from "lodash";
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';

const HotelImages = (props) => {
  const images = get(props, "images", []);
  return (
    <>
      <div style={{ margin: "10px" }}>
        <div style={{ borderRadius: "5px", margin: "10px", height: "500px" }}>
          <Grid
            container
            justifyContent="center"
            spacing={0.5}
            style={{ height: "500px", maxHeight: "500px" }}
          >
            <Grid item xs={6} style={{ height: "500px" }}>
              <img src={images[0]} style={{ height: "100%", width: "100%" }} />
            </Grid>
            <Grid
              container
              item
              xs={6}
              spacing={0.5}
              style={{ height: "500px" }}
            >
              <Grid item xs={6} style={{ height: "250px" }}>
                <img
                  src={images[1]}
                  style={{ height: "100%", width: "100%", objectFit: "fit" }}
                />
              </Grid>
              <Grid item xs={6} style={{ height: "250px" }}>
                <img
                  src={images[2]}
                  style={{ height: "100%", width: "100%", objectFit: "fit" }}
                />
              </Grid>
              <Grid item xs={6} style={{ height: "250px" }}>
                <img
                  src={images[3]}
                  style={{ height: "100%", width: "100%", objectFit: "fit" }}
                />
              </Grid>
              <Grid item xs={6} style={{ height: "250px" }}>
                <img
                  src={images[4]}
                  style={{ height: "100%", width: "100%", objectFit: "fit" }}
                />
              </Grid>

              {/* <Item>
            <img src={images[0]}  style={{height: '200px', width:'200px'}}/>
          </Item> */}
            </Grid>
          </Grid>
          <div style={{ position: "relative", bottom: "40px", left: "80%" }}>
            <Button
              variant="contained"
              style={{ background: "rgba(255,255,255,0.9)", color: "black" }}
            >
              {`See all ${images.length} photos`}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelImages;
