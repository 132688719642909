import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    width: "100%",
    height: "150px",
    [theme.breakpoints.down("xl")]: {
      height: "150px",
    },

    [theme.breakpoints.down("lg")]: {
      height: "150px",
    },
    [theme.breakpoints.down("md")]: {
      height: "130px",
    },

    [theme.breakpoints.down("sm")]: {
      height: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "80px",
    },
  },

  carouselImage: {
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
}));

export default useStyles;
