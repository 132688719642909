import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  fairRulesCancelContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  fairRulesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  fairRulesText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
  },
  originDestinationContainer: {
    display: "inline-flex",
    gap: "8px",
  },
  origin: {
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#344054",
  },
  destination: {
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#344054",
  },
  cancelAndChanges: {
    color: "#4037acd6",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
  },
  refundableNonRefundableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  refundableContainer: {
    display: "inline-flex",
    gap: "8px",
  },
  refundableText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  nonRefundable: {
    display: "inline-flex",
    gap: "8px",
  },
  nonRefundableText: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  changeableWithFeeContainer: {
    display: "inline-flex",
    gap: "8px",
  },
  changeableWithFee: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  fareBreakdown: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "capitalize",
  },
  passengerCountAndFairContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  passengerCountContainer: {
    display: "inline-flex",
    gap: "6px",
    alignItems: "center",
  },
  passengerCount: {
    color: "#08F",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  price: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  pricesContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  baseFare: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFarePrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxesAndFeeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFees: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxesAndFeesPrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  insuranceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tripInsurance: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tripInsuranceAmount: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baggageContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  extraBaggage: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  extraBaggagePrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));

export default useStyles;
