import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bookFlightMobile: {
    display: "flex",
    padding: "16px 16px",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    margin: "0px 25px",

    // [theme.breakpoints.up("sm")]: {
    //   display: "inline-flex",
    //   flexDirection: "row",
    // },
  },
  dynamicContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      display: "inline-flex",
      flexDirection: "row",
      flex: 2.5,
      justifyContent: "space-between",
    },
  },
  flightDetailsCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      flex: 1.6,
    },
  },

  fairDetailscard: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flex: 0.4,
    },
  },
  airLinesDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  flightFromDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  flightTime: {
    display: "flex",
    height: "32px",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  flightToDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },

  baggageOption: {
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },

  fairText: {
    display: "flex",
    width: "63px",
    padding: "4px 0px",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "16px",
  },

  airLinesName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },

  airlineNameLayout: {
    display: "flex",
    width: "51px",
    flexDirection: "column",
    alignItems: "start",
    gap: "4px",
  },

  airLineCode: {
    color: " #667085",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  departureDate: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    display: "flex",
  },

  flightFrom: {
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  flightDuration: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    marginRight: "20px",
  },
  durationTime: {
    color: "var(--heading, #344054)",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginLeft: "4px",
  },
  flightTravelType: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  flightTravelTypeText: {
    color: "#1DAC08",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },

  baggageTextLayout: {
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },
  baggageText: {
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  baggageOptionLayout: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  fairTextLayout: {
    color: "var(--heading, #344054)",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    display: "flex",
    gap: '5px'
  },

  buttonLayout: {
    display: "flex",
    padding: "10px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    background: "#1b1d52",
    border: "none",
    color: "#fff",
    width: "100%",
    alignSelf: "center",
  },
}));

export default useStyles;
