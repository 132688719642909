import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  totalPersons: {
    height: "55px",
    width: "inherit",
    borderRadius: "0px 0px 8px 8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    display: "flex",
    gap: "8px",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px",
    },
  },

  totalPersonsIcon: {
    height: "16px",
    width: "16px",
    margin: "16px 0px 12px 8px",
    alignItems: "center",
  },

  totalPersonsDropdown: {
    height: "17px",
    width: "calc(100% - 40px)",
    margin: "18px 8px 18px 8px",
    background: "#FFF",
    display: "flex",
    cursor: "pointer",
  },

  totalPersonsDropdownLabel: {
    height: "17px",
    width: "calc(100% - 30px)",
    color: "#222936",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "'Lato', sans-serif",
    cursor: "pointer",
  },

  totalPersonsDropdownIcon: {
    height: "16px",
    width: "16px",
    color: "#344054",
    marginRight: "8px",
  },
}));

export default useStyles;
