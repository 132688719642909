import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mapWrapper: {
    padding: "0 16px 24px 16px ",
    boxSizing: "border-box",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "4px",
    height: "100%",
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      // width: "400px",
      maxWidth: "100%",
      height: "400px",
    },
  },
  mapTitle: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "16px",
    paddingTop: '20px'
  },
  mapCaption: {
    fontSize: "24px",
    fontWeight: 700,
  },
  MapContainer: {
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   maxWidth: "400px",
    // },
  },
  map: {
    width: "100% !important",
    height: "290px !important",
    marginRight: '16px',
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      height: "300px !important",
      display: "block",
      margin: "0 auto",
      // maxWidth: '95% !important'
    },
  },
}));

export default useStyles;
