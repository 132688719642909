import React, { useEffect, useState } from "react";
import "./style.css";
import CopyOffer from "../../../../assets/images/copy_icon_blue.svg";
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getOffers } from "../../../../modules/home";
import { useNavigate } from "react-router";
import { ReactSVG } from "react-svg";
import ArrowRightSvg from "../../../../assets/images/arrow-right.svg";
import { useSelector } from "react-redux";
import useStyles from "./style";
import Grid from "@mui/material/Grid";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;
import { ToastContainer, toast } from "react-toastify";
import useIsTablet from "../../../../hooks/useIsTablet.hook";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles({

// });
const MoreOffers = () => {
  const classes = useStyles();
  const [offers, setOffers] = useState([]);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  useEffect(() => {
    handleOffers();
  }, []);

  const handleOffers = async () => {
    const data = await getOffers({ limit: 100, skip: 0, token: user?.token });
    setOffers(data.offers);
  };

  const handleViewAllClick = (htmlContent) => {
    navigate("/offers-details", { state: { htmlContent } });
  };

  const handleCopyClick = (couponCode) => {
    try {
      navigator.clipboard
        .writeText(couponCode)
        .then(() => {
          toast.success(`${t("offers.couponCodeCopied")}`);
        })
        .catch((error) => {
          console.error("Unable to copy coupon code:", error);
        });
    } catch (error) {
      console.error("Clipboard API not available:", error);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.subContainer}>
        <Box
          sx={{
            background: " #FAFAFA",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box className={classes.container}>
            <Box sx={{ display: "inline-flex", gap: "8px" }}>
              <Typography
                sx={{
                  color: "#667085",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                {/* Home */}
                {t("offers.home")}
              </Typography>
              <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
              <Typography
                sx={{
                  color: "#4037acd6",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/offers")}
              >
                {/* Offers */}
                {t("offers.offers")}
              </Typography>
            </Box>
          </Box>

          <div className={classes.moreOfferContainer}>
            {offers &&
              offers.map((offer) => {
                const {
                  _id,
                  name,
                  description,
                  imageUrl,
                  couponCode,
                  product,
                  validEndDate,
                  html,
                } = offer;
                const isoDate = new Date(validEndDate);
                const istDateOnlyString = isoDate.toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                });

                const fullDateArabic = isoDate.toLocaleString("ar", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
                return (
                  <Box
                    className={classes.offerCard}
                    key={_id}
                  // onClick={() => handleViewAllClick(html)}
                  >
                    <Box
                      sx={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: "193px",
                        backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        backgroundPosition: "contain",
                        backgroundSize: "cover",
                      }}
                    ></Box>
                    <div className={classes.couponContentsContainer}>
                      <Box className={classes.cardContent}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#1b1d52",
                              fontFamily: "Lato",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              borderRadius: "4px",
                              background: "#E6E6FA",
                              padding: "6px 10px",
                            }}
                          >
                            {product}
                          </Typography>
                          <Typography
                            sx={{
                              color: " #525A6A",
                              fontFamily: "Lato",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 300,
                            }}
                          >
                            {t("offers.validTill")} :
                            {
                              localStorage.getItem('language') == "AR"
                                ? fullDateArabic
                                : istDateOnlyString
                            }
                          </Typography>
                        </Box>
                        <Box sx={{ marginTop: "16px" }}>
                          <Typography
                            sx={{
                              color: "#344054",
                              fontFamily: "Lato",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: 600,
                            }}
                          >
                            {name}
                          </Typography>
                          <Box sx={{ height: "34px" }}>
                            <Typography
                              className={classes.ellipsis}
                              sx={{
                                color: "#525A6A",
                                fontFamily: " Lato",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 300,
                                marginTop: "6px",
                              }}
                            >
                              {description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "inline-flex",
                            justifyContent: "space-between",
                            // width: "100%",
                            maxWidth: "100%",
                            alignItems: "center",
                            marginTop: "32px",
                          }}
                        >
                          <Box
                            sx={{
                              marginTop: "10px",
                              display: "inline-flex",
                              gap: "8px",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                              backgroundColor: "#E6E6FA",
                              border: "1px dashed #1b1d52",
                              padding: "4px 8px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#1b1d52",
                                fontFamily: "Lato, sans-serif",
                                fontSize: "14px",
                                fontWeight: "700",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {couponCode}
                            </Typography>
                            <Divider
                              orientation="vertical"
                              sx={{
                                height: "18px",
                                border: "1px dashed #1b1d52",
                              }}
                            />
                            <ReactSVG
                              src={CopyOffer}
                              onClick={() => handleCopyClick(couponCode)}
                              style={{
                                color: "#1b1d52",
                                width: "18px",
                                height: "18px",
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                          <div
                            className={classes.viewDetails}
                            onClick={() => handleViewAllClick(html)}
                          >
                            {t("offers.viewDetails")}
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                );
              })}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default MoreOffers;
