import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "16px",
    background: "#FFF",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    color: "#4037acd6",
  },
  infoContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  },
  bookingId: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    color: "#667085",
  },
  bookingDateTitle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    color: "#667085",
  },
  bookingDate: {
    color: "#344054",
  },
}));

export default useStyles;
