import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { validateCoupon } from "../../../../../modules/flight";
import handleUnauthorisedLogout from "../../../../../modules/unauthorised";
import { setUserInfo } from "../../../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CouponCode = ({
  couponDetails,
  setCouponValue,
  traceId,
  setMessage,
  coupon,
  setIsCouponApplied,
  tripType,
  flightDetails,
  markup,
  markup2,
  setDiscountValue,
  isCouponApplied,
  setCoupon,
}) => {
  const { couponCode, ruleName, _id, successMessage } =
    couponDetails &&
    couponDetails.lowestAmountCoupon &&
    couponDetails.lowestAmountCoupon.length > 0 &&
    couponDetails.lowestAmountCoupon[0];
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rateOfExchange = useSelector((state) => state?.rateOfExchange?.rate);

  const handleCouponCode = async () => {
    try {
      const txnAmount = getTxnAmount();
      setCoupon(couponCode);
      const data = await validateCoupon({
        traceId,
        coupon: couponCode,
        token: user?.token,
        flight: couponDetails.payload,
      });
      const statusCode = data?.statusCode;
      const message = data?.message;
      if (statusCode == 401) {
        handleUnauthorisedLogout(message);
        dispatch(setUserInfo({}));
        navigate("/");
      }
      if (data?.valid == true) {
        if (tripType == "DRT") {
          calculateCouponAmount({
            flightDetails,
            markupValue: markup + markup2,
            tripType,
          });
        } else {
          calculateCouponAmount({
            flightDetails,
            markupValue: markup,
            tripType,
          });
        }
        setMessage(successMessage);
        setIsCouponApplied(true);
        toast.success(t("successMessages.couponAppliedSuccessfully"));
      } else {
        toast.error(t("errorMessages.enterValidCoupon"));
      }
    } catch (err) {
      console.log("err 73", err);
    }
  };

  const getTxnAmount = () => {
    if (tripType == "DRT") {
      return Math.ceil(
        flightDetails[0]?.Fare?.PublishedFare +
          flightDetails[1]?.Fare?.PublishedFare +
          markup +
          markup2
      );
    } else return Math.ceil(flightDetails[0]?.Fare?.TotalFare + markup);
  };

  const calculateCouponAmount = ({ flightDetails, markupValue, tripType }) => {
    let amt = 0;
    let baseFare = 0;
    let TotalFare = 0;
    if (tripType == "DRT") {
      baseFare =
        flightDetails[0]?.Fare?.BaseFare +
        flightDetails[1]?.Fare?.BaseFare +
        flightDetails[0]?.Fare?.OtherCharges +
        flightDetails[0]?.Fare?.OtherCharges +
        markupValue;
      TotalFare =
        flightDetails[0]?.Fare?.TotalFare +
        flightDetails[1]?.Fare?.TotalFare +
        markupValue;
    } else {
      baseFare =
        Math.ceil(flightDetails[0].Fare.TotalFare * rateOfExchange) -
        (Math.ceil(flightDetails[0].Fare.Tax * rateOfExchange) +
          Math.ceil(flightDetails[0].Fare.VATAmount * rateOfExchange)) +
        flightDetails[0].Fare.OtherCharges +
        markup;
      TotalFare =
        Math.ceil(flightDetails[0].Fare.TotalFare * rateOfExchange) + markup;
    }
    const { amountType, amount, applyTo, maxAmount } =
      couponDetails.lowestAmountCoupon[0];
    if (amountType === "FLAT") {
      amt = amount;
    } else {
      if (applyTo === "BASEFARE") {
        amt = (amount * (baseFare * rateOfExchange)) / 100;
      } else {
        amt = (amount * TotalFare) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    amt = Math.ceil(amt);
    setDiscountValue(amt);
    // console.log("couponAmount", TotalFare);
  };

  const {t} = useTranslation()
  return (
    <div className={classes.mainContainer} key={_id}>
      <div className={classes.couponContainer}>
        <p className={classes.couponName}>{couponCode}</p>
        {!isCouponApplied && (
          <p 
          className={classes.applyText} 
          onClick={handleCouponCode}
          style={{
            marginLeft: localStorage.getItem("language") == "AR"? "15px" : '0px'

          }}
          >
            {/* Apply */}
            {t("coupon.apply")}
          </p>
        )}
      </div>
      <p className={classes.description}>{ruleName}</p>
    </div>
  );
};

export default CouponCode;

CouponCode.propTypes = {
  couponDetails: PropTypes.any,
  setCouponValue: PropTypes.any,
  traceId: PropTypes.any,
  setMessage: PropTypes.any,
  setIsCouponApplied: PropTypes.any,
};
