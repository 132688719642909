import React from "react";
import PhoneSvg from "../../../../../../../../../assets/images/Iconphonecall.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

function AirlineContact({ airlineContactNumber, airLineCode }) {
  const { t } = useTranslation();
  return (
    <>
      {airlineContactNumber && (
        <div
          style={{
            padding: "16px",
            border: "1px solid #D0D5DD",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            background: "#fff",
          }}
        >
          <p style={{ fontWeight: "700", color: "#344054" }}>
            {t("myProfile.airlineContact")}
          </p>
          <div
            style={{
              display: "inline-flex",
              gap: "8px",
            }}
          >
            <ReactSVG src={PhoneSvg} />
            <p
              style={{
                fontWeight: "500",
                fontSize: "16px",
                color: "#344054",
              }}
            >
              {airlineContactNumber}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default AirlineContact;
