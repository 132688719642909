// import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import DOMPurify from "dompurify";
// import { ReactSVG } from "react-svg";
// import ArrowRightSvg from "../../../../../assets/images/arrow-right.svg";
// import { Box, Typography } from "@mui/material";
// import useIsMobile from "../../../../../hooks/useIsMobile.hook";
// import './offer.css'

// const OffersHtml = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { htmlContent } = location.state;
//   const isMobile = useIsMobile();

//   return (
//     <>
//       <Box
//         sx={{
//           marginBottom: "80px",
//           padding: isMobile ? "24px 20px" : "24px 150px",
//           display: "flex",
//           flexDirection: "column",
//           gap: "24px",
//         }}
//       >
//         <Box sx={{ display: "inline-flex", gap: "8px", alignItems: "center" }}>
//           <Typography
//             sx={{
//               color: "#667085",
//               fontFamily: "Lato, sans-serif",
//               fontSize: "14px",
//               fontStyle: "normal",
//               fontWeight: "400",
//               cursor: "pointer",
//             }}
//             onClick={() => navigate("/")}
//           >
//             Home
//           </Typography>
//           <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
//           <Typography
//             sx={{
//               color: "#08F",
//               fontFamily: "Lato, sans-serif",
//               fontSize: "14px",
//               fontStyle: "normal",
//               fontWeight: "400",
//               cursor: "pointer",
//             }}
//             onClick={() => navigate("/offers")}
//           >
//             Offers
//           </Typography>
//         </Box>
//         <div
//           style={{
//             width: "100%",
//           }}
//           dangerouslySetInnerHTML={{
//             __html: DOMPurify.sanitize(
//               htmlContent || "<h1>Page not found</h1>"
//             ),
//           }}
//         />
//       </Box>
//     </>
//   );
// };

// export default OffersHtml;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { ReactSVG } from "react-svg";
import ArrowRightSvg from "../../../../../assets/images/arrow-right.svg";
import { Box, Typography } from "@mui/material";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import "./offers.css";
import { useTranslation } from "react-i18next";
const sanitizeHTML = (htmlContent) => {
  const sanitizedHTML = DOMPurify.sanitize(
    htmlContent || "<h1>Page not found</h1>",
    {
      ALLOWED_ATTR: [
        "src",
        "alt",
        "colspan",
        "rowspan",
        "border",
        "cellpadding",
        "cellspacing",
        "align",
        "bgcolor",
        "valign",
        "background",
        "frame",
        "rules",
        "height",
        "width",
        "bordercolor",
        "bordercolordark",
        "bordercolorlight",
        "cellpadding",
        "cellspacing",
        "align",
        "char",
        "charoff",
        "valign",
        "abbr",
        "axis",
        "headers",
        "scope",
        "rowspan",
        "colspan",
        "nowrap",
        "bgcolor",
        "background",
        "border",
        "bordercolor",
        "bordercolordark",
        "bordercolorlight",
        "align",
        "char",
        "charoff",
        "valign",
        "cellpadding",
        "cellspacing",
        "width",
        "height",
      ],
    }
  );

  const wrappedHTML = sanitizedHTML.replace(
    /<table/g,
    '<div class="wrappedtable"><table'
  );
  const finalHTML = wrappedHTML.replace(/<\/table>/g, "</table></div>");
  // const finalWithImages = finalHTML.replace(/<img/g, '<img');
  const finalWithImages = finalHTML;

  return finalWithImages;
};
const OffersHtml = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { htmlContent } = location.state;
  const isMobile = useIsMobile();
  const {t} = useTranslation()
  return (
    <>
      <Box
        sx={{
          marginBottom: "80px",
          padding: isMobile ? "24px 20px" : "24px 150px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box sx={{ display: "inline-flex", gap: "8px", alignItems: "center" }}>
          <Typography
            sx={{
              color: "#667085",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            {/* Home */}
            {t("offers.home")}
          </Typography>
          <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => navigate("/offers")}
          >
            {/* Offers */}
            {t("offers.offers")}

          </Typography>
        </Box>
        <div
          style={{
            width: "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(htmlContent),
          }}
        />
      </Box>
    </>
  );
};
export default OffersHtml;
