import { get, noop } from "lodash";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { CalendarMonth } from "@mui/icons-material";
import eventBus from "../../../core/eventBus";
import { Calendar } from "react-date-range";
import { useSelector } from "react-redux";
import * as locales from 'react-date-range/dist/locale';

const MobileCalenderComp = (props) => {
  const [values, setValues] = useState({
    formattedValue: "",
    rawValue: new Date(),
  });
  const styles = useStyles();
  const isDisabled = get(props, "disabled", false);
  const placeholder = get(props, "placeholder", "");
  const onDateChange = get(props, "onDateChange", noop);
  const formatDate = get(props, "formatDate", undefined);
  const selectedDate = get(props, "selectedDate", "");
  let minDate = get(props, "minDate", new Date());

  let minimumDate;
  if (minDate.toString().includes("/")) {
    minDate = minDate.split("/");
    minimumDate = new Date(
      parseInt(minDate[2], 10),
      parseInt(minDate[1], 10) - 1,
      parseInt(minDate[0], 10)
    );
  }

  if (!minimumDate) minimumDate = minDate;

  const [showCalender, setShowCalendar] = useState(false);

  const handleChange = (value) => {
    if (formatDate) {
      const formattedValue = formatDate(value);
      const newFormattedValue = { ...values, formattedValue, rawValue: value };
      onDateChange(newFormattedValue);
      setValues(newFormattedValue);
    } else {
      const newFormattedValue = {
        ...values,
        formattedValue: "",
        rawValue: value,
      };
      onDateChange(newFormattedValue);
      setValues(newFormattedValue);
    }
    setShowCalendar(false);
    // eventBus.emit("close-bottomSheet");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (values && !event.target.closest(".calendar")) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCalender, values]);

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.transform = "rotate(180deg)"
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }else{
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }
  });
  return (
    <>
      <div
        className={`${styles.mobileCalender} calendar`}
        onClick={() => !isDisabled && setShowCalendar(!showCalender)}
      >
        <input
          className={`${styles.mobileCalenderInput} calendar`}
          value={selectedDate}
          type="text"
          placeholder={placeholder}
        />
        <CalendarMonth style={{ color: `${isDisabled ? "grey" : "black"}` }} />
      </div>
      {showCalender && (
        <Calendar
          className={`${styles.mobileCalenderWrapper} calendar`}
          date={values.rawValue}
          onChange={handleChange}
          showSelectionPreview={true}
          showMonthAndYearPickers={false}
          minDate={minimumDate}
          locale={
            localStorage.getItem("language") == "AR"
              ? locales["arSA"]
              : locales["enUS"]
          }
        />
      )}
    </>
  );
};

export default MobileCalenderComp;
