import { createSlice } from "@reduxjs/toolkit";

const getUserDetailsFromLocalStorage = () => {
  const user = JSON.parse(window.localStorage.getItem("userDetails"));
  return user;
};

const user = getUserDetailsFromLocalStorage();

const userSlice = createSlice({
  name: "user",
  initialState: {
    newCustomer: user?.newCustomer,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    token: user?.token,
    phoneNo: user?.phoneNo,
    profilePic: user?.profilePic ?? "",
    UUID: "",
  },
  reducers: {
    setUserInfo: (state, action) => {
      const {
        firstName = "",
        token = "",
        phoneNo = "",
        newCustomer = true,
        email,
        lastName,
        profilePic = "",
      } = action.payload;
      state.firstName = firstName;
      state.newCustomer = newCustomer;
      state.token = token;
      state.phoneNo = phoneNo;
      state.email = email;
      state.lastName = lastName;
      state.profilePic = profilePic;
    },

    setUUID: (state, action) => {
      state.UUID = action.payload;
    },
  },
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;
