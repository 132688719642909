import React, { useEffect, useState } from "react";
import { getUsps } from "../../../modules/home";
import "./style.css";
import { Box } from "@mui/material";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const FindTravelPerfection = () => {
  const [usps, setUsps] = useState([]);
  const [dots, setDots] = useState([]);
  const style = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    handleGetUsps();
  }, []);

  const handleGetUsps = async () => {
    const data = await getUsps();
    setUsps(data?.Usps);
    if (data?.count > 4) {
      const length = data?.count / 4;
      const dots = [];
      for (let i = 0; i < length; i++) dots.push(i);
      setDots([...dots]);
    }
  };

  return (
    <div className={style.travelPerfection}>
      <div className="header-travel-perfection">
        <div className="info">
          <div className={style.findTravelPerfectionTitle}>
            {t("travelPerfection.findTravel")}
            <span className={style.perfectionText}>
              {t("travelPerfection.perfection")}
            </span>
          </div>
          <div className={style.findTravelPerfectionSubHeading}>
            {t("travelPerfection.elevateFun")}
          </div>
        </div>
      </div>
      <div className={style.findTravelPerfectionCard}>
        {usps &&
          usps.length > 0 &&
          usps.map((item) => {
            const { _id, usp, title, icon } = item;
            return (
              <div className={style.cardLayout} key={_id}>
                <div className={style.cardDetails}>
                  <div className={style.icon}>
                    <img
                      src={`${S3Bucket}/${icon}`}
                      className={style.iconLayout}
                    />
                  </div>
                  <div className={style.cardDetailsText}>{title}</div>
                  <div className={style.cardDetailsInfo}>{usp}</div>
                </div>
              </div>
            );
          })}
      </div>
      <Box
        sx={{
          display: "inline-flex",
          color: "#08F",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          gap: "6px",
        }}
      >
        {dots &&
          dots.map((dot) => {
            return (
              <Box
                key={dot}
                sx={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "#1b1d52",
                  borderRadius: "50%",
                }}
              ></Box>
            );
          })}
      </Box>
    </div>
  );
};

export default FindTravelPerfection;
