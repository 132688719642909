import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "8px",
    background: "#FFF",
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
  },
  innerContainer: {
    display: "inline-flex",
    gap: "24px",
  },
  baggageAllowance: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  checkContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  baggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  baggageAllowedContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  cabinBaggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  checkContainer2: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cabinBaggageMainContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  baggagesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
    width: "240px",
  },
  fairDetailsText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #667085",
  },
  baseFareText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFare: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFeesText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  vatContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFees: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFeeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  fareDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  taxesAndFeeDescription: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    padding: "8px 0px",
    borderTop: "1px solid #667085",
  },
  faresMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    background: "#344054",
    borderRadius: "8px",
    width: "305px",
  },
  checkPrice: {
    fontSize: "0.75rem",
    fontWeight: "400",
    fontFamily: "Lato",
  },
  checkPriceContainer: {
    display: "inline-flex",
    borderRadius: "8px",
    gap: "4px",
    border: "1px solid #1b1d52",
    padding: "8px 4px",
    // padding: "0.875rem 0.750rem",
    justifyContent: "center",
    cursor: "pointer",
    width: "100%",
  },
}));

export default useStyles;
