import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  flightHeaderDetails: {
    display: "flex",
    padding: "16px 16px",
    alignItems: "center",
    gap: "20px",
  },

  flightHeaderDetailsLayout: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  filtersText1: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500,
  },
  filtersText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  applyText: {
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
}));

export default useStyles;
