import DOMPurify from "dompurify";
import { useLocation } from "react-router-dom";
import "./index.css";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import ArrowRightSvg from "../../../assets/images/arrow-right.svg";
import { useSelector } from "react-redux";
import "./index.css";
import { Rings } from "react-loader-spinner";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import useStyles from "./style";
import { getAboutUs } from "../../../modules/home";
import he from "he";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const location = useLocation();
  const {t}  = useTranslation()
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [terms, setTerms] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { terms } = location.state;
  const decodedString = new DOMParser().parseFromString(terms, "text/html")
    .documentElement.textContent;
  useEffect(() => {
    handleGetAboutUs();
  }, []);

  const handleGetAboutUs = async () => {
    const data = await getAboutUs(user?.token);
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    setTerms(data?.aboutUs);
  };
  return (
    <>
      <div className={classes.mainRootContainer}>
        <div
          style={{
            display: "inline-flex",
            gap: "8px",
            width: "80%",
            marginTop: "20px",
            // padding: '10px'
          }}
        >
          <p
            style={{
              color: "#667085",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            {/* Home */}
            {t("navigationDrawer.home")}
          </p>
          <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
          <p
            style={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              cursor: "pointer",
            }}
          >
            {/* About Us */}
            {t("navigationDrawer.aboutUs")}
          </p>
        </div>
        {terms ? (
          <div className={classes.mainContainer}>
            <h3 className="AboutUsHeading">
              {/* About Us */}
              {t("navigationDrawer.aboutUs")}
              </h3>
            <div className={classes.termsAndCondition}>
              <div dangerouslySetInnerHTML={{ __html: he.decode(terms) }} />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <Rings height={80} width={80} color="#1B1D52" />
          </div>
        )}
      </div>
    </>
  );
};

export default AboutUs;
