import React from "react";
import { chunk, get, split, isEmpty } from "lodash";
import useStyles from "./styles";
import roomImage from "../../../assets/images/room_image.jpg";
import { Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Tippy from "@tippy.js/react";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Table } from "@mui/material";
import Tooltip from "./Tooltip";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

export const RoomDetailsView = (props) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const index = get(props, "index", 0);
  const styles = useStyles();
  const navigate = useNavigate();
  const details = get(props, "details", {});
  let Inclusion = get(details, "Inclusion", "");
  Inclusion = Inclusion?.split(",")
  const roomNames = get(details, "Name", "");
  const cancellationPolicies = details.CancelPolicies;
  const least = props?.least;
  const count = props?.count;

  return (
    <>
      <div
        className={`${styles.roomDetails} roomDetails${index} ${least ? "least" : ""
          }`}
      >
        <div className={styles.countAndRoomDetailsContainer}>
          <div className={styles.roomDetailsBody}>
            {/* <img src={roomImage} className={styles.roomDetailsImage} /> */}
            <div className={styles.roomDetailsDescription}>
              <div className={styles.roomDetailsAndCancelationPolicyContainer}>
                <div className={styles.roomNameAndDescriptionContainer}>
                  <h2 className={styles.roomDetailsName}>
                    {/* {count > 1 && count + " x"}{" "} */}
                    {roomNames.map((room, index) => {
                      return <div>
                        <div
                          style={{ display: "inline" }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(room || ""),
                          }}
                          key={index}
                        />
                        <br />
                      </div>
                    })}
                  </h2>
                  <br />
                  <ul>
                    {Inclusion?.map((item, index) => {
                      return <div
                        className={styles.roomDescription}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item || ""),
                        }}
                        key={index}
                      />
                    })}
                  </ul>

                </div>
                <div className={styles.cancelationPolicyContainer}>
                  <Tooltip
                    text={
                      <>
                        <table
                          style={{
                            margin: "10px",
                            borderCollapse: "collapse",
                          }}
                          className={
                            (styles.table, styles.cancellationPolicyTooltip)
                          }
                        >
                          <thead
                            className={styles.cancelationPolicyTooltip}
                            style={{
                              background: "#E6E6FA",
                              margin: "50px 0",
                            }}
                          >
                            <tr
                              style={{
                                margin: "50px",
                              }}
                            >
                              <th
                                align="left"
                                style={{
                                  width: "100px",
                                  border: "2px solid grey",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                                className={styles.tableHeaderCell}
                              >
                                {t("hotelDetails.cancelledOnOrAfter")}
                              </th>
                              {/* <th
                                align="left"
                                style={{
                                  width: "100px",
                                  border: "2px solid grey",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                                className={styles.tableHeaderCell}
                              >
                                {t("hotelDetails.cancelledOnOrBefore")}
                              </th> */}
                              <th
                                align="left"
                                style={{
                                  width: "100px",
                                  border: "2px solid grey",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                                className={styles.tableHeaderCell}
                              >
                                {t("hotelDetails.cancellationCharges")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cancellationPolicies &&
                              cancellationPolicies.map((policy, index) => {
                                let {
                                  FromDate,
                                  // ToDate,
                                  CancellationCharge,
                                  ChargeType,
                                  // Currency,
                                } = policy;
                                // FromDate = moment(FromDate, 'YYYY-MM-DD HH:mm:ss');
                                // FromDate = FromDate.format("DD--MM--YYYY");
                                // ToDate = moment(ToDate);
                                // ToDate = ToDate.format("DD--MM--YYYY");
                                return (
                                  <tr className={styles.tableRow} key={index}>
                                    <td
                                      className={styles.tableDataCell}
                                      align="left"
                                      style={{
                                        width: "100px",
                                        border: "2px solid grey", // Add border style here
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {FromDate}
                                    </td>
                                    {/* <td
                                      className={styles.tableDataCell}
                                      align="left"
                                      style={{
                                        width: "100px",
                                        border: "2px solid grey", // Add border style here
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {ToDate}
                                    </td> */}
                                    <td
                                      className={styles.tableDataCell}
                                      align="left"
                                      // align="center"
                                      style={{
                                        width: "100px",
                                        border: "2px solid grey", // Add border style here
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {CancellationCharge} {ChargeType}
                                      {/* {ChargeType == 1 && Currency}
                                      {ChargeType == 2 && "%"}
                                      {ChargeType == 3 && "Nights"} */}
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr style={{ border: "2px solid grey" }}>
                              <td
                                align="left"
                                colSpan="3"
                                style={{
                                  border: "2px solid grey", // Add border style here
                                  textAlign: "left",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                {t("hotelDetails.noShowPolicy")}
                              </td>
                            </tr>
                            <tr style={{ border: "2px solid grey" }}>
                              <td
                                align="left"
                                colSpan="3"
                                style={{
                                  border: "2px solid grey", // Add border style here
                                  textAlign: "left",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                }}
                              >
                                {t("hotelDetails.earlyCheckOutPolicy")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    }
                  >
                    <p className={styles.cancellationPolicyText}>
                      {t("hotelDetails.cancellationPolicy")}
                    </p>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Divider style={{ width: "100%" }} /> */}
    </>
  );
};
