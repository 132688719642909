import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modifyCityCitySelect: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },

  multiCityCitySelect: {
    display: "flex",
    flexDirection: "column",
    // width: "40%",
    flex: 0.68,
  },

  departureDate: {
    [theme.breakpoints.up("lg")]: {
      flex: 1,
    },
  },
}));

export default useStyles;
