import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { debounce, get, map } from "lodash";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";
import { Divider, Slider } from "@mui/material";
import { Chips } from "../../components/Chips";
import { useDispatch, useSelector } from "react-redux";
import {
  getMinMaxPrice,
  searchHotelByName,
  updateHotelLocalFilters,
} from "../../../utils/slices/localFiltersSlice";
import StarIcon from "@mui/icons-material/Star";
import closeIcon from "../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../core/eventBus";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { formatSARCurrency } from "../../../utils/helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";
const hotelPropertyTypes = [
  {
    title: "Hotel",
    value: "hotel",
    selected: false,
  },
  {
    title: "Resort",
    value: "resort",
    selected: false,
  },
  {
    title: "Motel",
    value: "motel",
    selected: false,
  },
  {
    title: "Inn",
    value: "inn",
    selected: false,
  },
  {
    title: "Bed and Breakfast",
    value: "bedAndBreakfast",
    selected: false,
  },
  {
    title: "Hostel",
    value: "hostel",
    selected: false,
  },
  {
    title: "Guesthouse",
    value: "guesthouse",
    selected: false,
  },
  {
    title: "Cabin",
    value: "cabin",
    selected: false,
  },
  {
    title: "Villa",
    value: "villa",
    selected: false,
  },
  {
    title: "Apartment",
    value: "apartment",
    selected: false,
  },
  {
    title: "Condo",
    value: "condo",
    selected: false,
  },
  {
    title: "Chalet",
    value: "chalet",
    selected: false,
  },
  {
    title: "Cottage",
    value: "cottage",
    selected: false,
  },
];

const hotelAmenities = [
  {
    title: "Free Wi-Fi",
    value: "freeWifi",
    selected: false,
  },
  {
    title: "Swimming Pool",
    value: "swimmingPool",
    selected: false,
  },
  {
    title: "Gym",
    value: "gym",
    selected: false,
  },
  {
    title: "Restaurant",
    value: "restaurant",
    selected: false,
  },
  {
    title: "Spa",
    value: "spa",
    selected: false,
  },
  {
    title: "Room Service",
    value: "roomService",
    selected: false,
  },
  {
    title: "Parking",
    value: "parking",
    selected: false,
  },
  {
    title: "Airport Shuttle",
    value: "airportShuttle",
    selected: false,
  },
  {
    title: "Pet-Friendly",
    value: "petFriendly",
    selected: false,
  },
  {
    title: "Business Center",
    value: "businessCenter",
    selected: false,
  },
  {
    title: "Conference Rooms",
    value: "conferenceRooms",
    selected: false,
  },
  {
    title: "Luggage Storage",
    value: "luggageStorage",
    selected: false,
  },
  {
    title: "Laundry Service",
    value: "laundryService",
    selected: false,
  },
  {
    title: "Bar/Lounge",
    value: "barLounge",
    selected: false,
  },
  {
    title: "Bicycle Rental",
    value: "bicycleRental",
    selected: false,
  },
];

export const HotelSearchFiltersView = (props) => {
  const minMaxPrice = useSelector((state) => state.localFilters.minMaxPrice);
  const { t } = useTranslation();
  const {
    hotelFilters: { hotelCheckInDate, hotelCheckOutDate },
  } = useSelector((state) => state.globalFilters);
  const state = get(props, "state", {});
  const handlers = get(props, "handlers", {});
  const [isChecked, setIsChecked] = useState(false);
  const isPriceAccordionVisible = !get(state, "accordionsState.price", false);
  const isRatingAccordionVisible = !get(state, "accordionsState.rating", false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const checkInDateRaw = moment(hotelCheckInDate, "DD/MM/YYYY");
  const checkOutDateRaw = moment(hotelCheckOutDate, "DD/MM/YYYY");
  const noOfNights = checkOutDateRaw.diff(checkInDateRaw, "days");

  const [rangeSelector, setRangeSelector] = useState([
    Number(parseFloat(minMaxPrice.minPrice).toFixed(2)),
    Number(parseFloat(minMaxPrice.maxPrice).toFixed(2)),
  ]);
  const initialRange = [
    Number(parseFloat(minMaxPrice.minPrice).toFixed(2)),
    Number(parseFloat(minMaxPrice.maxPrice).toFixed(2)),
  ];
  const checkBoxFilter = useSelector(
    (state) => state.localFilters.hotelLocalFilters
  );
  const quickFiltersValue = useSelector(
    (state) => state.localFilters.hotelLocalFilters
  );
  const hotelLocalFilters = useSelector(
    (state) => state.localFilters.hotelLocalFilters
  );

  const hotelLocalFilterName = useSelector(
    (state) => state.localFilters.filterHotelByName
  );

  const currentValue = useSelector(
    (state) => state.localFilters.minMaxPrice.currentPrice
  );
  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const isChecked = event.target.checked;
    const updatedFilters = [...hotelLocalFilters];
    if (isChecked) {
      updatedFilters.push(checkboxValue);
    } else {
      const index = updatedFilters.indexOf(checkboxValue);
      if (index !== -1) {
        updatedFilters.splice(index, 1);
      }
    }

    // Dispatch the updated filters array
    dispatch(updateHotelLocalFilters(updatedFilters));
  };

  const handleInputValue = (event) => {
    const inputValue = event.target.value;
    dispatch(searchHotelByName(inputValue));
  };
  const handleResetAll = (event) => {
    dispatch(searchHotelByName(""));
    dispatch(updateHotelLocalFilters([]));
    dispatch(
      getMinMaxPrice({
        ...minMaxPrice,
        currentPrice: initialRange,
      })
    );
  };
  const updateMinAndMAxPrice = debounce((value) => {
    dispatch(
      getMinMaxPrice({
        ...minMaxPrice,
        currentPrice: value,
      })
    );
  }, 500);

  const handleSliderChange = (event, newValue) => {
    // setSliderPrice(newValue);
    setRangeSelector(newValue);
    updateMinAndMAxPrice(newValue);
  };

  const checkBoxIsChecked = (value) => {
    return checkBoxFilter.includes(value);
  };

  useEffect(() => {
    window.addEventListener("popstate", function () {
      dispatch(updateHotelLocalFilters([]));
    });
  }, []);

  return (
    <div className={styles.hotelFilters}>
      {isMobile && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
             
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              eventBus.emit("close-bottomSheet");
            }}
          >
            <ReactSVG src={closeIcon} />
            <span style={{ marginLeft: "10px" }}>
              {t("hotelSearchResult.filters")}
            </span>
          </div>
          <div style={{ display: "flex", gap: "4px" }}>
            <p
              className={styles.applyText}
              onClick={() => {
                eventBus.emit("close-bottomSheet");
              }}
            >
              {t("hotelSearchResult.apply")}
            </p>
            <p onClick={handleResetAll}>{t("hotelSearchResult.resetAll")}</p>
          </div>
        </div>
      )}
      <div className={styles.hotelFiltersFilter}>
        <label htmlFor="hotelName" className={styles.hotelFiltersFilterLabel}>
          {t("hotelSearchResult.filterByHotel")}
        </label>
        <div className={styles.hotelFiltersFilterInput}>
          <input
            className={styles.hotelFiltersFilterInputField}
            type="text"
            id="hotelName"
            placeholder={t("hotelSearchResult.searchLocalityHotelName")}
            onChange={handleInputValue}
            value={hotelLocalFilterName}
          />
          <Search />
        </div>
      </div>
      <Divider />
      <div className={styles.hotelFiltersAccordion}>
        <div
          className={styles.hotelFiltersAccordionHeader}
          onClick={() =>
            handlers.toggleAccordion({
              name: "price",
              value: isPriceAccordionVisible,
            })
          }
        >
          <p className={styles.hotelFiltersFilterLabelPrimary}>
            {t("hotelSearchResult.totalPrice")}
          </p>
          {isPriceAccordionVisible ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown />
          )}
        </div>
        <div
          className={
            isPriceAccordionVisible
              ? styles.hotelFiltersAccordionBodyOpen
              : styles.hotelFiltersAccordionBody
          }
        >
          <Slider
            className={styles.MuiSliderRoot}
            valueLabelDisplay="auto"
            size="small"
            onChange={handleSliderChange}
            disableSwap
            min={Number(parseFloat(minMaxPrice.minPrice).toFixed(2))}
            max={Number(parseFloat(minMaxPrice.maxPrice).toFixed(2))}
            value={currentValue ? currentValue : rangeSelector}
          />
          <div className={styles.hotelFiltersPriceTextFieldGroup}>
            {localStorage.getItem("language") == "AR" ? (
              <p className={styles.filterPriceText}>
                {currentValue
                  ? formatSARCurrency(Number(parseFloat(currentValue[1])).toFixed(2))
                  : formatSARCurrency(Number(parseFloat(rangeSelector[1])).toFixed(2))}
              </p>
            ) : (
              <p className={styles.filterPriceText}>
                {currentValue
                  ? formatSARCurrency(Number(parseFloat(currentValue[0])).toFixed(2))
                  : formatSARCurrency(Number(parseFloat(rangeSelector[0])).toFixed(2))}
              </p>
            )}

            <span> {t("hotelSearchResult.to")}</span>
            {localStorage.getItem("language") == "AR" ? (
              <p className={styles.filterPriceText}>
                {currentValue
                  ? formatSARCurrency(Number(parseFloat(currentValue[0])).toFixed(2))
                  : formatSARCurrency(Number(parseFloat(rangeSelector[0])).toFixed(2))}
              </p>
            ) : (
              <p className={styles.filterPriceText}>
                {currentValue
                  ? formatSARCurrency(Number(parseFloat(currentValue[1])).toFixed(2))
                  : formatSARCurrency(Number(parseFloat(rangeSelector[1])).toFixed(2))}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.hotelFiltersAccordion}>
        <div
          className={styles.hotelFiltersAccordionHeader}
          onClick={() =>
            handlers.toggleAccordion({
              name: "rating",
              value: isRatingAccordionVisible,
            })
          }
        >
          <p className={styles.hotelFiltersFilterLabelPrimary}>
            {" "}
            {t("hotelSearchResult.hotelRating")}{" "}
          </p>
          {isRatingAccordionVisible ? (
            <KeyboardArrowUp />
          ) : (
            <KeyboardArrowDown />
          )}
        </div>
        <div
          className={
            isRatingAccordionVisible
              ? styles.hotelFiltersAccordionBodyOpen
              : styles.hotelFiltersAccordionBody
          }
        >
          <div className={styles.hotelFiltersRating}>
            <div className={styles.hotelFiltersRatingCheckbox}>
              <input
                type="checkbox"
                name="starRating"
                value={"5"}
                checked={checkBoxIsChecked("5")}
                className={styles.hotelFiltersFilterCheckbox}
                onChange={handleCheckboxChange}
              />
              <p className={styles.hotelFiltersRatingLabel}>
                {t("hotelSearchResult.fiveStar")}{" "}
                <StarIcon
                  sx={{
                    width: "14px",
                    height: "14px",
                    color: "#FFA400",
                  }}
                />
              </p>
            </div>
            <div className={styles.hotelFiltersRatingCheckbox}>
              <input
                type="checkbox"
                name="starRating"
                value={"4"}
                checked={checkBoxIsChecked("4")}
                className={styles.hotelFiltersFilterCheckbox}
                onChange={handleCheckboxChange}
              />
              <p className={styles.hotelFiltersRatingLabel}>
                {t("hotelSearchResult.fourStar")}{" "}
                <StarIcon
                  sx={{
                    width: "14px",
                    height: "14px",
                    color: "#FFA400",
                  }}
                />
              </p>
            </div>
            <div className={styles.hotelFiltersRatingCheckbox}>
              <input
                type="checkbox"
                name="starRating"
                value={"3"}
                checked={checkBoxIsChecked("3")}
                className={styles.hotelFiltersFilterCheckbox}
                onChange={handleCheckboxChange}
              />
              <p className={styles.hotelFiltersRatingLabel}>
                {t("hotelSearchResult.threeStar")}
                <StarIcon
                  sx={{
                    width: "14px",
                    height: "14px",
                    color: "#FFA400",
                  }}
                />
              </p>
            </div>
            <div className={styles.hotelFiltersRatingCheckbox}>
              <input
                type="checkbox"
                name="starRating"
                value={"2"}
                checked={checkBoxIsChecked("2")}
                className={styles.hotelFiltersFilterCheckbox}
                onChange={handleCheckboxChange}
              />
              <p className={styles.hotelFiltersRatingLabel}>
                {t("hotelSearchResult.twoStar")}{" "}
                <StarIcon
                  sx={{
                    width: "14px",
                    height: "14px",
                    color: "#FFA400",
                  }}
                />
              </p>
            </div>
            <div className={styles.hotelFiltersRatingCheckbox}>
              <input
                type="checkbox"
                name="starRating"
                value={"1"}
                checked={checkBoxIsChecked("1")}
                className={styles.hotelFiltersFilterCheckbox}
                onChange={handleCheckboxChange}
              />
              <p className={styles.hotelFiltersRatingLabel}>
                {t("hotelSearchResult.oneStar")}{" "}
                <StarIcon
                  sx={{
                    width: "14px",
                    height: "14px",
                    color: "#FFA400",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
