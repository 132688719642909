import * as yup from "yup";

export const loginIn = (t) =>
  yup.object().shape({
    number: yup
      .string()
      .matches(/[6789]\d{9}$/, t("validations.signup/login.validPhoneNumber"))
      .required(t("validations.signup/login.phoneNumberRequired")),
  });

export const loginKSA = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(
        /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/,
        t("validations.signup/login.validEmail")
      )
      .required(t("validations.signup/login.emailRequired")),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-zA-Z\u0600-\u06FF])(?=.*[a-z\u0600-\u06FF])(?=.*[A-Z\u0600-\u06FF])(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&\u0600-\u06FF]{8,}$/,
        t("validations.signup/login.validPassword")
      )
      .required(t("validations.signup/login.passwordRequired")),
  });

export const loginEmail = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .matches(
        /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/,
        t("validations.signup/login.validEmail")
      )
      .required(t("validations.signup/login.emailRequired")),
  });
};
