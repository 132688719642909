import { Modal } from "@mui/material";
import React from "react";
import useStyles from "./style";
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { ReactSVG } from "react-svg";
import CloseSvg from "../../../../assets/images/x-close.svg";
import eventBus from "../../../../core/eventBus";

const ShareModal = ({ pdfUrl }) => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.headingContainer}>
        <p className={classes.logoutText}>Share To</p>
        <ReactSVG
          src={CloseSvg}
          onClick={() => eventBus.emit("close-bottomSheet")}
        />
      </div>
      <div className={classes.content2}>
        <WhatsappShareButton url={pdfUrl}>
          <WhatsappIcon size={54} round={true} />
        </WhatsappShareButton>
        <EmailShareButton url={pdfUrl}>
          <EmailIcon size={54} round={true} />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareModal;
