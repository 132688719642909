import React, { useEffect, useState } from "react";
import { Box, Table, Typography } from "@mui/material";
import bulletsIcon from "../../../../../../../assets/images/bullets_black.svg";
import lineArrowLeft from "../../../../../../../assets/images/Line arrow-left.svg";
import rightArrow from "../../../../../../../assets/images/arrow-rightarrowRight.svg";
import phone from "../../../../../../../assets/images/phone-view.svg";
import email from "../../../../../../../assets/images/enquiry-mail.svg";
import TicketSvg from "../../../../../../../assets/images/ticket-01.svg";
import { ReactSVG } from "react-svg";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./styles";
import useStyles2 from "./styles2";
import Tooltip from "./Tooltip";
import SelectedHotelDetails from "./components/selected-hotel-details";
import { get } from "lodash";
import { useSelector } from "react-redux";
import {
  getHotelBookingDetails,
  getMarkup,
} from "../../../../../../../modules/hotels";
import handleUnauthorisedLogout from "../../../../../../../modules/unauthorised";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatSARCurrency } from "../../../../../../../utils/helpers";
import eventBus from "../../../../../../../core/eventBus";
import PersonIcon from "@mui/icons-material/Person";
import Ticket from "./components/Tickets";
import Contact from "./components/contact";
import FareBreakDown from "./components/fareBreakdown";
import AmountPaid from "./components/AmoutPaid";
import { setUserInfo } from "../../../../../../../utils/slices/userSlice";
import Tippy from "@tippy.js/react";
import LoadingBackdrop from "../../../../../../flight-module/cards/loading-backdrop";
import SuccessModal from "../../../../../../hotel-module/cards/successModal";
import { sendTicketsOrVouchersToMail } from "../../../../../../../modules/home";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";
import dataNotFound from '../../../../../../../../src/assets/images/datanotfound.svg'
import { convertISOToArabicDateInDDMMYYY } from "../../../../../../../utils/helpers";
import { toast } from "react-toastify";

const HotelBookingStatus = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const id = searchParams.get("id");
  const cn = searchParams.get("cn")
  const user = useSelector((state) => state.user);
  const [bookingDetails, setBookingDetails] = useState({});
  const [convenienceFees, setConvenienceFees] = useState(0)
  const [totalPayableAmount, settotalPayableAmount] = useState(0)
  const [couponDiscount, setCouponDiscount] = useState(0)
  const [totalBaseFare, setTotalBaseFair] = useState(0)
  const [bookingStatus, setBookingStatus] = useState("")
  const [loading, setLoading] = useState(true)
  const [taxAmount, setTaxAmount] = useState(0)
  const [emailId, setEmailId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [bookedHotelDetails, setBookedHotelDetails] = useState("")
  const [hotelPhoneNumber, setHotelPhoneNumber] = useState("")
  const [pdfURL, setPdfURL] = useState("")
  const [bookedRooms, setBookedRoom] = useState("")
  const [ConfirmationNumber, setConfirmationNumber] = useState("")
  const [customerDetails, setCustomerDetails] = useState("")
  const [bookingDate, setBookingDate] = useState("")


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = user.token;
  // const [markupData, setMarkupData] = useState({});
  const [loader, setLoader] = useState(false);
  const [dataLoading, setDataLoading] = useState(false)
  const [showScreenError, setShowScreenError] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [desc, setDesc] = useState("");
  const [ticketEmail, setTicketEmail] = useState("");

  const globalFilters = useSelector((state) => state.globalFilters);
  const hotelFilters = globalFilters?.hotelFilters;

  const classes = useStyles();
  const styles = useStyles2();

  let bookedHotelcheckInDateRaw = moment(bookedHotelDetails?.CheckIn);
  let bookedHotelCheckOutDateRaw = moment(bookedHotelDetails?.CheckOut);
  const bookedHotelsNumberOfNights =
    bookedHotelCheckOutDateRaw.diff(bookedHotelcheckInDateRaw, "days");
  const parsedcreatedAt = moment(bookingDate);
  let formattedCreatedAt;
  if (localStorage.getItem('language') == 'AR') {
    formattedCreatedAt = convertISOToArabicDateInDDMMYYY(bookingDate)
  } else {
    formattedCreatedAt = parsedcreatedAt.format("D MMM YYYY");
  }
  // const { id } = useParams();
  const { t } = useTranslation();
  async function getBookingData() {
    setDataLoading(true)
    const data = await getHotelBookingDetails(token, id, cn);
    // console.log('booking Data', data)
    setDataLoading(false)
    const BookingDetail = data?.data?.bookingDetails?.BookingDetail
    const hotelInfoAPI = data?.data?.bookingDetails?.hotelInfoAPI?.response?.HotelDetails[0]
    const bookingStatus = data?.data?.bookingDetails?.status
    const pdfURL = data?.data?.pdfURL?.voucherUrl
    const hotelPhoneNumber = data?.data?.bookingDetails?.hotelInfoAPI?.response?.HotelDetails[0]?.PhoneNumber
    const BookingDate = BookingDetail?.BookingDate
    const ConfirmationNumber = BookingDetail?.ConfirmationNumber
    const hotelDetails = {
      CheckIn: BookingDetail?.CheckIn,
      CheckOut: BookingDetail?.CheckOut,
      HotelName: BookingDetail?.HotelDetails?.HotelName,
      // RateConditions: BookingDetail?.RateConditions,
      RateConditions: hotelInfoAPI?.Description,
      Rooms: BookingDetail?.Rooms
    }
    const Rooms = BookingDetail?.Rooms
    const CustomerDetails = Rooms && Rooms[0] && Rooms[0].CustomerDetails
    const convenienceFeeAmount = data?.data?.bookingDetails?.convenienceFeeAmount
    const totalPayableAmount = data?.data?.bookingDetails?.totalPayableAmount
    const couponDiscount = data?.data?.bookingDetails?.couponDiscount
    const taxAmount = data?.data?.bookingDetails?.taxAmount
    const emailId = data?.data?.bookingDetails?.emailId
    const phoneNumber = data?.data?.bookingDetails?.phoneNumber
    const totalBaseFair = data?.data?.bookingDetails?.totalBaseFare

    setBookingDetails(BookingDetail)
    setBookedHotelDetails(hotelDetails)
    setBookedRoom(Rooms)
    setConfirmationNumber(ConfirmationNumber)
    setBookingDate(BookingDate)
    setConvenienceFees(convenienceFeeAmount)
    setCustomerDetails(CustomerDetails)
    setTaxAmount(taxAmount)
    settotalPayableAmount(totalPayableAmount)
    setCouponDiscount(couponDiscount)
    setTotalBaseFair(totalBaseFair)
    setEmailId(emailId)
    setPhoneNumber(phoneNumber)
    setHotelPhoneNumber(hotelPhoneNumber)
    setPdfURL(pdfURL)
    setBookingStatus(bookingStatus)
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode != 200) {
      setShowScreenError(true)
    }
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    setBookingDetails(data);
    return data;
  }

  useEffect(() => {
    getBookingData();
  }, []);

  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, [location]);

  // const AddressLine2 = hotelBlockResponse?.AddressLine2;

  const handleSendTicketsOrVouchersToMail = async (Email) => {
    setLoader(true);
    const res = await sendTicketsOrVouchersToMail({
      // token: token,
      product: "HOTELS",
      bookingId: id,
    });

    if (res?.statusCode === 200) {
      setLoader(false);
      setShowSuccessModal(true);
      setDesc(`${t("errorMessages.voucherSentOn")} ${Email}`);
    } else {
      setLoader(false);
      // setShowSuccessModal(true);
      // setDesc(`${t("errorMessages.errorTryAgain")}`);
      toast.error(res?.message)
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSuccessModal]);

  console.log('pdfURl', pdfURL)
  console.log('emailId', emailId)
  return (
    <>
      <div className={classes.outerMainContainer}>
        <div
          className={classes.backToBookingButton}
          style={{ display: "flex" }}
        >
          <span
            style={{
              fontFamily: "lato",
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "5px",
              color: "#344054",
              cursor: "pointer",
            }}
            onMouseOver={(e) => {
              e.target.style.color = "#1B1D52";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "#344054";
            }}
            onClick={(e) => {
              navigate("/");
            }}
          >
            {/* Home */}
            {t("myBookings.home")}
          </span>
          <ReactSVG src={rightArrow} style={{ display: "inline" }} />{" "}
          <span
            style={{
              fontFamily: "lato",
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "5px",
              color: "#344054",
              cursor: "pointer",
            }}
            onMouseOver={(e) => {
              e.target.style.color = "#1B1D52";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "#344054";
            }}
            onClick={(e) => {
              navigate("/bookings");
            }}
          >
            {/* My bookings */}
            {t("myBookings.myBookings")}

          </span>
          <ReactSVG src={rightArrow} style={{ display: "inline" }} />{" "}
          <span
            style={{
              fontFamily: "lato",
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "5px",
              color: "#4037acd6",
              cursor: "pointer",
            }}
          >
            {bookedHotelDetails?.HotelName}
          </span>
        </div>
        {!dataLoading && !showScreenError &&
          <div className={classes.mainContainer}>
            <div className={classes.successContainer}>
              <div
                style={{
                  background: "#FFF",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div className={classes.bookingIdAndbookDateContainer}>
                  <p className={classes.hotelBookinghasbeenCompletedText}>

                    {bookingStatus == "CANCELLED" && t("myProfile.hotelBookingCancelledMessage")}
                    {bookingStatus != "CANCELLED" && t("myProfile.hotelBookingCompletedMessage")}
                  </p>
                </div>
                <div className={classes.bookingIdAndDate}>
                  <span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      {t("myProfile.bookedId")}
                    </span>{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      {": "}
                      {ConfirmationNumber}
                    </span>
                  </span>
                  <span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      {t("myProfile.bookedOn")}
                    </span>{": "}
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      {formattedCreatedAt}
                    </span>
                  </span>
                </div>
              </div>
              <div className={classes.hotelAndRoomDetailsContainer}>
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SelectedHotelDetails
                    // details={{
                    //   hotelDetails: hotelBookingDetails,
                    //   HotelPolicyDetail,
                    //   hotelSearch,
                    // }}
                    // confirmationScreenDetails={true}
                    bookedHotelDetails={bookedHotelDetails}
                  />
                </div>
                <div
                  style={{
                    background: "#F5F6F6",
                    borderRadius: "3px",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <div style={{ overflowX: "auto", width: "100%" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">
                            <p className={classes.tableHeaderCell}>
                              {t("myProfile.room")}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p className={classes.tableHeaderCell}>
                              {t("myProfile.travellers")}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p className={classes.tableHeaderCell}>
                              {t("myProfile.night")}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p className={classes.tableHeaderCell}>
                              {" "}
                              {t("myProfile.bookedId")}
                            </p>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bookedRooms && bookedRooms?.length > 0 &&
                          bookedRooms.map((room) => {
                            const CancelPolicies = room?.CancelPolicies
                            const CustomerDetails = room?.CustomerDetails
                            const Name = room?.Name[0]
                            let travellerCount = 0;
                            CustomerDetails.map((detail) => {
                              detail.CustomerNames.map((name) => {
                                travellerCount++;
                              })
                            })
                            return <TableRow className={classes.tableRow}>
                              <TableCell
                                className={classes.tableDataCell}
                                align="left"
                              >
                                {Name}
                              </TableCell>
                              <TableCell
                                className={classes.tableDataCell}
                                align="left"
                              >
                                {travellerCount}
                              </TableCell>
                              <TableCell
                                className={classes.tableDataCell}
                                align="left"
                              >
                                {/* {hotelSearch?.noOfNights} */}
                                {bookedHotelsNumberOfNights && bookedHotelsNumberOfNights}
                              </TableCell>
                              <TableCell
                                className={classes.tableDataCell}
                                align="left"
                              >
                                {ConfirmationNumber}
                              </TableCell>

                            </TableRow>

                          })
                        }
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "#FFF6E5",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "8px",
                }}
              >
                <p
                  style={{
                    color: "#FFA400",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  {/* Important Information */}
                  {t("bookingConfirmationHotel.importantInformation")}
                  {/* {t("myProfile.importantInformation")} */}
                </p>
                <div>
                  <p
                    style={{
                      color: "#344054",
                      fontSize: "14px",
                    }}
                  >
                    {/* Remarks */}
                    {t("bookingConfirmationHotel.remarks")}
                  </p>
                  <div
                    style={{
                      color: "#667085",
                      fontSize: "12px",
                    }}
                  >
                    {/* Please note that while your booking had been confirmed and is
                    guaranteed, the rooming list with your name may not be
                    adjusted in the hotel&apos;s reservation system until closer
                    to arrival */}
                    {t("bookingConfirmationHotel.remark1")}
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      color: "#344054",
                      fontSize: "14px",
                    }}
                  >
                    {/* Booking Terms & Conditions */}
                    {t("bookingConfirmationHotel.bookingTermsAndConditions")}
                  </p>
                  <ul
                    style={{
                      color: "#667085",
                      fontSize: "12px",
                    }}
                  >
                    <li>
                      {/* You must present a photo ID at the time of check in. Hotel
                      may ask for credit card or cash deposit for the extra
                      services at the time of check in. */}
                      {t("bookingConfirmationHotel.term1")}
                    </li>
                    <li>
                      {/* All extra charges should be collected directly from clients
                      prior to departure such as parking, phone calls, room
                      service, city tax, etc. */}
                      {t("bookingConfirmationHotel.term2")}
                    </li>
                    <li>
                      {/* We don&apos;t accept any responsibility for additional
                      expenses due to the changes or delays in air, road, rail,
                      sea or indeed of any other causes, all such expenses will
                      have to be borne by passengers. */}
                      {t("bookingConfirmationHotel.term3")}
                    </li>
                    <li>
                      {/* In case of wrong residency & nationality selected by user at
                      the time of booking the supplement charges may be applicable
                      and need to be paid to the hotel by guest on check in/ check
                      out. */}
                      {t("bookingConfirmationHotel.term4")}
                    </li>
                    <li>
                      {/* Any special request for bed type, early check in, late check
                      out, smoking rooms, etc are not guaranteed as subject to
                      availability at the time of check in. */}
                      {t("bookingConfirmationHotel.term5")}
                    </li>
                    <li>
                      {/* Early check out will attract full cancellation charges
                      unless otherwise specified. */}
                      {t("bookingConfirmationHotel.term6")}
                    </li>
                  </ul>
                </div>
                <div className="hotelPolicy">
                  <p
                    style={{
                      color: "#4037acd6",
                      fontSize: "14px",
                    }}
                  >
                    {/* Hotel Policies */}
                    {t("myProfile.hotelPolicy")}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{ __html: bookedHotelDetails?.RateConditions }}
                    style={{
                      color: "#667085",
                      fontSize: "12px",
                    }}
                  />
                </div>
              </div>
              <div
                // className={classes.guestDetailsContainer}
                style={{
                  padding: "16px",
                  background: "#FFF",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", gap: "6px" }}
                >
                  <p
                    style={{
                      fontFamily: "Lato, sans-serif",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "#344054",
                    }}
                  >
                    {/* Contact Information */}
                    {t("myProfile.contactInformation")}
                  </p>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Lato, sans-serif",
                      color: "#525A6A",
                    }}
                  >
                    {/* Information about primary traveller. Any communication by
                    hotel will be sent to these details. */}
                    {t("myProfile.primaryTravellerInfo")}
                  </p>
                </div>
                <div>
                  {customerDetails
                    && customerDetails[0]
                    && customerDetails[0].CustomerNames
                    && customerDetails[0].CustomerNames[0]
                    &&
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <PersonIcon sx={{ marginTop: "4px" }} />
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            fontFamily: "Lato, sans-serif",
                            color: "#344054",
                          }}
                        >
                          {customerDetails[0].CustomerNames[0].FirstName} {" "}
                          {customerDetails[0].CustomerNames[0].LastName}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <ReactSVG
                          src={phone}
                          style={{
                            marginTop: "4px",
                          }}
                        />{" "}
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            fontFamily: "Lato, sans-serif",
                            color: "#344054",
                          }}
                        >
                          {" "}
                          +966 {phoneNumber}{" "}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <ReactSVG
                          src={email}
                          style={{
                            marginTop: "4px",
                          }}
                        />{" "}
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "14px",
                            fontFamily: "Lato, sans-serif",
                            color: "#344054",
                          }}
                        >
                          {emailId}{" "}
                        </p>
                      </div>
                      {bookingStatus != "CANCELLED"
                        && <p className={classes.optionsText}>
                          <ReactSVG
                            src={TicketSvg}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSendTicketsOrVouchersToMail(emailId)
                            }
                          />
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSendTicketsOrVouchersToMail(emailId)
                            }
                          >
                            {/* Send Voucher on Email */}
                            {t("myProfile.sendVoucher")}
                          </p>
                        </p>}

                    </div>
                  }
                </div>
              </div>
            </div>
            <div className={classes.rightContainer}>
              {bookingStatus != "CANCELLED"
                && <Ticket
                  pdfURL={pdfURL}
                  handleSendTicketsOrVouchersToMail={
                    handleSendTicketsOrVouchersToMail
                  }
                  email={emailId}
                />}


              {hotelPhoneNumber && (
                <Contact hotelPhoneNumber={hotelPhoneNumber} />
              )}
              <FareBreakDown
                details={{

                  noOfNights: bookedHotelsNumberOfNights,
                  totalPayableAmount,
                  couponDiscount,
                  totalBaseFare,
                  taxAmount,
                  convenienceFees,
                  bookedRooms
                }}
              />
              <AmountPaid
                totalAmountPaid={totalPayableAmount}
                payment_method_type={"NetBanking"}
              />
            </div>
          </div>}
        {!dataLoading && showScreenError && <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <img src={dataNotFound} />
          <p>
            {/* Booking data not found. Please try again! */}
            {t("myProfile.bookingConfirmationHotel.bookingDataNotFound")}
          </p>
        </div>}
        {dataLoading &&
          <div
            style={{
              width: '90vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Rings
              height="150"
              width="150"
              color="#1B1D52"
              radius="6"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        }
      </div>
      {loader ? (
        <LoadingBackdrop open={loader} />
      ) : (
        showSuccessModal && (
          <SuccessModal
            showSuccessModal={showSuccessModal}
            setShowSuccessModal={setShowSuccessModal}
            desc={desc}
          />
        )
      )}
    </>
  );
};

export default HotelBookingStatus;
