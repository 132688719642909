import React, { useState } from "react";
import { InputAdornment, Modal, TextField } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { loginEmail, loginKSA } from "../../../utils/validations/login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmailConfirmation,
  setLoginPopup,
} from "../../../utils/slices/popupSlice";
import Signup from "../../../components/header/components/Signup-mobile";
import eventBus from "../../../core/eventBus";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
  },
}));
const LoginForm = ({ submitForm }) => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const { t, i18n } = useTranslation();
  const { login } = useSelector((state) => state.popup);
  const [showPassword, setShowPassword] = useState(false);
  const [enterEmailPopUp, setEnterEmailPopUp] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  // const submitForm = (values) => {
  //   console.log(values);
  // };

  const handleEnterEmailPopUp = () => {
    setEnterEmailPopUp((prev) => !prev);
  };
  // (?=.*\d)

  const handleSignUp = () => {
    dispatch(setLoginPopup(false));
    eventBus.emit("open-dialog", <Signup />);
  };

  return (
    <>
      {/* <button onClick={()=> i18n.changeLanguage("en")}>EN</button>
      <button onClick={()=> i18n.changeLanguage("ar")}>AR</button> */}
      <Modal
        open={login}
        onClose={() => dispatch(setLoginPopup(false))}
        className={classes.modal}
      >
        <div className="card-container" style={{
          marginLeft: localStorage.getItem('language') == "AR" ? '0px' : '35%',
          width: localStorage.getItem('language') == "AR" ? 'auto' : '460px'
        }}>
          <div className="card-login">
            <Formik
              initialValues={initialValues}
              onSubmit={submitForm}
              validationSchema={loginEmail(t)}
            >
              {(formik) => {
                const {
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  handleBlur,
                } = formik;
                return (
                  <>
                    <div className="card-title-login">
                      {t("login.loginTitle")}
                    </div>
                    <div className="card-meta-title-login">
                      {t("login.loginMetaTitle")}
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="input-title-login-secondary">
                        {t("login.InputTitleEmailId")}
                      </div>
                      <TextField
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t("login.placeholderEmailId")}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text-field"
                      />
                      {errors.email && touched.email && (
                        <span className="error">{errors.email}</span>
                      )}
                      <div className="input-title-login-secondary">
                        {t("login.InputTitlePassword")}
                      </div>
                      <TextField
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <InputAdornment position="end">
                              {showPassword ? (
                                <VisibilityIcon
                                  onClick={() => setShowPassword(false)}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(true)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t("login.placeholderPassword")}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text-field"
                      />

                      <div
                        className="forgot-password"
                        onClick={() => {
                          dispatch(setLoginPopup(false));
                          dispatch(setEmailConfirmation(true));
                        }}
                      >
                        {t("login.forgotPassword")}
                      </div>
                      <button
                        className="card-button-login-secondary"
                        type="submit"
                      >
                        {t("login.continue")}
                      </button>
                      <div className="card-bottom-title-login">
                        {t("login.dontHaveAccount")}{" "}
                        <span
                          className="register"
                          onClick={() => handleSignUp()}
                        >
                          {t("login.registerHere")}{" "}
                        </span>
                      </div>
                      <div className="card-bottom-title-login2">
                        {t("login.bottomTitle1")}{" "}
                        <span className="register" 
                        onClick={()=>{
                          navigate('/privacy-policy')
                          dispatch(setLoginPopup(false));
                          }}>
                          {t("login.bottomTitle2")}{" "}
                        </span>{" "}
                        &{" "}
                        <span className="register" onClick={()=>{
                          navigate('/terms')
                          dispatch(setLoginPopup(false));

                          }} >
                          {t("login.bottomTitle3")}{" "}
                        </span>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LoginForm;
