import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  selectedDepartureFlightContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",

    [theme.breakpoints.down("sm")]: {
      width: "100vW",
      padding: "10px 16px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },

  content1: {
    display: "flex",
    justifyContent: "space-between",
  },

  section1: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },

  selectedDepartureHeading: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  selectedDeparturePlace: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  selectedDepartureFlightChangeFlight: {
    color: "#4037acd6",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    textTransform: "uppercase",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  content2: {
    // [theme.breakpoints.up("sm")]: {
    //   height: "140px",
    // },
  },
}));
export default useStyles;
