import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./style";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import rightIcon from "../../../assets/images/carousel-right.png";
import leftIcon from "../../../assets/images/carousel-left.png";
import { ReactSVG } from "react-svg/dist";
import { useSelector } from "react-redux";

const ImageCarousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [intervalId, setIntervalId] = useState(null);
  const getLocale = useSelector((state) => state.locale);

  const updateImage = () => {
    setImageOpacity(0);
    setTimeout(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
      setImageOpacity(1);
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setImageOpacity(0);
      setTimeout(() => {
        setCurrentImage((prevImage) =>
          prevImage === images.length - 1 ? 0 : prevImage + 1
        );

        setImageOpacity(1);
      }, 500);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  const style = useStyles();

  const handleBackward = () => {
    clearInterval(intervalId);
    setImageOpacity(0);
    setTimeout(() => {
      setCurrentImage((prevImage) =>
        prevImage === 0 ? images.length - 1 : prevImage - 1
      );
      setImageOpacity(1);
    }, 500);
    const interval = setInterval(updateImage, 5000);
    setIntervalId(interval);
  };

  const handleForward = () => {
    clearInterval(intervalId);
    updateImage();
    const interval = setInterval(updateImage, 5000);
    setIntervalId(interval);
  };
  return (
    <div className={style.carouselContainer}>
      <img
        src={leftIcon}
        onClick={handleBackward}
        className={
          localStorage.getItem("language") == "AR"
            ? style.leftIconArabic
            : style.leftIcon
        }
      />
      <img
        className={style.carouselImage}
        src={images[currentImage]}
        alt={`Image ${currentImage + 1}`}
        style={{ opacity: imageOpacity }}
      />
      <img
        src={rightIcon}
        onClick={handleForward}
        className={style.rightIcon}
      />
    </div>
  );
};

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageCarousel;
