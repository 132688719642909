import React from 'react';
import { useTranslation } from 'react-i18next';

const NumberOfPassengersInputField = ({
  classes,
  values,
  setValues,
  valid,
  setValid,
  customStyles,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const reg = new RegExp("^[1-9][0-9]{0,3}$");
    let validStatuses = valid;
    validStatuses[4] = reg.test(e.target.value);
    setValid([...validStatuses]);
    setValues({ ...values, noOfPax: e.target.value });
  };

  return (
    <div className={``} style={{ width: '100%' }}>
      <input
        type="text"
        placeholder={t("packages.enterNumberOfPassengers")}
        value={values.noOfPax}
        style={{
          ...customStyles,
          minHeight: '0px',
          alignItems: 'normal',
          width: '100%',
          padding: '8px 10px',
          border: '1px solid #D1D5DD'
        }}
        onChange={handleChange}
        className={!valid[4] ? 'input-error' : ''}
      />
    </div>
  );
};

export default NumberOfPassengersInputField;
