import React, { useEffect, useState } from "react";
import CloseChipSvg from "../../../../../assets/images/close-chip.svg";
import { ReactSVG } from "react-svg";
import "./style.css";
import useStyles from "./style";
import useResponsive from "../../../../../hooks/responsive.hook";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAirlineFilter,
  handleRefundFilter,
  handleStopsFilter,
} from "../../../../../utils/slices/flightFiltersSlice";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const QuickFilters = () => {
  const [searchParams] = useSearchParams();
  const nonStop = searchParams.get("ns");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    quickFilters: dynamicFilters,
    airlineFilter,
    stopsFilter,
    refundFilter,
  } = useSelector((state) => state.flightFilters);
  const staticFilters = [
    `${t("quickFilters.nonStop")}`,
    `${t("quickFilters.refundable")}`,
  ];
  const filters = [...staticFilters, ...dynamicFilters];
  const [selectedFilters, setSelectedFilters] = useState([]);
  const currentBreakpoint = useResponsive();
  const style = useStyles();

  const handleDelete = (key, i) => {
    const index = selectedFilters.indexOf(key);
    if (key == "Non-Stop") {
      if (stopsFilter.includes(0)) {
        const arr = stopsFilter.filter((stops) => stops != 0);
        dispatch(handleStopsFilter([...arr]));
      }
    } else if (key == "Refundable") {
      if (refundFilter.includes(1)) {
        const arr = refundFilter.filter((refund) => refund != 1);
        dispatch(handleRefundFilter([...arr]));
      }
    } else {
      if (airlineFilter.includes(key)) {
        const arr = airlineFilter.filter((airline) => airline != key);
        dispatch(handleAirlineFilter([...arr]));
      }
    }
    selectedFilters.splice(index, 1);
    setSelectedFilters([...selectedFilters]);
  };

  const handleClearAllFilter = () => {
    const stopsArr = stopsFilter.filter((stops) => stops != 0);
    dispatch(handleStopsFilter([...stopsArr]));
    const refundArr = refundFilter.filter((refund) => refund != 1);
    dispatch(handleRefundFilter([...refundArr]));
    let i = 0;
    let airlineArr = airlineFilter.slice();
    while (i < dynamicFilters.length) {
      const airline = dynamicFilters[i];
      if (airlineArr.includes(airline)) {
        const index = airlineArr.indexOf(airline);
        airlineArr.splice(index, 1);
        dispatch(handleAirlineFilter([...airlineArr]));
      }
      i++;
    }
    setSelectedFilters([]);
  };

  const handleSelectedFilters = (key, index) => {
    console.log('handleSelectedFilters called' )
    console.log('stopsFilter', stopsFilter)
    if (key == "Non-Stop") {
      if (stopsFilter.includes(0)) {
        const arr = stopsFilter.filter((stops) => stops != 0);
        dispatch(handleStopsFilter([...arr]));
      } else {
        dispatch(handleStopsFilter([...stopsFilter, 0]));
      }
    } else if (key == "Refundable") {
      if (refundFilter.includes(1)) {
        const arr = refundFilter.filter((refund) => refund != 1);
        dispatch(handleRefundFilter([...arr]));
      } else {
        dispatch(handleRefundFilter([...refundFilter, 1]));
      }
    } else {
      if (airlineFilter.includes(key)) {
        const arr = airlineFilter.filter((airline) => airline != key);
        dispatch(handleAirlineFilter([...arr]));
      } else {
        dispatch(handleAirlineFilter([...airlineFilter, key]));
      }
    }
    setSelectedFilters([...selectedFilters, key]);
  };

  useEffect(() => {
    if (stopsFilter.includes(0)) {
      const index = selectedFilters.indexOf("Non-Stop");
      if (index === -1) setSelectedFilters([...selectedFilters, "Non-Stop"]);
    } else if (!stopsFilter.includes(0)) {
      const index = selectedFilters.indexOf("Non-Stop");
      if (index !== -1) {
        selectedFilters.splice(index, 1);
        setSelectedFilters([...selectedFilters]);
      }
    }

    if (refundFilter.includes(1)) {
      const index = selectedFilters.indexOf("Refundable");
      if (index === -1) setSelectedFilters([...selectedFilters, "Refundable"]);
    } else if (!refundFilter.includes(1)) {
      const index = selectedFilters.indexOf("Refundable");
      if (index !== -1) {
        selectedFilters.splice(index, 1);
        setSelectedFilters([...selectedFilters]);
      }
    }

    for (let i = 0; i < dynamicFilters.length; i++) {
      const airline = dynamicFilters[i];
      if (airlineFilter.includes(airline)) {
        const index = selectedFilters.indexOf(airline);
        if (index === -1) setSelectedFilters([...selectedFilters, airline]);
      } else if (!airlineFilter.includes(airline)) {
        const index = selectedFilters.indexOf(airline);
        if (index !== -1) {
          selectedFilters.splice(index, 1);
          setSelectedFilters([...selectedFilters]);
        }
      }
    }
  }, [stopsFilter, airlineFilter, refundFilter]);

  useEffect(()=>{
    if(nonStop == 'true'){
      handleSelectedFilters("Non-Stop",0)
    }
  },[])
  return (
    <div className={style.quickFilters}>
      <div className={style.quickFilterHeading}>
        {t("quickFilters.quicklyFilterResults")}
      </div>
      <div className="quick-filters-content">
        <div className="quick-filters-content1">
          {filters &&
            filters?.length > 0 &&
            filters.map((filter, i) => {
              return selectedFilters?.length > 0 &&
                selectedFilters.includes(filter) ? (
                <div
                  key={i}
                  className="filter-button-selected"
                  onClick={() => handleDelete(filter, i)}
                >
                  <div className={style.filterButtonText}>
                    {filter}
                    <ReactSVG src={CloseChipSvg} />
                  </div>
                </div>
              ) : (
                <div
                  key={i}
                  className="filter-button"
                  onClick={() => handleSelectedFilters(filter, i)}
                >
                  <div className={style.filterButtonText}>{filter}</div>
                </div>
              );
            })}
        </div>
        {currentBreakpoint === "extraLarge" && selectedFilters.length > 0 && (
          <div
            className="quick-filters-content2"
            onClick={() => handleClearAllFilter()}
          >
            {t("flightSearch.clearAll")}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickFilters;
