import { React } from "react";
import closeIcon from "../../../../assets/images/x-close.svg";
import PropTypes from "prop-types";

import DOMPurify from "dompurify";
import "./Policy.css";
import { useTranslation } from "react-i18next";

const HotelPolicyPopup = ({ handleModalClose, policy }) => {
  let hotelPolicy = policy;
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          height: '500px',
          maxHeight: '90vh',
          width: '720px',
          maxWidth: '80vw',
          background: '#fff'
        }}>
        <div style={{
          height: '100%',
          overflow: 'scroll',
          padding: '25px',

        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: "#5F57B9",
            fontSize: '25px',
            fontWeight: 'bolder',
            marginBottom: '15px'
          }}>
            <p>Hotel Policies</p>
            <img src={closeIcon} onClick={() => { handleModalClose() }}></img>
          </div>
          <div
            style={{
              fontFamily: "Lato",
              // marginTop: '150px'
            }}
            // className={"policies"}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(hotelPolicy || "<span>''</span>"),
            }}
          />
        </div>


      </div>
    </>
  );
};

export default HotelPolicyPopup;

HotelPolicyPopup.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  policies: PropTypes.object,
};
