import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { Box, Divider, Modal, Typography } from "@mui/material";
import CustomerSupportUser from "../../../../assets/images/customer-support.svg";
import CustomerSupportMail from "../../../../assets/images/email_icon.svg";
import CustomerSupportPhone from "../../../../assets/images/customer-support-phone.svg";
import landLine from "../../../../assets/images/landline1(16).svg";
import mobile from "../../../../assets/images/mobile_icon.svg";
import crossIcon from "../../../../assets/images/x-close.svg";
import useStyles from "./style";
import { useTranslation } from "react-i18next";

const CustomerSupport = ({
  showCustomerSupportModal,
  setShowCustomerSupportModal,
}) => {
  const style = useStyles();
  const {t}  = useTranslation()

  return (
    <Modal
      open={showCustomerSupportModal}
      onClose={() => setShowCustomerSupportModal(false)}
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={style.container}>
        <div
          style={{ alignSelf: "end", cursor: "pointer" }}
          onClick={() => setShowCustomerSupportModal(false)}
        >
          <ReactSVG src={crossIcon} />
        </div>
        <div
          style={{
            width: "100%",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactSVG src={CustomerSupportUser} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "22px",
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              alignSelf: "center",
            }}
          >
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            >
              {/* Customer Support */}
              {t("customerSupportModal.customerSupport")}
            </p>
            <p
              style={{
                color: "#525A6A",
                fontFamily: "Lato, sans-serif",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              {/* We're here to help - get in touch with our website's support team now. */}
              {t("customerSupportModal.weAreHereMessage")}
            </p>
          </div>
          <div
            style={{
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              // gap: "2px",
              // maxWidth: '80%',
              // margin: '0 auto',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              maxWidth: "80%",
              margin: "0 auto",
            }}
          >
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              {/* Email ID */}
              {t("customerSupportModal.emailId")}
            </p>
            <div
              style={{
                display: "inline-flex",
                gap: "4px",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <img
                src={CustomerSupportMail}
                style={{ height: "16px", width: "16px" }}
              />
              <p
                style={{
                  color: "#344054",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              >
                support@joyustrips.com
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              {/* Phone Number */}
              {t("customerSupportModal.phoneNumber")}
            </p>
            <div
              style={{
                display: "inline-flex",
                gap: "8px",
                maxWidth: "80%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginTop: "8px",
                }}
              >
                <div style={{ display: "flex", gap: "4px", alignItems: "end" }}>
                  <img src={mobile} />
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    +966 595558753
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerSupport;

CustomerSupport.propTypes = {
  showCustomerSupportModal: PropTypes.bool,
  setShowCustomerSupportModal: PropTypes.func,
};
