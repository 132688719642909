import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "24px 150px",
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    [theme.breakpoints.down("lg")]: {
      padding: "10px 50px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "12px 10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  breadCrumbContainer: {
    display: "inline-flex",
    gap: "8px",
    alignItems: "center",
  },
  nonActiveBreadCrumb: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    cursor: "pointer",
  },
  activeBreadCrumb: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  contentContainer: {
    display: "inline-flex",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
  },
  leftContainer: {
    flex: 1.2,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  rightContainer: {
    flex: 0.8,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  fareRuleContainer: {
    padding: "16px",
    background: "#FFF",
    border: "1px solid #D0D5DD",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  mainFeeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  feeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  feeContainerTitleMain: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "700",
  },
  feeContainerPriceMain: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontWeight: "800",
  },
  extraFeeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  feeContainerTitle: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
  },
  feeContainerPrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
  },
  informationContainer: {
    background: "#FFF6E5",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    gap: "8px",
  },
  infoHeading: {
    color: "#FFA400",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Lato, sans-serif",
  },
  infoContent: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },
}));

export default useStyles;
