import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HotelIcon from "@mui/icons-material/Hotel";
import DeckIcon from "@mui/icons-material/Deck";
import FlightSearchWidget from "./components/flight-search-widget";
import HotelSearchWidget from "./components/hotel-search-widget";
import { changeSearchWidgetType } from "../../utils/slices/globalSlice";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SearchWidget = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchWidgetType: searchWidget } = useSelector(
    (state) => state.global
  );
  const [searchWidgetType, setSearchWidgetType] = useState(searchWidget);
  const { t } = useTranslation();
  const getLocale = useSelector((state) => state.locale);
  const handleSearchWidgetType = (value) => {
    setSearchWidgetType(value);
    dispatch(changeSearchWidgetType(value));
  };

  return (
    <>
      <div className="search-widget-container">
        <div
          className={
            searchWidgetType == 1
              ? "card-component-flight"
              : "card-component-hotel"
          }
        >
          <div className="section1">
            <div
              className={
                searchWidgetType == 1 ? "select-flight-active" : "select-flight"
              }
              onClick={() => handleSearchWidgetType(1)}
            >
              {localStorage.getItem("language") == "AR" ? (
                <>
                  <FlightTakeoffIcon className="select-flight-icon"/>
                  <div className="text">{t("widgetType.flight")}</div>

                </>
              ) : (
                <>
                  <FlightTakeoffIcon style={{
                    margin: "1rem 0rem 1rem 0.5rem",
                    color: "#fff",
                    alignItems: "center",
                    justifyContent: "center"
                  }} />
                  <div style={{
                    margin:"1rem 1rem 1rem 0.5rem",
                    color:"#fff",
                    fontFamily:"Lato, sans-serif",
                    fontSize:"1rem"
                  }}>{t("widgetType.flight")}</div>

                </>
              )}
            </div>
            <div
              className={
                searchWidgetType == 0 ? "select-hotel-active" : "select-hotel"
              }
              onClick={() => handleSearchWidgetType(0)}
            >

              {localStorage.getItem("language") == "AR" ? (
                <>
                  <HotelIcon className="select-hotel-icon" />
                  <div className="text">{t("widgetType.hotel")}</div>
                </>
              ) : (
                <>
                  <HotelIcon style={{
                    margin: "1rem 0rem 1rem 0.5rem",
                    color: "#fff",
                    alignItems: "center",
                    justifyContent: "center"
                  }} />
                  <div style={{
                    margin: "1rem 1rem 1rem 0.5rem",
                    color: "#fff",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "1rem"
                  }}>{t("widgetType.hotel")}</div>

                </>
              )}
            </div>
            <div
              className="select-hotel"
              onClick={() => navigate("packages/search")}
            >
              {localStorage.getItem("language") == "AR" ? (
                <>
                  <DeckIcon className="select-hotel-icon" />
                  <div className="text">{t("widgetType.packages")}</div>
                </>
              ) : (
                <>
                  <DeckIcon style={{
                    margin: "1rem 0rem 1rem 0.5rem",
                    color: "#fff",
                    alignItems: "center",
                    justifyContent: "center"
                  }} />
                  <div style={{
                    margin: "1rem 1rem 1rem 0.5rem",
                    color: "#fff",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "1rem"
                  }}>{t("widgetType.packages")}</div>

                </>
              )}
            </div>
          </div>
          {searchWidgetType == 1 ? (
            <FlightSearchWidget
              activeColor={"#fff"}
              nonActiveColor={"#B8BEC4"}
            />
          ) : (
            <HotelSearchWidget />
          )}
        </div>
      </div>
    </>
  );
};

export default SearchWidget;
