import React from "react";
import "./style.css";
import AddSvg from "../../../../../../assets/images/plusIcon.svg";
import MinusSvg from "../../../../../../assets/images/minusIcon.svg";
import { Divider, TextField, Typography } from "@mui/material";
import {
  addRoom,
  removeRoom,
  updateAdultCounter,
  updateChildrenCounter,
  updateChildrenAges,
} from "../../../../../../utils/slices/hotelRoomSlice";
import { ReactSVG } from "react-svg/dist";
import plusIcon from "../../../../../../assets/images/plusIcon.svg";
import { childrenAge } from "../../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
// import useStyles from "./style";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const customStyles = {
  borderRadius: "10px",
  height: "37px",
};
const HotelTotalPerson = () => {
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.hotelRooms.rooms);
  const location = useLocation();
  const { t } = useTranslation();
  const path = location.pathname;

  const useStyles = makeStyles((theme) => ({
    totalPersonsContainer: {
      // padding: "16px",
      padding: "16px",
      display: "flex",
      gap: "0",
      justifyContent: "flex-start",
      // borderRadius: "16px",
      background: "#FFF",
      boxShadow: "0px 4px 12p x 0px rgba(0, 0, 0, 0.12)",
      zIndex: "120",
      [theme.breakpoints.up("md")]: {
        position: path == "/" ? "static" : "fixed",
        height: path == "/" ? "auto" : "300px",
        overflowY: path == "/" ? "none" : "auto !important",
        maxHeight: "-webkit-fill-available",
      },
    },
  }));

  const handleAddRoom = () => {
    dispatch(addRoom());
  };

  const handleRemoveRoom = (index) => {
    dispatch(removeRoom(index));
  };

  const classes = useStyles();

  const isMaxRoomsReached = rooms.length > 5;
  return (
    <div style={{ zIndex: "1000", background: "#fff" }}>
      <div
        className={classes.totalPersonsContainer}
        style={{ background: "#fff" }}
      
      >
        <div className="roomsContainer" style={{ background: "#FFF" }}>
          {rooms.map((room, roomIndex) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // gap: "16px",
                  zIndex: "100",
                  background: "#FFF",
                }}
                key={roomIndex}
              >
                {roomIndex !== 0 && (
                  <>
                   
                    <div
                      className="emptyDiv"
                      style={{
                        zIndex: "100",
                        background: "#FFF",
                        height: "16px",
                        display: "inline-block",
                      }}
                    ></div>
                    <Divider style={{ display: "inline-block" }} />
                    <div
                      className="emptyDiv"
                      style={{
                        zIndex: "100",
                        background: "#FFF",
                        height: "16px",
                        display: "inline-block",
                      }}
                    ></div>
                  </>
                )}
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    zIndex: "100",
                    background: "#FFF",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Lato, sans-serif",
                      color: "#344504",
                      fontSize: "16px",
                      fontWeight: "700",
                      zIndex: "100",
                      background: "#FFF",
                    }}
                  >
                    {t("hotelSearchWidget.rooms")} {roomIndex + 1}
                  </Typography>
                  {roomIndex !== 0 && (
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#4037acd6",
                        fontSize: "14px",
                        fontWeight: "500",
                        cursor: "pointer",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                      onClick={(e) => {
                        handleRemoveRoom(roomIndex);
                        // e.stopPropagation();
                      }}
                    >
                      {t("hotelSearchWidget.delete")}{" "}
                    </Typography>
                  )}
                </div>
                <div
                  className="emptyDiv"
                  style={{ zIndex: "100", background: "#FFF", height: "16px" }}
                ></div>
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    zIndex: "100",
                    background: "#FFF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      zIndex: "100",
                      background: "#FFF",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#344504",
                        fontSize: "14px",
                        fontWeight: "500",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                    >
                      {t("hotelSearchWidget.adults")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#667085",
                        fontSize: "12px",
                        fontWeight: "400",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                    >
                      ({t("hotelSearchWidget.above12Years")})
                    </Typography>
                  </div>
                  <div
                    className="emptyDiv"
                    style={{
                      background: "#fff",
                      flex: 1,
                      height: "61px",
                      width: "85px",
                      fontFamily: "Lato, sans-serif",
                      color: "#667085",
                      fontSize: "12px",
                      fontWeight: "400",
                      zIndex: "100",
                    }}
                  ></div>
                  <div
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #EAEAEA",
                      background: "#FFF",
                      width: "88px",
                      height: "37px",
                      padding: "8px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      zIndex: "100",
                    }}
                  >
                    <ReactSVG
                      src={MinusSvg}
                      style={{ cursor: "pointer", background: "#fff" }}
                      onClick={(e) => {
                        // e.stopPropagation();
                        if (room.adultCounter === 1) return;
                        dispatch(
                          updateAdultCounter({
                            roomIndex,
                            newAdultCounter: room.adultCounter - 1,
                          })
                        );
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#344504",
                        fontSize: "16px",
                        fontWeight: "400",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                    >
                      {room.adultCounter}
                    </Typography>
                    <ReactSVG
                      style={{ cursor: "pointer", background: "#fff" }}
                      src={plusIcon}
                      onClick={(e) => {
                        // e.stopPropagation();
                        if (room.adultCounter + room.childrenCounter === 4)
                          return;
                        dispatch(
                          updateAdultCounter({
                            roomIndex,
                            newAdultCounter: room.adultCounter + 1,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="emptyDiv"
                  style={{ zIndex: "100", background: "#FFF", height: "16px" }}
                ></div>
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    zIndex: "100",
                    background: "#FFF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      zIndex: "100",
                      background: "#FFF",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#344504",
                        fontSize: "14px",
                        fontWeight: "500",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                    >
                      {t("hotelSearchWidget.children")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#667085",
                        fontSize: "12px",
                        fontWeight: "400",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                    >
                      ({t("hotelSearchWidget.age12YearsAndBelow")})
                    </Typography>
                  </div>
                  <div
                    className="emptyDiv"
                    style={{
                      background: "#fff",
                      flex: 1,
                      height: "61px",
                      width: "85px",
                      fontFamily: "Lato, sans-serif",
                      color: "#667085",
                      fontSize: "12px",
                      fontWeight: "400",
                      zIndex: "100",
                    }}
                  ></div>
                  <div
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #EAEAEA",
                      background: "#FFF",
                      width: "88px",
                      height: "37px",
                      padding: "8px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      zIndex: "100",
                    }}
                  >
                    <ReactSVG
                      src={MinusSvg}
                      style={{ cursor: "pointer", background: "#fff" }}
                      onClick={(e) => {
                        // e.stopPropagation();
                        if (room.childrenCounter === 0) return;
                        const newChildrenCounter = room.childrenCounter - 1;
                        const newChildrenAges = room.childrenAges.slice(
                          0,
                          newChildrenCounter
                        );
                        dispatch(
                          updateChildrenCounter({
                            roomIndex,
                            newChildrenCounter,
                          })
                        );
                        dispatch(
                          updateChildrenAges({ roomIndex, newChildrenAges })
                        );
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: "Lato, sans-serif",
                        color: "#344504",
                        fontSize: "16px",
                        fontWeight: "400",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                    >
                      {room.childrenCounter}
                    </Typography>
                    <ReactSVG
                      src={plusIcon}
                      style={{
                        cursor: "pointer",
                        zIndex: "100",
                        background: "#fff",
                      }}
                      onClick={(e) => {
                        // e.stopPropagation();
                        if (room.adultCounter + room.childrenCounter === 4)
                          return;
                        const newChildrenCounter = room.childrenCounter + 1;
                        const newChildrenAges = [...room.childrenAges, 1]; // Add a placeholder age for the new child
                        dispatch(
                          updateChildrenCounter({
                            roomIndex,
                            newChildrenCounter,
                          })
                        );
                        dispatch(
                          updateChildrenAges({ roomIndex, newChildrenAges })
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="emptyDiv"
                  style={{ zIndex: "100", background: "#FFF", height: "16px" }}
                ></div>
                <div>
                  {(() => {
                    const inputFields = [];
                    for (let i = 0; i < room.childrenCounter; i++) {
                      inputFields.push(
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            zIndex: "100",
                            background: "#fff",
                          }}
                          key={i}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "4px",
                              zIndex: "100",
                              background: "#fff",
                              flex: 1,
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Lato, sans-serif",
                                color: "#344504",
                                fontSize: "14px",
                                fontWeight: "500",
                                zIndex: "100",
                                background: "#fff",
                              }}
                            >
                              {t("hotelSearchWidget.child")} {i + 1}{" "}
                              {t("hotelSearchWidget.age")}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: "Lato, sans-serif",
                                color: "#667085",
                                fontSize: "12px",
                                fontWeight: "400",
                                zIndex: "100",
                                background: "#fff",
                              }}
                            >
                              ({t("hotelSearchWidget.age1To11Years")})
                            </Typography>
                          </div>
                          <div
                            className="emptyDiv"
                            style={{
                              background: "#fff",
                              flex: 1,
                              height: "61px",
                              width: "85px",
                              fontFamily: "Lato, sans-serif",
                              color: "#667085",
                              fontSize: "12px",
                              fontWeight: "400",
                              zIndex: "100",
                            }}
                          ></div>
                          <TextField
                            select
                            // onClick={(e) => e.stopPropagation()}
                            style={{
                              width: "88px",
                              zIndex: "100",
                              background: "#fff",
                              flex: 1,
                            }}
                            InputProps={{
                              style: customStyles,
                            }}
                            SelectProps={{
                              native: true,
                            }}
                            value={room.childrenAges[i] || 1}
                            onChange={(event) => {
                              const selectedAge = event.target.value;
                              const newChildrenAges = [...room.childrenAges];
                              newChildrenAges[i] = selectedAge
                                ? selectedAge
                                : 1;
                              dispatch(
                                updateChildrenAges({
                                  roomIndex,
                                  newChildrenAges,
                                })
                              );
                            }}
                          >
                            <option value={1}>{1}</option>
                            {childrenAge.map((option) => (
                              <option
                                
                                key={option.value}
                                value={option.value}
                                style={{ background: "#FFF", "&.hover": { backgroundColor: "green"} }}
                              >
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </div>
                      );
                    }
                    return inputFields;
                  })()}
                </div>
                <div
                  className="emptyDiv"
                  style={{ zIndex: "100", background: "#FFF", height: "16px" }}
                ></div>
                {rooms.length < 5 && roomIndex == rooms.length - 1 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // width: "266px",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                        zIndex: "100",
                        background: "#FFF",
                      }}
                      onClick={(e) => {
                        handleAddRoom();
                        // e.stopPropagation();
                      }}
                    >
                      <img
                        src={AddSvg}
                        style={{ background: "#fff !important" }}
                      ></img>
                      <Typography
                        style={{
                          color: "#1b1d52",
                          fontFamily: "Lato",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "4 00",
                          zIndex: "100",
                          background: "#FFF",
                        }}
                      >
                        {t("hotelSearchWidget.addAnotherRoom")}
                      </Typography>
                    </div>
                    <div
                      className="emptyDiv"
                      style={{
                        zIndex: "100",
                        background: "#FFF",
                        height: "16px",
                      }}
                    ></div>
                  </>
                )}
              </div>
            );
          })}
          
        </div>
      </div>
    </div>
  );
};
export default HotelTotalPerson;
