import React from "react";
import GoogleMapReact from "google-map-react";
import useStyles from "../styles";
import PlaceIcon from "@mui/icons-material/Place";

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    {/* <span style={{ fontWeight: "bolder" }}>{text}</span> */}
    <PlaceIcon style={{ color: "red", height: "40px" }} />
  </div>
);

export default function MapContainer(props) {
  const lat = props.coordinates.lat;
  const long = props.coordinates.long;
  const classes = useStyles();
  const defaultProps = {
    center: {
      lat: Number(lat),
      lng: Number(long),
    },
    zoom: 12,
  };
  return (
    // Important! Always set the container height explicitly
    <div className={classes.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={Number(lat)}
          lng={Number(long)}
          text={props?.HotelName}
        />
      </GoogleMapReact>
    </div>
  );
}
