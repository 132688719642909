import React, { useState } from "react";
import OneWayIcon from "../../../../../assets/images/arrow-one-way.svg";
import RoundTripIcon from "../../../../../assets/images/arrow-round-trip.svg";
import { ReactSVG } from "react-svg";
import ModifySearchMobile from "../cards/modify-search-mobile";
import ModifySearch from "../cards/modify-search";
import { Modal } from "@mui/material";
import "./style.css";
import { flightClassData, flightClassDataArabic } from "../../../../../config";
import { formateDateToDayAndDate } from "../../../../../utils/helpers";
import { useSelector } from "react-redux";
import { get } from "lodash";
import eventBus from "../../../../../core/eventBus";
import useIsTablet from "../../../../../hooks/useIsTablet.hook";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTranslation } from "react-i18next";

const FlightResultHeader = () => {
  const {
    flightTravelType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
    flightTotalTravellers: {
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
    flightClassType,
    multiCityDates,
  } = useSelector((state) => state.searchFlightQuery);
  const isTablet = useIsTablet();

  const { loading } = useSelector((state) => state.modifySearchFlight);

  const [showModifySearch, setShowModifySearch] = useState(false);
  const { indexOfMultiCity } = useSelector(
    (state) => state.multiCityTripDetails
  );
  const getLocale = useSelector((state) => state.locale);
  const { t } = useTranslation();
  const modifySearchOpenHandle = () => {
    if (isTablet)
      eventBus.emit(
        "open-dialog",
        <div style={{ width: "500px", padding: "12px", height: "550px" }}>
          <ModifySearchMobile />
        </div>
      );
    else setShowModifySearch(true);
  };

  const modifySearchCloseHandle = () => {
    if (isTablet) eventBus.emit("close-dialog");
    else setShowModifySearch(false);
  };

  return (
    <>
      <div className="flightResultHeader">
        <div className="flightResultHeaderContainer">
          <div
            className={
              localStorage.getItem("language") == "AR"
                ? "flight-result-header-content1-arabic"
                : "flight-result-header-content1"
            }
          >
            <div className="flight-result-header-flightFrom">
              <div className="flight-result-header-code">
                {flightFrom?.[indexOfMultiCity].code}
              </div>
              <div className="flight-result-header-name">
                {flightFrom?.[indexOfMultiCity].city},{" "}
                {flightFrom?.[indexOfMultiCity].country}{" "}
              </div>
            </div>
            <div className="flight-result-header-icon">
              {(()=>{console.log('getLocale', getLocale)})()}
              {localStorage.getItem("language") == "AR" ? (
                flightTravelType === 2 ? (
                  <ReactSVG src={RoundTripIcon} />
                ) : (
                  <KeyboardBackspaceIcon />
                )
              ) : (
                <ReactSVG
                  src={flightTravelType === 2 ? RoundTripIcon : OneWayIcon}
                />
              )}
            </div>
            <div className="flight-result-header-flightTo">
              <div className="flight-result-header-code">
                {flightTo?.[indexOfMultiCity].code}
              </div>
              <div className="flight-result-header-name">
                {flightTo?.[indexOfMultiCity].city},{" "}
                {flightTo?.[indexOfMultiCity].country}
              </div>
            </div>
          </div>
          <div className="flight-result-header-divider"></div>
          <div className="flight-result-header-content2">
            <div className="flight-result-header-content2-section1">
              <div className="flight-result-header-content2-heading">
                {t("quickFilters.departure")}
              </div>
              <div className="flight-result-header-content2-value">
                {flightTravelType === 3 && indexOfMultiCity !== 0
                  ? formateDateToDayAndDate(
                      get(multiCityDates, `[${indexOfMultiCity - 1}].date`, "")
                    )
                  : formateDateToDayAndDate(flightDepartureDate)}
              </div>
            </div>
            {flightTravelType === 2 && (
              <div className="flight-result-header-content2-section2">
                <div className="flight-result-header-content2-heading">
                  {t("quickFilters.return")}
                </div>
                <div className="flight-result-header-content2-value">
                  {formateDateToDayAndDate(flightReturnDate)}
                </div>
              </div>
            )}
            <div className="flight-result-header-content2-section3">
              <div className="flight-result-header-content2-heading">
                {t("quickFilters.passengers")}
              </div>
              <div className="flight-result-header-content2-value">
                {flightAdultsCount} {t("codes.adultCode")}
                {flightChildrenCount > 0 && `, ${flightChildrenCount}${t("codes.childrenCode")}`}
                {flightInfantsCount > 0 && `, ${flightInfantsCount}${t("codes.infantCode")}`}
              </div>
            </div>
            <div className="flight-result-header-content2-section4">
              <div className="flight-result-header-content2-heading">
                {t("quickFilters.cabin")}
              </div>
              <div className="flight-result-header-content2-value">
                {localStorage.getItem('language') == "AR"
                  ? flightClassDataArabic[flightClassType]
                  : flightClassData[flightClassType]}
              </div>
            </div>
          </div>
          {!loading && (
            <div className="flight-result-header-content3">
              <div
                className="flight-result-header-modify-search"
                onClick={modifySearchOpenHandle}
              >
                <div className="modify-search-text">
                  {t("quickFilters.modifySearch")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!loading && showModifySearch && (
        <Modal
          open={showModifySearch}
          onClose={() => modifySearchCloseHandle()}
          style={{
            overflowY: 'scroll'
          }}
        >
          <ModifySearch setShowModifySearch={setShowModifySearch} />
        </Modal>
      )}
    </>
  );
};

export default FlightResultHeader;
