import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PackagesCard from "./search-packages-card";
import { Rings } from "react-loader-spinner";
import { useSelector } from "react-redux";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import filterIcon from "../../../../../../assets/images/arrow-3.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../../../../core/eventBus";
import useStyles from "./styles";
import PackagesSidebar from "../sidebar";
import { useTranslation } from "react-i18next";

const SearchPackagesResult = () => {
  const { filteredPackages } = useSelector((state) => state.searchPackage);
  useEffect(() => {}, [filteredPackages]);
  const isMobile = useIsMobile();
  const styles = useStyles();
  const { t } = useTranslation();
  const handleFiltersBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <div style={{ height: "500px" }}>
        <PackagesSidebar />
      </div>
    );
  };
  return filteredPackages && filteredPackages.length > 0 ? (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginBottom: "80px",
        }}
      >
        <PackagesCard packages={filteredPackages} />
      </Box>
      {isMobile && (
        <Box className={styles.bottomButtons}>
          <Box
            className={styles.filterLayout}
            onClick={handleFiltersBottomSheet}
          >
            <ReactSVG src={filterIcon} />
            <Typography>Filter</Typography>
          </Box>
        </Box>
      )}
    </div>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "100px",
        width: '500px',
        height : '500px',
        maxHeight : '90vh',
        maxWidth: '100%',
      }}
    >
      <Rings
        height="80"
        width="80"
        color="#1B1D52"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Box>
  );
};

export default SearchPackagesResult;
