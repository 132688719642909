import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "30px 0px 0px 0px",
  },
  couponContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  couponName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  applyText:{
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    cursor: "pointer",
    marginRight: '23px'
  },
  description:{
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  knowMore:{
    marginTop: "4px",
    color: "#08F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
  }
}));

export default useStyles;