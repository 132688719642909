import React, { useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router";
import { getBlogs } from "../../../modules/home";
import useStyles from "./style";
import { RecentArticleCard } from "./recent-articles-card";
import Carousel from "react-elastic-carousel";
import { isEmpty } from "lodash";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import useIsTablet from "../../../hooks/useIsTablet.hook";
import useIsTablet2 from "../../../hooks/useIsTablet.hook2";
import { useSelector } from "react-redux";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const RecentArticlesAndBlogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isTablet2 = useIsTablet2();
  const [blogs, setBlogs] = useState([]);
  const [index, setIndex] = useState(0);
  const style = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    handleGetBlogs();
  }, []);

  const handleScroll = (value) => {
    setIndex(value);
  };

  const handleGetBlogs = async () => {
    const data = await getBlogs({ limit: 100, skip: 0, token: user?.token });
    setBlogs(data?.blogs);

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
  };

  return (
    blogs?.length > 0 && (
      <div className={style.recentArticles}>
        <div className={style.recentArticlesHeader}>
          <div className={style.recentArticlesHeaderLeftSection}>
            <div className={style.recentArticlesTitle}>
              <span className={style.recentArticlesTitleInActive}>
                {t("blogs.recent")}
              </span>
              <span style={{color:"#4037acd6"}}>
                {t("blogs.articles")}
              </span>
              <span className={style.recentArticlesTitleInActive}>
                {t("blogs.and")}
              </span>
              <span style={{color:"#4037acd6"}}>
                {t("blogs.blog")}
              </span>
            </div>
            <div
              className={style.recentArticlesHeaderRightSection}
              onClick={() => navigate("/blogs")}
            >
              {t("blogs.viewAllBlogs")}
            </div>
          </div>
          <div className={style.recentArticlesDescription}>
            {t("blogs.visitRecentBlogs")}
          </div>
        </div>

        {!isEmpty(blogs) && blogs.length > 3 && (
          <div className={style.recentArticlesBody}>
            <Carousel
              itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
              enableSwipe={false}
            >
              {blogs.slice(index).map((article) => {
                const { _id, imageUrl, name, blogUrl, createdAt, description } =
                  article;
                return (
                  <RecentArticleCard
                    key={_id}
                    date={createdAt}
                    name={name}
                    description={description}
                    url={blogUrl}
                    image={`${S3Bucket}/${imageUrl}`}
                    onClick={() => window.open(blogUrl, "_blank")}
                  />
                );
              })}
            </Carousel>
          </div>
        )}
        {!isEmpty(blogs) && blogs.length == 3 && !isTablet2 && !isMobile && (
          <div className={style.recentArticlesBody}>
            {blogs.slice(index).map((article) => {
              const { _id, imageUrl, name, blogUrl, createdAt, description } =
                article;

              return (
                <div
                  style={{
                    // width: '370px',
                    flex: 1,
                    marginTop: "80px",
                  }}
                >
                  <RecentArticleCard
                    key={_id}
                    date={createdAt}
                    name={name}
                    description={description}
                    url={blogUrl}
                    image={`${S3Bucket}/${imageUrl}`}
                    onClick={() => window.open(blogUrl, "_blank")}
                  />
                </div>
              );
            })}
          </div>
        )}
        {!isEmpty(blogs) && blogs.length == 3 && (isTablet2 || isMobile) && (
          <div className={style.recentArticlesBody}>
            <Carousel
              itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
              enableSwipe={false}
            >
              {blogs.slice(index).map((article) => {
                const { _id, imageUrl, name, blogUrl, createdAt, description } =
                  article;

                return (
                  <RecentArticleCard
                    key={_id}
                    date={createdAt}
                    name={name}
                    description={description}
                    url={blogUrl}
                    image={`${S3Bucket}/${imageUrl}`}
                    onClick={() => window.open(blogUrl, "_blank")}
                  />
                );
              })}
            </Carousel>
          </div>
        )}
        {!isEmpty(blogs) && blogs.length < 3 && !isTablet2 && !isMobile && (
          <div className={style.recentArticlesBody}>
            {blogs.slice(index).map((article) => {
              const { _id, imageUrl, name, blogUrl, createdAt, description } =
                article;

              return (
                <div
                  style={{
                    width: "370px",
                    // flex: 1,
                    marginTop: "80px",
                  }}
                >
                  <RecentArticleCard
                    key={_id}
                    date={createdAt}
                    name={name}
                    description={description}
                    url={blogUrl}
                    image={`${S3Bucket}/${imageUrl}`}
                    onClick={() => window.open(blogUrl, "_blank")}
                  />
                </div>
              );
            })}
          </div>
        )}
        {!isEmpty(blogs) && blogs.length <= 2 && isTablet2 && (
          <div className={style.recentArticlesBody}>
            {blogs.slice(index).map((article) => {
              const { _id, imageUrl, name, blogUrl, createdAt, description } =
                article;
              if (blogs.length == 2) {
                return (
                  <div
                    style={{
                      // width: '370px',
                      flex: 1,
                      marginTop: "100px",
                    }}
                  >
                    <RecentArticleCard
                      key={_id}
                      date={createdAt}
                      name={name}
                      description={description}
                      url={blogUrl}
                      image={`${S3Bucket}/${imageUrl}`}
                      onClick={() => window.open(blogUrl, "_blank")}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      width: "370px",
                      // flex: 1,
                      marginTop: "80px",
                    }}
                  >
                    <RecentArticleCard
                      key={_id}
                      date={createdAt}
                      name={name}
                      description={description}
                      url={blogUrl}
                      image={`${S3Bucket}/${imageUrl}`}
                      onClick={() => window.open(blogUrl, "_blank")}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}
        {!isEmpty(blogs) &&
          blogs.length >= 2 &&
          blogs.length < 3 &&
          isMobile &&
          !isTablet2 && (
            <div className={style.recentArticlesBody}>
              <Carousel
                itemsToShow={isMobile ? 1 : isTablet ? 2 : 3}
                enableSwipe={false}
              >
                {blogs.slice(index).map((article) => {
                  const {
                    _id,
                    imageUrl,
                    name,
                    blogUrl,
                    createdAt,
                    description,
                  } = article;

                  return (
                    <RecentArticleCard
                      key={_id}
                      date={createdAt}
                      name={name}
                      description={description}
                      url={blogUrl}
                      image={`${S3Bucket}/${imageUrl}`}
                      onClick={() => window.open(blogUrl, "_blank")}
                    />
                  );
                })}
              </Carousel>
            </div>
          )}
        {!isEmpty(blogs) && blogs.length == 1 && isMobile && (
          <div className={style.recentArticlesBody}>
            {blogs.slice(index).map((article) => {
              const { _id, imageUrl, name, blogUrl, createdAt, description } =
                article;

              return (
                <div
                  style={{
                    // width: '370px',
                    flex: 1,
                    marginTop: "80px",
                    maxWidth: "88vw",
                  }}
                >
                  <RecentArticleCard
                    key={_id}
                    date={createdAt}
                    name={name}
                    description={description}
                    url={blogUrl}
                    image={`${S3Bucket}/${imageUrl}`}
                    onClick={() => window.open(blogUrl, "_blank")}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    )
  );
};

export default RecentArticlesAndBlogs;
