import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { setPassWord, setPassWordApi } from "../../../modules/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const styles = useStyles();
  const { t } = useTranslation()
  const { "*": token } = useParams();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility
  const navigate = useNavigate();

  const handlePassword = (e) => {
    setPassword(e.target.value);
    const password = e.target.value
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z\u0600-\u06FF])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[0-9a-zA-Z@#$%^&*!\u0600-\u06FF]{8,20}$/;

    if (!password) {
      setPasswordError(t("errorMessages.passwordIsRequired"));
      return;
    } else if (!passwordRegex.test(password)) {
      setPasswordError(t("errorMessages.passwordFormatError"))

      return;
    }
    if (passwordError) {
      setPasswordError("")
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    const confirmPassword = e.target.value

    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z\u0600-\u06FF])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[0-9a-zA-Z@#$%^&*!\u0600-\u06FF]{8,20}$/;

    if (!confirmPassword) {

      setConfirmPassword(t("errorMessages.confirmPasswordIsRequired"))
      return;
    } else if (!passwordRegex.test(confirmPassword)) {
      setConfirmPasswordError(t("errorMessages.passwordFormatError"))

      return;
    }
    if (confirmPasswordError) {
      setConfirmPasswordError("")
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleSubmit = async () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z\u0600-\u06FF])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[0-9a-zA-Z@#$%^&*!\u0600-\u06FF]{8,20}$/;
    if (!password && !confirmPassword) {
      setPasswordError(t("errorMessages.passwordIsRequired"));
      setConfirmPassword(t("errorMessages.confirmPasswordIsRequired"))
      return;
    } else if (!password || !confirmPassword) {
      if (!password) {
        setPasswordError(t("errorMessages.passwordIsRequired"));
        return
      } else {
        setConfirmPassword(t("errorMessages.confirmPasswordIsRequired"))
        return;
      }
    } else if (!passwordRegex.test(password) || !passwordRegex.test(confirmPassword)) {
      if (!passwordRegex.test(password)) {
        setPasswordError(t("errorMessages.passwordFormatError"))
      } else {
        setConfirmPasswordError(t("errorMessages.passwordFormatError"))
      }
      return;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError(t("errorMessages.passwordDoNotMatch"));
      return;
    }

    const data = {
      verificationToken: token,
      password: password,
    };
    console.log('setPasswordApi about to hit')
    const response = await setPassWordApi(data);
    console.log(response);
    if (response.statusCode === 200) {
      toast.success(t("successMessages.passwordChanged"))
      setTimeout(() => {
        navigate("/");
      }, 2000)
    } else {
      console.log('else block')
      toast.error(response?.message)
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.inputDiv}>
        <div>
          <p className={styles.passwordText}>{t("forgotPassword.enterNewPasswordLabel")}</p>
          <TextField
            className={styles.passwordInput}
            placeholder={t("forgotPassword.placeholderPassword")}
            type={showPassword ? "text" : "password"}
            onChange={handlePassword}
            style={{
              cursor: 'pointer'
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  {showPassword ? (
                    <VisibilityIcon onClick={togglePasswordVisibility} />
                  ) : (
                    <VisibilityOffIcon onClick={togglePasswordVisibility} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {passwordError && <p className={styles.error}>{passwordError}</p>}
        </div>
        <div>
          <p style={{ cursor: 'cursor' }} className={styles.passwordText}>{t("forgotPassword.confirmPassword")}</p>
          <TextField
            className={styles.passwordInput}
            placeholder={t("forgotPassword.confirmPassword")}
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleConfirmPassword}
            style={{
              cursor: 'pointer'
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  {showConfirmPassword ? (
                    <VisibilityIcon onClick={toggleConfirmPasswordVisibility} />

                  ) : (
                    <VisibilityOffIcon
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {confirmPasswordError && <p className={styles.error}>{confirmPasswordError}</p>}
        </div>
        <div>
          <button onClick={handleSubmit} className={styles.button}>
            {t("forgotPassword.submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
