import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "20px 20px",
    boxRadius: "8px",
  },
  titleContainer: {
    display: "inline-flex",
    gap: "6px",
    alignItems: "center",
  },
  titleIcon: {
    height: "40px",
    width: "40px",
  },
  title: {
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "700",
  },
  previousFareValue: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
    textDecoration: "line-through",
  },
  updatedFareValue: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
  },
  successButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    textTransform: "capitalize",
    background: "#1b1d52",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
  goBackButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
    textTransform: "capitalize",
    background: "#F8F8F8",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
  fareText: {
    display: "inline-flex",
    gap: "4px",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
  },
  info: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato, sans-serif",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  fareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
}));

export default useStyles;
