import { createSlice } from "@reduxjs/toolkit";

const initialCountry = {
  value: "SA",
  label: "Saudi Arabia",
};

const country =
  JSON.parse(localStorage.getItem("selectedCountry")) ||
  localStorage.setItem("selectedCountry", JSON.stringify(initialCountry));
const globalSlice = createSlice({
  name: "global",
  initialState: {
    searchWidgetType: 1,
    ipAddress: "",
    selectedCountry: country,
  },
  reducers: {
    changeSearchWidgetType: (state, action) => {
      state.searchWidgetType = action.payload;
      localStorage.setItem("searchWidgetType", action.payload);
    },
    handleIPAddress: (state, action) => {
      state.ipAddress = action.payload;
    },
    changeSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
  },
});

export const {
  changeSearchWidgetType,
  handleIPAddress,
  changeSelectedCountry,
} = globalSlice.actions;

export default globalSlice.reducer;
