import React, { useEffect, useState } from "react";
import { get, map, take } from "lodash";
import useStyles from "./styles";
import eventBus from "../../../core/eventBus";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import Carousel from "react-elastic-carousel";
// import Carousel from "react-material-ui-carousel";
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import CloseIcon from "@mui/icons-material/Close";
import ImageCarousel from "../../../components/home/carousel";
import ImageCarousel2 from "../../../components/home/carousel/Carousel2";
// import ImageCarousel from "../../../components/home/carousel/Carousel2";
import "./index.css";
import SimpleImageSlider from "react-simple-image-slider";
import defaultImage1 from "../../../assets/images/605 X 444 (1).png";
import defaultImage2 from "../../../assets/images/302 X 220 (1).png";
import { useTranslation } from "react-i18next";
const HotelImagesDialogView = (props) => {
  const images = get(props, "images", []);
  const classes = useStyles();

  return (
    <>
      <CloseIcon
        onClick={() => eventBus.emit("close-dialog")}
        sx={{
          height: "30px",
          width: "50px",
          color: "white",
          fontWeight: "bold",
          position: "absolute",
          top: "20px",
          right: "60px",
        }}
      />
      {/* <ImageCarousel images={images} /> */}
      <ImageCarousel2 images={images} />
      {/* <div style={{width: '100vw', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        {images.map((img) => {
          return (
            <div key={img} style={{ width: "500px", height: "500px",margin: '5px' }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={img}
                alt="hotel image"
              />
            </div>
          );
        })}
      </div> */}
      {/* <MDBCarousel style={{width: '1000px'}} showIndicators showControls fade>
        {images.map((img) => {
          return (
            <MDBCarouselItem
            // style={{width: '900px'}}
              className="w-100 d-block"
              itemId={1}
              src={img}
              alt="Carousel Image"
            ></MDBCarouselItem>
          );
        })}
      </MDBCarousel> */}
      {/* <CCarousel
        controls
        // style={{
        //   width: "900px",
        //   // height: "600px",
        //   backgroundColor: "transparent",
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        // }}
      >
        {images.map((img) => {
          return (
            <CCarouselItem>
              <CImage
                className="d-block w-100"
                // style={{
                //   width: "900px",
                //   alignSelf: "center",
                // }}
                src={img}
                alt="slide 1"
              />
            </CCarouselItem>
          );
        })}
      </CCarousel> */}
      {/* <Carousel
        disableArrowsOnEnd={true}
        className="allImages"
        style={{
          width: "900px",
          height: "600px",
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {images.map((img) => {
          return (
            <img
              src={img}
              style={{
                width: "900px",
                alignSelf: "center",
              }}
              alt="first_image"
            />
          );
        })}
      </Carousel> */}
    </>
  );
};

export const HotelDetailsImagesView = (props) => {
  const isMobile = useIsMobile();
  const styles = useStyles();
  const { t } = useTranslation();
  const images = get(props, "images", []);
  const [firstFiveImages, setFirstFiveImages] = useState([]);

  const getImages = () => {
    const targetLength = 5;

    if (images) {
      if (images.length >= targetLength) {
        return images.slice(0, targetLength);
      } else {
        const replicatedImages = [];
        for (let i = 0; i < targetLength; i++) {
          replicatedImages.push(images[i % images.length]);
        }
        return replicatedImages;
      }
    }
  };

  const handleMoreClick = () => {
    eventBus.emit("open-dialog", <HotelImagesDialogView images={images} />);
  };

  useEffect(() => {
    if (images) {
      const value = getImages();
      if (("value -> ", value)) setFirstFiveImages(value);
    } else {
      setFirstFiveImages([
        defaultImage1,
        defaultImage2,
        defaultImage2,
        defaultImage2,
        defaultImage2,
      ]);
    }
  }, [images]);

  return (
    <>
      {isMobile ? (
        <div>
          <ImageCarousel2 images={images} />
        </div>
      ) : (
        <div className={styles.hotelDetailsImages}>
          <img
            src={firstFiveImages[0]}
            className={styles.hotelDetailsImagesFirst}
            alt="First_Hotel_Image"
            onError={(e) => {
              e.target.src = defaultImage1;
            }}
          />
          <img
            src={firstFiveImages[1]}
            className={styles.hotelDetailsImagesSecond}
            alt="hotel_image"
            onError={(e) => {
              e.target.src = defaultImage2;
            }}
          />
          <img
            src={firstFiveImages[2]}
            className={styles.hotelDetailsImagesThird}
            alt="hotel_image"
            onError={(e) => {
              e.target.src = defaultImage2;
            }}
          />
          <img
            src={firstFiveImages[3]}
            className={styles.hotelDetailsImagesFourth}
            alt="hotel_image"
            onError={(e) => {
              e.target.src = defaultImage2;
            }}
          />
          <img
            src={firstFiveImages[4]}
            className={styles.hotelDetailsImagesFifth}
            alt="hotel_image"
            onError={(e) => {
              e.target.src = defaultImage2;
            }}
          />
          {images && (
            <button
              className={styles.hotelDetailsImagesButton}
              onClick={handleMoreClick}
            >{`${t("hotelDetails.showAll")} ${images?.length} ${t(
              "hotelDetails.images"
            )}`}</button>
          )}
        </div>
      )}
    </>
  );
};
