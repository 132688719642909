import React, { useEffect, useState } from "react";
import Error from "../../../../../../../error";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import swapSvg from ".././../../../../../../../../assets/images/swap.svg";
import { useDispatch, useSelector } from "react-redux";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import {
  addError,
  removeError,
  updateModifyFlightFrom,
  updateModifyFlightTo,
} from "../../../../../../../../../utils/slices/modifySearchFlightSlice";
import "./style.css";
import { getAirports } from "../../../../../../../../../modules/flight";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { SwapVert } from "@mui/icons-material";
const SearchAirport = ({
  searchAirportId,
  setShowSourceOptions,
  showSourceOptions,
  showDestinationOptions,
  setShowDestinationOptions,
}) => {
  const { flightFrom, flightTo, errors } = useSelector(
    (state) => state.modifySearchFlight
  );

  const [airports, setAirports] = useState([]);
  const [source, setSource] = useState("");
  const [sourceOptionsid, setSourceOptionsId] = useState();
  const [destination, setDestination] = useState("");
  const [destinationOptionsid, setDestinationOptionsId] = useState();
  const [showErrors, setShowErrors] = useState(errors);
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleSourceValue = (id) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    if (destinationOptionsid === id) {
      dispatch(
        addError({
          id: searchAirportId,
          errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
        })
      );
    } else {
      dispatch(removeError({ id: searchAirportId }));
    }

    setSourceOptionsId(id);
    setSource("");
    const [sourceCity] = airports.filter((airport) => airport._id === id);
    dispatch(updateModifyFlightFrom({ id: searchAirportId, data: sourceCity }));
    handleGetAirports();
  };

  const handleDestinationValue = (id) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    if (sourceOptionsid === id) {
      dispatch(
        addError({
          id: searchAirportId,
          errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
        })
      );
    } else {
      dispatch(removeError({ id: searchAirportId }));
    }

    setDestinationOptionsId(id);
    setDestination("");
    const [destinationCity] = airports.filter((airport) => airport._id === id);
    dispatch(
      updateModifyFlightTo({ id: searchAirportId, data: destinationCity })
    );
    handleGetAirports();
  };

  const handleSwap = () => {
    if (
      flightFrom.length > searchAirportId &&
      flightTo.length > searchAirportId &&
      Object.keys(flightFrom[searchAirportId]).length &&
      Object.keys(flightTo[searchAirportId]).length
    ) {
      const temp = flightTo[searchAirportId];
      dispatch(
        updateModifyFlightTo({
          id: searchAirportId,
          data: flightFrom[searchAirportId],
        })
      );
      dispatch(updateModifyFlightFrom({ id: searchAirportId, data: temp }));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (showSourceOptions && !event.target.closest(".source-component")) ||
        source
      ) {
        setSource("");
        setShowSourceOptions([false, false, false]);
        handleGetAirports();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSourceOptions, source]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (showDestinationOptions &&
          !event.target.closest(".destination-component")) ||
        destination
      ) {
        setDestination("");
        setShowDestinationOptions([false, false, false]);
        handleGetAirports();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDestinationOptions, destination]);

  useEffect(() => {
    setShowErrors(errors);
  }, [errors]);

  useEffect(() => {
    const sourceId =
      flightFrom[searchAirportId] && flightFrom[searchAirportId]._id;
    setSourceOptionsId(sourceId);
  }, [flightFrom, searchAirportId]);

  useEffect(() => {
    handleGetAirports();
  }, []);

  const handleGetAirports = async (search = "") => {
    const data = await getAirports({ limit: 100, skip: 0, search });
    if (data.length > 0) {
      setAirports(data);
    }
  };

  return (
    <div className="search-flight-field">
      <div
        className="from"
        onFocusCapture={() => {
          let options = [false, false, false];
          options[searchAirportId] = true;
          setShowSourceOptions(options);
        }}
      >
        <FlightTakeoffIcon className="from-flight-icon" />
        <input
          type="text"
          className="text source-component"
          placeholder={t("flightSearchWidget.from")}
          value={
            flightFrom &&
            flightFrom.length > searchAirportId &&
            Object.keys(flightFrom[searchAirportId]).length
              ? flightFrom[searchAirportId].code +
                " - " +
                flightFrom[searchAirportId].city +
                ", " +
                flightFrom[searchAirportId].country
              : source
          }
          onFocus={() => {
            let options = [false, false, false];
            options[searchAirportId] = true;
            setShowSourceOptions(options);
          }}
          onChange={(e) => {
            let options = [false, false, false];
            options[searchAirportId] = true;
            setShowSourceOptions(options);
            setSource(e.target.value);
            setSourceOptionsId();
            handleGetAirports(e.target.value);
            dispatch(updateModifyFlightFrom({ id: searchAirportId, data: {} }));
          }}
        />
        {showSourceOptions && showSourceOptions[searchAirportId] && (
          <div className={styles.citySuggestions}>
            <div className={styles.citySuggestionsContain}>
              <label style={{ marginBottom: "10px" }}>
                {/* Suggestions */}
                {t("flightSearchWidget.suggestion")}
                </label>
              {airports.map((airport, index) => {
                const { _id, code, name, city, country } = airport;
                if (index < 15) {
                  return (
                    <div
                      className={styles.cityListContainer}
                      key={_id}
                      onClick={() => handleSourceValue(_id)}
                    >
                      <div className={styles.cityListLayout}>
                        <div className={styles.cityName}>
                          <FlightTakeoffIcon />
                          <div>
                            <div className={styles.countryName}>
                              {city}, {country}
                            </div>
                            <div className={styles.airport}>{name}</div>
                          </div>
                        </div>
                        <div>
                          <div className={styles.airportCode}>{code}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
                // return (
                //   <div
                //     className={styles.cityListContainer}
                //     key={_id}
                //     onClick={() => handleSourceValue(_id)}
                //   >
                //     <div className={styles.cityListLayout}>
                //       <div className={styles.cityName}>
                //         <FlightTakeoffIcon />
                //         <div>
                //           <div className={styles.countryName}>
                //             {city}, {country}
                //           </div>
                //           <div className={styles.airport}>{name}</div>
                //         </div>
                //       </div>
                //       <div>
                //         <div className={styles.airportCode}>{code}</div>
                //       </div>
                //     </div>
                //   </div>
                // );
              })}
            </div>
          </div>
        )}
      </div>
      <div
            className={
              localStorage.getItem("language") == "AR"
                ? styles.swapIconWrapperArabic
                : styles.swapIconWrapper
            }
            onClick={handleSwap}
          >
            <SwapVert style={{ fontSize: "24px" }} />
          </div>
      <div
        className="to"
        onFocusCapture={() => {
          let options = [false, false, false];
          options[searchAirportId] = true;
          setShowDestinationOptions(options);
        }}
      >
        <FlightLandIcon className="to-flight-icon" />
        <input
          type="text"
          className="text destination-component"
          placeholder={t("flightSearchWidget.to")}
          value={
            Object.keys(
              flightTo.length > searchAirportId && flightTo[searchAirportId]
            ).length
              ? flightTo[searchAirportId].code +
                " - " +
                flightTo[searchAirportId].city +
                ", " +
                flightTo[searchAirportId].country
              : destination
          }
          onFocus={() => {
            let options = [false, false, false];
            options[searchAirportId] = true;
            setShowDestinationOptions(options);
          }}
          onChange={(e) => {
            let options = [false, false, false];
            options[searchAirportId] = true;
            setShowDestinationOptions(options);
            setDestination(e.target.value);
            setDestinationOptionsId();
            handleGetAirports(e.target.value);
            dispatch(updateModifyFlightTo({ id: searchAirportId, data: {} }));
          }}
        />
        {showDestinationOptions && showDestinationOptions[searchAirportId] && (
          <div
            className={
              searchAirportId == "2"
                ? styles.citySuggestionsMulticity
                : styles.citySuggestions
            }
          >
            <div className={styles.citySuggestionsContain}>
              <label style={{ marginBottom: "10px" }}>
                {/* Suggestions */}
                {t("flightSearchWidget.suggestion")}
                </label>
              {airports.map((airport, index) => {
                const { _id, code, name, city, country } = airport;
                if (index < 15) {
                  return (
                    <div
                      className={styles.cityListContainer}
                      key={_id}
                      onClick={() => handleDestinationValue(_id)}
                    >
                      <div className={styles.cityListLayout}>
                        <div className={styles.cityName}>
                          <FlightLandIcon />
                          <div>
                            <div className={styles.countryName}>
                              {city}, {country}
                            </div>
                            <div className={styles.airport}>{name}</div>
                          </div>
                        </div>
                        <div>
                          <div className={styles.airportCode}>{code}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
                // return (
                //   <div
                //     className={styles.cityListContainer}
                //     key={_id}
                //     onClick={() => handleDestinationValue(_id)}
                //   >
                //     <div className={styles.cityListLayout}>
                //       <div className={styles.cityName}>
                //         <FlightLandIcon />
                //         <div>
                //           <div className={styles.countryName}>
                //             {city}, {country}
                //           </div>
                //           <div className={styles.airport}>{name}</div>
                //         </div>
                //       </div>
                //       <div>
                //         <div className={styles.airportCode}>{code}</div>
                //       </div>
                //     </div>
                //   </div>
                // );
              })}
            </div>
          </div>
        )}
      </div>
      {showErrors.length > 0 &&
        showErrors.map((error) => {
          const { id, errorMessage } = error;
          return (
            <>
              {id === searchAirportId && (
                <div className="source-destination-error" key={id}>
                  <Error key={id} errorMessage={errorMessage} />
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};

export default SearchAirport;

SearchAirport.propTypes = {
  searchAirportId: PropTypes.number,
};
