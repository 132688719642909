import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import CheckSvg from "../../../../../../../assets/images/baggage-check.svg";
import useStyles from "./styles";
import useIsMobile from "../../../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../../../core/eventBus";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CheckPrice = ({ flights, handleBookNow, markupDetails = 0 }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  if (flights?.length <= 1) return;

  let uniqueFlights = [];
  const uniqueFlightsMap = {};
  for (let i = 0; i < flights?.length; i++) {
    const obj = flights[i];
    let fareClassification = obj?.Segments[0][0].FareFamilyClass;
    if (fareClassification == undefined) {
      fareClassification = "general";
    }
    const fare = obj?.Fare;
    const TotalFare = fare?.TotalFare;
    if (uniqueFlightsMap[fareClassification]) {
      const storedFareInformation = uniqueFlightsMap[fareClassification];
      if (TotalFare < storedFareInformation[1]) {
        uniqueFlightsMap[fareClassification] = [obj, TotalFare];
      }
    } else {
      uniqueFlightsMap[fareClassification] = [obj, TotalFare];
    }
  }
  const uniqueFlightsMapKey = Object.keys(uniqueFlightsMap);
  uniqueFlightsMapKey.forEach((flightType) => {
    uniqueFlights.push(uniqueFlightsMap[flightType][0]);
  });

  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
        overflowX: "scroll",
        scrollbarWidth: "none",
        flexDirection: isMobile ? "column" : "row",
        height: isMobile ? "400px" : "inherit",
      }}
    >
      {uniqueFlights &&
        uniqueFlights.map((flight, i) => {
          let Color;
          let Type;

          if (!flight?.Segments[0][0].FareFamilyClass) {
            Color = "#E6E6FA";
            Type = "General";
          } else {
            // const { FareClassification } = flight;
            // Color = FareClassification?.Color;
            Color = "#E6E6FA";
            Type = flight?.Segments[0][0]?.FareFamilyClass;
          }

          const {
            Fare: {
              TotalFare,
              Currency,
              BaseFare,
              OtherCharges,
              Tax,
              VATAmount,
            },
            Segments,
          } = flight;

          const { Baggage, CabinBaggage } = Segments[0][0];
          const [markup, setMarkup] = useState(0);

          useEffect(() => {
            getMarkup(markupDetails);
          }, [BaseFare, TotalFare]);

          const baseFareSAR =
            Math.ceil(TotalFare * rateOfExchange) -
            (Math.ceil(Tax * rateOfExchange) +
              Math.ceil(VATAmount * rateOfExchange));
          const getMarkup = (markupDetails) => {
            if (!markupDetails || Object.keys(markupDetails).length === 0) {
              return;
            }
            let amt = 0;
            const { amount, amountType, appliedOn, maxAmount } = markupDetails;
            if (amountType === "FLAT") {
              amt = amount < maxAmount ? amount : maxAmount;
            } else {
              amt = amount < maxAmount ? amount : maxAmount;
              if (appliedOn === "BASEFARE") {
                amt = (amt * baseFareSAR) / 100;
              } else {
                amt = (amt * TotalFare * rateOfExchange) / 100;
              }
            }
            amt = maxAmount < amt ? maxAmount : amt;
            setMarkup(Math.ceil(amt));
          };

          return (
            <div
              className={classes.flightsDataContainer}
              key={i}
              style={{
                borderTop: `2px solid ${Color}`,
              }}
            >
              <div className={classes.typeCurrencyContainer}>
                <p className={classes.type}>{Type}</p>
                <p className={classes.currency}>
                  {/* {Currency === "INR" && "₹"} */}
                  {/* {(Math.ceil(TotalFare) + markup).toLocaleString("en-IN")} */}
                  {t("flightSearch.currencySymbol")}{" "}
                  {(
                    Math.ceil(TotalFare * rateOfExchange) + markup
                  ).toLocaleString("en-IN")}
                </p>
              </div>
              <Divider sx={{ borderStyle: "dashed" }} />
              <div className={classes.baggagesContainer}>
                <p className={classes.baggageAllowance}>
                  {t("flightSearch.baggageAllowance")}
                </p>
                <div className={classes.baggageAllowedContainer}>
                  <div className={classes.checkContainer}>
                    <ReactSVG src={CheckSvg} />
                  </div>
                  <p className={classes.baggageAllowed}>
                    {Baggage} {t("flightSearch.baggageAllowed")}
                  </p>
                </div>
                {CabinBaggage && (
                  <div className={classes.cabinBaggageAllowedContainer}>
                    <div className={classes.checkContainer2}>
                      <ReactSVG src={CheckSvg} />
                    </div>
                    <p className={classes.cabinBaggageAllowed}>
                      {CabinBaggage} {t("flightSearch.cabinBaggageAllowed")}
                    </p>
                  </div>
                )}
              </div>
              <div
                style={{
                  backgroundColor: `${Color}`,
                }}
                className={classes.selectTextContainer}
                onClick={() => {
                  handleBookNow(flight, markupDetails);
                  eventBus.emit("close-bottomSheet");
                }}
              >
                {t("flightSearch.select")}
              </div>
            </div>
          );
        })}
    </Box>
  );
};

export default CheckPrice;

CheckPrice.propTypes = {
  flights: PropTypes.array,
  index: PropTypes.any,
  handleBookNow: PropTypes.any,
  markupDetails: PropTypes.any,
};
