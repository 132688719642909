import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles";
import { get } from "lodash";
import OTPInput from "react-otp-input";
import { ArrowBack } from "@mui/icons-material";
import eventBus from "../../../../core/eventBus";
import { MobileOtpEnter } from "../mobile-otp-enter";
import { setUserInfo } from "../../../../utils/slices/userSlice";
import { login, verifyOtp } from "../../../../modules/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.css";
import closeIcon from "../../../../assets/images/x-close.svg";
// import { setSignupPopup } from "../../../../utils/slices/popupSlice";
import Signup from "../Signup-mobile";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import SignupForm from "../Signup-mobile/IN";
import { signup } from "../../../../modules/auth";

export const MobileSubmitOtp = ({ data, handleSignupPopupClose, handleOpen }) => {
  // const mobileNumber = data.phoneNo;
  const styles = useStyles();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  // const [minutes, setMinutes] = useState(5);
  // const [seconds, setSeconds] = useState(0);
  // const [otpState, setOTPState] = useState({
  //   value: "",
  //   hasError: false,
  //   errorMessage: "",
  // });

  // const inputRefs = useRef([]);
  const dispatch = useDispatch();

  // const handleOTPChange = (value) => {
  //   const index = otpState.value.length - 1;
  //   const updatedValue = otpState.value.slice(0, -1);
  //   setOTPState((prev) => ({ ...prev, value: updatedValue }));
  //   if (index > 0) {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };
  // const handleOtpPopupClose = () => {
  //   eventBus.emit("close-dialog", <MobileSubmitOtp />);
  // };
  // const handleInputChange = (index, value) => {
  //   const newOtp = otpState.value.split("");
  //   newOtp[index] = value;
  //   setOTPState((prev) => ({ ...prev, value: newOtp.join("") }));

  //   if (value !== "" && inputRefs.current[index + 1]) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // };

  // const handleInputFocus = (index) => {
  //   const newPlaceholder = otpState.value[index] ? "" : " ";
  //   inputRefs.current[index].setAttribute("placeholder", newPlaceholder);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await verifyOtp({
  //       phoneNo: mobileNumber,
  //       otp: otpState.value,
  //       deviceToken: "test",
  //       deviceType: "WEB",
  //     });

  //     const status = response.status;
  //     const message = response.message;
  //     const data = response.data;
  //     const {
  //       newCustomer,
  //       firstName,
  //       accessToken,
  //       lastName,
  //       email,
  //       profilePic,
  //     } = data.data;
  //     if (status === 200) {
  //       if (newCustomer) {
  //         if (handleOtpPopupClose) {
  //           handleOtpPopupClose();
  //         } else {
  //           eventBus.emit("close-dialog", <MobileSubmitOtp />);
  //         }
  //         eventBus.emit("open-dialog", <Signup />);
  //       } else {
  //         if (window.localStorage) {
  //           const userDetails =
  //             window.localStorage &&
  //             JSON.parse(window.localStorage.getItem("userDetails"));
  //           userDetails.newCustomer = newCustomer;
  //           userDetails.firstName = firstName;
  //           userDetails.lastName = lastName;
  //           userDetails.email = email;
  //           userDetails.token = accessToken;
  //           userDetails.profilePic = profilePic;
  //           window.localStorage.setItem(
  //             "userDetails",
  //             JSON.stringify(userDetails)
  //           );
  //           dispatch(setUserInfo(userDetails));
  //           toast.success(`${t("loginPopUP.loginSuccessfully")}`, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //           if (handleOtpPopupClose) {
  //             handleOtpPopupClose();
  //           } else {
  //             eventBus.emit("close-dialog", <MobileSubmitOtp />);
  //           }
  //         }
  //       }
  //     } else {
  //       toast.error(message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  // const handleResentOtp = async () => {
  //   await login({
  //     phoneNo: mobileNumber,
  //     deviceToken: "test",
  //     deviceType: "WEB",
  //   })
  //     .then((data) => {
  //       if (data?.statusCode == 200) {
  //         setMinutes(4);
  //         setSeconds(59);
  //         toast.success(`OTP sent successfully ${mobileNumber}`, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     });
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }

  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(interval);
  //       } else {
  //         setSeconds(59);
  //         setMinutes(minutes - 1);
  //       }
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [seconds]);

  const submitForm = async (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNo: values.number,
      password: values.password,
      deviceToken: "test",
      deviceType: "WEB",
    };
    const response = await signup(data)
    const {
      statusCode,
      data: {
        newCustomer,
        firstName,
        accessToken,
        lastName,
        email,
        profilePic,
        message
      },
    } = response
    if (statusCode === 200) {
      if (window.localStorage) {
        const userDetails = {}
        userDetails.newCustomer = newCustomer;
        userDetails.firstName = firstName;
        userDetails.token = accessToken;
        userDetails.lastName = lastName;
        userDetails.email = email;
        userDetails.profilePic = profilePic;
        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
      }
      //   dispatch(setUserInfo(userDetails));
      //   if (redirectUrl.url !== "/") {
      //     navigate(redirectUrl.url, {
      //       state: redirectUrl.values,
      //     });
      //   }
      // }
      // close dialog
      eventBus.emit("close-dialog", <Signup />);
      handleSignupPopupClose()
      toast.success(t("successMessages.signupSuccessfully"))
      // dispatch(setSignupPopup(false));
    } else {
      toast.error(message)
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.mobileSubmitOtpView}>
        {/* <div
        style={{ alignSelf: "end", position: 'relative', top: '60px', right: '20px' }}
        onClick={() => {
          if (handleSignupPopupClose) {
            handleSignupPopupClose();
          } else {
            eventBus.emit("close-dialog", <MobileSubmitOtp />);
          }
        }}
      >
        <ReactSVG src={closeIcon} />
      </div> */}
        {/* 
      <div className={styles.mobileSubmitOtpViewHeader}>
        <h2 className={styles.mobileSubmitOtpViewHeaderTitle}>
          {t("otp.otpTitle")}
        </h2>
        <p className={styles.mobileSubmitOtpViewHeaderDesc}>
          {` ${t("otp.otpMetaTitle")} ${mobileNumber}`}{" "}
        </p>
      </div> */}
        {/* <div>
        <OTPInput
          value={otpState.value}
          numInputs={4}
          onChange={handleOTPChange}
          separator={<span></span>}
          renderInput={(inputProps, index) => (
            <input
              {...inputProps}
              ref={(ref) => (inputRefs.current[index] = ref)}
              value={otpState.value[index] || ""}
              onChange={(e) => {
                handleInputChange(index, e.target.value);
              }}
              onFocus={(e) => {
                handleInputFocus(index, e.target.value);
              }}
              type="number"
            />
          )}
          containerStyle="containerStyle"
          inputStyle="input-style"
          placeholder="0000"
          shouldAutoFocus
          isInputNum
        />
        <div className={styles.mobileSubmitOtpLabels}>
          <p className={styles.mobileSubmitOtpTimer}>
            {t("otp.expireIn")}{" "}
            <span className={styles.mobileSubmitOtpTimerHighlighted}>
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </p>
          <p className={styles.mobileSubmitOtpTimer}>
            {t("otp.didNotGetOtp")}{" "}
            <span
              className={
                seconds > 0 || minutes > 0
                  ? styles.mobileSubmitOtpTimerDisabled
                  : styles.mobileSubmitOtpTimerHighlighted
              }
              onClick={() => {
                if (seconds > 0 || minutes > 0) {
                  return;
                } else {
                  handleResentOtp();
                }
              }}
            >
              {t("otp.resendNow")}
            </span>
          </p>
        </div>
        <button
          disabled={otpState.hasError}
          type="submit"
          className={styles.mobileSubmitOtpFormSubmit}
          onClick={handleSubmit}
        >
          {t("otp.submit")}
        </button>
        <div
          role="button"
          className={styles.mobileSubmitOtpGoBack}
          // onClick={() => eventBus.emit("open-dialog", <MobileOtpEnter />)}
          onClick={() => {
            handleOpen();
            if (handleOtpPopupClose) {
              handleOtpPopupClose();
            } else {
              eventBus.emit("close-dialog", <MobileSubmitOtp />);
            }
          }}
        >
          <ArrowBack className={styles.goBackArrowIcon} />
          {t("otp.changeNumber")}
        </div>
      </div> */}
        <SignupForm submitForm={submitForm} />
      </div>
    </div>

  );
};
