import React, { useState } from "react";
import useStyles from "./styles";
import { MobileOneWayFlightFilterView } from "../../../../../../ui/views/MobileOneWayFlightFilterModify";
import { MobileMultiCityFlightFilterView } from "../../../../../../ui/views/MobileMultiCityFlightFilterModify";
import { useDispatch, useSelector } from "react-redux";
import { updateModifyFlightTravelType } from "../../../../../../utils/slices/modifySearchFlightSlice";
import CrossSvg from "../../../../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../../../../core/eventBus";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";

const SearchWidgetMobileModify = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const styles = useStyles();
  const {t} = useTranslation()
  const { flightTravelType } = useSelector((state) => state.modifySearchFlight);

  const MOBILE_FIGHT_FILTERS_MAP = {
    oneWay: MobileOneWayFlightFilterView,
    roundTrip: MobileOneWayFlightFilterView,
    multiCity: MobileMultiCityFlightFilterView,
  };

  const [tabsState, setTabsState] = useState({
    primaryTabValue: "flight",
    secondaryTabValue:
      flightTravelType == 1
        ? "oneWay"
        : flightTravelType == 2
        ? "roundTrip"
        : "multiCity",
  });
  const [isHotelCLicked, setHotelClicked] = useState(false);
  const [isFlightClicked, setFlightClicked] = useState(false);
  const [isOneWayClicked, setOneWayClicked] = useState(false);
  const [isRoundClicked, setRoundClicked] = useState(false);

  const handelSetHotelClicked = () => {
    setHotelClicked(!isHotelCLicked);
    setFlightClicked(false);
  };

  const handelSetFligtClicked = () => {
    setHotelClicked(!isFlightClicked);
    setHotelClicked(false);
  };
  const handelOneWayClicked = () => {
    setOneWayClicked(!isOneWayClicked);
    setRoundClicked(false);
  };

  const handelRoundClicked = () => {
    setRoundClicked(!isRoundClicked);
    setOneWayClicked(false);
  };

  const setPrimaryTab = (name) => {
    setTabsState((prev) => ({ ...prev, primaryTabValue: name }));
  };

  const setSecondaryTab = (name) => {
    setTabsState((prev) => ({ ...prev, secondaryTabValue: name }));
  };

  const FlightFilterView =
    MOBILE_FIGHT_FILTERS_MAP[tabsState.secondaryTabValue];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div
        style={{
          paddingTop: "8px",
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <p
          style={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            color: "#344054",
          }}
        >
          {t("flightSearch.modifySearch")}
          {/* Modify Search */}
        </p>
        <ReactSVG
          src={CrossSvg}
          onClick={() => {
            if (isMobile) {
              eventBus.emit("close-bottomSheet");
            } else {
              eventBus.emit("close-dialog");
            }
          }}
        />
      </div>
      <div className={styles.mobileSearchWidget}>
        <div className={styles.mobileSearchWidgetSecondaryTabs}>
          <button
            onClick={() => {
              setSecondaryTab("oneWay");
              dispatch(updateModifyFlightTravelType(1));
            }}
            className={`${styles.secondaryTab1} ${
              tabsState.secondaryTabValue === "oneWay"
                ? styles.activeSecondaryTab
                : ""
            }`}
          >
            {/* One Way */}
            {t("flightSearchWidgetMobile.oneWay")}
          </button>
          <button
            onClick={() => {
              {
                setSecondaryTab("roundTrip");
                dispatch(updateModifyFlightTravelType(2));
              }
            }}
            className={`${styles.secondaryTab2} ${
              tabsState.secondaryTabValue === "roundTrip"
                ? styles.activeSecondaryTab
                : ""
            }`}
          >
            {/* RoundTrip */}
            {t("flightSearchWidgetMobile.roundTrip")}

          </button>
          {/* <button
            onClick={() => {
              setSecondaryTab("multiCity");
              dispatch(updateModifyFlightTravelType(3));
            }}
            className={`${styles.secondaryTab3} ${
              tabsState.secondaryTabValue === "multiCity"
                ? styles.activeSecondaryTab
                : ""
            }`}
          >
            Multi City
          </button> */}
        </div>

        <FlightFilterView
          isDisabled={tabsState.secondaryTabValue === "oneWay" ? true : false}
        />
      </div>
    </div>
  );
};

export default SearchWidgetMobileModify;
