import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import RoundTripFlightSelection from "../cards/round-trip-flight-selection";
import MultiCityFlightSelection from "../cards/multi-city-flight-selection";
import QuickFilters from "../quick-fliters";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  searchFlightForMultiCity,
  searchFlightForOneWayNoAuth,
  searchFlightForDomesticRoundTrip,
  searchFlightForInternationalRoundTrip,
  getAirportByAirportCode,
  searchFlightForOneWay,
} from "../../../../../modules/flight";
import { handleLoader } from "../../../../../utils/slices/modifySearchFlightSlice";
import {
  handleQueryMultiCityDates,
  updateQueryFlightDates,
  updateQueryFlightClassType,
  updateQueryFlightFrom,
  updateQueryFlightTo,
  updateQueryFlightTravelType,
  updateQueryFlightType,
  updateQueryTravellersCount,
  handleQuerySearchApplicable,
} from "../../../../../utils/slices/searchFlightQuerySlice";
import "./style.css";
import BookFlightInternationalRoundTrip from "./international-round-trip";
import { setIndexOfRoundTrip } from "../../../../../utils/slices/bookedFlightSlice";
import FlightSvg from "../../../../../assets/images/no_result_icon_flight.svg";
import DomesticRoundTripContainer from "./domestic-round-trip";
import {
  handleSearchedFlightsForOneWay,
  handleSelectedFlightForOneWay,
} from "../../../../../utils/slices/onewaySlice";
import {
  handleSearchedFlightsForDomesticRoundTrip,
  handleSelectedFlightForDomesticRoundTrip,
} from "../../../../../utils/slices/domesticRoundTripSlice";
import {
  handleClubbedFlightForInternationalRoundTrip,
  handleSearchedFlightsForInternationalRoundTrip,
  handleSelectedFlightForInternationalRoundTrip,
} from "../../../../../utils/slices/internationalRoundTripSlice";
import {
  handleClubbedFlightForFareCategoryForMultiCityTrip,
  handleClubbedFlightsForSecondCityForMultiCityTrip,
  handleIndexOfSelectedMultiCity,
  handleSearchedFlightsForMultiCityTrip,
  handleSelectedFlightForMultiCityTrip,
} from "../../../../../utils/slices/multicitySlice";
import OneWayTripContainer from "./one-way";
import MultiCityTripContainer from "./multi-city";
import { get, isEmpty } from "lodash";
import {
  handleAirlineFilter,
  handleClearAllFilters,
  handleInboundFilters,
  handleOutboundFilters,
  handlePriceFilter,
  handleRefundFilter,
  handleSortFilter,
  handleStopsFilter,
} from "../../../../../utils/slices/flightFiltersSlice";
import useStyles from "./styles";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import { ReactSVG } from "react-svg";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setUserInfo } from "../../../../../utils/slices/userSlice";
import handleUnauthorisedLogout from "../../../../../modules/unauthorised";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setRate } from "../../../../../utils/slices/rateOfExchangeSlice";
// import { useDispatch } from "react-redux";

const SearchFlightResult = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, searchApplicable } = useSelector(
    (state) => state.modifySearchFlight
  );
  const { searchedFlights: oneWayFlights } = useSelector(
    (state) => state.oneWayTripDetails
  );

  const { searchedFlights: domesticRoundFlights } = useSelector(
    (state) => state.domesticRoundTripDetails
  );
  const { searchedFlights: internationalRoundFlights } = useSelector(
    (state) => state.internationalRoundTripDetails
  );
  const { searchedFlights: multiCityFlights } = useSelector(
    (state) => state.multiCityTripDetails
  );

  const isUUIDStored = () => {
    return localStorage.getItem("uuid");
  };

  const generateAndStoreUUID = () => {
    if (!isUUIDStored()) {
      const newUUID = uuidv4();
      localStorage.setItem("uuid", newUUID);
    }
  };

  const uuid = localStorage.getItem("uuid");
  useEffect(() => {
    generateAndStoreUUID();
  }, []);

  const {
    flightTravelType,
    flightType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
    flightClassType,
    multiCityDates,
    flightTotalTravellers: {
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
  } = useSelector((state) => state.searchFlightQuery);

  useEffect(() => {
    dispatch(handleLoader(true));
    const tripType = searchParams.get("tt");
    const paxType = searchParams.get("pt")?.split("_");
    const itinerary = searchParams.get("itinerary")?.split("_");
    const cabinClass = searchParams.get("cc");
    const nonStop = searchParams.get("ns");
    if (itinerary?.length > 0) {
      dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
      dispatch(updateQueryFlightTo({ id: 0, data: {} }));
      dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
      dispatch(updateQueryFlightTo({ id: 1, data: {} }));
      dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
      dispatch(updateQueryFlightTo({ id: 2, data: {} }));
    }

    let totalTravellers = 0;
    let travellersCount = [];
    for (let i = 0; i < paxType?.length; i++) {
      const values = paxType[i]?.split("-");
      travellersCount.push(Number(values[1]));
      totalTravellers += Number(values[1]);
    }

    dispatch(
      updateQueryTravellersCount({
        totalTravellers,
        adultCount: travellersCount[0],
        childrenCount: travellersCount[1],
        infantCount: travellersCount[2],
      })
    );

    dispatch(updateQueryFlightType(JSON.parse(nonStop) ? "1" : ""));
    dispatch(updateQueryFlightClassType(cabinClass));

    if (tripType == "O") {
      const values = itinerary[0]?.split("-");
      dispatch(
        updateQueryFlightDates({ departureDate: values[2], returnDate: "" })
      );
      dispatch(updateQueryFlightTravelType(1));
      handleGetAirport(values[0], values[1], 0);
    } else if (tripType == "R") {
      const firstTrip = itinerary[0]?.split("-");
      const secondTrip = itinerary[1]?.split("-");
      dispatch(
        updateQueryFlightDates({
          departureDate: firstTrip[2],
          returnDate: secondTrip[2],
        })
      );
      dispatch(updateQueryFlightTravelType(2));
      handleGetAirport(firstTrip[0], firstTrip[1], 0);
    } else if (tripType == "M") {
      let multiDates = [];
      dispatch(updateQueryFlightTravelType(3));
      for (let i = 0; i < itinerary?.length; i++) {
        const values = itinerary[i]?.split("-");
        if (i == 0) {
          dispatch(
            updateQueryFlightDates({ departureDate: values[2], returnDate: "" })
          );
        } else {
          multiDates.push({ key: i, date: values[2] });
        }
        handleGetAirport(values[0], values[1], i);
      }
      dispatch(handleQueryMultiCityDates(multiDates));
    }
  }, [searchParams, searchApplicable]);

  useEffect(() => {
    const tripType = searchParams.get("tt");
    const itinerary = searchParams.get("itinerary")?.split("_");
    if (tripType == "O") {
      if (flightFrom[0].code && flightTo[0].code && flightDepartureDate) {
        handleSearchFlights();
      }
    } else if (tripType == "R") {
      if (
        flightFrom[0].code &&
        flightTo[0].code &&
        flightDepartureDate &&
        flightReturnDate
      ) {
        handleSearchFlights();
      }
    } else if (tripType == "M") {
      if (itinerary?.length == 2) {
        if (
          flightFrom[0].code &&
          flightTo[0].code &&
          flightDepartureDate &&
          flightFrom[1].code &&
          flightTo[1].code &&
          multiCityDates
        ) {
          handleSearchFlights();
        }
      } else {
        if (
          flightFrom[0].code &&
          flightTo[0].code &&
          flightDepartureDate &&
          flightFrom[1].code &&
          flightTo[1].code &&
          flightFrom[2].code &&
          flightTo[2].code &&
          multiCityDates
        ) {
          handleSearchFlights();
        }
      }
    }
  }, [
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
    multiCityDates,
    searchApplicable,
  ]);

  const handleGetAirport = async (from, to, index) => {
    const fromAirport = await getAirportByAirportCode(from);
    if (fromAirport?.data) {
      dispatch(
        updateQueryFlightFrom({ id: index, data: fromAirport.data?.data })
      );
    }
    const toAirport = await getAirportByAirportCode(to);
    if (toAirport?.data) {
      dispatch(updateQueryFlightTo({ id: index, data: toAirport.data?.data }));
    }
  };

  const user = useSelector((state) => state.user);
  const classes = useStyles();

  const isMobile = useIsMobile();

  const getFlightSegmentsForMultiCity = () => {
    const results = [];
    for (let i = 0; i < flightFrom?.length; i++) {
      if (flightFrom[i]?.code && flightTo[i].code) {
        results.push({
          origin: flightFrom[i]?.code,
          destination: flightTo[i]?.code,
          // cabinClass: String(flightClassType),
          preferredDepartureTime:
            i === 0
              ? flightDepartureDate
              : get(multiCityDates, `[${i - 1}].date`, ""),
          preferredArrivalTime:
            i === 0
              ? flightDepartureDate
              : get(multiCityDates, `[${i - 1}].date`, ""),
        });
      }
    }

    return results;
  };

  const flightSegments =
    flightTravelType === 2
      ? [
          {
            origin: flightFrom[0]?.code,
            destination: flightTo[0]?.code,
            // cabinClass: String(flightClassType),
            preferredDepartureTime: flightDepartureDate,
            preferredArrivalTime: flightDepartureDate,
          },
          {
            origin: flightTo[0]?.code,
            destination: flightFrom[0]?.code,
            // cabinClass: String(flightClassType),
            preferredDepartureTime: flightReturnDate,
            preferredArrivalTime: flightReturnDate,
          },
        ]
      : flightTravelType === 1
      ? [
          {
            origin: flightFrom[0]?.code,
            destination: flightTo[0]?.code,
            preferredAirlines: [],
            // cabinClass: String(flightClassType),
            preferredDepartureTime: flightDepartureDate,
            preferredArrivalTime: flightDepartureDate,
          },
        ]
      : getFlightSegmentsForMultiCity();

  const flightData = {
    adultCount: flightAdultsCount,
    childCount: flightChildrenCount,
    infantCount: flightInfantsCount,
    cabinClass: String(flightClassType),
    // directFlight: true,
    // oneStopFlight: flightType == 1 ? false : true,
    journeyType: String(flightTravelType),
    flightSegments,
    isInternational:
      flightFrom?.[0].country === flightTo?.[0].country ? false : true,
    additionalDetails: {
      ipAddress: "192.168.1.68",
      deviceType: "WEB",
      browserType: "CHROME",
      uuid: uuid,
    },
  };

  const clearSearchedFlights = () => {
    dispatch(handleLoader(false));
    dispatch(handleSearchedFlightsForOneWay([]));
    dispatch(handleSearchedFlightsForMultiCityTrip([]));
    dispatch(handleSearchedFlightsForDomesticRoundTrip([]));
    dispatch(handleSearchedFlightsForInternationalRoundTrip([]));
  };

  const handleSearchFlights = () => {
    dispatch(handleLoader(true));
    dispatch(handleStopsFilter([]));
    dispatch(handleAirlineFilter([]));
    dispatch(handleRefundFilter([]));
    dispatch(handlePriceFilter([]));
    dispatch(handleIndexOfSelectedMultiCity(0));
    dispatch(handleSelectedFlightForDomesticRoundTrip([]));
    dispatch(handleSelectedFlightForInternationalRoundTrip([]));
    dispatch(handleSelectedFlightForMultiCityTrip([]));
    dispatch(handleSelectedFlightForOneWay([]));
    dispatch(handleSearchedFlightsForOneWay([]));
    dispatch(handleSearchedFlightsForMultiCityTrip([]));
    dispatch(handleSearchedFlightsForDomesticRoundTrip([]));
    dispatch(handleSearchedFlightsForInternationalRoundTrip([]));
    dispatch(handleClubbedFlightForFareCategoryForMultiCityTrip([]));
    dispatch(handleClubbedFlightsForSecondCityForMultiCityTrip([]));
    dispatch(handleClubbedFlightForInternationalRoundTrip([]));
    dispatch(handleClearAllFilters());
    dispatch(handleSortFilter(true));
    window.localStorage.removeItem("flightTravellersDetails");
    window.localStorage.removeItem("contactDetails");
    if (flightTravelType == 1) handleSearchFlightsForOneWay();
    else if (flightTravelType == 2) handleSearchFlightsForRoundtrip();
    else if (flightTravelType == 3) handleSearchForMultiCity();
  };

  const setOutboundFilters = (data) => {
    dispatch(handleOutboundFilters(data));
  };

  const setInboundFilters = (data) => {
    dispatch(handleInboundFilters(data));
  };

  const setRateOfExchange = (data) => {
    dispatch(setRate(data));
  };
  const handleSearchFlightsForOneWay = async () => {
    setOutboundFilters({});
    setInboundFilters({});
    const res = await searchFlightForOneWay(
      flightData,
      clearSearchedFlights,
      setOutboundFilters,
      user?.token,
      setRateOfExchange
    );

    const statusCode = res?.statusCode;
    const message = res?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    if (res?.statusCode === 400) {
      dispatch(handleLoader(false));
      toast.error(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (res?.results) {
      dispatch(handleLoader(false));
      dispatch(handleSearchedFlightsForOneWay(res?.results));
    }
  };

  const handleSearchFlightsForRoundtrip = async () => {
    setOutboundFilters({});
    setInboundFilters({});
    if (
      flightFrom &&
      flightTo
      // flightFrom[0].country !== flightTo[0].country
    ) {
      dispatch(setIndexOfRoundTrip(0));
      const res = await searchFlightForInternationalRoundTrip(
        flightData,
        clearSearchedFlights,
        setOutboundFilters,
        user?.token,
        setRateOfExchange
      );

      const statusCode = res?.statusCode;
      const message = res?.message;
      if (statusCode == 401) {
        handleUnauthorisedLogout(message);
        dispatch(setUserInfo({}));
        navigate("/");
      }

      if (res?.statusCode === 400) {
        dispatch(handleLoader(false));
        toast.error(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (res?.results) {
        dispatch(handleLoader(false));
        dispatch(handleSearchedFlightsForInternationalRoundTrip(res?.results));
      }
    } else {
      const res = await searchFlightForDomesticRoundTrip(
        flightData,
        clearSearchedFlights,
        setOutboundFilters,
        setInboundFilters,
        user?.token
      );

      const statusCode = res?.statusCode;
      const message = res?.message;
      if (statusCode == 401) {
        handleUnauthorisedLogout(message);
        dispatch(setUserInfo({}));
        navigate("/");
      }

      // if (res?.statusCode === 400) {
      //   dispatch(handleLoader(false));
      //   toast.error(res?.message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // } else if (res?.results) {
      //   dispatch(handleLoader(false));
      //   dispatch(
      //     handleSelectedFlightForDomesticRoundTrip([
      //       res?.results[0][0],
      //       res?.results[1][0],
      //     ])
      //   );
      //   dispatch(handleSearchedFlightsForDomesticRoundTrip(res?.results));
      // }
    }
  };

  const handleSearchForMultiCity = async () => {
    setOutboundFilters({});
    setInboundFilters({});
    const res = await searchFlightForMultiCity(
      flightData,
      clearSearchedFlights,
      setOutboundFilters,
      user?.token,
      setRateOfExchange
    );

    const statusCode = res?.statusCode;
    const message = res?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    if (res?.statusCode === 400) {
      dispatch(handleLoader(false));
      toast.error(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (res?.results) {
      dispatch(handleLoader(false));
      dispatch(handleSearchedFlightsForMultiCityTrip(res?.results));
    }
  };

  if (loading)
    return (
      <div className={classes.loader}>
        <Rings
          height={isMobile ? "100" : "150"}
          width="120"
          color="#1B1D52"
          radius="6"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
      </div>
    );

  return (
    <>
      {oneWayFlights?.length > 0 ||
      domesticRoundFlights?.length > 0 ||
      internationalRoundFlights?.length > 0 ||
      multiCityFlights?.length > 0 ? (
        <div className="container-search-result-flights">
          {flightTravelType == 3 && <MultiCityFlightSelection />}
          {flightTravelType == 2 && (
            // flightFrom[0].country != flightTo[0].country && (
            //   <RoundTripFlightSelection />
            // )
            <RoundTripFlightSelection />
          )}
          <QuickFilters />
          {flightTravelType == 1 && <OneWayTripContainer />}
          {flightTravelType == 2 && (
            // flightFrom[0].country !== flightTo[0].country && (
            //   <BookFlightInternationalRoundTrip />
            // )
            <BookFlightInternationalRoundTrip />
          )}
          {/* {flightTravelType == 2 &&
            flightFrom[0].country == flightTo[0].country && (
              <DomesticRoundTripContainer />
            )} */}
          {flightTravelType == 3 && <MultiCityTripContainer />}
        </div>
      ) : (
        <div className={classes.mainContainer}>
          <div className={classes.innerContainer}>
            <ReactSVG src={FlightSvg} />
            <div className={classes.flightNotAvailableContainer}>
              <p className={classes.flightNotAvailable}>
                {t("errorMessages.flightNotFoundError")}
              </p>
              <p className={classes.notAvailableDescription}>
                {t("errorMessages.weCouldNotFindFlight")}
              </p>
            </div>
            <div className={classes.refreshTextMainContainer}>
              <div
                className={classes.refreshTextContainer}
                onClick={() => navigate("/")}
              >
                <p className={classes.refreshText}>
                  {t("errorMessages.search")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchFlightResult;
