import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  popUpLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 24px",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: "10px",
    margin: "250px 100px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "34%",
      width: "32%",
      marginTop: "18%",
    },
  },

  gifLayout: {
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      height: "60px",
    },
  },
}));

export default useStyles;
