import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../assets/images/clock.svg";
import { get } from "lodash";
import moment from "moment";
import { Modal, Divider } from "@mui/material";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import HotelPolicyPopup from "./HotelPolicyPopup";
import useStyles from "./style";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import { Policy } from "@mui/icons-material";
import eventBus from "../../../../core/eventBus";
import { useLocation } from "react-router-dom";
import { BookingSummaryMobile } from "../../../../Pages/hotel-module/personal-details";
import { useTranslation } from "react-i18next";
import { convertToArabicDateInDDMMYYY } from "../../../../utils/helpers";
import { getArabicDayName } from "../../../../utils/helpers";
import { convertISOToArabicDateInDDMMYYY } from "../../../../utils/helpers";
import { getArabicDayNameFromISODate } from "../../../../utils/helpers";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const SelectedHotelDetails = (props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const confirmationScreenDetails = props?.confirmationScreenDetails;
  const bookedHotelDetails = props?.bookedHotelDetails;
  const bookedHotelName = bookedHotelDetails?.HotelName;
  const bookedHotelCheckIn = bookedHotelDetails?.CheckIn;
  const bookedHotelCheckOut = bookedHotelDetails?.CheckOut;
  const bookedHotelRateConditions = bookedHotelDetails?.RateConditions;
  const bookedRooms = bookedHotelDetails?.Rooms;
  let bookedHotelRoomsCount = 0;
  let bookedHotelAdultsCount = 0;
  let bookedHotelChildrenCount = 0;
  let bookedHotelcheckInDateRaw = moment(bookedHotelCheckIn);
  let bookedHotelCheckOutDateRaw = moment(bookedHotelCheckOut);

  let bookedHotelCheckInDate;
  let bookedHotelCheckInDay;
  let bookedHotelCheckOutDate;
  let bookedHotelCheckOutDay;

  if (localStorage.getItem("language") == "AR") {
    bookedHotelCheckInDate = convertISOToArabicDateInDDMMYYY(bookedHotelCheckIn)
    bookedHotelCheckInDay = getArabicDayNameFromISODate(bookedHotelCheckIn)

    bookedHotelCheckOutDate = convertISOToArabicDateInDDMMYYY(bookedHotelCheckOut)
    bookedHotelCheckOutDay = getArabicDayNameFromISODate(bookedHotelCheckOut)
  } else {
    bookedHotelCheckInDate = bookedHotelcheckInDateRaw.format("D MMMM YYYY")
    bookedHotelCheckInDay = bookedHotelcheckInDateRaw.format("dddd")

    bookedHotelCheckOutDate = bookedHotelCheckOutDateRaw.format("D MMMM YYYY")
    bookedHotelCheckOutDay = bookedHotelCheckOutDateRaw.format("dddd")
  }


  const bookedHotelsNumberOfNights = bookedHotelCheckOutDateRaw.diff(
    bookedHotelcheckInDateRaw,
    "days"
  );
  if (bookedRooms) {
    bookedHotelRoomsCount = bookedRooms?.length;
    bookedRooms.map((room) => {
      room.CustomerDetails.map((customer) => {
        customer.CustomerNames.map((name) => {
          if (name.Type == "Adult") {
            bookedHotelAdultsCount++;
          } else {
            bookedHotelChildrenCount++;
          }
        });
      });
    });
  }
  const hotelName = props?.details?.hotelDetails?.hotelDetails?.HotelName;
  const HotelName = props?.details?.hotelDetails?.HotelName;
  const HotelPolicy = props?.details?.hotelDetails?.hotelDetails?.Description;
  const hotelPolicy = props?.details?.hotelDetails?.Description;
  // const filterDetails = get(props, "details.filterValues", {});
  const filterDetails = get(props, "details.hotelFiltersState", {});
  const rooms = get(filterDetails, "totalGuests", []);
  const adultCount =
    rooms && rooms.reduce((acc, curr) => acc + curr.adultCounter, 0);
  const childrenCount =
    rooms && rooms.reduce((acc, curr) => acc + curr.childrenCounter, 0);
  let checkInDateRaw = moment(
    get(filterDetails, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  );
  let checkInDate;
  let checkOutDate;
  let checkInDay;
  let checkOutDay;

  if (localStorage.getItem("language") == "AR") {
    checkInDate = convertToArabicDateInDDMMYYY(get(filterDetails, "hotelCheckInDate", ""))
    checkInDay = getArabicDayName(get(filterDetails, "hotelCheckInDate", ""))
  } else {
    checkInDate = checkInDateRaw.format("D MMMM YYYY")
    checkInDay = checkInDateRaw.format("dddd")
  }
  const checkOutDateRaw = moment(
    get(filterDetails, "hotelCheckoutDate", ""),
    "DD/MM/YYYY"
  );
  if (localStorage.getItem("language") == "AR") {
    checkOutDate = convertToArabicDateInDDMMYYY(get(filterDetails, "hotelCheckoutDate", ""))
    checkOutDay = getArabicDayName(get(filterDetails, "hotelCheckoutDate", ""))
  } else {
    checkOutDate = checkOutDateRaw.format("D MMMM YYYY")
    checkOutDay = checkOutDateRaw.format("dddd")
  }
  const noOfNights = checkOutDateRaw.diff(checkInDateRaw, "days");
  const [open, setOpen] = useState(false);

  const isMobile = useIsMobile();

  const handleOpen = () => {
    if (isMobile && pathname.startsWith("/hotel/add-details/")) {
      eventBus.emit("close-bottomSheet", <BookingSummaryMobile />);
    }
    eventBus.emit(
      "open-dialog",
      <HotelPolicyPopup
        handleModalClose={handleClose}
        policy={
          confirmationScreenDetails
            ? bookedHotelRateConditions
            : HotelPolicy
              ? HotelPolicy
              : hotelPolicy
        }
      />
    );
  };
  const handleClose = () => {
    eventBus.emit(
      "close-dialog",
      <HotelPolicyPopup
        handleModalClose={handleClose}
        policy={HotelPolicy ? HotelPolicy : hotelPolicy}
      />
    );
  };
  const classes = useStyles();
  return (
    <>
      {confirmationScreenDetails && (
        <div className={classes.mainContainer}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              {bookedHotelName}
            </p>

            <p
              style={{
                color: "#4037acd6",
                fontFamily: "Lato",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                cursor: "pointer",
                width: '75px'
              }}
              onClick={() => {
                eventBus.emit("close-bottomSheet");
                handleOpen();
              }}
            >
              {t("addContactDetailsScreenHotel.hotelPolicies")}
            </p>

          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p
                style={{
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {t("hotelSearchResult.checkIn")}
              </p>

              <>
                <dir>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {bookedHotelcheckInDateRaw.format("dddd")} */}
                    {bookedHotelCheckInDay}
                  </p>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {bookedHotelcheckInDateRaw.format("D MMMM YYYY")} */}
                    {bookedHotelCheckInDate}
                  </p>
                </dir>
              </>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactSVG src={ClockSvg} />
              {isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {bookedHotelsNumberOfNights}
                  </p>
                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {bookedHotelsNumberOfNights > 1
                      ? `${t("addContactDetailsScreenHotel.nights")}`
                      : `${t("addContactDetailsScreenHotel.night")}`}
                  </p>
                </div>
              ) : (
                <p
                  style={{
                    color: "#667085",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {bookedHotelsNumberOfNights > 1
                    ? `${bookedHotelsNumberOfNights} ${t(
                      "addContactDetailsScreenHotel.nights"
                    )}`
                    : `${bookedHotelsNumberOfNights} ${t(
                      "addContactDetailsScreenHotel.night"
                    )}`}
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                marginLeft: isMobile ? "10px" : 0,
              }}
            >
              <p
                style={{
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {t("hotelSearchResult.checkOut")}
              </p>

              <>
                <div>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {bookedHotelCheckOutDateRaw.format("dddd")} */}
                    {bookedHotelCheckOutDay}
                  </p>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {bookedHotelCheckOutDateRaw.format("D MMMM YYYY")} */}
                    {bookedHotelCheckOutDate}
                  </p>
                </div>
              </>
            </div>
          </div>
          <Divider />
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bookedHotelRoomsCount}
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bookedHotelRoomsCount > 1
                ? `${t("addContactDetailsScreenHotel.rooms")}`
                : `${t("addContactDetailsScreenHotel.room")}`}
            </p>
            <div
              style={{
                width: "2px",
                height: "20px",
                borderRight: "2px solid rgba(0, 0, 0, 0.12)",
              }}
            ></div>

            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bookedHotelAdultsCount}
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bookedHotelAdultsCount > 1
                ? `${t("addContactDetailsScreenHotel.adults")}`
                : `${t("addContactDetailsScreenHotel.adult")}`}
            </p>
            <div
              style={{
                width: "2px",
                height: "20px",
                borderRight: "2px solid rgba(0, 0, 0, 0.12)",
              }}
            ></div>
            {bookedHotelChildrenCount ? (
              <>
                <p
                  style={{
                    color: "#344054",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {bookedHotelChildrenCount}
                </p>
                <p
                  style={{
                    color: "#667085",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {bookedHotelChildrenCount > 1
                    ? `${t("addContactDetailsScreenHotel.children")}`
                    : `${t("addContactDetailsScreenHotel.child")}`}
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {!confirmationScreenDetails && (
        <div className={classes.mainContainer}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{
                width: "75%",
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              {hotelName ? hotelName : HotelName}
            </p>
            {HotelPolicy ? (
              <div className="">
                <p
                  style={{
                    color: "#4037acd6",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    cursor: "pointer",
                    width: '75px'
                  }}
                  onClick={() => {
                    eventBus.emit("close-bottomSheet");
                    handleOpen();
                  }}
                >
                  {t("addContactDetailsScreenHotel.hotelPolicies")}
                </p>
              </div>
            ) : hotelPolicy ? (
              <div className="">
                <p
                  style={{
                    color: "#4037acd6",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    cursor: "pointer",
                    width: '75px'
                  }}
                  onClick={() => {
                    eventBus.emit("close-bottomSheet");
                    handleOpen();
                  }}
                >
                  {t("addContactDetailsScreenHotel.hotelPolicies")}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p
                style={{
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {t("hotelSearchResult.checkIn")}
              </p>

              <>
                <dir>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {checkInDateRaw.format("dddd")} */}
                    {checkInDay}
                  </p>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {checkInDateRaw.format("D MMMM YYYY")} */}
                    {checkInDate}
                  </p>
                </dir>
              </>


            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <ReactSVG src={ClockSvg} />
              {isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {noOfNights}
                  </p>
                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {noOfNights > 1
                      ? `${t("addContactDetailsScreenHotel.nights")}`
                      : `${t("addContactDetailsScreenHotel.night")}`}
                  </p>
                </div>
              ) : (
                <p
                  style={{
                    color: "#667085",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {noOfNights > 1
                    ? `${noOfNights} ${t(
                      "addContactDetailsScreenHotel.nights"
                    )}`
                    : `${noOfNights} ${t(
                      "addContactDetailsScreenHotel.night"
                    )}`}
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                marginLeft: isMobile ? "10px" : 0,
              }}
            >
              <p
                style={{
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {t("hotelSearchResult.checkOut")}
              </p>

              <>
                <div>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {checkOutDateRaw.format("dddd")} */}
                    {checkOutDay}
                  </p>
                  <p
                    style={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {/* {checkOutDateRaw.format("D MMMM YYYY")} */}
                    {checkOutDate}
                  </p>
                </div>
              </>

              {/* <Typography
            style={{
              color: "#667085",
              fontFamily: "Lato",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Fri, 11:00 am
          </Typography> */}
            </div>
          </div>
          <Divider />
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {rooms.length}
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {rooms.length > 1
                ? `${t("addContactDetailsScreenHotel.rooms")}`
                : `${t("addContactDetailsScreenHotel.room")}`}
            </p>
            <div
              style={{
                width: "2px",
                height: "20px",
                borderRight: "2px solid rgba(0, 0, 0, 0.12)",
              }}
            ></div>

            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {adultCount}
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {adultCount > 1
                ? `${t("addContactDetailsScreenHotel.adults")}`
                : `${t("addContactDetailsScreenHotel.adult")}`}
            </p>
            <div
              style={{
                width: "2px",
                height: "20px",
                borderRight: "2px solid rgba(0, 0, 0, 0.12)",
              }}
            ></div>
            {childrenCount ? (
              <>
                <p
                  style={{
                    color: "#344054",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {childrenCount}
                </p>
                <p
                  style={{
                    color: "#667085",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {childrenCount > 1
                    ? `${t("addContactDetailsScreenHotel.children")}`
                    : `${t("addContactDetailsScreenHotel.child")}`}
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedHotelDetails;
