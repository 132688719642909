import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import TickGif from ".././../../../../../../../../assets/images/tick-gif.gif";
import TicketSvg from ".././../../../../../../../../assets/images/download-01.svg";
import EmailSmsSvg from ".././../../../../../../../../assets/images/ticket-01.svg";
import { sendTicketsOrVouchersToMail } from "../../../../../../../../../modules/home";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";
import LoadingBackdrop from "../../../../../../../../flight-module/cards/loading-backdrop";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile.hook";
import { toast } from "react-toastify";

const TicketOptions = ({ pdfUrl, ticket, id, loader, setLoader }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [desc, setDesc] = useState("");

  const {
    ticket: {
      Results: {
        Itinerary: { Passenger },
      },
    },
  } = ticket;
  const { Email } = Passenger[0];

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "ticket.pdf";
    link.target = "_blank";
    link.click();
  };

  const handleSendTicketsOrVouchersToMail = async () => {
    setLoader(true);
    const res = await sendTicketsOrVouchersToMail({
      token: user.token,
      product: "FLIGHTS",
      bookingId: id,
    });

    if (res.statusCode === 200) {
      setLoader(false);
      setShowSuccessModal(true);
      setDesc(`${t("errorMessages.ticketSentOn")} ${Email}!`);
    } else {
      setLoader(false);
      // setShowSuccessModal(true);
      // setDesc(`${t("errorMessages.errorTryAgain")}`);
      // setDesc(res?.message)
      toast.error(res?.message)
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 2800);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSuccessModal]);

  const isMobile = useIsMobile()
  return (
    <>
      <div className={styles.mainContainer}>
        <p className={styles.heading}>{t("myProfile.tickets")}</p>
        <div className={styles.optionsContainer} onClick={handleDownload}>
          <ReactSVG src={TicketSvg} className={styles.optionsIcon} />
          <p className={styles.optionsText}>
            {t("myProfile.downloadETickets")}
          </p>
        </div>
        <div
          className={styles.optionsContainer}
          onClick={handleSendTicketsOrVouchersToMail}
        >
          <ReactSVG src={EmailSmsSvg} className={styles.optionsIcon} />
          <p className={styles.optionsText}>{t("myProfile.emailETickets")}</p>
        </div>
      </div>
      {loader ? (
        <LoadingBackdrop open={loader} />
      ) : (
        showSuccessModal && (
          <Modal
            open={showSuccessModal}
            onClose={() => setShowSuccessModal(false)}
            sx={{ outline: "none" }}
          >
            <div className={styles.modalContainer}
              style={{
                marginRight: localStorage.getItem("language") == "AR"
                  ? isMobile
                    ? '15%'
                    : "34%"
                  : "0"
              }}
              >
              <img src={TickGif} />
              <p
                style={{
                  color: "#344054",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {desc}
              </p>
            </div>
          </Modal>
        )
      )}
    </>
  );
};

export default TicketOptions;
