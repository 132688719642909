import React from "react";
import { get } from "lodash";
import useStyles from "./styles";
import defaultHotelImage from "../../../../src/assets/images/defaultHotelImage.png";
import defaultHotelImage2 from "../../../../src/assets/images/438 X 190.png";
import { useState } from "react";

const CardComp = (props) => {
  const styles = useStyles();

  const image = get(props, "image", "");
  const children = get(props, "children", <></>);
  const [imageError, setImageError] = useState(false);

  const handleImageError = (e) => {
    setImageError(true);
  };
  return (
    <div className={styles.uiCard}>
      {!imageError ? (
        <img onError={handleImageError} src={image} alt="Default image" className={styles.uiCardImage} />
      ) : (
        
        <img
          src={defaultHotelImage2}
          alt="hotel image"
          className={styles.uiCardImage}
        />
      )}

      <div className={styles.uiCardBody}>{children}</div>
    </div>
  );
};

export default CardComp;
