import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import AlertSvg from "../../../../assets/images/alertSvg.svg";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { resendEmailVerificationLink } from "../../../../modules/auth";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

const EmailVerification = ({
  showVerificatonPopup,
  setShowEmailVerificationPopup,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const styles = useStyles();
  const {t}  = useTranslation()

  const handleResendVerificationLink = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    console.log("userDetails", userDetails);
    const payload = { email: userDetails.email, token: userDetails.token };
    const response = await resendEmailVerificationLink(payload);
    if (response?.statusCode == 200) {
      toast.success(t("successMessages.verificationEmailSent"));
      setShowEmailVerificationPopup(false);
    } else {
      toast.error(t("errorMessages.errorInSendingEmail"));
    }
    // console.log('response', response)
  };

  const handleOK = () => {
    setShowEmailVerificationPopup(false);
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={showVerificatonPopup}
      onClose={() => setShowEmailVerificationPopup(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.mainContainer}>
        <CloseIcon
          style={{
            position: "absolute",
            top: "25px",
            right: "25px",
          }}
          onClick={() => {
            setShowEmailVerificationPopup(false);
          }}
        />

        <DialogTitle id="responsive-dialog-title" className={styles.title}>
          <div className={styles.titleContainer}>
            <img src={AlertSvg} className={styles.titleIcon} />
            <p className={styles.title}>
              {/* Email Not verified */}
              {t("registration.emailNotVerified")}
              </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={styles.contentContainer}>
            <DialogContentText>
              <p>
                {/* Please verify your email address before proceeding to book. */}
                {t("registration.pleaseVerifyYourEmail")}
                </p>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <div onClick={handleOK} className={styles.goBackButton}>
            Ok
          </div> */}
          <div
            onClick={handleResendVerificationLink}
            className={styles.successButton}
          >
            {/* Resend Verification link */}
            {t("registration.resendVerificationLink")}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default EmailVerification;
