import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
      borderColor: "#D0D5DD",
    },

    [theme.breakpoints.down("sm")]: {
      "& input::placeholder": {
        fontSize: "12px",
      },
    },
  },
  datePicker: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD", // Set the border color to black
      },
    },
    width: "100%",
    marginTop: "2px",
  },

  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    padding: "0px 16px 24px 4px",
  },
  titleDataContainer: {
    display: "inline-flex",
    gap: "16px",
  },
  nameContainerRow1: {
    display: "inline-flex",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column'
    }
  },
  firstNameContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  firstNameText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  middleNameContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  middleNameText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  nameContainerRow2: {
    display: "inline-flex",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column'
    }
  },

  lastNameContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  lastNameText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  dobContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
  },
  dobText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    marginBottom: "8px",
  },
  nationalityContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  nationalityText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  passportDetailContainerRow1: {
    display: "inline-flex",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column'
    }
  },
  passportNumberDetailContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  passportNumberText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  issuingCountryDetailContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  issuingCountryText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  expiryDateContainerRow: {
    display: "inline-flex",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down('sm')]:{
      flexDirection: 'column'
    }
  },
  expiryDateContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  expiryDateText: {
    color: "#344054",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  emptyContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  buttonBackgroundNonActive: {
    padding: "12px 24px",
    borderRadius: "12px",
    border: "1px solid #D0D5DD",
    background: " #FFF",
    color: "#525A6A",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    cursor: "pointer",
  },
  buttonBackgroundActive: {
    background: "#E6E6FA",
    color: "#1b1d52",
    padding: "12px 24px",
    borderRadius: "12px",
    border: "1px solid #D0D5DD",
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 400",
    lineHeight: " 24px",
    cursor: "pointer",
  },
}));

export default useStyles;
