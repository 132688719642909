import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainProfilePageContainer: {
    background: " #FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px 150px",
    [theme.breakpoints.down("lg")]: {
      padding: "10px 50px",
      // flexDirection: 'row',
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
      // flexDirection: 'row',
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px",
      // flexDirection: 'row',
    },
  },
  sidebarAndProfileDetailsContainer: {
    display: "inline-flex",
    gap: "24px",
    width: "100%",
    marginBottom: "90px",
    [theme.breakpoints.down("sm")]: {
      // display: 'flex',
      flexDirection: "column",
      alignItems: "center",
      // position: "absolute",
      // top: "0",
      // right: "0",
    },
    [theme.breakpoints.down("md")]: {
      // display: 'flex',
      flexDirection: "column",
      alignItems: "center",
      // position: "absolute",
      // top: "0",
      // right: "0",
    },
  },
  breadCrumbContainer: {
    display: "inline-flex",
    gap: "8px",
    alignItems: "center",
  },
  homeBreadCrumb: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  myProfileBreadCrumb: {
    color: "#4037acd6",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    cursor: "pointer",
  },
  profileSideBarContainer: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  profileDetailsContainer: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

export default useStyles;
