import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileOtpView: {
    maxWidth: "100%",
    width: '500px',
    padding: "25px",
    margin: '25px',
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px"
  },
  mobileOtpViewHeader: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    marginBottom: "40px",
  },
  mobileOtpViewHeaderTitle: {
    fontSize: theme.fontSizes.fontSize0,
    color: theme.palette.textColors.textColorPrimary,
  },
  mobileOtpViewHeaderDesc: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorSecondary,
  },
  mobileOtpFormField: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  mobileOtpFieldWrapper: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
    padding: "16px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  mobileOtpFieldForm: {
    // height: "200px",
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    // justifyContent: "space-between",
  },
  mobileOtpFieldLabel: {},
  mobileOtpField: {
    width: "100%",
    // height: "100%",
    border: "none",

    fontSize: theme.fontSizes.fontSize1,
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize4,
    },
    MozAppearance: "textfield",
  },
  mobileOtpFormSubmit: {
    width: "100%",
    color: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "10px",
    fontSize: theme.fontSizes.fontSize1,
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    border: "none",
    padding: "16px 12px",
  },
  mobileOtpTC: {
    fontSize: theme.fontSizes.fontSize1,
    marginTop: "10px",
    textAlign: "center",
  },
  mobileOtpTCHighlighted: {
    cursor: "pointer",
    color: theme.palette.entityColors.entityColorPrimary,
  },
  mobileOtpFieldError: {
    color: theme.palette.entityColors.entityColorBackgroundRed,
  },
}));

export default useStyles;
