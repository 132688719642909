import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  packages: {
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "24px 150px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },

  searchPackage: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  packagesLoader: {
    display: "flex",
    justifyContent: "center",
    width: "500px",
    maxWidth: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      background: "#FAFAFA",
    },
  },
  subContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "1434px",
    },
  },
  errorImage: {
    [theme.breakpoints.down("sm")]: {
      width: "140px",
    },
  },
}));

export default useStyles;
