import React from "react";
import { makeStyles } from "@mui/styles";
// import ArrowSvg from "../../../assets/images/Line arrow-left.svg";
import RightArrow from "../../../../assets/images/arrow-rightRight arrow.png";
import eventBus from "../../../../core/eventBus";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "10px",
    // padding: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFF",
    border: "1px solid #D0D5DD",
    padding: "24px 20px",
    [theme.breakpoints.down('sm')]: {
      maxHeight: '360px',
      maxWidth: '98%'
    }
  },
}));

function BookingSummary() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.mainContainer}>
      <p
        style={{
          color: "#344054",
          fontFamily: "Lato, sans-serif",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {t("addContactDetailsScreenHotel.bookingSummary")}
      </p>
      <img src={RightArrow} />
    </div>
  );
}

export default BookingSummary;
