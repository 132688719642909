import React from "react";
import PropTypes from "prop-types";
import { Box, Modal, Typography } from "@mui/material";
import LogoutPopupSvg from "../../assets/images/logout-popup.svg";
import { ReactSVG } from "react-svg";
import { logout } from "../../modules/auth";
import { setUserInfo } from "../../utils/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const Logout = ({ showLogoutModal, setShowLogoutModal }) => {
  const dispatch = useDispatch();
  const {t}  = useTranslation()
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const getLocale = useSelector((state) => state.locale);
  const path = location.pathname;
  const userDetails =
    window.localStorage &&
    JSON.parse(window.localStorage.getItem("userDetails"));
  const handleLogout = async () => {
    if (userDetails && userDetails.token) {
      setShowLogoutModal(false);
      const token = userDetails.token;
      const response = await logout(token);
      const { statusCode } = response;
      if (statusCode === 200) {
        window.localStorage.removeItem("userDetails");
        dispatch(setUserInfo({}));
        path == "/" ? window.location.reload() : navigate("/");
      }
    }
  };

  return (
    <Modal
      open={showLogoutModal}
      onClose={() => {
        setShowLogoutModal(false);
      }}
    >
      <div
        className={
          localStorage.getItem('language') == "AR"
            ? classes.mainContainerArabic
            : classes.mainContainer
        }
        style={{
          marginRight: localStorage.getItem('language') == "AR" ? '35%': '0px'
        }}
      >
        <div className={classes.logoutAndAreYouSureTextContainer}>
          <ReactSVG src={LogoutPopupSvg} />
          <p className={classes.logoutText}>
            {/* Logout */}
            {t("logoutCards.logout")}
            </p>
          <p className={classes.areYouSureText}>
            {/* Are you sure you want to log out ? */}
            {t("logoutCards.areyouSureText")}
          </p>
        </div>
        <div
          className={classes.confirmContainer}
          onClick={() => handleLogout()}
        >
          <p className={classes.confirmText} onClick={() => handleLogout()}>
            {/* Confirm */}
            {t("logoutCards.confirm")}
          </p>
        </div>
        <p
          className={classes.cancelText}
          onClick={() => {
            setShowLogoutModal(false);
          }}
        >
          {/* Cancel */}
          {t("logoutCards.cancel")}
        </p>
      </div>
    </Modal>
  );
};

export default Logout;

Logout.propTypes = {
  showLogoutModal: PropTypes.bool,
  setShowLogoutModal: PropTypes.func,
};
