import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "inline-flex",
    gap: "15px",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  type: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "700",
    color: "#344054",
  },
  currency: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "700",
    color: "#344054",
  },
  typeCurrencyContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  baggageAllowance: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  checkContainer: {
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EEFFE5",
  },
  baggageAllowed: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  baggageAllowedContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  cabinBaggageAllowed: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  checkContainer2: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cabinBaggageAllowedContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  baggagesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    borderRadius: "4px",
    width: "220px",
  },
  selectTextContainer: {
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 12px",
    color: "#344054",
    fontWeight: "600",
    fontSize: "14px",
    fontFamily: "Lato",
    cursor: "pointer",
  },
  flightsDataContainer: {
    background: "#FFF",
    border: "1px solid #D0D5DD",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
}));

export default useStyles;
