import React, { useEffect, useState } from "react";
import { subscribeToNewsLetter } from "../../../modules/home";
import tickGif from "../../../assets/images/insurance-tick.gif";
import "./style.css";
import useStyles from "./styles";
import { EmailOutlined } from "@mui/icons-material";
import eventBus from "../../../core/eventBus";
import { get } from "lodash";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const SubscribedDialog = (props) => {
  const styles = useStyles();
  return (
    <Modal open={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
      <div className={styles.newsLetterSubmissionDialog}>
        <img src={tickGif} />
        <p style={{ textAlign: "center", fontSize: "16px", color: "#344054" }}>
          {get(props, "desc", "")}
        </p>
      </div>
    </Modal>
  );
};

const Newsletter = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const styles = useStyles();
  const { t } = useTranslation();

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmptyError(false);
    setInvalidEmailError(false);
  };

  const handleSubscribe = async () => {
    setIsEmptyError(false);
    setInvalidEmailError(false);

    if (!email) {
      setIsEmptyError(true);
    } else if (
      !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      setInvalidEmailError(true);
    } else {
      const data = await subscribeToNewsLetter({ email });
      console.log('data', data)
      if (data?.statusCode === 200) {
        setShowSuccessModal(true);
        setEmail("");
        setDesc("Thank You For Subscribing Our Newsletter!");
        // eventBus.emit(
        //   "open-dialog",
        //   <SubscribedDialog
        //     desc={"Thank You For Subscribing Our Newsletter!"}
        //     showSuccessModal={showSuccessModal}
        //     setShowSuccessModal={setShowSuccessModal}
        //   />
        // );
      } else {
        toast.error(data?.message)
        // setShowSuccessModal(true);
        // setDesc("You Have Already Subscribed!");
        // eventBus.emit(
        //   "open-dialog",
        //   <SubscribedDialog
        //     desc={"You Have Already Subscribed!"}
        //     showSuccessModal={showSuccessModal}
        //     setShowSuccessModal={setShowSuccessModal}
        //   />
        // );
        setEmail("");
      }
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSuccessModal]);

  return (
    <>
      <div className={styles.newsLetter}>
        <div className={styles.newsLetterHeader}>
          <p className={styles.newsLetterSubTitle}>
            {t("newsletter.getLatestNewsAndOffers")}
          </p>
          <h2 className={styles.newsLetterTitle}>
            {t("newsletter.subscribeToNewsletter")}
          </h2>
        </div>
        <form
          className={styles.newsLetterForm}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubscribe();
          }}
        >
          <div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div className={styles.newsLetterEmailFieldWrapper}>
                <EmailOutlined style={{ fontSize: "16px" }} />
                <input
                  name="email"
                  placeholder={t("newsletter.enterEmailAddress")}
                  className={styles.newsLetterEmailField}
                  value={email}
                  onChange={(e) => handleEmailChange(e.target.value)}
                />
              </div>
              <button className={styles.newsLetterSubmitButton} type="submit">
                {t("newsletter.subscribe")}
              </button>
            </div>
            {isEmptyError && (
              <div className={styles.invalid}>
                {t("newsletter.pleaseEnterEmailAddress")}
              </div>
            )}
            {invalidEmailError && (
              <div className={styles.invalid}>
                {t("newsletter.pleaseEnterValidEmailAddress")}
              </div>
            )}
          </div>
        </form>
      </div>
      {showSuccessModal && (
        <Modal
          open={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px 24px",
              backgroundColor: "#fff",
              marginTop: "18%",
              marginLeft: "34%",
              width: "32%",
              alignItems: "center",
              borderRadius: "10px",
            }}
          >
            <img src={tickGif} />
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "2px",
              }}
            >
              {desc}
            </p>
          </div>
        </Modal>
      )}
    </>
    // <Box
    //   my="5rem"
    //   sx={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     flexDirection: "column",
    //     background: {
    //       xs: "none",
    //       sm: `url(${bgImage}) 50% / cover no-repeat`,
    //       md: `url(${bgImage}) no-repeat`,
    //     },
    //     height: "50vh",
    //     width: "100vw",
    //     px: { xs: 2, md: 0 },
    //   }}
    // >
    //   <Typography fontSize="1rem" fontWeight={400} color="#344054">
    //     Get the latest news and offers
    //   </Typography>
    //   <Typography
    //     fontSize="2rem"
    //     fontWeight={700}
    //     lineHeight="42px"
    //     color="#344054"
    //     textAlign="center"
    //   >
    //     Subscribe to our newsletter
    //   </Typography>
    //   <Box
    //     my="2rem"
    //     sx={{
    //       display: "flex",
    //       flexDirection: { xs: "column", md: "row" },
    //       justifyContent: "center",
    //       alignItems: "center",
    //       gap: "1rem",
    //     }}
    //   >
    //     <Paper
    //       sx={{ minWidth: { xs: "80vw", md: "25vw" }, borderRadius: "14px" }}
    //       elevation={1}
    //     >
    //       <div className={isError ? "email-input-error" : "email-input"}></div>
    //       <OutlinedInput
    //         fullWidth
    //         sx={{
    //           borderRadius: "14px",
    //         }}
    //         type="text"
    //         pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}"
    //         placeholder="Enter Email Address"
    //         id="outlined-adornment-amount"
    //         startAdornment={
    //           <InputAdornment position="start">
    //             <MailOutlineIcon />
    //           </InputAdornment>
    //         }
    //         value={email}
    //         onChange={(e) => handleEmailChange(e.target.value)}
    //       />
    //     </Paper>
    //     <Box
    //       onClick={() => {
    //         handleSubscribe;
    //       }}
    //       sx={{
    //         backgroundColor: "primary.main",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         px: "1.5rem",
    //         py: "1rem",
    //         borderRadius: "14px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <Typography fontWeight={400} color="#fff" fontSize="0.87rem">
    //         Subscribe
    //       </Typography>
    //     </Box>
    //   </Box>

    // </Box>
  );
};

export default Newsletter;
