import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { map } from "lodash";
import { Chips } from "../../components/Chips";
import {
  updateHotelLocalFilters,
  updatePriceSorting,
} from "../../../utils/slices/localFiltersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const quickFilters = [
  {
    title: "1 star",
    value: "1",
    selected: false,
  },
  {
    title: "2 star",
    value: "2",
    selected: false,
  },
  {
    title: "3 star",
    value: "3",
    selected: false,
  },
];
export const HotelQuickFiltersView = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [filters, setFilters] = useState(quickFilters);
  const dispatch = useDispatch();
  const selectedRange = useSelector((state) => state.localFilters.priceFilter);

  const handleChipClick = (value) => {
    const updatedFilters = filters?.map((filter) => {
      if (filter?.value === value) {
        return { ...filter, selected: !filter.selected };
      }
      return filter;
    });

    setFilters(updatedFilters);
    dispatch(updateHotelLocalFilters(value));
  };

  useEffect(() => {
    dispatch(
      updatePriceSorting({
        value: "",
      })
    );
  }, []);

  return (
    <>
      
      <div className={styles.hotelSearchQuickFiltersSortTab}>
        <p
          className={
            selectedRange.value == "desc"
              ? styles.hotelSearchQuickFiltersSortTabOptionSelected
              : styles.hotelSearchQuickFiltersSortTabOption
          }
          onClick={() =>
            dispatch(
              updatePriceSorting({
                value: "desc",
              })
            )
          }
        >
          {t("hotelSearchResult.priceHighestFirst")}
        </p>
        <p
          className={
            selectedRange.value == "asc"
              ? styles.hotelSearchQuickFiltersSortTabOptionSelected
              : styles.hotelSearchQuickFiltersSortTabOption
          }
          onClick={() =>
            dispatch(
              updatePriceSorting({
                value: "asc",
              })
            )
          }
        >
          {t("hotelSearchResult.priceLowestFirst")}
        </p>

        <p
          className={
            selectedRange.value == "starRatingHighest"
              ? styles.hotelSearchQuickFiltersSortTabOptionSelected
              : styles.hotelSearchQuickFiltersSortTabOption
          }
          onClick={() =>
            dispatch(
              updatePriceSorting({
                value: "starRatingHighest",
              })
            )
          }
        >
          {t("hotelSearchResult.starRatingHighestFirst")}
        </p>

        <p
          className={
            selectedRange.value == "starRatingLowest"
              ? styles.hotelSearchQuickFiltersSortTabOptionSelected
              : styles.hotelSearchQuickFiltersSortTabOption
          }
          onClick={() =>
            dispatch(
              updatePriceSorting({
                value: "starRatingLowest",
              })
            )
          }
        >
          {t("hotelSearchResult.starRatingLowestFirst")}
        </p>
      </div>
    </>
  );
};
