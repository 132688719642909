import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  hotelDetailsTitle: {
    fontSize: "24px",
    fontWeight: 700,
  },
}));

export default useStyles;
