import { createSlice } from "@reduxjs/toolkit";

const getHotelDetailsFromLocalStorage = () => {
  const hotel = JSON.parse(window.localStorage.getItem("hotelDetails"));
  return hotel;
};

const hotel = getHotelDetailsFromLocalStorage();

const searchHotelSlice = createSlice({
  name: "searchHotel",
  initialState: {
    bookingType: "Hotel",
    hotelCity: hotel ? hotel?.hotelCity : "",
    hotelCheckInDate: hotel ? hotel?.hotelCheckInDate : "",
    hotelCheckOutDate: hotel ? hotel?.hotelCheckOutDate : "",
    searchedHotels: [],
    hotelNotFound: false,
  },
  reducers: {
    updateHotelCity: (state, action) => {
      state.hotelCity = action.payload;
    },
    updateHotelDates: (state, action) => {
      const { checkInDate, checkOutDate } = action.payload;
      state.hotelCheckInDate = checkInDate;
      state.hotelCheckOutDate = checkOutDate;
    },
    addSearchedHotels: (state, action) => {
      state.searchedHotels = action.payload;
    },
    updateHotelNotFound: (state, action) => {
      state.hotelNotFound = action.payload;
    },
  },
});

export const {
  updateHotelCity,
  updateHotelDates,
  addSearchedHotels,
  updateHotelNotFound,
} = searchHotelSlice.actions;

export default searchHotelSlice.reducer;
