import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bookFlightMobile: {
    display: "flex",
    padding: "12px 16px",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#fff",
    margin: "0px 25px",
    // width: "100%",
  },
  flightDetailsCard: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 4,
    width: "100%",
  },

  fairDetailscard: {
    display: "inline-flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  airLinesDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "0.25"
  },
  flightFromDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  flightTime: {
    display: "flex",
    height: "32px",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  flightToDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },

  baggageOption: {
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },

  fairText: {
    display: "flex",
    width: "63px",
    padding: "4px 0px",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "16px",
  },

  airLinesName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },

  airlineNameLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },

  airLineCode: {
    color: " #667085",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  departureDate: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    display: "flex",
  },

  flightFrom: {
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  flightDuration: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
  },
  durationTime: {
    color: "var(--heading, #344054)",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    marginLeft: "4px",
  },
  flightTravelType: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  flightTravelTypeText: {
    color: "#1DAC08",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },

  baggageTextLayout: {
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },
  baggageText: {
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
  },

  baggageOptionLayout: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },

  fairTextLayout: {
    color: "var(--heading, #344054)",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
    display: "flex",
  },

  buttonLayout: {
    display: "flex",
    padding: "10px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    background: "#1b1d52",
    border: "none",
    color: "#fff",
    width: "100%",
    alignSelf: "center",
  },
}));

export default useStyles;
