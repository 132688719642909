import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography } from "@mui/material";
import AddSvg from "../../assets/images/faq-add.svg";
import MinusSvg from "../../assets/images/faq-minus.svg";
import ArrowRightSvg from "../../assets/images/arrow-right.svg";
import { getPrivacyPolicy } from "../../modules/home";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { Rings } from "react-loader-spinner";
import "./index.css";
import handleUnauthorisedLogout from "../../modules/unauthorised";
import { setUserInfo } from "../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
const customUseStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
}));

const customStyles = {
  borderRadius: "12px",
  padding: "4px 2px",
  background: "#FFF",
  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#525A6A",
  fontFamily: "Lato, sans-serif",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
};
let globalRerenderCount = 0;

const PrivacyPolicy = () => {
  const classes = customUseStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const styles = useStyles();
  const [policies, setPolicies] = useState([]);
  const {t}  = useTranslation()

  const policyMap = {};

  if (policies.length != 0) {
    policies.forEach((policy) => {
      const policyCategory = policy?.categoryName;
      if (policyMap[policyCategory]) {
        policyMap[policyCategory].push(policy);
      } else {
        policyMap[policyCategory] = [policy];
      }
    });
  }
  const policyCategories = Object.keys(policyMap);
  const initialCategoryToShow = policyCategories[0];

  const [categoryTab, setCategoryTab] = useState(policyCategories[0]);
  if (policies.length != 0 && globalRerenderCount == 1) {
    setCategoryTab(initialCategoryToShow);
  }

  const accordians = [];
  if (policies) {
    for (let i = 0; i < policies.length; i++) {
      if (i == 0) accordians.push(true);
      else accordians.push(false);
    }
  }

  const [showDetails, setShowDetails] = useState(accordians);

  const handleClick = (index) => {
    showDetails[index] = !showDetails[index];
    setShowDetails([...showDetails]);
  };

  useEffect(() => {
    handleGetPrivacyPolicy();
  }, [showDetails, setShowDetails]);

  const handleGetPrivacyPolicy = async () => {
    const data = await getPrivacyPolicy(user?.token);

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    setPolicies(data?.policies);
  };
  globalRerenderCount++;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#FAFAFA",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          gap: "8px",
          width: "80%",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#667085",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          {/* Home */}
          {t("navigationDrawer.home")}
        </Typography>
        <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
        <Typography
          sx={{
            color: "#4037acd6",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            cursor: "pointer",
          }}
        >
          {/* Privacy-Policy */}
          {t("navigationDrawer.privacyPolicy")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "row",
          flexDirection: "column",
          background: "#FAFAFA",
          width: "80%",
        }}
      >
        {policyCategories.length ? (
          <div style={{ paddingBottom: "50px" }}>
            <h3 style={{}} className="mb-3 faqHeading ">
              {/* Privacy Policy */}
              {t("navigationDrawer.privacyPolicy")}
            </h3>
            <div className="u-tabs-1">
              <ul
                className="u-unstyled"
                role="tablist"
                style={{
                  display: "flex",
                  flexGrow: "1",
                  listStyle: "none",
                  margin: "20px 0",
                  flexWrap: "wrap",
                  gap: "6px",
                }}
              >
                {policyCategories.map((policyCategory) => {
                  return (
                    <li
                      style={{
                        // flex: 1,
                        textAlign: "center",
                        background:
                          policyCategory == categoryTab ? "#1b1d52" : "grey",
                      }}
                      className={styles.policyCategories}
                      key={policyCategory}
                      onClick={() => setCategoryTab(policyCategory)}
                    >
                      {policyCategory}
                    </li>
                  );
                })}
              </ul>
              <div className="u-tab-content">
                {policyCategories.map((policyCategory) => {
                  return policyMap[policyCategory].map((policy, i) => {
                    const { _id, privyPolicy } = policy;

                    return (
                      <div
                        key={_id}
                        style={{
                          display:
                            policyCategory == categoryTab ? "flex" : "none",
                          flexDirection: "column",
                          borderBottom: "1px solid #E5E5E5",
                          background: "#FFF",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "inline-flex",
                            gap: "16px",
                            padding: "16px 16px 16px 16px",
                            alignItems: "center",
                            cursor: "pointer",
                            margin: "10px 0",
                          }}
                        >
                          <div className={styles.policies}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(privyPolicy || ""),
                              }}
                            />
                          </div>
                        </Box>
                      </div>
                    );
                  });
                })}
                <div className="u-tab-active">
                  <div className="u-container-layout-1">
                    <div className="u-accordion u-spacing-10 u-accordion-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <Rings height={80} width={80} color="#1B1D52" />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
