import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "24px 32px",
    backgroundColor: "#fff",
    marginTop: "13%",
    marginLeft: "35%",
    width: "28%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  mainContainerArabic: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "24px 32px",
    backgroundColor: "#fff",
    marginTop: "13%",
    marginRight: "35%",
    width: "28%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  logoutAndAreYouSureTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
  },
  logoutText: {
    fontFamily: "lato",
    fontSize: "18px",
    fontWeight: "700",
    color: "#1b1d52",
  },
  areYouSureText: {
    fontFamily: "lato",
    fontSize: "15px",
    fontWeight: "400",
    color: "#525A6A",
  },
  confirmContainer: {
    borderRadius: "10px",
    background: "#1b1d52",
    textAlign: "center",
    padding: "16px 16px",
    width: "100%",
    cursor: "pointer",
  },
  confirmText: {
    fontFamily: "lato",
    fontSize: "14px",
    fontWeight: "400",
    color: "#FFF",
  },
  cancelText: {
    fontFamily: "lato",
    fontSize: "14px",
    fontWeight: "400",
    color: "#1b1d52",
    cursor: "pointer",
  },
}));

export default useStyles;
