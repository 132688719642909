import React from "react";
import Grid from "@mui/material/Grid";
import "./styles.css";
import { get } from "lodash";
import useStyles from "./styles";

const HotelInfo = (props) => {
  const detail = get(props, "info", {});
  const styles = useStyles();
  return (
    <div
      style={{
        borderRadius: "5px",
        padding: "24px 16px",
        backgroundColor: "white",
      }}
    >
      <div className="rating-row">
        <div className="rating-row-item">{get(detail, "Rating", 0)}</div>
        <div className="rating-row-item">
          <img src="/icons/star.png" className="star-rating-icon" />
        </div>
        {/* <div className="rating-row-item">
            Resort . {detail.address.city}, {detail.address.state}
          </div> */}
      </div>
      <h1 className={styles.hotelDetailsTitle}>
        {get(detail, "HotelName", "")}
      </h1>
      <p>{get(detail, "Address", "")}</p>
      <div className="rating-row">
        <div className="rating-row-item">
          {get(detail, "StarRating", "")}/10
        </div>
      </div>
      <Grid container>
        <Grid item xs={4} style={{ margin: "10px" }}>
          <img src="/icons/location.png" className="marker-icon" />
        </Grid>
      </Grid>
    </div>
  );
};

export default HotelInfo;
