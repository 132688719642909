import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import {
  formatParsedDate,
  formatParsedDay,
} from "../../../../../../utils/helpers";
import { ReactSVG } from "react-svg";
import PlaneSvg from "../../../../../../assets/images/my-booking-plane.svg";
import UserSvg from "../../../../../../assets/images/user.svg";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import {
  flightCancellation,
  flightReissue,
} from "../../../../../../modules/flight";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import CancellationDetailsInfo from "./cancellation-details";
import { toast } from "react-toastify";
import CancellationSuccess from "./cancellation-status";
import ReissueSuccess from "./reissue-status";
import { Rings } from "react-loader-spinner";
import LoadingBackdrop from "../../../../../flight-module/cards/loading-backdrop";
import { useTranslation } from "react-i18next";
const FlightBookingContainer = ({ booking }) => {
  if (!booking.flightSearch[0]) {
    return;
  }

  const { adultCount, childCount, flightSegments, infantCount, journeyType } =
    booking.flightSearch[0];
  const styles = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { token } = useSelector((state) => state.user);
  const [validBooking, setValidBooking] = useState(true);
  const [bookingIds, setBookingIds] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReissueModal, setShowReissueModal] = useState(false);
  const [cancellationDetails, setCancellationDetails] = useState();
  const [showCancellationDetailsModal, setShowCancellationDetailsModal] =
    useState(false);
  const [cancelledSuccessModal, setCancelledSuccessModal] = useState(false);
  const [reissueSuccessModal, setReissueSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    if (booking?.tickets.length === 2) {
      if (
        booking.tickets[0].status !== "CONFIRMED" ||
        booking.tickets[1].status !== "CONFIRMED" ||
        booking.tickets[0].ticket.Results.Errors == 400 ||
        booking.tickets[1].ticket.Results.Errors == 400
      ) {
        setValidBooking(false);
      } else if (
        booking.tickets[0].status === "CONFIRMED" &&
        booking.tickets[1].status === "CONFIRMED"
      ) {
        const {
          Itinerary: { BookingId: bookingId1 },
          Itinerary: { Segments },
        } = booking.tickets[0].ticket.Results;
        const { BookingId: bookingId2 } = booking.tickets[1].ticket.Results;
        const { DepartureTime } = Segments[0];

        const targetDate = new Date(DepartureTime);
        const currentDate = new Date();
        currentDate < targetDate
          ? setShowCancelButton(true)
          : setShowCancelButton(false);
        setBookingIds(`${bookingId1}, ${bookingId2}`);
      }
    } else if (booking?.tickets.length === 1) {
      if (
        booking.tickets[0].status !== "CONFIRMED" ||
        booking.tickets[0].ticket.Results.Errors == 400
      ) {
        setValidBooking(false);
      } else if (booking.tickets[0].status === "CONFIRMED") {
        const {
          Itinerary: { BookingId: bookingId1 },
          Itinerary: { Segments },
        } = booking.tickets[0].ticket.Results;
        const { DepartureTime } = Segments[0];
        const targetDate = new Date(DepartureTime);
        const currentDate = new Date();
        currentDate < targetDate
          ? setShowCancelButton(true)
          : setShowCancelButton(false);
        setBookingIds(`${bookingId1}`);
      }
    }
  }, [booking]);

  const handleFlightCancellation = async () => {
    const payload = {
      booking: booking._id,
    };
    setShowCancelModal(false);
    setLoading(true);
    const res = await flightCancellation(payload, token);
    if (res && res?.cancelStatus == "CANCELLATION_REQUESTED") {
      setCancelledSuccessModal(true);
      setLoading(false);
    } else {
      toast.error(
        "Something went wrong while cancelling your flight, please try again!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }
  };

  const handleFlightReissue = async () => {
    const payload = {
      booking: booking._id,
    };
    setShowReissueModal(false);
    setLoading(true);
    const res = await flightReissue({ token, payload });

    if (res && res?.reissueStatus == "REISSUE_REQUESTED") {
      setReissueSuccessModal(true);
      setLoading(false);
    } else {
      toast.error(
        "Something went wrong while reissuing your flight, please try again!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }
  };

  const handleGetBookingById = async () => {
    navigate(`/flight/booking/${booking._id}`, {
      state: {
        bookingIds: bookingIds,
      },
    });
  };

  return (
    <>
      {validBooking && (
        <div className={styles.bookingListContainer}>
          <div className={styles.content1}>
            <div className={styles.content1Left}>
              <div className={styles.segmentContainer2}>
                {flightSegments &&
                  flightSegments.map((segment, i) => {
                    const { origin, destination } = segment;
                    return (
                      <div className={styles.heading}>
                        {origin}
                        <ReactSVG src={PlaneSvg} />
                        {destination} {i !== flightSegments.length - 1 && ", "}
                      </div>
                    );
                  })}
              </div>
              <div className={styles.subheading}>
                <p>
                  {showCancelButton &&
                    (booking?.status === "BOOKED" ||
                      booking?.status === "CANCELLATION_RETAINED" ||
                      booking?.status === "REISSUE_RETAINED" ||
                      booking?.status === "REISSUED") &&
                    // "Upcoming"
                    t("myProfile.upcoming")
                  }
                  {!showCancelButton &&
                    (booking?.status === "BOOKED" ||
                      booking?.status === "CANCELLATION_RETAINED" ||
                      booking?.status === "REISSUE_RETAINED" ||
                      booking?.status === "REISSUED") &&
                    // "Completed"                     
                    t("myProfile.completed")
                  }
                  {booking?.status == "CANCELLATION_REQUESTED" &&
                    // "Cancellation requested"
                    t("myProfile.cancellationRequested")

                  }
                  {booking?.status == "CANCELLATION_INPROGRESS" &&
                    // "Cancellation in progress"
                    t("myProfile.cancellationInProgress")

                  }
                  {booking?.status == "REISSUE_REQUESTED" &&
                    // "Re-issue requested"
                    t("myProfile.reissueRequested")
                  }
                  {booking?.status == "REISSUE_INPROGRESS" &&
                    // "Re-issue in progress"
                    t("myProfile.reissueInProgress")
                  }
                  {booking?.status == "CANCELLED" &&
                    // "Cancelled"
                    t("myProfile.cancelled")

                  }
                </p>
                <p>
                  ·{" "}
                  {journeyType == 1
                    ?
                    // "One Way"
                    t("myProfile.oneWay")
                    : journeyType == 3
                      ?
                      // "Multi City"
                      t("myProfile.multiCity")
                      :
                      // "Round Trip"
                      t("myProfile.roundTrip")
                  }
                </p>
                <p>·
                  {/* Booking Id  */} {t("myProfile.bookingId")}
                  -
                  {bookingIds}</p>
              </div>
            </div>
            <div className={styles.content1Right}>
              <p className={styles.viewStatus} onClick={handleGetBookingById}>
                {/* View Booking Details */}
                {t("myProfile.viewBookingDetails")}
              </p>
            </div>
          </div>
          <Divider />
          <div className={styles.content2}>
            <div className={styles.content21}>
              <p className={styles.lightText}>
                {/* From */}
                {t("myProfile.from")}

              </p>
              <p className={styles.lightText}>
                {formatParsedDate(flightSegments[0].preferredDepartureTime)}
              </p>
              <p className={styles.boldText}>
                {formatParsedDay(flightSegments[0].preferredDepartureTime)}
              </p>
            </div>
            <div className={styles.content21}>
              <p className={styles.lightText}>
                {/* To */}
                {t("myProfile.to")}

              </p>
              <p className={styles.lightText}>
                {formatParsedDate(
                  flightSegments[flightSegments.length - 1]
                    .preferredDepartureTime
                )}
              </p>
              <p className={styles.boldText}>
                <p className={styles.boldText}>
                  {formatParsedDay(
                    flightSegments[flightSegments.length - 1]
                      .preferredDepartureTime
                  )}
                </p>
              </p>
            </div>
            <div className={styles.content21}>
              <p className={styles.inlineProperty}></p>
              <p className={styles.inlineProperty}>
                <ReactSVG src={UserSvg} />
                <span className={styles.boldText}>
                  {adultCount} {adultCount > 1
                    ?
                    // "Adults" 
                    t("myProfile.adults")
                    :
                    // "Adult"
                    t("myProfile.adult")
                  }
                  {childCount > 0 && (
                    <>
                      ,{childCount} {childCount > 1
                        ?
                        // "Children" 
                        t("myProfile.children")
                        :
                        // "Child"
                        t("myProfile.child")
                      }
                    </>
                  )}
                  {infantCount > 0 && <>,{infantCount}
                    {/* Infant */} {t("myProfile.infant")}
                  </>}
                </span>
              </p>
            </div>
            <div className={isMobile ? styles.content22 : styles.content21}>
              <p></p>
              {showCancelButton &&
                (booking?.status === "BOOKED" ||
                  booking?.status === "CANCELLATION_RETAINED" ||
                  booking?.status === "REISSUE_RETAINED" ||
                  booking?.status === "REISSUED") && (
                  <p
                    className={styles.cancel}
                    onClick={() => setShowCancelModal(true)}
                    style={{ minWidth: "110px", minHeight: "30px" }}
                  >
                    {/* Cancel */}
                    {t("myProfile.cancel")}
                  </p>
                )}
              {showCancelButton &&
                (booking?.status === "BOOKED" ||
                  booking?.status === "CANCELLATION_RETAINED" ||
                  booking?.status === "REISSUE_RETAINED" ||
                  booking?.status === "REISSUED") && (
                  <p
                    className={styles.reissue}
                    onClick={() => setShowReissueModal(true)}
                    style={{ minWidth: "110px", minHeight: "30px" }}
                  >
                    {/* Re-issue */}
                    {t("myProfile.reissue")}
                  </p>
                )}
              {!showCancelButton &&
                (booking?.status === "BOOKED" ||
                  booking?.status === "CANCELLATION_RETAINED" ||
                  booking?.status === "REISSUE_RETAINED" ||
                  booking?.status === "REISSUED") && (
                  <p
                    className={styles.completed}
                    style={{ minWidth: "110px", minHeight: "30px" }}
                  >
                    {/* Completed */}
                    {t("myProfile.completed")}
                  </p>
                )}
              {booking?.status == "CANCELLED" && (
                <p
                  className={styles.cancelled}
                  style={{ minWidth: "110px", minHeight: "30px" }}
                >
                  {/* Cancelled */}
                  {t("myProfile.cancelled")}

                </p>
              )}
              {booking?.status != "CANCELLED" && (
                <p
                  className={styles.status}
                  style={{ minWidth: "110px", minHeight: "30px" }}
                >
                  {/* Booking Confirm */}
                  {t("myProfile.bookingConfirmed")}

                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.mainContainer}>
          <DialogTitle id="responsive-dialog-title" className={styles.title}>
            <p className={styles.title}>
              {/* Are you sure you want to cancel flight? */}
              {t("myProfile.confirmCancelFlight")}
            </p>
          </DialogTitle>
          <DialogActions style={{
            display: 'flex',
            gap: '10px'
          }}>
            <div
              className={styles.goBackButton}
              onClick={handleFlightCancellation}
            >
              {/* Yes */}
              {t("myProfile.yes")}

            </div>
            <div
              className={styles.successButton}
              onClick={() => setShowCancelModal(false)}
            >
              {/* No */}
              {t("myProfile.no")}

            </div>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={showReissueModal}
        onClose={() => setShowReissueModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.mainContainer}>
          <DialogTitle id="responsive-dialog-title" className={styles.title}>
            <p className={styles.title}>
              {/* Are you sure you want to re-issue flight? */}
              {t("myProfile.confirmReissueFlight")}

            </p>
          </DialogTitle>
          <DialogActions style={{
            display: 'flex',
            gap: '10px'
          }}>
            <div className={styles.goBackButton} onClick={handleFlightReissue}>
              {/* Yes */}
              {t("myProfile.yes")}

            </div>
            <div
              className={styles.successButton}
              onClick={() => setShowReissueModal(false)}
            >
              {/* No */}
              {t("myProfile.no")}
            </div>
          </DialogActions>
        </div>
      </Dialog>
      {/* {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        showCancellationDetailsModal && (
          <CancellationDetailsInfo
            cancellationDetails={cancellationDetails}
            showCancellationDetailsModal={showCancellationDetailsModal}
            setShowCancellationDetailsModal={setShowCancellationDetailsModal}
            cancelledSuccessModal={cancelledSuccessModal}
            setCancelledSuccessModal={setCancelledSuccessModal}
            booking={booking._id}
          />
        )
      )} */}
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        cancelledSuccessModal && (
          <CancellationSuccess
            cancelledSuccessModal={cancelledSuccessModal}
            setCancelledSuccessModal={setCancelledSuccessModal}
          />
        )
      )}
      {reissueSuccessModal && (
        <ReissueSuccess
          reissueSuccessModal={reissueSuccessModal}
          setReissueSuccessModal={setReissueSuccessModal}
        />
      )}
    </>
  );
};
export default FlightBookingContainer;
