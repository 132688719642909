import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    width: "100%",
    padding: "40px 150px",

    [theme.breakpoints.down("lg")]: {
      padding: "20px 50px",
      maxWidth: "100vw",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
      maxWidth: "100vw",
    },
  },
  confirmationImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    height: "230px",

    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
  },
  confirmationIcon: {
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      width: "35px",
    },
  },
  confirmationText: {
    fontFamily: "Lato, sans-serif",
    fontSize: "36px",
    fontWeight: 600,
    color: "#fff",

    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 500,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  tripInfoContainer: {
    display: "inline-flex",
    padding: "16px",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
  },
  flightInfoContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  fligtInfo: {
    display: "inline-flex",
    gap: "4px",
  },
  flightInfoContent1: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "85%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  city: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  journeyIcon: {
    marginTop: "6px",
  },
  subInfo: {
    color: " #344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  statusButton: {
    borderRadius: "24px",
    background: "#F1FCEB",
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusText: {
    color: "#1DAC08",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  pnrHeading: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  pnrText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  bookingConfirmationBottom: {
    height: "62px",
    marginTop: "-22px",
  },
  mainContainerError: {
    padding: "20px 20px",
    boxRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "150px 0px",
  },
  titleContainer: {
    display: "inline-flex",
    gap: "10px",
    alignItems: "center",
    border: "1px solid #DDD",
    padding: "12px 20px",
    boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.14)",
  },
  titleIcon: {
    height: "40px",
    width: "40px",
  },
  title: {
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "700",
    color: "#667085",
  },
  actionButtonComponent: {
    display: "inline-flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginBottom: "40px",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  actionButton: {
    padding: "12px 16px",
    borderRadius: "10px",
    border: "1px solid #4037acd6",
    display: "inline-flex",
    gap: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  actionButtonText: {
    color: "#4037acd6",
    fontSize: "16px",
    fontWeight: "600",
  },
  headerLinkContainer: {
    display: "inline-flex",
    gap: "8px",
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    cursor: "pointer",
  },
}));

export default useStyles;
