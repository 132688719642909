import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import {
  TimeFormat,
  addDurationStrings,
  calculateFlightDuration,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDuration,
  flightDurationInHoursAndMinutes,
  timeString,
} from "../../../../../../utils/helpers";
import { Modal } from "@mui/material";
import {
  flightClassData,
  flightClassDataArabic,
} from "../../../../../../config";
import CloseSvg from "../../../../../../assets/images/x-close.svg";
import PlaceholderImage from "../../../../../../assets/images/logo.png";
import CheckSvg from "../../../../../../assets/images/baggage-check.svg";
import DashedSvg from "../../../../../../assets/images/flight-result-dashed.svg";
import FlightDetailsSvg from "../../../../../../assets/images/flight-details.svg";
import EastIcon from "@mui/icons-material/East";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./style";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const FlightDetails = ({
  showFlightDetailsModal,
  setShowFlightDetailsModal,
  flight,
  segmentIndex = 0,
  markup,
}) => {
  let {
    Fare: { TotalFare, BaseFare, Tax, OtherCharges, VATAmount },
    Segments,
    NonRefundable,
  } = flight;
  // BaseFare = Math.ceil(TotalFare) - Math.ceil(Tax) + Math.ceil(VATAmount);
  const {
    Duration: departureDuration,
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime: startDate,
    },
    DepartureTime: startDate,
    Destination: { ArrTime: layoverDepTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[segmentIndex][0];
  const {
    Duration: arrivalDuration,
    Origin: { DepTime: layoverArrTime },
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
    },
    GroundTime,
    AccumulatedDuration: lastAccumulatedDuration,
  } = Segments[segmentIndex][Segments[segmentIndex]?.length - 1];

  const [showPriceAccordian, setShowPriceAccordian] = useState(false);
  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format("ddd, DD MMM YY");
    return formattedDate;
  };

  const classes = useStyles();
  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  const getLocale = useSelector((state) => state.locale);
  const { t } = useTranslation();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  return (
    <Modal
      open={showFlightDetailsModal}
      onClose={() => setShowFlightDetailsModal(false)}
      className={
        localStorage.getItem("language") == "AR"
          ? classes.modalArabic
          : classes.modal
      }
    >
      <div className={classes.mainContainer}>
        <div
          className={classes.secondaryContainer}
          style={{ padding: "16px 18px" }}
        >
          <div className={classes.flightDetailsAndRefundableDeptTimeContainer}>
            <div className={classes.flightDetailsContainer}>
              <p className={classes.flightDetailsText}>
                {t("flightSearch.flightDetails")}
              </p>
              <ReactSVG
                src={CloseSvg}
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                onClick={() => setShowFlightDetailsModal(false)}
              />
            </div>
            <div className={classes.originDestAndRefundableDeptTimeContainer}>
              <div
                className={classes.originDestinationAndTotalDurationContainer}
              >
                <div className={classes.originDestinationContainer}>
                  <p className={classes.origin}>{origin}</p>
                  <EastIcon sx={{ width: "16px", height: "16px" }} />
                  <p className={classes.destination}>{destination}</p>
                </div>
                <div className={classes.totalDurationContainer}>
                  <p className={classes.totalDurationText}>
                    {t("flightSearch.totalDuration")}
                  </p>
                  <p className={classes.totalDuration}>
                    {/* {firstAccumulatedDuration > 0
                      ? flightDurationInHoursAndMinutes(
                          firstAccumulatedDuration
                        )
                      : lastAccumulatedDuration > 0
                      ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                      : flightDurationInHoursAndMinutes(departureDuration)} */}
                    {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
                    {/* {TimeFormat(departureDuration)} */}
                    {convertAccumulatedTime(lastAccumulatedDuration)}
                  </p>
                </div>
              </div>
              <div className={classes.refundableDeptTimeContainer}>
                <p className={classes.refundableNonRefundableText}>
                  {!NonRefundable
                    ? t("flightSearch.partiallyRefundable")
                    : t("flightSearch.nonRefundable")}
                </p>
                <p className={classes.deptTime}>
                  {startDate && formatDate(startDate)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.emptyContainer}></div>
        {Segments[segmentIndex]?.length &&
          Segments[segmentIndex].map((flightDetail, i) => {
            const {
              CabinClass,
              CabinBaggage,
              IncludedBaggage,
              Baggage,
              ArrivalTime,
              DepartureTime,
              AirlineDetails: { AirlineName, FlightNumber, AirlineCode },
              Destination: {
                AirportName: DestAirportName,
                CityName: DestCityName,
                CountryName: DestCountryName,
                Terminal: DestTerminal,
              },
              Origin: {
                AirportName: OriginAirportName,
                CityName: OriginCityName,
                CountryName: OriginCountryName,
                Terminal: OriginTerminal,

                // DepTime,
              },
              Duration,
            } = flightDetail;
            return (
              <div key={i}>
                {i !== 0 && (
                  <div className={classes.flightDetailContainer}>
                    {/* <ReactSVG src={DashedSvg} /> */}
                    <div className={classes.changeOfPlansAndLayoverContainer}>
                      <p className={classes.changeOfPlansText}>
                        {t("flightSearch.changeOfPlanes")}
                      </p>
                      <p className={classes.layover}>
                        {/* {flightDurationInHoursAndMinutes(GroundTime)} Layover in */}
                        {TimeFormat(GroundTime)} {t("flightSearch.layoverIn")}{" "}
                        {Segments[segmentIndex]?.[i - 1]?.Destination?.CityName}
                      </p>
                    </div>
                    {/* <ReactSVG src={DashedSvg} /> */}
                  </div>
                )}
                <div className={classes.flightFullDetail}>
                  <div
                    className={
                      classes.deptTimeFlighDurationArrivalTimeContainer
                    }
                  >
                    <div className={classes.deptTimeContainer}>
                      <p className={classes.deptTimeText}>
                        {" "}
                        {DepartureTime && timeString(DepartureTime)}
                      </p>
                      <p className={classes.deptTime2}>
                        {" "}
                        {DepartureTime && formatDate(DepartureTime)}
                      </p>
                    </div>
                    {/* {(i === 0 && Duration > 0) ||
                    (i !== 0 && Duration !== lastAccumulatedDuration) ? (
                      <div
                        className={classes.flightDurationContainer}
                        style={{ height: "41px" }}
                      >
                        <div className={classes.flightDuration}>{Duration}</div>
                      </div>
                    ) : (
                      <div style={{ height: "41px" }}></div>
                    )} */}
                    <div className={classes.flightDuration}>
                      {TimeFormat(Duration)}
                    </div>
                    <div className={classes.arrivalTimeContainer}>
                      <p className={classes.arrivalTimeText}>
                        {" "}
                        {ArrivalTime && timeString(ArrivalTime)}
                      </p>
                      <p className={classes.arrivalTime}>
                        {" "}
                        {ArrivalTime && formatDate(ArrivalTime)}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <ReactSVG src={FlightDetailsSvg} />
                  </div>
                  <div
                    className={classes.airportArrivalandDestAndTerminalDetails}
                  >
                    <div className={classes.airportTerminalContainer}>
                      <p className={classes.originAirportName}>
                        {OriginAirportName}
                      </p>
                      {OriginTerminal && (
                        <p className={classes.originalTerminal}>
                          {" "}
                          Terminal {OriginTerminal}
                        </p>
                      )}
                      <p className={classes.originCityName}>
                        {OriginCityName}, {OriginCountryName}
                      </p>
                    </div>
                    <div className={classes.airLineDetail}>
                      {localStorage.getItem("language") != "AR" && (
                        <img
                          src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
                          onError={(e) => {
                            e.target.src = PlaceholderImage;
                          }}
                          style={{ width: "42px", height: "36px" }}
                        />
                      )}
                      <div
                        className={classes.airLineNamesAndFlighClassContainer}
                      >
                        <div className={classes.airLineNameAndCodeContainer}>
                          <p className={classes.airLineName}>{AirlineName}</p>
                          <div className={classes.emptyContainer2}></div>
                          <p className={classes.airLineCode}>
                            {AirlineCode}-{FlightNumber}
                          </p>
                        </div>
                        <p className={classes.flightClassAndCabinClass}>
                          {/* {localStorage.getItem("language") == "AR"
                            ? flightClassDataArabic[CabinClass]
                            : flightClassData[CabinClass]} */}
                          {CabinClass} {t("flightSearch.class")}
                        </p>
                      </div>
                      {localStorage.getItem("language") == "AR" && (
                        <img
                          src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
                          onError={(e) => {
                            e.target.src = PlaceholderImage;
                          }}
                          style={{ width: "42px", height: "36px" }}
                        />
                      )}
                    </div>
                    <div
                      className={classes.destAirPortNameAndTerminalContainer}
                    >
                      <p className={classes.destAirPortName}>
                        {" "}
                        {DestAirportName}{" "}
                      </p>
                      {DestTerminal && (
                        <p className={classes.destTerminal}>
                          {/* Terminal  */}
                          {t("flightSearch.terminal")}
                          {DestTerminal}
                        </p>
                      )}
                      <p className={classes.destCityName}>
                        {" "}
                        {DestCityName}, {DestCountryName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.cabinBaggageOuterContainer}>
                  <div className={classes.checkInBaggageAdultContainer}>
                    <div className={classes.checkSvgContainer}>
                      <ReactSVG src={CheckSvg} />
                    </div>
                    <div className={classes.checkInBaggageAndAdult}>
                      <p className={classes.checkInBaggage}>
                        {t("flightSearch.checkInBaggage")}
                      </p>
                      <p className={classes.adult}>
                        {IncludedBaggage}/ {t("flightSearch.adult")}
                      </p>
                    </div>
                  </div>
                  {CabinBaggage && (
                    <div className={classes.cabinBaggageContainer}>
                      <div className={classes.cabinBaggeCheckSvgContainer}>
                        <ReactSVG src={CheckSvg} />
                      </div>
                      <div className={classes.cabinBaggeAndAdultContainer}>
                        <p className={classes.cabinBaggage}>
                          {t("flightSearch.cabinBaggage")}
                        </p>
                        <p className={classes.cabinBaggageAdult}>
                          {CabinBaggage} / {t("flightSearch.adult")}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        {showPriceAccordian && (
          <div className={classes.fullFairDetailsContainer}>
            <div className={classes.FairDetailsContainer}>
              <p className={classes.fareDetails}>
                {t("flightSearch.fareDetails")}
              </p>
              <div className={classes.baseFareAndTaxesFessContainer}>
                <div className={classes.baseFareContainer}>
                  <p className={classes.baseFareText}>
                    {t("flightSearch.baseFare")}
                  </p>
                  <p className={classes.baseFare}>
                    {t("flightSearch.currencySymbol")}{" "}
                    {(
                      Math.ceil(TotalFare * rateOfExchange) -
                      (Math.ceil(Tax * rateOfExchange) +
                        Math.ceil(VATAmount * rateOfExchange)) +
                      Math.ceil(markup)
                    ).toLocaleString("en-IN")}
                    {/* {(
                      Math.ceil(BaseFare) + Math.ceil(OtherCharges)
                    ).toLocaleString("en-IN")} */}
                  </p>
                </div>
                <div className={classes.taxesAndFessContainer}>
                  <p className={classes.taxesAndFeesText}>
                    {t("flightSearch.taxesAndFees")}
                  </p>
                  <p className={classes.taxesAndFees}>
                    {t("flightSearch.currencySymbol")}{" "}
                    {Math.ceil(Tax * rateOfExchange).toLocaleString("en-IN")}
                  </p>
                </div>
                <div className={classes.vatContainer}>
                  <p className={classes.taxesAndFeesText}>
                    {t("fareRuleCard.vat")}
                  </p>
                  <p className={classes.taxesAndFees}>
                    {t("flightSearch.currencySymbol")}{" "}
                    {Math.ceil(VATAmount * rateOfExchange).toLocaleString(
                      "en-IN"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={classes.emptyContainer3}></div>
        <div className={classes.totalPriceAndContinueContainer}>
          <div className={classes.totalPriceOuterContainer}>
            {showPriceAccordian ? (
              <ExpandMoreIcon
                style={{ color: "#1b1d52", cursor: "pointer" }}
                onClick={() => setShowPriceAccordian(!showPriceAccordian)}
              />
            ) : (
              <ExpandLessIcon
                style={{ color: "#1b1d52", cursor: "pointer" }}
                onClick={() => setShowPriceAccordian(!showPriceAccordian)}
              />
            )}
            <div className={classes.totalPriceContainer}>
              <p className={classes.totalPriceText}>
                {t("flightSearch.totalPriceIncludingTax")}
              </p>
              <p className={classes.totalPrice}>
                {t("flightSearch.currencySymbol")}{" "}
                {(
                  Math.ceil(TotalFare * rateOfExchange) + markup
                ).toLocaleString("en-IN")}
                {/* {Math.ceil(TotalFare).toLocaleString("en-IN")} */}
              </p>
            </div>
          </div>
          <div
            className={classes.continueContainer}
            onClick={() => setShowFlightDetailsModal(false)}
          >
            <p className={classes.continue}>{t("flightSearch.continue")}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FlightDetails;

FlightDetails.propTypes = {
  showFlightDetailsModal: PropTypes.bool,
  setShowFlightDetailsModal: PropTypes.func,
  flight: PropTypes.object,
  markup: PropTypes.any,
};
