import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    // [theme.breakpoints.down('sm')]:{
    //     padding: '8px 4px',
    // }
  },

}));

export default useStyles;
