import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { Modal, TextField } from "@mui/material";
import CloseSvg from "../../../../../assets/images/x-close.svg";
import { editUserProfile } from "../../../../../modules/auth";
import { setUserInfo } from "../../../../../utils/slices/userSlice";
import { toast } from "react-toastify";
import useStyles from "./styles";
import { number } from "prop-types";
import { useTranslation } from "react-i18next";

const customStyles = {
  borderRadius: "12px",
  padding: "8px 2px",
  background: "#FFF",
  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#525A6A",
  fontFamily: "Lato, sans-serif",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
};

const EditProfile = ({
  openEditProfileModal,
  setOpenEditProfileModal,
  firstName,
  lastName,
  email,
  phoneNo,
  profilePic = "",
  setUserData
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { token } = useSelector((state) => state.user);
  const [updatedFirstName, setUpdatedFirstName] = useState(firstName);
  const [updatedLastName, setUpdatedLastName] = useState(lastName);
  const [updatedEmail, setUpdatedEmail] = useState(email);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(phoneNo)
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false)
  const { t } = useTranslation();
  const validPhoneNumberRegex = /^5\d{8}$/;
  const validEmailRegex = /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/;
  const validFirstNameRegex = /^[A-Za-z\u0600-\u06FF ]{2,50}$/;
  const validLastNameRegex = /^[A-Za-z\u0600-\u06FF]{2,50}$/;

  const validationSchema = {
    number: {
      regex: validPhoneNumberRegex,
      errorMessage: `${t("myProfile.invalidPhoneNumber")}`,
    },
    email: {
      regex: validEmailRegex,
      errorMessage: updatedEmail
        ? `${t("myProfile.enterValidEmail")}`
        : `${t("myProfile.enterEmail")}`,
    },
    firstName: {
      regex: validFirstNameRegex,
      errorMessage: updatedFirstName
        ? `${t("myProfile.enterValidFirstName")}`
        : `${t("myProfile.pleaseEnterFirstName")}`,
    },
    lastName: {
      regex: validLastNameRegex,
      errorMessage: updatedLastName
        ? `${t("myProfile.enterValidLastName")}`
        : `${t("myProfile.pleaseEnterValidLastName")}`,
    },
  };

  const handleEditProfileClick = async () => {
    if (
      validationSchema["firstName"].regex.test(updatedFirstName) &&
      validationSchema["lastName"].regex.test(updatedLastName) &&
      validationSchema["email"].regex.test(updatedEmail) &&
      validationSchema["number"].regex.test(updatedPhoneNumber)
    ) {
      const res = await editUserProfile({
        token,
        firstName: updatedFirstName,
        lastName: updatedLastName,
        email: updatedEmail,
        phoneNo: updatedPhoneNumber,
        profilePic: profilePic ? profilePic : "",
      });
      const {
        firstName,
        lastName,
        email,
        profilePic: profile,
        accessToken,
        phoneNo
      } = res;
      if (window.localStorage) {
        const userDetails =
          window.localStorage &&
          JSON.parse(window.localStorage.getItem("userDetails"));
        userDetails.newCustomer = false;
        userDetails.firstName = firstName;
        userDetails.token = accessToken;
        userDetails.lastName = lastName;
        userDetails.email = email;
        userDetails.phoneNo = phoneNo,
        userDetails.profilePic = profile;
        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
        dispatch(setUserInfo(userDetails));
        toast.success(t("successMessages.profileUpdatedSucessfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenEditProfileModal(false);
        location.reload()
      }
    } else if (!validationSchema["firstName"].regex.test(updatedFirstName)) {
      setFirstNameError(true);
    } else if (!validationSchema["lastName"].regex.test(updatedLastName)) {
      setLastNameError(true);
    } else if (!validationSchema["email"].regex.test(updatedEmail)) {
      setEmailError(true);
    } else if (!validationSchema['number'].regex.test(updatedPhoneNumber)) {
      setPhoneError(true)
    }
  };

  return (
    <Modal
      open={openEditProfileModal}
      onClose={() => setOpenEditProfileModal(false)}
    >
      <div
        className={
          localStorage.getItem("language") == "AR"
            ? classes.editProfileMainContainerArabic
            : classes.editProfileMainContainer
        }
      >
        <div className={classes.editProfileTextContainer}>
          <p className={classes.editProfileText}>
            {t("myProfile.editProfile")}
          </p>
          <ReactSVG
            src={CloseSvg}
            onClick={() => setOpenEditProfileModal(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className={classes.nameMobileEmailContainer}>
          <div className={classes.firstNameAndLastNameContainer}>
            <div className={classes.firstNameContainer}>
              <p className={classes.firstNameLable}>
                {t("myProfile.firstName")}
              </p>
              <TextField
                className={classes.root}
                value={updatedFirstName}
                onChange={(e) => {
                  setUpdatedFirstName(e.target.value);
                  if (firstNameError) {
                    setFirstNameError(false);
                  }
                }}
                size="small"
                placeholder={t("myProfile.enterFirstName")}
                InputProps={{ style: customStyles }}
              />
              {firstNameError && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {validationSchema.firstName.errorMessage}{" "}
                </p>
              )}
            </div>
            <div className={classes.lastNameContainer}>
              <p className={classes.lastNameLable}>{t("myProfile.lastName")}</p>
              <TextField
                className={classes.root}
                size="small"
                value={updatedLastName}
                onChange={(e) => {
                  setUpdatedLastName(e.target.value);
                  if (lastNameError) {
                    setLastNameError(false);
                  }
                }}
                placeholder={t("myProfile.enterLastName")}
                InputProps={{ style: customStyles }}
              />
              {lastNameError && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {validationSchema.lastName.errorMessage}{" "}
                </p>
              )}
            </div>
          </div>
          <div className={classes.mobileNumberAndEmailContainer}>
            <div className={classes.mobileNumberContainer}>
              <p className={classes.mobileNumberLable}>
                {t("myProfile.mobileNumber")}
              </p>
              <TextField
                className={classes.root}
                size="small"
                value={updatedPhoneNumber}
                onChange={(e) => {
                  setUpdatedPhoneNumber(e.target.value);
                  if (phoneError) {
                    setPhoneError(false);
                  }
                }}
                InputProps={{ style: customStyles }}
              />
              {phoneError && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {validationSchema.number.errorMessage}{" "}
                </p>
              )}
            </div>
            <div className={classes.emailIdContainer}>
              <p className={classes.emailIdLable}>{t("myProfile.emailId")}</p>
              <TextField
                className={classes.root}
                disabled={true}
                size="small"
                value={updatedEmail}
                onChange={(e) => {
                  setUpdatedEmail(e.target.value);
                  if (emailError) {
                    setEmailError(false);
                  }
                }}
                placeholder={t("myProfile.enterEmailId")}
                InputProps={{ style: customStyles }}
              />
              {emailError && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {validationSchema.email.errorMessage}{" "}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={classes.saveOuterContainer}>
          <div></div>
          <div
            className={classes.saveContainer}
            onClick={handleEditProfileClick}
          >
            <p className={classes.saveText}>{t("myProfile.save")}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfile;

EditProfile.propTypes = {
  openEditProfileModal: PropTypes.bool,
  setOpenEditProfileModal: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNo: PropTypes.string,
  profilePic: PropTypes.string,
};
