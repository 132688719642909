import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  package: {
    background: " #FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "0px 150px 24px 150px",

    // [theme.breakpoints.up("lg")]: {
    //   padding: "0px",
    // },
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  packageContentContainer: {
    display: "inline-flex",
    gap: "24px",
    width: "100%",
    marginBottom: "80px",
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  enquiryFormContainer: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  packageDetailsContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      background: "#FAFAFA",
    },
  },
  subContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "1434px",
    },
  },
}));

export default useStyles;
