import React from "react"
import eventBus from "../../../core/eventBus"
import { useTranslation } from "react-i18next"

export default function SignupVerifyEmail({ handleRedirection }) {
    function handleOkVerifiedClick() {
        eventBus.emit("close-dialog")
        location.reload()
        if (handleRedirection) {
            handleRedirection()
        }
    }
    const {t}  = useTranslation()
    return <div
        style={{
            width: '500px',
            maxWidth: '90vw',
            background: '#FFF',
            height: '170px',
            padding: '25px',
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            justifyContent: 'space-between'
        }}>

        <p>
            {/* An email verification link has been sent to your email. Please verify your email address. */}
            {t("registration.verficationLinkSent")}
            </p>
        <div style={{
            display: 'flex',
            gap: '15px',
            justifyContent: 'flex-end'
        }}>
            <button
                style={{
                    background: "#1B1D52",
                    color: '#fff',
                    width: '100px',
                    height: '35px'
                }}
                onClick={handleOkVerifiedClick}
            >
                {/* Ok */}
                {t("registration.ok")}
                </button>
            <button
                style={{
                    background: "#1B1D52",
                    color: '#fff',
                    width: '100px',
                    height: '35px'
                }}
                onClick={handleOkVerifiedClick}
            >
                {/* Verified */}
                {t("registration.verified")}
            </button>
        </div>
    </div>
}