import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import useStyles from "./style";
import closeSvg from "../../../../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../../../../core/eventBus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

const BaggageAccordianContentMobile = ({
  title,
  baggage,
  selected,
  type,
  setExtraBaggage,
  extraBaggage,
  index,
  toggle,
  setToggle,
}) => {
  const [checkedBaggage, setCheckedBaggage] = useState();
  const styles = useStyles();
  const rateOfExchange = useSelector((state) => state?.rateOfExchange?.rate);

  const selectedExtraBaggage = (data, i) => {
    let extraBaggageArray = extraBaggage;
    let updatedArray = extraBaggage[index];

    if (checkedBaggage == i) {
      setCheckedBaggage(-1);
      const isExistsIndex = updatedArray.findIndex(
        (baggage) => baggage.id == selected && baggage.type == type
      );
      if (isExistsIndex !== -1) {
        updatedArray.splice(isExistsIndex, 1);
        extraBaggageArray[index] = updatedArray;
        setExtraBaggage(extraBaggageArray);
      }
      extraBaggageArray[index] = [...updatedArray];
      setExtraBaggage(extraBaggageArray);
      setToggle(!toggle);
      return;
    }
    setCheckedBaggage(i);

    const isExistsIndex = updatedArray.findIndex(
      (baggage) => baggage.id == selected && baggage.type == type
    );
    if (isExistsIndex !== -1) {
      updatedArray.splice(isExistsIndex, 1);
      extraBaggageArray[index] = updatedArray;
      setExtraBaggage(extraBaggageArray);
    }

    extraBaggageArray[index] = [
      ...updatedArray,
      {
        ...data,
        id: selected,
        type,
      },
    ];

    setExtraBaggage(extraBaggageArray);
    setToggle(!toggle);
  };

  const isSelectedBaggage = (weight, price) => {
    let isSelected = false;
    extraBaggage &&
      extraBaggage[index] &&
      extraBaggage[index].map((baggage, i) => {
        if (
          baggage.id == selected &&
          baggage.type == type &&
          baggage.Weight == weight &&
          baggage.Price == price
        ) {
          isSelected = true;
          return;
        }
      });

    return isSelected;
  };

  return (
    <div className={styles.BaggageDetailsMobile}>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          sx={{ background: "#E6E6FA" }}
          expandIcon={<ExpandMoreIcon style={{ color: "#1b1d52" }} />}
        >
          <Typography className="baggage-accordian-heading">
            <div className="selected-wheight">{title}</div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ justifyContent: "center" }}>
          <div
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-between",
            }}
          >
            {baggage &&
              baggage &&
              baggage.map((data, i) => {
                const { Price, Weight } = data;
                return (
                  <div className="baggage-details-accordion-content-div">
                    <Box sx={{ display: "flex", gap: "8px " }}>
                      <input
                        type="checkbox"
                        checked={
                          i == checkedBaggage ||
                          isSelectedBaggage(Weight, Price)
                        }
                        onClick={() => selectedExtraBaggage(data, i)}
                      />
                      <Box>
                        <Typography
                          style={{
                            fontFamily: "Lato",
                            fontSize: "12px",
                            fontWeight: " 400px",
                          }}
                        >
                          Weight {Weight}Kg
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: "Lato",
                            fontSize: "12px",
                            fontWeight: " 400px",
                          }}
                        >
                          SAR {Math.ceil(Price * rateOfExchange)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                );
              })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BaggageAccordianContentMobile;
