import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    width: "100%",
    height: "650px",
    // margin: "0px 20px",
    display: "flex",

    [theme.breakpoints.down("xl")]: {
      height: "560px",
      padding: "0px 32px",
    },

    [theme.breakpoints.down("lg")]: {
      height: "560px",
      padding: "0px 32px",
    },
    [theme.breakpoints.down("md")]: {
      height: "380px",
      padding: "0px 32px",
    },

    [theme.breakpoints.down("sm")]: {
      height: "250px",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "150px",
      padding: "0px",
    },
  },

  carouselImage: {
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
    width: "100%",
    height: "100%",
    position: "relative",
    // borderRadius: "48px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
    },
  },
  rightIcon: {
    width: "80px",
    height: "80px",
    alignSelf: "center",
    position: "absolute",
    zIndex: "1",
    cursor: "pointer",
    right: "35px",

    [theme.breakpoints.down("md")]: {
      right: "0px",
      width: "50px",
      height: "50px",
    },
  },
  leftIcon: {
    width: "80px",
    height: "80px",
    alignSelf: "center",
    cursor: "pointer",
    position: "absolute",
    zIndex: "1",
    [theme.breakpoints.down("md")]: {
      width: "50px",
      height: "50px",
    },
  },

  leftIconArabic: {
    width: "80px",
    height: "80px",
    alignSelf: "center",
    cursor: "pointer",
    position: "absolute",
    zIndex: "1",
    left: "0",
    marginLeft: "35px",
    [theme.breakpoints.down("md")]: {
      width: "50px",
      height: "50px",
    },
  },
}));

export default useStyles;
