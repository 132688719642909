import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Offers from "../../components/home/offers";
// import Carousel from "react-material-ui-carousel";
import Packages from "../../components/home/packages";
import SearchWidget from "../../components/search-widget";
import Newsletter from "../../components/home/newsletter";
import RecentSearches from "../../components/home/recent-searches";
import RecentArticlesAndBlogs from "../../components/home/recent-articles";
import FindTravelPerfection from "../../components/home/travel-perfection";
import { getPackages } from "../../modules/package";
import { getBanners, getStrings } from "../../modules/home";
import ImageCarousel from "../../components/home/carousel";
import useStyles from "./style";
import AdsCarousel from "../../components/home/ads";
import "./style.css";
import useIsMobile from "../../hooks/useIsMobile.hook";
import SearchWidgetMobile from "../../components/home/search-widget-mobile";
import RecentSearchesHotel from "../../components/home/hotel-recent-searches";
import { v4 as uuidv4 } from "uuid";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;
import handleUnauthorisedLogout from "../../modules/unauthorised";
import { setUserInfo } from "../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import { updatePriceSorting } from "../../utils/slices/localFiltersSlice";
import eventBus from "../../core/eventBus";
import { useLocation } from "react-router";
import useIsTablet from "../../hooks/useIsTablet.hook";
import useResponsive from "../../hooks/responsive.hook";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const location = useLocation();
  const { flightTravelType, totalSelectionForMultiCity } = useSelector(
    (state) => state.searchFlight
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const isMobile = currentBreakpoint === "small";
  const [packages, setPackages] = useState([]);
  const [heroBanners, setHeroBanners] = useState([]);
  const [adBanners, setAdBanners] = useState([]);
  const styles = useStyles();
  const { i18n } = useTranslation();

  const carouselAndSearchRef = useRef(null);
  const searchRef = useRef(null);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const setLanguage = () => {
    if (localStorage.getItem("language") == "AR") {
      changeLanguage("ar");
    } else if (localStorage.getItem("language") == "EN") {
      changeLanguage("en");
    }
  };
  const isUUIDStored = () => {
    return localStorage.getItem("uuid");
  };

  const generateAndStoreUUID = () => {
    if (!isUUIDStored()) {
      const newUUID = uuidv4();
      localStorage.setItem("uuid", newUUID);
    }
  };
  // useEffect(() => {
  //   setLanguage();
  // }, []);

  useEffect(() => {
    generateAndStoreUUID();
  }, []);

  useEffect(() => {
    handleGetPackages();
    handleGetBanners();
  }, []);

  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, [location]);

  // useEffect(() => {
  //   handleGetString();
  // });

  // const handleGetString = async () => {
  //   const response = await getStrings("en");
  //   console.log("stringResponse", response.data);
  // };
  const currentBreakpoint = useResponsive();

  const handleGetPackages = async () => {
    const { packages: packagesData } = await getPackages({
      limit: 100,
      skip: 0,
    });
    setPackages(packagesData);
  };

  const handleGetBanners = async () => {
    const { data } = await getBanners(user?.token);
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    } else {
      const hero = data?.filter((banner) => banner?.bannerType === "HERO");
      setHeroBanners(hero);
      const ad = data?.filter((banner) => banner?.bannerType === "AD");
      setAdBanners(ad);
    }
    // const hero = data?.filter((banner) => banner?.bannerType === "HERO");
    // setHeroBanners(hero);
    // const ad = data?.filter((banner) => banner?.bannerType === "AD");
    // setAdBanners(ad);
  };

  const renderHeroSlides = heroBanners?.map((image) => {
    const { imageUrl } = image;
    const fullImageUrl = `${S3Bucket}/${imageUrl}`;
    return fullImageUrl;
  });

  const renderAdSlides = adBanners?.map((image) => {
    const { imageUrl } = image;
    const fullImageUrl = `${S3Bucket}/${imageUrl}`;
    return fullImageUrl;
  });

  const widgetType = useSelector((state) => state.global.searchWidgetType);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        {/* {currentBreakpoint !== "desktopView" && (
          <>
            <div className={styles.blurDiv1}>ok1</div>
            <div className={styles.blurDiv2}>ok2</div>
            <div className={styles.blurDiv3}>ok3</div>
            <div className={styles.blurDiv4}>ok4</div>
          </>
        )} */}
        <div
          ref={carouselAndSearchRef}
          className={styles.homeCarouselAndSearchWidgetContainer}
        >
          <ImageCarousel images={renderHeroSlides} />
        </div>
        <div className={styles.homeMidSection}>
          {(isMobile || isTablet) && <SearchWidgetMobile />}
          {!isMobile && !isTablet && (
            <div ref={searchRef} className={styles.homeSearchWidgetWrapper}>
              <SearchWidget />
            </div>
          )}
          {isMobile &&
            (widgetType === 1 ? <RecentSearches /> : <RecentSearchesHotel />)}

          {!isMobile &&
            (widgetType === 1 ? (
              <div
                style={
                  flightTravelType == 3 && !isMobile
                    ? totalSelectionForMultiCity == 3
                      ? { marginTop: "40px" }
                      : { marginTop: "30px" }
                    : { marginTop: "20px" }
                }
              >
                <RecentSearches />
              </div>
            ) : (
              <div style={{ marginTop: "20px" }}>
                <RecentSearchesHotel />
              </div>
            ))}

          <div className={styles.adsCarousel}>
            <AdsCarousel images={renderAdSlides} />
          </div>
          <div
          // style={{zIndex: '-10'}}
          >
            <Offers />
          </div>

          <div style={{ zIndex: 0 }}>
            <Packages packages={packages} />
          </div>

          <FindTravelPerfection />
        </div>
        <Newsletter />
        <RecentArticlesAndBlogs />

        {/* <div
          className={
            flightTravelType === 3 && addAnotherCity
              ? "home-content-enlarge"
              : flightTravelType === 3
              ? "home-content"
              : ""
          }
        >
          {currentBreakpoint === "extraLarge" && (
            <div className="gradient1"></div>
          )}

          <Box className={styles.recentSearches}>
            <RecentSearches />
          </Box>
          <Box className={styles.adsCarousel}>
            <AdsCarousel images={renderAdSlides} />
          </Box>
          <Box className={styles.offers}>
            <Offers />
          </Box>
          {currentBreakpoint === "extraLarge" && (
            <div className="gradient2"></div>
          )}
          <Box
            sx={{
              width: "1140px",
              marginLeft: "125px",
              position: "relative",
            }}
            className={styles.packages}
          >
            <Packages packages={packages} />
          </Box>
          {currentBreakpoint === "extraLarge" && (
            <div className="gradient3"></div>
          )}
          <Box
            sx={{
              marginTop: "50px",
            }}
          >
            <div className={styles.travelPerfectionBackground}></div>
            <div className={styles.findTravelPerfection}>
              <FindTravelPerfection />
            </div>
          </Box>

          <Box className={styles.newsletter}>
            <Newsletter />
          </Box>
        </div> */}
      </div>
      {/* <div className={styles.recentArticleAndBlog}>
        <RecentArticlesAndBlogs />
      </div> */}
      {/* <div className="gradient4"></div> */}
    </div>
  );
};

export default HomePage;
