import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { Box, Typography } from "@mui/material";
import OneWaySvg from "../../../../assets/images/arrow-one-way.svg";
import useStyles from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

const ChooseSeatInfo = ({ origin, destination }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.needLegRoomSeatAreCheaperContainer}>
        <p className={classes.needMoreLegRoom}>
          {t("seatSelection.needMoreLegroom")}
        </p>
        <p className={classes.seatsAreCheaper}>
          {t("seatSelection.cheaperWhenPreBooked")}
        </p>
      </div>
      <div className={classes.onwardOriginDestinationContainer}>
        <p className={classes.onward}>{t("seatSelection.onward")}</p>
        <p className={classes.origin}>{origin}</p>
        {localStorage.getItem("language") == "AR" ? (
          <ArrowBackIcon className={classes.oneWaySvg} />
        ) : (
          <ReactSVG src={OneWaySvg} className={classes.oneWaySvg} />
        )}

        <p className={classes.destination}>{destination}</p>
      </div>
    </div>
  );
};
export default ChooseSeatInfo;

ChooseSeatInfo.propTypes = {
  origin: PropTypes.any,
  destination: PropTypes.any,
};
