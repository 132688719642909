import { get } from "lodash";
import React from "react";
import useStyles from "./styles";

const TwoToneTitleComp = (props) => {
  const styles = useStyles();
  const firstPart = get(props, "firstPart", "");
  const secondPart = get(props, "secondPart", "");
  return (
    <h2 className={styles.twoToneTitle}>
      {firstPart}
      <span className={styles.twoToneTitleSecondPart}>{secondPart}</span>
    </h2>
  );
};

export default TwoToneTitleComp;
