import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  BaggageDetailsMobile: {
    display: "flex",
    gap: "20px",
    padding: "24px 16px",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  BaggageDetailsMobileContainer: {
    padding: "10px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    width: "150px",
    display: "flex",
  },
  closeImage: {
    marginTop: "16px",
    marginLeft: "16px",
  },
}));

export default useStyles;
