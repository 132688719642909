import React from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import "./style.css";
import { Box, Typography } from "@mui/material";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SidebarTimings = ({ timing, price }) => {
  const { icon, name } = timing;
  const classes = useStyles();
  const { t } = useTranslation();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  console.log('name', name)
  return (
    <div className={classes.nameAndPriceContainer}>
      <ReactSVG src={icon} />
      <p className={classes.name}>{name}</p>
      <p className={classes.price}>
        {t("flightSearch.currencySymbol")}{" "}
        {Math.ceil(price).toLocaleString("en-IN")}
      </p>
    </div>
  );
};

export default SidebarTimings;

SidebarTimings.propTypes = {
  timing: PropTypes.object,
  price: PropTypes.any,
};
