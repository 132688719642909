import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  flightSearchWidget: {
    display: "flex",
    width: "1140px",
    padding: "30px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "24px",
    borderRadius: "0px 5px 5px 5px",
    background: "rgba(255, 255, 255, 0.70)",
    backdropFilter: "blur(8px)",
  },

  flightSearchWidgetHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  flightSearchWidgetRadio: {
    width: "80%",
    padding: "10px",
  },

  radioLabelBackground: {
    cursor: "pointer",
    height: "33px",
    display: "inline-flex",
    borderRadius: "6px",
    marginRight: "10px",
  },

  radioGroupActive: {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    background: "#fff",
    borderRadius: "5px",
    gap: "10px",
  },

  radioGroup: {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    borderRadius: "5px",
    gap: "10px",
  },

  radioText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  radioButton: {
    width: "16px",
    height: "16px",
    background: "#FFF",
    borderRadius: "50%",
    border: "2px solid #000",
  },

  radioButtonActive: {
    width: "16px",
    height: "16px",
    background: "#FFF",
    borderRadius: "50%",
    border: "5px solid #1B1D52",
  },

  searchAirportContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 2.5,
    borderRadius: "12px",
    cursor: "pointer",
  },
  calendar: {
    flex: 1,
  },

  calendarMultiCity: {
    [theme.breakpoints.down("lg")]: {
      width: "170px",
    },
  },

  returnCalendarContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },

  userInfoLarge: {
    flex: 2.6,
  },
  userInfo: {
    flex: 1.5,
  },
}));

export default useStyles;
