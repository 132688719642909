import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchPageHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px",
    backgroundColor: "#E6E6FA",
    borderRadius: "5px",
  },
  searchPageHeaderButton: {
    padding: "8px 12px",
    color: theme.palette.entityColors.entityColorBackground1,
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    borderRadius: "8px",
    border: "none",
    cursor: "pointer",
    fontSize: theme.fontSizes.fontSize2,
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
}));

export default useStyles;
