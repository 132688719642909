import React, { useEffect, useState } from "react";
import HotelImages from "./image-view/hotel-images";
import RoomDetail from "./room-detail";
import AboutHotel from "./about-hotel";
import Amenities from "./amenities";
// import PropertyRules from "./property-rules";
import HotelInfo from "./info";
import "./style.css";
import { getRoomsDetails, getHotelDetails } from "../../modules/hotels";
import { useLocation } from "react-router-dom";
import { get, isEmpty } from "lodash";
import useStyles from "./styles";
import Map from "../../components/map";
import { useSelector } from "react-redux";
import axios from "axios";

const HotelDetails = () => {
  const { state } = useLocation();
  const styles = useStyles();
  const [hotelDetailsState, setHotelDetailsState] = useState({});
  const [roomsDetailsState, setRoomsDetailsState] = useState({});
  const user = useSelector((state) => state.user);
  const [ipAddress, setIpAddress] = useState("");
  const uuid = localStorage.getItem("uuid");
  const rooms = get(roomsDetailsState, "HotelRoomsDetails", []);
  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIpAddress(response.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, []);
  const fetchDetails = async () => {
    try {
      const [roomDetails, hotelDetails] = await Promise.all([
        getRoomsDetails({
          hotelCode: parseInt(get(state, "hotelCode", "")),
          traceId: get(state, "traceId", ""),
          resultIndex: get(state, "resultIndex", 0),
          token: user?.token,
          additionalDetails: {
            ipAddress: ipAddress,
            deviceType: "WEB",
            browserType: "CHROME",
            coordinates: [
              0
            ],
            uuid: uuid
          }
        }),
        getHotelDetails({
          hotelCode: parseInt(get(state, "hotelCode", "")),
          traceId: get(state, "traceId", ""),
          resultIndex: get(state, "resultIndex", 0),
          token: user?.token,
          additionalDetails: {
            ipAddress: ipAddress,
    
            deviceType: "WEB",
            browserType: "CHROME",
            coordinates: [
              0
            ],
            uuid: uuid
          }
        }),
      ]);
      setHotelDetailsState(get(hotelDetails, "data", {}));
      setRoomsDetailsState(get(roomDetails, "data", {}));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className={styles.hotelDetails}>
      <div className="images-container">
        <HotelImages
          images={get(hotelDetailsState, "hotelDetails.Images", [])}
        />
      </div>
      <div className={styles.hotelDetailsBody}>
        <div className={styles.hotelDetailsBodyInfo}>
          <HotelInfo info={get(hotelDetailsState, "hotelDetails", {})} />
        </div>
        <div className={styles.hotelDetailsBodyMap}>
          <Map data={get(hotelDetailsState, "hotelDetails", {})}/>
        </div>
        <div className={styles.hotelDetailsBodyAmenities}>
          <Amenities amenities={get(hotelDetailsState, "hotelDetails.HotelFacilities", [])} />
        </div>
        <div className={styles.hotelDetailsBodyAbout}>
          <AboutHotel hotelDetails={get(hotelDetailsState, "hotelDetails", {})}></AboutHotel>
        </div>
        { !isEmpty(rooms) && <div className={styles.hotelDetailsBodyRoomsList}>
          {rooms.map((roomDetail=>{
            return(
              <RoomDetail key={1} details={roomDetail}/>
            );
          }))}
        </div>}
      </div>
      {/* <div className="amenities-container">
          <div style={{ width: "50%", height: "100%" }}>
            <HotelInfo info={info} />
          </div>
          <div style={{ width: "50%", height: "100%" }}>
            <PropertyRules rules={amenities} />
          </div>
        </div>
        <div className="amenities-container">
          <div style={{ width: "50%" }}>
            <Amenities amenities={amenities} />
          </div>
          <div style={{ width: "50%" }}>
            <PropertyRules rules={amenities} />
          </div>
        </div>
        <div className="about-container">
          {hotelDetails && (
            <AboutHotel hotelDetails={hotelDetails}></AboutHotel>
          )}
        </div>
        <div className="hotel-view-container">
          {hotels.length > 0 &&
            hotels.map((e) => {
              return <RoomDetail key={1} hotel={e}></RoomDetail>;
            })}
        </div> */}
    </div>
  );
};

export default HotelDetails;
