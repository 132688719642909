import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import CouponCode from "./coupons-details";
import { TextField, InputAdornment } from "@mui/material";
import TrashSvg from "../../../../assets/images/trash_icon_red.svg";
import CouponSvg from "../../../../assets/images/gift_icon_blue.svg";
import useStyles from "./styles";
import { validateCoupon } from "../../../../modules/flight";
import handleUnauthorisedLogout from "../../../../modules/unauthorised";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const customStyles = {
  borderRadius: "10px",
  padding: "8px",
};

const AddCoupenGiftCard = ({
  couponDetails,
  traceId,
  setIsCouponApplied,
  tripType,
  flightDetails,
  markup,
  markup2,
  setDiscountValue,
  isCouponApplied,
  setMessage,
  message,
}) => {
  const styles = useStyles();
  const [coupon, setCoupon] = useState("");
  const handleCouponValue = (e) => {
    setCoupon(e.target.value);
  };

  const handleClearCouponValue = () => {
    setCoupon("");
    setMessage("");
    setIsCouponApplied(false);
    setDiscountValue(0);
  };

  const {t} = useTranslation()
  return (
    <div className={styles.mainContainer}>
      <div className={styles.applyCouponContainer}>
        <ReactSVG src={CouponSvg} />
        <p className={styles.applyCoupon}>
          {/* Apply Coupon Or Gift Card */}
          {t("coupon.applyCouponOrGiftCard")}
        </p>
      </div>
      <div className={styles.couponInputContainer}>
        {isCouponApplied && (
          <TextField
            sx={{ border: "1px #D0D5DD", minHeight: "0px !important" }}
            size="small"
            className={styles.root}
            fullWidth
            value={couponDetails.lowestAmountCoupon[0].couponCode}
            onChange={handleCouponValue}
            disabled={true}
            InputProps={{
              style: customStyles,
              endAdornment: (
                <InputAdornment position="end" className={styles.couponInput}>
                  {isCouponApplied && (
                    <ReactSVG src={TrashSvg} onClick={handleClearCouponValue} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
        {message && isCouponApplied && (
          <p className={styles.successText}>{message}</p>
        )}
        {!isCouponApplied && (
          <CouponCode
            couponDetails={couponDetails}
            setCouponValue={setCoupon}
            traceId={traceId}
            setMessage={setMessage}
            coupon={coupon}
            setIsCouponApplied={setIsCouponApplied}
            tripType={tripType}
            flightDetails={flightDetails}
            markup={markup}
            markup2={markup2}
            setDiscountValue={setDiscountValue}
            isCouponApplied={isCouponApplied}
          />
        )}
      </div>
    </div>
  );
};
export const PrivateCoupon = ({
  couponDetails,
  traceId,
  setIsCouponApplied,
  tripType,
  flightDetails,
  markup,
  markup2,
  setDiscountValue,
  isCouponApplied,
  setMessage,
  message,
  visibility,
  setCoupon,
  coupon,
}) => {
  const styles = useStyles();
  // const couponCode = isCouponApplied ? couponDetails?.lowestAmountCoupon ? couponDetails.lowestAmountCoupon[0]?.couponCode : "" : ""
  // const [coupon, setCoupon] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const rateOfExchange = useSelector((state) => state?.rateOfExchange?.rate);

  const handleCouponValue = (e) => {
    setCoupon(e.target.value);
  };

  const handleClearCouponValue = () => {
    setCoupon("");
    setMessage("");
    setIsCouponApplied(false);
    setDiscountValue(0);
  };

  const getTxnAmount = () => {
    if (tripType == "DRT") {
      return Math.ceil(
        flightDetails[0]?.Fare?.TotalFare +
          flightDetails[1]?.Fare?.TotalFare +
          markup +
          markup2
      );
    } else
      return Math.ceil(
        flightDetails[0]?.Fare?.TotalFare * rateOfExchange + markup
      );
  };
  const handleCouponCode = async (couponCode) => {
    const txnAmount = getTxnAmount();
    const data = await validateCoupon({
      traceId,
      coupon: couponCode,
      token: user?.token,
      flight: couponDetails.payload,
    });
    const statusCode = data?.statusCode;
    const message = data?.coupon?.successMessage;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    if (data?.valid == true) {
      const coupon = data.coupon;
      if (tripType == "DRT") {
        calculateCouponAmount({
          flightDetails,
          markupValue: markup + markup2,
          tripType,
          coupon,
        });
      } else {
        calculateCouponAmount({
          flightDetails,
          markupValue: markup,
          tripType,
          coupon,
        });
      }
      setMessage(message);
      setIsCouponApplied(true);
      toast.success(t("successMessages.couponAppliedSuccessfully"));
    } else {
      toast.error(t("errorMessages.enterValidCoupon"));
    }
  };

  const calculateCouponAmount = ({
    flightDetails,
    markupValue,
    tripType,
    coupon,
  }) => {
    let amt = 0;
    let baseFare = 0;
    let TotalFare = 0;
    if (tripType == "DRT") {
      baseFare =
        flightDetails[0]?.Fare?.TotalFare -
        (flightDetails[0]?.Fare?.Tax + flightDetails[0]?.Fare?.VATAmount) +
        flightDetails[1]?.Fare?.TotalFare -
        (flightDetails[1]?.Fare?.Tax + flightDetails[1]?.Fare?.VATAmount);
      markupValue;
      TotalFare =
        flightDetails[0]?.Fare?.TotalFare +
        flightDetails[1]?.Fare?.TotalFare +
        markupValue;
    } else {
      baseFare =
        Math.ceil(flightDetails[0]?.Fare?.TotalFare * rateOfExchange) -
        (Math.ceil(flightDetails[0]?.Fare?.Tax * rateOfExchange) +
          Math.ceil(flightDetails[0]?.Fare?.VATAmount * rateOfExchange));
      TotalFare = Math.ceil(flightDetails[0].Fare.TotalFare * rateOfExchange);
    }
    const { amountType, amount, applyTo, maxAmount } = coupon;

    if (amountType === "FLAT") {
      amt = amount;
    } else {
      if (applyTo === "BASEFARE") {
        amt = (amount * baseFare) / 100;
      } else {
        amt = (amount * TotalFare) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    amt = Math.ceil(amt);
    setDiscountValue(amt);
    
  };

  const {t} = useTranslation()
  return (
    <div className={styles.mainContainer}>
      <div className={styles.applyCouponContainer}>
        <ReactSVG src={CouponSvg} />
        <p className={styles.applyCoupon}>
          {/* Apply Coupon Or Gift Card */}
          {t("coupon.applyCouponOrGiftCard")}

          </p>
      </div>
      <div className={styles.couponInputContainer}>
        <TextField
          sx={{ border: "1px #D0D5DD", minHeight: "0px !important" }}
          size="small"
          className={styles.root}
          fullWidth
          value={coupon}
          onChange={handleCouponValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (coupon) {
                handleCouponCode(coupon);
              } else {
                toast.error(t("errorMessages.enterValidCoupon"));
              }
            }
          }}
          disabled={isCouponApplied ? true : false}
          InputProps={{
            style: customStyles,
            endAdornment: (
              <InputAdornment position="end" className={styles.couponInput}>
                {isCouponApplied && (
                  <ReactSVG src={TrashSvg} onClick={handleClearCouponValue} />
                )}
                {!isCouponApplied && (
                  <p
                    className={styles.applyText}
                    onClick={() => {
                      handleCouponCode(coupon);
                    }}
                  >
                    {/* Apply */}
                    {t("coupon.apply")}

                  </p>
                )}
              </InputAdornment>
            ),
          }}
        />
        {message && isCouponApplied && (
          <p className={styles.successText}>{message}</p>
        )}
        {!isCouponApplied &&
          couponDetails &&
          Object.keys(couponDetails).length > 0 &&
          couponDetails?.lowestAmountCoupon?.length > 0 && (
            <CouponCode
              couponDetails={couponDetails}
              setCouponValue={setCoupon}
              traceId={traceId}
              setMessage={setMessage}
              coupon={coupon}
              setIsCouponApplied={setIsCouponApplied}
              tripType={tripType}
              flightDetails={flightDetails}
              markup={markup}
              markup2={markup2}
              setDiscountValue={setDiscountValue}
              isCouponApplied={isCouponApplied}
              setCoupon={setCoupon}
            />
          )}
      </div>
    </div>
  );
};

export default AddCoupenGiftCard;

AddCoupenGiftCard.propTypes = {
  couponDetails: PropTypes.any,
  traceId: PropTypes.any,
  setIsCouponApplied: PropTypes.any,
};
