import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelDetailsInfo: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    padding: "24px 16px",
    borderRadius: "10px",
  },
  hotelDetailsRatingRow: {
    display: "flex",
    alignItems: "center",
    columnGap: "3px",
  },
  hotelDetailsTitle: {
    fontSize: "24px",
    fontWeight: 700,
  },
  hotelDetailsInfoName: {
    fontSize: theme.fontSizes.fontSize0,
    color: theme.palette.textColors.textColorPrimary,
  },
  hotelDetailsInfoUpper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    alignItems: "flex-start",
    paddingBottom: "16px",
    borderBottom: `1px dashed ${theme.palette.entityColors.entityColorBorder}`,
  },
  hotelDetailsRatingCount: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorSecondary,
  },
  hotelDetailsReviewsCount: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
  },
  hotelDetailsFacilities: {
    display: "flex",
    flexWrap: "wrap",
    gap: "32px",
  },
  hotelDetailsFacility: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
    fontWeight: "500",
  },
  hotelDetailsPriceSection: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "16px",
  },
  hotelDetailsPriceValue: {
    fontSize: theme.fontSizes.fontSize4,
    color: theme.palette.textColors.textColorPrimary,
    fontWeight: "800",
  },
  hotelDetailsPriceTax: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorSecondary,
    fontWeight: "400",
  },
  hotelDetailsSelectRoom: {
    padding: "12px 16px",
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    borderRadius: "10px",
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.entityColors.entityColorBackground1,
    fontWeight: "400",
    border: "none",
    cursor: "pointer",
  },
}));

export default useStyles;
