import React, { useEffect, useState } from "react";
import "./style.css";
import { ReactSVG } from "react-svg";
import ArrowRightSvg from "../../../../assets/images/arrow-right.svg";
import ArrowRightGrey from "../../../../assets/images/arrow-right-grey.svg";
import { getBlogs } from "../../../../modules/home";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const customUseStyles = makeStyles({
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
});

const Blogs = () => {
  const navigate = useNavigate();
  const classes = customUseStyles();
  const [blogs, setBlogs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(currentPage);
  const user = useSelector((state) => state.user);
  const styles = useStyles();
  const { t } = useTranslation();
  const getLocale = useSelector((state) => state.locale);
  const entriesPerPage = 9;

  useEffect(() => {
    handleGetBlogs();
  }, [currentPage]);

  const handleGetBlogs = async () => {
    const skip = (currentPage - 1) * entriesPerPage;
    const data = await getBlogs({
      limit: entriesPerPage,
      skip,
      token: user?.token,
    });
    setBlogs(data.blogs);
    setTotalBlogs(data.count);
  };

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageClick(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageClick(currentPage - 1);
    }
  };
  const handleGoButton = () => {
    if (inputPage >= 1 && inputPage <= totalPages) {
      setCurrentPage(inputPage);
    }
  };

  const totalPages = Math.ceil(totalBlogs / entriesPerPage);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <Box className={styles.blogContainer}>
          <Box
            sx={{ display: "inline-flex", gap: "8px", alignItems: "center" }}
          >
            <Typography
              sx={{
                color: "#667085",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              {/* Home */}
              {t("blogs.home")}
            </Typography>
            <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
            <Typography
              sx={{
                color: "#4037acd6",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                cursor: "pointer",
              }}
              onClick={() => navigate("/blogs")}
            >
              {/* Blogs */}
              {t("blogs.blogs")}
            </Typography>
          </Box>
          <Box className={styles.blogsContainer}>
            {blogs &&
              blogs.map((blogs) => {
                const { _id, imageUrl, name, description, blogUrl } = blogs;
                return (
                  <Box
                    className={styles.blogs}
                    key={_id}
                    onClick={() => window.open(blogUrl, "_blank")}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url(${S3Bucket}/${imageUrl})`,
                      }}
                      className={styles.blogImage}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "24px 16px",
                        alignItems: "flex-start",
                        gap: "8px",
                        flexShrink: 0,
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#344054",
                          fontFamily: "Lato",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",
                        }}
                      >
                        {name}
                      </Typography>
                      <Typography
                        className={classes.ellipsis}
                        sx={{
                          color: "#525A6A",
                          fontFamily: "Lato",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          textAlign: "justify",
                        }}
                      >
                        {description}
                      </Typography>
                      <Typography
                        alignItems={"right"}
                        sx={{
                          color: "#4037acd6",
                          fontFamily: "Lato",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          alignSelf: "flex-end",
                        }}
                      >
                        {/* Read More */}
                        {t("blogs.readMore")}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          <Box className={classes.blogsBelowSection}>
            <Box
              className={classes.previousNextContainer}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <p
                style={{
                  color: "rgba(36, 39, 42, 0.56)",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {" "}
                {t("blogs.showing")} {(currentPage - 1) * entriesPerPage + 1}{" "}
                {t("blogs.to")}{" "}
                {Math.min(currentPage * entriesPerPage, totalBlogs)}{" "}
                {t("blogs.of")} {totalBlogs} {t("blogs.entries")}
              </p>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={handlePrevPage}
                  className={
                    currentPage === 1 ? "button-disabled" : "button-active"
                  }
                >
                  {t("blogs.previous")}
                </div>

                {Array.from({ length: totalPages }, (_, index) => {
                  if (
                    index + 1 === currentPage ||
                    index + 1 === 1 ||
                    index + 1 === totalPages ||
                    (index + 1 >= currentPage - 1 &&
                      index + 1 <= currentPage + 1)
                  ) {
                    return (
                      <div
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={
                          index + 1 === currentPage
                            ? "page-number-active-background"
                            : "page-number-disabled-background"
                        }
                      >
                        {index + 1}
                      </div>
                    );
                  } else if (index + 1 === currentPage + 2) {
                    return <span key={index}>...</span>;
                  }
                  return null;
                })}

                <div
                  onClick={handleNextPage}
                  className={
                    currentPage === totalPages
                      ? "button-disabled"
                      : "button-active"
                  }
                >
                  {t("blogs.next")}
                </div>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#24272A",
                      fontfamily: "Inter",
                      fontSize: "14px",
                      fontstyle: "normal",
                      fontweight: 400,
                      marginTop: "3px",
                    }}
                  >
                    {t("blogs.goToPage")}
                  </Typography>
                  <input
                    className="page-input"
                    onChange={(e) => setInputPage(parseInt(e.target.value))}
                  />
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    className="go-button"
                    onClick={handleGoButton}
                  >
                    {localStorage.getItem('language') == "AR" && (
                      <ReactSVG src={ArrowRightGrey} />
                    )}
                    {t("blogs.go")}
                    {getLocale.language != "AR" && (
                      <ReactSVG src={ArrowRightGrey} />
                    )}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
export default Blogs;
