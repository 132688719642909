import { React, useState } from "react";
import closeIcon from "../../../../../assets/images/x-close.svg";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CancellationCharges from "./cancellation-charges";
import "./styles.css";
import ReissueCharges from "./resissue-charges";
import useStyles from "./style";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../core/eventBus";
import { useTranslation } from "react-i18next";

const CustomTab = styled(Tab)({
  textTransform: "capitalize",
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FairRulePopUp = ({ handleModalClose, flight }) => {
  const [value, setValue] = useState(0);
  const styles = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMobileClose = () => {
    eventBus.emit("close-bottomSheet");
  };

  return (
    <>
      <div>
        <div
          className="fair-rule-popup-heading-div"
          style={{ padding: "20px" }}
        >
          <div className={styles.heading}>
            {t("fairRulePopUp.cancelAndChanges")}
          </div>
          <img
            src={closeIcon}
            onClick={isMobile ? handleMobileClose : handleModalClose}
            alt="close icon"
            style={{ cursor: "pointer" }}
          ></img>
        </div>
        <div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              sx={{ width: "90%", marginLeft: "20px" }}
              onChange={handleChange}
              className="tab-layout"
              textc
              textColor="black"
              TabIndicatorProps={{ style: { backgroundColor: "#1b1d52" } }}
            >
              <CustomTab
                label={t("fairRulePopUp.cancellationCharges")}
                {...a11yProps(0)}
                sx={{
                  color: "#000",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  backgroundColor: value === 0 ? "#E6E6FA" : "initial",
                }}
              />
              <CustomTab
                label={t("fairRulePopUp.dateChangesCharges")}
                {...a11yProps(1)}
                sx={{
                  color: "#000",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  backgroundColor: value === 1 ? "#E6E6FA" : "initial",
                }}
              />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <CancellationCharges flight={flight} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ReissueCharges flight={flight} />
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </>
  );
};

export default FairRulePopUp;

FairRulePopUp.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  flight: PropTypes.object,
};
