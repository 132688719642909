import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelDetails: {
    padding: "0px 150px 0px 150px",
    backgroundColor: theme.palette.entityColors.entityColorBackground2,
  },
  hotelDetailsBody: {
    display: "grid",
    gridTemplateColumns: "repeat(2,minmax(200px, 1fr))",
    gridTemplateRows:
      "minmax(200px, 1fr) minmax(200px, 1fr)  minmax(300px, 2fr)  minmax(300px, 1.5fr)",
    gap: "16px",
    gridTemplateAreas: `
    "info map"
    "amenities dummy"
    "about about"
    "roomList roomList"
    `,
  },
  hotelDetailsBodyInfo: {
    gridArea: "info",
  },
  hotelDetailsBodyMap: {
    gridArea: "map",
  },
  hotelDetailsBodyAmenities: {
    gridArea: "amenities",
  },
  hotelDetailsBodyAbout: {
    gridArea: "about",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    padding: "24px 16px",
    borderRadius: "8px",
  },
  hotelDetailsBodyRoomsList: {
    gridArea: "roomList",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));

export default useStyles;
