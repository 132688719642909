import React from "react";
import { chunk, get, isEmpty, map, split } from "lodash";
import useStyles from "./styles";
import eventBus from "../../../core/eventBus";
import { Close } from "@mui/icons-material";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";
export const AmenitiesDialogView = (props) => {
  const styles = useStyles();
  const isMobile = useIsMobile();

  const amenities = get(props, "allAmenities", []);
  const setAmenitiesModal = get(props, "setAmenitiesModal", function () {});

  return (
    <div
      className={styles.hotelAmenitiesDialog}
      style={{
        width: isMobile ? "300px" : "auto",
      }}
    >
      <div className={styles.hotelAmenitiesDialogHeader}>
        <p className={styles.hotelAmenitiesDialogHeaderTitle}>Amenities</p>
        <div
          className={styles.hotelAmenitiesDialogHeaderClose}
          // onClick={() => eventBus.emit("close-dialog")}
          onClick={() => setAmenitiesModal(false)}
        >
          <Close style={{ fontSize: "20px" }} />
        </div>
      </div>
      {!isEmpty(amenities) && (
        <div className={styles.hotelAmenitiesDialogBody}>
          <ul style={{ paddingLeft: "10px" }}>
            {map(amenities, (amenity) => (
              <li
                className={styles.hotelAmenitiesDialogBodyValue}
                key={amenity}
                style={{ marginBottom: "5px", zIndex: "-1" }}
              >
                {amenity}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const HotelDetailsAmenitiesView = (props) => {
  let amenities = get(props, "amenities", []);
  let setAmenitiesModal = get(props, "setAmenitiesModal", function () {});
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  if (amenities.length === 1) {
    amenities = split(amenities[0], ",");
  }
  const amenitiesLength = amenities.length;
  let amenitiesCount = 0;
  // const [amenitiesList1, amenitiesList2, amenitiesList3, amenitiesList4] =
  //   chunk(amenities, 4);
  const [amenitiesList1, amenitiesList2, amenitiesList3] = chunk(amenities, 4);
  const styles = useStyles();

  const viewAllAmenities = () => {
    const jsxElement = (
      <div style={{ maxHeight: "80vh", width: !isMobile ? "500px" : "auto" }}>
        {" "}
        <AmenitiesDialogView allAmenities={amenities} />{" "}
      </div>
    );

    // eventBus.emit("open-dialog", jsxElement);
    setAmenitiesModal(true);
  };

  return (
    <>
      <div className={styles.hotelAmenities} style={{ zIndex: "-10" }}>
        <h1 className={styles.hotelAmenitiesTitle}>
          {t("hotelDetails.amenities")}
        </h1>
        <div className={styles.hotelAmenitiesListsWrapper}>
          {!isMobile ? (
            <>
              {" "}
              <ul
                className={styles.hotelAmenitiesList}
                style={{
                  listStyle: "disc",
                  padding: "10px",
                }}
              >
                {map(amenitiesList1, (value) => {
                  amenitiesCount++;
                  return (
                    <li key={value} className={styles.hotelAmenitiesListItem}>
                      {value}
                    </li>
                  );
                })}
              </ul>
              <ul
                className={styles.hotelAmenitiesList}
                style={{
                  listStyle: "disc",
                  padding: "10px",
                }}
              >
                {map(amenitiesList2, (value) => {
                  amenitiesCount++;
                  return (
                    <li key={value} className={styles.hotelAmenitiesListItem}>
                      {value}
                    </li>
                  );
                })}
              </ul>
              <ul
                className={styles.hotelAmenitiesList}
                style={{
                  listStyle: "disc",
                  padding: "10px",
                }}
              >
                {map(amenitiesList3, (value) => {
                  amenitiesCount++;
                  return (
                    <li key={value} className={styles.hotelAmenitiesListItem}>
                      {value}
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ul
                  className={styles.hotelAmenitiesList}
                  style={{
                    listStyle: isMobile ? "disc" : "none",
                  }}
                >
                  {map(amenitiesList1, (value) => {
                    amenitiesCount++;
                    return (
                      <li key={value} className={styles.hotelAmenitiesListItem}>
                        {value}
                      </li>
                    );
                  })}
                </ul>
                <ul
                  className={styles.hotelAmenitiesList}
                  style={{
                    listStyle: isMobile ? "disc" : "none",
                  }}
                >
                  {map(amenitiesList2, (value) => {
                    amenitiesCount++;
                    return (
                      <li key={value} className={styles.hotelAmenitiesListItem}>
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          )}

          {/* <ul className={styles.hotelAmenitiesList}>
            {map(amenitiesList4, (value) => {
              amenitiesCount++;
              return (
                <li key={value} className={styles.hotelAmenitiesListItem}>
                  {value}
                </li>
              );
            })}
          </ul> */}
        </div>
        <div
          className={styles.hotelAmenitiesViewMoreWrapper}
          // style={{ zIndex: "-10" }}
        >
          {amenitiesCount < amenitiesLength && (
            <button
              className={styles.hotelAmenitiesViewMore}
              onClick={viewAllAmenities}
              // style={{ zIndex: "1" }}
            >
              {t("hotelDetails.viewAllAmenities")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
