import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const PaymentDetails = ({ details }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    decodedResponse: { payment_method_type, amount },
  } = details;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.heading}>{t("myProfile.amountPaid")}</div>
      <div className={styles.feeContainer}>
        <p className={styles.feeContainerTitleMain}>{payment_method_type}</p>
        <p className={styles.feeContainerPriceMain}>
          {t("fareRuleCard.currencySymbol")}
          {parseInt(amount, 10).toLocaleString("en-us")}
        </p>
      </div>
    </div>
  );
};

export default PaymentDetails;
