import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import APIs from "../../../../../api";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { coupons } from "../../../../../config";

const CouponCode = ({
  couponData,
  coupon,
  setCoupon,
  calculateCouponAmount,
  hotelDetails,
  setIsCouponApplied,
  setSuccessMessage,
  traceId,
  totalFare,
}) => {
  const user = useSelector((state) => state.user);
  const {t} = useTranslation()
  function couponValidation(couponCode) {
    const body = {
      // traceId: traceId,
      coupon: couponCode,
      hotel: couponData.payload,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${user?.token}`,
      },
    };
    const apiUrl = user.token
      ? APIs.validateAuthCoupon
      : APIs.validateNoAuthCoupon;

    axios
      .post(apiUrl, body, config)
      .then((response) => {
        const valid = response?.data?.data?.valid;
        const coupon = response?.data?.data?.coupon;
        if (valid == true) {
          calculateCouponAmount(coupon);
        } else {
          toast.error(t("errorMessages.enterValidCoupon"));
        }
        setIsCouponApplied(valid);
        const successMessage = response?.data?.data?.coupon?.successMessage;
        setSuccessMessage(successMessage);
        toast.success(t("successMessages.couponAppliedSuccessfully"));
      })
      .catch((error) => {
        toast.error(t("errorMessages.enterValidCoupon"));
      });
  }

  if (couponData?.lowestAmountCoupon[0]?.couponCode) {
    const { _id, couponCode, ruleName } = couponData.lowestAmountCoupon[0];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          padding: "30px 0px 0px 0px",
        }}
        key={_id}
      >
        <Box sx={{ display: "inline-flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              color: "#344054",
              fontFamily: "Lato",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            {couponCode}
          </Typography>
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              cursor: "pointer",
              marginRight: "23px",
            }}
            onClick={() => {
              setCoupon(couponCode);
              // couponValidation(_id);
              couponValidation(couponCode);
              // calculateCouponAmount();
            }}
          >
            {/* Apply */}
            {t("coupon.apply")}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#667085",
            fontFamily: "Lato",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          {ruleName}
        </Typography>
      </Box>
    );
  }
};

export default CouponCode;
