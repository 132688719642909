import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bottomButtons: {
    display: "inline-flex",
    alignItems: "flex-start",
    alignSelf: "center",
    marginBottom: "20px",
    position: "fixed",
    bottom: "30px",
    left: "calc(50vW - 95px)",
    boxShadow: "0px 1px 48px 0px rgba(0, 0, 0, 0.24)",
    borderRadius: "24px",
  },
  bottomButtonsDRT: {
    display: "inline-flex",
    alignItems: "flex-start",
    alignSelf: "center",
    marginBottom: "20px",
    position: "fixed",
    bottom: "60px",
    left: "calc(50vW - 95px)",
    boxShadow: "0px 1px 48px 0px rgba(0, 0, 0, 0.24)",
    borderRadius: "24px",
  },
  filterLayout: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px 0px 0px 24px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },

  filterLayoutArabic: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "0px 24px 24px 0px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },

  sortLayout: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "0px 24px 24px 0px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },
  sortLayoutArabic: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px 0px 0px 24px",
    borderTop: "1px solid #08F",
    borderLeft: "1px solid #08F",
    borderBottom: "1px solid #08F",
    background: "#FBFDFF",
  },
  subContainer: {
    margin: "0px 150px",

    [theme.breakpoints.down("lg")]: {
      margin: "0px 20px",
    },

    [theme.breakpoints.down("md")]: {
      margin: "0px",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },

  container: {
    background: " #FAFAFA",
    height: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
