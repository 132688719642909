import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SelectedHotelDetails from "../../../components/hotel-module/cards/selected-hotel-details";
import SelectedHotelFareBreakdown from "../../../components/hotel-module/cards/selected-hotel-fare-breakdown";
import useStyles from "./styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Table } from "@mui/material";
import HotelStepper from "../../../components/hotel-module/stepper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HotelChangePrice from "../../../components/hotel-module/cards/change-price-modal";
import HotelChangeCancellationPolicy from "../../../components/hotel-module/cards/change-cancellation-policy-modal";
import HotelChangePolicy from "../../../components/hotel-module/cards/change-hotel-policy-modal";
import { toast } from "react-toastify";
import APIs from "../../../api";
import Coupon from "../../../components/hotel-module/cards/coupons";
import getMarkupDetails from "./getMarkup";
import calculateMarkup from "./calculateMarkupPrice";
import { useSelector } from "react-redux";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import HotelSessionExpired from "../../../components/hotel-module/cards/session-expired";
import eventBus from "../../../core/eventBus";
import CryptoJS from "crypto-js";
import { flightValue, hotelValue, moduleValue } from "../../../config";
import { useTranslation } from "react-i18next";
import EmailVerification from "../../../components/hotel-module/cards/Email-verification";
import CardSelection from "../../../components/flight-module/search-flight/components/cards/card-selection";
import FallBackScreen from "../../../components/home/fallback-screen";

function HotelPayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [fallBackScreen, setFallBackScreen] = useState(false);
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const hotelFiltersState = JSON.parse(searchParams.get("hotelFilters"));
  const user = useSelector((state) => state.user);
  const isMobile = useIsMobile();
  const [newCombinationPrice, setNewCombinationPrice] = useState("");
  const [showChangePriceModal, setShowChangePriceModal] = useState(false);
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [showChangeHotelPolicyModal, setShowChangeHotelPolicyModal] =
    useState(false);
  const [
    showChangeCancellationPolicyModal,
    setShowChangeCancellationPolicyModal,
  ] = useState(false);
  const [isPriceChanged, setIsPriceChanged] = useState(false);
  const [newRoomsDetails, setNewRoomsDetails] = useState([]);

  const [newCancellationPolicy, setNewCancellationPolicy] = useState("");
  const [newHotelPolicy, setNewHotelPolicy] = useState("");
  const [newHotelPolicyAccepted, setNewHotelPolicyAccepted] = useState(false);
  const [newCancellationPolicyAccepted, setNewCancellationPolicyAccepted] =
    useState(false);
  const [newHotelRoomsDetails, setNewHotelRoomsDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [convenienceFeeId, setConvenienceFeeId] = useState("");
  const [couponId, setCouponId] = useState("");
  const [markup, setMarkup] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [showVerificatonPopup, setShowEmailVerificationPopup] = useState(false);
  const classes = useStyles();
  const { state } = useLocation();
  const traceId = state?.TraceId;
  const hotelName = state?.hotelDetails?.HotelName;
  const starRating = state?.hotelDetails?.HotelRating;

  const {
    filterValues,
    hotelDetails,
    roomDetails,
    token,
    detailsFormState,
    contactDetailsValues,
    couponValue: appliedCouponValue,
    isCouponApplied: isCouponAppliedState,
    couponDetails: couponDetailsState,
    successMessage: successMessageState,
    roomsDetails,
    searchId,
    markupId,
  } = state;
  const couponData = JSON.parse(localStorage.getItem("couponData"));

  const [couponValue, setCouponValue] = useState(appliedCouponValue);
  const [couponDetails, setCouponDetails] = useState(couponDetailsState);
  const [isCouponApplied, setIsCouponApplied] = useState(isCouponAppliedState);
  const [successMessage, setSuccessMessage] = useState(successMessageState);
  let totalBaseFare = 0;

  async function getConvenienceFeeDetail() {
    try {
      const response = await fetch(`${APIs.convenienceFee}`, {
        method: "POST",
        body: JSON.stringify({
          searchId: searchId,
          hotelStarRating: starRating,
          hotelName: hotelName,
          transactionAmount: roomsDetails?.TotalFareRoundOff,
        }),
      });
      const data = await response.json();

      setConvenienceFeeId(data?.data._id);
      return data;
    } catch (err) {
      console.log("error in conveneince fee api ->", err);
    }
  }

  const openCardSelectionPopUP = (bookingId, bookingPayload) => {
    eventBus.emit(
      "open-dialog",
      <CardSelection
        amount={roomsDetails.TotalFare}
        firstName={detailsFormState.room1.adult1.firstName}
        lastName={detailsFormState.room1.adult1.lastName}
        email={contactDetailsValues.email}
        bookingId={bookingId}
        product="HOTEL"
        bookingPayload={bookingPayload}
        setFallBackScreen={setFallBackScreen}
        fallBackScreen={fallBackScreen}
        hotelCouponId = {couponValue.amount > 0 ? couponData.lowestAmountCoupon[0]._id : ""}
      />
    );
  };

  // console.log("HotelFirstName", detailsFormState.room1.adult1.firstName);
  async function calculateConvenienceFee() {
    try {
      const data = await getConvenienceFeeDetail();
      const { _id, amount, maxAmount, amountType, name, appliedOn } = data.data;
      if (amountType == "FLAT") {
        let amt;
        if (amount < maxAmount) {
          amt = amount * roomsDetails.Name.length;
        } else {
          amt = maxAmount * roomsDetails.Name.length;
        }
        setConvenienceFee(amt);
      } else {
        if (appliedOn == "BASEFARE") {
          const amt = Number(
            parseFloat((amount / 100) * roomsDetails.TotalBaseFare).toFixed(2)
          );
          if (maxAmount > amt) {
            setConvenienceFee(amt);
          } else {
            setConvenienceFee(maxAmount);
          }
        } else {
          const amt = Number(
            parseFloat((amount / 100) * roomsDetails.TotalFareRoundOff).toFixed(
              2
            )
          );
          // setConvenienceFee(amt);
          if (maxAmount > amt) {
            setConvenienceFee(amt);
          } else {
            setConvenienceFee(maxAmount);
          }
        }
      }
    } catch (err) {
      console.log("err ->", err);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const guestsRooms = Object.keys(detailsFormState);

  function calculateTotalPayableAmount() {
    if (convenienceFee && Number(parseFloat(couponValue.amount).toFixed(2))) {
      const totalAmount = Number(
        parseFloat(
          Number(parseFloat(roomsDetails.TotalFareRoundOff).toFixed(2)) +
            Number(parseFloat(convenienceFee).toFixed(2)) -
            Number(parseFloat(couponValue.amount).toFixed(2))
        ).toFixed(2)
      );
      return totalAmount;
    } else if (convenienceFee) {
      return Number(
        parseFloat(
          Number(parseFloat(roomsDetails.TotalFareRoundOff).toFixed(2)) +
            Number(parseFloat(convenienceFee).toFixed(2))
        ).toFixed(2)
      );
    } else if (Number(parseFloat(couponValue.amount).toFixed(2))) {
      return Number(
        parseFloat(
          Number(parseFloat(roomsDetails.TotalFareRoundOff).toFixed(2)) -
            Number(parseFloat(couponValue.amount).toFixed(2))
        ).toFixed(2)
      );
    } else {
      return Number(parseFloat(roomsDetails.TotalFareRoundOff).toFixed(2));
    }
  }

  const handleEncrypt = (payloadData) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(payloadData),
      CryptoJS.enc.Utf8.parse(moduleValue + flightValue + hotelValue + "e3n"),
      {
        mode: CryptoJS.mode.ECB,
      }
    ).toString();
    return encryptedData;
  };

  const makePayment = async () => {
    try {
      setPaymentButtonDisabled(true);
      const payloadData = {
        convenienceFee: convenienceFeeId,
        convenienceFeeAmount: convenienceFee,
        totalPayableAmount: calculateTotalPayableAmount(),
        markup: markupId,
        markupPrice: roomsDetails.markupAmount,
        coupon:
          couponValue.amount > 0 ? couponData.lowestAmountCoupon[0]._id : "",
        couponDiscount: couponValue?.amount > 0 ? couponValue.amount : 0,
        taxAmount: roomsDetails.TotalTaxRoundOff,
        customerDetails: filterValues.roomGuests.map((room, rootIndex) => {
          const guests = detailsFormState[`room${rootIndex + 1}`];
          const guestsKeys = Object.keys(guests);
          return {
            CustomerNames: guestsKeys.map((key, index) => {
              return {
                Title: key.startsWith("children")
                  ? detailsFormState[`room${rootIndex + 1}`][key].title
                  : detailsFormState[`room${rootIndex + 1}`][key].courtesyTitle,
                FirstName:
                  detailsFormState[`room${rootIndex + 1}`][key].firstName,
                LastName:
                  detailsFormState[`room${rootIndex + 1}`][key].lastName,
                Type: key.startsWith("children") ? "Child" : "Adult",
              };
            }),
          };
        }),
        bookingCode: roomsDetails.BookingCode,
        totalFare: roomsDetails.TotalFare,
        emailId: contactDetailsValues.email,
        phoneNumber: contactDetailsValues.mobileNumber,
        bookingType: "Voucher",
        searchId: searchId,
        totalBaseFare: roomsDetails.TotalBaseFare,
      };
      const bookingPayload = {
        bookingCode: payloadData.bookingCode,
        customerDetails: payloadData.customerDetails,
        totalFare: payloadData.totalFare,
        totalBaseFare: payloadData.totalBaseFare,
        emailId: payloadData.emailId,
        phoneNumber: payloadData.phoneNumber,
        bookingType: "LIMIT",
      };
      console.log("payload", payloadData);
      const encryptedData = handleEncrypt(payloadData);

      handleOpen();
      const response = await fetch(`${APIs.blockRooms}`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ blockRequest: encryptedData }),
      });

      const data = await response.json();
      const booking = data?.data?._id;
      bookingPayload.booking = booking;
      if (data.statusCode == 200) {
        openCardSelectionPopUP(booking, bookingPayload);
        handleClose();
        setPaymentButtonDisabled(false);

        // Booking API
        // const bookingResponse = await fetch(`${APIs.bookHotel}`, {
        //   method: "POST",
        //   headers: {
        //     Authorization: token,
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(bookingPayload),
        // });
        // const bookingResponseData = await bookingResponse.json();
        // redirect success
        // if (
        //   bookingResponseData.statusCode == 200 &&
        //   bookingResponseData?.data?.responseData?.ClientReferenceId
        // ) {
        //   setPaymentButtonDisabled(false);
        //   const id = bookingResponseData?.data?._id;
        //   const ConfirmationNumber =
        //     bookingResponseData?.data?.responseData?.ConfirmationNumber;
        //   navigate(
        //     `/hotel/booking-details/?id=${id}&cn=${ConfirmationNumber}&status=${"SUCCESS"}`
        //   );
        // } else {
        //   toast.error(bookingResponseData.message);
        //   handleClose();
        //   setPaymentButtonDisabled(false);
        // }
      } else if (data.statusCode == 402) {
        setShowEmailVerificationPopup(true);
        setPaymentButtonDisabled(false);
        handleClose();
      } else {
        // redirect fail
        setPaymentButtonDisabled(false);
        const id = data?.data?._id;
        navigate(`/hotel/booking-details/?id=${id}&status=${"FAILED"}`);
      }
      if (data?.data?.statusCode == 400) {
        setSessionExpired(true);
        setStatusMessage(data?.data?.customMessage);
        setPaymentButtonDisabled(false);
      }
    } catch (err) {
      setPaymentButtonDisabled(false);
      handleClose();
      const response = err?.response?.data;
      const statusCode = response?.statusCode;
      const message = response?.message;
      const customMessage = response?.customMessage;

      if (statusCode == 401) {
        handleUnauthorisedLogout(message);
        dispatch(setUserInfo({}));
        navigate("/");
      }

      if (statusCode == 400) {
        setSessionExpired(true);
        setStatusMessage(customMessage);
      }

      // toast.error(`${"errorMessages.paymentError"}, ${err.message}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      toast.error(t("errorMessages.paymentInitiationFailed"))
    }
  };

  useEffect(() => {
    calculateConvenienceFee();
  }, []);

  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, [location]);

  return (
    <div className={classes.mainContainer}>
      {fallBackScreen ? (
        <FallBackScreen />
      ) : sessionExpired ? (
        <HotelSessionExpired statusMessage={statusMessage} />
      ) : (
        <>
          <HotelStepper activeStep={2} />

          <h2 className={classes.mainHeading}>
            {t("paymentReviewScreen.completeBooking")}
          </h2>
          <div className={classes.hotelDetailsAndFareBreakdownContainer}>
            <div className={classes.hotelDetails}>
              <SelectedHotelDetails
                details={{
                  hotelDetails,
                  filterValues,
                  roomDetails,
                  hotelFiltersState,
                }}
              />
              <div className={classes.margin20}>
                <div className={classes.guestDetailsContainer}>
                  <p className={classes.guestDetailText}>
                    {t("paymentReviewScreen.guestDetail")}
                  </p>
                </div>
                <div style={{ overflowX: "auto" }}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeaderCell}>
                          {t("paymentReviewScreen.guestName")}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableHeaderCell}
                        >
                          {t("paymentReviewScreen.ageGroup")}
                        </TableCell>
                        <TableCell
                          className={classes.tableHeaderCell}
                          align="left"
                        >
                          {t("paymentReviewScreen.email")}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableHeaderCell}
                        >
                          {t("paymentReviewScreen.phoneNo")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {guestsRooms &&
                        guestsRooms?.map((room) => {
                          const guests = Object.keys(detailsFormState[room]);

                          return guests?.map((guest) => {
                            const { firstName, lastName, age } =
                              detailsFormState[room][guest];

                            let ageGroup;
                            let PAN;
                            let Title;
                            let email = contactDetailsValues?.email;
                            let phoneno = contactDetailsValues?.mobileNumber;

                            if (
                              "courtesyTitle" in detailsFormState[room][guest]
                            ) {
                              ageGroup = "Adult";
                              Title =
                                detailsFormState[room][guest].courtesyTitle;
                              PAN = detailsFormState[room][guest].panCard;
                            } else {
                              ageGroup = "Children";
                              PAN = detailsFormState[room][guest].PAN;
                              Title = detailsFormState[room][guest].title;
                              if (Title == "Mr.") {
                                Title = "Master";
                              } else {
                                Title = "Miss";
                              }
                            }
                            // {Title}{"."}
                            return (
                              <TableRow className={classes.tableRow}>
                                <TableCell align="left">
                                  {firstName} {lastName}
                                </TableCell>
                                <TableCell align="left">{ageGroup}</TableCell>
                                <TableCell
                                  className={classes.tableDataCell}
                                  align="left"
                                >
                                  {email}
                                </TableCell>
                                <TableCell
                                  className={classes.tableDataCell}
                                  align="left"
                                >
                                  {phoneno}
                                </TableCell>
                              </TableRow>
                            );
                          });
                        })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <div className={classes.fareBrekdown}>
              <SelectedHotelFareBreakdown
                details={{
                  hotelDetails,
                  filterValues,
                  roomDetails,
                  convenienceFee: convenienceFee,
                  couponValue,
                  roomsDetails,
                }}
              />
              <div style={{ marginTop: "20px" }}>
                <Coupon
                  // combinationRoomsPrices={combinationRoomsPrices}
                  hotelDetails={hotelDetails}
                  couponValue={couponValue}
                  setCouponValue={setCouponValue}
                  setCouponId={setCouponId}
                  traceId={traceId}
                  isCouponApplied={isCouponApplied}
                  setIsCouponApplied={setIsCouponApplied}
                  couponDetails={couponDetails}
                  setCouponDetails={setCouponDetails}
                  successMessage={successMessage}
                  setSuccessMessage={setSuccessMessage}
                  setSessionExpired={setSessionExpired}
                  roomsDetails={roomsDetails}
                />
              </div>

              <EmailVerification
                showVerificatonPopup={showVerificatonPopup}
                setShowEmailVerificationPopup={setShowEmailVerificationPopup}
              />
            </div>
          </div>
          <div className={classes.finalPaymentContainer}>
            <h3 className={classes.finalPaymentTotalPrice}>
              {t("paymentReviewScreen.continueToFinalPayment")}
            </h3>
            <p
              className={
                paymentButtonDisabled
                  ? classes.payNowButtonDisabled
                  : classes.payNowButton
              }
              onClick={() => {
                if (!paymentButtonDisabled) makePayment();
              }}
            >
              <p className={classes.payNowText}>
                {" "}
                {t("paymentReviewScreen.payNow")}
              </p>
            </p>
          </div>
          <Backdrop
            sx={{
              color: "#1B1D52",
              zIndex: "2",
            }}
            open={open}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </div>
  );
}

export default HotelPayment;
