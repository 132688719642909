import React from "react";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import ProfileSvg from "../../../../assets/images/profile-circle-blue.svg";
import { setLoginPopup } from "../../../../utils/slices/popupSlice";
import useStyles from "./style";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../core/eventBus";
import { MobileOtpEnter } from "../../../header/components/mobile-otp-enter";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AlreadyMember = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation();
  return (
    <div className={styles.alreadyMemberMainContainer}>
      <div className={styles.alreadyMemberInnerContainer}>
        <ReactSVG src={ProfileSvg} />
        <div className={styles.alreadyMemberTextDiv}>
          <p className={styles.alreadyMemberText1}>
            {t("alreadyMember.alreadyMemberQuestion")}
          </p>
          <p className={styles.alreadyMemberText2}>
            {t("alreadyMember.signInAndSpeedUp")}
          </p>
        </div>
      </div>
      <div
        className={styles.signInDiv}
        onClick={() =>
          isMobile
            ?
            // eventBus.emit("open-dialog", <MobileOtpEnter />)
            navigate('/mobile/login', {
              state: {
                redirection: location.pathname + location.search
              }
            })
            : dispatch(setLoginPopup(true))
        }
      >
        {t("alreadyMember.signIn")}
      </div>
    </div>
  );
};
export default AlreadyMember;
