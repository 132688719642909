import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  flightSelection: {
    height: "55px",
    width: "100%",
    borderRadius: "8px 8px 0px 0px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    display: "flex",
    cursor: "pointer",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      borderRadius: "8px",
      border: "1px solid #D0D5DD",
    },
  },

  flightSelectionOptions: {
    position: "absolute",
    height: "227px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    marginTop: "55px",
    overflow: "hidden",
    zIndex: "101",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    backgroundColor: "#fff",
    cursor: "pointer",
    padding: "16px 16px",
    width: "100%",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      position: "static",
      margin: "0px",
      height: "fit-content",
    },
  },

  flightClassOption: {
    color: "#344054",
    display: "flex",
    fontSize: "14px",
    // height: "33px",
    width: "100%",
    padding: "8px",
    borderRadius: "4px",

    "&:hover": {
      background: "#E6E6FA", 
      color: "#4037acd6"
    },
  },
}));

export default useStyles;
