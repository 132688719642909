import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  roomText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  conveniencefeetext: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  roomPrice: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  roomPriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  baseFarePriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  baseFareText: {
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFare: {
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxFeeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  taxFeesText: {
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxFee: {
    color: "#667085",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  totalPriceText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  totalPrice: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
  },
  fareBreakdownText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  fareBreakdownAndPolicyContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  cancellationPolicyText: {
    color: "#4037acd6",
    cursor: "pointer",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "Lato, sans-serif",
    fontWeight: "400",
    lineHeight: "normal",
  },
  cancellationTable:{
    width: "300px",
    [theme.breakpoints.down('sm')]:{
      width:'300px'
    }
  },
  cancellationTooltip:{
    [theme.breakpoints.down('sm')]:{
      marginLeft:'58px'
    }
  }
}));

export default useStyles;
