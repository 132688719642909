import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  faredetailsOne: {
    position: "fixed",
    marginTop: "-150px",
    background: "#E6E6FA",
    boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.14)",
    width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   width: "97%",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  fareDetailsMore: {
    position: "fixed",
    marginTop: "-380px",
    background: "#E6E6FA",
    boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.14)",
    width: "100%",

    // [theme.breakpoints.down("md")]: {
    //   width: "97%",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

export default useStyles;
