import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100vw",
    height: "60px",
    display: "flex",
    position: "fixed",
    top: "0px",
    zIndex: 33,
    backgroundColor: "#E6E6FA",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 160px",
    [theme.breakpoints.down("lg")]: {
      padding: "8px 50px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px 16px",
    },
  },
  headerLeftSection: {
    display: "flex",
    columnGap: "24px",
    alignItems: "center",
  },
  headerLogo: {},
  headerRightSection: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
  },
  headerCustomerSupportAction: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
    cursor: "pointer",

    "&:hover": {
      textDecoration: "none",
    },
  },
  headerFAQAction: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
    cursor: "pointer",

    "&:hover": {
      textDecoration: "none",
    },
  },
  headerCountryDropdown: {
    display: "flex",
    padding: "8px",
    borderRadius: "8px",
    columnGap: "6px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
    cursor: "pointer",
  },
  headerCountryDropdownFlag: {
    height: "10px",
    width: "16px",
  },
  selectBackgrundColor: {
    backgroundColor: "#E6E6FA",
    borderColor: "#E6E6FA",
    fontSize: '14px'
  },
  headerCountryDropdownDivider: {
    width: "1px",
    height: "18px",
    backgroundColor: theme.palette.entityColors.entityColorBorder,
  },
  headerLoginSignupAction: {
    padding: "8px",
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },
  headerLoginSignupActionLabel: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
    cursor: "pointer",
  },
  headerParent: {
    height: "66px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  loginButton: {
    display: "flex",
    padding: "8px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #1b1d52",
    background: "#fff",
  },

  loginButtonText: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  headerItems: {
    height: "36px",
    marginTop: "15px",
    display: "inline-flex",
    width: "35%",
    gap: "16px",
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginRight: "30px",
  },
  logoAndMenuIcon: {
    display: "flex",
    marginLeft: "30px",
    marginTop: "8px",
    marginBottom: "13px",
    alignItems: "center",
  },
  // headerLogo: {
  //   cursor: "pointer",
  //   marginLeft: "30px",
  // },
  menuIcon: {
    cursor: "pointer",
  },
  logoutPopup: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute !important",
      top: "0 !important",
      right: "0 !important",
    },
  },

  countryLayout: {
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#eaeaea",
    // },
  },

  countrySelection: {
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },

  countryName: {
    fontSize: "12px",
    fontFamily: "sans-serif",
  },
  selectCountries: {
    display: "flex",
    gap: "8px",
  },

  countries: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },

  languages: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  selectLanguage: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    flexDirection: "column",
    padding: "10px 10px 4px 10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "#fff",
    border: "#fff",
  },
}));

export default useStyles;
