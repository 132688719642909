import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  offer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    [theme.breakpoints.down("sm")]: {
      rowGap: "16px",
    },
  },
  title: {
    height: "31px",
    color: "#344054",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  viewAllOffers: {
    color: "#4037acd6",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
    alignSelf: "center",
    cursor: "pointer",
    // zIndex: 101,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  offerText: {
    color: " #1B1D52",
  },
  headerLeftSection: {
    display: "flex",
    rowGap: "8px",
    justifyContent: "space-between",
  },

  info: {
    display: "flex",
    columnGap: "32px",
    flexDirection: "column",
    // padding: "0px 54px",
    // [theme.breakpoints.down("sm")]: {
    //   padding: "0px 34px",
    // },
  },

  subHeading: {
    color: "#525A6A",
    fontSize: "18px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "-40px",
    },
  },
  cardContainer: {
    scrollbarWidth: "none",

    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      marginTop: "20px",

      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
      overflowY: "hidden",
    },
  },

  productType: {
    display: "inline-flex",
    padding: "6px 10px",
    alignItems: "flex-start",
    gap: "5px",
    background: "#E6E6FA",
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  offerDate: {
    color: " #525A6A",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
  },

  cardScroll: {
    display: "flex",
    gap: "10px",
    marginTop: "12px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "300px",
    // },
  },
  card: {
    margin: "10px",
    width: "100%",
    // minWidth: "360px",

    // width: "calc(100% - 10px)",
    // width: "370px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "300px",
    // },
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
    // margin: "10px",
  },

  cardDummy: {
    margin: "10px",
    flex: 1,

    // minWidth: "360px",

    // width: "calc(100% - 10px)",
    // width: "370px",
    // [theme.breakpoints.down("sm")]: {
    //   width: "300px",
    // },

    [theme.breakpoints.up("md")]: {
      margin: "50px 0px",
    },

    [theme.breakpoints.down("lg")]: {
      width: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
  },

  cardImage: {
    height: "193px",
    borderRadius: "5px 5px 0px 0px",
    background: "#D9D9D9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    maxWidth: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   width: "300px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "200px",
    // },
  },
  cardDescription: {
    display: "flex",
    padding: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "0px 0px 5px 5px",
    borderRight: " 1px solid #D0D5DD",
    borderBottom: "1px solid #D0D5DD",
    borderLeft: "1px solid #D0D5DD",
    background: "#FFF",
    // width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   width: "300px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "200px",
    // },
  },

  cardDescriptionLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // gap: "32px",
    // width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   width: "280px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "180px",
    // },
  },
  cardContent: {
    height: "137px",
  },

  cardDate: {
    // width: "338px",
    height: "26px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.down("sm")]: {
    //   width: "260px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "180px",
    // },
  },

  nameAndDescription: {
    marginTop: "10px",
  },

  couponCodeLayout: {
    // width: "338px",
    // padding: "4px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // [theme.breakpoints.down("sm")]: {
    //   width: "260px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "260px",
    // },
  },

  couponCode: {
    display: "inline-flex",
    padding: "6px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    border: "1px dashed  #1b1d52",
    background: " #E6E6FA",
  },

  offersName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  offersDescription: {
    color: " #525A6A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    marginTop: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },

  cuponCodeLayout: {
    color: "#1b1d52",
    fontSize: "16px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "4px 6px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  dashedLine: {
    width: "1px",
    height: "24px",
    background: " #1b1d52",
  },

  viewDetails: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    cursor: "pointer",
  },

  backArrowOffer: {
    marginTop: "240px",
    position: "absolute",
    zIndex: "100",
    width: "52px",
    height: "52px",
    borderRadius: "52px",
    backgroundColor: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
    marginLeft: "-50px",
    transform: "translateY(-50%)",
    opacity: " 0",
    transition: "opacity 0.3s, margin-left 1.5s",
  },

  backArrowOfferIcon: {
    width: "34px",
    height: "34px",
    color: "#525A6A",
  },
  offerCards: {
    margin: "0 10px",
  },

  SingleCard: {
    width: "360px",
  },
}));

export default useStyles;
