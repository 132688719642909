import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectDepartureHeader: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  selectDepartureTitle: {
    color: "#525A6A",
    fontFamily: "'Lato', sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  selectReturnFlightContainer: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",

    // [theme.breakpoints.up("sm")]: {
    //   height: "42px",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "100vW",
      marginBottom: "2px",
      marginTop: "0px",
      padding: "0px 20px 2px 20px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },

  RoundTripContent1: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },

  RoundTripContent2: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    borderRadius: "24px",
    border: "1px solid #1b1d52",
    background: "#E6E6FA",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: "36px",
  },

  flightRoundTripText: {
    padding: "8px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "12px 12px",
    },
  },

  selectedFlightRoundTrip: {
    display: "inline-flex",
    justifyContent: "space-between",
    gap: "4px",
    marginBottom: "10px",
    alignItems: "center",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      height: "42px",
    },

    [theme.breakpoints.down("sm")]: {
      // width: "100vW",
      padding: "10px 8px 0px 8px",
      marginTop: "0px",
      marginBottom: "2px",
    },
  },

  roundTripMetaText: {
    color: "#525A6A",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default useStyles;
