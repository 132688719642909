import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import MailSvg from "../../../../assets/images/mail.svg";
import CircularCheckSvg from "../../../../assets/images/circular_check_grey.svg";
import useStyles from "./style";
import { useTranslation } from "react-i18next";

const muiStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },

    "& .MuiInputBase-root .MuiNativeSelect-select ": {
      paddingRight: localStorage.getItem('language') != "AR" ? "3px !important" : "32px !important",
    },
  },
}));

const customStyles = {
  borderRadius: "10px",
  padding: "10px 4px",
  width: "inherit",
};

const countryCode = [
  {
    value: "+966",
    label: "+966",
  },
  // {
  //   value: "+966",
  //   label: "+966",
  // },
];

const AddContactDetails = ({
  setContactDetailsValues,
  contactDetailsValues,
  emailError,
  setEmailError,
  mobileNumberError,
  setMobileNumberError,
  // addressError,
  // setAddressError,
}) => {
  const { email, mobileNumber, address, cellCountryCode } =
    contactDetailsValues;
  const classes = muiStyles();
  const styles = useStyles();
  const { t } = useTranslation();
  const handleChange = (e) => {
    const value = e.target.value;
    setContactDetailsValues({ ...contactDetailsValues, email: value });
    const emailRegex = /^[A-Z0-9._%+-\u0600-\u06FF]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!value) {
      setEmailError(`${t("addContactDetailsScreen.emailIsRequired")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        emailErrorExists: true,
        email: value,
      });
    } else if (!emailRegex.test(value)) {
      setEmailError(`${t("addContactDetailsScreen.enterValidEmail")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        emailErrorExists: true,
        email: value,
      });
    } else {
      setEmailError("");
      setContactDetailsValues({
        ...contactDetailsValues,
        emailErrorExists: false,
        email: value,
      });
    }
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    const mobileNumberRegex = /^5\d{8}$/;

    if (!value) {
      setMobileNumberError(
        `${t("addContactDetailsScreen.mobileNumberIsRequired")}`
      );
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: true,
        mobileNumber: value,
      });
    } else if (!mobileNumberRegex.test(value)) {
      setMobileNumberError(
        `${t("addContactDetailsScreen.enterValidNumber")}`
      );
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: true,
        mobileNumber: value,
      });
    } else if (value.length < 9 || value.length > 15) {
      setMobileNumberError(`${t("addContactDetailsScreen.enterValidNumber")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: true,
        mobileNumber: value,
      });
    } else {
      setMobileNumberError("");
      setContactDetailsValues({
        ...contactDetailsValues,
        mobileNumberErrorExists: false,
        mobileNumber: value,
      });
    }
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setContactDetailsValues({ ...contactDetailsValues, address: value });
    const regex = /^[a-zA-Z0-9\s,'-\u0600-\u06FF]*$/;


    if (!value) {
      setAddressError(`${t("addContactDetailsScreen.addressIsRequired")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        addressErrorExists: true,
        address: value,
      });
    } else if (!regex.test(value)) {
      setAddressError(`${t("addContactDetailsScreen.enterValidAddress")}`);
      setContactDetailsValues({
        ...contactDetailsValues,
        addressErrorExists: true,
        address: value,
      });
    } else {
      setAddressError("");
      setContactDetailsValues({
        ...contactDetailsValues,
        addressErrorExists: false,
        address: value,
      });
    }
  };

  return (
    <Box className={styles.addContactDetailsContainer}>
      <Box sx={{ display: "inline-flex", gap: "10px" }}>
        <ReactSVG src={CircularCheckSvg} style={{ marginTop: "4px" }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Typography
            sx={{
              color: "#344054",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            {t("addContactDetailsScreen.addContactDetails")}
          </Typography>
          <Typography
            sx={{
              color: "#525A6A",
              fontFamily: "Lato",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            {t("addContactDetailsScreen.eTicketNotification")}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.contentContainer}>
        <Box className={styles.contactsDetailsCard}>
          <Box className={styles.fieldContainer}>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "20px",
              }}
            >
              {t("addContactDetailsScreen.mobileNumber")}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
            <Box sx={{ display: "inline-flex", gap: "16px", width: "100%" }}>
              <TextField
                className={classes.root}
                // className={styles.countryCode}
                sx={{
                  minHeight: "0px !important",
                  width: "40% !important",
                }}
                size="small"
                select
                SelectProps={{ native: true }}
                value={cellCountryCode}
                InputLabelProps={{
                  style: { textAlign: "center", marginTop: "10px" },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    padding: "10px 4px",
                    width: "100%",
                  },
                }}
                onChange={(e) => {
                  setContactDetailsValues({
                    ...contactDetailsValues,
                    cellCountryCode: e.target.value,
                  });
                }}
              >
                {countryCode.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                ;
              </TextField>
              <TextField
                className={classes.root}
                sx={{
                  minHeight: "0px !important",
                  width: "inherit !important",
                }}
                size="small"
                placeholder={t("addContactDetailsScreen.mobileNumber")}
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                error={mobileNumberError !== ""}
                helperText={mobileNumberError}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    padding: "10px 4px",
                    width: "100%",
                  },
                }}
              ></TextField>
            </Box>
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "20px",
              }}
            >
              {t("addContactDetailsScreen.email")}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
            <TextField
              className={classes.root}
              sx={{ minHeight: "0px !important", width: "100% !important" }}
              size="small"
              placeholder={t("addContactDetailsScreen.enterYourEmailId")}
              value={email}
              onChange={handleChange}
              error={emailError !== ""}
              helperText={emailError}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ padding: "4px 4px 0px 8px" }}
                  >
                    <ReactSVG src={MailSvg} />
                  </InputAdornment>
                ),
                style: customStyles,
              }}
            ></TextField>
          </Box>
        </Box>
        {/* <Box className={styles.fieldContainer2}>
          <Typography
            sx={{
              color: "#344054",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "20px",
            }}
          >
            Address<span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
          <TextField
            className={classes.root}
            sx={{ minHeight: "0px !important", width: "100% !important" }}
            size="small"
            placeholder="Enter Your Address"
            value={address}
            onChange={handleAddressChange}
            error={addressError !== ""}
            helperText={addressError}
            InputProps={{
              style: customStyles,
            }}
          ></TextField>
        </Box> */}
      </Box>
    </Box>
  );
};
export default AddContactDetails;
