import { createSlice } from "@reduxjs/toolkit";

const popupSlice = createSlice({
  name: "popup",
  initialState: {
    login: false,
    register: false,
    otp: false,
    signup: false,
    emailConfirmation: false,
    redirectUrl: 1,
    redirectScreen: 1,
  },
  reducers: {
    setLoginPopup: (state, action) => {
      state.login = action.payload;
    },
    setOtpPopup: (state, action) => {
      state.otp = action.payload;
    },
    setSignupPopup: (state, action) => {
      state.signup = action.payload;
    },
    handleRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    handleRedirectScreen: (state, action) => {
      state.redirectScreen = action.payload;
    },

    setEmailConfirmation: (state, action) => {
      state.emailConfirmation = action.payload;
    },
  },
});

export const {
  setLoginPopup,
  setOtpPopup,
  setSignupPopup,
  handleRedirectUrl,
  handleRedirectScreen,
  setEmailConfirmation,
} = popupSlice.actions;

export default popupSlice.reducer;
