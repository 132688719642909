import React from "react";
import useStyles from "./styles";
import { Rings } from "react-loader-spinner";

const LoaderComp = () => {
  const styles = useStyles();
  return (
    <div className={styles.loaderWrapper}>
      <Rings
        height="150"
        width="150"
        color="#1B1D52"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};

export default LoaderComp;
