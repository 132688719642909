import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ScrollToTop from "./components/scroll-to-top";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { handleIPAddress } from "./utils/slices/globalSlice";
import { map } from "lodash";
import { routes } from "./configs/router.config";
import { DialogConnector } from "./ui/components/DialogConnector";
import { BottomSheetConnector } from "./ui/components/BottomSheetConnector";
import { ErrorBoundary } from "./error-boundary";
import FallBackScreen from "./components/home/fallback-screen";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState("");
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, [i18n, i18n.language]);

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        dispatch(handleIPAddress(response.data.ip));
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, []);

  useEffect(() => {
    const environment = process.env.NODE_ENV;
    if (environment != "development") {
      const preventContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

      document.addEventListener("contextmenu", preventContextMenu, false);

      // Cleanup function
      return () => {
        document.removeEventListener("contextmenu", preventContextMenu, false);
      };
    }
  }, []);

  return (
    <ErrorBoundary fallback={<FallBackScreen />}>
      <div className="App">
        <ScrollToTop />
        <DialogConnector />
        <BottomSheetConnector />
        <ToastContainer />
        <Routes>
          {map(routes, ({ name, path, element: Element }) => (
            <Route key={name} path={path} element={<Element />} />
          ))}
        </Routes>
      </div>
    </ErrorBoundary>
  );
}

export default App;
