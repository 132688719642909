import { makeStyles } from "@mui/styles";
import bgImage from "../../../assets/images/map.png";

const useStyles = makeStyles((theme) => ({
  travelPerfection: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "60% 100%",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "100% 100%",
    },
    backgroundPosition: "center",
    padding: "40px 0px",
    zIndex: "2",

    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 0px)",
    },
  },

  findTravelPerfectionTitle: {
    color: "#344054",
    fontSize: "26px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },

  perfectionText: {
    color: "#4037acd6",
  },
  findTravelPerfectionSubHeading: {
    color: " #424855",
    textAlign: "center",
    fontSize: "18px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginRight: "-6px",
    },
  },

  iconLayout: {
    width: "42px",
    height: "42px",
    [theme.breakpoints.down("sm")]: {
      width: "30px",
      height: "30px",
    },
  },

  icon: {
    height: "64px",
    width: "64px",
    // borderRadius: "16px",
    // background: "#F0F8FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "45px",
      height: "45px",
      borderRadius: "12px",
    },
  },

  findTravelPerfectionCard: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },

  cardLayout: {
    // width: "274px",
  },

  cardDetails: {
    height: "158px",
    width: "230px",
    margin: "40px 25px 40px 15px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2px",
      width: "244px",
    },

    [theme.breakpoints.up("lg")]: {
      width: "270px",
    },
  },
  cardDetailsText: {
    marginTop: "16px",
    color: " #344054",
    fontSize: "16px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },

  cardDetailsInfo: {
    color: "#344054",
    fontSize: "14px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "8px",
    textAlign: "justify",
  },
}));

export default useStyles;
