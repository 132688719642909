import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileSearchWidget: {
    width: "100%",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    zIndex: "10",
    background: "yellow",
  },
  mobileSearchWidgetTabs: {
    display: "flex",
    width: "100%",
    height: "40px",
  },
  primaryTabInActive: {
    width: "50%",
    height: "100%",
    display: "flex",
    columnGap: "3px",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    boxSizing: "border-box",
  },
  primaryTabActive: {
    width: "50%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "3px",
    color: theme.palette.entityColors.entityColorPrimary,
    border: "none",
    borderBottom: `2px solid ${theme.palette.entityColors.entityColorPrimary}`,
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    boxSizing: "border-box",
  },

  mobileSearchWidgetSecondaryTabs: {
    display: "flex",
    height: "40px",
  },
  mobileSearchWidgetWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  secondaryTab1: {
    width: "50%",
    height: "100%",
    color: "#1b1d52",
    // borderRadius: " 5px 0px 0px 5px ",
    border: `1px solid ${theme.palette.entityColors.entityColorPrimary}`,
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },
  secondaryTab2: {
    width: "50%",
    height: "100%",
    padding: "12px 8px",
    // border: "none",
    color: "#1b1d52",
    // borderTop: `1px solid ${theme.palette.entityColors.entityColorPrimary}`,
    // borderBottom: `1px solid ${theme.palette.entityColors.entityColorPrimary}`,
    border: `1px solid ${theme.palette.entityColors.entityColorPrimary}`,
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },
  secondaryTab3: {
    width: "33%",
    height: "100%",
    color: "#1b1d52",
    borderRadius: "0px 5px 5px 0px",
    border: `1px solid ${theme.palette.entityColors.entityColorPrimary}`,
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },

  activeSecondaryTab: {
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    color: theme.palette.entityColors.entityColorBackground1,
  },

  datePicker: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "D0D5DD",
        borderRadius: "10px",
      },
    },
    width: "100%",
  },

  datePickerRoundTrip: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "D0D5DD",
        borderRadius: "10px",
      },
    },
    width: "155px",
  },
  searchWidgetMobile: {
    display: "flex",
    width: "100vW",
    padding: "16px 16px 24px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderRadius: "10px",
    background: "var(--Color, #FFF)",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
  },

  searchWidgetCard: {
    display: "flex",
    width: "350px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "48px",
  },
  searchType: {
    display: "inline-flex",
    alignItems: "flex-start",
  },
  searchTypeLayoutActive: {
    display: "flex",
    width: "161px",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "5px 5px 0px 0px",
    borderBottom: "2px solid #08F",
  },
  searchTypeLayout: {
    display: "flex",
    width: "161px",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "5px 5px 0px 0px",
  },
  tripType: {
    display: "inline-flex",
    alignItems: "flex-start",
    marginTop: "16px",
  },

  tripTypeLayout: {
    display: "flex",
    width: "175px",
    padding: "12px 8px",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "4px",
    borderRadius: "5px 0px 0px 5px",
  },
  tripTypeLayoutActive: {
    display: "flex",
    width: "175px",
    padding: "12px 8px",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "4px",
    borderRadius: "5px 0px 0px 5px",
    background: "#08F",
  },
  layoutOne: {
    gap: "16px",
  },

  layoutTwo: {
    gap: "16px",
  },

  button: {
    display: "flex",
    width: "330px",
    padding: "16px 0px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    background: "#08F",
    color: "#fff",
    border: "none",
  },
}));

export default useStyles;
