import Home from "../Pages/home";
import FlightSearch from "../Pages/flight-module/search-flight";
import FlightSeatDetails from "../Pages/flight-module/seat-details";
// import HotelSearch from "../Pages/hotel-module/search-hotel";
import Profile from "../components/header/components/my-profile";
import Bookings from "../components/header/components/my-bookings";
import FlightPersonalDetails from "../Pages/flight-module/personal-details";
import HotelPersonalDetails from "../Pages/hotel-module/personal-details";
import SearchPackages from "../components/home/packages/search-packages";
import Package from "../components/home/packages/package";
import Faq from "../components/home/faq";
import Blogs from "../components/home/recent-articles/blogs";
import MoreOffers from "../components/home/offers/more-offers";
import PrivacyPolicy from "../Auth/privacy-policy";
import TermsAndConditions from "../Auth/terms-and-conditions";
import OffersHtml from "../components/home/offers/more-offers/offers-html";
import Map from "../components/map";
import HotelDetails from "../Pages/hotel-view/index.jsx";
import { withGlobalFilters } from "../ui/hoc/withGlobalFilters";
import { withHeaderAndFooter } from "../ui/hoc/withHeaderAndFooter";
import { HotelSearchPage } from "../ui/pages/HotelSearch";
import { HotelDetailsPage } from "../ui/pages/HotelDetails";
import BookingConfirmation from "../Pages/flight-module/booking-confirmation";
import FinalDetails from "../Pages/flight-module/final-details";
import HotelPayment from "../Pages/hotel-module/Payment-screen";
import HotelBookingConfirmation from "../Pages/hotel-module/Booking-details";
import PageNotFoundPage from "../components/home/404";
import FlightBookingDetails from "../components/header/components/my-bookings/booking-list/flight/booking-details";
import HotelBookingStatus from "../components/header/components/my-bookings/booking-list/hotel/booking-details";
import BookFlightDetailsFlow from "../Pages/flight-module/book-flight-details-flow";
import AboutUs from "../components/home/aboutUs/index.js";
import ForgotPassword from "../components/home/forgot-password/index.js";
import Payment from "../components/common/Payment/index.js";
import PaymentLoadingScreen from "../components/common/Payment/payment-loading-page/index.js";
import VerifyEmail from "../Auth/verify-email/index.js";
// import { MobileOtpEnter } from "../components/header/components/mobile-otp-enter/index.jsx";
// import { MobileSubmitOtp } from "../components/header/components/mobile-submit-otp/index.jsx";
import MobileSignupPage from "../Auth/mobileSignupPage/index.js";
import MobileLoginPage from "../Auth/mobileLoginPage/index.js";
import MobileForgotPasswordPage from "../Auth/mobileForgotPasswordPage/index.js";

export const routes = [
  { path: "/", name: "Home", element: withHeaderAndFooter(Home) },
  {
    path: "/flight/search?",
    name: "Flight Search",
    element: withHeaderAndFooter(FlightSearch),
  },
  {
    path: "/flight/review-details/:traceId/:resultIndex/:isLcc/:totalTrips/:tripType/:adults/:children/:infants/:pf/:markupId/:cabinClass/:airlines/?",
    name: "Add Flight Details",
    element: withHeaderAndFooter(BookFlightDetailsFlow),
  },
  {
    path: "/flight/seat-details/:traceId/:resultIndex/:isLcc/:totalTrips/:tripType/:adults/:children/:infants",
    name: "Flight Seat Details",
    element: withHeaderAndFooter(FlightSeatDetails),
  },
  {
    path: "/flight/bookingConfirmation/:id/:status",
    name: "Flight Booking Confirmation",
    element: withHeaderAndFooter(BookingConfirmation),
  },
  {
    path: "/flight/final-details",
    name: "Flight Final Details",
    element: withHeaderAndFooter(FinalDetails),
  },
  {
    path: "/hotel/search",
    name: "Hotel Search",
    element: withHeaderAndFooter(withGlobalFilters(HotelSearchPage)),
  },
  {
    path: "/hotel/add-details/",
    name: "Add Hotel Details",
    element: withHeaderAndFooter(withGlobalFilters(HotelPersonalDetails)),
  },
  {
    path: "/packages/search",
    name: "Search Packages",
    element: withHeaderAndFooter(SearchPackages),
  },
  {
    path: "/package/:id",
    name: "Package Details",
    element: withHeaderAndFooter(Package),
  },
  { path: "/faq", name: "FAQ", element: withHeaderAndFooter(Faq) },
  { path: "/profile", name: "Profile", element: withHeaderAndFooter(Profile) },
  {
    path: "/bookings",
    name: "Bookings",
    element: withHeaderAndFooter(Bookings),
  },
  { path: "/blogs", name: "Blogs", element: withHeaderAndFooter(Blogs) },
  {
    path: "/offers",
    name: "More Offers",
    element: withHeaderAndFooter(MoreOffers),
  },
  {
    path: "/offers-details",
    name: "Offer Details",
    element: withHeaderAndFooter(OffersHtml),
  },
  { path: "/map", name: "Map", element: withHeaderAndFooter(Map) },
  {
    path: "/hotel-view",
    name: "Hotel Details",
    element: withHeaderAndFooter(HotelDetails),
  },
  {
    path: `/hotel/details/`,
    name: "Hotel Details",
    element: withHeaderAndFooter(withGlobalFilters(HotelDetailsPage)),
  },
  {
    path: "/hotel/payment",
    name: "Hotel Payment",
    element: withHeaderAndFooter(withGlobalFilters(HotelPayment)),
  },
  {
    // path: "/hotel/booking-details/:id/:status",
    path: "/hotel/booking-details",
    name: "Hotel Booking Details",
    element: withHeaderAndFooter(withGlobalFilters(HotelBookingConfirmation)),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    element: withHeaderAndFooter(PrivacyPolicy),
  },
  {
    path: "/terms",
    name: "Terms and Conditions",
    element: withHeaderAndFooter(TermsAndConditions),
  },
  {
    path: "/*",
    name: "Page Not Found",
    element: withHeaderAndFooter(PageNotFoundPage),
  },
  {
    path: "/flight/booking/:id/",
    name: "Flight Get Booking By Id",
    element: withHeaderAndFooter(FlightBookingDetails),
  },
  {
    path: "/hotel/booking/",
    name: "Hotel Get Booking By Id",
    element: withHeaderAndFooter(HotelBookingStatus),
  },
  {
    path: "/about-us",
    name: "About Us",
    element: withHeaderAndFooter(AboutUs),
  },

  {
    path: "/forgot-password/*",
    name: "Forgot password",
    element: withHeaderAndFooter(ForgotPassword),
  },

  {
    path: "/signup/:token",
    name: "signup password",
    element: withHeaderAndFooter(ForgotPassword),
  },
  {
    path: "/payment/:checkoutId",
    name: "Payment",
    element: withHeaderAndFooter(Payment),
  },
  {
    path: "/payment-processing/:checkoutId",
    name: "payment processing",
    element: withHeaderAndFooter(PaymentLoadingScreen),
  },
  {
    path: "/verify-email/*",
    name: "Verify-Email",
    element: withHeaderAndFooter(VerifyEmail)
  },
  {
    path: "/mobile/login",
    name: 'Login',
    element: withHeaderAndFooter(MobileLoginPage)
  },
  {
    path: "/mobile/signup",
    name: 'Signup',
    element: withHeaderAndFooter(MobileSignupPage)
  },
  {
    path: "/mobile/forgot-password",
    name: 'Forgot Password',
    element: withHeaderAndFooter(MobileForgotPasswordPage)
  }
];
