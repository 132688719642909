import { createSlice } from "@reduxjs/toolkit";

const rateOfExchangeSlice = createSlice({
  name: "rateOfExchange",
  initialState: {
    usdValue: [],
    sarValue: [],
    rate: 1,
  },
  reducers: {
    setRate: (state, action) => {
      state.rate = action.payload;
      localStorage.setItem("rateOfExchange", action.payload);
    },

    setUsdValue: (state, action) => {
      state.usdValue.push(action.payload);
      state.sarValue = state.usdValue.map((item) => ({
        ...item,
        TotalFare: item.TotalFare * state.rate,
        AgentMarkup: item.AgentMarkup * state.rate,
        OtherCharges: item.OtherCharges * state.rate,
        CreditCardCharge: item.CreditCardCharge * state.rate,
        ServiceFee: item.ServiceFee * state.rate,
        BaseFare: item.BaseFare * state.rate,
        Tax: item.Tax * state.rate,
        PenaltyAmount: item.PenaltyAmount * state.rate,
        VATAmount: item.VATAmount * state.rate,
        VATPercentage: item.VATPercentage,
      }));
    },

    setSarValue: (state, action) => {},
  },
});

export const { setRate, setUsdValue, setSarValue } =
  rateOfExchangeSlice.actions;

export default rateOfExchangeSlice.reducer;
