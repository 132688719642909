import React, { useEffect, useRef, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PropTypes from "prop-types";
import "./style.css";
import { Calendar } from "react-date-range";
import classNames from "classnames";
import { nanoid } from "nanoid";
import useStyles from "./style";
import * as locales from 'react-date-range/dist/locale';

const TravelDate = ({
  title,
  date = "",
  day = "",
  metaTitle,
  onDateChange,
  enableDateRange,
  onClick,
  minDate: minValue,
  setShowSourceOptions,
  setShowDestinationOptions,
  setShowFlightClassDropdown,
  setShowPassengersComponent,
  travelRef,
}) => {
  const uniqueId = nanoid();
  const styles = useStyles();
  const calendarWrapperClass = classNames("calender-wrapper", uniqueId);
  const calendarWrapperClassAbve = classNames(
    "calender-wrapper-above",
    uniqueId
  );
  const inputRef = useRef(null);
  const [calendarPosition, setCalendarPosition] = useState("bottom");

  const [dateState, setDateState] = useState({
    showCalander: false,
    uniqueId,
    dateValue: new Date(),
  });

  const toggleCalander = (value) => {
    const inputRect = inputRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - inputRect.bottom;

    if (spaceBelow < 300) {
      setCalendarPosition("top");
    } else {
      setCalendarPosition("bottom");
    }
    setDateState((prev) => ({ ...prev, showCalander: value }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const [calendarWrapperRef] =
        document.getElementsByClassName("calender-wrapper");

      if (calendarWrapperRef && !calendarWrapperRef?.contains(event.target)) {
        toggleCalander(false);
      }
    };

    if (dateState.showCalander) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateState.showCalander]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const [calendarWrapperRef] = document.getElementsByClassName(
        "calender-wrapper-above"
      );

      if (calendarWrapperRef && !calendarWrapperRef?.contains(event.target)) {
        toggleCalander(false);
      }
    };

    if (dateState.showCalander) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateState.showCalander]);

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.transform = "rotate(180deg)"
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      })
    }else{
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }
  });
  return (
    <div ref={inputRef}>
      <div
        ref={travelRef}
        className="travel-dates"
        onClick={(e) => {
          setShowSourceOptions([false, false, false]);
          setShowDestinationOptions([false, false, false]);
          setShowFlightClassDropdown(false);
          setShowPassengersComponent(false);
          if (!enableDateRange) {
            toggleCalander(true);
            // e.stopPropagation();
          } else {
            onClick();
          }
        }}
      >
        <div className="travel-date-content1">
          <div className="travel-date-title">{title}</div>
          <CalendarMonthIcon className="travel-date-calendar-icon" />
        </div>
        {date.length ? (
          <div className={styles.travelDateContent}>
            {date.substring(0, 2)}
            <span className={styles.travelDateText}>
              {date.substring(2, date.length)}
            </span>
          </div>
        ) : (
          <div className="travel-date-meta-title">
            <span className="travel-date-text-normal">{metaTitle}</span>
          </div>
        )}
        {date.length > 0 && <div className="travel-date-day">{day}</div>}
        {dateState.showCalander &&
          (calendarPosition === "bottom" ? (
            <Calendar
              className={calendarWrapperClass}
              date={
                !dateState.dateValue
                  ? minValue && minValue
                  : dateState.dateValue
              }
              minDate={minValue ? minValue : new Date()}
              showSelectionPreview={true}
              showMonthAndYearPickers={false}
              locale={
                localStorage.getItem("language") == "AR"
                  ? locales["arSA"]
                  : locales["enUS"]
              }
              onChange={(value) => {
                setDateState((prev) => ({
                  ...prev,
                  dateValue: value,
                  showCalander: false,
                }));
                onDateChange(value);
              }}
            />
          ) : (
            <Calendar
              className={calendarWrapperClassAbve}
              date={
                !dateState.dateValue
                  ? minValue && minValue
                  : dateState.dateValue
              }
              locale={
                localStorage.getItem("language") == "AR"
                  ? locales["arSA"]
                  : locales["enUS"]
              }
              minDate={minValue ? minValue : new Date()}
              showSelectionPreview={true}
              showMonthAndYearPickers={false}
              onChange={(value) => {
                setDateState((prev) => ({
                  ...prev,
                  dateValue: value,
                  showCalander: false,
                }));
                onDateChange(value);
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default TravelDate;

TravelDate.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  day: PropTypes.string,
  metaTitle: PropTypes.string,
  setShowCalendar: PropTypes.func,
  onDateChange: PropTypes.func,
  onClick: PropTypes.func,
  enableDateRange: PropTypes.bool,
};
