import React, { useEffect, useState } from "react";
import moment from "moment";
import useStyles from "./style";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../../../assets/images/clock.svg";
import ArrowUpSvg from "../../../../../../../../../assets/images/arrow-up.svg";
import PlaneSvg from "../../../../../../../../../assets/images/plane-right.svg";
import PlaceholderImage from "../../../../../../../../../assets/images/logo.png";
import PlaneLineSvg from "../../../../../../../../../assets/images/plane-line.svg";
import ProfileSvg from "../../../../../../../../../assets/images/profile.svg";
import {
  TimeFormat,
  flightDurationInHoursAndMinutes,
} from "../../../../../../../../../utils/helpers";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile.hook";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDateToArabicDateAndMonthAndYearFormat } from "../../../../../../../../../utils/helpers";

const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const FlightDetailsContainer = ({ flightTicket, flightSearch, index }) => {
  const [segmentDetails, setSegmentDetails] = useState([]);
  const [showModal, setShowModal] = useState([]);
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const {
    ticket: {
      Results: {
        Itinerary: { Segments, Passenger, PNR },
      },
    },
  } = flightTicket;
  const styles = useStyles();

  const formatTime = (inputDate) => {
    const formattedDate = moment(inputDate).format("HH:mm");
    return formattedDate;
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    if (localStorage.getItem("language") == "AR") {
      return formatDateToArabicDateAndMonthAndYearFormat(dateString);
    } else {
      const date = moment(dateString);
      const formattedDate = date.format("DD MMM, YY");
      return formattedDate;
    }
  };

  const getSeat = (SeatDynamic, originCityCode, destCityCode) => {
    const filteredSeat = SeatDynamic.filter((seat) => {
      return (
        seat.Origin === originCityCode && seat.Destination === destCityCode
      );
    });

    if (filteredSeat.length > 0) {
      const { Code } = filteredSeat[0];
      return Code;
    } else return "-";
  };

  const getBaggage = (Baggage, originCityCode, destCityCode) => {
    const filteredBaggage = Baggage.filter((baggage) => {
      return (
        baggage.Origin === originCityCode &&
        baggage.Destination === destCityCode
      );
    });

    if (filteredBaggage.length > 0) {
      const { Weight } = filteredBaggage[0];
      return `${Weight} KG`;
    } else return "-";
  };

  useEffect(() => {
    const expandArray = [];
    const result = [];
    const flightSegments = flightSearch[0].flightSegments;
    let j = 0;
    for (let i = 0; i < flightSegments.length; i++) {
      const flightSegment = flightSegments[i];
      const key = `${flightSegment.origin}-${flightSegment.destination}`;
      const matchingSegments = [];

      while (j < Segments.length) {
        if (index === 1 && i == 0) break;
        j++;
        matchingSegments.push(Segments[j - 1]);
        if (
          j + 1 < Segments.length &&
          Segments[j + 1].Origin.Airport.AirportCode ===
            flightSegment.destination
        ) {
          matchingSegments.push(Segments[j]);
          j++;
          break;
        }

        if (
          Segments[j - 1].Destination.AirportCode === flightSegment.destination
        ) {
          break;
        }
      }
      // }
      expandArray.push(false);
      result.push({
        [key]: matchingSegments,
      });
    }
    expandArray[0] = true;
    setSegmentDetails(result);
    setShowModal(expandArray);
  }, []);

  return (
    <>
      {segmentDetails &&
        segmentDetails.map((segment, i) => {
          const key = Object.keys(segment)[0];
          const value = segment[key];
          const originHead = key.split("-")[0];
          const destinationHead = key.split("-")[1];
          return (
            value &&
            value.length > 0 && (
              <div className={styles.mainContainer} key={i}>
                <div className={styles.headingContainer}>
                  <div className={styles.flightHeading}>
                    <p className={styles.heading}>{originHead}</p>
                    <ReactSVG src={PlaneSvg} />
                    <p className={styles.heading}>{destinationHead}</p>
                  </div>
                  {showModal[i] ? (
                    <ReactSVG
                      src={ArrowUpSvg}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const options = showModal;
                        options[i] = false;
                        setShowModal([...options]);
                      }}
                    />
                  ) : (
                    <ReactSVG
                      src={ArrowUpSvg}
                      style={{
                        cursor: "pointer",
                        transform: "rotate(180deg)",
                      }}
                      onClick={() => {
                        const options = showModal;
                        options[i] = true;
                        setShowModal([...options]);
                      }}
                    />
                  )}
                </div>
                {showModal[i] &&
                  value &&
                  value.map((segmentInfo) => {
                    const {
                      IncludedBaggage,
                      CabinBaggage,
                      DepartureTime,
                      ArrivalTime,
                      AirlineName,
                      Airline,
                      FlightNumber,
                      // AirlineDetails: {
                      //   AirlineCode,
                      //   AirlineName,
                      //   FlightNumber,
                      // },
                      Origin: {
                        CityCode: originCityCode,
                        CityName: originCityName,
                        AirportName: originAirportName,
                      },
                      Destination: {
                        CityCode: destCityCode,
                        CityName: destCityName,
                        AirportName: destAirportName,
                      },
                      Duration,
                    } = segmentInfo;
                    return (
                      <div className={styles.infoContainer}>
                        <div className={styles.flightDetailInfo}>
                          <div className={styles.airlineDetails}>
                            <img
                              src={`${S3Bucket}/AIRLINE_ICON/${Airline}.png`}
                              onError={(e) => {
                                e.target.src = PlaceholderImage;
                              }}
                              alt="flightLogo"
                              style={{ height: "36px", width: "42px" }}
                            />
                            <div className={styles.airlineDetailsContainer}>
                              <p className={styles.airlineName}>
                                {AirlineName}
                              </p>
                              <p className={styles.airlineCode}>
                                {Airline}-{FlightNumber}
                              </p>
                            </div>
                          </div>
                          <div className={styles.dummyContainer}>
                            <div className={styles.cityDetails}>
                              <p className={styles.city}>
                                {originCityCode}-{originCityName}
                              </p>
                              <p className={styles.time}>
                                {formatTime(DepartureTime)},{" "}
                                {formatDate(DepartureTime)}
                              </p>
                              <p className={styles.airportDetail}>
                                {originAirportName}
                              </p>
                            </div>
                            <div className={styles.durationContainer}>
                              <div className={styles.planeContainer}>
                                <p className={styles.lineDetails}></p>
                                <ReactSVG src={PlaneLineSvg} />
                                <p className={styles.lineDetails}></p>
                              </div>
                              <p className={styles.duration}>
                                <ReactSVG src={ClockSvg} />
                                {TimeFormat(Duration)}
                              </p>
                            </div>
                            <div className={styles.cityDetails}>
                              <p className={styles.city}>
                                {destCityCode}-{destCityName}
                              </p>
                              <p className={styles.time}>
                                {formatTime(ArrivalTime)},{" "}
                                {formatDate(ArrivalTime)}
                              </p>
                              <p className={styles.airportDetail}>
                                {destAirportName}
                              </p>
                            </div>
                          </div>
                        </div>
                        {IncludedBaggage || CabinBaggage ? (
                          <div className={styles.baggageContainer}>
                            <p className={styles.baggageHeading}>
                              {t("bookingConfirmationFlight.baggageDetails")}
                            </p>
                            <div className={styles.baggageContentContainer}>
                              {IncludedBaggage && (
                                <div className={styles.baggageContent}>
                                  <p className={styles.baggageContentHeading}>
                                    {t("bookingConfirmationFlight.checkIn")}
                                  </p>
                                  <p className={styles.baggageContentValue}>
                                    {IncludedBaggage} (
                                    {t("bookingConfirmationFlight.perAdult")})
                                  </p>
                                </div>
                              )}
                              {CabinBaggage && (
                                <div className={styles.baggageContent}>
                                  <p className={styles.baggageContentHeading}>
                                    {t("bookingConfirmationFlight.cabin")}
                                  </p>
                                  <p className={styles.baggageContentValue}>
                                    {CabinBaggage} (
                                    {t("bookingConfirmationFlight.perAdult")})
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className={styles.travellerDetailsContainer}>
                          <div className={styles.tableContainer}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">
                                    <p className={styles.tableHeadingContent}>
                                      {t(
                                        "bookingConfirmationFlight.travellerName"
                                      )}
                                    </p>
                                  </TableCell>
                                  <TableCell align="left">
                                    <p className={styles.tableHeadingContent}>
                                      {/* PNR */}
                                      {t("bookingConfirmationFlight.PNR")}
                                    </p>
                                  </TableCell>
                                  <TableCell align="left">
                                    <p className={styles.tableHeadingContent}>
                                      {/* E-Ticket Number */}
                                      {t(
                                        "bookingConfirmationFlight.eTicketNumber"
                                      )}
                                    </p>
                                  </TableCell>
                                  <TableCell align="left">
                                    <p className={styles.tableHeadingContent}>
                                      {t("bookingConfirmationFlight.seat")}
                                    </p>
                                  </TableCell>
                                  <TableCell align="left">
                                    <p className={styles.tableHeadingContent}>
                                      {t(
                                        "bookingConfirmationFlight.extraBaggage"
                                      )}
                                    </p>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Passenger &&
                                  Passenger.map((passenger) => {
                                    const {
                                      FirstName,
                                      LastName,
                                      Type,
                                      Gender,
                                      PaxSeat,
                                      PaxBaggage,
                                      Ticket: { TicketNumber },
                                    } = passenger;
                                    return (
                                      <TableRow
                                        style={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          className={styles.tableBodyContent}
                                        >
                                          <div
                                            className={styles.nameInfoContainer}
                                          >
                                            {FirstName} {LastName}
                                            <p>
                                              {Type == 1
                                                ? "Adult"
                                                : Type == 2
                                                ? "Child"
                                                : "Infant"}
                                              ,{" "}
                                              {Gender == 1 ? "Male" : "Female"}
                                            </p>
                                          </div>
                                        </TableCell>
                                        <TableCell align="left">
                                          <p
                                            className={styles.tableBodyContent}
                                          >
                                            {PNR}
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          <p
                                            className={styles.tableBodyContent}
                                          >
                                            {TicketNumber}
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          <p
                                            className={styles.tableBodyContent}
                                          >
                                            {PaxSeat && PaxSeat.length > 0
                                              ? getSeat(
                                                  PaxSeat,
                                                  originCityCode,
                                                  destCityCode
                                                )
                                              : "-"}
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          <p
                                            className={styles.tableBodyContent}
                                          >
                                            {PaxBaggage && PaxBaggage.length > 0
                                              ? getBaggage(
                                                  PaxBaggage,
                                                  key.split("-")[0],
                                                  key.split("-")[1]
                                                )
                                              : "-"}
                                          </p>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )
          );
        })}
    </>
  );
  // })}
  //   </>
  // );
};

export default FlightDetailsContainer;
