import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookFlightMultiCityTripCard from "./show-flight";
import { searchClubbedFlights } from "../../../../../../modules/flight";
import {
  handleClubbedFlightForFareCategoryForMultiCityTrip,
  handleClubbedFlightsForSecondCityForMultiCityTrip,
  handleSelectedFlightForMultiCityTrip,
  handleIndexOfSelectedMultiCity,
  handleSearchedFlightsForMultiCityTrip,
} from "../../../../../../utils/slices/multicitySlice";
import BookingMultiFlightsMobile from "./show-flight-mobile";
import useResponsive from "../../../../../../hooks/responsive.hook";
import {
  handleClearAllFilters,
  handleClubbedFiltersForSecondCity,
  handleClubbedFiltersForThirdCity,
  handleFilteredFlights,
  handleQuickFilters,
  handleSortFilter,
  handleTotalFlights,
} from "../../../../../../utils/slices/flightFiltersSlice";

import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import { useNavigate } from "react-router-dom";
import {
  filterFlightsHelperFunctionForOneWay,
  sortFlightsOnPrice,
} from "../../helper";
import FlightNotFound from "../flight-not-found";
import handleUnauthorisedLogout from "../../../../../../modules/unauthorised";
import { setUserInfo } from "../../../../../../utils/slices/userSlice";
import FlightResultSubHeader from "../../flight-result-sub-header";

const MultiCityTripContainer = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const currentBreakpoint = useResponsive();
  const {
    searchedFlights,
    clubbedFlightsForSecondCity,
    indexOfMultiCity,
    clubbedFlightsForFareCategory,
    selectedFlight,
  } = useSelector((state) => state.multiCityTripDetails);
  const user = useSelector((state) => state.user);

  const {
    // flightFrom,
    // flightTo,
    flightTotalTravellers: {
      flightAdultsCount,
      flightInfantsCount,
      flightChildrenCount,
    },
  } = useSelector((state) => state.modifySearchFlight);

  const {
    outboundFilters,
    airlineFilter: airlines,
    filteredFlights,
    stopsFilter: stops,
    refundFilter: refundOption,
    priceFilter: price,
    durationFilter: duration,
    outBoundDepartureDurationFilter: outBoundDepartureDuration,
    outBoundArrivalDurationFilter: outBoundArrivalDuration,
    sort,
    clubbedFiltersForSecondCity,
    clubbedFiltersForThirdCity,
  } = useSelector((state) => state.flightFilters);

  const {
    flightClassType,
    flightDepartureDate,
    flightReturnDate,
    flightFrom,
    flightTo,
    multiCityDates,
  } = useSelector((state) => state.searchFlightQuery);

  const setClubbedFiltersForSecondCity = (data) => {
    dispatch(handleClubbedFiltersForSecondCity(data));
  };

  const setClubbedFiltersForThirdCity = (data) => {
    dispatch(handleClubbedFiltersForThirdCity(data));
  };

  const handleBookNow = async (selected) => {
    dispatch(handleSortFilter(true));
    const packages =
      filteredFlights?.length > 0
        ? filteredFlights[selected]
        : searchedFlights[selected];
    const selectedFlights =
      filteredFlights?.length > 0
        ? filteredFlights[selected][0]
        : searchedFlights[selected][0];
    const data = await searchClubbedFlights({
      segmentIndex: 1,
      packages,
      setClubbedFiltersForSecondCity,
      setClubbedFiltersForThirdCity,
      token: user?.token,
    });

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    dispatch(handleClubbedFlightsForSecondCityForMultiCityTrip(data));
    dispatch(handleIndexOfSelectedMultiCity(1));
    dispatch(handleSelectedFlightForMultiCityTrip([selectedFlights]));
    dispatch(handleClearAllFilters());
  };

  const handleBookNowOfFareCategory = async (flight) => {
    dispatch(handleSortFilter(true));
    const packages =
      filteredFlights?.length > 0
        ? filteredFlights[flight]
        : clubbedFlightsForSecondCity[flight];
    const selectedFlightOfFareCategory =
      filteredFlights?.length > 0
        ? filteredFlights[flight][0]
        : clubbedFlightsForSecondCity[flight][0];
    const data = await searchClubbedFlights({
      segmentIndex: 2,
      packages,
      setClubbedFiltersForSecondCity,
      setClubbedFiltersForThirdCity,
      token: user?.token,
    });

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    dispatch(handleClubbedFlightForFareCategoryForMultiCityTrip(data));
    dispatch(handleIndexOfSelectedMultiCity(2));
    dispatch(
      handleSelectedFlightForMultiCityTrip([
        ...selectedFlight,
        selectedFlightOfFareCategory,
      ])
    );
    dispatch(handleClearAllFilters());
  };

  const handleBookNowOfFinal = async (selected, index = 0) => {
    let flightForSecondCity;
    let firstDate = "";
    let secondDate = "";
    let flightForFareCategory;
    let resultIndices;
    let totalTrips;
    let isLcc;
    let fare = [];
    let markupId;
    let airlineCode = [];
    if (
      Object.keys(flightFrom[2]).length === 0 ||
      Object.keys(flightTo[2]).length === 0
    ) {
      flightForSecondCity =
        filteredFlights?.length > 0
          ? filteredFlights?.[selected][0]
          : clubbedFlightsForSecondCity?.[selected][0];
    } else {
      flightForFareCategory =
        filteredFlights?.length > 0
          ? filteredFlights?.[selected][0]
          : clubbedFlightsForFareCategory?.[selected][index];
    }

    if (
      Object.keys(flightFrom[2]).length === 0 ||
      Object.keys(flightTo[2]).length === 0
    ) {
      totalTrips = 2;
      resultIndices = flightForSecondCity.ResultId;
      isLcc = JSON.stringify([flightForSecondCity?.IsLcc]);
      const markup = getMarkup(
        flightForSecondCity?.markup,
        flightForSecondCity
      );
      markupId = flightForSecondCity?.markup?._id;
      fare = JSON.stringify([
        Math.ceil(flightForSecondCity.Fare?.Total * rateOfExchange) + markup,
      ]);
      airlineCode = JSON.stringify([flightForSecondCity.ValidatingAirline]);
      const [fDate] = multiCityDates.filter((date) => date.key === 1);
      firstDate = fDate.date;
    } else {
      totalTrips = 3;
      resultIndices = flightForFareCategory.ResultId;
      isLcc = JSON.stringify([flightForFareCategory?.IsLcc]);
      const markup = getMarkup(
        flightForFareCategory?.markup,
        flightForFareCategory
      );
      markupId = flightForFareCategory?.markup?._id;
      fare = JSON.stringify([
        Math.ceil(flightForFareCategory.Fare?.TotalFare * rateOfExchange) +
          markup,
      ]);
      airlineCode = JSON.stringify([flightForFareCategory.ValidatingAirline]);
      const [fDate] = multiCityDates.filter((date) => date.key === 1);
      const [sDate] = multiCityDates.filter((date) => date.key === 2);
      firstDate = fDate.date;
      secondDate = sDate.date;
    }

    const traceId = window.localStorage.getItem("traceId");
    const resultIndex = JSON.stringify([resultIndices]);
    dispatch(handleClearAllFilters());
    const isInternational = flightFrom[0].country != flightTo[0].country;

    {
      isMobile
        ? navigate(
            `/flight/review-details/${traceId}/${resultIndex}/${isLcc}/${totalTrips}/${"MCT"}/${flightAdultsCount}/${flightChildrenCount}/${flightInfantsCount}/${fare}/${markupId}/${flightClassType}/${airlineCode}?ff=${
              flightFrom[0].code
            }&ft=${
              flightTo[0].code
            }&dd=${flightDepartureDate}&md1=${firstDate}&md2=${secondDate}&isIt=${isInternational}`
          )
        : window.open(
            `/flight/review-details/${traceId}/${resultIndex}/${isLcc}/${totalTrips}/${"MCT"}/${flightAdultsCount}/${flightChildrenCount}/${flightInfantsCount}/${fare}/${markupId}/${flightClassType}/${airlineCode}?ff=${
              flightFrom[0].code
            }&ft=${
              flightTo[0].code
            }&dd=${flightDepartureDate}&md1=${firstDate}&md2=${secondDate}&isIt=${isInternational}`
          );
    }
  };

  const getMarkup = (markupDetails, flight) => {
    let amt = 0;
    if (!markupDetails) return 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt =
          (amt * (flight?.Fare?.BaseFare + flight?.Fare?.OtherCharges)) / 100;
      } else {
        amt = (amt * flight?.Fare?.TotalFare) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    return Math.ceil(amt);
  };

  useEffect(() => {
    handleFilterFlightForMultiCityTrip();
  }, [
    airlines,
    stops,
    refundOption,
    price,
    duration,
    outBoundDepartureDuration,
    outBoundArrivalDuration,
  ]);

  const handleFilterFlightForMultiCityTrip = () => {
    const filteredFlights = filterFlightsHelperFunctionForOneWay({
      stops,
      airlines,
      refundOption,
      price,
      duration,
      flights:
        indexOfMultiCity === 0
          ? searchedFlights
          : indexOfMultiCity === 1
          ? clubbedFlightsForSecondCity
          : clubbedFlightsForFareCategory,
      index: indexOfMultiCity === 0 ? 0 : indexOfMultiCity === 1 ? 1 : 2,
      outBoundDepartureDuration,
      outBoundArrivalDuration,
    });
    dispatch(handleFilteredFlights(filteredFlights));
    if (indexOfMultiCity === 0)
      dispatch(handleTotalFlights(searchedFlights.length));
    else if (indexOfMultiCity === 1)
      dispatch(handleTotalFlights(clubbedFlightsForSecondCity.length));
    else dispatch(handleTotalFlights(clubbedFlightsForFareCategory.length));
  };

  useEffect(() => {
    const flights =
      filteredFlights.length > 0
        ? filteredFlights
        : indexOfMultiCity === 0
        ? searchedFlights
        : indexOfMultiCity === 1
        ? clubbedFlightsForSecondCity
        : clubbedFlightsForFareCategory;
    const sortedFlights = sortFlightsOnPrice(sort, flights);
    if (filteredFlights.length > 0) {
      dispatch(handleFilteredFlights(sortedFlights));
    } else if (indexOfMultiCity === 0) {
      dispatch(handleSearchedFlightsForMultiCityTrip(sortedFlights));
    } else if (indexOfMultiCity === 1) {
      dispatch(
        handleClubbedFlightsForSecondCityForMultiCityTrip(sortedFlights)
      );
    } else {
      dispatch(
        handleClubbedFlightForFareCategoryForMultiCityTrip(sortedFlights)
      );
    }
  }, [sort]);

  useEffect(() => {
    handleQickFiltersAirlines();
  }, [
    clubbedFiltersForSecondCity,
    outboundFilters,
    indexOfMultiCity,
    clubbedFiltersForThirdCity,
  ]);

  const handleQickFiltersAirlines = () => {
    const airlineCount =
      indexOfMultiCity === 0
        ? outboundFilters.airlineCount
        : indexOfMultiCity === 1
        ? clubbedFiltersForSecondCity.airlineCount
        : clubbedFiltersForThirdCity.airlineCount;

    const airlines = Object.keys(airlineCount)
      .sort((a, b) => airlineCount[b] - airlineCount[a])
      .slice(0, 3);
    dispatch(handleQuickFilters([...airlines]));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {currentBreakpoint === "extraLarge" && <FlightResultSubHeader />}
      {indexOfMultiCity === 0 ? (
        stops.length > 0 ||
        airlines.length > 0 ||
        refundOption.length > 0 ||
        price.length > 0 ||
        duration.length > 0 ||
        outBoundDepartureDuration.length > 0 ||
        outBoundArrivalDuration.length > 0 ? (
          filteredFlights?.length > 0 ? (
            filteredFlights.map((flight, i) => {
              return isMobile ? (
                <BookingMultiFlightsMobile
                  key={i}
                  flight={flight}
                  handleBookNow={handleBookNow}
                  index={indexOfMultiCity}
                  selected={i}
                />
              ) : (
                <BookFlightMultiCityTripCard
                  key={i}
                  flight={flight}
                  handleBookNow={handleBookNow}
                  index={indexOfMultiCity}
                  selected={i}
                />
              );
            })
          ) : (
            <FlightNotFound />
          )
        ) : (
          searchedFlights &&
          searchedFlights.map((flight, i) => {
            return isMobile ? (
              <BookingMultiFlightsMobile
                key={i}
                flight={flight}
                handleBookNow={handleBookNow}
                index={indexOfMultiCity}
                selected={i}
              />
            ) : (
              <BookFlightMultiCityTripCard
                key={i}
                flight={flight}
                handleBookNow={handleBookNow}
                index={indexOfMultiCity}
                selected={i}
              />
            );
          })
        )
      ) : indexOfMultiCity === 1 ? (
        stops.length > 0 ||
        airlines.length > 0 ||
        refundOption.length > 0 ||
        price.length > 0 ||
        duration.length > 0 ||
        outBoundDepartureDuration.length > 0 ||
        outBoundArrivalDuration.length > 0 ? (
          filteredFlights?.length > 0 ? (
            filteredFlights.map((flight, i) => {
              return isMobile ? (
                <BookingMultiFlightsMobile
                  key={i}
                  flight={flight}
                  handleBookNow={
                    Object.keys(flightFrom[2]).length === 0 ||
                    Object.keys(flightTo[2]).length === 0
                      ? handleBookNowOfFinal
                      : handleBookNowOfFareCategory
                  }
                  index={indexOfMultiCity}
                  selected={i}
                />
              ) : (
                <BookFlightMultiCityTripCard
                  key={i}
                  flight={flight}
                  handleBookNow={
                    Object.keys(flightFrom[2]).length === 0 ||
                    Object.keys(flightTo[2]).length === 0
                      ? handleBookNowOfFinal
                      : handleBookNowOfFareCategory
                  }
                  index={indexOfMultiCity}
                  selected={i}
                />
              );
            })
          ) : (
            <FlightNotFound />
          )
        ) : (
          clubbedFlightsForSecondCity &&
          clubbedFlightsForSecondCity.map((flight, i) => {
            return isMobile ? (
              <BookingMultiFlightsMobile
                key={i}
                flight={flight}
                handleBookNow={
                  Object.keys(flightFrom[2]).length === 0 ||
                  Object.keys(flightTo[2]).length === 0
                    ? handleBookNowOfFinal
                    : handleBookNowOfFareCategory
                }
                index={indexOfMultiCity}
                selected={i}
              />
            ) : (
              <BookFlightMultiCityTripCard
                key={i}
                flight={flight}
                handleBookNow={
                  Object.keys(flightFrom[2]).length === 0 ||
                  Object.keys(flightTo[2]).length === 0
                    ? handleBookNowOfFinal
                    : handleBookNowOfFareCategory
                }
                index={indexOfMultiCity}
                selected={i}
              />
            );
          })
        )
      ) : stops.length > 0 ||
        airlines.length > 0 ||
        refundOption.length > 0 ||
        price.length > 0 ||
        duration.length > 0 ||
        outBoundDepartureDuration.length > 0 ||
        outBoundArrivalDuration.length > 0 ? (
        filteredFlights?.length > 0 ? (
          filteredFlights.map((flight, i) => {
            return isMobile ? (
              <BookingMultiFlightsMobile
                key={i}
                flight={flight}
                handleBookNow={handleBookNowOfFinal}
                index={indexOfMultiCity}
                selected={i}
              />
            ) : (
              <BookFlightMultiCityTripCard
                key={i}
                flight={flight}
                handleBookNow={handleBookNowOfFinal}
                index={indexOfMultiCity}
                selected={i}
              />
            );
          })
        ) : (
          <FlightNotFound />
        )
      ) : (
        clubbedFlightsForFareCategory &&
        clubbedFlightsForFareCategory.map((flight, i) => {
          return isMobile ? (
            <BookingMultiFlightsMobile
              key={i}
              flight={flight}
              handleBookNow={handleBookNowOfFinal}
              index={indexOfMultiCity}
              selected={i}
            />
          ) : (
            <BookFlightMultiCityTripCard
              key={i}
              flight={flight}
              handleBookNow={handleBookNowOfFinal}
              index={indexOfMultiCity}
              selected={i}
            />
          );
        })
      )}
    </div>
  );
};

export default MultiCityTripContainer;
