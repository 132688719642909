import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardPassenger: {
    width: "600px",
    height: "255px",
    borderRadius: "16px",
    marginTop: "-11px",
    marginLeft: "-400px",
    background: "#FFF",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    position: "absolute",
    zIndex: 1,

    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: "0px",
    },
  },
  cardPassengerArabic: {
    width: "600px",
    height: "255px",
    borderRadius: "16px",
    marginTop: "-11px",
    background: "#FFF",
    left: 0,
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    position: "absolute",
    zIndex: 1,

    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: "0px",
    },
  },

  cardPassengerEnlarge: {
    width: "600px",
    height: "255px",
    borderRadius: "16px",
    marginTop: "-11px",
    marginLeft: "-224px",
    background: "#FFF",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.12)",
    position: "absolute",
    zIndex: 1,

    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: "0px",
    },
  },

  cardContent: {
    padding: "16px",
  },

  section1: {
    width: "307px",
    height: "59px",
  },

  section1Text: {
    height: "19px",
    color: "#344054",
    fontSize: "16px",
    fontFamily: "'Lato', sans-serif",
    fontWeight: 500,
  },

  section1ListNumbers: {
    height: "24px",
    marginTop: "16px",
    display: "flex",
    gap: "12.5px",
  },

  nonActiveValid: {
    borderRadius: "8px",
    background: "#F5F6F6",
    width: "27px",
    height: "24px",
    textAlign: "center",
    color: "#344054",
    fontSize: "15px",
    fontFamily: "'Lato', sans-serif",
    paddingTop: "3px",
    cursor: "pointer",
  },

  nonActiveInvalid: {
    borderRadius: "8px",
    background: "gray",
    width: "27px",
    height: "24px",
    textAlign: "center",
    color: "#344054",
    fontSize: "15px",
    fontFamily: "'Lato', sans-serif",
    paddingTop: "3px",
    cursor: "pointer",
  },

  section1ListNumbersNumberActive: {
    borderRadius: "8px",
    background: "#08F",
    color: "#F5F6F7",
    width: "27px",
    height: "24px",
    textAlign: "center",
    fontSize: "15px",
    fontFamily: "'Lato', sans-serif",
    paddingTop: "3px",
    cursor: "pointer",
  },

  section2: {
    width: "580px",
    height: "79px",
    marginTop: "24px",
    display: "flex",
    gap: "25px",
  },

  content1: {
    width: "272px",
    height: "79px",
  },

  content1Text: {
    height: "19px",
    color: "#344054",
    fontSize: "16px",
    fontFamily: "'Lato', sans-serif",
    fontWeight: 500,
  },

  content1ListNumbers: {
    height: "24px",
    marginTop: "16px",
    display: "flex",
    gap: "12.5px",
  },

  section2ListNumbersNumberActive: {
    borderRadius: "8px",
    background: "#08F",
    color: "#F5F6F7",
    width: "27px",
    height: "24px",
    textAlign: "center",
    fontSize: "15px",
    fontFamily: "'Lato', sans-serif",
    paddingTop: "3px",
    cursor: "pointer",
  },

  content2: {
    width: "270px",
    height: "79px",
  },

  content2Text: {
    height: "19px",
    color: "#344054",
    fontSize: "16px",
    fontFamily: "'Lato', sans-serif",
    fontWeight: 500,
  },

  errors: {
    width: "552px",
    height: "30px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
  },

  travellersCardError: {
    marginTop: "-10px",
    marginLeft: "-40px",
  },

  section3: {
    width: "552px",
    height: "33px",
    marginTop: "12px",
  },

  section3Button: {
    width: "68px",
    height: "33px",
    marginLeft: "484px",
    borderRadius: "12px",
    border: "1px solid #08F",
    background: "#FFF",
  },

  section3ButtonText: {
    color: "#344054",
    fontSize: "14px",
    fontFamily: "'Lato', sans-serif",
    textAlign: "center",
    marginTop: "8px",
    cursor: "pointer",
  },
}));

export default useStyles;
