import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectedDepartureFlight from "../../../../search-flight/components/cards/selected-departure-flight";
import { setIndexOfRoundTrip } from "../../../../../../utils/slices/bookedFlightSlice";
import "./style.css";
import { handleSelectedFlightForInternationalRoundTrip } from "../../../../../../utils/slices/internationalRoundTripSlice";
import useStyles from "./style";
import { handleClearAllFilters } from "../../../../../../utils/slices/flightFiltersSlice";
import { useTranslation } from "react-i18next";

const RoundTripFlightSelection = () => {
  const dispatch = useDispatch();
  const { indexOfRoundTrip } = useSelector((state) => state.bookedFlight);
  const { selectedFlight } = useSelector(
    (state) => state.internationalRoundTripDetails
  );
  const { flightFrom, flightTo, flightTravelType } = useSelector(
    (state) => state.searchFlightQuery
  );
  const styles = useStyles();
  const { t } = useTranslation();
  const handleChangeFlight = async () => {
    dispatch(setIndexOfRoundTrip(0));
    dispatch(handleSelectedFlightForInternationalRoundTrip([]));
    dispatch(handleClearAllFilters());
  };

  return flightTravelType === 2 && indexOfRoundTrip == 0 ? (
    <div className={styles.selectReturnFlightContainer}>
      <div className={styles.RoundTripContent1}>
        <div className={styles.selectDepartureHeader}>
          {t("flightSearch.selectYourDepartureFlight")}
        </div>
        <div className={styles.selectDepartureTitle}>
          {t("flightSearch.nextStepChooseReturnFlight")}
        </div>
      </div>
      <div className={styles.RoundTripContent2}>
        <span className={styles.flightRoundTripText}>
          {flightFrom?.[0].city?.split(",")[0]} {t("flightSearch.to")}{" "}
          {flightTo?.[0].city?.split(",")[0]}
        </span>
      </div>
    </div>
  ) : (
    flightTravelType === 2 && indexOfRoundTrip == 1 && (
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <SelectedDepartureFlight
          index={0}
          flight={selectedFlight}
          handleChangeFlight={handleChangeFlight}
        />
        <div className={styles.selectReturnFlightContainer}>
          <div className={styles.RoundTripContent1}>
            <div className={styles.selectDepartureHeader}>
              {t("flightSearch.selectYourReturnFlight")}
            </div>
            <div className={styles.roundTripMetaText}>
              {t("flightSearch.nextStepTotalPrice")}
            </div>
          </div>
          <div className={styles.RoundTripContent2}>
            <span className={styles.flightRoundTripText}>
              {flightTo?.[0].city.split(",")[0]} {t("flightSearch.to")}{" "}
              {flightFrom?.[0].city.split(",")[0]}
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default RoundTripFlightSelection;
