import { createSlice } from "@reduxjs/toolkit";

const bookedFlightSlice = createSlice({
  name: "bookedFlights",
  initialState: {
    bookedFlights: [],
    indexOfRoundTrip: 0,
    changedFlight: false,
    selectedFlight: [],
  },
  reducers: {
    bookFlight: (state, action) => {
      state.bookedFlights.push(action.payload);
    },
    changeFlight: (state, action) => {
      state.bookedFlights = state.bookedFlights.filter(
        (flight) => flight.id !== action.payload.id
      );
    },
    clearBookedFlights: (state) => {
      state.bookedFlights = [];
    },
    setIndexOfRoundTrip: (state, action) => {
      state.indexOfRoundTrip = action.payload;
    },
    handleChangedFlight: (state, action) => {
      state.changedFlight = action.payload;
    },
    handleSelectedFlightGlobal: (state, action) => {
      state.selectedFlight = action.payload;
    },
  },
});

export const {
  bookFlight,
  changeFlight,
  clearBookedFlights,
  setIndexOfRoundTrip,
  handleChangedFlight,
  handleSelectedFlightGlobal,
  handleApplicable,
} = bookedFlightSlice.actions;

export default bookedFlightSlice.reducer;
