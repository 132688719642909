import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStyles from "./style";

const PackagesCarousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageOpacity(0);
      setTimeout(() => {
        setCurrentImage((prevImage) =>
          prevImage === images.length - 1 ? 0 : prevImage + 1
        );

        setImageOpacity(1);
      }, 500);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  const style = useStyles();

  return (
    <div className={style.carouselContainer}>
      <img
        className={style.carouselImage}
        src={images && images.length > 0 ? images[currentImage] : ""}
        alt={`Image ${currentImage + 1}`}
        style={{ opacity: imageOpacity }}
      />
    </div>
  );
};

PackagesCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PackagesCarousel;
