import React, { useState } from "react";
import useStyles from "../styles";
import HotelSvg from "../../../../../../assets/images/hotel-booking.svg";
import UserSvg from "../../../../../../assets/images/user.svg";
import LocationSvg from "../../../../../../assets/images/location-view.svg";
import { ReactSVG } from "react-svg";
import {
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { formatDateToDdMmYyyyForBooking } from "../../../../../../utils/helpers";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../../../src/core/eventBus";
import { cancelHotelBooking, reissueHotelBooking } from "../../../../../../modules/hotels";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import CancellationSuccess from "./cancellation-status";
import ReissueSuccess from "./reissue-status";
import LoadingBackdrop from "../../../../../flight-module/cards/loading-backdrop";
import { toast } from "react-toastify";
import { convertISOToArabicDateInDDMMYYY } from "../../../../../../utils/helpers";


const CancellationStatus = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: "flex",
        width: "300px",
        maxWidth: "90vw",
        flexDirection: "column",
        padding: "10px 16px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <div></div>
        <CloseIcon
          sx={{ height: "30px" }}
          onClick={() => eventBus.emit("close-dialog", <CancellationStatus />)}
        />
      </div>
      <p style={{ fontWeight: "bolder", marginBottom: "16px" }}>
        Cancellation Status
      </p>

      {data?.statusCode == 200 ? (
        // <div>{data?.data?.cancelStatus}</div>
        <div>
          <div>
            Cancellation is initiated. Rest assured, we will notify you if your
            hotel is cancelled from our side.
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <Button
              sx={{ marginTop: "16px" }}
              variant="contained"
              onClick={() =>
                eventBus.emit("close-dialog", <CancellationStatus />)
              }
            >
              Ok
            </Button>
          </div>
        </div>
      ) : data?.statusCode == 500 ? (
        <div>
          <div>Something went wrong, Please try again!</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <Button
              sx={{ marginTop: "16px" }}
              variant="contained"
              onClick={() =>
                eventBus.emit("close-dialog", <CancellationStatus />)
              }
            >
              Ok
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div>{data?.message}</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <Button
              sx={{ marginTop: "16px" }}
              variant="contained"
              onClick={() =>
                eventBus.emit("close-dialog", <CancellationStatus />)
              }
            >
              Ok
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const BookingCancellationCharges = ({ booking, setOpenBackdrop }) => {
  const styles = useStyles();
  const user = useSelector((state) => state.user);
  const token = user?.token;
  const hotelRoomsDetails = booking?.hotelBlockResponse?.HotelRoomsDetails;
  async function handleBookingCancel() {
    const bookingId = booking?._id;
    const payload = {
      booking: bookingId,
      remarks: "Test cancel",
    };
    setOpenBackdrop(true);
    const data = await cancelHotelBooking(token, payload);
    eventBus.emit("close-dialog", <BookingCancellationCharges />);
    eventBus.emit("open-dialog", <CancellationStatus data={data} />);
    setOpenBackdrop(false);
  }
  return (
    <div
      style={{
        padding: "10px 16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignItems: "center",
        maxWidth: "90vw",
        width: "300px",
      }}
    >
      <p style={{ marginBottom: "10px", fontWeight: "bolder" }}>
        Cancellation Charges:{" "}
      </p>
      <div>
        {hotelRoomsDetails?.map((room, index) => {
          let CancellationPolicy = room?.CancellationPolicy;
          CancellationPolicy = CancellationPolicy?.replace("#^#", ", ");
          CancellationPolicy = CancellationPolicy?.replace("#!#", " ");
          CancellationPolicy = CancellationPolicy?.replace("|", " ");
          return (
            <div style={{ marginBottom: "10px" }}>
              {hotelRoomsDetails.length > 1 && <div> Room {index + 1}</div>}
              <div dangerouslySetInnerHTML={{ __html: CancellationPolicy }} />
            </div>
          );
        })}
        <p className={styles.cancel} onClick={handleBookingCancel}>
          Cancel
        </p>
      </div>
    </div>
  );
};
function extractCheckInOutTimes(rateConditions) {
  let checkInTime = null;
  let checkOutTime = null;
  if (rateConditions) {
    rateConditions.forEach(condition => {
      if (condition.includes("CheckIn Time-Begin")) {
        checkInTime = condition
      } else if (condition.includes("CheckOut Time")) {
        checkOutTime = condition
      }
    });
  } else {
    return { checkInTime, checkOutTime };

  }

  return { checkInTime, checkOutTime };
}

const HotelBookingContainer = ({ booking }) => {
  const user = useSelector((state) => state.user);
  const token = user?.token;
  const hotelAddress = booking?.hotelSearchResult[0]?.hotelInfoAPI?.response?.HotelDetails[0]?.Address
  const address = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.HotelDetails?.City
  const RateConditions = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.RateConditions
  // const hotelPolicy = booking?.hotelBlockResponse?.HotelPolicyDetail;
  const times = extractCheckInOutTimes(RateConditions);
  const id = booking?._id;
  const bookingStatus = booking?.status;
  const navigate = useNavigate();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { status, hotelSearchResult } = booking;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const styles = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const checkIn = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.CheckIn
  const checkOut = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.CheckOut
  const noOfRooms = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.NoOfRooms
  const Rooms = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.Rooms
  const HotelName = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.HotelDetails?.HotelName
  const BookingId = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.ConfirmationNumber

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReissueModal, setShowReissueModal] = useState(false);
  const [cancelledSuccessModal, setCancelledSuccessModal] = useState(false);
  const [reissueSuccessModal, setReissueSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const parsedChecIn = moment(checkIn)
  const prsedCheckOut = moment(checkOut)
  const noOfNights = prsedCheckOut.diff(parsedChecIn, "days")


  let totalAdults = 0
  let totalChildren = 0
  Rooms?.map((room) => {
    const CustomerDetails = room?.CustomerDetails
    CustomerDetails?.map((detail) => {
      const CustomerNames = detail?.CustomerNames
      CustomerNames.map((name) => {
        if (name?.Type === "Adult") {
          totalAdults++
        } else {
          totalChildren++
        }
      })
    })
  })

  const CheckOut = booking?.hotelBookingDetailsAPI?.response?.BookingDetail?.CheckOut

  const currentDate = new Date();
  let showCancellationButton = false;

  const checkIndateString = new Date(checkIn);
  if (currentDate <= checkIndateString) {
    showCancellationButton = true;
  }

  const handleHotelCancellation = async () => {
    const bookingId = booking?._id;
    const payload = {
      booking: bookingId,
    };
    setLoading(true);
    const res = await cancelHotelBooking(token, payload);
    if (
      res.statusCode < 400 &&
      res?.data?.cancelStatus === "CANCELLATION_REQUESTED"
    ) {
      setCancelledSuccessModal(true);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(t("errorMessages.errorWhileCancellingHotel"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleHotelReissue = async () => {
    const bookingId = booking?._id;
    const payload = {
      booking: bookingId,
    };
    setLoading(true);
    const res = await reissueHotelBooking(token, payload);
    if (
      res.statusCode < 400 &&
      res?.data?.reissueStatus === "REISSUE_REQUESTED"
    ) {
      setReissueSuccessModal(true);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(t("errorMessages.errorWhileReissuingHotel"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCancel = () => {
    setShowCancelModal(false);
    handleHotelCancellation();
    // setShowCancellationDetailsModal(true);
  };
  const handleReissue = () => {
    setShowReissueModal(false);
    handleHotelReissue();
    // setShowCancellationDetailsModal(true);
  };
  const hotelBookingStatusMap = {
    INITIATED: "INITIATED",
    PAYMENT_SUCCESS: "Payment Success",
    PAYMENT_INITIATION_FAILED: "Payment Initiation Failed",
    BOOKING_FAILED: "Booking Failed",
    PAYMENT_FAILED: "Payment Failed",
    PAYMENT_INITIATED: "PAYMENT_INITIATED",
    BOOKED: "Booked",
    CANCELLATION_NOTSET: "CANCELLATION_NOTSET",
    CANCELLATION_INPROGRESS: "Cancellation Inprogress",
    CANCELLATION_FAILED: "Cancellation Rejected",
    CANCELLED: "Cancelled",
    REFUND_FAILED: "Refund Failed",
    PAYMENT_RETURNED: "Payment Returned",
  };


  return (
    <div className={styles.bookingListContainer}>
      <div className={styles.content1}>
        <div className={styles.content1Left}>
          <div className={styles.heading}>
            <ReactSVG src={HotelSvg} />
            {HotelName}
          </div>
          <div className={styles.subheading}>
            {/* <p>{bookingStatus}</p> */}
            {bookingStatus === "CANCELLATION_REQUESTED"
              &&
              // "Cancellation requested"
              t("myProfile.cancellationRequested")
            }
            {bookingStatus === "CANCELLATION_INPROGRESS"
              &&
              // "Cancellation in progress"
              t("myProfile.cancellationInProgress")
            }
            {bookingStatus === "REISSUE_REQUESTED"
              &&
              // "Re-issue requested"
              t("myProfile.reissueRequested")
            }
            {bookingStatus === "REISSUE_INPROGRESS"
              &&
              // "Re-issue in progress"
              t("myProfile.reissueInProgress")
            }
            {bookingStatus === "CANCELLED"
              &&
              // "Cancelled"
              t("myProfile.cancelled")
            }
            {(bookingStatus === "BOOKED"
              || bookingStatus === "CANCELLATION_RETAINED"
              || bookingStatus === "REISSUE_RETAINED"
              || bookingStatus === "REISSUED")
              && !showCancellationButton
              &&
              // "Completed"
              t("myProfile.completed")

            }
            {(bookingStatus === "BOOKED"
              || bookingStatus === "CANCELLATION_RETAINED"
              || bookingStatus === "REISSUE_RETAINED"
              || bookingStatus === "REISSUED")
              && showCancellationButton
              && <p>{t("myProfile.upcoming")}</p>}
            <p>
              ·{noOfRooms}{" "}
              {noOfRooms > 1
                ? `${t("myProfile.rooms")}`
                : `${t("myProfile.room")}`}
            </p>
            <p>
              ·{noOfNights}{" "}
              {noOfNights > 1
                ? `${t("myProfile.nights")}`
                : `${t("myProfile.night")}`}
            </p>
            <p>
              · {t("myProfile.bookingId")} - {BookingId}
            </p>
          </div>
        </div>
        <div className={styles.content1Right}>
          <p
            onClick={() => {
              navigate(`/hotel/booking/?id=${id}&cn=${BookingId}`);
            }}
            className={styles.viewStatus}
          >
            {t("myProfile.viewBookingDetails")}
          </p>
        </div>
      </div>
      <Divider />
      <div className={styles.content2}>
        <div className={styles.content21}>
          <p className={styles.lightText}>{t("myProfile.checkIn")}</p>
          <p className={styles.lightText}>
            {checkIn
              && localStorage.getItem('language') != "AR"
              && new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
                new Date(checkIn)
              )}
            {checkIn
              && localStorage.getItem('language') == "AR"
              && new Intl.DateTimeFormat("ar-sa", { weekday: "long" }).format(
                new Date(checkIn)
              )}
          </p>
          <p className={styles.lightText}>{times.checkInTime}</p>
          <p className={styles.lightText}>
            {
              localStorage.getItem('language') == "AR"
                ? convertISOToArabicDateInDDMMYYY(checkIn)
                : formatDateToDdMmYyyyForBooking(checkIn)
            }
            {/* {formatDateToDdMmYyyyForBooking(checkIn)} */}
          </p>

        </div>
        <div className={styles.content21}>
          <p className={styles.lightText}>{t("myProfile.checkOut")}</p>
          <p className={styles.lightText}>
            {checkOut && localStorage.getItem('language') != "AR"
              && new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
                new Date(checkOut)
              )}
            {checkOut && localStorage.getItem('language') == "AR"
              && new Intl.DateTimeFormat("ar-sa", { weekday: "long" }).format(
                new Date(checkOut)
              )}
          </p>
          <p className={styles.lightText}>{times.checkOutTime}</p>
          <p className={styles.lightText}>
            {
              localStorage.getItem('language') == "AR"
                ? convertISOToArabicDateInDDMMYYY(checkOut)
                : formatDateToDdMmYyyyForBooking(checkOut)
            }
            {/* {formatDateToDdMmYyyyForBooking(checkOut)} */}
          </p>

        </div>
        <div
          className={styles.content21 + " " + styles.addressContainer}
          style={{ width: "25%" }}
        >
          <p className={styles.inlineProperty}>
            <ReactSVG src={LocationSvg} />
            <span className={styles.boldText}>{hotelAddress && hotelAddress}</span>
          </p>
          <p className={styles.inlineProperty}>
            <ReactSVG src={UserSvg} />
            <span className={styles.boldText}>
              {totalAdults}{" "}
              {totalAdults > 1
                ? `${t("myProfile.adults")}`
                : `${t("myProfile.adult")}`}{" "}
              {totalChildren > 0 && (
                <>
                  ,{totalChildren}{" "}
                  {totalChildren > 1
                    ? `${t("myProfile.children")}`
                    : `${t("myProfile.child")}`}
                </>
              )}
            </span>
          </p>
        </div>

        <div className={isMobile ? styles.content22 : styles.content21}>
          <p></p>
          {showCancellationButton
            && (bookingStatus === "BOOKED"
              || bookingStatus === "CANCELLATION_RETAINED"
              || bookingStatus === "REISSUE_RETAINED"
              || bookingStatus === "REISSUED")
            &&
            <p
              className={styles.reissue + " " + styles.hotelCancelButton}
              onClick={() => setShowReissueModal(true)}
              style={{ minWidth: '110px', minHeight: '30px' }}
            >
              {/* Re-issue */}
              {t("myProfile.reissue")}
            </p>
          }
          {showCancellationButton
            && (bookingStatus === "BOOKED"
              || bookingStatus === "CANCELLATION_RETAINED"
              || bookingStatus === "REISSUE_RETAINED"
              || bookingStatus === "REISSUED")
            && (
              <p
                className={styles.cancel + " " + styles.hotelCancelButton}
                onClick={() => setShowCancelModal(true)}
                style={{ minWidth: '110px', minHeight: '30px' }}
              >
                {/* Cancel */}
                {t("myProfile.cancel")}

              </p>
            )}
          {!showCancellationButton
            && (bookingStatus === "BOOKED"
              || bookingStatus === "CANCELLATION_RETAINED"
              || bookingStatus === "REISSUE_RETAINED"
              || bookingStatus === "REISSUED")
            && (
              <p
                className={styles.completed + " " + styles.hotelCancelButton}
                style={{ minWidth: '110px', minHeight: '30px' }}
              >
                {/* Completed */}
                {t("myProfile.completed")}
              </p>
            )}
          {bookingStatus == "CANCELLED"
            && <p
              className={styles.cancelledStatus + " " + styles.hotelStatusButton}
              style={{ minWidth: '110px', minHeight: '30px' }}>
              {/* Cancelled */}
              {t("myProfile.cancelled")}

            </p>}
          {bookingStatus != "CANCELLED"
            && <p className={styles.status + " " + styles.hotelStatusButton}
              style={{ minWidth: '110px', minHeight: '30px' }}>
              {/* Booking Confirm */}
              {t("myProfile.bookingConfirmed")}
            </p>}
        </div>

      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.mainContainer}>
          <DialogTitle id="responsive-dialog-title" className={styles.title}>
            <p className={styles.title}>
              {/* Are you sure you want to cancel hotel? */}
              {t("myProfile.confirmCancelHotel")}
            </p>
          </DialogTitle>
          <DialogActions style={{
            display: 'flex',
            gap: '10px'
          }}>
            <div className={styles.goBackButton} onClick={handleCancel}>
              {/* Yes */}
              {t("myProfile.yes")}
            </div>
            <div
              className={styles.successButton}
              onClick={() => setShowCancelModal(false)}
            >
              {/* No */}
              {t("myProfile.no")}
            </div>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={showReissueModal}
        onClose={() => setShowReissueModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={styles.mainContainer}>
          <DialogTitle id="responsive-dialog-title" className={styles.title}>
            <p className={styles.title}>
              {/* Are you sure you want to re-issue hotel? */}
              {t("myProfile.confirmReissueHotel")}
            </p>
          </DialogTitle>
          <DialogActions
            style={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <div className={styles.goBackButton} onClick={handleReissue}>
              {/* Yes */}
              {t("myProfile.yes")}

            </div>
            <div
              className={styles.successButton}
              onClick={() => setShowReissueModal(false)}
            >
              {/* No */}
              {t("myProfile.no")}

            </div>
          </DialogActions>
        </div>
      </Dialog>
      {loading ? (
        <LoadingBackdrop open={loading} />
      ) : (
        cancelledSuccessModal ? (
          <CancellationSuccess
            cancelledSuccessModal={cancelledSuccessModal}
            setCancelledSuccessModal={setCancelledSuccessModal}
          />
        ) :
          reissueSuccessModal &&
          <ReissueSuccess
            reissueSuccessModal={reissueSuccessModal}
            setReissueSuccessModal={setReissueSuccessModal}
          />
      )}
    </div>
  );
};

export default HotelBookingContainer;
