import React from "react"
import SignupForm from "../../components/header/components/Signup-web/IN"
import { signup } from "../../modules/auth";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SignupVerifyEmail from "../../components/common/Signup-verify-email";
import eventBus from "../../core/eventBus";
import { useTranslation } from "react-i18next";

export default function MobileSignupPage() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const { state } = useLocation()
    console.log('state', state)

    function handleRedirection() {
        if (state?.redirection) {
            window.location.replace(state.redirection)
        } else {
            window.location.replace('/')
        }
    }
    console.log('state', state)
    const submitForm = async (values) => {
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNo: values.number,
            password: values.password,
            deviceToken: "test",
            deviceType: "WEB",
        };
        const response = await signup(data)
        const {
            statusCode,
            data: {
                newCustomer,
                firstName,
                accessToken,
                lastName,
                email,
                profilePic,
                message
            },
        } = response
        if (statusCode === 200) {
            if (window.localStorage) {
                const userDetails = {}
                userDetails.newCustomer = newCustomer;
                userDetails.firstName = firstName;
                userDetails.token = accessToken;
                userDetails.lastName = lastName;
                userDetails.email = email;
                userDetails.profilePic = profilePic;
                window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
            }
            eventBus.emit('open-dialog', <SignupVerifyEmail handleRedirection={handleRedirection} />)

            toast.success(t("successMessages.emailVeified"))
        } else {
            toast.error(message)
        }
    };
    return <SignupForm submitForm={submitForm} redirection={state?.redirection} />
}