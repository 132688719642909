import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelAmenities: {
    padding: "24px 16px",
    borderRadius: "8px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    height: '100%'
  },
  hotelAmenitiesTitle: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "16px",
  },
  hotelAmenitiesListsWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  hotelAmenitiesList: {
    listStyle: "none",
    marginLeft: '20px'
  },
  hotelAmenitiesListItem: {
    lineHeight: 2.2,
    fontSize: "14px",
  },
  hotelAmenitiesViewMoreWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  hotelAmenitiesViewMore: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    padding: "16px",
    marginLeft: "auto",
    marginTop: "32px",
    color: 'black',
    backgroundColor: theme.palette.entityColors.entityColorBackground1,

    "&:hover": {
      cursor: "pointer",
    },
  },

  hotelAmenitiesDialog: {
    padding: "0px 16px 16px 16px",
  },
  hotelAmenitiesDialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    position: "sticky",
    top: "0px",
    paddingTop: "16px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },
  hotelAmenitiesDialogHeaderTitle: {
    fontSize: theme.fontSizes.fontSize1,
    color: theme.palette.textColors.textColorPrimary,
    fontWeight: "700",
  },
  hotelAmenitiesDialogHeaderClose: {
    cursor: "pointer",
  },
  hotelAmenitiesDialogBody: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
  },
  hotelAmenitiesDialogBodyValue: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
  },
}));

export default useStyles;
