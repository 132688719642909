import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#FAFAFA",
  },
  innerContainer: {
    margin: "0px 150px",
    padding: "40px 0px",
  },

  policyCategories: {
    // height: "auto",
    // maxHeight: '70px',
    // marginRight: "5px",
    display: "flex",
    color: "white",
    fontSize: "18px",
    alignItems: "center",
    justifyContent: "center",
    // minWidth: "70px",
    padding: "8px 12px",
    // maxWidth: '100px',
    // overflowY: 'auto',
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },

  policies: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    // fontWeight: "600",
    fontStyle: "normal",
    paddingLeft: "10px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

export default useStyles;
