import React from "react";
import { get } from "lodash";
import useStyles from "./styles";

const AboutHotel = (props) => {
  const styles = useStyles();
  const details = get(props, "hotelDetails", {});
  
  return (
    <div>
      <h2 className={styles.hotelDetailsAboutCaption}>{`About ${get(details, "HotelName", "Hotel")}`}</h2>
      <div className={styles.hotelDetailsAboutDescriptionWrapper} dangerouslySetInnerHTML={{__html:get(details, "Description", <></>)}}/>
    </div>
  );
};

export default AboutHotel;
