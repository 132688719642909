import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bottomButtons: {
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "1px",
    alignSelf: "center",
    marginBottom: "20px",
    position: "fixed",
    bottom: "50px",
    left: "calc(50vW - 60px)",
    // boxShadow: "0px 1px 48px 0px rgba(0, 0, 0, 0.24)",
  },

  filterLayout: {
    display: "flex",
    padding: "12px 30px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },
}));

export default useStyles;
