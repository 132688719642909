import React from 'react'
import EmailConfirmation from '../email-confirmation'

export default function MobileForgotPasswordPage() {
    return <div
        style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
        <div
            style={{
                width: '500px',
                maxWidth: '90vw',
                // padding: '25px',
                margin: '25px',
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px"

            }}>
            <EmailConfirmation />

        </div>
    </div >
}