import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  name: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  price: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  nameAndPriceContainer:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 0px",
  }
}));

export default useStyles;
