import axios from "axios";
import APIs from "../api";
import { async } from "@firebase/util";

export const getFaqs = async (token = "") => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetFaqsAuth(config);
  } else {
    return handleGetFaqsNoAuth();
  }
};
export const getPrivacyPolicy = async (token = "") => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetPrivacyPolicyAuth(config);
  } else {
    return handleGetPrivacyPolicyNoAuth();
  }
};
export const getTermsAndConditions = async (token = "") => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetTermsAndConditionsAuth(config);
  } else {
    return handleGetTermsAndConditionsNoAuth();
  }
};
const handleGetTermsAndConditionsAuth = async (config) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getTermsAndConditionsAuth}`, config);
    return data;
  } catch (error) {
    console.log("Get Faqs Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetTermsAndConditionsNoAuth = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getTermsAndConditionsNOAuth}`);
    return data;
  } catch (error) {
    console.log("Get Faqs No Auth API Error ", error);
  }
};
const handleGetFaqsAuth = async (config) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getFaqsAuth}`, config);
    return data;
  } catch (error) {
    console.log("Get Faqs Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetFaqsNoAuth = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getFaqs}`);
    return data;
  } catch (error) {
    console.log("Get Faqs No Auth API Error ", error);
  }
};
const handleGetPrivacyPolicyAuth = async (config) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getPrivacyPolicyAuth}`, config);
    return data;
  } catch (error) {
    console.log("Get Faqs Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetPrivacyPolicyNoAuth = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getPrivacyPolicyNoAuth}`);
    return data;
  } catch (error) {
    console.log("Get Faqs No Auth API Error ", error);
  }
};

export const getBanners = async (token = "") => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetBannersAuth(config);
  } else {
    return handleGetBannersNoAuth();
  }
};

const handleGetBannersAuth = async (config) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getBannersAuth}`, config);
    return data;
  } catch (error) {
    console.log("Get Banners Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetBannersNoAuth = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getBanners}`);
    return data;
  } catch (error) {
    console.log("Get Banners No Auth API Error ", error);
  }
};

export const getUsps = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getUsps}`);
    return data;
  } catch (error) {
    console.log("Get Usps API Error ", error);
  }
};

export const subscribeToNewsLetter = async (payload) => {
  try {
    const { data } = await axios.post(`${APIs.subscribeToNewsLetter}`, payload);
    return data;
  } catch (error) {
    console.log("Subscribe Newsletter API Error ", error);
    return error.response.data
  }
};

export const getBlogs = async ({ skip, limit, token = "" }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetBlogsAuth(skip, limit, config);
  } else {
    return handleGetBlogsNoAuth(skip, limit);
  }
};

const handleGetBlogsAuth = async (skip, limit, config) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${APIs.getBlogsAuth}?skip=${skip}&limit=${limit}`,
      config
    );
    return data;
  } catch (error) {
    console.log("Get Blogs Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetBlogsNoAuth = async (skip, limit) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getBlogs}?skip=${skip}&limit=${limit}`);
    return data;
  } catch (error) {
    console.log("Get Blogs No Auth API Error ", error);
  }
};

export const getStrings = async (language) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const { data } = await axios.get(
      `${APIs.getStrings}?languageCode=${language}`,
      config
    );
    return data;
  } catch (error) {
    console.log("get string Api error", error);
  }
};

export const getOffers = async ({ limit, skip, token = "" }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetOffersAuth(skip, limit, config);
  } else {
    return handleGetOffersNoAuth(skip, limit);
  }
};

const handleGetOffersAuth = async (skip, limit, config) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${APIs.getOffersAuth}?skip=${skip}&limit=${limit}`,
      config
    );
    return data;
  } catch (error) {
    console.log("Get Offers Auth API Error ", error);
    const response = error.response.data;
    return response;
  }
};

const handleGetOffersNoAuth = async (skip, limit) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getOffers}?skip=${skip}&limit=${limit}`);
    return data;
  } catch (error) {
    console.log("Get Offers No Auth API Error ", error);
  }
};

export const getRecentSearches = async (token = "", uuid) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return getRecentSearchesAuth(config, uuid);
  } else {
    return getRecentSearchesNoAuth(uuid);
  }
};

export const getRecentSearchesAuth = async (config, uuid) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getRecentSearches}?uuid=${uuid}`, config);
    return data;
  } catch (error) {
    console.log("Get recent search API Error ", error);
    const response = error.response.data;
    return response;
  }
};

export const getRecentSearchesNoAuth = async (uuid) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getRecentSearchesNoAuth}?uuid=${uuid}`);
    return data;
  } catch (error) {
    console.log("Get recent search API Error ", error);
  }
};

export const sendTicketsOrVouchersToMail = async ({
  product,
  bookingId,
  token = "",
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${APIs.sendTicketOrVoucherToMail}?product=${product}&bookingId=${bookingId}`,
      config
    );

    return data;
  } catch (error) {
    console.log("Error sending mail: ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const getAboutUs = async (token = "") => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  if (token) {
    return handleGetAboutUsAuth(config);
  } else {
    return handleGetAboutUsNoAuth();
  }
};
const handleGetAboutUsAuth = async (config) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getAboutUsAuth}`, config);
    return data;
  } catch (error) {
    console.log("Get Faqs Auth API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetAboutUsNoAuth = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getAboutUsNOAuth}`);
    return data;
  } catch (error) {
    console.log("Get Faqs No Auth API Error ", error);
  }
};