import React, { useEffect, useState } from "react";
import bookingConfirmIcon from "../../../assets/images/booking confirm_icon (1).svg";
import { Box, Table, Typography } from "@mui/material";
import bookingConfirmBottom from "../../../assets/images/booking-confirm-bottom-icon.svg";
import bulletsIcon from "../../../assets/images/bullets_black.svg";
import lineArrowLeft from "../../../assets/images/Line arrow-left.svg";
import printer from "../../../assets/images/printer.svg";
import download from "../../../assets/images/download.svg";
import share from "../../../assets/images/share.svg";
import { ReactSVG } from "react-svg";
import { Link, useLoaderData, useLocation, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./styles";
import useStyles2 from "./styles2";
import Tooltip from "./Tooltip";
import SelectedHotelDetails from "../../../components/hotel-module/cards/selected-hotel-details";
import { get } from "lodash";
import { useSelector } from "react-redux";
import HotelStepper from "../../../components/hotel-module/stepper";
import { getHotelBookingDetails } from "../../../modules/hotels";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { setUserInfo } from "../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatSARCurrency } from "../../../utils/helpers";
import eventBus from "../../../core/eventBus";
import { getMarkup } from "../../../modules/hotels";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import ShareModal from "../../../components/flight-module/cards/share-modal";
import { useTranslation } from "react-i18next";
import Tippy from "@tippy.js/react";
import { Rings } from "react-loader-spinner";
import dataNotFound from '../../../assets/images/datanotfound.svg'
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import { convertISOToArabicDateInDDMMYYY } from "../../../utils/helpers";

const HotelBookingConfirmation = () => {
  const { pathname } = useLocation();
  const theme = useTheme()
  const { t } = useTranslation();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = user.token;
  const [bookingDetails, setBookingDetails] = useState({});
  const [convenienceFees, setConvenienceFees] = useState(0)
  const [totalPayableAmount, settotalPayableAmount] = useState(0)
  const [couponDiscount, setCouponDiscount] = useState(0)
  const [totalBaseFare, setTotalBaseFair] = useState(0)
  const [loading, setLoading] = useState(true)
  const [taxAmount, setTaxAmount] = useState(0)
  const [emailId, setEmailId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [bookedHotelDetails, setBookedHotelDetails] = useState("")
  const [pdfURL, setPdfURL] = useState("")
  const [bookedRooms, setBookedRoom] = useState("")
  const [ConfirmationNumber, setConfirmationNumber] = useState("")
  const [bookingDate, setBookingDate] = useState("")
  const [showError, setShowError] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false);
  const [markupData, setMarkupData] = useState({});
  const globalFilters = useSelector((state) => state.globalFilters);
  const hotelFilters = globalFilters?.hotelFilters;
  const isMobile = useIsMobile();
  const checkInDateRaw = moment(
    get(hotelFilters, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  );
  const checkOutDateRaw = moment(
    get(hotelFilters, "hotelCheckOutDate", ""),
    "DD/MM/YYYY"
  );
  const noOfNights = checkOutDateRaw.diff(checkInDateRaw, "days");
  const classes = useStyles();
  const styles = useStyles2();

  console.log('bookingDate', bookingDate)
  const parsedcreatedAt = moment(bookingDate);
  let formattedCreatedAt;
  if (localStorage.getItem('language') == 'AR') {
    formattedCreatedAt = convertISOToArabicDateInDDMMYYY(bookingDate)
  } else {
    formattedCreatedAt = parsedcreatedAt.format("D MMM YYYY");
  }

  const searchURL = location?.search;
  const searchParam = new URLSearchParams(searchURL);
  const status = searchParam.get("status")
  const id = searchParam.get("id")
  const cn = searchParam.get("cn")
  // const { id, status } = useParams();
  async function getBookingData() {
    const data = await getHotelBookingDetails(token, id, cn);
    const BookingDetail = data?.data?.bookingDetails?.BookingDetail
    const hotelInfoAPI = data?.data?.bookingDetails?.hotelInfoAPI?.response?.HotelDetails[0]
    const pdfURL = data?.data?.pdfURL?.voucherUrl
    const BookingDate = BookingDetail?.BookingDate
    const ConfirmationNumber = BookingDetail?.ConfirmationNumber
    const hotelDetails = {
      CheckIn: BookingDetail?.CheckIn,
      CheckOut: BookingDetail?.CheckOut,
      HotelName: BookingDetail?.HotelDetails?.HotelName,
      // RateConditions: BookingDetail?.RateConditions,
      RateConditions: hotelInfoAPI?.Description,
      Rooms: BookingDetail?.Rooms
    }
    const Rooms = BookingDetail?.Rooms
    const convenienceFeeAmount = data?.data?.bookingDetails?.convenienceFeeAmount
    const totalPayableAmount = data?.data?.bookingDetails?.totalPayableAmount
    const couponDiscount = data?.data?.bookingDetails?.couponDiscount
    const taxAmount = data?.data?.bookingDetails?.taxAmount
    const emailId = data?.data?.bookingDetails?.emailId
    const phoneNumber = data?.data?.bookingDetails?.phoneNumber
    const totalBaseFair = data?.data?.bookingDetails?.totalBaseFare

    setBookingDetails(BookingDetail)
    setBookedHotelDetails(hotelDetails)
    setBookedRoom(Rooms)
    setConfirmationNumber(ConfirmationNumber)
    setBookingDate(BookingDate)
    setConvenienceFees(convenienceFeeAmount)
    setTaxAmount(taxAmount)
    settotalPayableAmount(totalPayableAmount)
    setCouponDiscount(couponDiscount)
    setTotalBaseFair(totalBaseFair)
    setEmailId(emailId)
    setPhoneNumber(phoneNumber)
    setLoading(false)
    setPdfURL(pdfURL)
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    if (statusCode != 200) {
      setShowError(true)
    }
    setBookingDetails(data);
    return data;
  }

  useEffect(() => {
    if (status == "SUCCESS") {
      getBookingData();
    }
  }, []);

  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, [location]);
  let bookedHotelcheckInDateRaw = moment(bookedHotelDetails?.CheckIn);
  let bookedHotelCheckOutDateRaw = moment(bookedHotelDetails?.CheckOut);


  const bookedHotelsNumberOfNights =
    bookedHotelCheckOutDateRaw.diff(bookedHotelcheckInDateRaw, "days");


  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfURL;
    link.download = "voucher.pdf";
    link.target = "_blank";
    link.click();
  };

  const handleShare = () => {
    eventBus.emit(
      "open-bottomSheet",
      <ShareModal pdfUrl={pdfURL} />
    );
  };
  function hanldeOpenCancelltionTooltipDialog(CancelPolicies) {
    eventBus.emit('open-dialog', <>
      <div style={{
        padding: '10px 10px',
        // paddingBottom: '10px',
        background: 'white',
        position: 'relative',
        borderRadius: '10px',
      }}>

        <table
          className={styles.table + " " + styles.tippyTable}
          style={{ background: 'white', borderCollapse: 'collapse' }}
        >
          <thead

            className={styles.tableHead}
            style={{ background: '#E6E6FA', border: '2px solid grey' }}

          >
            <tr
              className={styles.tableRow}
            >
              <th
                align="left"
                className={styles.tableHeaderCell + " " + styles.tableCell}
                style={{ border: '2px solid grey' }}
              >
                {/* Cancelled on or After */}
                {t("bookingConfirmationHotel.cancelledOnOrAfter")}

              </th>

              <th
                align="left"
                className={styles.tableHeaderCell + " " + styles.tableCell}
                style={{ border: '2px solid grey' }}

              >
                {/* Cancellation Charges */}
                {t("bookingConfirmationHotel.cancellationCharges")}

              </th>
            </tr>
          </thead>
          <tbody
          >
            {CancelPolicies &&
              CancelPolicies.map(
                (policy, index) => {
                  let {
                    FromDate,
                    CancellationCharge,
                    ChargeType,
                  } = policy;
                  // FromDate = moment(FromDate);
                  // FromDate =
                  //   FromDate.format("DD--MM--YYYY");
                  // ToDate = moment(ToDate);
                  // ToDate =
                  //   ToDate.format("DD--MM--YYYY");
                  return (
                    <tr
                      className={styles.tableRow}
                      key={index}
                    >
                      <td
                        className={styles.tableDataCell + " " + styles.tableCell}
                        align="left"
                      >
                        {FromDate}
                      </td>

                      <td
                        className={styles.tableDataCell + " " + styles.tableCell}
                        align="left"
                      >
                        {CancellationCharge}{" "}
                        {ChargeType}

                      </td>
                    </tr>
                  );
                }
              )}
            <tr style={{ border: "2px solid grey" }}>
              <td
                align="left"
                colSpan="3"
                className={styles.tableDataCell + " " + styles.tableCell}
              >
                No Show will attract full cancellation
                charge unless otherwise specified
              </td>
            </tr>
            <tr style={{ border: "2px solid grey" }}>
              <td
                align="left"
                colSpan="3"
                className={styles.tableDataCell + " " + styles.tableCell}
              >
                Early check out will attract full
                cancellation charge unless otherwise
                specified
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </>)
  }
  return (
    <div className={classes.mainContainer}>
      <HotelStepper activeStep={4} />{" "}
      <div
        className={classes.backToBookingButton}
        style={{ display: "flex", marginBottom: "5px", cursor: "pointer" }}
        onClick={() => {
          if (status == "SUCCESS") {
            navigate("/bookings");
          } else {
            navigate("/");
          }
        }}
      >
        <ReactSVG src={lineArrowLeft} style={{ display: "inline" }} />{" "}
        <span
          style={{
            fontFamily: "lato",
            fontSize: "14px",
            fontWeight: "400",
            marginLeft: "5px",
            color: "#344054",
          }}
        >
          {status == "SUCCESS"
            ? `${t("bookingConfirmationHotel.backToBookings")}`
            : "Home"}
        </span>
      </div>
      {status == "SUCCESS" ? (
        <>
          {loading &&
            <div
              style={{
                width: '90vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Rings
                height="150"
                width="150"
                color="#1B1D52"
                radius="6"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
              />
            </div>

          }
          {!loading && !showError &&
            <div className={classes.successContainer}>
              <div
                style={{ margin: "20px, 0" }}
                className={classes.bookingConfirmedLogoAndTextContainer}
              >
                <ReactSVG src={bookingConfirmIcon} />
                <p className={classes.bookingConfirmedText}>
                  {t("bookingConfirmationHotel.bookingConfirmed")}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "20px 0",
                  minHeight: "40px",
                  padding: "10px",
                  alignItems: "center",
                  gap: '10px'
                }}
                className={classes.bookingIdAndbookDateContainer}
              >
                <p className={classes.hotelBookinghasbeenCompletedText}>
                  {" "}
                  {t("bookingConfirmationHotel.bookingCompletionMessage")}{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <p className={classes.paymentCompletedText}>
                    {t("bookingConfirmationHotel.paymentCompletionMessage")}
                  </p>
                  <span>
                    {" "}
                    <span
                      className={classes.bookingIdText}
                      style={{ fontWeight: "bolder" }}
                    >
                      {t("bookingConfirmationHotel.bookingId")}
                      {/* Booking Id: */}
                      {" "}
                    </span>
                    {ConfirmationNumber}
                  </span>
                </div>
              </div>
              <div
                style={{ margin: "20px 0" }}
                className={classes.bookingIdAndDate}
              >
                <span>
                  <span style={{ fontWeight: "bolder" }}>
                    {t("bookingConfirmationHotel.bookedOn")}
                  </span>{" "}
                  {formattedCreatedAt}
                </span>
              </div>
              <div className={classes.margin20 + " " + "HotelDetail"}>
                <SelectedHotelDetails
                  details={{

                  }}
                  confirmationScreenDetails={true}
                  bookedHotelDetails={bookedHotelDetails}
                />
              </div>
              <div
                className={classes.margin20 + " " + "roomDetails"}
                style={{ overflowX: "auto" }}
              >
                <div className={classes.guestDetailsContainer}>
                  <p className={classes.guestDetailText}>
                    {t("bookingConfirmationHotel.roomDetails")}
                  </p>
                </div>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.room")}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        align="left"
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.travellers")}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        align="left"
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.nights")}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        align="left"
                        className={classes.tableHeaderCell}
                      >
                        {/* Booking Id */}
                        {t("bookingConfirmationHotel.bookingId")}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        align="left"
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.cancellationPolicy")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookedRooms && bookedRooms?.length > 0 &&
                      bookedRooms.map((room) => {
                        const CancelPolicies = room?.CancelPolicies
                        const CustomerDetails = room?.CustomerDetails
                        const Name = room?.Name[0]
                        let travellerCount = 0;
                        CustomerDetails.map((detail) => {
                          detail.CustomerNames.map((name) => {
                            travellerCount++;
                          })
                        })
                        return <TableRow className={classes.tableRow}>
                          <TableCell
                            className={classes.tableDataCell}
                            align="left"
                          >
                            {Name}
                          </TableCell>
                          <TableCell
                            className={classes.tableDataCell}
                            align="left"
                          >
                            {travellerCount}
                          </TableCell>
                          <TableCell
                            className={classes.tableDataCell}
                            align="left"
                          >
                            {/* {hotelSearch?.noOfNights} */}
                            {bookedHotelsNumberOfNights && bookedHotelsNumberOfNights}
                          </TableCell>
                          <TableCell
                            className={classes.tableDataCell}
                            align="left"
                          >
                            {ConfirmationNumber}
                          </TableCell>
                          <TableCell
                            className={classes.tableDataCell}
                            align="left"
                          >
                            {!isMobile && <Tippy
                              arrow={true}
                              placement="bottom-end"
                              content={
                                <>
                                  <table
                                    className={styles.table + " " + styles.tippyTable}
                                    style={{ background: 'white', borderCollapse: 'collapse' }}
                                  >
                                    <thead

                                      className={styles.tableHead}
                                      style={{ background: '#E6E6FA', border: '2px solid grey' }}

                                    >
                                      <tr
                                        className={styles.tableRow}
                                      >
                                        <th
                                          align="left"
                                          className={styles.tableHeaderCell + " " + styles.tableCell}
                                          style={{ border: '2px solid grey' }}
                                        >
                                          {/* Cancelled on or After */}
                                          {t("bookingConfirmationHotel.cancelledOnOrAfter")}

                                        </th>

                                        <th
                                          align="left"
                                          className={styles.tableHeaderCell + " " + styles.tableCell}
                                          style={{ border: '2px solid grey' }}

                                        >
                                          {/* Cancellation Charges */}
                                          {t("bookingConfirmationHotel.cancellationCharges")}

                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                    >
                                      {CancelPolicies &&
                                        CancelPolicies.map(
                                          (policy, index) => {
                                            let {
                                              FromDate,
                                              CancellationCharge,
                                              ChargeType,
                                            } = policy;
                                            // FromDate = moment(FromDate);
                                            // FromDate =
                                            //   FromDate.format("DD--MM--YYYY");
                                            // ToDate = moment(ToDate);
                                            // ToDate =
                                            //   ToDate.format("DD--MM--YYYY");
                                            return (
                                              <tr
                                                className={styles.tableRow}
                                                key={index}
                                              >
                                                <td
                                                  className={styles.tableDataCell + " " + styles.tableCell}
                                                  align="left"
                                                >
                                                  {FromDate}
                                                </td>

                                                <td
                                                  className={styles.tableDataCell + " " + styles.tableCell}
                                                  align="left"
                                                >
                                                  {CancellationCharge}{" "}
                                                  {ChargeType}

                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      <tr style={{ border: "2px solid grey" }}>
                                        <td
                                          align="left"
                                          colSpan="3"
                                          className={styles.tableDataCell + " " + styles.tableCell}
                                        >
                                          No Show will attract full cancellation
                                          charge unless otherwise specified
                                        </td>
                                      </tr>
                                      <tr style={{ border: "2px solid grey" }}>
                                        <td
                                          align="left"
                                          colSpan="3"
                                          className={styles.tableDataCell + " " + styles.tableCell}
                                        >
                                          Early check out will attract full
                                          cancellation charge unless otherwise
                                          specified
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              }
                            >
                              <p className={styles.cancellationPolicyText}>
                                {/* Cancellation Policy */}
                                {t("bookingConfirmationHotel.cancellationPolicy")}
                              </p>
                            </Tippy>}
                            {isMobile &&
                              <p
                                className={styles.cancellationPolicyText}
                                onClick={
                                  (e) => {
                                    hanldeOpenCancelltionTooltipDialog(CancelPolicies)
                                  }
                                }
                              >
                                {/* Cancellation Policy */}
                                {t("bookingConfirmationHotel.cancellationPolicy")}
                              </p>}
                          </TableCell>
                        </TableRow>

                      })
                    }
                  </TableBody>
                </Table>
              </div>
              <div style={{ margin: "20px 0" }}>
                <div className={classes.guestDetailsContainer}>
                  <p className={classes.guestDetailText}>
                    {t("bookingConfirmationHotel.guestDetails")}
                  </p>
                </div>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.guestName")}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        align="left"
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.ageGroup")}
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookedRooms && bookedRooms?.length > 0 &&
                      bookedRooms.map((room) => {
                        return room.CustomerDetails.map((customer) => {
                          const customerNames = []
                          customer.CustomerNames.map((name) => {

                            const {
                              Title,
                              FirstName,
                              LastName,
                              Type,
                            } = name
                            if (Type == "Adult") {
                              customerNames.unshift(name)
                            } else {
                              customerNames.push(name)
                            }
                          })
                          return customerNames.map((name) => {
                            const {
                              Title,
                              FirstName,
                              LastName,
                              Type,
                            } = name

                            return <TableRow className={classes.tableRow}>
                              <TableCell align="left">
                                {FirstName} {LastName}
                              </TableCell>
                              <TableCell align="left">
                                {Type}
                              </TableCell>

                            </TableRow>
                          })
                        })
                      })


                    }
                  </TableBody>
                </Table>
              </div>
              <div className={classes.margin20}>
                <div className={classes.guestDetailsContainer}>
                  <p className={classes.guestDetailText}>
                    {t("bookingConfirmationHotel.contactDetails")}
                  </p>
                </div>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        className={classes.tableHeaderCell}
                        align="left"
                      >
                        {t("bookingConfirmationHotel.email")}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bolder" }}
                        align="left"
                        className={classes.tableHeaderCell}
                      >
                        {t("bookingConfirmationHotel.phoneNo")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <TableRow className={classes.tableRow}>
                      <TableCell
                        className={classes.tableDataCell}
                        align="left"
                      >
                        {emailId}
                      </TableCell>
                      <TableCell
                        className={classes.tableDataCell}
                        align="left"
                      >
                        {phoneNumber}
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </div>

              <div className={classes.margin20}>
                <Box className={classes.paymentGreyContainer}>
                  <Typography
                    sx={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    {t("bookingConfirmationHotel.paymentDetails")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    padding: "16px",
                    alignItems: "flex-start",
                    gap: "26px",
                    borderBottom: "1px solid #D0D5DD",
                    background: "#FFF",
                  }}
                >
                  <Typography
                    sx={{
                      width: "105px",
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "bolder",
                    }}
                  >
                    {t("bookingConfirmationHotel.baseFare")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    {t("bookingConfirmationHotel.currencySymbol")}{" "}
                    {formatSARCurrency(parseFloat(totalBaseFare).toFixed(2))}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    padding: "16px",
                    alignItems: "flex-start",
                    gap: "26px",
                    borderBottom: "1px solid #D0D5DD",
                    background: "#FFF",
                  }}
                >
                  <Typography
                    sx={{
                      width: "105px",
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "bolder",
                    }}
                  >
                    {t("bookingConfirmationHotel.taxesAndFees")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    {t("bookingConfirmationHotel.currencySymbol")}{" "}
                    {formatSARCurrency(parseFloat(taxAmount).toFixed(2))}
                  </Typography>
                </Box>
                {convenienceFees ? (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "16px",
                      alignItems: "flex-start",
                      gap: "26px",
                      borderBottom: "1px solid #D0D5DD",
                      background: "#FFF",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "105px",
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "bolder",
                      }}
                    >
                      {t("bookingConfirmationHotel.convenienceFee")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      {t("bookingConfirmationHotel.currencySymbol")}{" "}
                      {formatSARCurrency(parseFloat(convenienceFees).toFixed(2))}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
                {couponDiscount ? (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "16px",
                      alignItems: "flex-start",
                      gap: "26px",
                      borderBottom: "1px solid #D0D5DD",
                      background: "#FFF",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "105px",
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "bolder",
                      }}
                    >
                      {t("bookingConfirmationHotel.discount")} (-)
                    </Typography>
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      -{t("bookingConfirmationHotel.currencySymbol")}{" "}
                      {formatSARCurrency(parseFloat(couponDiscount).toFixed(2))}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}

                <Box
                  sx={{
                    display: "flex",
                    padding: "16px",
                    alignItems: "flex-start",
                    gap: "26px",
                    borderBottom: "1px solid #D0D5DD",
                    background: "#FFF",
                  }}
                >
                  <Typography
                    sx={{
                      width: "105px",
                      color: "#667085",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "bolder",
                    }}
                  >
                    {t("bookingConfirmationHotel.total")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#344054",
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    {t("bookingConfirmationHotel.currencySymbol")} {parseFloat(totalPayableAmount).toFixed(2)}
                  </Typography>
                </Box>
              </div>
              <div>
                <p

                  className={classes.paymentGreyContainer}
                >
                  {t("bookingConfirmationHotel.importantInformation")}
                </p>

                <div
                  className="margin20"
                  style={{
                    // background: "#FFF6E5",
                    background: "#fff",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    gap: "8px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                      }}
                    >
                      {/* Remarks */}
                      {t("bookingConfirmationHotel.remarks")}
                    </p>
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "12px",
                      }}
                    >
                      {/* Please note that while your booking had been confirmed and is
                      guaranteed, the rooming list with your name may not be
                      adjusted in the hotel&apos;s reservation system until closer
                      to arrival */}
                      {t("bookingConfirmationHotel.remark1")}
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                      }}
                    >
                      {/* Booking Terms & Conditions */}
                      {t("bookingConfirmationHotel.bookingTermsAndConditions")}
                    </p>
                    <ul
                      style={{
                        color: "#667085",
                        fontSize: "12px",
                      }}
                    >
                      <li>
                        {/* You must present a photo ID at the time of check in. Hotel
                        may ask for credit card or cash deposit for the extra
                        services at the time of check in. */}
                        {t("bookingConfirmationHotel.term1")}
                      </li>
                      <li>
                        {/* All extra charges should be collected directly from clients
                        prior to departure such as parking, phone calls, room
                        service, city tax, etc. */}
                        {t("bookingConfirmationHotel.term2")}
                      </li>
                      <li>
                        {/* We don&apos;t accept any responsibility for additional
                        expenses due to the changes or delays in air, road, rail,
                        sea or indeed of any other causes, all such expenses will
                        have to be borne by passengers. */}
                        {t("bookingConfirmationHotel.term3")}
                      </li>
                      <li>
                        {/* In case of wrong residency & nationality selected by user at
                        the time of booking the supplement charges may be applicable
                        and need to be paid to the hotel by guest on check in/ check
                        out. */}
                        {t("bookingConfirmationHotel.term4")}
                      </li>
                      <li>
                        {/* Any special request for bed type, early check in, late check
                        out, smoking rooms, etc are not guaranteed as subject to
                        availability at the time of check in. */}
                        {t("bookingConfirmationHotel.term5")}
                      </li>
                      <li>
                        {/* Early check out will attract full cancellation charges
                        unless otherwise specified. */}
                        {t("bookingConfirmationHotel.term6")}
                      </li>
                    </ul>
                  </div>
                  <div className="hotelPolicy">
                    <p
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                      }}
                    >
                      {/* Hotel Policies */}
                      {t("bookingConfirmationHotel.hotelPolicy")}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: bookedHotelDetails?.RateConditions }}
                      style={{
                        color: "#667085",
                        fontSize: "12px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.printDownlodSharebuttonsContainer}
              // style={{
              //   display: "flex",
              //   justifyContent: "flex-end",
              //   marginTop: "30px",
              //   paddingBottom: "30px",
              // }}
              >
                <div
                  className={classes.printDownloadAndShareButtons}
                  style={{
                    display: "flex",
                    gap: "8px",
                    // [theme.breakpoints.down('sm')]:{
                    //   flexDirection: 'column'
                    // }
                  }}
                >
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "86px",
                      height: "41px",
                      borderRadius: "10px",
                      border: "1px solid #1B1D52",
                      padding: "12px 16px 12px 16px",
                      gap: "8px",
                      color: "#1B1D52",
                      background: "white",
                      borderColor: "#1B1D52",
                    }}

                    onClick={() => handleDownload()}
                  >
                    <ReactSVG src={printer} style={{ display: "inline", fill: '#1B1D52' }} />
                    <span>
                      {/* Print */}
                      {t("bookingConfirmationHotel.print")}
                    </span>
                  </button>
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "120px",
                      height: "41px",
                      borderRadius: "10px",
                      border: "1px solid #1B1D52",
                      padding: "12px 16px 12px 16px",
                      gap: "8px",
                      color: "#1B1D52",
                      background: "white",
                      borderColor: "#1B1D52",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDownload()}
                  >
                    <ReactSVG src={download} style={{ display: "inline", fill: '#1B1D52' }} />
                    <span>
                      {/* Download */}
                      {t("bookingConfirmationHotel.download")}
                    </span>
                  </button>
                  {isMobile && (
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "91px",
                        height: "41px",
                        borderRadius: "10px",
                        border: "1px solid #1B1D52",
                        padding: "12px 16px 12px 16px",
                        gap: "8px",
                        color: "#1B1D52",
                        background: "white",
                        borderColor: "#1B1D52",
                      }}
                      onClick={() => handleShare()}
                    >
                      <ReactSVG src={share} style={{ display: "inline", fill: '#1B1D52' }} />
                      <span>
                        {/* Share */}
                        {t("bookingConfirmationHotel.share")}
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          }
          {showError && <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img src={dataNotFound} />
            <p>
              {/* Booking data not found. Please try again! */}
              {t("bookingConfirmationHotel.bookingDataNotFound")}
            </p>
          </div>}
        </>

      ) : (
        <div className={classes.errorContainer}>
          <h1 style={{ margin: "15px" }}> Booking Detail </h1>
          <p style={{ margin: "15px", color: "red" }}>
            {/* Apologies, your hotel booking has failed. Please contact our support
            team for assistance. */}
            {t("bookingConfirmationHotel.apologiYourHotelBookingFailed")}
          </p>
        </div>
      )
      }
    </div >)
}

export default HotelBookingConfirmation
