import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "10px",
    border: "1px solid #08F",
    background: "#F2F7FB",
    padding: "24px",
    display: "inline-flex",
    justifyContent: "space-between",
    margin: "0px 10px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px 0px 10px",
    },
  },
  innerContainer: {
    gap: "12px",
    display: "inline-flex",
    alignItems: "center",
  },
  tickButton: {
    width: "12px",
    height: "12px",
    color: "#08F",
  },
  secureTripText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
  },
  removeContainer: {
    borderRadius: "6px",
    border: "1px solid #08F",
    boxShadow: "0px 2px 16px 0px rgba(0, 136, 255, 0.24)",
    color: "#08F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    cursor: "pointer",
  },
}));

export default useStyles;
