import React, { useEffect, useState } from "react";
import { Box, Checkbox, Divider, Slider, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getPackages } from "../../../../../../modules/package";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../../../../../assets/images/x-close.svg";

import {
  updateBudget,
  updateCategories,
  updateCities,
  updateClearAll,
  updateDays,
  updateFilteredPackage,
  updateNights,
  updateWithFlights,
  updateWithoutFlights,
  updatePackagesSearchGoingOn
} from "../../../../../../utils/slices/packageSlice";
import { ReactSVG } from "react-svg";
import eventBus from "../../../../../../core/eventBus";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const PackagesSidebar = () => {
  const dispatch = useDispatch();
  const {
    categoryIds,
    cities,
    minDaysFilter,
    maxDaysFilter,
    minNightsFilter,
    maxNightsFilter,
    minPersonPriceFilter,
    maxPersonPriceFilter,
    withFlight,
    withoutFlight,
  } = useSelector((state) => state.searchPackage);
  const [filterData, setFilterData] = useState([]);
  const [showCategories, setShowCategories] = useState(true);
  const [showFlightCategories, setShowFlightCategories] = useState(true);
  const [showDayDuration, setShowDayDuration] = useState(false);
  const [showNightDuration, setShowNightDuration] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [showPlaces, setShowPlaces] = useState(false);
  const [isWithFlight, setIsWithFlight] = useState(false);
  const [isWithoutFlight, setIsWithoutFlight] = useState(false);
  const [totalPackages, setTotalPackages] = useState(0);
  const [totalFilteredPackages, setTotalFilteredPackages] = useState(0);
  const location = useLocation();
  const path = location.pathname;
  const isMobile = useIsMobile();
  useEffect(() => {
    setIsWithFlight(false);
    setIsWithoutFlight(false);
    handleGetPackages();
    // dispatch(updateClearAll());
  }, []);

  const handleGetPackages = async () => {
    dispatch(updatePackagesSearchGoingOn(true))
    const data = await getPackages({ limit: 100, skip: 0 });
    dispatch(updateFilteredPackage(data?.packages));
    const {
      maxDays,
      minDays,
      maxNights,
      minNights,
      maxPersonPrice,
      minPersonPrice,
      packagesCount,
      totalPackagesCount,
    } = data;
    dispatch(updatePackagesSearchGoingOn(false))
    setTotalFilteredPackages(packagesCount);
    setTotalPackages(totalPackagesCount);
    setFilterData(data);
    setDayDuration([minDays, maxDays]);
    setNightDuration([minNights, maxNights]);
    setBuget([minPersonPrice, maxPersonPrice]);
  };

  const {
    cityCount: places,
    packageCategories,
    minDays,
    maxDays,
    minPersonPrice,
    maxPersonPrice,
    minNights,
    maxNights,
  } = filterData;
  const [nightDuration, setNightDuration] = React.useState([
    minNights,
    maxNights,
  ]);
  const [dayDuration, setDayDuration] = React.useState([minDays, maxDays]);
  const [budget, setBuget] = React.useState([minPersonPrice, maxPersonPrice]);

  const handleDayDuration = (_, newValue) => {
    if (!Array.isArray(newValue)) return;
    setDayDuration(newValue);
    dispatch(updateDays({ start: newValue[0], end: newValue[1] }));
  };

  const handleNightDuration = (_, newValue) => {
    if (!Array.isArray(newValue)) return;
    setNightDuration(newValue);
    dispatch(updateNights({ start: newValue[0], end: newValue[1] }));
  };

  const handleBudget = (_, newValue) => {
    if (!Array.isArray(newValue)) return;
    setBuget(newValue);
    dispatch(updateBudget({ start: newValue[0], end: newValue[1] }));
  };

  const handleCategoryChange = ({ _id: id, name }) => {
    dispatch(updateCategories({ id, name }));
  };

  const handleCategoryCheck = (id) => {
    const index =
      categoryIds && categoryIds.filter((category) => category.id === id);
    if (index.length !== 0) return true;
    return false;
  };

  const handlePlaceChange = ({ i: id, city: name }) => {
    dispatch(updateCities({ id, name }));
  };

  const handlePlaceCheck = (id) => {
    const index = cities && cities.filter((city) => city.id === id);
    if (index.length !== 0) return true;
    return false;
  };

  const handleWithFlight = (e) => {
    setIsWithFlight(e.target.checked);
    if (e.target.checked === false && withoutFlight === false) {
      dispatch(updateWithFlights(true));
      dispatch(updateWithoutFlights(true));
    } else if (e.target.checked === true && isWithoutFlight === false) {
      dispatch(updateWithoutFlights(false));
      dispatch(updateWithFlights(e.target.checked));
    } else {
      dispatch(updateWithFlights(e.target.checked));
    }
  };

  const handleWithoutFlight = (e) => {
    setIsWithoutFlight(e.target.checked);
    if (e.target.checked === false && withFlight === false) {
      dispatch(updateWithFlights(true));
      dispatch(updateWithoutFlights(false));
    } else if (e.target.checked === true && isWithFlight === false) {
      dispatch(updateWithFlights(false));
      dispatch(updateWithoutFlights(e.target.checked));
    } else {
      dispatch(updateWithoutFlights(e.target.checked));
    }
  };

  useEffect(() => {
    handleGetFilteredPackages();
  }, [
    categoryIds,
    cities,
    minDaysFilter,
    maxDaysFilter,
    minNightsFilter,
    maxNightsFilter,
    minPersonPriceFilter,
    maxPersonPriceFilter,
    withFlight,
    withoutFlight,
  ]);

  const handleGetFilteredPackages = async () => {
    dispatch(updatePackagesSearchGoingOn(true))
    const categories = [];
    const city = [];
    for (let i = 0; i < categoryIds.length; i++)
      categories.push(categoryIds[i].id);
    for (let i = 0; i < cities.length; i++) city.push(cities[i].name);
    const data = await getPackages({
      limit: 100,
      skip: 0,
      minDaysFilter,
      maxDaysFilter,
      minNightsFilter,
      maxNightsFilter,
      minPersonPriceFilter,
      maxPersonPriceFilter,
      withFlight,
      withoutFlight,
      categoryIds: categories,
      cities: city,
    });
    dispatch(updateFilteredPackage(data?.packages));
    dispatch(updatePackagesSearchGoingOn(false))
    setTotalFilteredPackages(data?.packagesCount);
    setTotalPackages(data?.totalPackagesCount);
  };

  const handleClearAll = () => {
    dispatch(updateClearAll());
    handleGetPackages();
    setIsWithFlight(false);
    setIsWithoutFlight(false);
  };

  const { t } = useTranslation();
  const getLocale = useSelector((state) => state.locale);
  return places && packageCategories ? (
    <Box
      sx={{
        padding: "24px 16px",
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box sx={{ display: "inline-flex", justifyContent: "space-between" }}>
        {isMobile && (
          <div onClick={() => eventBus.emit("close-bottomSheet")}>
            <ReactSVG src={closeIcon} />
          </div>
        )}
        {categoryIds?.length > 0 && (
          <Typography
            sx={{
              color: "344054",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              wordSpacing: "2px",
            }}
          >
            {totalFilteredPackages} of {totalPackages} Packages
          </Typography>
        )}

        <Typography
          sx={{
            color: "#4037acd6",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            textTransform: "uppercase",
            cursor: "pointer",
          }}
          onClick={handleClearAll}
        >
          {t("packages.clearAll")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setShowCategories(!showCategories)}
        >
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t("packages.categories")}
          </Typography>
          {showCategories ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {showCategories &&
          packageCategories.map((category) => {
            const { name, _id } = category;
            return (
              <Box
                sx={{ display: "inline-flex", justifyContent: "space-between" }}
                key={_id}
              >
                <Typography
                  sx={{
                    color: "#525A6A",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                >
                  {name}
                </Typography>
                <Checkbox
                  checked={handleCategoryCheck(_id) ? true : false}
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#D0D5DD",
                    "&.Mui-checked": {
                      color: "#1b1d52",
                    },
                  }}
                  onChange={() => handleCategoryChange({ _id, name })}
                />
              </Box>
            );
          })}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setShowFlightCategories(!showFlightCategories)}
        >
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t("packages.flights")}
          </Typography>
          {showFlightCategories ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {showFlightCategories && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <Box
              sx={{ display: "inline-flex", justifyContent: "space-between" }}
            >
              <Typography
                sx={{
                  color: "#525A6A",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                {t("packages.withFlight")}
              </Typography>
              <Checkbox
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "#D0D5DD",
                  "&.Mui-checked": {
                    color: "#1b1d52",
                  },
                }}
                checked={isWithFlight}
                onChange={handleWithFlight}
              />
            </Box>
            <Box
              sx={{ display: "inline-flex", justifyContent: "space-between" }}
            >
              <Typography
                sx={{
                  color: "#525A6A",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                {t("packages.withoutFlight")}
              </Typography>
              <Checkbox
                sx={{
                  width: "16px",
                  height: "16px",
                  color: "#D0D5DD",
                  "&.Mui-checked": {
                    color: "#1b1d52",
                  },
                }}
                checked={isWithoutFlight}
                onChange={handleWithoutFlight}
              />
            </Box>
          </div>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setShowDayDuration(!showDayDuration)}
        >
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t("packages.durationInDays")}
          </Typography>
          {showDayDuration ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {showDayDuration && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: "#525A6A",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              ({dayDuration[0]}D - {dayDuration[1]}D)
            </Typography>
            <Slider
              size="small"
              value={dayDuration}
              onChange={handleDayDuration}
              valueLabelDisplay="auto"
              disableSwap
              min={minDays}
              max={maxDays}
            />
          </Box>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setShowNightDuration(!showNightDuration)}
        >
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t("packages.durationInNights")}
          </Typography>
          {showNightDuration ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {showNightDuration && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: "#525A6A",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              ({nightDuration[0]}N - {nightDuration[1]}N)
            </Typography>
            <Slider
              size="small"
              value={nightDuration}
              onChange={handleNightDuration}
              valueLabelDisplay="auto"
              disableSwap
              min={minNights}
              max={maxNights}
            />
          </Box>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setShowBudget(!showBudget)}
        >
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t("packages.budgetPerPerson")}
          </Typography>
          {showBudget ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {showBudget && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Slider
              size="small"
              value={budget}
              onChange={handleBudget}
              valueLabelDisplay="auto"
              disableSwap
              min={minPersonPrice}
              max={maxPersonPrice}
            />
            <Box
              sx={{ display: "inline-flex", justifyContent: "space-between" }}
            >
              <Typography
                sx={{
                  color: "#525A6A",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}
              >
                {localStorage.getItem('language') == "AR"
                  ? budget[1].toLocaleString("en-In")
                  : budget[0].toLocaleString("en-In")}
              </Typography>
              <Typography
                sx={{
                  color: "#525A6A",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}
              >
                {localStorage.getItem('language') == "AR"
                  ? budget[0].toLocaleString("en-In")
                  : budget[1].toLocaleString("en-In")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setShowPlaces(!showPlaces)}
        >
          <Typography
            sx={{
              color: "#4037acd6",
              fontFamily: "Lato, sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t("packages.places")}
          </Typography>
          {showPlaces ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
        {showPlaces &&
          places &&
          places.map((place, i) => {
            let { city, count } = place;
            city = city?.toLowerCase();
            return (
              <Box sx={{ display: "inline-flex", gap: "8px" }} key={i}>
                <Checkbox
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "#D0D5DD",
                    "&.Mui-checked": {
                      color: "#1b1d52",
                    },
                  }}
                  checked={handlePlaceCheck(i) ? true : false}
                  onChange={() => handlePlaceChange({ i, city })}
                />
                <Typography
                  sx={{
                    color: "#525A6A",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                >
                  {city?.charAt(0).toUpperCase() + city?.slice(1)} ({count})
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default PackagesSidebar;
