import React from "react";
import { Divider } from "@mui/material";
import { get } from "lodash";
import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import { useState } from "react";
import useStyles from "./styles";
// import Tooltip from "./Tooltip";
import Tooltip from "../../../header/components/my-bookings/booking-list/hotel/booking-details/Tooltip";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatSARCurrency } from "../../../../utils/helpers";
import { ReactSVG } from "react-svg";
import Tippy from "@tippy.js/react";
import InfoSVG from "../../../../assets/images/info-circle.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
let reRenderCount = 0;

const SelectedHotelFareBreakdown = (props) => {
  const path = useLocation();
  const { t } = useTranslation();
  const pathName = path.pathname;
  const classes = useStyles();
  const roomsDetails = get(props, "details.roomsDetails", {});
  let TotalFareRoundOff = Number(parseFloat(get(roomsDetails, "TotalFareRoundOff", 0)).toFixed(2));
  let TotalTaxRoundOff = Number(parseFloat(get(roomsDetails, "TotalTaxRoundOff", 0).toFixed(2)));
  let TotalBaseFare = Number(parseFloat(TotalFareRoundOff - TotalTaxRoundOff).toFixed(2))
  const noOfRooms = useSelector((state) => state.hotelRooms.rooms.length)
  const details = props.details;
  const convenienceFee = props?.details?.convenienceFee;
  const couponValue = props?.details?.couponValue;
  const isNonEmptyCouponValue =
    typeof couponValue === "object" &&
    couponValue !== null &&
    Object.keys(couponValue).length > 0;

  const cancellationPolicies = get(roomsDetails, "CancelPolicies", "");
  const [showFare, setShowFare] = useState(false);
  const filterDetails = details.filterValues;

  const handleShowFareClick = (index) => {
    // const updatedShowFare = showFare.map((value, i) =>
    //   i === index ? !value : value
    // );
    // console.log("updatedShowFare",updatedShowFare)
    setShowFare(!showFare);
  };


  const checkInDateRaw = moment(
    get(filterDetails, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  );
  const checkOutDateRaw = moment(
    get(filterDetails, "hotelCheckOutDate", ""),
    "DD/MM/YYYY"
  );
  const noOfNights = checkOutDateRaw.diff(checkInDateRaw, "days");

  reRenderCount++;
  return (
    <div className={classes.mainContainer}>
      <div className={classes.fareBreakdownAndPolicyContainer}>
        <p className={classes.fareBreakdownText}>
          {t("fareRuleCard.fareBreakdown")}
        </p>
        <Tooltip
          text={
            <>
              <table
                style={{
                  margin: "10px",
                  // width: "430px",
                  borderCollapse: "collapse", // Add this to collapse table borders
                }}
                className={(classes.table, classes.cancellationTable)}
              >
                <thead
                  className={classes.cancellationTable}
                  style={{
                    background: "#E6E6FA",
                    // width: "430px",
                    margin: "50px 0",
                  }}
                >
                  <tr
                    style={{
                      // width: "430px",
                      margin: "50px",
                    }}
                  >
                    <th
                      align="left"
                      style={{
                        width: "100px",
                        border: "2px solid grey",
                        // Add border style here
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      className={classes.tableHeaderCell}
                    >
                      {t("hotelDetails.cancelledOnOrAfter")}
                    </th>
                    {/* <th
                      align="left"
                      style={{
                        width: "100px",
                        border: "2px solid grey", // Add border style here
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      className={classes.tableHeaderCell}
                    >
                      {t("hotelDetails.cancelledOnOrBefore")}
                    </th> */}
                    <th
                      align="left"
                      style={{
                        width: "100px",
                        border: "2px solid grey", // Add border style here
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      className={classes.tableHeaderCell}
                    >
                      {t("hotelDetails.cancellationCharges")}
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={classes.cancellationTable}
                // style={{ width: "430px" }}
                >
                  {cancellationPolicies &&
                    cancellationPolicies.map((policy, index) => {
                      let { FromDate, CancellationCharge, ChargeType } = policy;
                      // console.log('FromDate', FromDate)
                      // FromDate = moment(FromDate, 'YYYY-MM-DD HH:mm:ss');
                      // console.log('FromDate', FromDate)
                      // FromDate = FromDate.format("DD--MM--YYYY");
                      // ToDate = moment(ToDate);
                      // ToDate = ToDate.format("DD--MM--YYYY");
                      return (
                        <tr
                          // style={{ width: "430px" }}
                          className={classes.tableRow}
                          key={index}
                        >
                          <td
                            className={classes.tableDataCell}
                            align="left"
                            style={{
                              width: "100px",
                              border: "2px solid grey", // Add border style here
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {FromDate}
                          </td>
                          {/* <td
                            className={classes.tableDataCell}
                            align="left"
                            style={{
                              width: "100px",
                              border: "2px solid grey", // Add border style here
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {ToDate}
                          </td> */}
                          <td
                            className={classes.tableDataCell}
                            align="left"
                            style={{
                              width: "100px",
                              border: "2px solid grey", // Add border style here
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {CancellationCharge} {ChargeType}
                            {/* {ChargeType == 1 && Currency}
                            {ChargeType == 2 && "%"}
                            {ChargeType == 3 && "Nights"} */}
                          </td>
                        </tr>
                      );
                    })}
                  <tr style={{ border: "2px solid grey" }}>
                    <td
                      align="left"
                      colSpan="3"
                      style={{
                        border: "2px solid grey", // Add border style here
                        textAlign: "left",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {t("hotelDetails.noShowPolicy")}
                    </td>
                  </tr>
                  <tr style={{ border: "2px solid grey" }}>
                    <td
                      align="left"
                      colSpan="3"
                      style={{
                        border: "2px solid grey", // Add border style here
                        textAlign: "left",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {t("hotelDetails.earlyCheckOutPolicy")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        >
          <p className={classes.cancellationPolicyText}>
            {t("hotelDetails.cancellationPolicy")}
          </p>
        </Tooltip>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
        <div className={classes.roomPriceContainer}>
          <p className={classes.roomText}>
            {noOfRooms}  {t("addContactDetailsScreenHotel.room")}  &nbsp; x &nbsp;
            {noOfNights} {" "} {noOfNights > 1
              ? `${t("addContactDetailsScreenHotel.nights")}`
              : `${t("addContactDetailsScreenHotel.night")}`}

            {showFare === true ? (
              <ExpandLess
                style={{ color: "#1b1d52", cursor: "pointer" }}
                onClick={() => handleShowFareClick(0)}
              />
            ) : (
              <ExpandMore
                style={{ color: "#1b1d52", cursor: "pointer" }}
                onClick={() => handleShowFareClick(0)}
              />
            )}
          </p>
          <p className={classes.roomPrice}>
            {`${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
              TotalFareRoundOff
            )} `}
          </p>
        </div>
        {showFare === true ? (
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div className={classes.baseFarePriceContainer}>
              <p className={classes.baseFareText}>
                {t("fareRuleCard.baseFare")}
              </p>
              <p className={classes.baseFare}>
                {`${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
                  TotalBaseFare
                )}`}
              </p>
            </div>
            <div className={classes.taxFeeContainer}>
              <p className={classes.taxFeesText}>
                {t("fareRuleCard.hotelTaxesAndFees")}
              </p>
              <p className={classes.taxFee}>
                {`${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
                  TotalTaxRoundOff
                )}`}
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {isNonEmptyCouponValue ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className={classes.conveniencefeetext}>
            {t("fareRuleCard.discount")} {"(-)"}
          </p>
          <p className={classes.roomPrice}>{`- ${t(
            "hotelDetails.currencySymbol"
          )} 
          ${formatSARCurrency(parseFloat(couponValue.amount).toFixed(2))}`}</p>
        </div>
      ) : (
        ""
      )}
      {convenienceFee ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Tippy
            arrow={true}
            placement="bottom-start"
            animation="shift-away"
            content={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  padding: "16px",
                  background: "#344054",
                  borderRadius: "4px",
                  width: "250px",
                  marginTop: "-20px",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    color: "#FFF",
                    fontWeight: 500,
                  }}
                >
                  {t("fareRuleCard.nonRefundableConvenienceFee")}{" "}
                  {formatSARCurrency(parseFloat(
                    convenienceFee / roomsDetails?.Name?.length
                  ).toFixed(2))}{" "}
                  {t("fareRuleCard.perRoom")}
                </p>
              </div>
            }
          >
            <p
              className={classes.conveniencefeetext}
              style={{
                display: "inline-flex",
                gap: "4px",
                marginTop: "2px",
              }}
            >
              {t("fareRuleCard.convenienceFee")}
              <ReactSVG src={InfoSVG} />
            </p>
          </Tippy>
          <p className={classes.roomPrice}>{`${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
            convenienceFee
          )}`}</p>
        </div>
      ) : (
        ""
      )}
      <Divider style={{ borderStyle: "dashed" }} />
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "8px",
        }}
      >
        <p className={classes.totalPriceText}>{t("fareRuleCard.totalPrice")}</p>
        <p className={classes.totalPrice}>
          {convenienceFee && isNonEmptyCouponValue
            ? `${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
              TotalFareRoundOff +
              convenienceFee -
              Number(parseFloat(couponValue.amount).toFixed(2))
            )}`
            : convenienceFee
              ? `${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
                TotalFareRoundOff + convenienceFee
              )}`
              : isNonEmptyCouponValue
                ? `${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
                  TotalFareRoundOff - Number(parseFloat(couponValue.amount).toFixed(2))
                )}`
                : `${t("hotelDetails.currencySymbol")} ${formatSARCurrency(
                  TotalFareRoundOff
                )}`}
        </p>
      </div>
    </div>
  );
};

export default SelectedHotelFareBreakdown;
