import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileCityField: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  textFieldFromWrapper: {
    width: "100%",
    padding: "14px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "8px",
    borderRadius: "10px 10px 0px 0px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  textFieldFromSingleWrapper: {
    width: "100%",
    padding: "14px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "8px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  textField: {
    border: "none",
    fontSize: theme.fontSizes.fontSize2,
    width: "inherit",
    // pointerEvents: "none",

    "&:focus": {
      outline: "none",
    },

    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize2,
    },
  },
  textFieldToWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "14px",
    columnGap: "8px",
    borderRadius: "0px 0px 10px 10px",
    borderLeft: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    borderRight: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    borderBottom: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  swapIconWrapper: {
    width: "34px",
    position: "absolute",
    right: "30px",
    top: "calc(50% - 17px)",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid 1b1d52",
    // backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    color: "#fff",
  },

  swapIconWrapperArabic: {
    width: "34px",
    position: "absolute",
    left: "30px",
    top: "calc(50% - 17px)",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid 1b1d52",
    // backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    color: "#fff",
  },

  airportOptions: {
    padding: "20px 16px",
    backgroundColor: "#FAFAFA",
    borderBottom: "1px solid #D0D5DD",
    borderRight: "1px solid #D0D5DD",
    borderLeft: "1px solid #D0D5DD",
    marginTop: "-5px",
    zIndex: 100,
  },
  airportOptionsList: {
    maxHeight: "400px",
    overflowY: "scroll",
    listStyle: "none",
  },
  airportOptionsTitle: {
    marginBottom: "12px",
  },
  airportOptionAirportInfo: {
    display: "flex",
    flexDirection: "column",
  },
  airportOptionsListOption: {
    padding: "12px 8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "12px",
    boxSizing: "border-box",
    borderBottom: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  airportOptionsListOptionLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "8px",
  },
  airportOptionAirportName: {
    // width: "200px",
    fontSize: "14px",
    fontWeight: "400",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
  },
  airportOptionAirportCityName: {
    fontSize: theme.fontSizes.fontSize2,
    display: "flex",
    alignItems: "end",
    gap: "2px",
    marginLeft: "2px",
  },
  airportOptionsListOptionRight: {
    padding: "8px",
    backgroundColor: theme.palette.entityColors.entityColorBackgroundLightBlue,
    color: theme.palette.entityColors.entityColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
  },

  departureCalendar: {
    marginTop: "16px",
  },
}));

export default useStyles;
