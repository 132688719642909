import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    background: "#FAFAFA",
    gap: "20px",
    justifyContent: "center",
    width: "100%",
    paddingRight: "120px",
    paddingLeft: "120px",
    paddingTop: "20px",
    paddingBottom: "20px",

    [theme.breakpoints.up("md")]: {
      minHeight: "600px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100vW",
      display: "inline-flex",
    },
    // [theme.breakpoints.down("md")]: {
    //   paddingRight: "0px",
    //   paddingLeft: "0px",
    //   minHeight: "0px",
    //   width: "100vW",
    // },
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  layoutLeftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  layoutRightContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "40%",
  },
  fareRuleContainerDRT: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px",
  },
  fareRuleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px",
  },
  insuranceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tripInsurance: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tripInsuranceAmount: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baggageContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  extraBaggage: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  extraBaggagePrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  mainPriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  mainPriceHeading: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  mainpriceValue: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
  },
  FairDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  baseFareAndTaxesFessContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  fullFairDetailsContainer: {
    marginTop: "-120px",
    background: "#E6E6FA",
    boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.14)",
    width: "100%",
    zIndex: 110,
  },
  container: {
    position: "fixed",
    bottom: "0",
    zIndex: 90,
  },
  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  baseFareText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  fareDetails: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #D0D5DD",
  },
  baseFare: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFees: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFessContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  taxesAndFeesText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },

  fairContainer: {
    display: "flex",
    width: "100vW",
    padding: "16px",
    background: "#FFF",
    boxShadow: "0px -8px 12px 0px rgba(0, 0, 0, 0.12)",
    justifyContent: "space-between",
  },
  fairCardLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
  },
  fairLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  totalText: {
    color: "#667085",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  fair: {
    color: "#344054",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
  },
  bookNowButton: {
    display: "inline-flex",
    padding: "10px 14px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    background: "#1b1d52",
    border: "none",
    color: "#FFF",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  expandIcon: {
    marginRight: "10px",
    color: "#4037acd6",
  },
}));
export default useStyles;
