import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelFilters: {
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  hotelFiltersFilter: {
    display: "flex",
    flexDirection: "column",
    rowGap: "6px",
  },
  hotelFiltersFilterLabel: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "500",
    color: theme.palette.textColors.textColorPrimary,
  },
  hotelFiltersFilterLabelPrimary: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "600",
    color: theme.palette.entityColors.entityColorPrimary,
  },
  hotelFiltersFilterCheckboxGroup: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  hotelFiltersFilterCheckboxWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  hotelFiltersFilterCheckbox: {
    accentColor: `${theme.palette.entityColors.entityColorPrimary}`,
    width: "14px",
    height: "14px",
    cursor: "pointer",
  },
  hotelFiltersFilterInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    borderRadius: "12px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    columnGap: "8px",
  },
  hotelFiltersFilterInputField: {
    border: "none",
    width: "100%",
    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize1,
      color: theme.palette.textColors.textColorPrimary,
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  hotelFiltersAccordion: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  hotelFiltersAccordionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  hotelFiltersAccordionBody: {
    width: "100%",
    display: "none",
  },
  hotelFiltersAccordionBodyOpen: {
    display: "flex",
    flexDirection: "column",
    columnGap: "12px",
  },
  hotelFiltersPriceTextFieldGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  hotelFiltersPriceTextField: {
    width: "106px",
    padding: "16px",
    borderRadius: "12px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  hotelFiltersOffers: {
    display: "flex",
    flexDirection: "column",
  },
  hotelFiltersOffersBody: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    paddingTop: "16px",
  },
  hotelFiltersRating: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    paddingTop: "16px",
  },
  hotelFiltersRatingCheckbox: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },
  hotelFiltersRatingLabel: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
    color: theme.palette.textColors.textColorPrimary,
    alignContent: "center",
  },
  filterPriceText: {
    fontFamily: "lato",
    fontSize: "16px",
  },
  applyText :{
    color: "#4037acd6",
    cursor: "pointer",
    // fontSize: "14px",
    fontFamily: "Lato",
    // fontWeight: "500"
  },
  MuiSliderRoot : {
    color:"#1b1d52 !important"
  }
}));

export default useStyles;
