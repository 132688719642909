import React from "react";
import { SearchPageHeaderView } from "../../views/SearchPageHeader";

const SearchPageHeaderContainer = props => {
  return (
    <SearchPageHeaderView {...props}/>
  );
};

SearchPageHeaderContainer.propTypes = {};

export default SearchPageHeaderContainer;
