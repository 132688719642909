import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import TravelDate from "../../cards/travel-date";
import closeSvg from "../../../../../assets/images/x-close.svg";
import SearchAirport from "../../flight-search-widget/dropdowns/search-airport";
import {
  handleMultiCityDatesHome,
  removeError,
  updateAnotherCity,
  updateFlightFrom,
  updateFlightTo,
  updateTotalSelectionForMultiCity,
} from "../../../../../utils/slices/searchFlightSlice";
import "./style.css";
import {
  handleMultiCityDates,
  updateModifyAnotherCity,
  updateModifyFlightFrom,
  updateModifyFlightTo,
  updateModifyTotalSelectionForMultiCity,
} from "../../../../../utils/slices/modifySearchFlightSlice";
import { find, get } from "lodash";
import {
  formatParsedDate,
  formatParsedDay,
} from "../../../../../utils/helpers";
import Error from "../../../../flight-module/error";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
const MultiCity = ({
  setShowCalendar,
  handleDateChange,
  datesList,
  setShowFlightClassDropdown,
  setShowPassengersComponent,
  showSourceOptions,
  setShowSourceOptions,
  showDestinationOptions,
  setShowDestinationOptions,
}) => {
  const {
    flightTo,
    multiCityDates,
    errors,
    flightDepartureDate,
    totalSelectionForMultiCity,
  } = useSelector((state) => state.searchFlight);

  const dispatch = useDispatch();
  const [multiCity, setMultiCity] = useState(
    totalSelectionForMultiCity == 3 ? [1, 2] : [1]
  );
  const flightDepartureDateParts = flightDepartureDate.split("/");
  const [firstDate] = multiCityDates.filter((date) => date.key === 1);
  const firstDateParts = firstDate?.date.split("/");
  const styles = useStyles();
  const { t } = useTranslation();
  const title = t("flightSearchWidget.departureDate");
  const metaTitle = t("flightSearchWidget.selectDepartureDate");
  return (
    <>
      {multiCity.map((key) => {
        const dateObj = find(datesList, (_d) => _d.key == key);
        const dateValue = get(dateObj, "date", "");
        return (
          <div className="multi-city-contents-home-2" key={key}>
            <div className={styles.multiCityCitySearch}>
              <SearchAirport
                searchAirportId={key}
                showSourceOptions={showSourceOptions}
                setShowSourceOptions={setShowSourceOptions}
                showDestinationOptions={showDestinationOptions}
                setShowDestinationOptions={setShowDestinationOptions}
              />
              {key === 1 &&
                errors &&
                errors
                  .filter((error) => error.id === "multi-city-2-city")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1.05,
              }}
            >
              <div style={{ height: "112px" }}>
                <TravelDate
                  title={title}
                  date={dateValue ? formatParsedDate(dateValue) : ""}
                  day={dateValue ? formatParsedDay(dateValue) : ""}
                  minDate={
                    key == 1
                      ? flightDepartureDateParts &&
                        new Date(
                          parseInt(flightDepartureDateParts[2], 10),
                          parseInt(flightDepartureDateParts[1], 10) - 1,
                          parseInt(flightDepartureDateParts[0], 10)
                        )
                      : key == 2
                      ? firstDateParts
                        ? new Date(
                            parseInt(firstDateParts[2], 10),
                            parseInt(firstDateParts[1], 10) - 1,
                            parseInt(firstDateParts[0], 10)
                          )
                        : flightDepartureDateParts &&
                          new Date(
                            parseInt(flightDepartureDateParts[2], 10),
                            parseInt(flightDepartureDateParts[1], 10) - 1,
                            parseInt(flightDepartureDateParts[0], 10)
                          )
                      : ""
                  }
                  metaTitle={metaTitle}
                  setShowCalendar={setShowCalendar}
                  value={dateValue}
                  onDateChange={(date) => {
                    if (key == 1) {
                      dispatch(handleMultiCityDatesHome([]));
                    }
                    handleDateChange(date, key);
                  }}
                  setShowFlightClassDropdown={setShowFlightClassDropdown}
                  setShowPassengersComponent={setShowPassengersComponent}
                  setShowSourceOptions={setShowSourceOptions}
                  setShowDestinationOptions={setShowDestinationOptions}
                />
              </div>
              {key === 1 &&
                errors &&
                errors
                  .filter((error) => error.id === "multi-city-1")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
              {key === 2 &&
                errors &&
                errors
                  .filter((error) => error.id === "multi-city-2")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
            </div>
            <div style={{ display: "flex", flex: 3.58 }}>
              {key === 1 ? (
                multiCity?.length === 1 && (
                  <>
                    <div
                      className="multi-city-add-another-city"
                      onClick={() => {
                        setMultiCity([1, 2]);
                        dispatch(updateAnotherCity(true));
                        dispatch(updateModifyAnotherCity(true));
                        dispatch(updateTotalSelectionForMultiCity(3));
                        dispatch(updateModifyTotalSelectionForMultiCity(3));
                        if (flightTo.length > key) {
                          dispatch(
                            updateFlightFrom({ id: 2, data: flightTo[1] })
                          );
                          dispatch(
                            updateModifyFlightFrom({ id: 2, data: flightTo[1] })
                          );
                        }
                      }}
                    >
                      <div className="another-city-text">
                        <span className="plus-icon">+</span>{" "}
                        {t("flightSearchWidget.addAnotherCity")}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    ></div>
                  </>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <div className="multi-city-divider"></div>
                  <div
                    className="multi-city-cross-icons"
                    onClick={() => {
                      const multicityDatesLocal =
                        multiCityDates.length === 0
                          ? []
                          : multiCityDates[0].key === 1
                          ? [multiCityDates[0]]
                          : [];
                      setMultiCity([1]);
                      dispatch(updateAnotherCity(false));
                      dispatch(updateModifyAnotherCity(false));
                      dispatch(updateFlightFrom({ id: 2, data: {} }));
                      dispatch(updateModifyFlightFrom({ id: 2, data: {} }));
                      dispatch(updateFlightTo({ id: 2, data: {} }));
                      dispatch(updateModifyFlightTo({ id: 2, data: {} }));
                      dispatch(handleMultiCityDates(multicityDatesLocal));
                      dispatch(handleMultiCityDatesHome(multicityDatesLocal));
                      dispatch(removeError({ id: 2 }));
                      dispatch(removeError({ id: "multi-city-2" }));
                      dispatch(updateTotalSelectionForMultiCity(2));
                      dispatch(updateModifyTotalSelectionForMultiCity(2));
                      const flightDetailsData = JSON.parse(
                        window.localStorage.getItem("flightDetails")
                      );
                      const modifySearchFlightDetails = JSON.parse(
                        window.localStorage.getItem("modifySearchFlightDetails")
                      );
                      if (flightDetailsData) {
                        flightDetailsData.multiCityDates = multicityDatesLocal;
                        window.localStorage.setItem(
                          "flightDetails",
                          JSON.stringify(flightDetailsData)
                        );
                      }
                      if (modifySearchFlightDetails) {
                        modifySearchFlightDetails.multiCityDates =
                          multicityDatesLocal;
                        window.localStorage.setItem(
                          "modifySearchFlightDetails",
                          JSON.stringify(modifySearchFlightDetails)
                        );
                      }
                    }}
                  >
                    <ReactSVG src={closeSvg} />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MultiCity;

MultiCity.propTypes = {
  setShowCalendar: PropTypes.func,
  showCalendar: PropTypes.bool,
  handleDateChange: PropTypes.func,
  datesList: PropTypes.array,
};
