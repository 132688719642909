import React, { useEffect, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PropTypes from "prop-types";
import "./style.css";
import { Calendar } from "react-date-range";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { addDays } from "date-fns";
import useStyles from "./style";
import * as locales from 'react-date-range/dist/locale';


const TravelDate = ({
  title,
  date = "",
  day = "",
  metaTitle,
  onDateChange,
  enableDateRange,
  onClick,
  minDate: minValue,
  setShowCheckInError,
  setShowCheckOutError,
  setShowCalendar,
  showCalendar,
  sentFrom,
  setShowFlightClassDropdown,
  setShowPassengersComponent,
  setShowSourceOptions,
  setShowDestinationOptions,
  travelRef,
}) => {
  const uniqueId = nanoid();
  const calendarWrapperClass = classNames("calender-wrapper", uniqueId);
  const styles = useStyles();

  const [dateState, setDateState] = useState({
    showCalander: false,
    uniqueId,
    dateValue: new Date(),
  });

  const toggleCalander = (value) => {
    setDateState((prev) => ({ ...prev, showCalander: value }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const [calendarWrapperRef] =
        document.getElementsByClassName("calender-wrapper");

      if (calendarWrapperRef && !calendarWrapperRef?.contains(event.target)) {
        toggleCalander(false);
      }
    };

    if (dateState.showCalander) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateState.showCalander]);

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.transform = "rotate(180deg)"
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }else{
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }
  });
  return (
    <>
      <div
        ref={travelRef}
        className="travel-date-home"
        onClick={(e) => {
          if (!enableDateRange) {
            toggleCalander(true);
            // e.stopPropagation();
          } else {
            onClick();
          }
          if (sentFrom && sentFrom == "hotel") {
            setShowCheckInError(false);
            setShowCheckOutError(false);
            setShowCalendar(!showCalendar);
          } else {
            setShowFlightClassDropdown(false);
            setShowPassengersComponent(false);
            setShowSourceOptions([false, false, false]);
            setShowDestinationOptions([false, false, false]);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "12px",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className={styles.titleText}>{title}</div>
            <CalendarMonthIcon
              style={{
                height: "18px",
                width: "18px",
                color: "#344054",
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <div style={{ width: "100%" }}>
              {date.length ? (
                <p
                  style={{
                    color: "#344054",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  {date.substring(0, 2)}
                  <span
                    style={{
                      color: "#344054",
                      fontSize: "0.8rem",
                      fontWeight: "700",
                    }}
                  >
                    {date.substring(2, date.length)}
                  </span>
                </p>
              ) : (
                <p
                  style={{
                    color: "#667085",
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                >
                  {metaTitle}
                </p>
              )}
            </div>
            {date.length > 0 && (
              <div
                style={{
                  color: "#344054",
                  fontSize: "0.9rem",
                }}
              >
                {day}
              </div>
            )}
          </div>
        </div>
        {dateState.showCalander && (
          <Calendar
            className={calendarWrapperClass}
            date={
              !dateState.dateValue ? minValue && minValue : dateState.dateValue
            }
            minDate={minValue ? minValue : new Date()}
            locale={
              localStorage.getItem("language") == "AR"
                ? locales["arSA"]
                : locales["enUS"]
            }
            showSelectionPreview={true}
            showMonthAndYearPickers={false}
            onChange={(value) => {
              setDateState((prev) => ({
                ...prev,
                dateValue: value,
                showCalander: false,
              }));
              onDateChange(value);
            }}
          />
        )}
      </div>
    </>
  );
};

export default TravelDate;
TravelDate.displayName = "Travel Date Calender";

TravelDate.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  day: PropTypes.string,
  metaTitle: PropTypes.string,
  setShowCalendar: PropTypes.func,
  onDateChange: PropTypes.func,
  onClick: PropTypes.func,
  enableDateRange: PropTypes.bool,
};
