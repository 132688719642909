import React, { useEffect, useRef, useState } from "react";
import MultiCity from "./multi-city";
import SearchIcon from "../../../../../../../assets/images/search-lg.svg";
import TravelDate from "./cards/travel-date";
import { useDispatch, useSelector } from "react-redux";
import FlightClass from "./dropdowns/flight-class";
import SearchAirport from "./dropdowns/search-airport";
import TotalTravellers from "./dropdowns/total-persons";
import { DateRangePicker, Calendar } from "react-date-range";
import {
  flightTravelTypeData,
  flightTravelTypeDataArabic,
  flightTypeData,
  flightTypeDataArabic,
} from "../../../../../../../config";
import {
  addError,
  addModifySearchedFlights,
  handleLoader,
  handleMultiCityDates,
  handleSearchApplicable,
  removeError,
  updateModifyDates,
  updateModifyFlightFrom,
  updateModifyFlightTravelType,
  updateModifyFlightType,
  updateModifyTotalSelectionForMultiCity,
} from "../../../../../../../utils/slices/modifySearchFlightSlice";
import PropTypes from "prop-types";
import { addDays } from "date-fns";
import {
  formatDate,
  formatDateToDdMmYyyy,
  formatDay,
  formatDateToDdMmmYyyy,
  formatDayOfFormattedDate,
} from "../../../../../../../utils/helpers";
import "./style.css";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";
import { clearBookedFlights } from "../../../../../../../utils/slices/bookedFlightSlice";
import { isEmpty, map, find, constant } from "lodash";
import Error from "../../../../../error";
import { ReactSVG } from "react-svg";
import {
  updateQueryFlightFrom,
  updateQueryFlightTo,
} from "../../../../../../../utils/slices/searchFlightQuerySlice";
import useStyles from "./styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as locales from 'react-date-range/dist/locale';
import { convertToArabicDateInDDMMYYY } from "../../../../../../../utils/helpers";
import { getArabicDayName } from "../../../../../../../utils/helpers";


const FlightSearchWidget = ({
  activeColor,
  nonActiveColor,
  setShowModifySearch,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getLocale = useSelector((state) => state.locale);
  const {
    flightTravelType,
    flightType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightClassType,
    flightTotalTravellers: {
      totalCount,
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
    searchApplicable,
    flightReturnDate,
    multiCityDates,
    errors,
  } = useSelector((state) => state.modifySearchFlight);
  const calendarRef = useRef();
  const travelDateRef = useRef();

  const [flightDepartureDateParts, setFightDepartureDateParts] = useState(
    flightDepartureDate ? flightDepartureDate.split("/") : ""
  );
  const [travelType, setTravelType] = useState(Number(flightTravelType));
  const [type, setType] = useState(flightType);
  const [dateSource, setDateSource] = useState("");
  const [isNonStopTrue, setIsNonStopTrue] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFlightClassDropdown, setShowFlightClassDropdown] = useState(false);
  const [showPassengersComponent, setShowPassengersComponent] = useState(false);
  const [showSourceOptions, setShowSourceOptions] = useState([
    false,
    false,
    false,
  ]);
  const [showDestinationOptions, setShowDestinationOptions] = useState([
    false,
    false,
    false,
  ]);
  const [returnDate, setReturnDate] = useState(
    formatDateToDdMmmYyyy(flightReturnDate ? flightReturnDate : "")
  );

  const [returnDay, setReturnDay] = useState(
    formatDayOfFormattedDate(flightReturnDate ? flightReturnDate : "")
  );
  const [departureDate, setDepartureDate] = useState(
    formatDateToDdMmmYyyy(flightDepartureDate)
  );
  const [departureDay, setDepartureDay] = useState(
    formatDayOfFormattedDate(flightDepartureDate)
  );

  useEffect(() => {
    if (flightDepartureDate) {
      setFightDepartureDateParts(flightDepartureDate?.split("/"));
    }
  }, [flightDepartureDate]);

  const [multicityDateList, setMulticityDateList] = useState(multiCityDates);

  const styles = useStyles();
  const handleFlightTravelType = (e) => {
    const value = Number(e.target.value);
    if (value === 1) {
      dispatch(removeError({ id: "round-trip" }));
      setReturnDate("");
      setReturnDate("");
      dispatch(
        updateModifyDates({
          returnDate: "",
          departureDate: formatDateToDdMmYyyy(departureDate),
        })
      );
    }

    setTravelType(value);
    dispatch(updateModifyFlightTravelType(value));
    if (value === 3) {
      dispatch(removeError({ id: "round-trip" }));
      dispatch(updateModifyFlightFrom({ id: 1, data: flightTo[0] }));
    }
  };

  const handleFlightType = (e) => {
    const nonStop = isNonStopTrue ? false : true;
    setIsNonStopTrue(nonStop);
    const value = nonStop ? e.target.value : "";
    setType(value);
    dispatch(updateModifyFlightType(value));
  };

  const addSearchFlightDetailsToLocalStorage = (value, returnDate) => {
    const data = {
      flightTravelType: value ? value : flightTravelType,
      flightType,
      flightFrom,
      flightTo,
      flightDepartureDate,
      flightReturnDate: returnDate ? returnDate : flightReturnDate,
      totalCount,
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
      flightClassType,
      multiCityDates,
    };

    window.localStorage.setItem(
      "modifySearchFlightDetails",
      JSON.stringify(data)
    );
  };

  const handleSearchClick = async () => {
    for (let i = 0; i < flightFrom.length; i++) {
      if (
        flightFrom[i]?._id &&
        flightTo[i]?._id &&
        flightFrom[i]?._id === flightTo[i]?._id
      ) {
        dispatch(
          addError({
            id: i,
            errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
          })
        );
        return;
      }
    }
    if (errors.length === 0) {
      if (
        Object.keys(flightFrom?.[0]).length !== 0 &&
        Object.keys(flightTo?.[0]).length !== 0
      ) {
        if (flightTravelType === 1) {
          if (flightDepartureDate.length === 0) {
            dispatch(
              addError({
                id: "one-way",
                errorMessage: `${t("errorMessages.selectDepartureDate")}`,
              })
            );
            return;
          } else {
            const nonStop = flightType == 1 ? true : false;
            const url =`/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}&tt=O&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`;
            if(url != location.pathname + location.search){
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateModifyTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(flightTravelType);
              dispatch(handleLoader(true));
              dispatch(clearBookedFlights());
              dispatch(addModifySearchedFlights([]));
              dispatch(handleSearchApplicable(!searchApplicable));
              navigate(url);
            }
          }
        } else if (flightTravelType === 2) {
          if (flightDepartureDate.length === 0) {
            dispatch(
              addError({
                id: "one-way",
                errorMessage: `${t("errorMessages.selectDepartureDate")}`,
              })
            );
            return;
          } else if (flightReturnDate.length === 0) {
            dispatch(
              addError({
                id: "round-trip",
                errorMessage: `${t("errorMessages.selectReturnDate")}`,
              })
            );
            return;
          } else {
            const nonStop = flightType == 1 ? true : false;
            const url =`/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightTo[0].code}-${flightFrom[0].code}-${flightReturnDate}&tt=R&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`;
            if(url != location.pathname + location.search) {
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateModifyTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(flightTravelType);
              dispatch(handleLoader(true));
              dispatch(clearBookedFlights());
              dispatch(addModifySearchedFlights([]));
              dispatch(handleSearchApplicable(!searchApplicable));
              navigate(url);
            }
          }
        } else {
          if (
            Object.keys(flightFrom?.[2]).length !== 0 &&
            Object.keys(flightTo?.[2]).length !== 0
          ) {
            if (
              Object.keys(flightFrom?.[1]).length !== 0 &&
              Object.keys(flightTo?.[1]).length !== 0
            ) {
              if (flightDepartureDate.length === 0) {
                dispatch(
                  addError({
                    id: "one-way",
                    errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                  })
                );
                return;
              } else {
                const isFirstDatePresent = multiCityDates.find(
                  (date) => date.key === 1
                );
                if (!isFirstDatePresent) {
                  dispatch(
                    addError({
                      id: "multi-city-1",
                      errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                    })
                  );
                  return;
                }

                const isSecondDatePresent = multiCityDates.find(
                  (date) => date.key === 2
                );
                if (!isSecondDatePresent) {
                  dispatch(
                    addError({
                      id: "multi-city-2",
                      errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                    })
                  );
                  return;
                }

                const [firstDate] = multiCityDates.filter(
                  (date) => date.key === 1
                );
                const [secondDate] = multiCityDates.filter(
                  (date) => date.key === 2
                );

                const nonStop = flightType == 1 ? true : false;
                const url =`/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightFrom[1].code}-${flightTo[1].code}-${firstDate.date}_${flightFrom[2].code}-${flightTo[2].code}-${secondDate.date}&tt=M&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`;
                if(url != location.pathname + location.search) {
                  dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                  dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                  dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                  dispatch(handleLoader(true));
                  dispatch(updateModifyTotalSelectionForMultiCity(3));
                  addSearchFlightDetailsToLocalStorage(3);
                  dispatch(clearBookedFlights());
                  dispatch(addModifySearchedFlights([]));
                  dispatch(handleSearchApplicable(!searchApplicable));
                  navigate(url);
                }
              }
            } else {
              dispatch(
                addError({
                  id: "multi-city-2-city",
                  errorMessage: `${t("errorMessages.enterSourceDestination")}`,
                })
              );
              return;
            }
          } else if (
            Object.keys(flightFrom?.[1]).length !== 0 &&
            Object.keys(flightTo?.[1]).length !== 0
          ) {
            if (flightDepartureDate.length === 0) {
              dispatch(
                addError({
                  id: "one-way",
                  errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                })
              );
              return;
            } else {
              const isFirstDatePresent = multiCityDates.find(
                (date) => date.key === 1
              );
              if (!isFirstDatePresent) {
                dispatch(
                  addError({
                    id: "multi-city-1",
                    errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                  })
                );
                return;
              }
            }
            if (
              flightFrom[1]._id === flightTo[0]._id &&
              flightFrom[0]._id === flightTo[1]._id
            ) {
              const index = multiCityDates.findIndex((date) => date.key === 1);
              const nonStop = flightType == 1 ? true : false;
              const url = `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightTo[0].code}-${flightFrom[0].code}-${multiCityDates[index].date}&tt=R&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`;
              if(url != location.pathname + location.search) {
                dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                dispatch(updateModifyFlightTravelType(Number(2)));
                dispatch(
                  updateModifyDates({
                    departureDate: flightDepartureDate,
                    returnDate: multiCityDates[index].date,
                  })
                );
                dispatch(handleLoader(true));
                addSearchFlightDetailsToLocalStorage(
                  2,
                  multiCityDates[index].date
                );
                dispatch(clearBookedFlights());
                dispatch(addModifySearchedFlights([]));
                dispatch(handleSearchApplicable(!searchApplicable));
                navigate(url);
              }
            } else {
              const [firstDate] = multiCityDates.filter(
                (date) => date.key === 1
              );
              const nonStop = flightType == 1 ? true : false;
              const url = `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightFrom[1].code}-${flightTo[1].code}-${firstDate.date}&tt=M&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`;
              if(url != location.pathname + location.search) {
                dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                dispatch(updateModifyTotalSelectionForMultiCity(2));
                addSearchFlightDetailsToLocalStorage(3);
                dispatch(handleLoader(true));
                dispatch(clearBookedFlights());
                dispatch(addModifySearchedFlights([]));
                dispatch(handleSearchApplicable(!searchApplicable));
                navigate(url);
              }
            }
          } else {
            if (flightDepartureDate.length === 0) {
              dispatch(
                addError({
                  id: "one-way",
                  errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                })
              );
              return;
            }

            const nonStop = flightType == 1 ? true : false;
            const url = `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}&tt=O&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`;
            if(url != location.pathname + location.search) {
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateModifyFlightTravelType(Number(1)));
              dispatch(updateModifyTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(1);
              dispatch(handleLoader(true));
              dispatch(clearBookedFlights());
              dispatch(addModifySearchedFlights([]));
              dispatch(handleSearchApplicable(!searchApplicable));
              navigate(url);
            }
          }
        }
      } else {
        dispatch(
          addError({
            id: "one-way-search",
            errorMessage: `${t("errorMessages.enterSourceDestination")}`,
          })
        );
        return;
      }
      setShowModifySearch(false);
    }
  };

  const handleMultCityDateSelection = (date, key) => {
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    if (key === 1) {
      dispatch(
        handleMultiCityDates([{ key, date: formatDateToDdMmYyyy(date) }])
      );
      return;
    }
    if (isEmpty(multiCityDates)) {
      dispatch(
        handleMultiCityDates([{ key, date: formatDateToDdMmYyyy(date) }])
      );
    } else {
      const existingDateValue = find(multiCityDates, (_d) => _d.key === key);
      if (existingDateValue) {
        const updatedDateValues = map(multiCityDates, (dateObj) => {
          return dateObj.key == key
            ? { ...dateObj, date: formatDateToDdMmYyyy(date) }
            : { ...dateObj, date: dateObj.date };
        });
        dispatch(handleMultiCityDates(updatedDateValues));
      } else {
        dispatch(
          handleMultiCityDates([
            ...multiCityDates,
            { key, date: formatDateToDdMmYyyy(date) },
          ])
        );
      }
    }
    setMulticityDateList(multiCityDates);
  };

  const inputDateString = flightDepartureDate;
  const date = moment(inputDateString, "DD/MM/YYYY");
  const formattedDepartureDate = date.toDate();

  const inputDateReturnString = flightReturnDate;
  const returnDateState = moment(inputDateReturnString, "DD/MM/YYYY");
  const formattedReturnDate = returnDateState.toDate();

  const [range, setRange] = useState([
    {
      startDate: flightDepartureDate ? formattedDepartureDate : new Date(),
      endDate: flightReturnDate
        ? formattedReturnDate
        : flightDepartureDate
        ? formattedDepartureDate
        : addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const handleRangeDataChange = (date) => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    const returnDateState = formatDateToDdMmYyyy(date);
    setReturnDay(formatDay(date));
    setReturnDate(formatDate(date));
    dispatch(
      updateModifyDates({
        returnDate: returnDateState,
        departureDate: flightDepartureDate,
      })
    );
  };

  const handleDateChange = (date) => {
    dispatch(handleMultiCityDates([]));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    const startDateState = formatDateToDdMmYyyy(date);
    setDepartureDay(formatDay(date));
    setDepartureDate(formatDate(date));
    setReturnDay("");
    setReturnDate("");
    dispatch(
      updateModifyDates({ returnDate: "", departureDate: startDateState })
    );

    if (flightTravelType === 2) {
      if (calendarRef.current && travelDateRef.current) {
        calendarRef.current.click();
        travelDateRef.current.click();
        setShowCalendar(true);
      }
    }
    // setShowCalendar(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCalendar && !event.target.closest(".calendar")) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCalendar]);

  useEffect(()=>{
    if(localStorage.getItem("language") == "AR"){
      setDepartureDate(convertToArabicDateInDDMMYYY(flightDepartureDate))
      setDepartureDay(getArabicDayName(flightDepartureDate))
      setReturnDate(convertToArabicDateInDDMMYYY(flightReturnDate ? flightReturnDate : ""))
      setReturnDay(getArabicDayName(flightReturnDate ? flightReturnDate : ""))
    }
  }, [])

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.transform = "rotate(180deg)"
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }else{
      elements?.forEach(element => {
        if(element && element.style) {
          element.style.display= 'flex',
          element.style.justifyContent = "center",
          element.style.alignItems= 'center'
        }
      });
    }
  });
  return (
    <>
      <div className="section2-flight" style={{paddingBottom: '20px'}}>
        <div className="section2-flight-content1">
          <div className="radio-button-group">
            {localStorage.getItem("language") == "AR"
              ? flightTravelTypeDataArabic &&
                Object.entries(flightTravelTypeDataArabic).map(
                  ([key, value]) => {
                    key = Number(key);
                    return (
                      <div
                        className="radio-label-background"
                        key={key}
                        style={{
                          backgroundColor:
                            travelType === key ? activeColor : nonActiveColor,
                        }}
                      >
                        <label
                          className={
                            localStorage.getItem("language") == "AR"
                              ? "radio-group-arabic"
                              : "radio-group"
                          }
                        >
                          <span className="radio-text">{value}</span>
                          <input
                            type="radio"
                            id={key}
                            name={key}
                            value={key}
                            checked={key === travelType}
                            onChange={(e) => handleFlightTravelType(e)}
                          />
                          <span htmlFor={key} className="radio-icon"></span>
                        </label>
                      </div>
                    );
                  }
                )
              : flightTravelTypeData &&
                Object.entries(flightTravelTypeData).map(([key, value]) => {
                  key = Number(key);
                  return (
                    <div
                      className="radio-label-background"
                      key={key}
                      style={{
                        backgroundColor:
                          travelType === key ? activeColor : nonActiveColor,
                      }}
                    >
                      <label className="radio-group">
                        <span className="radio-text">{value}</span>
                        <input
                          type="radio"
                          id={key}
                          name={key}
                          value={key}
                          checked={key === travelType}
                          onChange={(e) => handleFlightTravelType(e)}
                        />
                        <span htmlFor={key} className="radio-icon"></span>
                      </label>
                    </div>
                  );
                })}
          </div>
          <div className="checkbox-button">
            {localStorage.getItem("language") == "AR"
              ? flightTypeDataArabic &&
                Object.entries(flightTypeDataArabic).map(([key, value]) => {
                  return (
                    <label className="checkbox-group" key={key}>
                      <span className="checkbox-text">{value}</span>
                      <input
                        type="checkbox"
                        id={key}
                        name={key}
                        value={key}
                        checked={key === type}
                        onChange={(e) => handleFlightType(e)}
                      />
                      <span htmlFor={key} className="checkbox-icon"></span>
                    </label>
                  );
                })
              : flightTypeData &&
                Object.entries(flightTypeData).map(([key, value]) => {
                  return (
                    <label className="checkbox-group" key={key}>
                      <span className="checkbox-text">{value}</span>
                      <input
                        type="checkbox"
                        id={key}
                        name={key}
                        value={key}
                        checked={key === type}
                        onChange={(e) => handleFlightType(e)}
                      />
                      <span htmlFor={key} className="checkbox-icon"></span>
                    </label>
                  );
                })}
          </div>
        </div>
        <div className="section2-flight-content2">
          <div
            // className={
            //   travelType == 3
            //     ? styles.multiCityCitySelect
            //     : styles.modifyCityCitySelect
            // }
            style={{ flex: 2.01 }}
          >
            <SearchAirport
              searchAirportId={0}
              setShowSourceOptions={setShowSourceOptions}
              setShowDestinationOptions={setShowDestinationOptions}
              showSourceOptions={showSourceOptions}
              showDestinationOptions={showDestinationOptions}
            />
            {errors &&
              errors
                .filter((error) => error.id === "one-way-search")
                .map((error) => {
                  const { id, errorMessage } = error;
                  return <Error key={id} errorMessage={errorMessage} />;
                })}
          </div>
          <div className={styles.departureDate}>
            <div className="calendar" onClick={() => setDateSource(0)}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TravelDate
                  title={t("flightSearchWidget.departureDate")}
                  metaTitle={t("flightSearchWidget.selectDepartureDate")}
                  setShowCalendar={setShowCalendar}
                  date={departureDate}
                  onClick={() => setShowCalendar(true)}
                  onDateChange={handleDateChange}
                  day={departureDay}
                  setShowSourceOptions={setShowSourceOptions}
                  setShowDestinationOptions={setShowDestinationOptions}
                  setShowFlightClassDropdown={setShowFlightClassDropdown}
                  setShowPassengersComponent={setShowPassengersComponent}
                />
              </div>
              {errors &&
                errors
                  .filter((error) => error.id === "one-way")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
            </div>
          </div>
          {travelType !== 3 && (
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <>
                <div
                  ref={calendarRef}
                  className="calendar"
                  onClick={() => {
                    setDateSource(1);
                    setShowCalendar(true);
                    setTravelType(Number(2));
                    dispatch(updateModifyFlightTravelType(Number(2)));
                  }}
                >
                  <TravelDate
                    title={t("flightSearchWidget.returnDate")}
                    metaTitle={t("flightSearchWidget.bookRoundTrip")}
                    date={returnDate.length > 0 ? returnDate : ""}
                    onClick={() => setShowCalendar(true)}
                    onDateChange={handleRangeDataChange}
                    day={returnDay}
                    minDate={
                      flightDepartureDateParts
                        ? new Date(
                            parseInt(flightDepartureDateParts[2], 10),
                            parseInt(flightDepartureDateParts[1], 10) - 1,
                            parseInt(flightDepartureDateParts[0], 10)
                          )
                        : new Date()
                    }
                    setShowSourceOptions={setShowSourceOptions}
                    setShowDestinationOptions={setShowDestinationOptions}
                    setShowFlightClassDropdown={setShowFlightClassDropdown}
                    setShowPassengersComponent={setShowPassengersComponent}
                    travelRef={travelDateRef}
                  />
                </div>
              </>
              {errors &&
                errors
                  .filter((error) => error.id === "round-trip")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
            </div>
          )}
          <div
            className={travelType === 3 ? "user-info-enlarges" : "user-info"}
          >
            <FlightClass
              setShowFlightClassDropdown={setShowFlightClassDropdown}
              showFlightClassDropdown={showFlightClassDropdown}
            />
            <TotalTravellers
              showPassengersComponent={showPassengersComponent}
              setShowPassengersComponent={setShowPassengersComponent}
            />
          </div>
          <div className="search-flight-button" onClick={handleSearchClick}>
            <ReactSVG src={SearchIcon} />
          </div>
        </div>
        {travelType === 3 && (
          <MultiCity
            setShowCalendar={setShowCalendar}
            showCalendar={showCalendar}
            handleDateChange={handleMultCityDateSelection}
            datesList={multiCityDates}
            setShowSourceOptions={setShowSourceOptions}
            setShowDestinationOptions={setShowDestinationOptions}
            showSourceOptions={showSourceOptions}
            showDestinationOptions={showDestinationOptions}
            setShowFlightClassDropdown={setShowFlightClassDropdown}
            setShowPassengersComponent={setShowPassengersComponent}
          />
        )}
      </div>
      {showCalendar && (
        <div
          style={{
            marginLeft: dateSource == 0 ? "350px" : "550px",
          }}
          className="calenderWrapModifyFlight calendar"
        >
          {travelType === 2 ? (
            <div className="range-calendar">
              {/* <DateRangePicker
                onChange={(item) => handleRangeDataChange(item)}
                editableDateInputs={false}
                ranges={range}
                months={2}
                minDate={
                  dateSource == 0
                    ? new Date()
                    : flightDepartureDateParts
                    ? new Date(
                        parseInt(flightDepartureDateParts[2], 10),
                        parseInt(flightDepartureDateParts[1], 10) - 1,
                        parseInt(flightDepartureDateParts[0], 10)
                      )
                    : new Date()
                }
                direction="horizontal"
                className="calendarElement calendar-component"
              /> */}
              {/* <Calendar
                onChange={(item) => handleRangeDataChange(item)}
                minDate={
                  dateSource == 0
                    ? new Date()
                    : flightDepartureDateParts
                    ? new Date(
                        parseInt(flightDepartureDateParts[2], 10),
                        parseInt(flightDepartureDateParts[1], 10) - 1,
                        parseInt(flightDepartureDateParts[0], 10)
                      )
                    : new Date()
                }
                showMonthAndYearPickers={false}
              /> */}
            </div>
          ) : (
            <div>
              <Calendar
                onChange={(item) => handleDateChange(item)}
                date={
                  departureDate.length > 0
                    ? new Date(departureDate)
                    : new Date()
                }
                locale={
                  localStorage.getItem("language") == "AR"
                    ? locales["arSA"]
                    : locales["enUS"]
                }
                months={2}
                direction="horizontal"
                showMonthAndYearPickers={false}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FlightSearchWidget;

FlightSearchWidget.propTypes = {
  activeColor: PropTypes.string,
  nonActiveColor: PropTypes.string,
  setShowModifySearch: PropTypes.func,
};
