import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  faqCategory: {
    // flex: 1,
    // textAlign: "center",
    // background: faqCategory == categoryTab ? "rgb(12, 140, 233)" : "grey",
    // height: "40px",
    // marginRight: "5px",
    // color: "white",
    // fontSize: "18px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center  ",
  },
}));

export default useStyles;
