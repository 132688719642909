import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectDomesticRoundTrip: {
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "20px 16px",
    gap: "12px",
    display: "flex",
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      width: "414px",
    },
  },

  selectDomesticRoundTripCard: {
    display: "flex",
    flex: "2",
  },

  flightDetailsCard: {
    display: "flex",
    flex: "1.75",
    // marginRight: "8px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  flightDetails: {
    display: "inline-flex",
    flex: "1",
    gap: "4px",
    marginRight: "12px",
  },

  flightDetailsLayout: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    alignItems: "center",
    borderRight: "1px dashed #D0D5DD",
    padding: "0px 16px 0px 0px",
    flex: "1",

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },

  flightDetailsLayoutArabic: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    alignItems: "center",
    borderLeft: "1px dashed #D0D5DD",
    padding: "0px 0px 0px 16px",
    flex: "1",

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  content1: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: "1",
    width: "100%",
    alignItems: "flex-start",

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  content2: {
    display: "inline-flex",
    justifyContent: "space-between",
    flex: "1",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  flightDetailsText: {
    fontSize: "12px",
    color: "#1b1d52",
    cursor: "pointer",
  },
  airLineDetails: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "flex-start",
  },
  flightFrom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  depTime: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },
  origin: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },

  flightDuration: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  flightDurationLayout: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  durationTime: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },
  flightTravelType: {
    display: "inline-flex",
  },

  airLineName: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },

  flightTravelTypeText: {
    fontFamily: "Lato",
    color: "#1DAC08",
    fontSize: "12px",
    fontWeight: "600",
    marginTop: "8px",
  },

  arrivalTime: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },

  destination: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },

  airLineCode: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },

  fairContainer: {
    display: "flex",
    flex: ".25",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "8px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  fairContainerArabic: {
    display: "flex",
    flex: ".25",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "8px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bookNowButton: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  totalTax: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "20px",
    fontWeight: "800",
    marginTop: "-4px",
  },
  fareDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    background: "#344054",
    borderRadius: "8px",
    width: "305px",
  },
  fareDetailsText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #667085",
  },
  baseFareText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFare: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFeesText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxesAndFees: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFeeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  baseFareAndTaxesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
}));

export default useStyles;
