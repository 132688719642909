import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import { ReactSVG } from "react-svg";
import { Box, Divider, Typography } from "@mui/material";
import ClockSvg from "../../../../assets/images/clock.svg";
import BaggageSvg from "../../../../assets/images/baggage.svg";
import PlaceholderImage from "../../../../assets/images/logo.png";
import CheckSvg from "../../../../assets/images/baggage-check.svg";
import PlanelineSvg from "../../../../assets/images/plane-line.svg";
import DashedSvg from "../../../../assets/images/cart-flight-details-line.svg";
import {
  TimeFormat,
  addDurationStrings,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDuration,
  flightDurationInHoursAndMinutes,
  timeString,
} from "../../../../utils/helpers";
import FlightDetails from "../../search-flight/components/cards/flight-details";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import closeSvg from "../../../../assets/images/x-close.svg";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../core/eventBus";
import FlightDetailsMobile from "../../search-flight/components/cards/flight-details-mobile";
import { useTranslation } from "react-i18next";
import { formatDateToArabicDayDateAndMonthFormat } from "../../../../utils/helpers";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const SelectedFlightDetails = ({
  flight = {},
  selected,
  markup,
  source = "other",
}) => {
  if (Object.keys(flight).length === 0) return <></>;
  const classes = useStyles();
  const { t } = useTranslation();
  const { flightTravelType: travelType } = useSelector(
    (state) => state.modifySearchFlight
  );
  const [showFlightDetailsModal, setShowFlightDetailsModal] = useState(false);
  const { Segments } = flight;
  if (Segments?.length <= 0 || Segments[0]?.length <= 0) return;
  const {
    AirlineDetails: { AirlineCode },
    Duration: departureDuration,
    DepartureTime,
    Origin: {
      // Airport: { CityName: origin, CityCode: originCode },
      CityName: origin,
      CityCode: originCode,
    },
    Baggage,
    CabinBaggage,
    IncludedBaggage,
    Destination: { ArrTime: layoverDepTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[0][0];
  const {
    Duration: arrivalDuration,
    Destination: {
      // Airport: { CityName: destination, CityCode: destinationCode },
      CityName: destination,
      CityCode: destinationCode,
    },
    ArrivalTime,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration: lastAccumulatedDuration,
  } = Segments[0][Segments[0]?.length - 1];
  const length = Segments[0]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  const formatDate = (dateString) => {
    if(localStorage.getItem("language") != "AR"){
      const date = moment(dateString);
      const formattedDate = date.format("ddd, MMM DD");
      return formattedDate;
    }else{
      return formatDateToArabicDayDateAndMonthFormat(dateString)
    }

  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  const isMobile = useIsMobile();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.topContainer}>
        <div className={classes.departureReturnContainer}>
          <p className={classes.returnDeparture}>
            {travelType == 2 && selected === 1
              ? `${t("flightSearch.return")}`
              : `${t("flightSearch.departure")}`}
          </p>
          <p className={classes.departureTime}>
            {DepartureTime && formatDate(DepartureTime)}
          </p>
        </div>
        <p
          className={classes.flightDetailsText}
          onClick={() =>
            isMobile
              ? eventBus.emit(
                  "open-bottomSheet",
                  <FlightDetailsMobile flight={flight} markup={markup} />
                )
              : setShowFlightDetailsModal(true)
          }
        >
          {t("flightSearch.flightDetails")}
        </p>
        {/* {isMobile && source === "other" && (
          <ReactSVG
            src={closeSvg}
            onClick={() => eventBus.emit("close-bottomSheet")}
          />
        )} */}
      </div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <div className={classes.bottomContainer}>
        <div className={classes.flightLogoDepTimeContainer}>
          <img
            src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
            onError={(e) => {
              e.target.src = PlaceholderImage;
            }}
            alt="flightLogo"
            style={{ height: "36px", width: "42px" }}
          />
          <div className={classes.depTimeAndOriginContainer}>
            <div className={classes.deptTimeContainer}>
              <p className={classes.deptTimeZero}>
                {/* {DepTime && timeString(DepTime)?.split(" ")[0]} */}
                {DepartureTime && timeString(DepartureTime)}
              </p>
              {/* <p className={classes.deptTimeOne}>
                {DepTime && timeString(DepTime)?.split(" ")[1]}
              </p> */}
            </div>
            <p className={classes.origin}>{isMobile ? originCode : origin}</p>
          </div>
        </div>
        <div className={classes.baggageAllowanceMainContainer}>
          <div className={classes.clockAndDurationContainer}>
            <ReactSVG src={ClockSvg} className={classes.clockSvg} />
            <p className={classes.duration}>
              {/* {firstAccumulatedDuration > 0
                ? flightDurationInHoursAndMinutes(firstAccumulatedDuration)
                : lastAccumulatedDuration > 0
                ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                : flightDurationInHoursAndMinutes(departureDuration)} */}
              {/* {TimeFormat(departureDuration)} */}
              {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
              {convertAccumulatedTime(lastAccumulatedDuration)}
            </p>
          </div>
          <div className={classes.travelTypeContainer}>
            <ReactSVG src={DashedSvg} className={classes.dashSvg} />
            <p className={classes.travelType}>{flightTravelType}</p>
            <ReactSVG src={DashedSvg} className={classes.dashSvg} />
            <ReactSVG src={PlanelineSvg} style={{ marginTop: "4px" }} />
          </div>
        </div>
        <div className={classes.destArrivalOuterContainer}>
          <div className={classes.desArrivalInnerContainer}>
            <p className={classes.arrivalTime}>
              {/* {ArrTime && timeString(ArrTime)?.split(" ")[0]} */}
              {ArrivalTime && timeString(ArrivalTime)}
            </p>
            {/* <p className={classes.arrivalTimeTwo}>
              {ArrTime && timeString(ArrTime)?.split(" ")[1]}
            </p> */}
          </div>
          <p className={classes.destinationTime}>
            {isMobile ? destinationCode : destination}
          </p>
        </div>
      </div>
      <div className={classes.baggageLine}>
        <Tippy
          arrow={true}
          placement="bottom-end"
          animation="shift-away"
          content={
            <div className={classes.baggagesAllowedMainContainer}>
              <p className={classes.baggageAllowanceText}>
                {t("flightSearch.baggageAllowance")}
              </p>
              <div className={classes.baggagesAllowedContianer}>
                <div className={classes.checkSvgContainer}>
                  <ReactSVG src={CheckSvg} />
                </div>
                <p className={classes.baggageAllowedText}>
                  {IncludedBaggage} {t("flightSearch.baggageAllowed")}
                </p>
              </div>
              {CabinBaggage && (
                <div className={classes.cabinBaggageMainContainer}>
                  <div className={classes.checkSvgContainer}>
                    <ReactSVG src={CheckSvg} />
                  </div>
                  <p className={classes.cabinBaggageAllowedText}>
                    {CabinBaggage} {t("flightSearch.cabinBaggageAllowed")}
                  </p>
                </div>
              )}
            </div>
          }
        >
          <div className={classes.baggageOptionContainer}>
            <ReactSVG src={BaggageSvg} />
            <p className={classes.baggageOptionText}>
              {t("flightSearch.baggageOptionsAvailable")}
            </p>
          </div>
        </Tippy>
      </div>

      {showFlightDetailsModal && (
        <FlightDetails
          setShowFlightDetailsModal={setShowFlightDetailsModal}
          showFlightDetailsModal={showFlightDetailsModal}
          flight={flight}
          markup={markup}
        />
      )}
    </div>
  );
};

export default SelectedFlightDetails;

SelectedFlightDetails.propTypes = {
  flight: PropTypes.object,
  selected: PropTypes.any,
};
