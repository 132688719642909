import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  domesticSearchCardSelected: {
    borderRadius: "8px",
    border: "1px solid #08F",
    background: "#FBFDFF",
    fontFamily: "'Lato', sans-serif",
    fontStyle: "normal",
    lineHeight: "normal",
    cursor: "pointer",
    padding: "16px 8px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
  },
  domesticSearchCard: {
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    fontFamily: "'Lato', sans-serif",
    fontStyle: "normal",
    lineHeight: "normal",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 8px",
    alignItems: "center",
  },

  airLinesDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  imageLayout: {
    width: "36px",
    height: "32px",
  },

  flightFair: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
  },

  flightTime: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },

  flightDuration: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },

  flightDurationText: {
    color: "#344054",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 700,
  },
  dashedLine: {
    background: "#D0D5DD",
    width: "72px",
    height: "1px",
  },
  depTime: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 700,
  },
  arrvTime: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  airLinesDetails1: {
    display: "inline-flex",
    gap: "4px",
  },
  fligthTravelType: {
    color: "#1DAC08",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  info: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 500,
  },
  info1: {
    color: "#344053",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 500,
  },
  airLinesName: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  airlineNameLayout: {
    display: "flex",
    width: "51px",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  divider: {
    border: "1px solid #DBDEE0",
    width: "100%",
  },
  baggageOptions: {
    color: "#667085",
    fontSize: "12px",
    fontWeight: 400,
  },
  flightDetailsOptions: {
    color: "#08F",
    fontSize: "12px",
    fontWeight: 400,
  },
  baggageOptionsContainer: {
    display: "inline-flex",
    gap: "4px",
    justifyContent: "flex-start",
    width: "100%",
  },
  bottom: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
  },
  clock: {
    display: "inline-flex",
    gap: "4px",
  },
}));

export default useStyles;
