import { makeStyles } from "@mui/styles";
import bookingConfirmationTop from "../../../../../../../assets/images/booking-confirmation-top.svg";

const useStyles = makeStyles((theme) => ({
  outerMainContainer: {
    padding: "24px 150px",
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    [theme.breakpoints.down("lg")]: {
      padding: "10px 50px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "12px 10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      flexDirection: "column",
    },
  },
  successContainer: {
    flex: 1.2,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  rightContainer: {
    flex: 0.8,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  bookingConfirmedText: {
    fontFamily: "Lato",
    fontSize: "40px",
    fontWeight: 700,
    color: "#fff",
  },
  bookingConfirmedLogoAndTextContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${bookingConfirmationTop})`,
    height: "234px",
  },
  tableHeaderCell: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
  },
  tableDataCell: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },

  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    overflow: "scroll",
  },
  guestDetailsContainer: {
    // display: "flex",
    padding: "16px",
    alignItems: "flex-start",
    gap: "71px",
    // background: "#F4F4F4",
    background: "#FFFFFF",
  },
  guestDetailText: {
    color: " #344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  table: {
    overflow: "scroll",
  },
  bookingIdAndbookDateContainer: {
    background: "#FFFFFF",
    display: "flex",
    margin: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  hotelBookinghasbeenCompletedText: {
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  bookingIdAndDate: {
    display: "flex",
    margin: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  paymentGreyContainer: {
    display: "flex",
    padding: "16px",
    alignItems: "flex-start",
    gap: "71px",
    background: " #F4F4F4",
  },
  margin20: {
    margin: "20px 0px ",
  },
  errorContainer: {
    width: "50%",
    margin: "0 auto",
    marginBottom: "30px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "20px 15px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  roomDetails: {
    background: "#F5F6F6",
    overflow: "auto",
  },
  hotelAndRoomDetailsContainer: {
    padding: "16px",
    background: "#FFF",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  optionsText: {
    color: "#4037acd6",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Lato, sans-serif",
    display: "inline-flex",
    gap: "8px",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
