import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { Modal } from "@mui/material";
import BaggageInfoPopUp from "../cards/baggage-info-pop-up";
import CheckSvg from "../../../../assets/images/check_green.svg";
import AddSvg from "../../../../assets/images/plus-sign-blue.svg";
import ArrowSvg from "../../../../assets/images/right-arrow-black.svg";
import "./style.css";
import useStyles from "./styles";
import eventBus from "../../../../core/eventBus";
import BaggageDetailsMobile from "../cards/baggage-info-mobile";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";
const BaggageDetails = ({
  flight = {},
  baggage,
  setExtraBaggage,
  extraBaggage,
  index,
  toggle,
  setToggle,
}) => {
  if (Object.keys(flight).length === 0) return <></>;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { Segments } = flight;
  if (!Segments) return;
  if (Segments.length <= 0) return;
  if (!Segments[0] || !Segments[0][0]) return;
  const styles = useStyles();
  const {
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
    },
    CabinBaggage,
    IncludedBaggage,
    Baggage,
  } = Segments[0][0];
  const {
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
    },
  } = Segments[0][Segments[0]?.length - 1];

  return (
    <>
      <div className={styles.addBaggageContainer}>
        <div className={styles.innerAllowanceContainer}>
          <div style={{ display: "inline-flex", gap: "8px" }}>
            <text className={styles.originText}>{origin}</text>
            <ReactSVG src={ArrowSvg} style={{ marginTop: "2px" }} />
            <text className={styles.destinationText}>{destination}</text>
          </div>
          {baggage && baggage.length > 0 && (
            <div
              className={styles.addExtraBaggageLayout}
              onClick={() =>
                isMobile
                  ? eventBus.emit(
                      "open-bottomSheet",
                      <BaggageDetailsMobile
                        baggage={baggage}
                        setExtraBaggage={setExtraBaggage}
                        extraBaggage={extraBaggage}
                        index={index}
                        toggle={toggle}
                        setToggle={setToggle}
                      />
                    )
                  : handleOpen()
              }
            >
              <ReactSVG src={AddSvg} />
              <text className={styles.addExtraBaggageText}>
                {t("addContactDetailsScreen.addExtraBaggage")}
              </text>
            </div>
          )}
        </div>
        <div className={styles.baggageAllowanceContainer}>
          <text className={styles.baggageAllowanceHead}>
            {" "}
            {t("addContactDetailsScreen.baggageAllowance")}
          </text>
          <div className={styles.baggageAllowedBody}>
            <ReactSVG src={CheckSvg} />
            <text className={styles.baggageAllowedBodyText}>
              {IncludedBaggage} {t("addContactDetailsScreen.baggageAllowed")}
            </text>
          </div>
          {CabinBaggage && (
            <div className={styles.baggageAllowedBody}>
              <ReactSVG src={CheckSvg} />
              <text className={styles.cabinBaggageAllowedBodyText}>
                {CabinBaggage}{" "}
                {t("addContactDetailsScreen.cabinBaggageAllowed")}
              </text>
            </div>
          )}
        </div>
      </div>
      <Modal open={open} onClose={handleClose} style={{ outline: "none" }}>
        <div className={styles.baggageInfoPopUpBox}>
          <BaggageInfoPopUp
            handleModalClose={handleClose}
            baggage={baggage}
            setExtraBaggage={setExtraBaggage}
            extraBaggage={extraBaggage}
            index={index}
            toggle={toggle}
            setToggle={setToggle}
          />
        </div>
      </Modal>
    </>
  );
};

export default BaggageDetails;

BaggageDetails.propTypes = {
  flight: PropTypes.object,
  baggage: PropTypes.any,
  setExtraBaggage: PropTypes.any,
  extraBaggage: PropTypes.any,
};
