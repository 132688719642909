import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchFlightField: {
    height: "112px",
    width: "inherit",
    position: "relative",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  swapIconWrapper: {
    width: "34px",
    position: "absolute",
    right: "30px",
    top: "calc(50% - 17px)",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid 1b1d52",
    // backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    color: "#fff",
  },

  swapIconWrapperArabic: {
    width: "34px",
    position: "absolute",
    left: "30px",
    top: "calc(50% - 17px)",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid 1b1d52",
    // backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "50%",
    backgroundColor: "#1b1d52",
    color: "#fff",
  },
}));

export default useStyles;
