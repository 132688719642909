import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  calender: {
    margin: "30px 0px",
    [theme.breakpoints.down("lg")]: {
      margin: "30px 0px",
    },
  },

  closeIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
  },

  closeIconArabic: {
    position: "absolute",
    top: "20px",
    left: "20px",
    cursor: "pointer",
  },
}));

export default useStyles;
