import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import useStyles from "./style";
import closeSvg from "../../../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../../../core/eventBus";
import BaggageAccordianContentMobile from "./baggage-info-accordian-content-mobile";

const BaggageDetailsMobile = ({
  baggage,
  setExtraBaggage,
  extraBaggage,
  index,
  toggle,
  setToggle,
}) => {
  const { adultCount, childrenCount } = JSON.parse(
    window.localStorage.getItem("flightTravellerDetails")
  );

  const renderAdultAccordian = () => {
    const accordians = [];
    for (let i = 0; i < adultCount; i++) {
      accordians.push(
        <BaggageAccordianContentMobile
          title={`AD ${i + 1}`}
          baggage={baggage}
          selected={i}
          type={"AD"}
          setExtraBaggage={setExtraBaggage}
          extraBaggage={extraBaggage}
          index={index}
          toggle={toggle}
          setToggle={setToggle}
        />
      );
    }
    return accordians;
  };

  const renderChildrenAccordian = () => {
    const accordians = [];
    for (let i = 0; i < childrenCount; i++) {
      accordians.push(
        <BaggageAccordianContentMobile
          title={`CH ${i + 1}`}
          baggage={baggage}
          selected={i}
          type={"CH"}
          setExtraBaggage={setExtraBaggage}
          extraBaggage={extraBaggage}
          index={index}
          toggle={toggle}
          setToggle={setToggle}
        />
      );
    }
    return accordians;
  };

  return (
    <Box sx={{ outline: "none" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          padding: "16px 12px 0px 12px",
        }}
      >
        <div
          style={{
            color: "#344054",
            fontSize: "14px",
            fontWeight: "600",
            display: "inline-flex",
            justifyContent: "space-between",
          }}
        >
          <div>Baggage info / Add baggage</div>
          <img
            src={closeSvg}
            alt="close icon"
            style={{ cursor: "pointer" }}
            onClick={() => eventBus.emit("close-bottomSheet")}
          ></img>
        </div>
        <div>
          {renderAdultAccordian()}
          {renderChildrenAccordian()}
        </div>
      </div>
    </Box>
  );
};

export default BaggageDetailsMobile;
