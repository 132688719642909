import React, { useState, useEffect } from "react";
import { HotelDetails } from "../../containers/HotelDetails";
import useStyles, { useHeaderLeftViewStyles } from "./styles";
import { SearchPageHeader } from "../../containers/SearchPageHeader";
import { get, reduce } from "lodash";
import moment from "moment";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { useLocation } from "react-router";
import eventBus from "../../../core/eventBus";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import nightStay from "../../../../src/assets/images/nights_stay.svg"
import { calculateNumberOfNights } from "../../../utils/helpers";
import { formatDateToArabic } from "../../../utils/helpers";

const HeaderLeftView = (props) => {
  // const hotelFilters = get(props, "filterValues.hotelFilters", {});
  const hotelFilters = get(props, "filterValues", {});
  let checkInDate = moment(
    get(hotelFilters, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  ).format("dddd, DD/MM");
  if(localStorage.getItem("language") == "AR"){
    checkInDate = formatDateToArabic(get(hotelFilters, "hotelCheckInDate", ""))
  }
  let checkOutDate = moment(
    get(hotelFilters, "hotelCheckoutDate", ""),
    "DD/MM/YYYY"
  ).format("dddd, DD/MM");
  if(localStorage.getItem("language") == "AR"){
    checkOutDate = formatDateToArabic(get(hotelFilters, "hotelCheckoutDate", ""))
  }
  const hotelCheckInDate = moment(
    get(hotelFilters, "hotelCheckInDate", ""),
    "DD/MM/YYYY"
  ).format("DD/MM/YYYY");
  const hotelCheckoutDate = moment(
    get(hotelFilters, "hotelCheckoutDate", ""),
    "DD/MM/YYYY"
  ).format("DD/MM/YYYY");
  const cityName = get(hotelFilters, "hotelCity.name", "");
  const countryName = get(hotelFilters, "hotelCity.country", "");
  const rooms = get(hotelFilters, "totalGuests", []);
  const adultsAndChildCount = reduce(
    rooms,
    (accumulator, booking) => {
      accumulator.totalAdults += booking.adultCounter;
      accumulator.totalChildren += booking.childrenCounter;

      return accumulator;
    },
    { totalAdults: 0, totalChildren: 0 }
  );
  const childrenCount = get(adultsAndChildCount, "totalChildren", 0);
  const leftViewStyles = useHeaderLeftViewStyles();
  const { t } = useTranslation();
  return (
    <div className={leftViewStyles.wrapper}>
      <div
        className={
          localStorage.getItem("language") == "AR"
            ? leftViewStyles.locationArabic
            : leftViewStyles.location
        }
      >
        <span className={leftViewStyles.locationLabel}>
          {t("hotelSearchResult.location")}
        </span>
        <span className={leftViewStyles.locationValue}>
          {countryName ? `${cityName} , ${countryName}` : cityName}
        </span>
      </div>
      <div className={leftViewStyles.checkIn}>
        <span className={leftViewStyles.checkInLabel}>
          {" "}
          {t("hotelSearchResult.checkIn")}
        </span>
        <span className={leftViewStyles.checkInValue}>{checkInDate}</span>
      </div>
      <div className="stay-nights">
        <ReactSVG
          style={{ color: "#1B1D52" }}
          src={nightStay}
          beforeInjection={(svg) => {
            svg.classList.add("nightsSvg");
            svg.setAttribute("style", "fill: #1B1D52");
          }}
        />
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckoutDate)}{" "}
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckoutDate) > 1
          ? t("hotelSearchWidget.nights")
          : t("hotelSearchWidget.nights")}
      </div>
      <div className={leftViewStyles.checkIn}>
        <span className={leftViewStyles.checkInLabel}>
          {t("hotelSearchResult.checkOut")}
        </span>
        <span className={leftViewStyles.checkInValue}>{checkOutDate}</span>
      </div>
      <div className={leftViewStyles.checkIn}>
        <span className={leftViewStyles.checkInLabel}>
          {t("hotelSearchResult.roomAndGuest")}
        </span>
        <span className={leftViewStyles.checkInValue}>
          {/* {`${rooms.length} Rooms , ${get(adultsAndChildCount, "totalAdults", 0)} Adults, ${get(adultsAndChildCount, "totalChildren", 0)} Children`} */}
          {/* {`${rooms.length} Rooms , ${get(adultsAndChildCount, "totalAdults", 0)} Adults, ${childrenCount? (childrenCount + " Children "):  ""} `} */}
          {rooms.length > 1
            ? `${rooms.length} ${t("hotelSearchWidget.rooms")},`
            : `${rooms.length} ${t("hotelSearchWidget.room")},`}{" "}
          {get(adultsAndChildCount, "totalAdults", 0) > 1
            ? `${get(adultsAndChildCount, "totalAdults", 0)} ${t("hotelSearchWidget.adults")}`
            : `${get(adultsAndChildCount, "totalAdults", 0)} ${t("hotelSearchWidget.adult")}`}{" "}
          {childrenCount ? `, ${childrenCount} ${t("hotelSearchWidget.children")}` : ""}
        </span>
      </div>
    </div>
  );
};

export const HotelDetailsPage = (props) => {
  const location = useLocation();
  const styles = useStyles();
  const isMobile = useIsMobile();
  const filtersFromHOC = get(props, "globalFilters", {});
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  // const hotelCode = searchParams.get("hotelCode");
  // const resultIndex = searchParams.get("resultIndex");
  // const traceId = searchParams.get("traceId");
  const hotelFilters = JSON.parse(searchParams.get("hotelFilters"));

  const [modifySearchEnabled, setModifySearchEnabled] = useState(false);
  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, [location]);
  return (
    <div className={styles.hotelDetailsPage}>
      {!isMobile && (
        <SearchPageHeader
          leftView={HeaderLeftView}
          // globalFilters={filtersFromHOC}
          globalFilters={hotelFilters}
          modifySearchEnabled={modifySearchEnabled}
          setModifySearchEnabled={setModifySearchEnabled}
        />
      )}
      <HotelDetails
        modifySearchEnabled={modifySearchEnabled}
        setModifySearchEnabled={setModifySearchEnabled}
      />
    </div>
  );
};
