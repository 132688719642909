import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  emailConfirmationView: {
    width: "100%",
    padding: "20px 26px",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifySelf: "center",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
  },

  emailBottomContent: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "center",
    width: "100%",
  },

  emailVerificationText: {
    fontSize: "24px",
    fontFamily: "Lato",
  },
  enterEmailText: {
    fontSize: "14px",
    fontFamily: "Lato",
  },

  emailInput: {
    width: "100%",
    height: "40px",
    borderRadius: "5px",
    padding: "8px",
    border: "1px solid lightgrey",
  },

  notificationText: {
    fontSize: "12px",
    fontFamily: "Lato",
  },
  submitButton: {
    display: "flex",
    justifySelf: "right",
    padding: "8px 10px",
    borderRadius: "6px",
    border: "1px solid #1b1d52",
    color: "#fff",
    background: "#1b1d52",
    cursor: "pointer",
  },
  invalid: {
    marginTop: "4px",
    fontSize: theme.fontSizes.fontSize2,
    color: "red",

    // [theme.breakpoints.up("md")]: {
    //   marginRight: "300px",
    // },
  },
}));

export default useStyles;
