import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    width: "100%",
    height: "452px !important",
    // [theme.breakpoints.down("xl")]: {
    //   height: "560px",
    //   padding: "0px 32px",
    // },

    // [theme.breakpoints.down("lg")]: {
    //   height: "470px",
    //   padding: "0px 32px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   height: "380px",
    //   padding: "0px 32px",
    // },

    [theme.breakpoints.down("sm")]: {
      height: "302px !important",
      padding: "0px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   height: "150px",
    //   padding: "0px",
    // },
  },

  carouselImage: {
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
    width: "100%",
    height: "450px !important",

    // borderRadius: "48px",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
      height: '300px !important'
    },
  },
}));

export default useStyles;
