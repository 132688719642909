import React from "react";
import { Rings } from "react-loader-spinner";
import SearchFlightSidebar from "./components/search-flight-sidebar";
import SearchFlightResult from "./components/search-flight-result";
import "react-tooltip/dist/react-tooltip.css";
import "tippy.js/dist/tippy.css";
import useResponsive from "../../../hooks/responsive.hook";
import "./style.css";
import { useSelector } from "react-redux";
import useStyles from "./style";
import FlightSvg from "../../../assets/images/flight-not-found.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";

const SearchFlight = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const currentBreakpoint = useResponsive();
  // let flightFrom, flightTo, flightTravelType;
  // if (window.localStorage.getItem("modifySearchFlightDetails")) {
  //   const {
  //     flightFrom: from,
  //     flightTo: to,
  //     flightTravelType: travelType,
  //   } = JSON.parse(window.localStorage.getItem("modifySearchFlightDetails"));

  //   flightFrom = from;
  //   flightTo = to;
  //   flightTravelType = travelType;
  // }

  const { loading } = useSelector((state) => state.modifySearchFlight);
  const { indexOfRoundTrip } = useSelector((state) => state.bookedFlight);
  const { indexOfMultiCity, searchedFlights: mctSearchedFlights } = useSelector(
    (state) => state.multiCityTripDetails
  );
  const { searchedFlights: owtSearchedFlights } = useSelector(
    (state) => state.oneWayTripDetails
  );
  const { searchedFlights: drtSearchedFlights } = useSelector(
    (state) => state.domesticRoundTripDetails
  );
  const { searchedFlights: irtSearchedFlights } = useSelector(
    (state) => state.internationalRoundTripDetails
  );

  const { flightTravelType, flightFrom, flightTo } = useSelector(
    (state) => state.searchFlightQuery
  );

  const {
    outboundFilters,
    inboundFilters,
    clubbedFiltersForThirdCity,
    clubbedFiltersForSecondCity,
  } = useSelector((state) => state.flightFilters);

  return (
    <div className={styles.bookFlightContainer}>
      <>
        {(currentBreakpoint === "large" ||
          currentBreakpoint === "extraLarge") &&
          !loading && (
            <div className="book-flight-sidebar">
              {flightTravelType == 1 && owtSearchedFlights?.length > 0 && (
                <SearchFlightSidebar
                  outboundFilters={outboundFilters}
                  from={flightFrom[0]}
                  to={flightTo[0]}
                  showPriceFilter={true}
                />
              )}

              {flightTravelType == 2 && irtSearchedFlights?.length > 0 ? (
                indexOfRoundTrip == 0 ? (
                  <SearchFlightSidebar
                    outboundFilters={outboundFilters}
                    from={flightFrom[0]}
                    to={flightTo[0]}
                    showPriceFilter={false}
                  />
                ) : (
                  <SearchFlightSidebar
                    outboundFilters={clubbedFiltersForSecondCity}
                    from={flightTo[0]}
                    to={flightFrom[0]}
                    showPriceFilter={true}
                  />
                )
              ) : (
                <></>
              )}
              {/* {flightTravelType == 2 &&
                drtSearchedFlights?.length > 0 &&
                flightFrom[0].country == flightTo[0].country && (
                  <SearchFlightSidebar
                    outboundFilters={outboundFilters}
                    inboundFilters={inboundFilters}
                    from={flightFrom[0]}
                    to={flightTo[0]}
                    showPriceFilter={true}
                  />
                )} */}
              {flightTravelType == 3 && mctSearchedFlights?.length > 0 ? (
                indexOfMultiCity == 0 ? (
                  <SearchFlightSidebar
                    outboundFilters={outboundFilters}
                    from={flightFrom[0]}
                    to={flightTo[0]}
                    showPriceFilter={false}
                  />
                ) : indexOfMultiCity == 1 ? (
                  <SearchFlightSidebar
                    outboundFilters={clubbedFiltersForSecondCity}
                    from={flightFrom[1]}
                    to={flightTo[1]}
                    showPriceFilter={!flightFrom[2].code ? true : false}
                  />
                ) : indexOfMultiCity == 2 ? (
                  <SearchFlightSidebar
                    outboundFilters={clubbedFiltersForThirdCity}
                    from={flightFrom[2]}
                    to={flightTo[2]}
                    showPriceFilter={true}
                  />
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          )}
        <div className={styles.bookFlightResult}>
          <SearchFlightResult />
        </div>
      </>
    </div>
  );
};

export default SearchFlight;
