import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import useStyles from "./style";
import SegmentDetails from "./segment-details";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FlightBookingConfirmation = ({ bookingDetails }) => {
  console.log('bookingDetails', bookingDetails)
  const {
    flightTickets,
    status: statusText,
    insuranceAmount,
    baggageAmount,
    seatAmount,
    // couponDiscount,
    // convenienceFeeAmount,
    totalPayableAmount,
    flightSearch,
    // markupPrice,
    taxAmount,

    TotalAmountSAR,
    taxSAR,
    convenienceFeeAmount,
    markupPrice,
    baseFareSAR,
    vatAmountSAR,
    seatAmountSAR,
    baggageAmountSAR,
    couponDiscount,
  } = bookingDetails[0];
  const classes = useStyles();
  const rateOfExchange = flightSearch[0].tboResponse.LocalCurrencyROE;

  useEffect(() => {}, []);

  const {
    ticket: {
      Results: {
        Itinerary: { Passenger },
      },
    },
  } = flightTickets[0];

  const vatAmount = Passenger[0].Fare.VATAmount;
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.contentContainer}>
        {flightTickets &&
          flightTickets.map((flightTicket, i) => {
            return (
              <SegmentDetails
                flightSearch={flightSearch}
                flightTicket={flightTicket}
                index={i}
                key={i}
                statusText={statusText}
              />
            );
          })}
        <Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "71px",
              background: " #F4F4F4",
            }}
          >
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {t("bookingConfirmationFlight.paymentDetails")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.baseFare")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {t("bookingConfirmationFlight.currencySymbol")}{" "}
              {/* {Math.ceil(
                (Passenger[0].Fare.TotalFare - seatAmount - baggageAmount) *
                  rateOfExchange -
                  (Math.ceil(Passenger[0].Fare.Tax * rateOfExchange) +
                    Math.ceil(Passenger[0].Fare.VATAmount * rateOfExchange))
              ) + markupPrice} */}
              {baseFareSAR + markupPrice}
              {/* {Math.ceil(
                Math.ceil(Passenger[0].Fare.TotalFare) * rateOfExchange
              ) -
                (Math.ceil(Math.ceil(baggageAmount) * rateOfExchange) +
                  Math.ceil(Math.ceil(seatAmount) * rateOfExchange) +
                  Math.ceil(Math.ceil(Passenger[0].Fare.Tax) * rateOfExchange) +
                  Math.ceil(
                    Math.ceil(Math.ceil(Passenger[0].Fare.VATAmount)) *
                      rateOfExchange
                  )) +
                markupPrice -
                couponDiscount} */}
              {/* {(
                Passenger[0].Fare.TotalFare +
                couponDiscount -
                Passenger[0].Fare.Tax -
                baggageAmount -
                seatAmount -
                convenienceFeeAmount -
                insuranceAmount
              ).toLocaleString("en-IN")} */}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.taxesAndFees")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {t("bookingConfirmationFlight.currencySymbol")}{" "}
              {/* {Math.ceil(Passenger[0].Fare.Tax * rateOfExchange).toLocaleString(
                "en-IN"
              )} */}
              {taxSAR}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.VAT")}
              {/* VAT */}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {t("bookingConfirmationFlight.currencySymbol")}{" "}
              {/* {Math.ceil(vatAmount * rateOfExchange).toLocaleString("en-IN")} */}
              {vatAmountSAR}
            </Typography>
          </Box>
          {seatAmountSAR > 0 && (
            <Box
              sx={{
                display: "flex",
                padding: "16px",
                alignItems: "flex-start",
                gap: "26px",
                borderBottom: "1px solid #D0D5DD",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  width: "105px",
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {t("bookingConfirmationFlight.seatPrice")}
              </Typography>
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {t("bookingConfirmationFlight.currencySymbol")}{" "}
                {/* {Math.ceil(seatAmount * rateOfExchange).toLocaleString("en-IN")} */}
                {seatAmountSAR}
              </Typography>
            </Box>
          )}
          {baggageAmountSAR > 0 && (
            <Box
              sx={{
                display: "flex",
                padding: "16px",
                alignItems: "flex-start",
                gap: "26px",
                borderBottom: "1px solid #D0D5DD",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  width: "105px",
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {t("bookingConfirmationFlight.extraBaggagePrice")}
              </Typography>
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {t("bookingConfirmationFlight.currencySymbol")}{" "}
                {/* {Math.ceil(baggageAmount * rateOfExchange).toLocaleString(
                  "en-IN"
                )} */}
                {baggageAmountSAR}
              </Typography>
            </Box>
          )}
          {insuranceAmount > 0 && (
            <Box
              sx={{
                display: "flex",
                padding: "16px",
                alignItems: "flex-start",
                gap: "26px",
                borderBottom: "1px solid #D0D5DD",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  width: "105px",
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {t("bookingConfirmationFlight.tripInsurance")}
              </Typography>
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {t("bookingConfirmationFlight.currencySymbol")}{" "}
                {Math.ceil(insuranceAmount * rateOfExchange).toLocaleString(
                  "en-IN"
                )}
              </Typography>
            </Box>
          )}
          {convenienceFeeAmount > 0 && (
            <Box
              sx={{
                display: "flex",
                padding: "16px",
                alignItems: "flex-start",
                gap: "26px",
                borderBottom: "1px solid #D0D5DD",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  width: "105px",
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {t("bookingConfirmationFlight.convenienceFee")}
              </Typography>
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                {t("bookingConfirmationFlight.currencySymbol")}{" "}
                {Math.ceil(convenienceFeeAmount).toLocaleString("en-IN")}
              </Typography>
            </Box>
          )}
          {couponDiscount > 0 && (
            <Box
              sx={{
                display: "flex",
                padding: "16px",
                alignItems: "flex-start",
                gap: "26px",
                borderBottom: "1px solid #D0D5DD",
                background: "#FFF",
              }}
            >
              <Typography
                sx={{
                  width: "105px",
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {t("bookingConfirmationFlight.discount")}
              </Typography>
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                - {t("bookingConfirmationFlight.currencySymbol")}{" "}
                {Math.ceil(couponDiscount).toLocaleString("en-IN")}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.total")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {t("bookingConfirmationFlight.currencySymbol")} {}
              {/* {Math.ceil(
                (Passenger[0].Fare.TotalFare - seatAmount - baggageAmount) *
                  rateOfExchange
              ) +
                markupPrice +
                Math.ceil(seatAmount * rateOfExchange) +
                Math.ceil(baggageAmount * rateOfExchange) +
                Math.ceil(convenienceFeeAmount) -
                Math.ceil(couponDiscount)} */}
              {TotalAmountSAR}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "71px",
              background: " #F4F4F4",
            }}
          >
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {t("bookingConfirmationFlight.contactDetails")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.name")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {Passenger[0].FirstName} {Passenger[0].LastName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.phone")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {Passenger[0].Mobile1}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "26px",
              borderBottom: "1px solid #D0D5DD",
              background: "#FFF",
            }}
          >
            <Typography
              sx={{
                width: "105px",
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              {t("bookingConfirmationFlight.email")}
            </Typography>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              {Passenger[0].Email}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "71px",
              background: " #F4F4F4",
            }}
          >
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
              }}
            >
              {/* Important Information */}
              {t("bookingConfirmationFlight.importantInformation")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px 16px 0px 16px",
              background: "#FFF",
              color: "#667085",
              fontSize: "12px",
            }}
          >
            <ul
              style={{
                color: "#667085",
                fontSize: "12px",
              }}
            >
              <li>
                {/* If you require any help with your booking, please don&apos;t
                hesitate to reach out to us at support@zapstravel.com. */}
                {t("bookingConfirmationFlight.supportMessage")}
              </li>
              <li>{t("bookingConfirmationFlight.importantNote")}</li>
              <li>{t("bookingConfirmationFlight.importantNote")}</li>
              <li>{t("bookingConfirmationFlight.visaRequirementsNote")}</li>
              <li>{t("bookingConfirmationFlight.ticketFormatNote")}</li>
              <li>{t("bookingConfirmationFlight.baggageAllowanceNote")}</li>
              <li>{t("bookingConfirmationFlight.boardingPassNote")}</li>
              <li>{t("bookingConfirmationFlight.mobilePhonesNote")}</li>
              <li>
                {t("bookingConfirmationFlight.immigrationInformationNote")}
              </li>
              <li>{t("bookingConfirmationFlight.okToBoardNote")}</li>
            </ul>
            <p
              style={{
                color: "#344054",
                fontSize: "14px",
              }}
            >
              {t("bookingConfirmationFlight.termsAndConditions")}
            </p>
            <ul
              style={{
                color: "#667085",
                fontSize: "12px",
              }}
            >
              <li>{t("bookingConfirmationFlight.covidProtocolsNote")}</li>
              <li>{t("bookingConfirmationFlight.checkInTimingNote")} </li>
              <li>{t("bookingConfirmationFlight.airIndiaCheckInNote")}</li>
              <li>{t("bookingConfirmationFlight.infantProofOfAgeNote")} </li>
              <li>{t("bookingConfirmationFlight.nameChangeNote")}</li>
              <li>{t("bookingConfirmationFlight.codeShareNote")}</li>
              <li>
                {/* Joyus does not assume any liability whatsoever for cancelled
                flights, flights that are missed, or flights not connecting due
                to any scheduled changes made by the airlines. */}
                {t("bookingConfirmationFlight.joyusDoesNotAssueLiabilityNote")}
              </li>
              <li>{t("bookingConfirmationFlight.valuableItemsNote")}</li>
              <li>
                {/* Passengers who book a series of one-way tickets (e.g. separate
                ticketing), need to check if the Minimum Connection Time (MCT)
                is enough between flights. Joyus might provide different
                airlines as a cost-effective option but Joyus would not be
                responsible if any one of the airlines rescheduled the itinerary
                that would result in a shorter connection time, thereby risking
                a missed flight. To avoid any issues be sure to either book a
                return flight (and the airline will manage the MCT for you) or
                that you have adequate time during your connection or layover to
                catch your next flight. */}
                {t("bookingConfirmationFlight.seriesOfOneWayTicketsNote")}
              </li>
              <li>{t("bookingConfirmationFlight.conditionsOfCarriageNote")}</li>
              <li>{t("bookingConfirmationFlight.seatRequestNote")}</li>
              <li>{t("bookingConfirmationFlight.boardingPassRetainNote")} </li>
            </ul>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default FlightBookingConfirmation;

FlightBookingConfirmation.propTypes = {
  bookingDetails: PropTypes.any,
};
