import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "8px",
    background: "#FFF",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  innerContainer: {
    display: "inline-flex",
    gap: "12px",
    flex: 5,
  },
  baggageAllowance: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  checkContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  baggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  baggageContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  cabinBaggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  checkSvgContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cabinBaggageContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  baggagesMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
    width: "240px",
  },
  fareDetails: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #667085",
  },
  baseFareText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFare: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFeesText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxesAndFees: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFeeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  fareBreakDownContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  taxesAndFeeDescription: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    padding: "8px 0px",
    borderTop: "1px solid #667085",
  },
  fareDetailsMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    background: "#344054",
    borderRadius: "8px",
    width: "305px",
  },
  checkPrice: {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato",
  },
  checkPriceContainer: {
    display: "inline-flex",
    borderRadius: "8px",
    border: "1px solid #1b1d52",
    padding: "10px 4px",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

export default useStyles;
