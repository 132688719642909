import { useEffect } from "react";
import { useState } from "react";

const breakpoints = {
  small: " (max-width: 576px)",
  medium: "(min-width: 577px) and (max-width: 820px)",
  large: "(min-width: 821px) and (max-width: 1100px) ",
  extraLarge: "(min-width: 1101px)",
  // desktopView: "(min-width: 1920px)",
};

const useResponsive = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null);

  const checkCurrentBreakpoint = () => {
    for (const breakpoint in breakpoints) {
      if (window.matchMedia(breakpoints[breakpoint]).matches) {
        setCurrentBreakpoint(breakpoint);
        return;
      }
    }
  };

  useEffect(() => {
    checkCurrentBreakpoint();
    const mediaQueryLists = {};
    for (const breakpoint in breakpoints) {
      mediaQueryLists[breakpoint] = window.matchMedia(breakpoints[breakpoint]);
      mediaQueryLists[breakpoint].addListener(checkCurrentBreakpoint);
    }
    for (const breakpoint in breakpoints) {
      mediaQueryLists[breakpoint] = window.matchMedia(breakpoints[breakpoint]);
      mediaQueryLists[breakpoint].addListener(checkCurrentBreakpoint);
    }
  }, []);

  return currentBreakpoint;
};

export default useResponsive;
