import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  MobileMultiCity: {
    width: "100%",
    display: "flex",
    rowGap: "20px",
    flexDirection: "column",
  },
  MobileFirstCity: {
    rowGap: "10px",
    display: "flex",
    flexDirection: "column",
  },

  MobileSecondCity: {
    rowGap: "10px",
    display: "flex",
    flexDirection: "column",
  },

  MobileThirdCity: {
    rowGap: "10px",
    display: "flex",
    flexDirection: "column",
  },

  buttonsLayout: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },

  plusIcon: {
    width: "30px",
    height: "30px",
  },
}));

export default useStyles;
