import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { TwoToneTitle } from "../../../ui/components/TwoToneTitle";
import { map } from "lodash";
import { Flight } from "@mui/icons-material";
import Carousel from "react-elastic-carousel";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { getRecentSearches } from "../../../modules/home";
import { get } from "lodash";
import moment from "moment";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import hotelIcon from "../../../assets/images/hotel_icon.svg";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import { updateModifySearchHotelObject } from "../../../utils/slices/modifySearchHotelSlice";
import { updateModifyHotelRoomObject } from "../../../utils/slices/modifyHotelRoomSlice";
import { updateHotelFilters } from "../../../utils/slices/globalFiltersSlice";
import { useNavigate } from "react-router-dom";
import useIsTablet from "../../../hooks/useIsTablet.hook";
import { useTranslation } from "react-i18next";
import { getTimeDifferenceInArabic } from "../../../utils/helpers";

const RecentSearchesHotel = () => {
  const styles = useStyles();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [recentSearches, setRecentSearches] = useState();

  const uuid = localStorage.getItem("uuid");
  const userDetails =
    window.localStorage &&
    JSON.parse(window.localStorage.getItem("userDetails"));

  const recentSearchesHandler = async () => {
    const token = userDetails?.token;
    const response = await getRecentSearches(token, uuid);
    setRecentSearches(response?.hotelSearches);
  };
  useEffect(() => {
    recentSearchesHandler();
  }, []);
  return (
    <div className={styles.recentSearch}>
      {recentSearches && recentSearches.length > 0 && (
        <TwoToneTitle
          firstPart={t("recentSearches.yourRecent")}
          secondPart={t("recentSearches.searches")}
        />
      )}
      <div className={styles.recentSearchCards}>
        {isMobile ? (
          <Carousel itemsToShow={1} enableSwipe={false}>
            {recentSearches &&
              map(recentSearches, (cardData) => {
                if (cardData.city?.length <= 0) return;
                const createdAt = moment(cardData.createdAt);
                const timeAgo = createdAt.fromNow();
                const timeAgoArabic = getTimeDifferenceInArabic(
                  cardData.createdAt
                );
                const checkInDate = cardData.checkInDate;
                const parsedCheckInDate = moment(checkInDate);
                const formattedCheckInDate =
                  parsedCheckInDate.format("ddd, DD MMM");
                const checkOutDate = cardData.checkOutDate;
                const parsedCheckOutDate = moment(checkOutDate);
                const formattedCheckOutDate =
                  parsedCheckOutDate.format("ddd, DD MMM");

                const isoDateCheckIn = new Date(parsedCheckInDate);
                const formattedCheckInDateArabic =
                  isoDateCheckIn.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });

                const isoDateCheckOut = new Date(parsedCheckOutDate);
                const formattedCheckOutDateArabic =
                  isoDateCheckOut.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                return (
                  <div key={cardData._id} className={styles.recentSearchCard}>
                    <div className={styles.recentSearchCardLeftSection}>
                      <div className={styles.recentSearchCardFlightPath}>
                        <span className={styles.cardCityName}>
                          {cardData.city[0].name}
                        </span>
                      </div>

                      <p className={styles.flightDateAndTime}>
                        {localStorage.getItem("language") != "AR"
                          ? `${formattedCheckInDate} - ${formattedCheckOutDate}`
                          : `${formattedCheckInDateArabic} - ${formattedCheckOutDateArabic}`}
                      </p>
                      <p className={styles.cardFlightType}> </p>
                    </div>
                    <div className={styles.recentSearchCardRightSection}>
                      <p className={styles.flightType}>
                        {/* {timeAgo} */}
                        {localStorage.getItem("language") != "AR"
                          ? timeAgo
                          : timeAgoArabic}
                      </p>

                      <ReactSVG src={hotelIcon} />

                      <p
                        className={styles.cardSearchAction}
                        onClick={() => {
                          const hotelCity = cardData.city[0];
                          const totalGuests = [];

                          const length = cardData.roomGuests?.length;
                          const hotelCheckInDate = moment(
                            cardData.checkInDate
                          ).format("DD/MM/YYYY");
                          const hotelCheckOutDate = moment(
                            cardData.checkOutDate
                          ).format("DD/MM/YYYY");

                          for (let i = 0; i < length; i++) {
                            const { NoOfAdults, ChildAge, NoOfChild } =
                              cardData.roomGuests[i];
                            const adultCounter = NoOfAdults;
                            const childrenAges =
                              ChildAge === null ? [] : ChildAge;
                            const childrenCounter = NoOfChild;
                            totalGuests.push({
                              adultCounter,
                              childrenAges,
                              childrenCounter,
                            });

                            const data = {
                              hotelCity: hotelCity,
                              roomGuests: totalGuests,
                              hotelCheckInDate,
                              hotelCheckOutDate,
                            };
                            window.localStorage.setItem(
                              "hotelDetails",
                              JSON.stringify(data)
                            );
                            window.localStorage.setItem(
                              "modifySearchHotelDetails",
                              JSON.stringify(data)
                            );
                            dispatch(
                              updateModifySearchHotelObject({
                                hotelCheckInDate,
                                hotelCheckOutDate,
                                hotelCity,
                              })
                            );
                            dispatch(
                              updateModifyHotelRoomObject({ totalGuests })
                            );
                            dispatch(updateHotelFilters(data));

                            const uuid = localStorage.getItem("uuid");
                            navigate(
                              `/hotel/search?hotelCheckInDate=${hotelCheckInDate}&hotelCheckoutDate=${hotelCheckOutDate}&totalGuests=${JSON.stringify(
                                totalGuests
                              )}&hotelCity=${JSON.stringify(
                                hotelCity
                              )}&sharedUUId=${uuid}`,
                              { state: data }
                            );
                            // navigate("/hotel/search", { state: data });
                          }
                        }}
                      >
                        {t("recentSearches.search")}
                      </p>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        ) : (
          <>
            {recentSearches &&
              map(recentSearches, (cardData) => {
                console.log("cardData", cardData);
                if (cardData.city?.length <= 0) return;
                const createdAt = moment(cardData.createdAt);
                const timeAgo = createdAt.fromNow();
                const timeAgoArabic = getTimeDifferenceInArabic(
                  cardData.createdAt
                );
                const checkInDate = cardData.checkIn;
                const parsedCheckInDate = moment(checkInDate);
                const formattedCheckInDate =
                  parsedCheckInDate.format("ddd, DD MMM");
                const checkOutDate = cardData.checkOut;
                const parsedCheckOutDate = moment(checkOutDate);
                const formattedCheckOutDate =
                  parsedCheckOutDate.format("ddd, DD MMM");

                const isoDateCheckIn = new Date(parsedCheckInDate);
                const formattedCheckInDateArabic =
                  isoDateCheckIn.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });

                const isoDateCheckOut = new Date(parsedCheckOutDate);
                const formattedCheckOutDateArabic =
                  isoDateCheckOut.toLocaleString("ar", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                return (
                  <div
                    key={cardData._id}
                    className={
                      recentSearches.length > 3
                        ? styles.recentSearchCard
                        : styles.recentSearchCard1
                    }
                  >
                    <div className={styles.recentSearchCardLeftSection}>
                      <div className={styles.recentSearchCardFlightPath}>
                        <span className={styles.cardCityName}>
                          {cardData.city[0].name}
                        </span>
                      </div>

                      <p className={styles.flightDateAndTime}>
                        {localStorage.getItem("language") != "AR"
                          ? `${formattedCheckInDate} - ${formattedCheckOutDate}`
                          : `${formattedCheckInDateArabic} - ${formattedCheckOutDateArabic}`}
                      </p>
                      <p className={styles.cardFlightType}> </p>
                    </div>
                    <div className={styles.recentSearchCardRightSection}>
                      <p className={styles.flightType}>
                        {/* {timeAgo} */}
                        {localStorage.getItem("language") != "AR"
                          ? timeAgo
                          : timeAgoArabic}
                      </p>

                      <ReactSVG src={hotelIcon} />

                      <p
                        className={styles.cardSearchAction}
                        onClick={() => {
                          console.log("card clicked");
                          const hotelCity = cardData.city[0];
                          let totalGuests = [];

                          const length = cardData.paxRooms?.length;
                          const hotelCheckInDate = moment(
                            cardData.checkIn
                          ).format("DD/MM/YYYY");
                          const hotelCheckOutDate = moment(
                            cardData.checkOut
                          ).format("DD/MM/YYYY");

                          for (let i = 0; i < length; i++) {
                            const { adults, childrenAges, children } =
                              cardData.paxRooms[i];
                            const adultCounter = adults;
                            // const childrenAges =
                            //   ChildAge === null ? [] : ChildAge;
                            const childrenCounter = children;
                            const objectToPush = {
                              adultCounter,
                              childrenAges,
                              childrenCounter,
                            };
                            totalGuests?.push(objectToPush);
                          }
                          const data = {
                            hotelCity: hotelCity,
                            roomGuests: totalGuests,
                            hotelCheckInDate,
                            hotelCheckOutDate,
                          };
                          window.localStorage.setItem(
                            "hotelDetails",
                            JSON.stringify(data)
                          );
                          window.localStorage.setItem(
                            "modifySearchHotelDetails",
                            JSON.stringify(data)
                          );
                          dispatch(
                            updateModifySearchHotelObject({
                              hotelCheckInDate,
                              hotelCheckOutDate,
                              hotelCity,
                            })
                          );
                          dispatch(
                            updateModifyHotelRoomObject({ totalGuests })
                          );
                          dispatch(updateHotelFilters(data));
                          console.log("totalGuests", totalGuests);
                          const uuid = localStorage.getItem("uuid");
                          navigate(
                            `/hotel/search?hi=${hotelCheckInDate}&hco=${hotelCheckOutDate}&tg=
                              ${JSON.stringify(
                                totalGuests
                              )}&hc=${JSON.stringify(hotelCity)}
                              &sharedUUId=${uuid}`,
                            { state: data }
                          );

                          // navigate("/hotel/search", { state: data });
                        }}
                      >
                        {t("recentSearches.search")}
                      </p>
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default RecentSearchesHotel;
