import React from "react";
// import "./styles.css";
import { get, map, split, take } from "lodash";
import useStyles from "./styles";
import { Star } from "@mui/icons-material";
import { formatSARCurrency } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

export const HotelDetailsInfo = (props) => {
  const detail = get(props, "info", {});
  const nights = get(props, "nights", 1);
  const infoSource = get(props, "infoSource", "");
  const styles = useStyles();
  const { t } = useTranslation();
  const ratingCount = get(detail, "HotelRating", 0);
  const priceDetails = get(detail, "Price", {});
  const facilities = get(detail, "HotelFacilities", []);
  const firstFourFacilities =
    facilities.length === 1
      ? take(split(facilities[0], ","), 4)
      : take(facilities, 4);

  const handleSelectRoomClick = () => {
    const element = document.getElementsByClassName("least")[0];
    const dimenstions = element?.getBoundingClientRect();
    const top = dimenstions.top;
    const left = dimenstions.left;
    const bottom = dimenstions.bottom;
    const difference = bottom - top;
    window.scroll(0, bottom);
  }

  return (
    <div className={styles.hotelDetailsInfo}>
      <div className={styles.hotelDetailsInfoUpper}>
        <div className={styles.hotelDetailsRatingRow}>
          <p
            style={{
              alignSelf: "flex-start",
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            <span className={styles.hotelDetailsRatingCount}>
              {ratingCount}
            </span>
            <Star style={{ color: "#FFA400", fontSize: "16px" }} />
          </p>

          <p className={styles.hotelDetailsRatingCount}>
            {get(detail, "Address", "")}
          </p>
        </div>
        <h1 className={styles.hotelDetailsInfoName}>
          {get(detail, "HotelName", "")}
        </h1>
        <div className={styles.hotelDetailsReviewsRow}>
          <p
            className={styles.hotelDetailsReviewsCount}
          >{`${ratingCount}/10`}</p>
        </div>
        <div className={styles.hotelDetailsFacilities}>
          {map(firstFourFacilities, (facility) => (
            <p className={styles.hotelDetailsFacility}>{facility}</p>
          ))}
        </div>
      </div>
      <div className={styles.hotelDetailsPriceSection}>
        <p className={styles.hotelDetailsPriceValue}>
          <span> {t("hotelDetails.currencySymbol")}</span>
          {/* {get(priceDetails, "PublishedPrice", 0)} */}
          {/* <span className={styles.hotelDetailsPriceTax}> / night + </span>
          <span className={styles.hotelDetailsPriceTax}>
            <span >&#8377;</span>
            {get(priceDetails, "OtherCharges", 0)} tax
          </span> */}{" "}
          {formatSARCurrency(parseFloat(props.price / nights))}
          &nbsp;
          <span
            style={{ fontSize: "14px", fontWeight: "400", color: "#667085" }}
          >
            {t("hotelDetails.perNight")}
          </span>
        </p>
        <button
          className={styles.hotelDetailsSelectRoom}
          onClick={handleSelectRoomClick}
        >
          {t("hotelDetails.selectRoom")}
        </button>
      </div>
      {/* <div className="rating-row">
        <div className="rating-row-item">{get(detail, "Rating", 0)}</div>
        <div className="rating-row-item">
          <img src="/icons/star.png" className="star-rating-icon" />
        </div>
        <div className="rating-row-item">
            Resort . {detail.address.city}, {detail.address.state}
        </div> 
      </div>
      <h1 className={styles.hotelDetailsTitle}>
        {get(detail, "HotelName", "")}
      </h1>
      <p>{get(detail, "Address", "")}</p>
      <div className="rating-row">
        <div className="rating-row-item">
          {get(detail, "StarRating", "")}/10
        </div>
      </div>
      <Grid container>
        <Grid item xs={4} style={{ margin: "10px" }}>
          <img src="/icons/location.png" className="marker-icon" />
        </Grid>
      </Grid> */}
    </div>
  );
};
