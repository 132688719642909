import React from "react";
import FallbackSvg from "../../../assets/images/fallback.svg";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";

const FallBackScreen = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          minHeight: "50vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactSVG src={FallbackSvg} />
        <div
          style={{
            background: "#A2A0B0",
            color: "white",
            fontSize: "14px",
            padding: "8px 12px",
            borderRadius: "8px",
            fontFamily: "Lato, sans-serif",
            marginTop: "-40px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          Go To Home
        </div>
      </div>
    </>
  );
};

export default FallBackScreen;
