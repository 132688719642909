import { makeStyles } from "@mui/styles";

export const useHeaderLeftViewStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    columnGap: "48px",
  },
  location: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderRight: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    paddingRight: "48px",
  },

  locationArabic: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderLeft: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    paddingLeft: "48px",
  },

  locationLabel: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "700",
  },
  locationValue: {
    color: theme.palette.textColors.textColorSecondary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
  },
  checkIn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  checkInLabel: {
    color: theme.palette.textColors.textColorSecondary,
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "400",
  },
  checkInValue: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "700",
  },
}));

const useStyles = makeStyles((theme) => ({
  hotelDetailsPage: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.entityColors.entityColorBackground2,
    padding: "16px 150px 48px 150px",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      minHeight: "100vH",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "16px 20px 48px 20px",
      minHeight: "100vH",
    },
  },
  //   hotelDetailsPageBody: {
  //     display: "flex",
  //     columnGap: "24px",
  //   },
  //   hotelDetailsPageBodyFilters: {
  //     flex: "1",
  //   },
  //   hotelDetailsPageBodyListing: {
  //     flex: "3",
  //     display: "flex",
  //     flexDirection: "column",
  //     rowGap: "16px",
  //   },
}));

export default useStyles;
