import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: "100%",
    height: "100%",
  },
  button: {
    padding: "16px",
    width: "100%",
    fontSize: theme.fontSizes.fontSize2,
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",

    "&:focus": {
      boxShadow: "box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);",
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    color: theme.palette.entityColors.entityColorBackground1,
  },
  buttonSecondary: {
    color: theme.palette.entityColors.entityColorPrimary,
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },
}));

export default useStyles;
