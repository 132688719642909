import axios from "axios";
import APIs from "../api";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getPackages = async ({
  limit,
  skip,
  categoryIds = [],
  withFlight = true,
  withoutFlight = true,
  minDaysFilter = 0,
  maxDaysFilter = 0,
  minNightsFilter = 0,
  maxNightsFilter = 0,
  minPersonPriceFilter = 0,
  maxPersonPriceFilter = 0,
  cities = [],
}) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${APIs.getPackages}`,
      {
        limit,
        skip,
        categoryIds,
        withFlight,
        withoutFlight,
        minDaysFilter,
        maxDaysFilter,
        minNightsFilter,
        maxNightsFilter,
        minPersonPriceFilter,
        maxPersonPriceFilter,
        cities,
      },
      config
    );
    return data;
  } catch (error) {
    console.log("Get Packages API Error ", error);
  }
};

export const getPackage = async ({ packageId }) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${APIs.getPackage}?package=${packageId}`, config);
    return data;
  } catch (error) {
    console.log("Get Package API Error ", error);
  }
};

export const enquireAboutPackage = async (payload) => {
  try {
    const {
      data: { statusCode },
    } = await axios.post(`${APIs.enguirePackage}`, payload, config);
    return statusCode;
  } catch (error) {
    console.log("Enquire Package API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};
