import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import { ReactSVG } from "react-svg";
import FlightDetails from "../../../cards/flight-details";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import DashedSvg from "../../../../../../../assets/images/flight-result-dashed.svg";
import PlanelineSvg from "../../../../../../../assets/images/plane-line.svg";
import BaggageSvg from "../../../../../../../assets/images/baggage.svg";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import CheckSvg from "../../../../../../../assets/images/baggage-check.svg";
import {
  timeString,
  calculateFlightDurationInHoursAndMinutes,
  flightDurationInHoursAndMinutes,
  addDurationStrings,
  convertAccumulatedTime,
} from "../../../../../../../utils/helpers";
import "./style.css";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookFlight = ({ flight }) => {
  if (!flight) return;
  const [showFlightDetailsModal, setShowFlightDetailsModal] = useState(false);
  const [markup, setMarkup] = useState(0);
  const classes = useStyles();
  const rateOfExchange = useSelector((state) => state?.rateOfExchange?.rate);
  const { t } = useTranslation();
  const {
    Segments,
    Fare: { TotalFare, BaseFare, OtherCharges },
  } = flight;
  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    Duration: departureDuration,
    CabinBaggage,
    Baggage,
    Destination: { ArrTime: layoverDepTime },
  } = Segments[0][0];
  const {
    Duration: arrivalDuration,
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration,
  } = Segments[0][Segments[0]?.length - 1];
  const length = Segments[0]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  useEffect(() => {
    getMarkup(flight?.markup);
  }, [BaseFare, TotalFare, OtherCharges]);

  const getMarkup = (markupDetails) => {
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * (BaseFare * rateOfExchange)) / 100;
      } else {
        amt = (amt * (TotalFare * rateOfExchange)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    setMarkup(Math.ceil(amt));
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  return (
    <>
      <div className="book-flight-card-container">
        <div className="book-flight-card-icon">
          <img
            src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
            onError={(e) => {
              e.target.src = PlaceholderImage;
            }}
            className="book-flight-card-image"
            style={{ height: "36px", width: "42px" }}
          />
        </div>
        <div className="book-flight-card-flight-details">
          <div>
            <div className="book-flight-card-flight-name">{AirlineName}</div>
            <div className="book-flight-card-flight-code">
              {AirlineCode}-{FlightNumber}
            </div>
          </div>
        </div>
        <div className="book-flight-card-place-details">
          <div>
            <div className="book-flight-card-place-time">
              {DepTime && timeString(DepTime)}
            </div>
            <div className="book-flight-card-place-name">{origin}</div>
          </div>
        </div>
        <div className="book-flight-card-time-details">
          <div className="book-flight-card-time-taken">
            <ReactSVG src={ClockSvg} />
            {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
            {convertAccumulatedTime(AccumulatedDuration)}
          </div>
          <div className="book-flight-card-way">
            <ReactSVG src={DashedSvg} />
            <div className="book-flight-card-way-text">{flightTravelType}</div>
            <ReactSVG src={DashedSvg} />
            <ReactSVG
              src={PlanelineSvg}
              className="book-flight-card-plane-line-icon"
            />
          </div>
          <Tippy
            content={
              <div className={classes.baggageAndCabinBaggageContainer}>
                <p className={classes.baggageAllowance}>
                  {t("flightSearch.baggageAllowance")}
                </p>
                <div className={classes.baggageAllowedContainer}>
                  <div className={classes.checkContainer}>
                    <ReactSVG src={CheckSvg} />
                  </div>
                  <div className={classes.baggageAllowed}>
                    {Baggage} {t("flightSearch.baggageAllowed")}
                  </div>
                </div>
                {CabinBaggage && (
                  <div className={classes.cabinBaggageContainer}>
                    <div className={classes.checkContainer2}>
                      <ReactSVG src={CheckSvg} />
                    </div>
                    <p className={classes.cabinBaggageAllowed}>
                      {CabinBaggage} {t("flightSearch.cabinBaggageAllowed")}
                    </p>
                  </div>
                )}
              </div>
            }
            arrow={true}
            placement="bottom-end"
            animation="shift-away"
          >
            <div className="book-flight-card-baggage-options">
              <ReactSVG src={BaggageSvg} />
              {t("flightSearch.baggageOptionsAvailable")}
            </div>
          </Tippy>
        </div>
        <div className="book-flight-card-place-details">
          <div>
            <div className="book-flight-card-place-time">
              {ArrTime && timeString(ArrTime)}
            </div>
            <div className="book-flight-card-place-name">{destination}</div>
          </div>
        </div>
        <div
          className="book-flight-card-flight-details-button"
          onClick={() => setShowFlightDetailsModal(true)}
        >
          {t("flightSearch.flightDetails")}
        </div>
      </div>

      {showFlightDetailsModal && (
        <FlightDetails
          setShowFlightDetailsModal={setShowFlightDetailsModal}
          showFlightDetailsModal={showFlightDetailsModal}
          flight={flight}
          markup={markup}
        />
      )}
    </>
  );
};

export default BookFlight;

BookFlight.propTypes = {
  flight: PropTypes.object,
  showButton: PropTypes.bool,
};
