import React, { useEffect, useState } from "react";
import { MobileCitySelectionField } from "../../components/MobileCitySelectionField";
import { MobileCalender } from "../../components/MobileCalender";
import moment from "moment";
import useStyles from "./styles";
import { Button } from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLoader,
  updateModifyDates,
  updateModifyFlightTravelType,
  updateModifySearchFlightObject,
} from "../../../utils/slices/modifySearchFlightSlice";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  updateFlightFrom,
  updateFlightTo,
  updateDates,
  updateFlightTravelType,
  handleMultiCityDatesHome,
  updateTotalSelectionForMultiCity,
  addError,
  removeError,
} from "../../../utils/slices/searchFlightSlice";
import { useNavigate } from "react-router";
import { clearBookedFlights } from "../../../utils/slices/bookedFlightSlice";
import FlightClass from "../../../components/search-widget/components/flight-search-widget/dropdowns/flight-class";
import TotalTravellers from "../../../components/search-widget/components/flight-search-widget/dropdowns/total-persons";
import {
  updateQueryFlightFrom,
  updateQueryFlightTo,
} from "../../../utils/slices/searchFlightQuerySlice";
import Error from "../../../components/flight-module/error";
import { useTranslation } from "react-i18next";
export const MobileMultiCityFlightFilterView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const [showFlightClassDropdown, setShowFlightClassDropdown] = useState(false);
  const [showPassengersComponent, setShowPassengersComponent] = useState(false);
  const {
    flightTravelType,
    flightType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
    flightClassType,
    flightTotalTravellers: {
      totalCount,
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
    errors,
    multiCityDates,
  } = useSelector((state) => state.searchFlight);

  useEffect(() => {
    const [first] = multiCityDates.filter((date) => date.key == 1);
    if (first) {
      setFirstDate(first?.date);
    } else {
      setFirstDate("");
    }

    const [second] = multiCityDates.filter((date) => date.key == 2);
    if (second) {
      setSecondDate(second?.date);
    } else {
      setSecondDate("");
    }
  }, [multiCityDates]);

  const addSearchFlightDetailsToLocalStorage = (value, returnDate) => {
    const data = {
      flightTravelType: value ? value : flightTravelType,
      flightType,
      flightFrom,
      flightTo,
      flightDepartureDate,
      flightReturnDate: returnDate ? returnDate : flightReturnDate,
      totalCount,
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
      flightClassType,
      multiCityDates,
    };
    window.localStorage.setItem("flightDetails", JSON.stringify(data));
    window.localStorage.setItem(
      "modifySearchFlightDetails",
      JSON.stringify(data)
    );
    dispatch(updateModifySearchFlightObject(data));
  };

  const [flightFilterState, setFlightFilterState] = useState({
    cities: {},
    checkInDate: "",
    checkOutDate: "",
  });
  const [isEnableThirdCity, setEnableThirdCity] = useState(false);
  const [checkInDate, setCheckInDate] = useState();
  const formatDate = (rawDate) => {
    const formattedValue = moment(rawDate).format("DD/MM/YYYY");
    return formattedValue;
  };

  const handleEnableThirdCity = () => {
    setEnableThirdCity(!isEnableThirdCity);
  };

  const handleFirstDepartureDateChange = (checkInDate) => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    setFlightFilterState((prev) => ({ ...prev, checkInDate }));
    setCheckInDate(checkInDate.formattedValue);
    dispatch(
      updateDates({ returnDate: "", departureDate: checkInDate.formattedValue })
    );
    dispatch(handleMultiCityDatesHome([]));
    dispatch(updateFlightTravelType(1));
  };
  const handleSecondDepartureDateChange = (checkInDate) => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    setFlightFilterState((prev) => ({ ...prev, checkInDate }));
    const updatesDates = multiCityDates?.filter((date) => date.key != 1);
    setCheckInDate(checkInDate.formattedValue);
    dispatch(
      handleMultiCityDatesHome([{ key: 1, date: checkInDate.formattedValue }])
    );
    dispatch(updateFlightTravelType(3));
  };

  const handleThirdDepartureDateChange = (checkInDate) => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    setFlightFilterState((prev) => ({ ...prev, checkInDate }));
    const updatesDates = multiCityDates?.filter((date) => date.key != 2);
    setCheckInDate(checkInDate.formattedValue);
    dispatch(
      handleMultiCityDatesHome([
        ...updatesDates,
        { key: 2, date: checkInDate.formattedValue },
      ])
    );
    dispatch(updateFlightTravelType(3));
  };

  const handleFirstCitySelection = (payload) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-1-city" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    dispatch(updateFlightFrom({ id: 0, data: payload.from }));
    dispatch(updateFlightTo({ id: 0, data: payload.to }));
  };

  const handleSecondCitySelection = (payload) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-1-city" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    dispatch(updateFlightFrom({ id: 1, data: payload.from }));
    dispatch(updateFlightTo({ id: 1, data: payload.to }));
  };
  const handleThirdCitySelection = (payload) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-1-city" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    dispatch(updateFlightFrom({ id: 2, data: payload.from }));
    dispatch(updateFlightTo({ id: 2, data: payload.to }));
  };

  const handleClick = () => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    dispatch(removeError({ id: "multi-city-1-city" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    for (let i = 0; i < flightFrom.length; i++) {
      if (
        flightFrom[i]?._id &&
        flightTo[i]?._id &&
        flightFrom[i]?._id === flightTo[i]?._id
      ) {
        dispatch(
          addError({
            id: "one-way-search",
            errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
          })
        );
        return;
      }

      if (errors.length === 0) {
        dispatch(clearBookedFlights());
        if (
          Object.keys(flightFrom?.[0]).length !== 0 &&
          Object.keys(flightTo?.[0]).length !== 0
        ) {
          if (flightTravelType === 1) {
            if (flightDepartureDate.length === 0) {
              dispatch(
                addError({
                  id: "one-way",
                  errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                })
              );
            } else {
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateTotalSelectionForMultiCity(0));
              dispatch(handleLoader(true));
              addSearchFlightDetailsToLocalStorage(flightTravelType);
              const nonStop = flightType == 1 ? true : false;
              navigate(
                `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}&tt=O&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
              );
            }
          } else if (flightTravelType === 2) {
            if (flightDepartureDate.length === 0) {
              dispatch(
                addError({
                  id: "one-way",
                  errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                })
              );
            } else if (flightReturnDate.length === 0) {
              dispatch(
                addError({
                  id: "round-trip",
                  errorMessage: `${t("errorMessages.selectReturnDate")}`,
                })
              );
            } else {
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(flightTravelType);
              const nonStop = flightType == 1 ? true : false;
              navigate(
                `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightTo[0].code}-${flightFrom[0].code}-${flightReturnDate}&tt=R&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
              );
            }
          } else {
            if (
              Object.keys(flightFrom?.[2]).length !== 0 &&
              Object.keys(flightTo?.[2]).length !== 0
            ) {
              if (
                Object.keys(flightFrom?.[1]).length !== 0 &&
                Object.keys(flightTo?.[1]).length !== 0
              ) {
                if (flightDepartureDate.length === 0) {
                  dispatch(
                    addError({
                      id: "one-way",
                      errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                    })
                  );
                } else {
                  const isFirstDatePresent = multiCityDates.find(
                    (date) => date.key === 1
                  );
                  if (!isFirstDatePresent) {
                    dispatch(
                      addError({
                        id: "multi-city-1",
                        errorMessage: `${t(
                          "errorMessages.selectDepartureDate"
                        )}`,
                      })
                    );
                    return;
                  }

                  const isSecondDatePresent = multiCityDates.find(
                    (date) => date.key === 2
                  );
                  if (!isSecondDatePresent) {
                    dispatch(
                      addError({
                        id: "multi-city-2",
                        errorMessage: `${t(
                          "errorMessages.selectDepartureDate"
                        )}`,
                      })
                    );
                    return;
                  }

                  const [firstDate] = multiCityDates.filter(
                    (date) => date.key === 1
                  );
                  const [secondDate] = multiCityDates.filter(
                    (date) => date.key === 2
                  );

                  dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                  dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                  dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                  dispatch(updateTotalSelectionForMultiCity(3));
                  addSearchFlightDetailsToLocalStorage(3);
                  dispatch(handleLoader(true));
                  const nonStop = flightType == 1 ? true : false;
                  navigate(
                    `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightFrom[1].code}-${flightTo[1].code}-${firstDate.date}_${flightFrom[2].code}-${flightTo[2].code}-${secondDate.date}&tt=M&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
                  );
                }
              } else {
                dispatch(
                  addError({
                    id: "multi-city-2-city",
                    errorMessage: `${t(
                      "errorMessages.enterSourceDestination"
                    )}`,
                  })
                );
              }
            } else if (
              Object.keys(flightFrom?.[1]).length !== 0 &&
              Object.keys(flightTo?.[1]).length !== 0
            ) {
              if (flightDepartureDate.length === 0) {
                dispatch(
                  addError({
                    id: "one-way",
                    errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                  })
                );
                return;
              } else {
                const isFirstDatePresent = multiCityDates.find(
                  (date) => date.key === 1
                );
                if (!isFirstDatePresent) {
                  dispatch(
                    addError({
                      id: "multi-city-1",
                      errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                    })
                  );
                  return;
                }
              }
              if (
                flightFrom[1]._id === flightTo[0]._id &&
                flightFrom[0]._id === flightTo[1]._id
              ) {
                const index = multiCityDates.findIndex(
                  (date) => date.key === 1
                );
                dispatch(updateFlightTravelType(Number(2)));
                dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                dispatch(updateModifyFlightTravelType(Number(2)));
                dispatch(
                  updateDates({
                    departureDate: flightDepartureDate,
                    returnDate: multiCityDates[index].date,
                  })
                );

                dispatch(
                  updateModifyDates({
                    departureDate: flightDepartureDate,
                    returnDate: multiCityDates[index].date,
                  })
                );

                addSearchFlightDetailsToLocalStorage(
                  2,
                  multiCityDates[index].date
                );
                const nonStop = flightType == 1 ? true : false;
                dispatch(handleLoader(true));
                navigate(
                  `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightTo[0].code}-${flightFrom[0].code}-${multiCityDates[index].date}&tt=R&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
                );
              } else {
                const [firstDate] = multiCityDates.filter(
                  (date) => date.key === 1
                );
                dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                dispatch(updateTotalSelectionForMultiCity(2));
                addSearchFlightDetailsToLocalStorage(3);
                dispatch(handleLoader(true));
                const nonStop = flightType == 1 ? true : false;
                navigate(
                  `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightFrom[1].code}-${flightTo[1].code}-${firstDate.date}&tt=M&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
                );
              }
            } else {
              if (flightDepartureDate.length === 0) {
                dispatch(
                  addError({
                    id: "one-way",
                    errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                  })
                );
                return;
              }

              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateFlightTravelType(Number(1)));
              dispatch(updateTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(1);
              const nonStop = flightType == 1 ? true : false;
              dispatch(handleLoader(true));
              navigate(
                `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}&tt=O&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
              );
            }
          }
        } else {
          dispatch(
            addError({
              id: "one-way-search",
              errorMessage: `${t("errorMessages.enterSourceDestination")}`,
            })
          );
        }
      }
    }
    addSearchFlightDetailsToLocalStorage(0);
  };
  const styles = useStyles();
  return (
    <div className={styles.MobileMultiCity}>
      <div className={styles.MobileFirstCity}>
        <div>{t("flightSearchWidgetMobile.trip1")}</div>
        <MobileCitySelectionField
          onChange={handleFirstCitySelection}
          index={0}
        />
        {errors &&
          errors
            .filter((error) => error.id === "one-way-search")
            .map((error) => {
              const { id, errorMessage } = error;
              return <Error key={id} errorMessage={errorMessage} />;
            })}
        <MobileCalender
          placeholder={t("flightSearchWidgetMobile.departureDate")}
          formatDate={formatDate}
          onDateChange={handleFirstDepartureDateChange}
          selectedDate={flightDepartureDate}
        />
        {errors &&
          errors
            .filter((error) => error.id === "one-way")
            .map((error) => {
              const { id, errorMessage } = error;
              return <Error key={id} errorMessage={errorMessage} />;
            })}
      </div>
      <div className={styles.MobileSecondCity}>
        <div>{t("flightSearchWidgetMobile.trip2")}</div>
        <MobileCitySelectionField
          onChange={handleSecondCitySelection}
          index={1}
        />
        {errors &&
          errors
            .filter((error) => error.id === "multi-city-2-city")
            .map((error) => {
              const { id, errorMessage } = error;
              return <Error key={id} errorMessage={errorMessage} />;
            })}
        <MobileCalender
          placeholder={t("flightSearchWidgetMobile.departureDate")}
          formatDate={formatDate}
          onDateChange={handleSecondDepartureDateChange}
          selectedDate={firstDate}
          minDate={flightDepartureDate ? flightDepartureDate : ""}
        />
        {errors &&
          errors
            .filter((error) => error.id === "multi-city-1")
            .map((error) => {
              const { id, errorMessage } = error;
              return <Error key={id} errorMessage={errorMessage} />;
            })}
      </div>
      {isEnableThirdCity && (
        <div className={styles.MobileThirdCity}>
          <div className={styles.thirdCityNameLayout}>
            <div>{t("flightSearchWidgetMobile.trip3")}</div>
          </div>

          <MobileCitySelectionField
            onChange={handleThirdCitySelection}
            index={2}
          />
          {errors &&
            errors
              .filter((error) => error.id === "multi-city-2-city")
              .map((error) => {
                const { id, errorMessage } = error;
                return <Error key={id} errorMessage={errorMessage} />;
              })}
          <MobileCalender
            placeholder={t("flightSearchWidgetMobile.departureDate")}
            formatDate={formatDate}
            onDateChange={handleThirdDepartureDateChange}
            selectedDate={secondDate}
            minDate={
              firstDate
                ? firstDate
                : flightDepartureDate
                ? flightDepartureDate
                : ""
            }
          />
          {errors &&
            errors
              .filter((error) => error.id === "multi-city-2")
              .map((error) => {
                const { id, errorMessage } = error;
                return <Error key={id} errorMessage={errorMessage} />;
              })}
        </div>
      )}

      <FlightClass
        showFlightClassDropdown={showFlightClassDropdown}
        setShowFlightClassDropdown={setShowFlightClassDropdown}
      />
      <TotalTravellers
        showPassengersComponent={showPassengersComponent}
        setShowPassengersComponent={setShowPassengersComponent}
      />
      <div className={styles.buttonsLayout}>
        <Button
          onClick={handleClick}
          label={`${t("flightSearchWidgetMobile.search")}`}
        />
        {isEnableThirdCity ? (
          <DeleteRoundedIcon
            sx={{ color: "#1b1d52", width: "40px", height: "40px" }}
            onClick={() => {
              handleEnableThirdCity();
              dispatch(updateFlightFrom({ id: 2, data: {} }));
              dispatch(updateFlightTo({ id: 2, data: {} }));
              const updatesDates = multiCityDates?.filter(
                (date) => date.key != 2
              );
              dispatch(handleMultiCityDatesHome([...updatesDates]));
            }}
          />
        ) : (
          <ControlPointRoundedIcon
            className={styles.plusIcon}
            sx={{ color: "#1b1d52", width: "40px", height: "40px" }}
            onClick={handleEnableThirdCity}
          />
        )}
      </div>
    </div>
  );
};
