import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookFlightInternationalRoundTripCard from "./show-flight";
import { setIndexOfRoundTrip } from "../../../../../../utils/slices/bookedFlightSlice";
import { searchClubbedFlights } from "../../../../../../modules/flight";
import {
  handleClubbedFlightForInternationalRoundTrip,
  handleSearchedFlightsForInternationalRoundTrip,
  handleSelectedFlightForInternationalRoundTrip,
} from "../../../../../../utils/slices/internationalRoundTripSlice";
import {
  handleClearAllFilters,
  handleClubbedFiltersForSecondCity,
  handleFilteredFlights,
  handleQuickFilters,
  handleSortFilter,
  handleTotalFlights,
} from "../../../../../../utils/slices/flightFiltersSlice";
import BookFlightInternationalRoundTripCardMobile from "./show-flight-mobile";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import { useNavigate } from "react-router-dom";
import {
  filterFlightsHelperFunctionForOneWay,
  sortFlightsOnPrice,
} from "../../helper";
import FlightNotFound from "../flight-not-found";
import handleUnauthorisedLogout from "../../../../../../modules/unauthorised";
import { setUserInfo } from "../../../../../../utils/slices/userSlice";
import useResponsive from "../../../../../../hooks/responsive.hook";
import FlightResultSubHeader from "../../flight-result-sub-header";

const BookFlightInternationalRoundTrip = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const currentBreakpoint = useResponsive();
  const { indexOfRoundTrip: index } = useSelector(
    (state) => state.bookedFlight
  );
  const { searchedFlights, clubbedFlights } = useSelector(
    (state) => state.internationalRoundTripDetails
  );
  const user = useSelector((state) => state.user);
  const {
    outboundFilters,
    airlineFilter: airlines,
    filteredFlights,
    stopsFilter: stops,
    refundFilter: refundOption,
    priceFilter: price,
    durationFilter: duration,
    outBoundDepartureDurationFilter: outBoundDepartureDuration,
    outBoundArrivalDurationFilter: outBoundArrivalDuration,
    sort,
    clubbedFiltersForSecondCity,
  } = useSelector((state) => state.flightFilters);

  const {
    flightTotalTravellers: {
      flightAdultsCount,
      flightInfantsCount,
      flightChildrenCount,
    },
  } = useSelector((state) => state.modifySearchFlight);

  const {
    flightClassType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
  } = useSelector((state) => state.searchFlightQuery);

  const setClubbedFiltersForSecondCity = (data) => {
    dispatch(handleClubbedFiltersForSecondCity(data));
  };

  const handleBookNow = async (selected) => {
    const packages =
      filteredFlights?.length > 0
        ? filteredFlights[selected]
        : searchedFlights[selected];
    const selectedFlight =
      filteredFlights?.length > 0
        ? filteredFlights[selected][0]
        : searchedFlights[selected][0];
    dispatch(handleSortFilter(true));
    const data = await searchClubbedFlights({
      segmentIndex: 1,
      packages,
      setClubbedFiltersForSecondCity,
      token: user?.token,
    });

    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    dispatch(handleClubbedFlightForInternationalRoundTrip(data));
    dispatch(setIndexOfRoundTrip(1));
    dispatch(handleSelectedFlightForInternationalRoundTrip(selectedFlight));
    dispatch(handleClearAllFilters());
  };
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const handleBookNowOfFinal = async (flight) => {
    const airlineCode = JSON.stringify([flight.ValidatingAirline]);
    const traceId = window.localStorage.getItem("traceId");
    const resultIndex = JSON.stringify([flight?.ResultId]);
    const isLcc = JSON.stringify([flight?.IsLcc]);
    const markup = getMarkup(flight?.markup, flight);
    const markupId = flight?.markup?._id;
    const fare = JSON.stringify([
      Math.ceil(flight?.Fare?.TotalFare * rateOfExchange) + markup,
    ]);
    const isInternational = flightFrom[0].country != flightTo[0].country;
    dispatch(handleClearAllFilters());
    {
      isMobile
        ? navigate(
            `/flight/review-details/${traceId}/${resultIndex}/${isLcc}/${2}/${"IRT"}/${flightAdultsCount}/${flightChildrenCount}/${flightInfantsCount}/${fare}/${markupId}/${flightClassType}/${airlineCode}?ff=${
              flightFrom[0].code
            }&ft=${
              flightTo[0].code
            }&dd=${flightDepartureDate}&rd=${flightReturnDate}&isIt=${isInternational}`
          )
        : window.open(
            `/flight/review-details/${traceId}/${resultIndex}/${isLcc}/${2}/${"IRT"}/${flightAdultsCount}/${flightChildrenCount}/${flightInfantsCount}/${fare}/${markupId}/${flightClassType}/${airlineCode}?ff=${
              flightFrom[0].code
            }&ft=${
              flightTo[0].code
            }&dd=${flightDepartureDate}&rd=${flightReturnDate}&isIt=${isInternational}`
          );
    }
  };

  useEffect(() => {
    handleFilterFlightForInternationalRoundTrip();
  }, [
    airlines,
    stops,
    refundOption,
    price,
    duration,
    outBoundDepartureDuration,
    outBoundArrivalDuration,
  ]);

  const handleFilterFlightForInternationalRoundTrip = () => {
    const filteredFlights = filterFlightsHelperFunctionForOneWay({
      stops,
      airlines,
      refundOption,
      price,
      duration,
      flights: index === 0 ? searchedFlights : clubbedFlights,
      index: index === 0 ? 0 : 1,
      outBoundDepartureDuration,
      outBoundArrivalDuration,
    });
    dispatch(handleFilteredFlights(filteredFlights));
    if (index === 0) dispatch(handleTotalFlights(searchedFlights?.length));
    else dispatch(handleTotalFlights(clubbedFlights?.length));
  };

  const getMarkup = (markupDetails, flight) => {
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt =
          (amt * (flight?.Fare?.BaseFare + flight?.Fare?.OtherCharges)) / 100;
      } else {
        amt = (amt * flight?.Fare?.TotalFare) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    return Math.ceil(amt);
  };

  useEffect(() => {
    const flights =
      filteredFlights.length > 0
        ? filteredFlights
        : index === 0
        ? searchedFlights
        : clubbedFlights;
    const sortedFlights = sortFlightsOnPrice(sort, flights);
    if (filteredFlights.length > 0) {
      dispatch(handleFilteredFlights(sortedFlights));
    } else if (index === 0) {
      dispatch(handleSearchedFlightsForInternationalRoundTrip(sortedFlights));
    } else {
      dispatch(handleClubbedFlightForInternationalRoundTrip(sortedFlights));
    }
  }, [sort]);

  useEffect(() => {
    handleQickFiltersAirlines();
  }, [clubbedFiltersForSecondCity, outboundFilters, index]);

  const handleQickFiltersAirlines = () => {
    const airlineCount =
      index === 0
        ? outboundFilters.airlineCount
        : clubbedFiltersForSecondCity.airlineCount;

    const airlines = Object.keys(airlineCount)
      .sort((a, b) => airlineCount[b] - airlineCount[a])
      .slice(0, 3);
    dispatch(handleQuickFilters([...airlines]));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      {currentBreakpoint === "extraLarge" && <FlightResultSubHeader />}
      {index === 0 ? (
        stops.length > 0 ||
        airlines.length > 0 ||
        refundOption.length > 0 ||
        price.length > 0 ||
        duration.length > 0 ||
        outBoundDepartureDuration.length > 0 ||
        outBoundArrivalDuration.length > 0 ? (
          filteredFlights?.length > 0 ? (
            filteredFlights.map((flight, i) => {
              return (
                <>
                  {isMobile ? (
                    <BookFlightInternationalRoundTripCardMobile
                      key={i}
                      flight={flight}
                      handleBookNow={handleBookNow}
                      index={index}
                      selected={i}
                    />
                  ) : (
                    <BookFlightInternationalRoundTripCard
                      key={i}
                      flight={flight}
                      handleBookNow={handleBookNow}
                      index={index}
                      selected={i}
                    />
                  )}
                </>
              );
            })
          ) : (
            <FlightNotFound />
          )
        ) : (
          searchedFlights &&
          searchedFlights.map((flight, i) => {
            return (
              <>
                {isMobile ? (
                  <BookFlightInternationalRoundTripCardMobile
                    key={i}
                    flight={flight}
                    handleBookNow={handleBookNow}
                    index={index}
                    selected={i}
                  />
                ) : (
                  <BookFlightInternationalRoundTripCard
                    key={i}
                    flight={flight}
                    handleBookNow={handleBookNow}
                    index={index}
                    selected={i}
                  />
                )}
              </>
            );
          })
        )
      ) : stops.length > 0 ||
        airlines.length > 0 ||
        refundOption.length > 0 ||
        price.length > 0 ||
        duration.length > 0 ||
        outBoundDepartureDuration.length > 0 ||
        outBoundArrivalDuration.length > 0 ? (
        filteredFlights?.length > 0 ? (
          filteredFlights &&
          filteredFlights.map((flight, i) => {
            return (
              <>
                {isMobile ? (
                  <BookFlightInternationalRoundTripCardMobile
                    key={i}
                    flight={flight}
                    handleBookNow={handleBookNowOfFinal}
                    index={index}
                    selected={i}
                  />
                ) : (
                  <BookFlightInternationalRoundTripCard
                    key={i}
                    flight={flight}
                    handleBookNow={handleBookNowOfFinal}
                    index={index}
                    selected={i}
                  />
                )}
              </>
            );
          })
        ) : (
          <FlightNotFound />
        )
      ) : (
        clubbedFlights &&
        clubbedFlights.map((flight, i) => {
          return (
            <>
              {isMobile ? (
                <BookFlightInternationalRoundTripCardMobile
                  key={i}
                  flight={flight}
                  handleBookNow={handleBookNowOfFinal}
                  index={index}
                  selected={i}
                />
              ) : (
                <BookFlightInternationalRoundTripCard
                  key={i}
                  flight={flight}
                  handleBookNow={handleBookNowOfFinal}
                  index={index}
                  selected={i}
                />
              )}
            </>
          );
        })
      )}
    </div>
  );
};

export default BookFlightInternationalRoundTrip;
