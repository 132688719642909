import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navigationDrawer: {
    display: "flex",
    padding: "44px 24px 24px 24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  drawerContentWithIcons: {
    display: "flex",
    paddingBottom: "0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderBottom: "1px solid  #D0D5DD",
    width: "166px",
  },
  drawerContentWithoutIcons: {
    display: "flex",
    width: "166px",
    paddingBottom: "0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    borderBottom: "1px solid  #D0D5DD",
  },
  contentLayout: {
    display: "flex",
    padding: "8px 0px",
    alignItems: "center",
    gap: "8px",
  },
  contentText: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  headerCountryDropdown: {
    display: "flex",
    padding: "8px",
    borderRadius: "8px",
    columnGap: "6px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorPrimary,
    cursor: "pointer",
  },

  headerCountryDropdownDivider: {
    width: "1px",
    height: "18px",
    backgroundColor: theme.palette.entityColors.entityColorBorder,
  },

  headerCountryDropdownFlag: {
    height: "10px",
    width: "16px",
    position: "relative",
    bottom: "4px",
  },
  headerLoginSignupAction: {
    padding: "8px",
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },
}));

export default useStyles;
