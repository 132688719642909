import React from "react";
import SignupInForm from "./IN";
import { signup } from "../../modules/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  handleRedirectScreen,
  setSignupPopup,
} from "../../utils/slices/popupSlice";
import { setUserInfo } from "../../utils/slices/userSlice";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirectUrl } = useSelector((state) => state.popup);
  const submitForm = async (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNo: values.number,
      deviceToken: "test",
      deviceType: "WEB",
      countryCode: "+966",
    };

    const {
      statusCode,
      data: {
        newCustomer,
        firstName,
        accessToken,
        lastName,
        email,
        profilePic,
      },
    } = await signup(data);
    if (statusCode === 200) {
      if (window.localStorage) {
        const userDetails =
          window.localStorage &&
          JSON.parse(window.localStorage.getItem("userDetails"));
        userDetails.newCustomer = newCustomer;
        userDetails.firstName = firstName;
        userDetails.token = accessToken;
        userDetails.lastName = lastName;
        userDetails.email = email;
        userDetails.profilePic = profilePic;
        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
        dispatch(setUserInfo(userDetails));
        if (redirectUrl?.screenNo) {
          dispatch(handleRedirectScreen({ screenNo: redirectUrl.screenNo }));
        }
      }

      dispatch(setSignupPopup(false));
    }
  };

  return (
    <>
      <SignupInForm submitForm={submitForm} />
    </>
  );
};

export default Signup;
