import React from "react";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

function TermsAndConditions({ terms }) {
  const { t } = useTranslation();
  return (
    <div
      className="card"
      style={{
        borderRadius: "10px",
        background: "#FFF",
        padding: "24px 16px",
        display: "flex",
        flexDirection: "column",
        gap: "26px",
        marginTop: "15px",
      }}
    >
      <div className="card-content">
        <div className="card-body">
          <h4
            className="card-title"
            style={{
              fontSize: "16px",
              color: "#000",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {t("packages.termsAndConditions")}
          </h4>
          <p className="card-text"></p>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(terms || ""),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
