import React from "react";
import LoginInForm from "./KSA";
import { login, loginWithEmail } from "../../modules/auth";
import { useDispatch } from "react-redux";
import { setOtpPopup, setLoginPopup } from "../../utils/slices/popupSlice";
import { setUserInfo } from "../../utils/slices/userSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Login = () => {
  console.log('login popup')
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const submitForm = async (values) => {
    const email = `${values.email}`;
    const password = `${values.password}`;
    const response = await loginWithEmail({
      email,
      password,
      // deviceToken: "test",
      // deviceType: "WEB",
    });
    if (response.statusCode === 200) {
      const { firstName, accessToken, lastName, profilePic, phoneNo, isBlocked } =
        response.data;
      const userData = {
        email: email,
        firstName: firstName,
        token: accessToken,
        lastName: lastName,
        profilePic: profilePic,
        phoneNo: phoneNo,
      };
      if (isBlocked) {
        toast.error(t("errorMessages.youAreBlocked"))
        dispatch(setLoginPopup(false));

      } else {
        window.localStorage.setItem("userDetails", JSON.stringify(userData));
        dispatch(setUserInfo(userData));
        // dispatch(setOtpPopup(true));
        dispatch(setLoginPopup(false));
        toast.success(`${t("loginPopUP.loginSuccessfully")}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <LoginInForm submitForm={submitForm} />
    </>
  );
};

export default Login;
