import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelListing: {
    display: "grid",
    gridTemplateColumns: "repeat(2,minmax(150px, 1fr))",
    gridTemplateRows: "repeat(2,minmax(150px, 1fr))",
    width: "100%",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "minmax(150px, 1fr)",
      gridTemplateRows: "none",
    },
  },
  hotelCardWrapper: {
    cursor: "pointer",
  },
  hotelCardBody: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  hotelCardNameSection: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "6px",
  },
  hotelCardNameSectionLabel: {
    fontSize: "14px",
    fontWeight: "700",
    color: theme.palette.textColors.textColorPrimary,
  },
  hotelCardNameSectionRatingCount: {
    display: "flex",
    // alignItems: "center",
    columnGap: "3px",
    fontSize: theme.fontSizes.fontSize3,
    color: theme.palette.textColors.textColorSecondary,
  },
  hotelCardPriceSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  hotelCardPriceSectionOfferTag: {
    backgroundColor: theme.palette.entityColors.entityColorBackgroundLightGreen,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 6px",
    boxSizing: "border-box",
    columnGap: "4px",
  },
  hotelCardPriceSectionOfferTagIcon: {
    color: theme.palette.entityColors.entityColorBackgroundGreen,
  },
  hotelCardPriceSectionOfferTagText: {
    color: theme.palette.entityColors.entityColorBackgroundGreen,
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "500",
  },
  hotelCardPriceSectionPrice: {
    display: "flex",
    flexDirection: "column",
    rowGap: "3px",
    alignItems: "flex-end",
  },
  hotelCardPriceSectionPriceNumber: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "700",
  },
  hotelCardPriceSectionPriceTaxes: {
    color: theme.palette.textColors.textColorSecondary,
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "400",
  },

  errorImage: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
