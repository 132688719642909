import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import PlanelineSvg from "../../../../../../../assets/images/plane-line.svg";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import CheckPrice from "../check-price";
import { useSelector } from "react-redux";
import FlightDetailsMobile from "../../../cards/flight-details-mobile";
import {
  calculateFlightDurationInHoursAndMinutes,
  flightDurationInHoursAndMinutes,
  addDurationStrings,
  convertAccumulatedTime,
} from "../../../../../../../utils/helpers";
import eventBus from "../../../../../../../core/eventBus";
import { useTranslation } from "react-i18next";

const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookingMultiFlightsMobile = ({
  flight,
  handleBookNow,
  index,
  selected,
}) => {
  const { flightFrom, flightTo } = useSelector(
    (state) => state.modifySearchFlight
  );
  const styles = useStyles();
  const { t } = useTranslation();
  const [markup, setMarkup] = useState(0);
  const [isFlightsDetailsMenuOpen, setIsFlightsDetailsMenuOpen] =
    useState(false);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const {
    Segments,
    Fare: { TotalFare, BaseFare, Tax, OtherCharges },
  } = flight[0];
  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    Duration: departureDuration,
    CabinBaggage,
    Baggage,
    Destination: { ArrTime: layoverDepTime },
    // AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[index][0];
  const {
    Duration: arrivalDuration,
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[index][Segments[index]?.length - 1];
  const length = Segments[index]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1}  ${t("flightSearch.stops")}`;

  const style = useStyles();

  const timeString = (dateTime) => {
    const dateTimeMoment = moment(dateTime);
    const formattedTime = dateTimeMoment.format("HH:mm");
    return formattedTime;
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );

  const handleCheckPriceBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <CheckPrice
        flights={flight}
        handleBookNow={handleBookNow}
        markupDetails={flight[0].markup}
      />
    );
  };

  useEffect(() => {
    getMarkup(flight[0]?.markup);
  }, [TotalFare, BaseFare, OtherCharges]);

  const getMarkup = (markupDetails) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }
    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * (BaseFare * rateOfExchange)) / 100;
      } else {
        amt = (amt * (TotalFare * rateOfExchange)) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    setMarkup(Math.ceil(amt));
  };

  const handleFlightDetailsBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <FlightDetailsMobile
        flight={flight[0]}
        segmentIndex={index}
        markup={markup}
      />
    );
  };
  return (
    <Box className={style.bookFlightMobile}>
      <Box className={style.flightDetailsCard}>
        <Box className={style.airLinesDetails}>
          <img
            src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
            onError={(e) => {
              e.target.src = PlaceholderImage;
            }}
            style={{ height: "36px", width: "42px" }}
          ></img>
          <Box className={style.airlineNameLayout}>
            <div className={style.airLinesName}>{AirlineName}</div>
            <div className={style.airLineCode}>
              {AirlineCode}-{FlightNumber}
            </div>
          </Box>
        </Box>
        <Box className={style.flightFromDetails}>
          <div className={style.departureDate}>
            {DepTime && timeString(DepTime)}
          </div>
          <div className={style.flightFrom}>{origin}</div>
        </Box>
        <Box className={style.flightTime}>
          <Box className={style.flightDuration}>
            <ReactSVG
              src={ClockSvg}
              style={{
                width: "12px",
                height: "12px",
              }}
            />
            <div className={style.durationTime}>
              {/* {firstAccumulatedDuration > 0
                ? flightDurationInHoursAndMinutes(firstAccumulatedDuration)
                : lastAccumulatedDuration > 0
                ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                : flightDurationInHoursAndMinutes(departureDuration)} */}
              {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
              {convertAccumulatedTime(firstAccumulatedDuration)}
            </div>
          </Box>
          <Box className={style.flightTravelType}>
            <div
              style={{
                width: "20px",
                height: "1px",
                background: "#D0D5DD",
              }}
            ></div>
            <div className={style.flightTravelTypeText}>{flightTravelType}</div>
            <div
              style={{
                width: "20px",
                height: "1px",
                background: "#D0D5DD",
              }}
            ></div>
            <ReactSVG src={PlanelineSvg} />
          </Box>
        </Box>
        <Box className={style.flightToDetails}>
          <div className={style.departureDate}>
            {ArrTime && timeString(ArrTime)}
          </div>
          <div className={style.flightFrom}>{destination}</div>
        </Box>
      </Box>
      <Box className={style.fairDetailscard}>
        <Box className={style.baggageOption}>
          <Box className={style.baggageOptionLayout}>
            <div
              className={style.baggageText}
              onClick={() => handleFlightDetailsBottomSheet()}
            >
              {t("flightSearch.flightDetails")}
            </div>
          </Box>
        </Box>
        {(index === 2 ||
          (index < 2 && Object.keys(flightFrom[index + 1]).length === 0) ||
          Object.keys(flightTo[index + 1]).length === 0) && (
          <Box className={style.fairText}>
            <div className={style.fairTextLayout}>
              <div>{t("flightSearch.currencySymbol")}</div>
              {(Math.ceil(TotalFare) + markup).toLocaleString("en-IN")}
            </div>
          </Box>
        )}
      </Box>

      {flightFrom[index + 1]?.code && flightTo[index + 1]?.code && (
        <button
          className={style.buttonLayout}
          onClick={() => handleBookNow(selected)}
        >
          {t("flightSearch.bookNow")}
        </button>
      )}
      {index === 2 ||
      (index < 2 && Object.keys(flightFrom[index + 1]).length === 0) ||
      Object.keys(flightTo[index + 1]).length === 0 ? (
        flight?.length > 1 ? (
          <button
            className={style.buttonLayout}
            onClick={handleCheckPriceBottomSheet}
          >
            {t("flightSearch.checkPrice")}
          </button>
        ) : (
          <button
            className={style.buttonLayout}
            onClick={() => handleBookNow(selected)}
          >
            {t("flightSearch.bookNow")}
          </button>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};
BookingMultiFlightsMobile.propTypes = {
  flight: PropTypes.any,
  handleBookNow: PropTypes.func,
  index: PropTypes.any,
  selected: PropTypes.any,
};
export default BookingMultiFlightsMobile;
