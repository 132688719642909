import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardScroll: {
    display: "flex",
    gap: "16px",
    marginTop: "-40px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    zIndex: 10,
  },

  cardContainer: {
    scrollbarWidth: "none",

    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      marginTop: "20px",

      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
      },
      overflowY: "hidden",
    },
  },

  title: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "uppercase",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  packagesText: {
    color: "#4037acd6",
  },

  titleContainer: {
    marginTop: "-30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px",
      marginTop: "40px",
    },
  },

  viewAllPackageText: {
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      // marginBottom:"51px",
      marginRight: "10px",
    },
  },

  SubHeading: {
    color: "#525A6A",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

export default useStyles;
