import React, { useEffect, useState } from "react";
import { MobileCitySelectionField } from "../../components/MobileCitySelectionField";
import useStyles from "./style";
import { MobileCalender } from "../../components/MobileCalender";
import { PersonSelection } from "../../components/PersonSelection";
import { Button } from "../../components/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateHotelFilters } from "../../../utils/slices/globalFiltersSlice";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { updateHotelCity } from "../../../utils/slices/searchHotelSlice";
import { updateHotelDates } from "../../../utils/slices/searchHotelSlice";
import HotelTotalPerson from "../../../components/search-widget/components/hotel-search-widget/dropdown/total-persons";
import closeIcon from "../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../core/eventBus";
import { calculateNumberOfNights } from "../../../utils/helpers";
import nightStay from '../../../assets/images/nights_stay.svg'
import { useTranslation } from "react-i18next";

const MobileHotelModifyWIdget = () => {
  const styles = useStyles();
  const {t}  = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rooms = useSelector((state) => state.hotelRooms.rooms);
  const { hotelCheckInDate, hotelCheckOutDate } = useSelector(
    (state) => state.searchHotel
  );
  const { hotelCity } = useSelector((state) => state.searchHotel);
  const [hotelFilterState, setHotelFilterState] = useState({
    cities: { hotelCity },
    checkInDate: hotelCheckInDate,
    checkOutDate: hotelCheckOutDate,
    roomDetails: rooms,
  });

  const [minDate, setMinDate] = useState(hotelCheckInDate);
  const [showCheckInDateError, setShowCheckInDateError] = useState(false);
  const [showCheckOutDateError, setShowCheckOutDateError] = useState(false);
  const [showCityEnterError, setShowCityEnterError] = useState(false);
  const [
    ShowCheckOutMustBeGreaterThanCheckInError,
    setShowCheckOutMustBeGreaterThanCheckInError,
  ] = useState(false);

  const handleClick = () => {
    const totalGuests = [];
    const length = rooms?.length;
    for (let i = 0; i < length; i++) {
      const { adultCounter, childrenCounter, childrenAges } = rooms[i];
      totalGuests.push({
        adultCounter,
        childrenCounter,
        childrenAges,
      });
    }

    const data = {
      hotelCity: hotelCity,
      roomGuests: totalGuests,
      hotelCheckInDate: hotelCheckInDate,
      hotelCheckOutDate: hotelCheckOutDate,
    };

    window.localStorage.setItem("hotelDetails", JSON.stringify(data));
    window.localStorage.setItem(
      "modifySearchHotelDetails",
      JSON.stringify(data)
    );
    localStorage.removeItem("hotelGuestDetails");
    localStorage.removeItem("contactDetailsForHotel");

    dispatch(updateHotelFilters(data));
    let isEveryThingOk = true;
    if (
      !Object.keys(hotelFilterState.cities).length &&
      !hotelFilterState.checkInDate &&
      !hotelFilterState.checkOutDate
    ) {
      setShowCityEnterError(true);
      setShowCheckInDateError(true);
      setShowCheckOutDateError(true);
      isEveryThingOk = false;
    } else if (!Object.keys(hotelFilterState.cities).length) {
      setShowCityEnterError(true);
      isEveryThingOk = false;
    } else if (!hotelFilterState.checkInDate) {
      setShowCityEnterError(false);
      setShowCheckInDateError(true);
      isEveryThingOk = false;
    } else if (!hotelFilterState.checkOutDate) {
      setShowCityEnterError(false);
      setShowCheckInDateError(false);
      setShowCheckOutDateError(true);
      isEveryThingOk = false;
    } else if (
      hotelCheckOutDate.split("/")[0] <= hotelCheckInDate.split("/")[0]
    ) {
      setShowCheckOutMustBeGreaterThanCheckInError(true);
      setShowCheckOutDateError(false);
      isEveryThingOk = false;
    }
    const uuid = localStorage.getItem('uuid')
    if (isEveryThingOk) {
      // navigate("/hotel/search", { state: data });
      navigate(
        `/hotel/search?hi=${hotelCheckInDate}&hco=${hotelCheckOutDate}&tg=${JSON.stringify(
          totalGuests
        )}&hc=${JSON.stringify(hotelCity)}&sharedUUId=${uuid}`,
        { state: data }
      );
    }
  };

  const updateCities = (payload) => {
    setHotelFilterState((prev) => ({ ...prev, cities: payload }));
    dispatch(updateHotelCity(payload.from));
  };

  const handleCheckInDateChange = (checkInDate) => {
    setHotelFilterState((prev) => ({
      ...prev,
      checkInDate: checkInDate,
    }));
    dispatch(
      updateHotelDates({
        checkInDate: checkInDate.formattedValue,
        checkOutDate: "",
      })
    );
  };

  const handleCheckOutDateChange = (checkOutDate) => {
    setHotelFilterState((prev) => ({
      ...prev,
      checkOutDate: checkOutDate,
    }));
    dispatch(
      updateHotelDates({
        checkOutDate: checkOutDate.formattedValue,
        checkInDate: hotelCheckInDate,
      })
    );
  };

  const formatDate = (rawDate) => {
    const formattedValue = moment(rawDate).format("DD/MM/YYYY");
    return formattedValue;
  };

  useEffect(() => {
    addDaysToDate(hotelCheckInDate);
  }, [hotelCheckInDate]);

  const addDaysToDate = (inputDate) => {
    const dateParts = inputDate.split("/");
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    const maxDaysInMonth = new Date(year, month, 0).getDate();

    let newDay = day + 1;
    let newMonth = month;
    let newYear = year;

    if (newDay > maxDaysInMonth) {
      newDay -= maxDaysInMonth;
      newMonth++;

      if (newMonth > 12) {
        newMonth = 1;
        newYear++;
      }
    }

    const formattedDay = String(newDay).padStart(2, "0");
    const formattedMonth = String(newMonth).padStart(2, "0");

    setMinDate(`${formattedDay}/${formattedMonth}/${newYear}`);
  };

  return (
    <div className={styles.mobileHotelFilter}>
      <div className={styles.header}>
        {/* <div className={styles.headerText}>Edit travel details</div> */}
        <div className={styles.headerText}>
          {/* Modify Search */}
          {t("hotelSearchWidgetMobile.modifySearch")}
        </div>
        <div onClick={() => eventBus.emit("close-bottomSheet")}>
          <ReactSVG src={closeIcon} />
        </div>
      </div>
      <div
        className="selectCity"
        onClick={() => {
          if (showCityEnterError) {
            setShowCityEnterError(false);
          }
        }}
      >
        <MobileCitySelectionField
          for={"hotel"}
          placeholder={t("hotelSearchWidgetMobile.selectCity")}
          type={"single"}
          onChange={updateCities}
        />
        {showCityEnterError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
            {t("hotelSearchWidgetMobile.selectDestinationCity")}
          </p>
        )}
      </div>
      <div
        className="checkInDate"
        onClick={() => {
          if (showCheckInDateError) {
            setShowCheckInDateError(false);
          }
        }}
      >
        <MobileCalender
          placeholder={t("hotelSearchWidgetMobile.enterCheckInDate")}
          formatDate={formatDate}
          onDateChange={handleCheckInDateChange}
          selectedDate={hotelCheckInDate}
        />
        {showCheckInDateError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
           {t("hotelSearchWidgetMobile.enterCheckInDate")}
          </p>
        )}
      </div>
      <div className={styles.stayNights}>
        <ReactSVG
          style={{ color: "#1B1D52" }}
          src={nightStay}
          beforeInjection={(svg) => {
            svg.classList.add('nightsSvg')
            svg.setAttribute('style', 'fill: #1B1D52')
          }} />
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate)} {" "}
        {calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate) > 1 
        ? t("hotelSearchWidget.nights") :
          t("hotelSearchWidget.night")}
      </div>
      <div
        className="checkOutDate"
        onClick={() => {
          if (showCheckOutDateError) {
            setShowCheckOutDateError(false);
          }

          if (ShowCheckOutMustBeGreaterThanCheckInError) {
            setShowCheckOutMustBeGreaterThanCheckInError(false);
          }
        }}
      >
        <MobileCalender
          placeholder={t("hotelSearchWidgetMobile.enterCheckOutDate")}
          formatDate={formatDate}
          onDateChange={handleCheckOutDateChange}
          selectedDate={hotelCheckOutDate}
          minDate={minDate}
        />
        {showCheckOutDateError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
           {t("hotelSearchWidgetMobile.enterCheckOutDate")}
          </p>
        )}
        {ShowCheckOutMustBeGreaterThanCheckInError && (
          <p style={{ color: "red", fontSize: "14px", fontFamily: "lato" }}>
            Check out date must be greater than check in date
          </p>
        )}
      </div>
      <div>
        <HotelTotalPerson />
      </div>

      <div style={{ margin: "10px 0px" }}>
        <Button
          onClick={() => {
            handleClick();
            eventBus.emit("close-bottomSheet");
            window.location.reload();
          }}
          label={"Search"}
        />
      </div>
    </div>
  );
};

export default MobileHotelModifyWIdget;
