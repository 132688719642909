import React, { useEffect, useRef, useState } from "react";
import Error from "../../../../../flight-module/error";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import swapSvg from ".././../../../../../assets/images/swap.svg";
import { useDispatch, useSelector } from "react-redux";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import {
  addError,
  removeError,
  updateFlightFrom,
  updateFlightTo,
} from "../../../../../../utils/slices/searchFlightSlice";
import "./style.css";
import { getAirports } from "../../../../../../modules/flight";
import {
  updateModifyFlightFrom,
  updateModifyFlightTo,
} from "../../../../../../utils/slices/modifySearchFlightSlice";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { SwapVert } from "@mui/icons-material";
const SearchAirport = ({
  searchAirportId,
  showSourceOptions,
  setShowSourceOptions,
  showDestinationOptions,
  setShowDestinationOptions,
}) => {
  const { flightFrom, flightTo, errors } = useSelector(
    (state) => state.searchFlight
  );
  const [airports, setAirports] = useState([]);
  const [source, setSource] = useState("");
  const [sourceOptionsid, setSourceOptionsId] = useState();
  const [destination, setDestination] = useState("");
  const [destinationOptionsid, setDestinationOptionsId] = useState();
  const [showErrors, setShowErrors] = useState(errors);
  const [initialFrom, setInitialFrom] = useState(0);
  const [initialTo, setInitialTo] = useState(0);
  const { t } = useTranslation();
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const getLocale = useSelector((state) => state.locale);
  const fromClick = () => {
    fromRef.current.focus();
    let options = [false, false, false];
    if (initialFrom == 0) {
      options[searchAirportId] = true;
      setInitialFrom(1);
    } else {
      options[searchAirportId] = !showSourceOptions[searchAirportId];
    }
    setShowSourceOptions(options);
  };

  const toClick = () => {
    toRef.current.focus();
    let options = [false, false, false];
    if (initialTo == 0) {
      options[searchAirportId] = true;
      setInitialTo(1);
    } else {
      options[searchAirportId] = !showDestinationOptions[searchAirportId];
    }
    setShowDestinationOptions(options);
  };

  const styles = useStyles();
  const dispatch = useDispatch();

  const handleSourceValue = (id) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    if (destinationOptionsid === id) {
      dispatch(
        addError({
          id: searchAirportId,
          errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
        })
      );
    } else {
      dispatch(removeError({ id: searchAirportId }));
    }

    setSourceOptionsId(id);
    setSource("");
    const [sourceCity] = airports.filter((airport) => airport._id === id);
    dispatch(updateFlightFrom({ id: searchAirportId, data: sourceCity }));
    dispatch(updateModifyFlightFrom({ id: searchAirportId, data: sourceCity }));
    handleGetAirports();
    setShowSourceOptions(false);
  };

  const handleDestinationValue = (id) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "multi-city-2-city" }));
    if (sourceOptionsid === id) {
      dispatch(
        addError({
          id: searchAirportId,
          errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
        })
      );
    } else {
      dispatch(removeError({ id: searchAirportId }));
    }

    setDestinationOptionsId(id);
    setDestination("");
    const [destinationCity] = airports.filter((airport) => airport._id === id);
    dispatch(updateFlightTo({ id: searchAirportId, data: destinationCity }));
    dispatch(
      updateModifyFlightTo({ id: searchAirportId, data: destinationCity })
    );
    handleGetAirports();
    setShowDestinationOptions(false);
  };

  const handleSwap = () => {
    if (
      flightFrom.length > searchAirportId &&
      flightTo.length > searchAirportId &&
      Object.keys(flightFrom[searchAirportId]).length &&
      Object.keys(flightTo[searchAirportId]).length
    ) {
      const temp = flightTo[searchAirportId];
      dispatch(
        updateFlightTo({
          id: searchAirportId,
          data: flightFrom[searchAirportId],
        })
      );
      dispatch(
        updateModifyFlightTo({
          id: searchAirportId,
          data: flightFrom[searchAirportId],
        })
      );
      dispatch(updateFlightFrom({ id: searchAirportId, data: temp }));
      dispatch(updateModifyFlightFrom({ id: searchAirportId, data: temp }));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (showSourceOptions && !event.target.closest(".source-component")) ||
        source
      ) {
        setSource("");
        setShowSourceOptions([false, false, false]);
        handleGetAirports();
        setInitialFrom(0);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSourceOptions, source]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (showDestinationOptions &&
          !event.target.closest(".destination-component")) ||
        destination
      ) {
        setDestination("");
        setShowDestinationOptions([false, false, false]);
        handleGetAirports();
        setInitialTo(0);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDestinationOptions, destination]);

  useEffect(() => {
    setShowErrors(errors);
  }, [errors]);

  useEffect(() => {
    const sourceId =
      flightFrom[searchAirportId] && flightFrom[searchAirportId]._id;
    setSourceOptionsId(sourceId);
  }, [flightFrom, searchAirportId]);

  useEffect(() => {
    handleGetAirports();
  }, []);

  const handleGetAirports = async (search = "") => {
    const data = await getAirports({ limit: 100, skip: 0, search });
    if (data.length > 0) {
      setAirports(data);
    }
  };

  return (
    <div className="search-flight-fields">
      <div className="from source-component" onClick={fromClick}>
        <div
          style={{
            padding: "16px",
            display: "inline-flex",
            gap: "12px",
          }}
        >
          <FlightTakeoffIcon sx={{ width: "24px", height: "24px" }} />
          <input
            type="text"
            className="text source-component"
            placeholder={t("flightSearchWidget.from")}
            value={
              flightFrom &&
              flightFrom.length > searchAirportId &&
              Object.keys(flightFrom[searchAirportId]).length
                ? flightFrom[searchAirportId].code +
                  " - " +
                  flightFrom[searchAirportId].city +
                  ", " +
                  flightFrom[searchAirportId].country
                : source
            }
            onFocus={() => {
              let options = [false, false, false];
              options[searchAirportId] = true;
              setShowSourceOptions(options);
            }}
            onChange={(e) => {
              let options = [false, false, false];
              options[searchAirportId] = true;
              setShowSourceOptions(options);
              setSource(e.target.value);
              handleGetAirports(e.target.value);
              setSourceOptionsId();
              dispatch(updateFlightFrom({ id: searchAirportId, data: {} }));
              dispatch(
                updateModifyFlightFrom({ id: searchAirportId, data: {} })
              );
            }}
            ref={fromRef}
          />
        </div>
        {showSourceOptions && showSourceOptions[searchAirportId] && (
          <div className="from-class-options-group">
            <div
              style={{
                width: "inherit",
                height: "300px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "4px 16px",
                marginTop: "6px",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Lato, sans-serif",
                  color: "#344054",
                  textTransform: "uppercase",
                }}
              >
                {/* Suggestions */}
                {t("flightSearchWidget.suggestion")}
              </p>
              {airports.map((airport, index) => {
                const { _id, code, name, city, country } = airport;
                if (index < 15) {
                  return (
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        width: "inherit",
                        padding: "4px 0px",
                      }}
                      key={_id}
                      onClick={() => handleSourceValue(_id)}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "12px",
                          alignItems: "center",
                        }}
                      >
                        <FlightTakeoffIcon
                          sx={{ width: "24px", height: "24px" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Lato, sans-serif",
                            color: "#344054",
                          }}
                        >
                          <p>
                            {city}, {country}
                          </p>
                          <p
                            style={{
                              color: "#525A6A",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            {name}
                          </p>
                        </div>
                      </div>
                      <p
                        style={{
                          height: "30px",
                          background: "#E6E6FA",
                          borderRadius: "2px",
                          padding: "2px 6px",
                          fontSize: "12px",
                          color: "#1b1d52",
                          fontWeight: "400",
                          fontFamily: "Lato, sans-serif",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {code}
                      </p>
                    </div>
                  );
                }
                // return (
                //   <div
                //     style={{
                //       display: "inline-flex",
                //       justifyContent: "space-between",
                //       width: "inherit",
                //       padding: "4px 0px",
                //     }}
                //     key={_id}
                //     onClick={() => handleSourceValue(_id)}
                //   >
                //     <div
                //       style={{
                //         display: "inline-flex",
                //         gap: "12px",
                //         alignItems: "center",
                //       }}
                //     >
                //       <FlightTakeoffIcon
                //         sx={{ width: "24px", height: "24px" }}
                //       />
                //       <div
                //         style={{
                //           display: "flex",
                //           flexDirection: "column",
                //           fontSize: "14px",
                //           fontWeight: "400",
                //           fontFamily: "Lato, sans-serif",
                //           color: "#344054",
                //         }}
                //       >
                //         <p>
                //           {city}, {country}
                //         </p>
                //         <p
                //           style={{
                //             color: "#525A6A",
                //             fontSize: "12px",
                //             fontWeight: "400",
                //           }}
                //         >
                //           {name}
                //         </p>
                //       </div>
                //     </div>
                //     <p
                //       style={{
                //         background: "#EEF7FF",
                //         borderRadius: "2px",
                //         padding: "2px 6px",
                //         fontSize: "12px",
                //         color: "#08F",
                //         fontWeight: "400",
                //         fontFamily: "Lato, sans-serif",
                //         display: "flex",
                //         alignItems: "center",
                //       }}
                //     >
                //       {code}
                //     </p>
                //   </div>
                // );
              })}
            </div>
          </div>
        )}
      </div>
      <div
        className={
          localStorage.getItem("language") == "AR"
            ? styles.swapIconWrapperArabic
            : styles.swapIconWrapper
        }
        onClick={handleSwap}
      >
        <SwapVert style={{ fontSize: "24px" }} />
      </div>
      <div className="to destination-component" onClick={toClick}>
        <div style={{ padding: "16px", display: "inline-flex", gap: "12px" }}>
          <FlightLandIcon sx={{ width: "24px", height: "24px" }} />
          <input
            type="text"
            className="text destination-component"
            placeholder={t("flightSearchWidget.to")}
            value={
              Object.keys(
                flightTo?.length > searchAirportId && flightTo[searchAirportId]
              ).length
                ? flightTo[searchAirportId].code +
                  " - " +
                  flightTo[searchAirportId].city +
                  ", " +
                  flightTo[searchAirportId].country
                : destination
            }
            onFocus={() => {
              let options = [false, false, false];
              options[searchAirportId] = true;
              setShowDestinationOptions(options);
            }}
            onChange={(e) => {
              let options = [false, false, false];
              options[searchAirportId] = true;
              setShowDestinationOptions(options);
              setDestination(e.target.value);
              setDestinationOptionsId();
              handleGetAirports(e.target.value);
              dispatch(updateFlightTo({ id: searchAirportId, data: {} }));
              dispatch(updateModifyFlightTo({ id: searchAirportId, data: {} }));
            }}
            ref={toRef}
          />
        </div>
        {showDestinationOptions && showDestinationOptions[searchAirportId] && (
          <div className="to-class-options-group">
            <div
              style={{
                width: "inherit",
                height: "300px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "4px 16px",
                marginTop: "6px",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Lato, sans-serif",
                  color: "#344054",
                  textTransform: "uppercase",
                }}
              >
                {/* Suggestions */}
                {t("flightSearchWidget.suggestion")}
              </p>
              {airports.map((airport, index) => {
                const { _id, code, name, city, country } = airport;
                if (index < 15) {
                  return (
                    <div
                      style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        width: "inherit",
                        padding: "4px 0px",
                      }}
                      key={_id}
                      onClick={() => handleDestinationValue(_id)}
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          gap: "12px",
                          alignItems: "center",
                        }}
                      >
                        <FlightLandIcon
                          sx={{ width: "24px", height: "24px" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Lato, sans-serif",
                            color: "#344054",
                          }}
                        >
                          <p>
                            {city}, {country}
                          </p>
                          <p
                            style={{
                              color: "#525A6A",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            {name}
                          </p>
                        </div>
                      </div>
                      <p
                        style={{
                          background: "rgb(230, 230, 250)",
                          borderRadius: "2px",
                          padding: "2px 6px",
                          fontSize: "12px",
                          color: "rgb(27, 29, 82)",
                          fontWeight: "400",
                          fontFamily: "Lato, sans-serif",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {code}
                      </p>
                    </div>
                  );
                }
                // return (
                //   <div
                //     style={{
                //       display: "inline-flex",
                //       justifyContent: "space-between",
                //       width: "inherit",
                //       padding: "4px 0px",
                //     }}
                //     key={_id}
                //     onClick={() => handleDestinationValue(_id)}
                //   >
                //     <div
                //       style={{
                //         display: "inline-flex",
                //         gap: "12px",
                //         alignItems: "center",
                //       }}
                //     >
                //       <FlightLandIcon sx={{ width: "24px", height: "24px" }} />
                //       <div
                //         style={{
                //           display: "flex",
                //           flexDirection: "column",
                //           fontSize: "14px",
                //           fontWeight: "400",
                //           fontFamily: "Lato, sans-serif",
                //           color: "#344054",
                //         }}
                //       >
                //         <p>
                //           {city}, {country}
                //         </p>
                //         <p
                //           style={{
                //             color: "#525A6A",
                //             fontSize: "12px",
                //             fontWeight: "400",
                //           }}
                //         >
                //           {name}
                //         </p>
                //       </div>
                //     </div>
                //     <p
                //       style={{
                //         background: "#EEF7FF",
                //         borderRadius: "2px",
                //         padding: "2px 6px",
                //         fontSize: "12px",
                //         color: "#08F",
                //         fontWeight: "400",
                //         fontFamily: "Lato, sans-serif",
                //         display: "flex",
                //         alignItems: "center",
                //       }}
                //     >
                //       {code}
                //     </p>
                //   </div>
                // );
              })}
            </div>
          </div>
        )}
      </div>
      {showErrors.length > 0 &&
        showErrors.map((error) => {
          const { id, errorMessage } = error;
          return (
            <>
              {id === searchAirportId && (
                <div className="source-destination-error" key={id}>
                  <Error key={id} errorMessage={errorMessage} />
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};

export default SearchAirport;

SearchAirport.propTypes = {
  searchAirportId: PropTypes.number,
};
