import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bookingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  bookingsHeader: {
    padding: "0px 8px",
    display: "inline-flex",
    gap: "8px",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    alignItems: "flex-start",
    width: "100%",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      gap: "4px",
      justifyContent: "space-around",
    },
  },
  tabComponent: {
    display: "inline-flex",
    gap: "4px",
    padding: "12px 16px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "12px 6px",
    },
  },
  tabText: {
    color: "#667085",
    fontSize: "14px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  tabComponentActive: {
    display: "inline-flex",
    gap: "4px",
    padding: "12px 16px",
    borderBottom: "2px solid #4037acd6",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "12px 6px",
    },
  },
  tabTextActive: {
    color: "#4037acd6",
    fontSize: "14px",
    fontWeight: "400",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  bookingListContainer: {
    padding: "1rem",
    borderRadius: "16px",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  content1: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      alignItems: "flex-start",
    },
  },
  segmentContainer2: {
    display: "inline-flex",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
  },
  heading: {
    display: "inline-flex",
    color: "#344054",
    fontSize: "1rem",
    fontWeight: "600",
    gap: "16px",
  },
  subheading: {
    display: "inline-flex",
    color: "#525A6A",
    fontSize: "0.825rem",
    fontWeight: "400",
    gap: "12px",
    [theme.breakpoints.down("xxs")]: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  },
  content1Left: {
    display: "flex",
    flexDirection: "column",
  },
  content2: {
    display: "inline-flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
      width: "100%",
      gap: "12px",
    },
  },
  content21: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "flex-end",

    [theme.breakpoints.down("sm")]: {
      width: "45%",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
  },
  content22: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  lightText: {
    color: "#667085",
    fontSize: "0.825rem",
    fontWeight: "400",
  },
  boldText: {
    color: "#344054",
    fontSize: "0.825rem",
    fontWeight: "400",
  },
  inlineProperty: {
    display: "inline-flex",
    gap: "4px",
  },
  status: {
    padding: "6px",
    borderRadius: "10px",
    backgroundColor: "#E2FFE3",
    color: "#4CAF50",
    fontSize: "0.825rem",
    fontWeight: "400",
    justifyContent: "flex-end",
    textAlign: "center",
  },
  cancelledStatus: {
    padding: "6px",
    borderRadius: "10px",
    backgroundColor: "#FCCCD3",
    color: "red",
    fontSize: "0.825rem",
    fontWeight: "400",
    justifyContent: "flex-end",
    textAlign: "center",
  },
  cancel: {
    padding: "6px",
    borderRadius: "10px",
    backgroundColor: "#FCCCD3",
    color: "red",
    fontSize: "0.825rem",
    fontWeight: "400",
    justifyContent: "flex-end",
    textAlign: "center",
    cursor: "pointer",
  },
  reissue: {
    padding: "6px",
    borderRadius: "10px",
    backgroundColor: "#E6E6FA",
    color: "#1b1d52",
    fontSize: "0.825rem",
    fontWeight: "400",
    justifyContent: "flex-end",
    textAlign: "center",
    cursor: "pointer",
  },
  completed: {
    padding: "6px",
    borderRadius: "10px",
    backgroundColor: "#25D366",
    color: "white",
    fontSize: "0.825rem",
    fontWeight: "400",
    justifyContent: "flex-end",
    textAlign: "center",
  },
  cancelled: {
    padding: "6px",
    borderRadius: "10px",
    backgroundColor: "#FCCCD3",
    color: "red",
    fontSize: "0.825rem",
    fontWeight: "400",
    justifyContent: "flex-end",
    textAlign: "center",
  },
  viewStatus: {
    color: "#4037acd6",
    fontSize: "0.825rem",
    fontWeight: "400",
    cursor: "pointer",
  },
  mainContainer: {
    padding: "8px",
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    color: "#344054",
  },
  successButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    textTransform: "capitalize",
    background: "#1b1d52",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
  goBackButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    textTransform: "capitalize",
    background: "#ff4d4d",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
  contentLastTwoContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  // hotelStatusButton: {
  //   [theme.breakpoints.down('sm')]: {
  //     width: '150px'
  //   }
  // },
  // hotelCancelButton: {
  //   [theme.breakpoints.down('sm')]: {
  //     width: '150px'
  //   }
  // },
  addressContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
}));

export default useStyles;
