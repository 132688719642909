import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  filterButtonText: {
    color: "#344054",
    fontFamily: "'Lato', sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "0px 8px",
    display: "inline-flex",
    gap: "4px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },

  quickFilters: {
    height: "76px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      paddingLeft: "20px",
    },
  },

  quickFilterHeading: {
    color: "#344054",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",

    [theme.breakpoints.down("sm")]: {
      fontWeight: "500px",
      fontSize: "14px",
    },
  },
}));

export default useStyles;
