import { createSlice } from "@reduxjs/toolkit";

const getFlightDetailsFromLocalStorage = () => {
  const flight = JSON.parse(
    window.localStorage.getItem("modifySearchFlightDetails")
  );
  return flight;
};

const flight = getFlightDetailsFromLocalStorage();

const modifySearchFlightSlice = createSlice({
  name: "modifySearchFlight",
  initialState: {
    flightTravelType: flight ? flight?.flightTravelType : Number(1),
    addAnotherCity: false,
    flightType: flight ? flight?.flightType : "",
    flightFrom: flight ? flight?.flightFrom : [{}, {}, {}],
    flightTo: flight ? flight?.flightTo : [{}, {}, {}],
    flightDepartureDate: flight ? flight?.flightDepartureDate : "",
    flightReturnDate: flight ? flight?.flightReturnDate : "",
    flightClassType: flight ? flight?.flightClassType : Number(2),
    multiCityDates: flight ? flight?.multiCityDates : [],
    flightTotalTravellers: {
      totalCount: flight ? flight?.totalCount : 1,
      flightAdultsCount: flight ? flight?.flightAdultsCount : 1,
      flightChildrenCount: flight ? flight?.flightChildrenCount : 0,
      flightInfantsCount: flight ? flight?.flightInfantsCount : 0,
    },
    totalSelectionForMultiCity: 0,
    errors: [],
    searchedFlights: [],
    domesticSelectedFlights: [],
    searchApplicable: false,
    clubbedFlights: [],
    loading: false,
  },
  reducers: {
    updateModifyTravellersCount: (state, action) => {
      const { totalTravellers, adultCount, childrenCount, infantCount } =
        action.payload;
      Object.assign(state.flightTotalTravellers, {
        totalCount: totalTravellers,
        flightAdultsCount: adultCount,
        flightChildrenCount: childrenCount,
        flightInfantsCount: infantCount,
      });
    },
    updateModifyFlightClassType: (state, action) => {
      state.flightClassType = action.payload;
    },
    updateModifyFlightTravelType: (state, action) => {
      state.flightTravelType = action.payload;
    },
    updateModifyFlightType: (state, action) => {
      state.flightType = action.payload;
    },
    updateModifyFlightTo: (state, action) => {
      const { id, data } = action.payload;
      state.flightTo[id] = data;
    },
    updateModifyFlightFrom: (state, action) => {
      const { id, data } = action.payload;
      state.flightFrom[id] = data;
    },
    updateModifyDates: (state, action) => {
      const { departureDate, returnDate } = action.payload;
      state.flightDepartureDate = departureDate;
      state.flightReturnDate = returnDate;
    },
    addError: (state, action) => {
      const { id } = action.payload;
      const isErrorExists = state.errors.find((error) => error.id === id);
      if (!isErrorExists) state.errors.push(action.payload);
    },
    removeError: (state, action) => {
      state.errors = state.errors.filter(
        (error) => error.id !== action.payload.id
      );
    },
    updateModifyAnotherCity: (state, action) => {
      state.addAnotherCity = action.payload;
    },
    addModifySearchedFlights: (state, action) => {
      state.searchedFlights = action.payload;
    },
    updateModifySearchFlightObject: (state, action) => {
      const {
        flightTravelType,
        flightType,
        flightFrom,
        flightTo,
        flightDepartureDate,
        flightReturnDate,
        totalCount,
        flightAdultsCount,
        flightChildrenCount,
        flightInfantsCount,
        flightClassType,
        multiCityDates,
      } = action.payload;
      state.flightTravelType = flightTravelType;
      state.flightFrom = flightFrom;
      state.flightTo = flightTo;
      state.flightType = flightType;
      state.flightDepartureDate = flightDepartureDate;
      state.flightReturnDate = flightReturnDate;
      state.flightClassType = flightClassType;
      state.multiCityDates = multiCityDates;
      Object.assign(state.flightTotalTravellers, {
        totalCount: totalCount,
        flightAdultsCount: flightAdultsCount,
        flightChildrenCount: flightChildrenCount,
        flightInfantsCount: flightInfantsCount,
      });
    },
    handleSearchApplicable: (state, action) => {
      state.searchApplicable = action.payload;
    },
    handleSelectedDomesticFlights: (state, action) => {
      state.domesticSelectedFlights = action.payload;
    },
    handleClubbedFlights: (state, action) => {
      state.clubbedFlights = action.payload;
    },
    handleLoader: (state, action) => {
      state.loading = action.payload;
    },
    handleMultiCityDates: (state, action) => {
      state.multiCityDates = action.payload;
    },
    updateModifyTotalSelectionForMultiCity: (state, action) => {
      state.totalSelectionForMultiCity = action.payload;
    },
  },
});

export const {
  updateModifyTravellersCount,
  updateModifyFlightClassType,
  updateModifyFlightTravelType,
  updateModifyFlightType,
  updateModifyFlightFrom,
  updateModifyFlightTo,
  updateModifyDates,
  addError,
  removeError,
  updateModifyAnotherCity,
  addModifySearchedFlights,
  handleSearchApplicable,
  updateModifySearchFlightObject,
  handleSelectedDomesticFlights,
  handleClubbedFlights,
  handleLoader,
  handleMultiCityDates,
  updateModifyTotalSelectionForMultiCity,
} = modifySearchFlightSlice.actions;

export default modifySearchFlightSlice.reducer;
