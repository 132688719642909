import React from "react";
import useStyles from "./style";
import { Box, Typography } from "@mui/material";
import crossIcon from "../../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import eventBus from "../../../../core/eventBus";
import { updatePriceSorting } from "../../../../utils/slices/localFiltersSlice";
import { useTranslation } from "react-i18next";

const SearchingSortHotelMobile = () => {
  const style = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedRange = useSelector((state) => state.localFilters.priceFilter);

  const { sort } = useSelector((state) => state.flightFilters);
  return (
    <Box className={style.sortBottomDrawer}>
      <Box className={style.sortCard}>
        <Box className={style.title}>
          <div className={style.sortBy}>
            <ReactSVG
              src={crossIcon}
              onClick={() => eventBus.emit("close-bottomSheet")}
            />
            <Typography>{t("hotelSearchResult.sortBy")}</Typography>
          </div>
          <div
            className={style.clearAll}
            onClick={() =>
              dispatch(
                updatePriceSorting({
                  value: "",
                })
              )
            }
          >
            {t("hotelSearchResult.clearAll")}
          </div>
        </Box>

        <div className={style.sortList}>
          <div
            className={
              selectedRange.value == "asc"
                ? style.sortListLayoutSelected1
                : style.sortListLayout1
            }
            onClick={() =>
              dispatch(
                updatePriceSorting({
                  value: "asc",
                })
              )
            }
          >
            <p>{t("hotelSearchResult.lowestPriceFirst")}</p>
          </div>
          <div
            className={
              selectedRange.value == "desc"
                ? style.sortListLayoutSelected1
                : style.sortListLayout1
            }
            onClick={() =>
              dispatch(
                updatePriceSorting({
                  value: "desc",
                })
              )
            }
          >
            <p>{t("hotelSearchResult.highestPriceFirst")}</p>
          </div>

          <div
            className={
              selectedRange.value == "starRatingHighest"
                ? style.sortListLayoutSelected1
                : style.sortListLayout1
            }
            onClick={() =>
              dispatch(
                updatePriceSorting({
                  value: "starRatingHighest",
                })
              )
            }
          >
            <p>{t("hotelSearchResult.starRatingHighestFirst")}</p>
          </div>

          <div
            className={
              selectedRange.value == "starRatingLowest"
                ? style.sortListLayoutSelected
                : style.sortListLayout
            }
            onClick={() =>
              dispatch(
                updatePriceSorting({
                  value: "starRatingLowest",
                })
              )
            }
          >
            <p>{t("hotelSearchResult.starRatingLowestFirst")}</p>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default SearchingSortHotelMobile;
