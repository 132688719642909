import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  uiCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  uiCardImage: {
    width: "100%",
    height: "192px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    border: "1px solid #D0D5DD"
  },
  uiCardBody: {
    padding: "16px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "0px 0px 10px 10px",
    borderLeft: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    borderBottom: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    borderRight: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    minHeight: "120px",
  },
}));

export default useStyles;
