import React from "react";
import useStyles from "./styles";
import { get, noop } from "lodash";
import classNames from "classnames";

const ButtonComp = (props) => {
    
  const label = get(props, "label", "");
  const styles = useStyles();
  const variant = get(props, "variant", "primary");
  const onClick = get(props, "onClick", noop);

  const buttonClassName = classNames( styles.button,  variant === "primary" ? styles.buttonPrimary : styles.buttonSecondary);

  return (
    <div className={styles.buttonWrapper} >
      <button className={buttonClassName} onClick={onClick}>{label}</button>
    </div>
  );
};

export default ButtonComp;
