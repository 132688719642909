import React, { useState } from "react";
import useStyles from "./style";
import searchIcon from "../../../../assets/images/search-navigation.svg";
import helpIcon from "../../../../assets/images/helpIcon.svg";
import faqIcon from "../../../../assets/images/message-question.svg";
import profile from "../../../../assets/images/user_navigation.svg";
import logOutIcon from "../../../../assets/images/log-out-icon.svg";
import Otp from "../../../../Auth/Otp";
import Login from "../../../../Auth/Login";
import Signup from "../../../../Auth/Signup";
import Logout from "../../../../Auth/logout";
import CustomerSupport from "../../components/customer-support";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import MobileLogoutPopup from "../mobile-log-out-popup";
import eventBus from "../../../../core/eventBus";
import FlagSvg from "../../../../assets/images/india-flag.svg";
import countryList from "react-select-country-list";
import ReactCountryFlag from "react-country-flag";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { changeSelectedCountry } from "../../../../utils/slices/globalSlice";
import { Menu } from "@mui/material";
import { useTranslation } from "react-i18next";

const NavigationDrawer = ({ setIsMenuOpen }) => {
  const style = useStyles();
  const options = countryList().getData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryVal = useSelector((state) => state.global.selectedCountry);
  const [searchTerm, setSearchTerm] = useState(null);
  const { login, otp, signup } = useSelector((state) => state.popup);
  const [showCustomerSupportModal, setShowCustomerSupportModal] =
    useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showMobileLogoutModal, setShowMobileLogoutModal] = useState(false);
  const [toggleMobileLogOutPopup, setToggleMobileLogOutPopup] = useState(false);
  const [showCountryDropdown, setShowCountryDropdown] = useState(null);
  const [showCountryDropdownEL, setShowCountryDropdownEL] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const language = localStorage.getItem("language");
  const [selectedCountryCode, setSelectedCountryCode] = useState(language);
  const path = location.pathname;
  const openCountry = Boolean(showCountryDropdown);
  const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
  const { firstName, lastName, email, profilePic } = useSelector(
    (state) => state.user
  );

  const isMobile = useIsMobile();
  const handleMobileLogout = async (value) => {
    showLogoutModal(true);
  };

  const handleMobileLogOutPopup = () => {
    setIsMenuOpen(false);
    setShowLogoutModal(true);
  };

  const handleCountryClose = () => {
    setShowCountryDropdown(null);
  };
  const handleCountryClick = (option) => {
    dispatch(changeSelectedCountry(option));
    localStorage.setItem("selectedCountry", JSON.stringify(option));
    setShowCountryDropdown(false);
    window.location.reload();
  };

  const handleCountrySearch = (e) => {
    const term = e.target.value.toLowerCase();
    console.log("term ->", term);
    if (term == "") {
      setSearchTerm(term);
      setFilteredOptions(options);
    } else {
      let filteredOptions = options.filter((option) => {
        return option.label.toLowerCase().includes(term);
      });
      setFilteredOptions(filteredOptions);
      setSearchTerm(term);
    }
  };
  const handleCustomerSupport = () => {
    setIsMenuOpen(false);
    setShowCustomerSupportModal(true);
  };

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
    window.location.reload();
    changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);
  };
  return (
    <div className={style.navigationDrawer}>
      <div className={style.drawerContentWithIcons}>
        <div className={style.contentLayout}>
          <ReactSVG src={searchIcon} />
          <p className={style.contentText} onClick={() => navigate("/")}>
            Home
          </p>
        </div>
        <div className={style.contentLayout}>
          <ReactSVG src={helpIcon} style={{ height: 18, width: 18 }} />
          <p
            className={style.contentText}
            onClick={() => handleCustomerSupport()}
          >
            Customer Support
          </p>
        </div>
        <div className={style.contentLayout}>
          <ReactSVG src={faqIcon} />
          <p className={style.contentText} onClick={() => navigate("/faq")}>
            FAQ
          </p>
        </div>
        {firstName && (
          <div className={style.contentLayout}>
            <ReactSVG src={profile} />
            <p
              className={style.contentText}
              onClick={() => navigate("/profile")}
            >
              Profile
            </p>
          </div>
        )}
        <div className={style.contentLayout}>
          {firstName ? (
            <>
              <ReactSVG src={logOutIcon} />
              <div onClick={() => handleMobileLogOutPopup()}>Logout</div>
            </>
          ) : (
            <>
              <p className={style.contentText}></p>
            </>
          )}
        </div>
        <div className={style.contentLayout}>
          <div className="countrySelectNavBar">
            <div className={style.headerCountryDropdown} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <div
                className={style.headerCountryDropdownFlag}
                style={{ marginBottom: '5px' }}
              >
                <ReactCountryFlag
                  countryCode={countryVal ? countryVal.value : ""}
                  svg
                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  cdnSuffix="svg"
                  title={countryVal ? countryVal.value : ""}
                />
              </div>
              {path == "/" && (
                <>
                  <select
                    className={style.selectBackgrundColor}
                    onClick={() => {
                      setShowCountryDropdown(true);
                      const element = document.querySelector(
                        ".countrySelectNavBar"
                      );
                      setShowCountryDropdownEL(element);
                    }}
                  >

                  </select>
                </>
              )}
              <div className={style.headerCountryDropdownDivider} />
              <select
                className={style.selectBackgrundColor}
                value={selectedCountryCode}
                onChange={handleCountryChange}
              >
                <option value="EN">EN</option>
                <option value="AR">العربية</option>
              </select>
              {/* <div>EN</div> */}
              <div className={style.headerCountryDropdownDivider} />
              <div>SAR</div>
            </div>
          </div>
        </div>
      </div>

      {showCustomerSupportModal && (
        <CustomerSupport
          showCustomerSupportModal={showCustomerSupportModal}
          setShowCustomerSupportModal={setShowCustomerSupportModal}
        />
      )}
      {showLogoutModal && (
        <Logout
          showLogoutModal={showLogoutModal}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}

      {showCountryDropdown && (
        <Menu
          className={style.countryShowPopup}
          anchorEl={showCountryDropdownEL}
          open={openCountry}
          onClose={handleCountryClose}
          // onClick={handleCountryClose}
          PaperProps={{
            elevation: 0,
            style: {
              overflow: "hidden",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: "''",
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "#F2F7FB",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              borderRadius: "10px",
              background: "#F2F7FB",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <>
            <input
              type="text"
              onChange={handleCountrySearch}
              className="searchCountryInput"
              value={searchTerm}
              placeholder={selectedCountry?.label}
              style={{
                margin: "15px",
                width: "280px",
                height: "25px",
                borderRadius: "5px",
                border: "1px solid grey",
                paddingLeft: "5px",
              }}
            />

            <ul
              style={{
                maxHeight: "90vh",
                height: "400px",
                listStyle: "none",
                paddingLeft: "15px",
                paddingRight: '15px',
                overflowY: "auto",
                width: "300px",
              }}
            >
              {filteredOptions.map((option) => {
                return (
                  <li
                    style={{
                      cursor: "pointer",
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    key={option}
                    onClick={() => {
                      handleCountryClick(option);
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={option ? option.value : ""}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      cdnSuffix="svg"
                      title={option ? option.value : ""}
                    />
                    {option.label} {", "} {option.value}
                  </li>
                );
              })}
            </ul>
          </>
        </Menu>
      )}
    </div>
  );
};
export default NavigationDrawer;
