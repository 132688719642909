import React, { useEffect, useState } from "react";
import ReactStars from "react-stars";
import { get, map, isEmpty } from "lodash";
import { Card } from "../../components/Card";
import useStyles from "./styles";
import { LocalOffer } from "@mui/icons-material";
import { Loader } from "../../components/Loader";
import noDataFound from "../../../assets/images/HNA.svg";
import datanotfound from "../../../assets/images/datanotfound.svg";
import hotelNotFound from "../../../assets/images/NO_HOTEL_FOUND.png";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { formatSARCurrency } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const HotelListingView = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const filters = useSelector((state) => state.globalFilters);
  const state = get(props, "state", {});
  const handlers = get(props, "handlers", {});
  const setShowFilters = get(props, "setShowFilters", function () { });
  const showFilters = get(props, "showFilters", true);
  const hotels = get(state, "networkState.filteredData", []);
  const noOfRooms = useSelector((state) => state.hotelRooms.rooms.length)
  const traceId = get(state, "networkState.data.TraceId", "");
  const isLoading = get(state, "networkState.isLoading", false);
  const searchId = get(state, "networkState.searchId", null);
  const [markupPrice, setMarkupPrice] = useState(0);
  const isMobile = useIsMobile();
  const [noOfNights, setNoOfNights] = useState(0);

  useEffect(() => {
    if (filters?.hotelFilters) {
      const { hotelFilters } = filters;
      if (hotelFilters?.hotelCheckInDate && hotelFilters?.hotelCheckOutDate) {
        const checkInDateRaw = moment(
          hotelFilters.hotelCheckInDate,
          "DD/MM/YYYY"
        );
        const checkOutDateRaw = moment(
          hotelFilters.hotelCheckOutDate,
          "DD/MM/YYYY"
        );
        const nights = checkOutDateRaw.diff(checkInDateRaw, "days");
        setNoOfNights(nights);
      }
    }
  }, [filters]);



  const getMarkup = (markupDetails, publishPrice, tax) => {
    let value = 0;

    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
      amt = Number(parseFloat(amt).toFixed(2))
      amt = Number(parseFloat(noOfRooms * amt).toFixed(2));
    } else {
      if (appliedOn === "BASEFARE") {
        amt = (amount * (publishPrice - tax)) / 100;
      } else {
        amt = (amount * publishPrice) / 100;
      }
      amt = maxAmount < amt ? maxAmount : amt;
    }

    return parseFloat(amt).toFixed(2);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!isEmpty(hotels) ? (
            <div className={styles.hotelListing}>
              {map(hotels, (_hotel) => {
                let starRating = get(_hotel, "HotelRating", 0);
                if (starRating == "FiveStar") {
                  starRating = 5;
                } else if (starRating == "FourStar") {
                  starRating = 4;
                } else if (starRating == "ThreeStar") {
                  starRating = 3;
                } else if (starRating == "TwoStar") {
                  starRating = 2;
                } else if (starRating == "OneStar") {
                  starRating = 1;
                }
                let address = get(_hotel, "Address", "");
                const priceDetails = get(_hotel, "Price", {});
                const hotelCode = get(_hotel, "HotelCode", "");
                // const resultIndex = get(_hotel, "ResultIndex", 0);
                const hotelName = get(_hotel, "HotelName", "");
                const markup = get(_hotel, "markup", "");
                const markup_id = get(markup, "_id", "");
          
                const Rooms = get(_hotel, "Rooms", []);
                let leastTotalFare;
                let leastTax;
                Rooms.map((room) => {
                  const totalFare = room?.TotalFare;
                  const totalTax = room?.TotalTax;
                  if (
                    leastTotalFare &&
                    totalFare &&
                    totalFare < leastTotalFare
                  ) {
                    leastTotalFare = totalFare;
                    leastTax = totalTax;
                  } else if (!leastTotalFare && totalFare) {
                    leastTotalFare = totalFare;
                    leastTax = totalTax;
                  }
                })
                const publishPrice = parseFloat(leastTotalFare).toFixed(2);
                const taxes = parseFloat(leastTax).toFixed(2);
                if (address.length > 105) {
                  address = address.slice(0, 105);
                  address += "...";
                }
                _hotel.leastTotalFare = leastTotalFare;
                _hotel.starRating = starRating;
                return (
                  <div
                    className={styles.hotelCardWrapper}
                    onClick={() => {
                      handlers.handleCardClick({
                        hotelCode,
                        _hotel,
                        searchId,
                        markup_id
                      });
                      localStorage.setItem("markup_id", markup_id);
                    }}
                  >
                    <Card
                      key={_hotel?.HotelName}
                      image={get(_hotel, "HotelPicture", "")}
                    >
                      <div className={styles.hotelCardBody}>
                        <div className={styles.hotelCardNameSection}>
                          <ReactStars
                            count={5}
                            value={starRating}
                            size={12}
                            edit={false}
                            color2={"#FFA400"}
                          />
                          <p className={styles.hotelCardNameSectionLabel}>
                            {get(_hotel, "HotelName", "")}
                          </p>
                          <div
                            className={styles.hotelCardNameSectionRatingCount}
                            style={{ height: "35px" }}
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "flex-start",
                              }}
                            >
                              {" "}
                              {`${starRating}`}{" "}
                              <span>
                                <ReactStars
                                  count={1}
                                  size={12}
                                  edit={false}
                                  color2={"#667085"}
                                />{" "}
                              </span>
                            </span>

                            <span className="hotelAddress">
                              {" "}
                              {`Hotel in ${address}`}
                            </span>
                          </div>
                        </div>
                        <div className={styles.hotelCardPriceSection}>
                          <div className={styles.hotelCardPriceSectionOfferTag}>
                            <LocalOffer
                              style={{ fontSize: "16px" }}
                              className={
                                styles.hotelCardPriceSectionOfferTagIcon
                              }
                            />
                            <p
                              className={
                                styles.hotelCardPriceSectionOfferTagText
                              }
                            >
                              {t("hotelSearchResult.offersAvailable")}
                            </p>
                          </div>
                          <div className={styles.hotelCardPriceSectionPrice}>
                            <p
                              className={
                                styles.hotelCardPriceSectionPriceNumber
                              }
                            >
                              SAR{" "}
                              {formatSARCurrency(
                                parseFloat(
                                  (Number(publishPrice) +
                                    Number((markup
                                      ? getMarkup(markup, publishPrice, taxes)
                                      : 0)) -
                                    Number(taxes)) /
                                  noOfNights
                                ).toFixed(2)
                              )}
                            </p>
                            <p
                              className={styles.hotelCardPriceSectionPriceTaxes}
                            >
                              {`+ ${formatSARCurrency(
                                parseFloat(taxes / noOfNights).toFixed(2)
                              )} ${t(
                                "hotelSearchResult.taxesAndFeesPerNight"
                              )}`}
                            </p>
                          </div>
                        </div>
                        {isMobile && (
                          <div>
                            <button
                              style={{
                                width: "100%",
                                background: "#1B1D52",
                                color: "white",
                                height: "41px",
                                padding: "12px, 32px, 12px, 32px",
                                borderRadius: "8px",
                                border: "none",
                                fontSize: "14px",
                                fontFamily: "lato",
                              }}
                            >
                              {t("hotelSearchResult.viewDetails")}
                            </button>
                          </div>
                        )}
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={styles.hotelListingDataNotFound}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: isMobile ? "80vh" : "50vh",
              }}
            >
              {(() => {
                const initialApiData = state?.networkState?.initialApiData;
                if (!initialApiData) {
                  setShowFilters(false);
                }
              })()}
              <img src={datanotfound} className={styles.errorImage} />
              <p style={{ fontFamily: "lato", fontWeight: "bold" }}>
                {t("hotelSearchResult.hotelsNotFound")}
              </p>
              <p>{t("hotelSearchResult.hotelsNotFoundMessage")}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};
