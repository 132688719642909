import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  frontCard: {
    width: "100%",
    height: "400px",
  },
  frontCardImage: {
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;
