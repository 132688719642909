import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    container: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "24px 16px",
        backgroundColor: "#fff",
        // marginTop: "13%",
        // marginLeft: "35%",
        width: "32%",
        alignItems: "center",
        borderRadius: "10px",
        outline: "0",
        [theme.breakpoints.down('md')]:{
            width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            width: "80%",
            // marginTop: "13%",
            // marginLeft: "6%",
        }
    }
}));

export default useStyles;
