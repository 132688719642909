import React, { useEffect, useState } from 'react'
import verifyEmail from '../../modules/auth'
import { useParams } from 'react-router-dom'
import DoneIcon from '@mui/icons-material/Done';
import { toast } from 'react-toastify';
import { Rings } from 'react-loader-spinner';
import tickGif from '../../assets/images/insurance-tick.gif'
import { Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AlertSvg from '../../../src/assets/images/alertSvg.svg'
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile.hook';

export default function VerifyEmail() {
    const { "*": params } = useParams();
    const { t } = useTranslation()
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // console.log(useParams())
    // console.log('params', params)
    async function validateEmail() {
        setLoading(true)
        const response = await verifyEmail(params)
        const message = response.message
        const statusCode = response.statusCode
        setLoading(false)

        if (statusCode == 200) {
            toast.success(t("successMessages.signupSuccessfully"))
            setTimeout(() => { navigate("/") }, 2000)
        } else {
            toast.error(message)
            setErrorMessage(message)
        }
        console.log('response', response)
    }
    useEffect(() => {
        validateEmail()
    }, [])
    const isMobile = useIsMobile()
    return <div
        style={{
            display: 'flex',
            justifyContent: 'center'

        }}>
        {!loading && errorMessage && <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            height: '100px',
            width: '400px',
            maxWidth: '90vw',
            borderRadius: '10px',
            margin: '50px',
            padding: '15px',
            alignItems: 'center',
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px"
        }}>

            <img
                src={AlertSvg}
                style={{ height: '30px', width: '30px' }}
                onError={(e) => {
                    console.log('error', e)
                }}
            />

            {errorMessage && errorMessage}
        </div>
        }
        {!loading && !errorMessage && <>
            <Modal
                open={true}
                onClose={() => setShowSuccessModal(false)}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        padding: "16px 24px",
                        backgroundColor: "#fff",
                        marginTop: "18%",
                        marginLeft: isMobile ? '15%' : '34%',
                        marginRight:
                            localStorage.getItem("language") == "AR"
                                ? isMobile
                                    ? '15%'
                                    : '35%'
                                : '0%',
                        width: "32%",
                        alignItems: "center",
                        borderRadius: "10px",
                    }}
                >
                    <img src={tickGif} />
                    <p
                        style={{
                            color: "#344054",
                            fontFamily: "Lato, sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            letterSpacing: "2px",
                        }}
                    >
                        {/* {"Email Verified"} */}
                        {t("registration.emailVerified")}
                    </p>

                </div>
            </Modal>
        </>}

        {loading && <Rings height={80} width={80} color="#1B1D52" />}
    </div >
}