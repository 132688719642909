import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import CouponCode from "./coupons-details";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import TrashSvg from "../../../../assets/images/trash_icon_red.svg";
import CouponSvg from "../../../../assets/images/gift_icon_blue.svg";
import ArrowSvg from "../../../../assets/images/Line arrow-right-blue.svg";
import APIs from "../../../../api";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";

const customStyles = {
  borderRadius: "10px",
  padding: "8px",
};

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
}));

const AddCoupenGiftCard = ({
  hotelDetails,
  setCouponValue,
  couponValue,
  // combinationRoomsPrices,
  traceId,
  setCouponId,
  isCouponApplied,
  setIsCouponApplied,
  couponDetails,
  setCouponDetails,
  successMessage,
  setSuccessMessage,
  setSessionExpired,
  roomsDetails,
}) => {
  const styles = useStyles();
  const [couponData, setCouponData] = useState({});

  let BASEFARE = roomsDetails.TotalBaseFare;
  let TOTALFARE = roomsDetails.TotalFareRoundOff;
  // for (let i = 0; i < combinationRoomsPrices.length; i++) {
  //   const room = combinationRoomsPrices[i];
  //   BASEFARE += room.baseFare;
  //   TOTALFARE += room.roomPrice;
  // }

  const globalFilters = useSelector((state) => state.globalFilters);
  const hotelName = hotelDetails?.HotelName;
  const rating = hotelDetails?.StarRating;
  const hotelFilters = globalFilters?.hotelFilters;
  const hotelCheckInDate = hotelFilters?.hotelCheckInDate;
  const hotelCheckOutDate = hotelFilters?.hotelCheckOutDate;
  const parts = hotelCheckInDate?.split("/");
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];
  const travelDate = `${day}/${month}/${year}`;

  const partsOut = hotelCheckOutDate?.split("/");
  const dayOut = partsOut[0];
  const monthOut = partsOut[1];
  const yearOut = partsOut[2];
  const travelDateOut = `${dayOut}/${monthOut}/${yearOut}`;

  const destination = hotelFilters?.hotelCity?.cityId;
  let paxCount = 0;
  const roomCount = hotelFilters?.roomGuests?.length;
  hotelFilters.roomGuests.forEach((room) => {
    paxCount += room.adultCounter;
    paxCount += room.childrenCounter;
  });

  const paxType = [];
  hotelFilters.roomGuests.forEach((room) => {
    if (!paxType.includes("ADULT")) {
      if (room.adultCounter >= 1) {
        paxType.push("ADULT");
      }
    }
    if (!paxType.includes("ADULT")) {
      if (room.adultCounter >= 1) {
        paxType.push("ADULT");
      }
    }
    if (room.childrenCounter >= 1) {
      paxType.push("CHILDREN");
    }
  });

  const handleCouponValue = (e) => {
    setCouponDetails(e.target.value);
  };

  const handleClearCouponValue = () => {
    setCouponDetails("");
    setCouponValue({});
    setIsCouponApplied(false);
  };

  function getBookingDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  function getNightsCount() {
    const travelDateParts = travelDate.split("/");
    const bookingDateParts = travelDateOut.split("/");
    const bookingDate2 = new Date(
      travelDateParts[2],
      travelDateParts[1] - 1,
      travelDateParts[0]
    );
    const travelDate2 = new Date(
      bookingDateParts[2],
      bookingDateParts[1] - 1,
      bookingDateParts[0]
    );
    const timeDifference = travelDate2.getTime() - bookingDate2.getTime();
    const nightsCount = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return nightsCount;
  }
  const user = useSelector((state) => state.user);

  function calculateCouponAmount(coupon) {
    const { couponCode, amountType, amount, applyTo, maxAmount, _id } = coupon;
    const couponValue = {};
    if (amountType == "FLAT") {
      couponValue.couponCode = couponCode;
      couponValue.amount = amount;
    } else {
      if (applyTo == "BASEFARE") {
        const couponAmount = (amount / 100) * BASEFARE;
        couponValue.couponCode = couponCode;
        couponValue.amount = couponAmount;
      } else {
        const couponAmount = (amount / 100) * TOTALFARE;
        couponValue.couponCode = couponCode;
        couponValue.amount = couponAmount;
      }
    }
    if (couponValue.amount > maxAmount) {
      couponValue.amount = maxAmount;
    }
    setCouponValue(couponValue);
    // setCouponId(_id);
  }
  function couponValidation(couponCode) {
    const body = {
      // traceId: traceId,
      coupon: couponCode,
      hotel: couponData.payload,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${user?.token}`,
      },
    };
    const apiUrl = user.token
      ? APIs.validateAuthCoupon
      : APIs.validateNoAuthCoupon;

    axios
      .post(apiUrl, body, config)
      .then((response) => {
        const valid = response?.data?.data?.valid;
        const coupon = response?.data?.data?.coupon;
        if (valid == true) {
          calculateCouponAmount(coupon);
        } else {
          toast.error(t("errorMessages.enterValidCoupon"));
        }
        setIsCouponApplied(valid);
        const successMessage = response?.data?.data?.coupon?.successMessage;
        setSuccessMessage(successMessage);
        toast.success(t("successMessages.couponAppliedSuccessfully"));
      })
      .catch((error) => {
        toast.error(t("errorMessages.enterValidCoupon"));
      });
  }
  useEffect(() => {
    const token = user?.token
    const url = user.token ? APIs.getHotelCouponAuth :  APIs.getHotelCouponNoAuth
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        destination: [destination],
        travelDate: travelDate,
        bookingDate: getBookingDate(),
        paxType,
        paxCount: paxCount,
        txnAmount: TOTALFARE,
        hotelName,
        nightsCount: getNightsCount(),
        roomsCount: roomCount,
        rating: rating,
        day: new Date().getDay(),
        baseFare: BASEFARE,
        totalFares: TOTALFARE,
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const couponData = data.data;
        if (couponData?.lowestAmountCoupon.length > 0) {
          setCouponId(couponData.lowestAmountCoupon[0]?._id);
        }
        setCouponData(couponData);
        localStorage.setItem("couponData", JSON.stringify(couponData));

        if(isCouponApplied && couponData.lowestAmountCoupon.length == 0){
          handleClearCouponValue()
        }
      })
      .catch((err) => {
        console.log("error in coupon api ->", err);
      });
  }, [TOTALFARE, BASEFARE, user]);

  const coupon = couponData?.lowestAmountCoupon
    ? couponData?.lowestAmountCoupon[0]
    : "";
  const visibility = coupon?.visibility;
  const originalCouponCode = coupon?.couponCode;
  const _id = coupon?._id;
  const ruleName = coupon?.ruleName;

  const {t} = useTranslation()
  return (
    <>
      <div
        style={{
          borderRadius: "9px 9px 9px 9px",
          border: "1px solid #D0D5DD",
        }}
      >
        <div
          style={{
            borderRadius: "9px 9px 0px 0px",
            background: "#E6E6FA",
            display: "inline-flex",
            gap: "8px",
            padding: "24px 16px",
            width: "100%",
          }}
        >
          <ReactSVG src={CouponSvg} />
          <p
            style={{
              color: "#344054",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            {/* Apply Coupon Or Gift Card */}
            {t("coupon.applyCouponOrGiftCard")}
          </p>
        </div>
        <div style={{ padding: "24px 16px" }}>
          <TextField
            sx={{
              border: "1px #D0D5DD",
              fontWeight: "bolder",
              minHeight: "0px !important",
            }}
            size="small"
            className={styles.root}
            fullWidth
            value={couponDetails}
            onChange={handleCouponValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // console.log('Enter key pressed!');
                if (couponDetails) {
                  couponValidation(couponDetails);
                } else {
                  toast.error(t("errorMessages.enterValidCoupon"));
                }
              }
            }}
            disabled={isCouponApplied ? true : false}
            InputProps={{
              style: customStyles,
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    padding: "4px 16px 4px 0px",
                    cursor: "pointer",
                    ":disabled": "true",
                  }}
                >
                  {isCouponApplied && (
                    <ReactSVG src={TrashSvg} onClick={handleClearCouponValue} />
                  )}
                  {!isCouponApplied && (
                    <p
                      style={{
                        color: "#4037acd6",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-end",
                        // marginTop: '15px'
                      }}
                      onClick={() => {
                        if (couponDetails) {
                          couponValidation(couponDetails);
                        } else {
                          toast.error(t("errorMessages.enterValidCoupon"));
                        }
                      }}
                    >
                      {/* Apply */}
                      {t("coupon.apply")}

                    </p>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {isCouponApplied && (
            <p
              style={{
                marginTop: "14px",
                color: "#1DAC08",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                cursor: "pointer",
              }}
            >
              {successMessage}
            </p>
          )}
          {couponData &&
            // && visibility == "PUBLIC"
            couponData?.lowestAmountCoupon?.length > 0 &&
            !isCouponApplied && (
              <CouponCode
                couponData={couponData}
                coupon={couponDetails}
                setCoupon={setCouponDetails}
                calculateCouponAmount={calculateCouponAmount}
                hotelDetails={hotelDetails}
                setIsCouponApplied={setIsCouponApplied}
                setSuccessMessage={setSuccessMessage}
                traceId={traceId}
                totalFare={TOTALFARE}
                roomsDetails={roomsDetails}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default AddCoupenGiftCard;
