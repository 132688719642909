import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import CheckSvg from "../../../../../../../assets/images/baggage-check.svg";
import useStyles from "./styles";
import eventBus from "../../../../../../../core/eventBus";
import { useSelector } from "react-redux";

const CheckPrice = ({ flights, index, handleBookNow, selected }) => {
  const classes = useStyles();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  if (flights?.length <= 1) return;
  return (
    <div className={classes.mainContainer}>
      {flights &&
        flights.map((flight, i) => {
          let Color;
          let Type;

          if (!flight?.FareClassification) {
            Color = "lightblue";
            Type = "General";
          } else {
            const { FareClassification } = flight;
            Color = FareClassification?.Color;
            Type = FareClassification?.Type;
          }

          const {
            Fare: { TotalFare, Currency, BaseFare, OtherCharges },
            Segments,
          } = flight;

          const { Baggage, CabinBaggage } = Segments[index][0];

          const [markup, setMarkup] = useState(0);

          useEffect(() => {
            getMarkup(flight?.markup);
          }, [BaseFare, TotalFare]);

          const getMarkup = (markupDetails) => {
            if (!markupDetails || Object.keys(markupDetails).length === 0) {
              return;
            }
            let amt = 0;
            const { amount, amountType, appliedOn, maxAmount } = markupDetails;
            if (amountType === "FLAT") {
              amt = amount < maxAmount ? amount : maxAmount;
            } else {
              amt = amount < maxAmount ? amount : maxAmount;
              if (appliedOn === "BASEFARE") {
                amt = (amt * (BaseFare + OtherCharges)) / 100;
              } else {
                amt = (amt * TotalFare) / 100;
              }
            }
            amt = maxAmount < amt ? maxAmount : amt;
            setMarkup(Math.ceil(amt));
          };

          return (
            <div
              className={classes.baggagesAndSelectTextContainer}
              key={i}
              style={{
                borderTop: `2px solid ${Color}`,
              }}
            >
              <div className={classes.typeAndCurrencyContainer}>
                <p className={classes.type}>{Type}</p>
                <p className={classes.currency}>
                  {Currency === "INR" && "₹"}
                  {(
                    Math.ceil(TotalFare * rateOfExchange) + markup
                  ).toLocaleString("en-IN")}
                </p>
              </div>
              <Divider sx={{ borderStyle: "dashed" }} />
              <div className={classes.baggagesContainer}>
                <p className={classes.baggageAllowanceText}>
                  Baggage allowance
                </p>
                <div className={classes.mainBaggageContainer}>
                  <div className={classes.checkContainer}>
                    <ReactSVG src={CheckSvg} />
                  </div>
                  <p className={classes.baggageAllowed}>
                    {Baggage} Baggage Allowed
                  </p>
                </div>
                {CabinBaggage && (
                  <div className={classes.cabinBaggageContainer}>
                    <div className={classes.checkContainer2}>
                      <ReactSVG src={CheckSvg} />
                    </div>
                    <p className={classes.cabinBaggageAllowed}>
                      {CabinBaggage} Cabin Baggage Allowed
                    </p>
                  </div>
                )}
              </div>
              <div
                className={classes.selectText}
                style={{
                  backgroundColor: `${Color}`,
                }}
                onClick={() => {
                  handleBookNow(selected, i);
                  eventBus.emit("close-bottomSheet");
                }}
              >
                Select
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CheckPrice;

CheckPrice.propTypes = {
  flights: PropTypes.array,
  index: PropTypes.any,
  handleBookNow: PropTypes.any,
};
