import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import HotelSvg from "../../../../../../../assets/images/hotel-package.svg";
import TransferSvg from "../../../../../../../assets/images/truck-package.svg";
import FlightSvg from "../../../../../../../assets/images/airplane-package.svg";
import ActivitySvg from "../../../../../../../assets/images/activity-package.svg";
import useStyles from "./styles";
import useIsMobile from "../../../../../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const PackagesCard = ({ packages }) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const handlePackageDetail = (id) => {
    navigate(`/package/${id}`);
  };

  return (
    <Box className={styles.packageContainer}>
      {packages.map((item) => {
        const {
          _id,
          personPrice,
          flights,
          hotels,
          activities,
          transfers,
          benefits,
          itinerary,
          name,
          days,
          nights,
          packageImage,
          discountedPrice,
        } = item;
        return (
          <Box
            key={_id}
            className={styles.packageCard}
            onClick={() => handlePackageDetail(_id)}
          >
            <Box
              sx={{
                borderRadius: "10px",
                height: "200px",
                backgroundImage: `url(${S3Bucket}/${packageImage})`,
                backgroundRepeat: "no-repeat",
                width: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></Box>
            <Box
              sx={{
                borderRadius: "18px",
                backgroundColor: "#1b1d52",
                padding: "6px 8px",
                position: "absolute",
                marginTop: "185px",
                alignSelf: "end",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  wordSpacing: "2px",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  width: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {days}{t("clockTimingsCodes.D")}/{nights}{t("clockTimingsCodes.D")}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                position: "relative",
                height: "36px",
                display: "-webkit-Box",
                "-webkit-line-clamp": 2,
                "-webkit-box-orient": "vertical",
              }}
            >
              {name}
            </Typography>
            <Box className={styles.packageBenefits}>
              {flights ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <ReactSVG src={FlightSvg} />
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {flights} {t("packages.flights")}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" />
                </>
              ) : (
                <></>
              )}
              {hotels ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <ReactSVG src={HotelSvg} />
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {hotels} {t("packages.hotels")}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" />
                </>
              ) : (
                <></>
              )}
              {activities ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <ReactSVG src={ActivitySvg} />
                    <Typography
                      sx={{
                        color: "#344054",
                        fontFamily: "Lato, sans-serif",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {activities} {t("packages.activities")}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" />
                </>
              ) : (
                <></>
              )}
              {transfers ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  <ReactSVG src={TransferSvg} />
                  <Typography
                    sx={{
                      color: "#344054",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    {transfers} {t("packages.transfers")}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ display: "inline-flex", gap: "8px", width: "100%" }}>
              {itinerary &&
                itinerary.slice(0, 3).map((iti, id) => {
                  const { title } = iti;
                  return (
                    <Box
                      sx={{ p: "0.37rem", backgroundColor: "#E6EAEE" }}
                      key={id}
                    >
                      {title.length <= 15 ? (
                        <Typography
                          fontSize="0.75rem"
                          fontWeight={400}
                          color="#344054"
                        >
                          {title}
                        </Typography>
                      ) : (
                        <Typography
                          fontSize="0.75rem"
                          fontWeight={400}
                          color="#344054"
                        >
                          {title.substr(0, 15)}..
                        </Typography>
                      )}
                    </Box>
                  );
                })}
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "space-between",
                height: "60px",
              }}
            >
              <div className={styles.benefitsContainer}>
                {benefits &&
                  benefits.slice(0, 2).map((benefit, i) => {
                    return (
                      <div className={styles.packageDetailsContainer} key={i}>
                        <div>&#x2022;</div>
                        <div className={styles.packageDetails}>{benefit}</div>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.priceContainer}>
                <div className={styles.personPrice}>
                  <div className={styles.priceDetails}>
                    <div className={styles.packageDetailDiscountedPrice}>
                      {personPrice.toLocaleString("en-In")}
                    </div>
                    <div style={{ fontSize: "16px", display: "flex" }}>
                      {t("flightSearch.currencySymbol")}
                      {discountedPrice.toLocaleString("en-In")}
                    </div>
                  </div>
                </div>
                <div className={styles.perPersonText}>
                  {t("packages.perPerson")}
                </div>
              </div>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PackagesCard;

PackagesCard.propTypes = {
  packages: PropTypes.array,
};
