import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  profileMainContainer: {
    borderRadius: "16px",
    backgroundColor: "#FFF",
    padding: "24px 16px 30px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  profileHeaderContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  loginDetailsText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  manageLoginDetailsText: {
    color: "#525A6A",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  editBoxOuterContainer: {
    borderRadius: "10px",
    background: "#FFF",
    border: "1px solid #1b1d52",
    padding: "10px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "65px",
      height: "40px",
      marginBottom: "6px",
    },
  },
  editTextInnerContainer: {
    display: "inline-flex",
    gap: "8px",
  },
  editText: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  profileDetailsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  firstNameContainer: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "12px 16px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  firstNameLabel: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  firstName: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  lastNameContainer: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "12px 16px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  lastNameLable: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  lastName: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mobileNumberContainer: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "12px 16px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  mobileNumberLable: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  mobileNumber: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  emailIdContainer: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "12px 16px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  emailIdLable: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  emailId: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  mobileNumberAndIcon: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  emailIdAndIcon: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  mobileLoginDetailsTextAndEditProfileBtnContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
export default useStyles;
