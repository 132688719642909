import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    width: "100%",
    padding: "40px 150px",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px",
      maxWidth: "100vw",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px 10px",
      maxWidth: "100vw",
    },
  },
  segmentContainer: {
    display: "flex",
    padding: "32px 16px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D0D5DD",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
  },
  airlineContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  segmentFlightInfoContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  flightInfoFromContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    flex: 0.35,
  },
  flightInfoDurationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    flex: 0.3,
  },
  flightInfoToContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    flex: 0.35,
  },
  confirmationImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    height: "230px",

    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
  },
  confirmationIcon: {
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      width: "35px",
    },
  },
  confirmationText: {
    fontFamily: "Lato, sans-serif",
    fontSize: "36px",
    fontWeight: 600,
    color: "#fff",

    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      fontWeight: 500,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  tripInfoContainer: {
    display: "inline-flex",
    padding: "16px 0px",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
  },
  flightInfoContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  fligtInfo: {
    display: "inline-flex",
    gap: "4px",
  },
  flightInfoContent1: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "85%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  city: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  journeyIcon: {
    marginTop: "6px",
  },
  subInfo: {
    color: " #344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  statusButton: {
    borderRadius: "20px",
    background: "#F1FCEB",
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusText: {
    color: "#1DAC08",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  pnrHeading: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  pnrText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
  },
  bookingConfirmationBottom: {
    height: "62px",
  },
  tableContainer: {
    overflowX: "auto",
  },
  refundableStatus: {
    display: "inline-flex",
    gap: "8px",
    cursor: "pointer",
  },
  tooltipContainer: {
    background: "#3a485f",
    borderRadius: "4px",
    // width: "450px",
    color: "#FFF",
    fontSize: "12px",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.24)",
    padding: "24px 32px",
    overflowY: "scroll",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      // width: "350px",
    },
  },
}));

export default useStyles;
