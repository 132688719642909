import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import UserSvg from "../../../../../../assets/images/enquiry-user.svg";
import MailSvg from "../../../../../../assets/images/enquiry-mail.svg";
import MobileSvg from "../../../../../../assets/images/enquiry-mobile.svg";
import Tickgif from "../../../../../../assets/images/insurance-tick.gif";
import { formatDateToYyyyMmDd } from "../../../../../../utils/helpers";
import { enquireAboutPackage } from "../../../../../../modules/package";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import handleUnauthorisedLogout from "../../../../../../modules/unauthorised";
import { setUserInfo } from "../../../../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Calendar } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import NameInputField from "./components/name";
import EmailInputField from "./components/email";
import PhoneNumberInputField from "./components/phone";
import CityInputField from "./components/city";
import NumberOfPassengersInputField from "./components/passengers";
import TravelDateInputField from "./components/traveldate";

const useStylesMaterial = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
      borderRadius: "12px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
        borderRadius: "12px",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
        borderRadius: "12px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
        borderRadius: "12px",
      },
    },
  },
}));

const customStyles = {
  borderRadius: "12px",
  padding: "8px 2px",
  background: "#FFF",
  // boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#525A6A",
  fontFamily: "Lato, sans-serif",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
};

const countryInputStyleMobile = {
  borderRadius: "12px",
  padding: "8px 2px",
  background: "#FFF",
  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#525A6A",
  fontFamily: "Lato, sans-serif",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
};

const Enquiryform = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStylesMaterial();
  const [showTravelDate, setShowTravelDate] = useState();
  const styles = useStyles();
  const { id } = useParams();
  const initialState = {
    name: "",
    email: "",
    phoneNo: "",
    countryCode: "+966",
    noOfPax: "",
    departureCity: "",
    departureDate: null,
  };

  const validStatus = [true, true, true, true, true, true];

  const errorValues = [
    "Enter valid name",
    "Enter valid email",
    "Enter valid phone number",
    "Enter valid city",
    "Enter valid travellers",
    "Enter valid travel date",
  ];

  const [values, setValues] = useState(initialState);
  const [valid, setValid] = useState(validStatus);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const isMobile = useIsMobile();
  const maxDate = dayjs().add(2, "year").toDate();
  const handleSubmitEnquiryform = async () => {
    if (values.departureDate && dayjs(values.departureDate).year() > 2099) {
      toast.error(t("errorMessages.pleaseSelectValidDate"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Stop further execution
    }
    let invalid = false;
    valid &&
      valid.map((value, index) => {
        if (value === false) {
          invalid = true;
          switch (index) {
            case 0:
              toast.error(t("errorMessages.enterValidName"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 1:
              toast.error(t("errorMessages.enterValidEmail"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 2:
              toast.error(t("errorMessages.enterValidPhone"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 3:
              toast.error(t("errorMessages.enterValidCity"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 4:
              toast.error(t("errorMessages.enterValidNumberOfPassengers"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 5:
              toast.error(t("errorMessages.enterValidTravelDate"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            default:
              console.log("index", index);
          }
        }
      });

    if (invalid === true) return;

    const nameReg = new RegExp("^[a-zA-Z\u0600-\u06FF ]{0,19}$");
    const emailReg = new RegExp(
      /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/
    );
    const phoneReg = /^[0-9-()+ ]+$/;
    const cityReg = new RegExp("^[a-zA-Z\u0600-\u06FF ]{0,19}$");
    const passengerReg = new RegExp("^[1-9][0-9]{0,3}$");

    // reg.test(e.target.value);
    if (values.name == "" || !nameReg.test(values.name)) {
      invalid = true;
      let validStatuses = valid;
      validStatuses[0] =
        nameReg.test(values.name) || values.name == "" ? false : true;
      setValid([...validStatuses]);
      toast.error(t("errorMessages.enterValidName"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (values.email == "" || !emailReg.test(values.email)) {
      invalid = true;
      let validStatuses = valid;
      validStatuses[1] = emailReg.test(values.email);
      setValid([...validStatuses]);
      toast.error(t("errorMessages.enterValidEmail"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (values.phoneNo == "" || !phoneReg.test(values.phoneNo)) {
      invalid = true;
      let validStatuses = valid;
      validStatuses[2] = phoneReg.test(values.phoneNo);
      setValid([...validStatuses]);
      toast.error(t("errorMessages.enterValidPhone"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (values.departureCity == "" || !cityReg.test(values.departureCity)) {
      invalid = true;
      let validStatuses = valid;
      validStatuses[3] =
        cityReg.test(values.departureCity) || values.departureCity == ""
          ? false
          : true;
      setValid([...validStatuses]);
      toast.error(t("errorMessages.enterValidCity"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (values.countryCode === "") {
      invalid = true;
      toast.error(t("errorMessages.enterValidCountoryCode"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (
      values.noOfPax == 0 ||
      values.noOfPax == "" ||
      !passengerReg.test(values.noOfPax)
    ) {
      invalid = true;
      let validStatuses = valid;
      validStatuses[4] =
        passengerReg.test(values.noOfPax) || values.noOfPax == ""
          ? false
          : true;
      setValid([...validStatuses]);
      toast.error(t("errorMessages.enterValidNumberOfPassengers"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (values.departureDate == null) {
      invalid = true;
      let validStatuses = valid;
      validStatuses[5] = false;
      setValid([...validStatuses]);
      toast.error(t("errorMessages.enterValidTravelDate"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (invalid === true) return;
    const data = {
      ...values,
      package: id,
    };
    const statusCode = await enquireAboutPackage(data);
    const errorStatusCode = statusCode?.statusCode;
    const message = statusCode?.message;
    if (errorStatusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    if (statusCode === 200) {
      setValues({ ...initialState });
      setShowSuccessModal(true);
    } else {
      toast.error(t("errorMessages.enterValidValues"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showSuccessModal]);

  const countryCodes = require("country-codes-list");
  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
  );
  const countryInfoList = {};

  for (const countryCode in myCountryCodesObject) {
    const countryInfo = myCountryCodesObject[countryCode];
    const matches = countryInfo.match(/\[(\w+)\] (.+): \+(\d+)/);

    if (matches && matches.length === 4) {
      const countryCode = matches[1];
      const countryName = matches[2];
      const callingCode = `+${matches[3]}`;
      countryInfoList[countryCode] = {
        countryName: countryName,
        callingCode: callingCode,
      };
    }
  }

  const { t } = useTranslation();

  useEffect(() => {
    const elements = document.querySelectorAll(".rdrNextPrevButton");
    if (localStorage.getItem("language") === "AR") {
      elements?.forEach((element) => {
        if (element && element.style) {
          element.style.transform = "rotate(180deg)";
          (element.style.display = "flex"),
            (element.style.justifyContent = "center"),
            (element.style.alignItems = "center");
        }
      });
    } else {
      elements?.forEach((element) => {
        if (element && element.style) {
          (element.style.display = "flex"),
            (element.style.justifyContent = "center"),
            (element.style.alignItems = "center");
        }
      });
    }
    const calendarWrapper = document.querySelector(".rdrCalendarWrapper");
    if (calendarWrapper && calendarWrapper.style) {
      calendarWrapper.style.width = "100%";
    }
  });

  return (
    <div
      className="mainContainer"
      style={{
        borderRadius: "10px",
        background: "#FFF",
        padding: "24px 16px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <p
          className="planYourPackageText"
          style={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          {/* Plan your package! */}
          {t("packages.planYourPackage")}
        </p>
        <p
          className="packageDescription"
          style={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          {/* For a truly unique experience, contact one of our travel advisors via
          WhatsApp or enter your details and we will contact you to help
          customise your package! */}
          {t("packages.uniqueExperienceText")}
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <p
          style={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
          }}
        >
          {/* Contact Details */}
          {t("packages.contactDetails")}
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div
            className="nameAndInputContainer"
            style={{ display: "flex", flexDirection: "column", gap: "6px" }}
          >
            <p
              className="NameText"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {t("packages.name")}
            </p>
            {/* <TextField
              className={classes.root + " " + "NameInputField"}
              size="small"
              // placeholder="Enter Name"
              placeholder={t("packages.enterName")}
              sx={{
                minHeight: "0px !important",
                alignItems: "normal !important",
                width: "100%",
              }}
              value={values.name}
              onChange={(e) => {
                const reg = new RegExp("^[a-zA-Z\u0600-\u06FF ]{0,19}$");
                let validStatuses = valid;
                validStatuses[0] = reg.test(e.target.value);
                setValid([...validStatuses]);
                setValues({ ...values, name: e.target.value });
              }}
              InputProps={{
                style: customStyles,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginTop: "6px", padding: "0px 4px 0px 12px" }}
                  >
                    <ReactSVG src={UserSvg} />
                  </InputAdornment>
                ),
              }}
              error={!valid[0]}
            /> */}
            <NameInputField
              classes={classes}
              values={values}
              setValues={setValues}
              valid={valid}
              setValid={setValid}
              customStyles={customStyles}
              UserSvg={UserSvg}
            />
            {valid[0] === false && (
              <p
                className="ErrorText"
                style={{
                  color: "red",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {/* {errorValues[0]} */}
                {t("errorMessages.enterValidName")}
              </p>
            )}
          </div>
          <div
            className="emailAndInputContainer"
            style={{ display: "flex", flexDirection: "column", gap: "6px" }}
          >
            <p
              className="emailText"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {/* Email id */}
              {t("packages.emailId")}
            </p>
            {/* <TextField
              className={classes.root}
              size="small"
              // placeholder="Enter Email"
              placeholder={t("packages.enterEmail")}
              value={values.email}
              sx={{
                minHeight: "0px !important",
                alignItems: "normal !important",
                width: "100%",
              }}
              onChange={(e) => {
                const reg = new RegExp(
                  /[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/
                );
                let validStatuses = valid;
                validStatuses[1] = reg.test(e.target.value);
                setValid([...validStatuses]);
                setValues({ ...values, email: e.target.value });
              }}
              InputProps={{
                style: customStyles,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ marginTop: "6px", padding: "0px 4px 0px 12px" }}
                  >
                    <ReactSVG src={MailSvg} />
                  </InputAdornment>
                ),
              }}
              error={!valid[1]}
            /> */}
            <EmailInputField
              classes={classes}
              values={values}
              setValues={setValues}
              valid={valid}
              setValid={setValid}
              customStyles={customStyles}
              MailSvg={MailSvg}
            />
            {valid[1] === false && (
              <p
                className="emailErrorText"
                style={{
                  color: "red",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {/* {errorValues[1]} */}
                {t("errorMessages.enterValidEmail")}
              </p>
            )}
          </div>
          <div
            className="phoneNumberAndInputContainer"
            style={{ display: "flex", flexDirection: "column", gap: "6px" }}
          >
            <p
              className="phoneNumberText"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {/* Phone number */}
              {t("packages.phoneNumber")}
            </p>
            {/* <div
              className="phoneNumberInputContainer"
              style={{ display: "inline-flex", gap: "16px" }}
            >
              {
                <TextField
                  className={classes.root}
                  sx={{
                    width: isMobile ? "30%" : "21%",
                    minHeight: "0px !important",
                    alignItems: "normal !important",
                  }}
                  size="small"
                  select
                  value={values.countryCode}
                  onChange={(e) =>
                    setValues({ ...values, countryCode: e.target.value })
                  }
                  SelectProps={{ native: true }}
                  InputProps={{
                    style: isMobile ? countryInputStyleMobile : customStyles,
                  }}
                  InputLabelProps={{
                    style: { fontSize: "12px" },
                  }}
                >
                  <option value="+966">+966 </option>
                  {Object.keys(countryInfoList).map((countryCode) => (
                    <option
                      key={countryCode}
                      value={countryInfoList[countryCode].callingCode}
                    >
                      {countryInfoList[countryCode].callingCode}
                    </option>
                  ))}
                  ;
                </TextField>
              }
              <TextField
                className={classes.root}
                size="small"
                sx={{
                  width: isMobile ? "70%" : "79%",
                  minHeight: "0px !important",
                  alignItems: "normal !important",
                }}
                // placeholder="Enter Phone Number"
                placeholder={t("packages.enterPhoneNumber")}
                type="phone"
                value={values.phoneNo}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const truncatedValue = inputValue.substring(0, 15);
                  const reg = /^[0-9-()+ ]+$/;
                  const isLengthValid = truncatedValue.length >= 8;
                  const isFormatValid = reg.test(truncatedValue);

                  let validStatuses = [...valid];
                  validStatuses[2] = isLengthValid && isFormatValid;
                  setValid(validStatuses);
                  setValues({ ...values, phoneNo: truncatedValue });
                }}
                error={!valid[2]}
                InputProps={{
                  style: customStyles,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginTop: "6px", padding: "0px 4px 0px 12px" }}
                    >
                      <ReactSVG src={MobileSvg} />
                    </InputAdornment>
                  ),
                }}
              />
            </div> */}
            <PhoneNumberInputField
              classes={classes}
              values={values}
              setValues={setValues}
              valid={valid}
              setValid={setValid}
              customStyles={customStyles}
              isMobile={isMobile}
              countryInfoList={countryInfoList}
              MobileSvg={MobileSvg}
              countryInputStyleMobile={countryInputStyleMobile}
            />
            {valid[2] === false && (
              <p
                className="phoneNumberErrorContainer"
                style={{
                  color: "red",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {/* {errorValues[2]} */}
                {t("errorMessages.enterValidPhone")}
              </p>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <p
              className="cityText"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {/* City */}
              {t("packages.city")}
            </p>
            {/* <TextField
              className={classes.root}
              size="small"
              // placeholder="Enter City"
              placeholder={t("packages.enterCity")}
              value={values.departureCity}
              onChange={(e) => {
                const reg = new RegExp("^[a-zA-Z\u0600-\u06FF ]{0,19}$");
                let validStatuses = valid;
                validStatuses[3] = reg.test(e.target.value);
                setValid([...validStatuses]);
                setValues({ ...values, departureCity: e.target.value });
              }}
              sx={{
                minHeight: "0px !important",
                alignItems: "normal !important",
                width: "100%",
              }}
              error={!valid[3]}
              InputProps={{ style: customStyles }}
            /> */}
            <CityInputField
              classes={classes}
              values={values}
              setValues={setValues}
              valid={valid}
              setValid={setValid}
              customStyles={customStyles}
            />
            {valid[3] === false && (
              <p
                className="cityErrorMessage"
                style={{
                  color: "red",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {/* {errorValues[3]} */}
                {t("errorMessages.enterValidCity")}
              </p>
            )}
          </div>
          <div
            className="passengerAndInputContainer"
            style={{ display: "flex", flexDirection: "column", gap: "6px" }}
          >
            <p
              className="numberOfPassengerText"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {/* Number of Passengers */}
              {t("packages.numberOfPassengers")}
            </p>
            {/* <TextField
              className={classes.root}
              size="small"
              // placeholder="Enter Number Of Passengers"
              placeholder={t("packages.enterNumberOfPassengers")}
              value={values.noOfPax}
              sx={{
                minHeight: "0px !important",
                alignItems: "normal !important",
                width: "100%",
              }}
              onChange={(e) => {
                const reg = new RegExp("^[1-9][0-9]{0,3}$");
                let validStatuses = valid;
                validStatuses[4] = reg.test(e.target.value);
                setValid([...validStatuses]);
                setValues({ ...values, noOfPax: e.target.value });
              }}
              error={!valid[4]}
              InputProps={{ style: customStyles }}
            /> */}
            <NumberOfPassengersInputField
              classes={classes}
              values={values}
              setValues={setValues}
              valid={valid}
              setValid={setValid}
              customStyles={customStyles}
            />
            {valid[4] === false && (
              <p
                className="passengerErrorText"
                style={{
                  color: "red",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                {/* {errorValues[4]} */}
                {t("errorMessages.enterValidNumberOfPassengers")}
              </p>
            )}
          </div>
          <div
            className="travelDateTextAndCalenderContainer"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="travelDateContainer"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                alignSelf: "flex-start",
              }}
            >
              {/* Travel Date */}
              {t("packages.travelDate")}
            </p>
            {/* <TextField
              className={classes.root}
              sx={{
                minHeight: "0px !important",
                alignItems: "normal !important",
                width: "100%",
                fontWeight: "bolder",
              }}
              placeholder={t("packages.travelDate")}
              onClick={() => {
                setShowTravelDate(!showTravelDate);
              }}
              value={values.departureDate}
              InputProps={{
                // style: customStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
              disabled={true}
              error={!valid[5]}
            ></TextField> */}
            <TravelDateInputField
              classes={classes}
              values={values}
              setShowTravelDate={setShowTravelDate}
              showTravelDate={showTravelDate}
              valid={valid}
              customStyles={customStyles}
            />
            {valid[5] === false && (
              <p
                className="passengerErrorText"
                style={{
                  color: "red",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  alignSelf: "flex-start",
                }}
              >
                {/* {errorValues[5]} */}
                {t("errorMessages.enterValidTravelDate")}
              </p>
            )}
            {showTravelDate && (
              <Calendar
                style={{ width: "100%" }}
                value={values.departureDate}
                onChange={(date) => {
                  if (!date) {
                    setValues({ ...values, departureDate: null });
                  } else {
                    const value = formatDateToYyyyMmDd(date);
                    setValues({ ...values, departureDate: value });
                    setValid((prev) => {
                      const newValid = [...prev];
                      newValid[5] = true;
                      return newValid;
                    });
                  }
                  setShowTravelDate(false);
                }}
                minDate={new Date()}
                date={
                  values.departureDate
                    ? new Date(values.departureDate)
                    : new Date()
                }
                locale={
                  localStorage.getItem("language") == "AR"
                    ? locales["arSA"]
                    : locales["enUS"]
                }
              />
            )}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                value={values.departureDate}
                className={classes.root}
                sx={{
                  minHeight: "0px !important",
                  width: "100%",
                  alignItems: "normal !important",
                }}
                onChange={(date) => {
                  console.log("Selected date:", date);
                  if (!date) {
                    setValues({ ...values, departureDate: null });
                  } else {
                    const value = formatDateToYyyyMmDd(date);
                    console.log("Formatted date:", value);
                    setValues({ ...values, departureDate: value });
                    setValid((prev) => {
                      const newValid = [...prev];
                      newValid[5] = true;
                      return newValid;
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    // placeholder: "Select date",
                    placeholder: t("packages.selectDate")
                  },
                }}
                minDate={dayjs()}
                maxDate={dayjs().year(2099)}
              />
            </LocalizationProvider> */}
          </div>
        </div>
      </div>
      <div
        className="requestACallbackContainer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          backgroundColor: "#1b1d52",
          padding: "12px 16px",
          cursor: "pointer",
        }}
        onClick={handleSubmitEnquiryform}
      >
        <p
          className="requestACallbackText"
          style={{
            color: "#FFF",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          {/* Request a call back */}
          {t("packages.requestCallback")}
        </p>
      </div>
      {showSuccessModal && (
        <Modal
          open={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        >
          <div
            className={styles.popUpLayout}
            style={{
              marginRight:
                localStorage.getItem("language") == "AR" ? "34%" : "null",
            }}
          >
            <img
              src={Tickgif}
              // style={isMobile && { width: "60px", height: "60px" }}
              className={styles.gifLayout}
            />
            <p
              className="requestSentText"
              style={{
                color: "#344054",
                fontFamily: "Lato, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
              }}
            >
              {/* Request Sent. We will get back to you shortly! */}
              {t("packages.requestSent")}
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Enquiryform;
