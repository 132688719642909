import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  airlLineName: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "14px",
    fontWeight: "700",
  },
  airLineCode: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },
  airLinNameAndCodeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "1",
  },
  deptTime: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },
  origin: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },
  departureDuration: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },
  deptDurationContainer: {
    display: "inline-flex",
  },
  flightTravelType: {
    fontFamily: "Lato",
    color: "#1DAC08",
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "4px",
    padding: "2px 4px",
  },
  travelTypeContainer: {
    display: "inline-flex",
  },
  deptDurationAndTravelTypeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  arrTime: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "12px",
    fontWeight: "700",
  },
  destination: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },
  arrTimeAndDestinationContainer: {
    display: "flex",
    flexDirection: "column",
  },
  airLineDepartureAndArrivalContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "4",
  },
  baggageAllowance: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  baggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  baggageAllowedContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  baggageAllowedOuterContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  checkSvgContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cabinBaggageAllowed: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  cabinBaggageContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  baggagesMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
    width: "240px",
  },
  baggageOptions: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },
  baggageOptionsContainer: {
    display: "inline-flex",
    gap: "4px",
  },
  flightDetailsText: {
    fontFamily: "Lato",
    color: "#1b1d52",
    fontSize: "12px",
    fontWeight: "400",
  },
  baggagesAndFlightDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  fareDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    background: "#344054",
    borderRadius: "8px",
    width: "305px",
  },
  fareDetailsText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #667085",
  },
  baseFareText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFare: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFeesText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxesAndFees: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFeeContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  baseFareAndTaxesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  totalInclText: {
    fontFamily: "Lato",
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",
  },
  totalAmount: {
    fontFamily: "Lato",
    color: "#344054",
    fontSize: "20px",
    fontWeight: "800",
  },
  totalTextContainer: {
    display: "inline-flex",
    gap: "4px",
  },
  fareDetailsOuterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  baggageAndFlightFareDetailsContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
}));

export default useStyles;
