import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getPackage } from "../../../../modules/package";
import ArrowRightSvg from "../../../../assets/images/arrow-right.svg";
import { Rings } from "react-loader-spinner";
import PackageDetail from "./components/package-detail";
import Enquiryform from "./components/enquiry-form";
// import Carousel from "react-material-ui-carousel";
// import ImageCarousel from "../../carousel";
import PackagesCarousel from "../../carousel/PackagesCarousel";
import TermsAndConditions from "./components/TermsAndConditions";
import useStyles from "./styles";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import { useTranslation } from "react-i18next";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const Package = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [packageDetails, setPackageDetails] = useState({});
  const [packageDetailsLoading, setPackageDetailsLoading] = useState(false);
  const styles = useStyles();
  const terms = packageDetails?.termsConditions;

  useEffect(() => {
    handlePackageDetail();
  }, []);

  const handlePackageDetail = async () => {
    setPackageDetailsLoading(true);
    const { packages } = await getPackage({ packageId: id });
    setPackageDetails(packages);
    setPackageDetailsLoading(false);
  };

  const images = packageDetails?.packageBanner?.map(
    (image, i) => `${S3Bucket}/${image}`
  );

  const renderSlides = packageDetails?.packageBanner?.map((image, i) => (
    <div key={i}>
      <img src={`${S3Bucket}/${image}`} style={{ height: "450px" }} />
    </div>
  ));

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        {packageDetails &&
          Object.keys(packageDetails)?.length > 0 &&
          !packageDetailsLoading && (
            <div
              style={{
                background: " #FAFAFA",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PackagesCarousel images={images} />
              <div className={styles.package}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "8px",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/")}
                  >
                    {/* Home */}
                    {t("packages.home")}
                  </p>
                  <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
                  <p
                    style={{
                      color: "#667085",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/packages/search")}
                  >
                    {/* Packages */}
                    {t("packages.packages")}
                  </p>
                  {packageDetails && packageDetails.city && (
                    <>
                      <ReactSVG
                        src={ArrowRightSvg}
                        style={{ marginTop: "3px" }}
                      />
                      <p
                        style={{
                          color: "#4037acd6",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                      >
                        {packageDetails?.city?.charAt(0).toUpperCase() +
                          packageDetails?.city?.toLowerCase().slice(1)}
                      </p>
                    </>
                  )}
                </div>
                <div className={styles.packageContentContainer}>
                  <div className={styles.packageDetailsContainer}>
                    <PackageDetail packageDetails={packageDetails} />

                    {terms && <TermsAndConditions terms={terms} />}
                  </div>
                  <div className={styles.enquiryFormContainer}>
                    <Enquiryform />
                  </div>
                </div>
              </div>
            </div>
          )}
        {!packageDetailsLoading &&
          (!packageDetails || Object.keys(packageDetails).length == 0) && (
            <div style={{
              height: '400px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '50vh'
            }}>
              {t("packages.packageNotAvailable")}
            </div>
          )}
        {packageDetailsLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Rings
              height="80"
              width="80"
              color="#1B1D52"
              radius="6"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Package;
