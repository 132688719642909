import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  twoToneTitle: {
    display: "flex",
    columnGap: "4px",
    fontSize: theme.fontSizes.fontSizeJumbo,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.fontSizes.fontSize2,
    },
  },
  twoToneTitleSecondPart: {
    color: "#4037acd6",
    fontSize: theme.fontSizes.fontSizeJumbo,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.fontSizes.fontSize2,
    },
  },
}));

export default useStyles;
