import React from "react";
import { HotelQuickFiltersView } from "../../views/HotelQuickFilters/HotelQuickFiltersView";

const HotelQuickFiltersContainer = (props) => {
  return (
    <HotelQuickFiltersView {...props} />
  );
};

export default HotelQuickFiltersContainer;
