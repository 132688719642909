import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "10px",
    background: "#FFF",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "16px 16px 0px 16px",

    [theme.breakpoints.down("sm")]: {
      // display: "inline-flex",
      // flexDirection: "row",
      // width: "100%",
      // justifyContent: "space-between",
    },
  },
  needLegRoomSeatAreCheaperContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",

    [theme.breakpoints.down("sm")]: {
      gap: "4px",
    },
  },
  needMoreLegRoom: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    color: "#344054",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  seatsAreCheaper: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    color: "#525A6A",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  onwardOriginDestinationContainer: {
    display: "inline-flex",
    gap: "8px",
  },
  onward: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    color: "#344054",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  origin: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    color: "#344054",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  oneWaySvg: {
    display: "flex",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    alignItems: "center",
    color: "#344054",
  },
  destination: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    color: "#344054",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default useStyles;
