import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    borderRadius: "2px",
    height: "40px",
    alignItems: "center",
    padding: "12px",
  },
  airLines: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato",
    flex: "1",
  },
  departure: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato",
    flex: "1",
    display: "flex",
    justifyContent: "center",
  },
  arrival: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato",
    flex: "1",
    display: "flex",
    justifyContent: "center",
  },
  duration: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato",
    flex: "2",
    display: "flex",
    justifyContent: "center",
  },
  arivalContainer: {
    display: "inline-flex",
    gap: "4px",
    flex: "2",
    justifyContent: "flex-end",
    cursor: "pointer"
  },
  price: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato",
    cursor: "pointer"
  },
}));

export default useStyles;
