import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileCalender: {
    padding: "16px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    columnGap: "6px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  mobileCalenderDisabled: {
    color: "gray",
    cursor: "not-allowed",
  },
  mobileCalenderInput: {
    width: "100%",
    border: "none",
    fontSize: theme.fontSizes.fontSize2,
    pointerEvents: "none",

    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize2,
    },
  },
  mobileCalenderWrapper: {
    width: "100%",

    "& .rdrMonths .rdrMonth": {
      width: "100%",
    },
  },
}));

export default useStyles;
