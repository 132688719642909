import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

const EmailInputField = ({
  classes,
  values,
  setValues,
  valid,
  setValid,
  customStyles,
  MailSvg,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const reg = new RegExp(/[a-z0-9\u0600-\u06FF]+@[a-z\u0600-\u06FF]+\.[a-z]{2,3}/);
    let validStatuses = valid;
    validStatuses[1] = reg.test(e.target.value);
    setValid([...validStatuses]);
    setValues({ ...values, email: e.target.value });
  };

  return (
    <div className={``} style={{ width: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', top: '11px', left: '12px', padding: '0px 4px 0px 0px' }}>
        <ReactSVG src={MailSvg} />
      </div>
      <input
        type="email"
        placeholder={t("packages.enterEmail")}
        value={values.email}
        style={{
          ...customStyles,
          minHeight: '0px',
          alignItems: 'normal',
          width: '100%',
          paddingLeft: '36px', // Adjust padding to make room for the icon
          border: '1px solid #D1D5DD'
        }}
        onChange={handleChange}
        className={!valid[1] ? 'input-error' : ''}
      />
    </div>
  );
};

export default EmailInputField;
