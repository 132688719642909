import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import UpcomingBookingsSvg from "../../../../../assets/images/upcoming-bookings.svg";
import CancelledBookingsSvg from "../../../../../assets/images/cancelled-booking.svg";
import CompletedBookingsSvg from "../../../../../assets/images/complete-booking.svg";
import AllBookingsSvg from "../../../../../assets/images/all-booking.svg";
import UpcomingBookingsSvgActive from "../../../../../assets/images/upcoming-booking-active.svg";
import CancelledBookingsSvgActive from "../../../../../assets/images/cancelled-booking-active.svg";
import CompletedBookingsSvgAcitve from "../../../../../assets/images/complete-booking-active.svg";
import AllBookingsSvgActive from "../../../../../assets/images/all-bookings-active.svg";
import { ReactSVG } from "react-svg";
import { getUserBookings } from "../../../../../modules/flight";
import { useSelector } from "react-redux";
import handleUnauthorisedLogout from "../../../../../modules/unauthorised";
import { setUserInfo } from "../../../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import HotelBookingContainer from "./hotel";
import FlightBookingContainer from "./flight";
import { useTranslation } from "react-i18next";
import { Rings } from "react-loader-spinner";

const AllBookings = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [active, setActive] = useState("ALL");
  const [bookingList, setBookingList] = useState([]);
  const [found, setFound] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [bookingsLoading, setBookingsLoading] = useState(false);

  useEffect(() => {
    handleGetBookings();
  }, [active]);

  const handleGetBookings = async () => {
    setBookingsLoading(true)
    const data = await getUserBookings({ filter: active, token: user?.token });
    setBookingsLoading(false)
    const statusCode = data?.statusCode;
    const message = data?.message;
    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }
    const bookings = [...data.flightBookings, ...data.hotelBookings];
    setBookingList(bookings);
  };

  return (
    <div className={styles.bookingContainer}>
      <div className={styles.bookingsHeader}>
        <div
          className={
            active === "ALL" ? styles.tabComponentActive : styles.tabComponent
          }
          onClick={() => {
            setActive("ALL");
          }}
        >
          <ReactSVG
            src={active === "ALL" ? AllBookingsSvgActive : AllBookingsSvg}
            style={{ marginTop: "4px" }}
          />
          <span
            className={active === "ALL" ? styles.tabTextActive : styles.tabText}
          >
            {t("myProfile.allBookings")}
          </span>
        </div>
        <div
          className={
            active === "UPCOMING"
              ? styles.tabComponentActive
              : styles.tabComponent
          }
          onClick={() => {
            setActive("UPCOMING");
          }}
        >
          <ReactSVG
            src={
              active === "UPCOMING"
                ? UpcomingBookingsSvgActive
                : UpcomingBookingsSvg
            }
            style={{ marginTop: "4px" }}
          />
          <span
            className={
              active === "UPCOMING" ? styles.tabTextActive : styles.tabText
            }
          >
            {t("myProfile.upcoming")}
          </span>
        </div>
        <div
          className={
            active === "COMPLETED"
              ? styles.tabComponentActive
              : styles.tabComponent
          }
          onClick={() => {
            setActive("COMPLETED");
          }}
        >
          <ReactSVG
            src={
              active === "COMPLETED"
                ? CompletedBookingsSvgAcitve
                : CompletedBookingsSvg
            }
            style={{ marginTop: "4px" }}
          />
          <span
            className={
              active === "COMPLETED" ? styles.tabTextActive : styles.tabText
            }
          >
            {t("myProfile.completed")}
          </span>
        </div>
        <div
          className={
            active === "CANCELLED"
              ? styles.tabComponentActive
              : styles.tabComponent
          }
          onClick={() => {
            setActive("CANCELLED");
          }}
        >
          <ReactSVG
            src={
              active === "CANCELLED"
                ? CancelledBookingsSvgActive
                : CancelledBookingsSvg
            }
            style={{ marginTop: "4px" }}
          />
          <span
            className={
              active === "CANCELLED" ? styles.tabTextActive : styles.tabText
            }
          >
            {t("myProfile.cancelled")}
          </span>
        </div>
      </div>
      {bookingList.length > 0 && !bookingsLoading && (
        bookingList.map((booking) => {
          // if (booking.status !== "BOOKED") return;
          if (!found) setFound(true);
          return booking?.hotelSearchResult ? (
            <HotelBookingContainer booking={booking} />
          ) : booking?.tickets ? (
            <FlightBookingContainer booking={booking} />
          ) : (
            <></>
          );
        })
      )}

      {bookingList.length <= 0 && !bookingsLoading && (
        <div> {t("myProfile.noBookingFound")}</div>
      )
      }

      {
        bookingsLoading && <div style={{
          width: '100%',
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'

        }}>
          <Rings height={150} width={150} color="#1B1D52" />
        </div>
      }
    </div >
  );
};

export default AllBookings;
