import React from 'react';
import { useTranslation } from 'react-i18next';

const CityInputField = ({
  classes,
  values,
  setValues,
  valid,
  setValid,
  customStyles,
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    const reg = new RegExp("^[a-zA-Z\u0600-\u06FF ]{0,19}$");
    let validStatuses = valid;
    validStatuses[3] = reg.test(e.target.value);
    setValid([...validStatuses]);
    setValues({ ...values, departureCity: e.target.value });
  };

  return (
    <div className={` `} style={{ width: '100%' }}>
      <input
        type="text"
        placeholder={t("packages.enterCity")}
        value={values.departureCity}
        style={{
          ...customStyles,
          minHeight: '0px',
          alignItems: 'normal',
          width: '100%',
          padding: '8px 10px',
          border: '1px solid #D1D5DD'
 
        }}
        onChange={handleChange}
        className={!valid[3] ? 'input-error' : ''}
      />
     
    </div>
  );
};

export default CityInputField;
