import React from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import PackagesSidebar from "./components/sidebar";
import SearchPackagesResult from "./components/search-result";
import ArrowRightSvg from "../../../../assets/images/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";
import PackageSvg from "../../../../assets/images/NO_PACKAGE_FOUND.png";
import { updateClearAll } from "../../../../utils/slices/packageSlice";
import useStyles from "./style";
import useIsMobile from "../../../../hooks/useIsMobile.hook";
import { Rings } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const SearchPackages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filteredPackages, categoryIds, packagesSearchGoingOn } = useSelector(
    (state) => state.searchPackage
  );
  const styles = useStyles();
  const isMobile = useIsMobile();
  const {t} = useTranslation()
  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <Box className={styles.packages}>
          {!isMobile && (
            <Box
              sx={{ display: "inline-flex", gap: "8px", alignItems: "center" }}
            >
              <Typography
                sx={{
                  color: "#667085",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                {/* Home */}
                {t("packages.home")}
              </Typography>
              <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
              <Typography
                sx={{
                  color: "#4037acd6",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
              >
                {/* Packages */}
                {t("packages.packages")}

              </Typography>
            </Box>
          )}
          <Box sx={{ display: "inline-flex", gap: "24px", width: "100%" }}>
            {!isMobile && (
              <Box sx={{ width: "25%" }}>
                <PackagesSidebar />
              </Box>
            )}
            <div className={styles.searchPackage}>
              {filteredPackages?.length != 0 && <SearchPackagesResult />}
              {packagesSearchGoingOn && (
                <div className={styles.packagesLoader}>
                  <Rings
                    height="150"
                    width="150"
                    color="#1B1D52"
                    radius="6"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="rings-loading"
                  />
                </div>
              )}
              {!packagesSearchGoingOn && filteredPackages?.length == 0 && (
                <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "center",
                    // width: "75%",
                    justifySelf: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                      width: isMobile ? "100%" : undefined,
                      alignItems: "center",
                    }}
                  >
                    <img src={PackageSvg} className={styles.errorImage} />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "center",
                        padding: "0px 16px",
                        textAlign: "justify",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "600",
                        }}
                      >
                        {/* Package not available */}
                        {t("packages.packageNotAvailable")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        {/* Our systems seem to be experiencing an issue. We are
                        working on resolving it asap. Please refresh the page or
                        go back to the earlier page */}
                        {t("packages.packageErrorMessage")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "inline-flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          background: "#1b1d52",
                          borderRadius: "8px",
                          padding: "8px 12px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#FFF",
                            fontFamily: "Lato, sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            isMobile
                              ? window.location.reload()
                              : dispatch(updateClearAll())
                          }
                        >
                          Refresh
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default SearchPackages;
