import { createSlice } from "@reduxjs/toolkit";

const multiCityTripSlice = createSlice({
  name: "multiCityTrip",
  initialState: {
    searchedFlights: [],
    selectedFlight: [],
    clubbedFlightsForSecondCity: [],
    clubbedFlightsForFareCategory: [],
    indexOfMultiCity: 0
  },
  reducers: {
    handleSearchedFlightsForMultiCityTrip: (state, action) => {
      state.searchedFlights = action.payload;
    },
    handleSelectedFlightForMultiCityTrip: (state, action) => {
      state.selectedFlight = action.payload;
    },
    handleClubbedFlightsForSecondCityForMultiCityTrip: (state, action) => {
      state.clubbedFlightsForSecondCity = action.payload;
    },
    handleClubbedFlightForFareCategoryForMultiCityTrip: (state, action) => {
      state.clubbedFlightsForFareCategory = action.payload;
    },
    handleIndexOfSelectedMultiCity: (state, action) => {
      state.indexOfMultiCity = action.payload;
    }
  },
});

export const {
  handleSearchedFlightsForMultiCityTrip,
  handleSelectedFlightForMultiCityTrip,
  handleClubbedFlightsForSecondCityForMultiCityTrip,
  handleClubbedFlightForFareCategoryForMultiCityTrip,
  handleIndexOfSelectedMultiCity
} = multiCityTripSlice.actions;

export default multiCityTripSlice.reducer;
