import React, { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Insurance from "../../../components/flight-module/personal-details/insurance";
import AlreadyMember from "../../../components/flight-module/personal-details/already-member";
import CartFlightDetails from "../../../components/flight-module/cards/selected-flight-details";
import BaggageDetails from "../../../components/flight-module/personal-details/add-baggage-details";
import AddContactDetails from "../../../components/flight-module/personal-details/add-contact-details";
import AddPersonalDetails from "../../../components/flight-module/personal-details/add-personal-details";
import BookingSummary from "../../../components/flight-module/personal-details/booking-summary-mobile";
import CircularCheckSvg from "../../../assets/images/circular_check_grey.svg";
import useStyles from "./style";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from "@mui/material";
import {
  handleRedirectUrl,
  setLoginPopup,
} from "../../../utils/slices/popupSlice";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import eventBus from "../../../core/eventBus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MobileOtpEnter } from "../../../components/header/components/mobile-otp-enter";
import { addPersonalDetails } from "../../../modules/flight";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../../utils/slices/localeSlice";

const PersonalDetails = (props) => {
  const {
    calculateExtraBaggagePriceValue,
    setCalculateExtraBaggagePriceValue,
    flightDetails,
    additionalBaggageDetails,
    insuranceDetails,
    extraBaggage,
    setExtraBaggage,
    isPassportRequired,
    hasSeatDynamic,
    applyInsurance,
    setApplyInsurance,
    selectedDate,
    adults,
    infants,
    childCount,
    tripType,
    markup,
    markup2,
    setScreenNo,
    seatPrice,
    isCouponApplied,
    discountValue,
    airlineCodes,
    traceId,
    resultIndex,
    markupId,
    convenienceFeeDetails,
    couponDetails,
    flightNumbers,
  } = props;

  // console.log("additionalBaggageDetails", additionalBaggageDetails);
  const travellersError = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const myRef = useRef(null);
  const accRef = useRef(null);
  const rateOfExchange = useSelector((state) => state?.rateOfExchange?.rate);
  const user = useSelector((state) => state.user);
  const totalCount = Number(adults) + Number(childCount) + Number(infants);
  const initialCountry = JSON.parse(localStorage.getItem("selectedCountry"));

  const travellerDetailsFromLocalStorage = JSON.parse(
    window.localStorage.getItem("flightTravellersDetails")
  );
  const contactDetailsFromLocalStorage = JSON.parse(
    window.localStorage.getItem("contactDetails")
  );

  for (let i = 0; i < totalCount; i++) {
    travellersError.push({
      firstNameError: "",
      lastNameError: "",
      dateOfBirthError: "",
      passportError: "",
      nationalityError: "",
      issuingCountryError: "",
      expiryDateError: "",
    });
  }

  const initialValues = [];
  for (let i = 0; i < totalCount; i++) {
    if (
      travellerDetailsFromLocalStorage &&
      travellerDetailsFromLocalStorage[i]
    ) {
      const {
        title,
        expiryDate,
        firstName,
        middleName,
        lastName,
        nationality,
        passportNumber,
        issuingCountry,
        dateOfBirth,
      } =
        travellerDetailsFromLocalStorage &&
        travellerDetailsFromLocalStorage.length > 0 &&
        travellerDetailsFromLocalStorage[i];
      initialValues.push({
        title: title ? title : "Mr",
        firstName: firstName ? firstName : "",
        middleName: middleName ? middleName : "",
        lastName: lastName ? lastName : "",
        dateOfBirth: dateOfBirth ? dateOfBirth : "",
        nationality: nationality ? nationality : "",
        passportNumber: passportNumber ? passportNumber : "",
        issuingCountry: issuingCountry ? issuingCountry : "",
        expiryDate: expiryDate ? expiryDate : "",
        isFirstNameErrorExists: false,
        isMiddleNameErrorExists: false,
        isLastNameErrorExists: false,
        paxType: 0,
      });
    } else {
      initialValues.push({
        title: "Mr",
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        nationality: initialCountry.label,
        passportNumber: "",
        issuingCountry: "",
        expiryDate: "",
        isFirstNameErrorExists: true,
        isMiddleNameErrorExists: false,
        isLastNameErrorExists: true,
        paxType: 0,
      });
    }
  }
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const contactDetails = {
    email: contactDetailsFromLocalStorage?.email
      ? contactDetailsFromLocalStorage?.email
      : userDetails?.email
      ? userDetails.email
      : "",
    mobileNumber: contactDetailsFromLocalStorage?.mobileNumber
      ? contactDetailsFromLocalStorage.mobileNumber
      : userDetails?.phoneNo
      ? userDetails.phoneNo
      : "",
    address: contactDetailsFromLocalStorage?.address
      ? contactDetailsFromLocalStorage.address
      : "",
    cellCountryCode: contactDetailsFromLocalStorage?.cellCountryCode
      ? contactDetailsFromLocalStorage.cellCountryCode
      : "+966",
    emailErrorExists:
      contactDetailsFromLocalStorage || userDetails?.email ? false : true,
    mobileNumberErrorExists:
      contactDetailsFromLocalStorage || userDetails?.phoneNo ? false : true,
    addressErrorExists: contactDetailsFromLocalStorage ? false : true,
  };

  const [values, setValues] = useState(initialValues);
  const [travellerDetailsError, setTravellerDetailsError] =
    useState(travellersError);
  const [contactDetailsValues, setContactDetailsValues] =
    useState(contactDetails);
  const [toggle, setToggle] = useState(true);
  const [showPriceAccordian, setShowPriceAccordian] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const isDOBRequired = isPassportRequired
    ? true
    : airlineCodes
    ? airlineCodes.includes("AI") || airlineCodes.includes("I5")
    : true;

  // const [addressError, setAddressError] = useState("");
  const styles = useStyles();
  const isMobile = useIsMobile();
  // const location = useLocation();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const searchURL = location?.search;
  const searchParam = new URLSearchParams(searchURL);
  const language = searchParam.get("lang");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const setLanguageFromUrl = () => {
    if (language == "AR") {
      changeLanguage("ar");
    }
  };

  // useEffect(() => {
  //   setLanguageFromUrl();
  //   dispatch(setLanguage("AR"));
  // }, []);

  useEffect(() => {
    calculateExtraBaggagePrice();
  }, [toggle]);

  useEffect(() => {}, [user]);

  const checkTravellerDetails = () => {
    let flag = false;
    const index = -1;
    const paxType = 1;
    for (let i = 0; i < totalCount; i++) {
      const {
        isFirstNameErrorExists,
        isMiddleNameErrorExists,
        isLastNameErrorExists,
        dateOfBirth,
        nationality,
        passportNumber,
        issuingCountry,
        expiryDate,
        firstName,
        lastName,
        paxType,
      } = values[i];

      console.log("isPassportRequired", isPassportRequired);
      if (
        isFirstNameErrorExists ||
        isMiddleNameErrorExists ||
        isLastNameErrorExists ||
        (isDOBRequired && dateOfBirth === "") ||
        (paxType != 1 && !dateOfBirth) ||
        // nationality === "" ||
        !firstName ||
        !lastName ||
        !nationality
      ) {
        if ((isDOBRequired && !dateOfBirth) || (paxType != 1 && !dateOfBirth)) {
          console.log("date of birth error block");

          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["dateOfBirthError"] = `${t(
              "errorMessages.dateOfBirthIsRequired"
            )}`;
            return updatedArray;
          });
        }
        if (!nationality) {
          console.log("nationality error block");
          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["nationalityError"] = "Nationality is required";
            return updatedArray;
          });
        }
        if (!lastName) {
          console.log("lastname error block");
          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["lastNameError"] = `${t(
              "errorMessages.lastNameIsRequired"
            )}`;
            return updatedArray;
          });
        }
        if (!firstName) {
          console.log("first name error block");
          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["firstNameError"] = `${t(
              "errorMessages.firstNameIsRequired"
            )}`;
            return updatedArray;
          });
        }
        console.log("flag changed at 293");
        flag = true;
      } else if (
        isPassportRequired &&
        (passportNumber === "" || issuingCountry === "" || expiryDate === "")
      ) {
        if (!passportNumber) {
          console.log("passport number error block ");
          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["passportError"] = `${t(
              "errorMessages.passportIsRequired"
            )}`;
            return updatedArray;
          });
        }
        if (!issuingCountry) {
          console.log("issuing country error block ");
          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["issuingCountryError"] = `${t(
              "errorMessages.issuingCountryIsRequired"
            )}`;
            return updatedArray;
          });
        }
        if (!expiryDate) {
          console.log("expiry date error block ");
          setTravellerDetailsError((prev) => {
            const updatedArray = [...prev];
            updatedArray[i]["expiryDateError"] = `${t(
              "errorMessages.expiryDateIsRequired"
            )}`;
            return updatedArray;
          });
        }
        console.log("flag changed at 328");
        flag = true;
      }
      if (flag === false) {
        window.localStorage.setItem(
          "flightTravellersDetails",
          JSON.stringify(values)
        );
        window.localStorage.setItem(
          "contactDetails",
          JSON.stringify(contactDetailsValues)
        );
      }

      console.log("flag", flag);

      if (flag === true) {
        return { flag, index: i, paxType };
      }
    }

    console.log("flag", flag);
    return { flag, index, paxType };
  };

  const handleBottomSheet = () => {
    eventBus.emit("open-dialog", <MobileOtpEnter />);
  };

  const handleDetailsSubmit = () => {
    const { flag: isErrorExists, index, paxType } = checkTravellerDetails();
    const title =
      paxType == 1
        ? t("addContactDetailsScreen.adult")
        : paxType == 2
        ? t("addContactDetailsScreen.children")
        : t("addContactDetailsScreen.infant");
    const {
      emailErrorExists,
      mobileNumberErrorExists,
      addressErrorExists,
      email,
      address,
      mobileNumber,
    } = contactDetailsValues;

    let contactValuesRequiredFlag = false;

    if (!mobileNumber) {
      contactValuesRequiredFlag = true;
      setMobileNumberError(`${t("errorMessages.mobileNumberIsRequired")}`);
    }

    if (!email) {
      contactValuesRequiredFlag = true;
      setEmailError(`${t("errorMessages.emailRequired")}`);
    }

    // if (!address) {
    //   contactValuesRequiredFlag = true;
    //   setAddressError("Address is required");
    // }

    if (contactValuesRequiredFlag) {
      executeScroll();
      return;
    }
    if (emailErrorExists || mobileNumberErrorExists) {
      executeScroll();
      toast.error(`${t("errorMessages.enterValidContactDetails")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (isErrorExists) {
      executeAccordianScroll();
      toast.error(`${t("errorMessages.enterValidDetailsOf")} ${title}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!user || !user.token || user?.token == "") {
      handleAddFlightDetails();
      isMobile
        ? // handleBottomSheet()
          navigate("/mobile/login", {
            state: {
              redirection: location.pathname + location.search,
            },
          })
        : dispatch(setLoginPopup(true));

      if (hasSeatDynamic) {
        dispatch(
          handleRedirectUrl({
            screenNo: 2,
          })
        );
      } else {
        dispatch(
          handleRedirectUrl({
            screenNo: 3,
          })
        );
      }
    } else {
      handleAddFlightDetails(user.token);
      if (hasSeatDynamic) {
        setScreenNo(2);
      } else {
        setScreenNo(3);
      }
    }
  };

  const executeScroll = () => myRef.current.scrollIntoView();
  const executeAccordianScroll = () => accRef.current.scrollIntoView();
  const renderAccordions = () => {
    const accordions = [];
    const travellersCount = {
      totalCount: totalCount,
      adultCount: Number(adults),
      childrenCount: Number(childCount),
      infantCount: Number(infants),
    };

    for (let i = 1; i <= travellersCount.totalCount; i++) {
      let accordionTitle = "";
      let paxType = null;
      if (i <= travellersCount.adultCount) {
        paxType = 1;
        accordionTitle = `${t("addContactDetailsScreen.adult")} ${i} :`;
      } else if (
        i <=
        travellersCount.adultCount + travellersCount.childrenCount
      ) {
        paxType = 2;
        accordionTitle = `${t("addContactDetailsScreen.children")} ${
          i - travellersCount.adultCount
        } :`;
      } else {
        paxType = 3;
        accordionTitle = `${t("addContactDetailsScreen.infant")} ${
          i - travellersCount.adultCount - travellersCount.childrenCount
        } :`;
      }

      accordions.push(
        <Accordion
          key={i}
          style={{ borderRadius: "10px", marginBottom: "12px" }}
          defaultExpanded={i == 1 ? true : false}
          onChange={(e, expanded) => {
            if (accordionTitle.startsWith("Infant") && expanded) {
              const accordianDetail = document.getElementById(accordionTitle);
              accordianDetail?.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          <AccordionSummary
            style={{ padding: "8px 12px" }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Box
              sx={{
                display: "inline-flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <ReactSVG src={CircularCheckSvg} style={{ marginTop: "4px" }} />
              <Typography
                sx={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {accordionTitle}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div id={accordionTitle}>
              <AddPersonalDetails
                values={values[i - 1]}
                setValues={setValues}
                index={i - 1}
                isPassportRequired={isPassportRequired}
                paxType={paxType}
                selectedDate={selectedDate}
                travellerDetailsError={travellerDetailsError}
                setTravellerDetailsError={setTravellerDetailsError}
                isDOBRequired={paxType != 1 ? true : isDOBRequired}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      );
    }
    return accordions;
  };

  const handleAddFlightDetails = async (token) => {
    const ResultIndex = JSON.parse(resultIndex);
    const flightDetails = [];
    for (let i = 0; i < ResultIndex.length; i++) {
      flightDetails.push({
        resultIndex: ResultIndex[i],
        airlineCode: airlineCodes[i],
        flightNumber: flightNumbers[i],
      });
    }

    const passengers = [];
    for (let i = 0; i < values.length; i++) {
      passengers.push({
        title: values[i].title,
        paxType: values[i].paxType,
        firstName: values[i].firstName,
        lastName: values[i].lastName,
        dateOfBirth: values[i].dateOfBirth,
        nationality: "IN",
        passportNumber: values[i].passportNumber,
        expiryDate: values[i].expiryDate,
        issuingCountry: values[i].issuingCountry,
      });
    }

    const payload = {
      traceId,
      contactNumber: contactDetailsValues.mobileNumber,
      contactName: values[0].firstName,
      contactEmail: contactDetailsValues.email,
      flightDetails,
      markup: markupId ? markupId : null,
      markupPrice: markup + markup2,
      couponApplicable:
        couponDetails?.lowestAmountCoupon?.length > 0
          ? couponDetails?.lowestAmountCoupon[0]?._id
          : "",
      isCouponApplied: isCouponApplied.toString(),
      couponDiscount: discountValue,
      convenienceFee:
        convenienceFeeDetails.length > 0 ? convenienceFeeDetails[0]._id : "",
      passenger: passengers,
    };

    await addPersonalDetails({ token, payload });
  };

  const calculateExtraBaggagePrice = () => {
    let value = 0;
    for (const key in extraBaggage) {
      const extraBaggagePrices = extraBaggage[key];
      for (let j = 0; j < extraBaggagePrices.length; j++) {
        value += extraBaggagePrices[j].Price;
      }
    }
    setCalculateExtraBaggagePriceValue(value);
  };

  const FlightDetailsComponent = () => {
    return flightDetails.map((flight, i) => {
      return (
        <Box>
          <CartFlightDetails
            flight={flight}
            key={i}
            selected={i}
            markup={tripType === "DRT" ? (i == 0 ? markup : markup2) : markup}
          />
        </Box>
      );
    });
  };

  return (
    <>
      <Box className={styles.personalDetailsLayout}>
        {user && user.token ? (
          <></>
        ) : (
          <Box
            sx={{
              padding: "0px 10px",
            }}
          >
            <AlreadyMember />
          </Box>
        )}
        {isMobile && (
          <Box
            sx={{
              padding: "0px 10px",
            }}
            onClick={() => {
              return eventBus.emit(
                "open-bottomSheet",
                <>
                  <FlightDetailsComponent />
                </>
              );
            }}
          >
            <BookingSummary />
          </Box>
        )}
        <Box
          sx={{
            padding: "0px 10px",
          }}
        >
          <div ref={myRef}></div>

          <AddContactDetails
            contactDetailsValues={contactDetailsValues}
            setContactDetailsValues={setContactDetailsValues}
            emailError={emailError}
            setEmailError={setEmailError}
            mobileNumberError={mobileNumberError}
            setMobileNumberError={setMobileNumberError}
            // addressError={addressError}
            // setAddressError={setAddressError}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2px",
            padding: "0px 10px",
          }}
        >
          <div ref={accRef}></div>
          {renderAccordions()}
        </Box>
        <Box
          sx={{
            padding: "16px",
            borderRadius: "10px",
            border: "1px solid #D0D5DD",
            background: "#FFF",
            margin: "0px 10px",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              borderRadius: "10px",
              background: "#F7F7F7",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: "#344054",
                }}
              >
                {t("addContactDetailsScreen.aboutYourBaggage")}
              </Typography>
              {additionalBaggageDetails &&
                additionalBaggageDetails.length > 0 && (
                  <Box className={styles.baggage}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                        color: "#525A6A",
                      }}
                    >
                      {t("addContactDetailsScreen.needAdditionalBaggage")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "24px",
                        color: "#4CAF50",
                      }}
                    >
                      {t("addContactDetailsScreen.saveTimeAndMoney")}
                    </Typography>
                  </Box>
                )}
            </Box>
            {flightDetails &&
              flightDetails.map((flight, i) => {
                const originDestination = Object.keys(extraBaggage);
                return (
                  <BaggageDetails
                    flight={flight}
                    key={i}
                    baggage={
                      additionalBaggageDetails ? additionalBaggageDetails : []
                    }
                    setExtraBaggage={setExtraBaggage}
                    extraBaggage={extraBaggage}
                    index={originDestination[i]}
                    toggle={toggle}
                    setToggle={setToggle}
                  />
                );
              })}
          </Box>
        </Box>
        {/* <Insurance
          applyInsurance={applyInsurance}
          setApplyInsurance={setApplyInsurance}
          insuranceDetails={insuranceDetails}
        /> */}
        {!isMobile && (
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              padding: "0px 10px",
            }}
          >
            <div></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                padding: "10px 12px",
                borderRadius: "8px",
                border: "1px solid #1b1d52",
                background: "#1b1d52",
                cursor: "pointer",
              }}
              onClick={handleDetailsSubmit}
            >
              <p
                style={{
                  color: "#fff",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {t("addContactDetailsScreen.continueToFinalDetails")}
              </p>
            </div>
          </div>
        )}
      </Box>
      {isMobile &&
        (tripType === "DRT" ? (
          <div className={styles.container}>
            {showPriceAccordian && (
              <div className={styles.fullFairDetailsContainer}>
                <div className={styles.FairDetailsContainer}>
                  <p className={styles.fareDetails}>
                    {t("fareRuleCard.fareDetails")}
                  </p>
                  <div className={styles.baseFareAndTaxesFessContainer}>
                    <div className={styles.baseFareContainer}>
                      <p className={styles.baseFareText}>
                        {" "}
                        {t("fareRuleCard.baseFare")}
                      </p>
                      <p className={styles.baseFare}>
                        SAR{" "}
                        {(
                          Math.ceil(
                            flightDetails[0].Fare.TotalFare * rateOfExchange
                          ) -
                          (Math.ceil(
                            flightDetails[0].Fare.Tax * rateOfExchange
                          ) +
                            Math.ceil(
                              flightDetails[0].Fare.VATAmount * rateOfExchange
                            )) +
                          Math.ceil(
                            flightDetails[1].Fare.TotalFare * rateOfExchange
                          ) -
                          (Math.ceil(
                            flightDetails[1].Fare.Tax * rateOfExchange
                          ) +
                            Math.ceil(
                              flightDetails[1].Fare.VATAmount * rateOfExchange
                            )) +
                          Math.ceil(
                            flightDetails[0].Fare.OtherCharges * rateOfExchange
                          ) +
                          Math.ceil(
                            flightDetails[1]?.Fare.OtherCharges * rateOfExchange
                          ) +
                          markup +
                          markup2
                        ).toLocaleString("en-IN")}
                      </p>
                    </div>
                    <div className={styles.taxesAndFessContainer}>
                      <p className={styles.taxesAndFeesText}>
                        {t("fareRuleCard.taxesAndFees")}{" "}
                      </p>
                      <p className={styles.taxesAndFees}>
                        SAR{" "}
                        {(
                          Math.ceil(
                            flightDetails[0].Fare.Tax * rateOfExchange
                          ) +
                          Math.ceil(flightDetails[1]?.Fare.Tax * rateOfExchange)
                        ).toLocaleString("en-IN")}
                      </p>
                    </div>
                    {calculateExtraBaggagePriceValue > 0 && (
                      <div className={styles.taxesAndFessContainer}>
                        <p className={styles.taxesAndFeesText}>
                          {t("fareRuleCard.extraBaggage")}
                        </p>
                        <p className={styles.taxesAndFees}>
                          SAR{" "}
                          {Math.ceil(
                            calculateExtraBaggagePriceValue * rateOfExchange
                          ).toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {applyInsurance &&
                      insuranceDetails &&
                      insuranceDetails?.amount && (
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>
                            {" "}
                            {t("fareRuleCard.insurance")}
                          </p>
                          <p className={styles.taxesAndFees}>
                            SAR{" "}
                            {Math.ceil(
                              insuranceDetails.amount * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                    {seatPrice > 0 && (
                      <div className={styles.taxesAndFessContainer}>
                        <p className={styles.taxesAndFeesText}>
                          {t("fareRuleCard.extraSeatPrice")}
                        </p>
                        <p className={styles.taxesAndFees}>
                          {t("fareRuleCard.currencySymbol")}{" "}
                          {(seatPrice * rateOfExchange).toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}

                    {isCouponApplied && (
                      <div className={styles.taxesAndFessContainer}>
                        <p className={styles.taxesAndFeesText}>
                          {t("fareRuleCard.discount")}
                        </p>
                        <p className={styles.taxesAndFees}>
                          - {t("fareRuleCard.currencySymbol")}{" "}
                          {discountValue.toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.fairContainer}>
              <div style={{ display: "flex" }}>
                <ExpandLessIcon
                  className={styles.expandIcon}
                  onClick={() => setShowPriceAccordian(!showPriceAccordian)}
                />
                <div className={styles.fairCardLayout}>
                  <div className={styles.fairLayout}>
                    <div className={styles.totalText}>
                      {t("fareRuleCard.totalIncludingTax")}
                    </div>
                    <div className={styles.fair}>
                      {applyInsurance && insuranceDetails
                        ? `${t("fareRuleCard.currencySymbol")} ${(
                            Math.ceil(
                              flightDetails[0].Fare.TotalFare * rateOfExchange
                            ) +
                            Math.ceil(
                              flightDetails[1].Fare.TotalFare * rateOfExchange
                            ) +
                            Math.ceil(
                              insuranceDetails?.amount * rateOfExchange
                            ) +
                            Math.ceil(
                              calculateExtraBaggagePriceValue * rateOfExchange
                            ) +
                            Math.ceil(seatPrice * rateOfExchange) +
                            markup +
                            markup2 -
                            discountValue
                          ).toLocaleString("en-IN")}`
                        : `${t("fareRuleCard.currencySymbol")} ${(
                            Math.ceil(
                              flightDetails[0].Fare.TotalFare * rateOfExchange
                            ) +
                            Math.ceil(
                              flightDetails[1].Fare.TotalFare * rateOfExchange
                            ) +
                            Math.ceil(
                              calculateExtraBaggagePriceValue * rateOfExchange
                            ) +
                            Math.ceil(seatPrice * rateOfExchange) +
                            markup +
                            markup2 -
                            discountValue
                          ).toLocaleString("en-IN")} `}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div onClick={() => handleDetailsSubmit()}>
                <button className={styles.bookNowButton}>
                  {t("flightSearch.bookNow")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            {showPriceAccordian && (
              <div className={styles.fullFairDetailsContainer}>
                <div className={styles.FairDetailsContainer}>
                  <p className={styles.fareDetails}>
                    {t("fareRuleCard.fareDetails")}
                  </p>
                  <div className={styles.baseFareAndTaxesFessContainer}>
                    <div className={styles.baseFareContainer}>
                      <p className={styles.baseFareText}>
                        {" "}
                        {t("fareRuleCard.baseFare")}
                      </p>
                      <p className={styles.baseFare}>
                        {t("fareRuleCard.currencySymbol")}{" "}
                        {(
                          Math.ceil(
                            flightDetails[0].Fare.TotalFare * rateOfExchange
                          ) -
                          (Math.ceil(
                            flightDetails[0].Fare.Tax * rateOfExchange
                          ) +
                            Math.ceil(
                              flightDetails[0].Fare.VATAmount * rateOfExchange
                            )) +
                          Math.ceil(
                            flightDetails[0].Fare.OtherCharges * rateOfExchange
                          ) +
                          markup
                        ).toLocaleString("en-IN")}
                      </p>
                    </div>
                    <div className={styles.taxesAndFessContainer}>
                      <p className={styles.taxesAndFeesText}>
                        {t("fareRuleCard.taxesAndFees")}{" "}
                      </p>
                      <p className={styles.taxesAndFees}>
                        {t("fareRuleCard.currencySymbol")}{" "}
                        {Math.ceil(
                          flightDetails[0].Fare.Tax * rateOfExchange
                        ).toLocaleString("en-IN")}
                      </p>
                    </div>
                    <div className={styles.taxesAndFessContainer}>
                      <p className={styles.taxesAndFeesText}>
                        {" "}
                        {t("fareRuleCard.vat")}{" "}
                      </p>
                      <p className={styles.taxesAndFees}>
                        {t("fareRuleCard.currencySymbol")}{" "}
                        {Math.ceil(
                          flightDetails[0].Fare.VATAmount * rateOfExchange
                        ).toLocaleString("en-IN")}
                      </p>
                    </div>
                    {calculateExtraBaggagePriceValue > 0 && (
                      <div className={styles.taxesAndFessContainer}>
                        <p className={styles.taxesAndFeesText}>
                          {t("fareRuleCard.extraBaggage")}
                        </p>
                        <p className={styles.taxesAndFees}>
                          {t("fareRuleCard.currencySymbol")}{" "}
                          {Math.ceil(
                            calculateExtraBaggagePriceValue * rateOfExchange
                          ).toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {applyInsurance &&
                      insuranceDetails &&
                      insuranceDetails?.amount && (
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>
                            {t("fareRuleCard.insurance")}
                          </p>
                          <p className={styles.taxesAndFees}>
                            {t("fareRuleCard.currencySymbol")}{" "}
                            {Math.ceil(
                              insuranceDetails.amount * rateOfExchange
                            ).toLocaleString("en-IN")}
                          </p>
                        </div>
                      )}
                    {seatPrice > 0 && (
                      <div className={styles.taxesAndFessContainer}>
                        <p className={styles.taxesAndFeesText}>
                          {t("fareRuleCard.extraSeatPrice")}
                        </p>
                        <p className={styles.taxesAndFees}>
                          {t("fareRuleCard.currencySymbol")}{" "}
                          {(seatPrice * rateOfExchange).toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                    {isCouponApplied && (
                      <div className={styles.taxesAndFessContainer}>
                        <p className={styles.taxesAndFeesText}>
                          {t("fareRuleCard.discount")}
                        </p>
                        <p className={styles.taxesAndFees}>
                          - {t("fareRuleCard.currencySymbol")}{" "}
                          {discountValue.toLocaleString("en-IN")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.fairContainer}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {showPriceAccordian ? (
                  <ExpandLessIcon
                    className={styles.expandIcon}
                    onClick={() => setShowPriceAccordian(!showPriceAccordian)}
                  />
                ) : (
                  <ExpandMoreIcon
                    className={styles.expandIcon}
                    onClick={() => setShowPriceAccordian(!showPriceAccordian)}
                  />
                )}
                <div className={styles.fairCardLayout}>
                  <div className={styles.fairLayout}>
                    <div className={styles.totalText}>
                      {t("fareRuleCard.totalIncludingTax")}
                    </div>
                    <div className={styles.fair}>
                      {applyInsurance && insuranceDetails
                        ? `${t("fareRuleCard.currencySymbol")} ${(
                            Math.ceil(
                              flightDetails[0].Fare.TotalFare * rateOfExchange
                            ) +
                            Math.ceil(
                              insuranceDetails?.amount * rateOfExchange
                            ) +
                            Math.ceil(
                              calculateExtraBaggagePriceValue * rateOfExchange
                            ) +
                            Math.ceil(seatPrice * rateOfExchange) +
                            markup +
                            markup2 -
                            discountValue
                          ).toLocaleString("en-IN")}`
                        : `${t("fareRuleCard.currencySymbol")} ${(
                            Math.ceil(
                              flightDetails[0].Fare.TotalFare * rateOfExchange
                            ) +
                            Math.ceil(
                              calculateExtraBaggagePriceValue * rateOfExchange
                            ) +
                            Math.ceil(seatPrice * rateOfExchange) +
                            markup +
                            markup2 -
                            discountValue
                          ).toLocaleString("en-IN")} `}{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => handleDetailsSubmit()}>
                <button className={styles.bookNowButton}>
                  {t("fareRuleCard.continueToFinalDetails")}
                </button>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default PersonalDetails;
