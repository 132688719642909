import React from "react";
import useStyles from "./style";
import { formatDateToDdMmYyyyForBooking } from "../../../../../../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { convertISOToArabicDateInDDMMYYY } from "../../../../../../../../../utils/helpers";

const SuccessContainer = ({ id, date, bookingStatus }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <div className={styles.mainContainer}>
      <p className={styles.heading}>
        {/* {t("myProfile.bookingCompletedMessage")} */}
        {/* Your flight booking has */}
        {bookingStatus == "CANCELLED" && t("myProfile.bookingCancelledMessage")}
        {bookingStatus != "CANCELLED" && t("myProfile.bookingCompletedMessage") }
      </p>
      <div className={styles.infoContainer}>
        <p className={styles.bookingId}>
          {t("myProfile.bookingId")} : {id}
        </p>
        <p className={styles.bookingDateTitle}>
          {t("myProfile.bookedOn")} :{" "}
          <span className={styles.bookingDate}>
          {
              localStorage.getItem('language') == "AR"
                ? convertISOToArabicDateInDDMMYYY(date)
                : formatDateToDdMmYyyyForBooking(date)
            }
            {/* {formatDateToDdMmYyyyForBooking(date)} */}
          </span>
        </p>
      </div>
    </div>
  );
};

export default SuccessContainer;
