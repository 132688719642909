import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  multiCitySearch: {
    display: "flex",
    flexDirection: "column",
    flex: 2.01,
    [theme.breakpoints.down("lg")]: {
      flex: 2.5,
    },
  },

  multiCityTravelDate: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("lg")]: {
      flex: 1,
    },
  },
}));

export default useStyles;
