import React, { useState } from "react";
import useStyles from "./styles";
import { Smartphone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { login, loginWithEmail } from "../../../../modules/auth";
import { setUserInfo } from "../../../../utils/slices/userSlice";
import { useDispatch } from "react-redux";
import { MobileSubmitOtp } from "../mobile-submit-otp";
import eventBus from "../../../../core/eventBus";
import closeIcon from "../../../../assets/images/x-close.svg";
import "./index.css";
import { ReactSVG } from "react-svg";
import FlagSvg from "../../../../assets/images/india-flag.svg";
import { useTranslation } from "react-i18next";
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import LockIcon from '@mui/icons-material/Lock';
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";


const ERROR_MESSAGES = {
  emptyValue: "Please enter mobile number",
  invalidMobileNumber: "Mobile number in not valid",
  emptyEmailValue: "Please enter email",
  invalidEmail: "Email is not valid",
  emptyPassword: "Please enter password.",
  invalidPassword: `Password is not valid. 
  Password must contain one capital letter, one small letter, one number and one special character`
};

export const MobileOtpEnter = ({ redirection }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mobileNumberState, setMobileNumberState] = useState({
    value: "",
    hasError: false,
    errorMessage: "",
  });
  const [emailState, setEmailState] = useState({
    value: "",
    hasError: false,
    errorMessage: "",
  });
  const [passwordState, setPasswordState] = useState({
    value: "",
    hasError: false,
    errorMessage: "",
  });
  const [showPassword, setShowPassword] = useState(false)

  const handleEmailChange = (value) => {
    const emailRegex = new RegExp(/^[\w\u0600-\u06FF-]+(\.[\w\u0600-\u06FF-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/);
    if (emailRegex.test(value)) {
      setEmailState((prev) => ({
        ...prev,
        value,
        hasError: false,
        errorMessage: "",
      }));
    } else {
      setEmailState((prev) => ({
        ...prev,
        value,
        hasError: true,
        errorMessage: t("errorMessages.enterValidEmail")
      }));
    }
  };
  const handlePasswordChange = (value) => {
    const passwordRegex = new RegExp(/^(?=.*[a-zA-Z\u0600-\u06FF])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&\u0600-\u06FF]{8,}$/);
    if (passwordRegex.test(value)) {
      setPasswordState((prev) => ({
        ...prev,
        value,
        hasError: false,
        errorMessage: "",
      }));
    } else {
      setPasswordState((prev) => ({
        ...prev,
        value,
        hasError: true,
        errorMessage: t("errorMessages.passwordFormatError")
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginWithEmail({ email: emailState.value, password: passwordState.value });
    const { data, message, statusCode } = response


    if (statusCode === 200) {
      const { firstName, lastName, accessToken, profilePic, newCustomer, email, phoneNo, isBlocked } = data
      const userDetails = {
        firstName,
        lastName,
        token: accessToken,
        profilePic,
        newCustomer,
        email,
        phoneNo
      }
      if (isBlocked) {
        toast.error(t("errorMessages.youAreBlocked"))
      } else {
        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
        dispatch(setUserInfo(data));
        if (redirection) {
          window.location.replace(redirection)
        } else {
          window.location.replace('/')
        }

        toast.success(t("successMessages.loginSuccessfully"))
      }


    } else {
      console.log('error')
      toast.error(message)
    }
  };

  const handleRegister = () => {

    navigate('/mobile/signup', {
      state: {
        redirection: redirection
      }
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.mobileOtpView}>

        <div className={styles.mobileOtpViewHeader}>
          <h2 className={styles.mobileOtpViewHeaderTitle}>
            {t("login.loginTitle")}
          </h2>
          <p className={styles.mobileOtpViewHeaderDesc}>
            {t("login.loginMetaTitle")}
          </p>
        </div>
        <form className={styles.mobileOtpFieldForm} onSubmit={handleSubmit}>
          <div
            className={styles.mobileOtpFormField}
            style={{ marginBottom: "-10px" }}
          >
            <label className={styles.mobileOtpFieldLabel} htmlFor="emailId">
              {t("login.InputTitleEmailId")}
            </label>

            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={t("login.placeholderEmailId")}
              id="emailId"
              value={emailState.value}
              type="email"
              className={styles.mobileOtpField}
              onChange={(e) => handleEmailChange(e.target.value)}
              onBlur={() =>
                !emailState.value &&
                setEmailState((prev) => ({
                  ...prev,
                  hasError: true,
                  errorMessage: t("errorMessages.enterValidEmail")
                }))
              }
            />
            {emailState.hasError && (
              <p className={styles.mobileOtpFieldError}>
                {emailState.errorMessage}
              </p>
            )}
            <label className={styles.mobileOtpFieldLabel} htmlFor="password">
              {t("login.InputTitlePassword")}
            </label>

            <TextField
              placeholder={t("login.placeholderPassword")}
              id="password"
              value={passwordState.value}
              className={styles.mobileOtpField + styles.mobileOtpFieldWrapper}
              onChange={(e) => handlePasswordChange(e.target.value)}
              onBlur={() =>
                !passwordState.value &&
                setPasswordState((prev) => ({
                  ...prev,
                  hasError: true,
                  errorMessage: t("errorMessages.passwordFormatError")
                }))
              }
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <VisibilityIcon
                        onClick={() => setShowPassword(false)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => setShowPassword(true)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {/* </div> */}
            <p style={{
              display: 'flex',
              justifyContent: 'flex-end',
              color: "#4037acd6",
              cursor: "pointer",
              marginTop: "16px"
            }}
              onClick={() => {
                navigate('/mobile/forgot-password')
              }}
            >
              {/* Forgot Password? */}
              {t('login.forgotPassword')}
            </p>
            {passwordState.hasError && (
              <p className={styles.mobileOtpFieldError}>
                {passwordState.errorMessage}
              </p>
            )}
          </div>

          <button
            disabled={emailState.hasError || passwordState.hasError}
            type="submit"
            className={styles.mobileOtpFormSubmit}
            style={{ marginBottom: "10px" }}
          >
            {t("login.continue")}
          </button>

        </form>
        <p style={{ display: 'flex', justifyContent: 'center' }}>
          {t("login.dontHaveAccount")} {" "}
          <span
            style={{ color: "#4037acd6", marginLeft: '5px' }}
            onClick={handleRegister}
          > {" "} {t("login.registerHere")}
            {/* Register here */}
          </span> </p>
        <div className={styles.mobileOtpTC}>
          {t("login.bottomTitle1")}
          {/* By continuing, you agree to travel */}
          <span
            onClick={() => {
              eventBus.emit("close-dialog", <MobileOtpEnter />);
              navigate("/privacy-policy");
            }}
            className={styles.mobileOtpTCHighlighted}
          >
            {" "}
            {t("login.bottomTitle2")}
            {/* privacy policy */}
          </span>{" "}
          &{" "}
          <span
            onClick={() => {
              eventBus.emit("close-dialog", <MobileOtpEnter />);
              navigate("/terms");
            }}
            className={styles.mobileOtpTCHighlighted}
          >
            {" "}
            {t("login.bottomTitle3")}
            {/* terms of use. */}
          </span>
        </div>
      </div ></div >

  );
};
