import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { Modal, Divider } from "@mui/material";
import FeeSvg from "../../../../../../../../../assets/images/feemark.svg";
import CheckSvg from "../../../../../../../../../assets/images/check_green.svg";
import CancelSvg from "../../../../../../../../../assets/images/cancelmark.svg";
import ArrowSvg from "../../../../../../../../../assets/images/right-arrow-black.svg";
import useStyles from "./style";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../../../../../core/eventBus";
import FairRulePopUp from "./fair-rules-pop-up";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FareRules = ({
  flight = {},
  showWay,
  markup,
  seatDetails,
  baggageDetails,
  flightSearch,
  fareDetailsInSAR
}) => {
  if (Object.keys(flight).length === 0) return <></>;

  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let rateOfExchange = flightSearch[0].tboResponse.LocalCurrencyROE;
  console.log("rateOfExchange", flightSearch[0].tboResponse.LocalCurrencyROE);

  const handleMobileOpen = () => {
    eventBus.emit("open-bottomSheet", <FairRulePopUp flight={flight} />);
  };

  const { Origin, Destination, NonRefundable, Passenger, MiniFareRules } =
    flight.Itinerary;
  const {
    Fare: { TotalFare, BaseFare, Tax, OtherCharges, VATAmount },
  } = Passenger[0];

  return (
    <div className={classes.mainContainer}>
      <div className={classes.fairRulesCancelContainer}>
        <div className={classes.fairRulesContainer}>
          <p className={classes.fairRulesText}>{t("fareRuleCard.fareRules")}</p>
          {showWay && (
            <div className={classes.originDestinationContainer}>
              <p className={classes.origin}>{Origin}</p>
              <ReactSVG src={ArrowSvg} style={{ marginTop: "2px" }} />
              <p className={classes.destination}>{Destination}</p>
            </div>
          )}
        </div>
        <div>
          <p
            className={classes.cancelAndChanges}
            onClick={isMobile ? handleMobileOpen : handleOpen}
          >
            {t("fareRuleCard.cancelAndChanges")}{" "}
          </p>

          <Modal open={open} onClose={handleClose}>
            <div className="fair-rule-pop-up-box" style={{ outline: "none" }}>
              <FairRulePopUp handleModalClose={handleClose} flight={flight} />
            </div>
          </Modal>
        </div>
      </div>
      <div className={classes.refundableNonRefundableContainer}>
        {NonRefundable ? (
          <div className={classes.refundableContainer}>
            <ReactSVG src={CancelSvg} />
            <p className={classes.refundableText}>
              {t("fareRuleCard.nonRefundable")}
            </p>
          </div>
        ) : (
          <div className={classes.nonRefundable}>
            <ReactSVG src={CheckSvg} />
            <p className={classes.nonRefundableText}>
              {t("fareRuleCard.refundable")}
            </p>
          </div>
        )}
        {MiniFareRules && MiniFareRules?.length > 0 && (
          <div className={classes.changeableWithFeeContainer}>
            <ReactSVG src={FeeSvg} />
            <p className={classes.changeableWithFee}>
              {t("fareRuleCard.changeableWithFees")}
            </p>
          </div>
        )}
      </div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <>
        <div className={classes.pricesContainer}>
          <p className={classes.baseFare}>{t("fareRuleCard.baseFare")}</p>
          <p className={classes.baseFarePrice}>
            {t("fareRuleCard.currencySymbol")}{" "}
            {/* {Math.ceil(
              (TotalFare - seatDetails - baggageDetails) * rateOfExchange -
                (Math.ceil(Tax * rateOfExchange) +
                  Math.ceil(VATAmount * rateOfExchange))
            ) + markup} */}
            {fareDetailsInSAR?.baseFareSAR + fareDetailsInSAR?.markupPrice}
          </p>
        </div>
        <div className={classes.taxesAndFeeContainer}>
          <p className={classes.taxesAndFees}>
            {t("fareRuleCard.taxesAndFees")}{" "}
          </p>
          <p className={classes.taxesAndFeesPrice}>
            {t("fareRuleCard.currencySymbol")}{" "}
            {/* {Math.ceil(Tax * rateOfExchange).toLocaleString("en-IN")} */}
            {fareDetailsInSAR?.taxSAR}
          </p>
        </div>
        <div className={classes.taxesAndFeeContainer}>
          <p className={classes.taxesAndFees}>
            {/* VAT */}
            {t("fareRuleCard.vat")}{" "}
          </p>
          <p className={classes.taxesAndFeesPrice}>
            {t("fareRuleCard.currencySymbol")}{" "}
            {/* {Math.ceil(VATAmount * rateOfExchange).toLocaleString("en-IN")} */}
            {fareDetailsInSAR?.vatAmountSAR}
          </p>
        </div>
      </>
    </div>
  );
};
export default FareRules;
