import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ProfileSidebar from "../profile-sidebar";
import ArrowRightSvg from "../../../../assets/images/arrow-right.svg";
import AllBookings from "./booking-list";
import Logout from "../../../../Auth/logout";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../../../modules/auth";
const Bookings = () => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage?.userDetails)
  const token = userDetails?.token
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isProfileBookingsActive, setIsProfileBookingsActive] = useState(true);
  const [userData, setUserData]= useState({})
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const classes = useStyles();
  const handleIsProfileActive = () => {
    setIsProfileActive(true);
    setIsProfileBookingsActive(false);
    navigate("/profile");
  };

  const handleIsProfileBookingsActive = () => {
    setIsProfileActive(false);
    setIsProfileBookingsActive(true);
    navigate("/bookings");
  };

  const handleIsProfileLogoutActive = () => {
    handleLogout();
  };

  const handleLogout = async () => {
    setShowLogoutModal(true);
  };

  const { t } = useTranslation()

  async function getuserProfileData() {
    try {
      const data = await getUserProfile(token);
      const { email = userDetails.email, firstName, lastName, phoneNo, profilePic } = data;
      const userdata = {
        email,
        firstName,
        lastName,
        phoneNo,
        profilePic,
        token,
      };
      setUserData(userdata);
      console.log('userData', userdata)
      localStorage.setItem("userDetails", JSON.stringify(userdata));
    } catch (err) {
      console.log("error", err);
    }
  }

  useEffect(() => {
    // get user data
    if (token) {
      getuserProfileData();
    } else {
      navigate('/')
    }
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.breadCrumbContainer}>
        <p className={classes.homeBreadCrumb} onClick={() => navigate("/")}>
          {t("myBookings.home")}
          {/* Home */}
        </p>
        <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
        <p
          className={classes.myProfileBreadCrumb}
          onClick={() => navigate("/bookings")}
        >
          {t("myBookings.myBookings")}
          {/* My Bookings */}
        </p>
      </div>
      <div className={classes.sidebarAndBookingsContainer}>
        <div className={classes.sideBarContainer}>
          <ProfileSidebar
            isProfileActive={isProfileActive}
            handleIsProfileActive={handleIsProfileActive}
            isProfileBookingsActive={isProfileBookingsActive}
            handleIsProfileBookingsActive={handleIsProfileBookingsActive}
            handleIsProfileLogoutActive={handleIsProfileLogoutActive}
            userData={userData}
          />
        </div>
        <div className={classes.allBookingsContainer}>
          <AllBookings />
        </div>
      </div>
      {showLogoutModal && (
        <Logout
          showLogoutModal={showLogoutModal}
          setShowLogoutModal={setShowLogoutModal}
        />
      )}
    </div>
  );
};

export default Bookings;
