import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  packageContainer: {
    // display: "inline-flex",
    // flexWrap: "wrap",
    gap: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(2,minmax(200px,1fr))",
    gridTemplateRows: "1fr",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  packageCard: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    background: "#FFF",
    borderRadius: "10px",
    cursor: "pointer",
    // width: "460px",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    // [theme.breakpoints.down("lg")]: {
    //   width: "360px",
    // },
    // flex: 1,
  },

  personPrice: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
    textAlign: "right",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },

  packageDetails: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "-webkit-Box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  packageDetailsContainer: {
    display: "flex",
    gap: "6px",
  },

  perPersonText: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    textAlign: "right",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },

  priceContainer: {
    alignSelf: "end",
    // height: "60px",
  },

  priceDetails: {
    display: "flex",
    gap: "10px",
  },

  packageDetailDiscountedPrice: {
    fontSize: "14px",
    textAlign: "right",
    fontFamily: "Lato",
    fontWeight: 500,
    textDecoration: "line-through",
    color: "#667085",
    marginTop: "6px",
  },

  benefitsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",

    // [theme.breakpoints.up("md")]: {
    //   width: "250px",
    // },
  },

  packageBenefits: {
    display: "inline-flex",
    gap: "22px",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },

    [theme.breakpoints.down("lg")]: {
      gap: "15px",
    },
  },
}));

export default useStyles;
