import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  uiChips: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    boxSizing: "border-box",
    padding: "8px",
    borderRadius: "16px",
    columnGap: "8px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },
  uiChipsLabel: {
    fontSize: theme.fontSizes.fontSize3,
  },
  uiChipsCloseIcon: {
    cursor: "pointer",
  },
}));

export default useStyles;
