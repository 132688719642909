import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "140px",
      borderRadius: "0px",
    },
  },
  topContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  departureReturnContainer: {
    display: "inline-flex",
    gap: "16px",
  },
  returnDeparture: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  departureTime: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  flightDetailsText: {
    color: "#4037acd6",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
  },
  bottomContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  flightLogoDepTimeContainer: {
    display: "inline-flex",
    gap: "12px",
  },
  depTimeAndOriginContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  deptTimeContainer: {
    display: "inline-flex",
    gap: "2px",
    alignItems: "center",
  },
  deptTimeZero: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  deptTimeOne: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  origin: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baggageAllowanceMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    justifyContent: "center",
    alignItems: "center",
  },
  clockAndDurationContainer: {
    display: "inline-flex",
    gap: "4px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "16px",
  },
  duration: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  travelTypeContainer: {
    display: "inline-flex",
    gap: "4px",
  },
  travelType: {
    color: "#1DAC08",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  baggageOptionContainer: {
    display: "inline-flex",
    gap: "4px",
  },
  baggageOptionText: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  baggagesAllowedMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
    width: "240px",
  },
  baggageAllowanceText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  baggagesAllowedContianer: {
    display: "inline-flex",
    gap: "6px",
  },
  checkSvgContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  baggageAllowedText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  cabinBaggageMainContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  cabinBaggageAllowedText: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  destArrivalOuterContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  desArrivalInnerContainer: {
    display: "inline-flex",
    gap: "2px",
    alignItems: "center",
  },
  arrivalTime: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  arrivalTimeTwo: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  destinationTime: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  clockSvg: {
    [theme.breakpoints.down("sm")]: {
      width: "8px",
      height: "8px",
      marginTop: "-7px",
      marginRight: "6px",
    },
  },
  baggageLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
