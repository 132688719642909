import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "0px 150px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
  },
  hotelDetailsAndFareBreakdownContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "24px",
    //   gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  mainHeading: {
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      // gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  finalPaymentContainer: {
    backgroundColor: "#E6E6FA",
    padding: "12px 24px",
    borderRadius: "5px",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "20px 0",
  },
  payNowButton: {
    background: "#1b1d52",
    borderRadius: "8px",
    padding: ".625rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "110px",
    },
    // [theme.breakpoints.down('sm')]:{
    //   width: '100px'
    // }
  },
  payNowButtonDisabled: {
    background: "#9e9e9e",
    borderRadius: "8px",
    padding: ".625rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "110px",
    },
  },

  payNowText: {
    fontFamily: "Lato, sans-serif",
    color: "#FFF",
    fontWeight: "600",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  finalPaymentTotalPrice: {
    fontFamily: "Lato, sans-serif",
    color: "#344054",
    fontWeight: "600",
    letterSpacing: "0.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hotelDetails: {
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  fareBrekdown: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  tableHeaderCell: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
    // overflow: 'scroll'
  },
  tableDataCell: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    // overflow: 'scroll'
  },

  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    overflow: "scroll",
  },
  guestDetailsContainer: {
    display: "flex",
    padding: "16px",
    alignItems: "flex-start",
    gap: "71px",
    background: "#F4F4F4",
  },
  guestDetailText: {
    color: " #344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "bolder",
  },
  table: {
    overflow: "scroll",
  },
  margin20: {
    margin: "20px 0",
  },
}));

export default useStyles;
