import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "8px",
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#344054",
  },
  metaTitle: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#667085",
  },
  successButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    textTransform: "capitalize",
    background: "#1B1D52",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
}));

export default useStyles;
