import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileSubmitOtpView: {
    maxWidth: "100%",
    width: '500px',
    padding: "32px 16px",
    // background: "#fff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center'
  },
  mobileSubmitOtpViewHeader: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
  },
  mobileSubmitOtpViewHeaderTitle: {
    fontSize: theme.fontSizes.fontSize4,
    color: theme.palette.textColors.textColorPrimary,
  },
  mobileSubmitOtpViewHeaderDesc: {
    fontSize: theme.fontSizes.fontSize2,
    color: theme.palette.textColors.textColorSecondary,
  },
  mobileSubmitOtpFormField: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  mobileSubmitOtpFieldWrapper: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
    padding: "16px",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
  },
  mobileSubmitOtpFieldForm: {
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mobileSubmitOtpFieldLabel: {},
  mobileSubmitOtpField: {
    width: "100%",
    // height: "100%",
    border: "none",
    fontSize: theme.fontSizes.fontSize4,
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize4,
    },
  },
  mobileSubmitOtpFormSubmit: {
    width: "100%",
    marginTop: "16px",
    color: theme.palette.entityColors.entityColorBackground1,
    borderRadius: "10px",
    fontSize: theme.fontSizes.fontSize1,
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    border: "none",
    padding: "16px 12px",
  },
  mobileSubmitOtpTimer: {
    fontSize: theme.fontSizes.fontSize2,
    textAlign: "center",
  },
  mobileSubmitOtpLabels: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  mobileSubmitOtpTimerHighlighted: {
    cursor: "pointer",
    color: theme.palette.entityColors.entityColorPrimary,
  },
  mobileSubmitOtpTimerDisabled: {
    cursor: "pointer",
    color: "#344054",
  },
  mobileSubmitOtpFieldError: {
    color: theme.palette.entityColors.entityColorBackgroundRed,
  },
  mobileSubmitOtpGoBack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "8px",
    fontSize: theme.fontSizes.fontSize1,
    marginTop: "16px",
    color: theme.palette.entityColors.entityColorPrimary,
  },
  goBackArrowIcon: {
    color: theme.palette.entityColors.entityColorPrimary,
  },
}));

export default useStyles;
