import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  alreadyMemberMainContainer: {
    borderRadius: "10px",
    border: "1px solid #1b1d52",
    background: "#E6E6FA",
    padding: "16px 16px",
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  alreadyMemberInnerContainer: {
    gap: "12px",
    display: "inline-flex",
    alignItems: "center",
  },
  alreadyMemberTextDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    [theme.breakpoints.down("sm")]: {
      gap: "2px",
    },
  },
  alreadyMemberText1: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  alreadyMemberText2: {
    color: "#525A6A",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  signInDiv: {
    borderRadius: "6px",
    border: "1px solid #1b1d52",
    divShadow: "0px 2px 16px 0px rgba(0, 136, 255, 0.24)",
    color: "#FFF",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    cursor: "pointer",
    background: "#1b1d52",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      padding: "6px 10px",
    },
  },

  profileIcon: {
    [theme.breakpoints.down("sm")]: {
      width: "32px",
      height: "32px",
    },
  },
}));
export default useStyles;
