import React, { useEffect, useState } from "react";
import { get, isEmpty, set, map, chunk, split } from "lodash";
import useStyles from "./styles";
import { HotelDetailsInfo } from "../HotelDetailsInfo";
import { HotelDetailsImagesView } from "../HotelDetailsImages/HotelDetailsImagesView";
import Map from "../../../components/map";
import { HotelDetailsAmenitiesView } from "../HotelDetailsAmenities";
import { AboutHotel } from "../AboutHotel";
import { RoomDetailsView } from "../RoomDetails";
import { HotelDetailsPropertyRulesView } from "../HotelDetailsPropertyRules";
import { Rings } from "react-loader-spinner";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { loadLanguages, use } from "i18next";
import APIs from "../../../api";
import { formatSARCurrency } from "../../../utils/helpers";
import datanotfound from "../../../assets/images/datanotfound.svg";
import HotelSessionExpired from "../../../components/hotel-module/cards/session-expired";
import { AmenitiesDialogView } from "../HotelDetailsAmenities";
import Modal from "@mui/material/Modal";
import { Block, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { flightValue, hotelValue, moduleValue } from "../../../config";
import CryptoJS from "crypto-js";

export const HotelDetailsView = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const hotelCode = searchParams.get("hotelCode");

  const hotelFilters = JSON.parse(searchParams.get("hotelFilters"));
  const navigate = useNavigate();
  const styles = useStyles();
  const isMobile = useIsMobile();
  const [markup, setMarkup] = useState("");
  const [openCombinationPrice, setOpenCombinationPrice] = useState([]);
  const noOfRooms = useSelector((state) => state.hotelRooms.rooms.length)
  const [readMoreSeeLess, setReadMoreSeeLess] = useState(t("errorMessages.readMore"));
  const [readMoreSeeLessArabic, setReadMoreSeeLessArabic] =
    useState("اقرأ المزيد");
  const [selectedOpenCombinationRooms, setSelectedOpenCombinationRooms] =
    useState([]);
  const [openAmenitiesModal, setAmenitiesModal] = useState(false);
  const [priceToShowProp, setPriceToShowProp] = useState(0);
  const isLoading = get(props, "state.networkState.isLoading", false);
  const searchId = get(props, "state.searchId", null)
  const markupId = get(props, "state.markupId", null)
  const showScreenError = get(props, "state.showScreenError", false)
  const hotelDetails = get(props, "state.hotelDetails", {});
  const roomDetails = get(props, "state.roomsDetails", {})
  const sessionExpired = get(props, "state.sessionExpired", {});
  const statusMessage = get(props, "state.statusMessage", {});
  const hotelImages = get(hotelDetails, "Images", []);
  const rooms = get(props, "state.roomsDetails.HotelRoomsDetails", []);
  const rules = get(hotelDetails, "HotelPolicy", "");
  const roomCombination =
    props?.state?.roomsDetails?.RoomCombinations?.RoomCombination;
  const infoSource = props?.state?.roomsDetails?.RoomCombinations?.InfoSource;

  let allRooms = [];
  const {
    hotelFilters: { hotelCheckInDate, hotelCheckOutDate },
  } = useSelector((state) => state.globalFilters);

  const checkInDateRaw = moment(hotelCheckInDate, "DD/MM/YYYY");
  const checkOutDateRaw = moment(hotelCheckOutDate, "DD/MM/YYYY");
  const nights = checkOutDateRaw.diff(checkInDateRaw, "days");

  let amenities = get(hotelDetails, "HotelFacilities", []);
  const handleDecrypt = async (encryptedData) => {
    try {
      const REACT_APP_SECRETKEY = process.env.REACT_APP_SECRETKEY
      const key = CryptoJS.enc.Utf8.parse(REACT_APP_SECRETKEY);
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
        mode: CryptoJS.mode.ECB,
      });
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const decryptedObject = JSON.parse(decryptedText);
      return decryptedObject;
    } catch (error) {
      console.error("Decryption error:", error);
      return null;
    }
  };
  const handleBookNow = (room, index) => {
    // Here I need to encrypt room and then pass 
    localStorage.setItem("selectedRoom", JSON.stringify(room))
    isMobile
      ? navigate(
        `/hotel/add-details?&hotelCode=${hotelCode}&hotelFilters=${JSON.stringify(hotelFilters)}
        &searchId=${searchId}&mi=${markupId}&sr=${index}`
      )
      : window.open(
        `/hotel/add-details?&hotelCode=${hotelCode}&hotelFilters=${JSON.stringify(hotelFilters)}
        &searchId=${searchId}&mi=${markupId}&sr=${index}`
      );
  };



  if (roomDetails && Object.keys(roomDetails).length != 0 && !priceToShowProp) {
    let priceToShow = Number.MAX_SAFE_INTEGER;
    const markup = roomDetails?.markup
    roomDetails?.Rooms?.map((room, index) => {
      room.TotalFareRoundOff = Number(parseFloat(room.TotalFare).toFixed(2))
      room.TotalTaxRoundOff = Number(parseFloat(room.TotalTax).toFixed(2))
      room.TotalBaseFare = Number(parseFloat(room.TotalFareRoundOff - room.TotalTaxRoundOff).toFixed(2))
      if (markup) {
        const [markupAmount, appliedOn] = calculateMarkup(
          room.TotalFareRoundOff,
          room.TotalBaseFare,
          markup
        );
        room.TotalFareRoundOff += markupAmount;
        room.TotalBaseFare = room.TotalFareRoundOff - room.TotalTaxRoundOff;

      }

      if (room.TotalFareRoundOff < priceToShow) {
        priceToShow = room.TotalFareRoundOff
      }
    })
    setPriceToShowProp(priceToShow)
  }

  function calculateMarkup(roomPrice, baseFare, markup) {
    const amountType = markup?.amountType;
    const amount = markup?.amount;
    const maxAmount = markup?.maxAmount;
    const appliedOn = markup?.appliedOn;

    let markupAmount;
    if (amountType == "FLAT") {
      markupAmount = Number(parseFloat(noOfRooms * Number(parseFloat((amount < maxAmount ? amount : maxAmount)).toFixed(2))).toFixed(2))
      return [markupAmount, appliedOn];
    } else {
      if (appliedOn == "BASEFARE") {
        markupAmount = Number(parseFloat((amount / 100) * baseFare).toFixed(2));
      } else {
        markupAmount = Number(parseFloat(((amount / 100) * roomPrice)).toFixed(2));
      }
      return [markupAmount < maxAmount ? markupAmount : maxAmount, appliedOn];
    }
  }

  return (
    <>
      {isLoading &&
        <div className={styles.hotelDetailsLoader}>
          <Rings
            height="150"
            width="150"
            color="#1B1D52"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      }
      {!isLoading && !showScreenError &&
        <div className={`${styles.hotelDetails}`}>
          {sessionExpired ? (
            <HotelSessionExpired statusMessage={statusMessage} />
          ) : (
            <>
              <HotelDetailsImagesView images={hotelImages} />
              <div
                className={`${styles.hotelDetailsBody} ${!rules ? styles.hotelDetailsNoPropertyRules : ""
                  }`}
              >
                <div
                  className={styles.hotelDetailsBodyInfo}
                  style={{ marginRight: "10px" }}
                >
                  <HotelDetailsInfo
                    info={hotelDetails}
                    price={priceToShowProp}
                    nights={nights}
                    infoSource={infoSource}
                  />
                </div>
                <div className={styles.hotelDetailsBodyMap}>
                  <Map data={get(hotelDetails, "Map", {})} />
                </div>
                <div className={styles.hotelDetailsBodyAmenities}>
                  <HotelDetailsAmenitiesView
                    amenities={get(
                      hotelDetails,
                      "HotelFacilities",
                      []
                    )}
                    setAmenitiesModal={setAmenitiesModal}
                  />
                </div>
                <div className={styles.hotelDetailsBodyPropertyRules}>
                  {rules && <HotelDetailsPropertyRulesView rules={rules} />}
                </div>
                <div className={styles.hotelDetailsBodyAbout}>
                  <AboutHotel
                    hotelDetails={hotelDetails}
                    readMoreSeeLess={readMoreSeeLess}
                    SetReadMoreSeeLess={setReadMoreSeeLess}
                  ></AboutHotel>
                </div>

                {roomDetails && (
                  <div className={styles.hotelDetailsBodyRoomsList}>
                    {roomDetails &&
                      roomDetails?.Rooms?.map((room, index) => {
                        const markup = roomDetails?.markup
                        room.markup = roomDetails?.markup
                        room.TotalFareRoundOff = Number(parseFloat(room.TotalFare).toFixed(2))
                        room.TotalTaxRoundOff = Number(parseFloat(room.TotalTax).toFixed(2))
                        room.TotalBaseFare = Number(parseFloat(room.TotalFareRoundOff - room.TotalTaxRoundOff).toFixed(2))
                        room.markupAmount = 0;
                        if (markup) {
                          const [markupAmount, appliedOn] = calculateMarkup(
                            room.TotalFareRoundOff,
                            room.TotalBaseFare,
                            markup
                          );
                          room.markupAmount = markupAmount
                          room.TotalFareRoundOff += markupAmount;
                          room.TotalBaseFare = room.TotalFareRoundOff - room.TotalTaxRoundOff;
                        }

                        return (
                          <>
                            <div className={styles.roomsCombinationContainer}>
                              <p className={styles.displayNone}>
                                {(allRooms.length = 0)}
                              </p>
                              <div className={styles.roomsContainer}>
                                <>
                                  <RoomDetailsView
                                    least={
                                      room.TotalFareRoundOff == priceToShowProp
                                        ? "least"
                                        : ""
                                    }
                                    key={index}
                                    // index={roomIndex}
                                    details={room}
                                  // hotelDetails={hotelDetails}
                                  // count={count}
                                  />
                                </>
                              </div>
                              {!isMobile && <Divider orientation="vertical" />}
                              <div
                                className={
                                  styles.combinationPriceAndBookNowContainer
                                }
                              >
                                <div
                                  className={styles.roomDetailsAmenitiesPrice}
                                >
                                  <div
                                    className={
                                      styles.roomDetailsAmenitiesPriceValue
                                    }
                                  >
                                    <p>{t("hotelDetails.currencySymbol")}</p>
                                    &nbsp;
                                    <p>
                                      {formatSARCurrency(
                                        parseFloat(room.TotalFareRoundOff / nights).toFixed(2)
                                      )}{" "}
                                    </p>
                                    &nbsp;
                                    <p
                                      style={{
                                        color: "#667085",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        fontFamily: "Lato, sans-serif",
                                      }}
                                    >
                                      {t("hotelDetails.perNight")}
                                    </p>
                                  </div>
                                  <button
                                    className={
                                      styles.roomDetailsAmenitiesBookNow
                                    }
                                    onClick={() => {
                                      handleBookNow(
                                        room,
                                        index
                                      );
                                    }}
                                  >
                                    {t("hotelDetails.bookNow")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}
                <div className={styles.amenitiesPopup}>
                  {" "}
                  <Modal
                    open={openAmenitiesModal}
                    style={{
                      maxWidth: "content-width",
                      verticalAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <div
                      className={styles.amenitiesInnerPopup}
                      style={{
                        // maxWidth: "max-content",
                        background: "white",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        margin: "0px auto",
                        // maxHeight: "90vh",
                        // top: "5vh",
                        // bottom: "5vh",
                        // width: isMobile ? "300px" : "500px",
                        paddingBottom: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "10px 0",
                        }}
                      >
                        <div
                          style={{ fontWeight: "bolder", paddingLeft: "12px" }}
                        >
                          {" "}
                          Amenities
                        </div>{" "}
                        <CloseIcon
                          fontSize="medium"
                          onClick={() => {
                            setAmenitiesModal(false);
                          }}
                        />
                      </div>
                      <ul
                        style={{
                          paddingLeft: "30px",
                          listStyle: "disc",
                          overflowY: "auto",
                        }}
                      >
                        {amenities?.map((amenity) => {
                          return <li>{amenity}</li>;
                        })}
                      </ul>
                    </div>
                  </Modal>
                </div>
              </div>
            </>
          )}
        </div>}

      {showScreenError &&
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
            width: '90vw'
          }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img src={datanotfound} />
            <p>Room details not found </p>

          </div>
        </div>}
    </>
  );
};
