import React, { useEffect, useState } from "react";
import { HotelDetailsView } from "../../views/HotelDetails";
import { cloneDeep, get, set } from "lodash";
import { getRoomsDetails, getHotelDetails, getMarkup } from "../../../modules/hotels";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const HotelDetailsContainer = (props) => {
  const { state } = useLocation();
  const location = useLocation();
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const hotelCode = searchParams.get("hotelCode");
  const searchId = searchParams.get("searchId");
  const markup_id = searchParams.get("mi")
  // const resultIndex = searchParams.get("resultIndex");
  // const traceId = searchParams.get("traceId");
  const hotelFilters = JSON.parse(searchParams.get("hotelFilters"));

  const user = useSelector((state) => state.user);
  const modifySearchEnabled = props?.modifySearchEnabled;
  const setModifySearchEnabled = props?.setModifySearchEnabled;
  const [hotelDetailsState, setHotelDetailsState] = useState({});
  const [roomsDetailsState, setRoomsDetailsState] = useState({});
  const [showScreenError, setShowScreenError] = useState(false)
  const [ipAddress, setIpAddress] = useState("");

  const [networkState, setNetworkState] = useState({
    isLoading: false,
    error: false,
  });
  const [hotelDetails, setHotelDetails] = useState({});
  const [hotelRooms, setHotelRooms] = useState({});
  const [hotelRoomsCombination, setHotelRoomsCombination] = useState({});
  const [sessionExpired, setSessionExpired] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIpAddress(response.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, []);
  const uuid = localStorage.getItem("uuid");
  const addPriceDetailsInHotel = (details) => {
    const clonedDetails = cloneDeep(details);
    set(clonedDetails, "hotelDetails.Price", get(state, "priceDetails", {}));
    return clonedDetails;
  };

  const fetchDetails = async () => {
    try {
      setNetworkState((prev) => ({ ...prev, isLoading: true }));
      const [hotelDetails, roomsDetails, markupDetails] = await Promise.all([
        getHotelDetails({
          hotelCode: hotelCode,
          token: user?.token,
          searchId,
          additionalDetails: {
            ipAddress: ipAddress,
            deviceType: "WEB",
            browserType: "CHROME",
            coordinates: [0],
            uuid: uuid,
          },
        }),

        getRoomsDetails({
          hotelCode,
          searchId,
          token: user?.token,
          additionalDetails: {
            ipAddress: ipAddress,
            deviceType: "WEB",
            browserType: "CHROME",
            coordinates: [0],
            uuid: uuid,
          },

        }),

        getMarkup(markup_id)
      ]);
      if (hotelDetails.statusCode != 200
        || roomsDetails.statusCode != 200
        || markupDetails.statusCode != 200) {
        setShowScreenError(true)
      }
      setNetworkState((prev) => ({ ...prev, isLoading: false }));
      setModifySearchEnabled(true);

      if (hotelDetails?.data?.detailsResponse) {
        setHotelDetailsState(hotelDetails?.data?.detailsResponse[0]);
      }
      if (roomsDetails?.data && roomsDetails.data[0]) {
        if (markupDetails?.data) {
          const { amount, amountType, appliedOn, maxAmount } = markupDetails.data
          const markup = { amountType, amount, appliedOn, maxAmount }
          roomsDetails.data[0].markup = markup
          setRoomsDetailsState(roomsDetails.data[0])
        } else {
          setRoomsDetailsState(roomsDetails.data[0])
        }
      }
    

      let isSessionExpired;
      if (hotelDetails?.statusCode == 400) {
        isSessionExpired = true;
      }
      if (hotelDetails?.data?.statusCode == 400) {
        isSessionExpired = true;
      }

      if (isSessionExpired) {
        setSessionExpired(true);
        setStatusMessage(hotelDetails?.data?.customMessage);
      }
    } catch (error) {
      console.log(error);
      setShowScreenError(true)
      setNetworkState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <HotelDetailsView
        {...props}
        state={{
          hotelDetails: addPriceDetailsInHotel(hotelDetailsState),
          roomsDetails: roomsDetailsState,
          networkState,
          sessionExpired: sessionExpired,
          statusMessage: statusMessage,
          searchId: searchId,
          markupId : markup_id,
          showScreenError: showScreenError
        }}
      />
    </>
  );
};

export default HotelDetailsContainer;
