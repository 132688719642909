import React from "react";
import MapContainer from "./map-container";
import useStyles from "./styles";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const Map = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const coordinates = {
    lat: JSON.stringify(props.data) == JSON.stringify({})? 0 : props.data.split("|")[0],
    long: JSON.stringify(props.data) == JSON.stringify({})? 0 : props.data.split("|")[1]
    // lat: get(props, "data.Latitude", 0),
    // long: get(props, "data.Longitude", 0),
  };
  const HotelName = props?.data?.HotelName;
  return (
    <>
      <div className={styles.mapWrapper}>
        <h2 className={styles.mapTitle}>{t("hotelDetails.location")}</h2>
        <MapContainer
          className={styles.MapContainer}
          coordinates={coordinates}
          HotelName={HotelName}
        />
      </div>
    </>
  );
};

export default Map;
