import React, { useState } from "react";
import { InputAdornment, Modal, TextField } from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { signupIn } from "../../../utils/validations/signup";
// import {signupIn} from '../../'
import { signupIn } from "../../../../../utils/validations/signup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import {
//   setSignupPopup,
//   setLoginPopup,
// } from "../../../utils/slices/popupSlice";
import PropTypes from "prop-types";
import "./style.css";
// import Login from "../../Login";
import { MobileOtpEnter } from "../../mobile-otp-enter";
// import eventBus from "../../../core/eventBus";
import eventBus from "../../../../../core/eventBus";
// import useIsMobile from "../../../hooks/useIsMobile.hook";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import Signup from "..";
import { setLoginPopup } from "../../../../../utils/slices/popupSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";

const SignupForm = ({ submitForm }) => {
  console.log('SignupForm')
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  // const { signup } = useSelector((state) => state.popup);
  const [openRegistration, setRegistration] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const userDetails =
    window.localStorage &&
    JSON.parse(window.localStorage.getItem("userDetails"));
  const phoneNo = userDetails ? userDetails.phoneNo : "";

  const initialValues = {
    email: "",
    number: phoneNo,
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: ""
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="card-signup-secondary"
      // style={{ width: "350px" }}
      style={{ width: "100%", marginLeft: "0px", maxWidth: '90vw' }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={signupIn(t)}
      >
        {(formik) => {
          const {
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur,
          } = formik;
          return (
            <>
              <div className="card-title">{t("signup.registrationTitle")}</div>
              <div className="card-meta-title">
                {t("signup.registrationMetaTitle")}
              </div>
              <form onSubmit={handleSubmit}>

                <div className="firstNameAndLastNameContainer">
                  <div className="firstNameContainer">
                    <div className="input-title-signup">
                      {t("signup.InputTitleFirstName")}
                    </div>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("signup.placeholderFirstName")}
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-field-signup"
                    />
                    {errors.firstName && touched.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="lastNameContainer">
                    <div className="input-title-signup">
                      {t("signup.InputTitleLastName")}
                    </div>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("signup.placeholderLastName")}
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-field-signup"
                    />
                    {errors.lastName && touched.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>

                </div>

                <div className="phoneAndEmailContainer">
                  <div className="phonenumberContainer">
                    <div className="input-title-signup">
                      {t("signup.InputTitleMobile")}
                    </div>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneIphoneIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("signup.placeholderMobile")}
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-field-signup"
                    />
                    {errors.number && touched.number && (
                      <p className="error">{errors.number}</p>
                    )}
                  </div>
                  <div className="emailContainer">
                    <div className="input-title-signup">
                      {t("signup.InputTitleEmailId")}
                    </div>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("signup.placeholderEmailId")}
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-field-signup"
                    />
                    {errors.email && touched.email && (
                      <p className="error">{errors.email}</p>
                    )}
                  </div>
                </div>

                <div className="passwordAndConfirmPasswordContainer">
                  <div className="passwordContainer">
                    <div className="input-title-signup">
                      {t("signup.InputTitlePassword")}
                    </div>
                    <TextField
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      password={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {showPassword ? (
                              <VisibilityIcon
                                onClick={() => setShowPassword(false)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setShowPassword(true)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("signup.placeholderPassword")}
                      value={values.password}
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-field-signup"
                    />
                    {errors.password && touched.password && (
                      <p style={{marginTop: '20px'}} className="error">{errors.password}</p>
                    )}
                  </div>
                  <div className="confirmPasswordContainer">
                    <div className="input-title-signup">
                      {t("signup.InputTitleConfirmPassword")}
                    </div>
                    <TextField
                      variant="outlined"
                      type={showConfirmPassword ? "text" : "password"}
                      password={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {showConfirmPassword ? (
                              <VisibilityIcon
                                onClick={() => setShowConfirmPassword(false)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() => setShowConfirmPassword(true)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("signup.placeholderPassword")}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text-field-signup"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <p style={{marginTop: '20px'}} className="error">{errors.confirmPassword}</p>
                    )}
                  </div>
                </div>

                <button className="card-button-register" type="submit">
                  {t("signup.registerButton")}
                </button>
                <div
                  className="card-bottom-title"
                  // onClick={() => navigate("/login")}
                  onClick={() => {
                    eventBus.emit("close-dialog", <Signup />);
                    dispatch(setLoginPopup(true));
                  }}
                >
                  <ArrowBackIcon
                    className="login-register"
                    style={{ marginRight: "3px", fontSize: "14px" }}
                  />
                  <p style={{ width: "100px" }} className="login-register">
                    {t("signup.backToLoginButton")}
                  </p>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignupForm;

SignupForm.propTypes = {
  submitForm: PropTypes.func,
};
