import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  bottomSheetWrapper: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.7)",
    backdropFilter: "blur(2px)",
    zIndex: "99",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  bottomSheet: {
    width: "100vw",
    borderRadius: "10px 10px 0px 0px",
    height: "fit-content",
    overflow: "auto",
    maxWidth: "800px",
    maxHeight: "100vh",
    minWidth: "200px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
  },
}));

export default useStyles;
