import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  InfantsCountData,
  adultsCountData,
  childrenCountData,
} from "../../../../../config";
import { updateTravellersCount } from "../../../../../utils/slices/searchFlightSlice";
import "./style.css";
import { updateModifyTravellersCount } from "../../../../../utils/slices/modifySearchFlightSlice";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const PassengerMobile = ({ closePassengersComponent }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    flightTotalTravellers: {
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
    flightTravelType,
  } = useSelector((state) => state.searchFlight);

  const [adultCount, setAdultCount] = useState(flightAdultsCount);
  const [childrenCount, setChildrenCount] = useState(flightChildrenCount);
  const [infantCount, setInfantCount] = useState(flightInfantsCount);

  const [maxAdultCount, setMaxAdultCount] = useState(9);
  const [maxInfantCount, setMaxInfantCount] = useState(4);
  const [maxChildrenCount, setMaxChildrenCount] = useState(6);

  const handleShowPassengerComponent = (e) => {
    e.stopPropagation();
    const totalTravellers = adultCount + childrenCount + infantCount;
    dispatch(
      updateTravellersCount({
        totalTravellers,
        adultCount,
        childrenCount,
        infantCount,
      })
    );
    dispatch(
      updateModifyTravellersCount({
        totalTravellers,
        adultCount,
        childrenCount,
        infantCount,
      })
    );
    closePassengersComponent();
  };

  const handleAdultCountValue = (value) => {
    setAdultCount(value);
    const totalTravellers = value + childrenCount + infantCount;
    dispatch(
      updateTravellersCount({
        totalTravellers,
        adultCount: value,
        childrenCount,
        infantCount,
      })
    );
    dispatch(
      updateModifyTravellersCount({
        totalTravellers,
        adultCount: value,
        childrenCount,
        infantCount,
      })
    );
  };

  const handleChildrenCountValue = (value) => {
    setChildrenCount(value);
    const totalTravellers = adultCount + value + infantCount;
    dispatch(
      updateTravellersCount({
        totalTravellers,
        adultCount,
        childrenCount: value,
        infantCount,
      })
    );
    dispatch(
      updateModifyTravellersCount({
        totalTravellers,
        adultCount: value,
        childrenCount,
        infantCount,
      })
    );
  };

  const handleInfantCountValue = (value) => {
    setInfantCount(value);
    const totalTravellers = adultCount + childrenCount + value;
    dispatch(
      updateTravellersCount({
        totalTravellers,
        adultCount,
        childrenCount,
        infantCount: value,
      })
    );
    dispatch(
      updateModifyTravellersCount({
        totalTravellers,
        adultCount: value,
        childrenCount,
        infantCount,
      })
    );
  };

  useEffect(() => {
    const count = adultCount + childrenCount + infantCount;
    setMaxAdultCount(9 - count + adultCount);
    setMaxChildrenCount(9 - count + childrenCount);
    const validInfantCount = Math.min(
      9 - count + infantCount,
      adultCount < 5 ? adultCount : 4
    );
    setMaxInfantCount(validInfantCount);
    if (validInfantCount < infantCount) setInfantCount(validInfantCount);
  }, [adultCount, childrenCount, infantCount]);

  return (
    <div className="card-passenger-mobile">
      <div className="card-content-mobile">
        <div className="section-1-mobile">
          <p className="heading">
            {t("flightSearchWidgetMobile.adultsOnTravelDay")}
          </p>
          <div className="list-numbers-mobile">
            {adultsCountData.map((count) => {
              return (
                <div
                  className="number"
                  key={count}
                  onClick={() =>
                    count <= maxAdultCount && handleAdultCountValue(count)
                  }
                >
                  {adultCount === count ? (
                    <div className="active-mobile">{count}</div>
                  ) : (
                    <div
                      className={
                        count <= maxAdultCount
                          ? "non-active-valid"
                          : "non-active-invalid"
                      }
                    >
                      {count}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="section-1-mobile">
          <p className="heading">
            {t("flightSearchWidgetMobile.childrenOnTravelDay")}
          </p>
          <div className="list-numbers-mobile">
            {childrenCountData.map((count) => {
              return (
                <div
                  className="number"
                  key={count}
                  onClick={() =>
                    count <= maxChildrenCount && handleChildrenCountValue(count)
                  }
                >
                  {childrenCount === count ? (
                    <div className="active-mobile">{count}</div>
                  ) : (
                    <div
                      className={
                        count <= maxChildrenCount
                          ? "non-active-valid"
                          : "non-active-invalid"
                      }
                    >
                      {count}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="section-1-mobile">
          <p className="heading">
            {t("flightSearchWidgetMobile.infantsOnTravelDay")}
          </p>
          <div className="list-numbers-mobile">
            {InfantsCountData.map((count) => {
              return (
                <div
                  className="number"
                  key={count}
                  onClick={() =>
                    count <= maxInfantCount && handleInfantCountValue(count)
                  }
                >
                  {infantCount === count ? (
                    <div className="active-mobile">{count}</div>
                  ) : (
                    <div
                      className={
                        count <= maxInfantCount
                          ? "non-active-valid"
                          : "non-active-invalid"
                      }
                    >
                      {count}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="bottom">
          <p
            className="apply-text"
            onClick={(e) => handleShowPassengerComponent(e)}
          >
            {t("flightSearchWidgetMobile.apply")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PassengerMobile;

PassengerMobile.propTypes = {
  closePassengersComponent: PropTypes.func,
};
