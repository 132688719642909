import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "8px",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      // padding: "12px 5px",
    },
  },
  title: {
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    color: "#344054",
  },
  successButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    textTransform: "capitalize",
    background: "#1b1d52",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
  goBackButton: {
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    textTransform: "capitalize",
    background: "#ff4d4d",
    borderRadius: "8px",
    padding: "8px 20px",
    cursor: "pointer",
  },
  tableHeadingContent: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
  },
  tableBodyContent: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },
  tableContainer: {
    overflowX: "auto",
    width: "100%",
    background: "#F5F6F6",
    border: "1px solid #D0D5DD",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  infoBottom: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },
  infoBottomTitle: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
  },
}));

export default useStyles;
