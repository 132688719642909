import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelDetailsPropertyRules: {
    padding: "24px 16px",
    borderRadius: "8px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    height: "100%",
  },
  hotelDetailsPropertyRulesTitle: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "16px",
  },
  hotelDetailsPropertyRulesSeeAllWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  hotelDetailsPropertyRulesSeeAll: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    padding: "16px",
    marginLeft: "auto",
    marginTop: "32px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    cursor: "pointer",
  },
  hotelDetailsPropertyRulesRule: {
    lineHeight: 2.2,
    fontSize: "14px",
  },

  hotelDetailsPropertyRulesDialog: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px 16px 16px",
    width: "576px !important",

    "& p": {
      fontSize: theme.fontSizes.fontSize2,
      marginBottom: "6px",
    },

    "& span": {
      fontSize: theme.fontSizes.fontSize2,
      borderTop: "1px solid black",
      marginBottom: "6px",
      marginTop: "6px",
      fontWeight: "600",
      lineHeight: "1.8",
    },

    "& span ul": {
      marginTop: "6px",
      fontWeight: "400",
      listStylePosition: "inside",
    },
  },
  hotelDetailsPropertyRulesDialogTitle: {
    fontSize: theme.fontSizes.fontSize1,
  },
  hotelDetailsPropertyRulesDialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
    position: "sticky",
    top: "0px",
    padding: "12px 0px",
    backgroundColor: "#fff",
  },
  hotelDetailsPropertyRulesDialogCloseIconWrapper: {
    cursor: "pointer",
  },
}));

export default useStyles;
