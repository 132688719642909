import React from "react";
import phonecall from "../../../../../../../../../assets/images/Iconphonecall.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

function Contact({ hotelPhoneNumber }) {
  // const AddressLine2 = hotelBlockResponse?.AddressLine2;
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: "16px",
        borderRadius: "10px",
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        border: "1px solid #D0D5DD",
      }}
    >
      <div style={{ display: "flex", gap: "8px" }}>
        <ReactSVG src={phonecall} />
        <p
          style={{
            color: "#344054",
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "Lato, sans-serif",
          }}
        >
          {t("myProfile.localContactInfo")}
        </p>
      </div>
      <p style={{ fontSize: "12px", fontWeight: "400", color: "#667085" }}>
        {t("myProfile.checkInContactInfo")}
      </p>
      <p style={{ fontWeight: "500", fontSize: "14px" }}>{hotelPhoneNumber}</p>
    </div>
  );
}

export default Contact;
