import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileOneWayFlight: {
    width: "100%",
    display: "flex",
    rowGap: "16px",
    flexDirection: "column",
  },
}));

export default useStyles;
