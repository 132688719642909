import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const TravelDateInputField = ({
  classes,
  values,
  setShowTravelDate,
  showTravelDate,
  valid,
  customStyles,
}) => {
  const { t } = useTranslation();

  return (
    <div className={` `} style={{ width: '100%', position: 'relative' }}>
      <input
        type="text"
        placeholder={t("packages.travelDate")}
        value={values.departureDate}
        readOnly
        onClick={() => setShowTravelDate(!showTravelDate)}
        style={{
          ...customStyles,
          minHeight: '0px',
          alignItems: 'normal',
          width: '100%',
          paddingRight: '36px', // Adjust padding to make room for the icon
          cursor: 'pointer',
          padding: '8px 10px',
          backgroundColor: 'white', // Ensuring the background color remains consistent with a disabled input
          border: '1px solid #D1D5DD'

        }}
        className={!valid[5] ? 'input-error' : ''}
        disabled={false}
      />
      <div style={{ position: 'absolute', top: '50%', right: '12px', transform: 'translateY(-50%)', pointerEvents: 'none' }}>
        <CalendarMonthIcon />
      </div>
    </div>
  );
};

export default TravelDateInputField;
