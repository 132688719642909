import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  travelDateContent: {
    height: "29px",
    margin: "4px 16px 0px 16px",
    color: " #344054",
    fontSize: "24px",
    fonWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
  },

  travelDateText: {
    color: " #344054",
    fontSize: "20px",
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
  },
}));

export default useStyles;
