import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../../../assets/images/clock.svg";
import { get } from "lodash";
import moment from "moment";
import HotelPolicyPopup from "./HotelPolicyPopup";
import useStyles from "./style";
import useIsMobile from "../../../../../../../../../hooks/useIsMobile.hook";
import eventBus from "../../../../../../../../../core/eventBus";
import { useTranslation } from "react-i18next";
import { convertISOToArabicDateInDDMMYYY } from "../../../../../../../../../utils/helpers";
import { getArabicDayNameFromISODate } from "../../../../../../../../../utils/helpers";

const SelectedHotelDetails = (props) => {
  const bookedHotelDetails = props?.bookedHotelDetails;
  const bookedHotelName = bookedHotelDetails?.HotelName;
  const bookedHotelCheckIn = bookedHotelDetails?.CheckIn;
  const bookedHotelCheckOut = bookedHotelDetails?.CheckOut;
  const bookedHotelRateConditions = bookedHotelDetails?.RateConditions;
  const bookedRooms = bookedHotelDetails?.Rooms;
  let bookedHotelRoomsCount = 0;
  let bookedHotelAdultsCount = 0;
  let bookedHotelChildrenCount = 0;
  let bookedHotelcheckInDateRaw = moment(bookedHotelCheckIn);
  let bookedHotelCheckOutDateRaw = moment(bookedHotelCheckOut);


  const bookedHotelsNumberOfNights = bookedHotelCheckOutDateRaw.diff(
    bookedHotelcheckInDateRaw,
    "days"
  );
  if (bookedRooms) {
    bookedHotelRoomsCount = bookedRooms?.length;
    bookedRooms.map((room) => {
      room.CustomerDetails.map((customer) => {
        customer.CustomerNames.map((name) => {
          if (name.Type == "Adult") {
            bookedHotelAdultsCount++;
          } else {
            bookedHotelChildrenCount++;
          }
        });
      });
    });
  }

  const { t } = useTranslation();


  const checkInDateRaw = moment(bookedHotelCheckIn);
  const checkOutDateRaw = moment(bookedHotelCheckOut);

  const isMobile = useIsMobile();

  const handleOpen = () => {
    eventBus.emit(
      "open-dialog",
      <HotelPolicyPopup handleModalClose={handleClose} policy={bookedHotelRateConditions} />
    );
  };
  const handleClose = () => {
    eventBus.emit(
      "close-dialog",
      <HotelPolicyPopup handleModalClose={handleClose} policy={bookedHotelRateConditions} />
    );
  };
  const classes = useStyles();

  let bookedHotelCheckInDate;
  let bookedHotelCheckInDay;
  let bookedHotelCheckOutDate;
  let bookedHotelCheckOutDay;

  if (localStorage.getItem("language") == "AR") {
    bookedHotelCheckInDate = convertISOToArabicDateInDDMMYYY(bookedHotelCheckIn)
    bookedHotelCheckInDay = getArabicDayNameFromISODate(bookedHotelCheckIn)

    bookedHotelCheckOutDate = convertISOToArabicDateInDDMMYYY(bookedHotelCheckOut)
    bookedHotelCheckOutDay = getArabicDayNameFromISODate(bookedHotelCheckOut)
  } else {
    bookedHotelCheckInDate = checkInDateRaw.format("D MMMM YYYY")
    bookedHotelCheckInDay = checkInDateRaw.format("dddd")

    bookedHotelCheckOutDate = checkOutDateRaw.format("D MMMM YYYY")
    bookedHotelCheckOutDay = checkOutDateRaw.format("dddd")
  }
  return (
    <div className={classes.mainContainer}>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: "16px",
            fontFamily: "Lato, sans-serif",
            color: "#344054",
          }}
        >
          {bookedHotelName}
        </p>
        {bookedHotelRateConditions &&
          <div className="">
            <p
              style={{
                color: "#4037acd6",
                fontFamily: "Lato",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            >
              {t("myProfile.hotelPolicy")}
            </p>
          </div>
        }
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <p
            style={{
              fontWeight: 500,
              fontSize: "14px",
              fontFamily: "Lato, sans-serif",
              color: "#000",
            }}
          >
            {t("myProfile.checkIn")}
          </p>
          <p
            style={{
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "Lato, sans-serif",
              color: "#000",
            }}
          >
            {/* {checkInDateRaw.format("dddd")} */}
            {bookedHotelCheckInDay}
          </p>
          <p
            style={{
              fontWeight: 400,
              fontSize: "12px",
              fontFamily: "Lato, sans-serif",
              color: "#667085",
            }}
          >
            {/* {checkInDateRaw.format("D MMMM YYYY")} */}
            {bookedHotelCheckInDate}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactSVG src={ClockSvg} />
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {bookedHotelsNumberOfNights}
              </p>
              <p
                style={{
                  color: "#667085",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {bookedHotelsNumberOfNights > 1
                  ? `${t("myProfile.nights")}`
                  : `${t("myProfile.night")}`}
              </p>
            </div>
          ) : (
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bookedHotelsNumberOfNights > 1
                ? `${bookedHotelsNumberOfNights} ${t("myProfile.nights")}`
                : `${bookedHotelsNumberOfNights} ${t("myProfile.night")}`}
            </p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            marginLeft: isMobile ? "10px" : 0,
          }}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: "14px",
              fontFamily: "Lato, sans-serif",
              color: "#000",
            }}
          >
            {t("myProfile.checkOut")}
          </p>
          <p
            style={{
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "Lato, sans-serif",
              color: "#000",
            }}
          >
            {/* {checkOutDateRaw.format("dddd")} */}
            {bookedHotelCheckOutDay}
          </p>
          <p
            style={{
              fontWeight: 400,
              fontSize: "12px",
              fontFamily: "Lato, sans-serif",
              color: "#667085",
            }}
          >
            {/* {checkOutDateRaw.format("D MMMM YYYY")} */}
            {bookedHotelCheckOutDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectedHotelDetails;
