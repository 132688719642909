import React, { useState } from "react";
import { paymentInitiate } from "../../../../../../modules/flight";
import { useNavigate } from "react-router-dom";
import eventBus from "../../../../../../core/eventBus";
import useStyles from "./styles";
import CryptoJS from "crypto-js";
import { flightValue, hotelValue, moduleValue } from "../../../../../../config";
import { Backdrop, CircularProgress, Radio } from "@mui/material";
import FallBackScreen from "../../../../../home/fallback-screen";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const CardSelection = ({
  amount,
  firstName,
  lastName,
  email,
  bookingId,
  product,
  bookingPayload,
  setFallBackScreen,
  fallBackScreen,
  flightCouponId,
  flightIsCouponApplied,
  hotelCouponId
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const token = JSON.parse(localStorage.getItem("userDetails"));
  const currentDate = new Date();
  const navigate = useNavigate();
  const styles = useStyles();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  console.log("date", formattedDate);
  const payload = {
    amount: amount,
    paymentType: "DB",
    currency: "SAR",
    card: selectedOption,
    bookingId: bookingId,
    orderDate: formattedDate,
    hotelBookingData: bookingPayload,
    product: product,
    customer: {
      name: firstName,
      surname: lastName,
      email: email,
    },
  };
  const handleEncrypt = (payloadData) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(payloadData),
      CryptoJS.enc.Utf8.parse(moduleValue + flightValue + hotelValue + "e3n"),
      {
        mode: CryptoJS.mode.ECB,
      }
    ).toString();
    return encryptedData;
  };

  const encryptedData = handleEncrypt(payload);
  const submitPaymentInitiate = async () => {
    if (selectedOption) {
      setOpen(true);
      const response = await paymentInitiate(encryptedData, token.token);
      if (response.id) {
        setOpen(false);
        eventBus.emit("close-dialog");
        navigate(`/payment/${response.id}`,{
          state: {
            flightCouponId: flightCouponId,
            flightIsCouponApplied: flightIsCouponApplied,
            hotelCouponId: hotelCouponId
          }
        });
      } else {
        eventBus.emit("close-dialog");
        toast.error(t("errorMessages.apologizeForInconvenience"))
        // setFallBackScreen(!fallBackScreen);
      }
      // console.log("responsePayment", response.description);
    } else {
      toast.error(t("errorMessages.selectCardBeforeProceeding"))
    }

  };
  return (
    <>
      <div className={styles.cardSelectionMain}>
        <p className={styles.selectCardText}>
          {t("selectPaymentOptionCard.selectCardForProcessing")}
          {/* Select a card for processing */}
          </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label style={{ marginRight: "10px" }}>
            <Radio
              value="MADA"
              name="radio-buttons"
              checked={selectedOption === "MADA"}
              onChange={handleOptionChange}
              sx={{
                color: "#1b1d52",
                "&.Mui-checked": {
                  color: "#1b1d52",
                },
              }}
            />
            {/* MADA */}
            {t("selectPaymentOptionCard.mada")}
          </label>
          <label style={{ marginRight: "10px" }}>
            <Radio
              value="OTHER"
              name="radio-buttons"
              checked={selectedOption === "OTHER"}
              onChange={handleOptionChange}
              sx={{
                color: "#1b1d52",
                "&.Mui-checked": {
                  color: "#1b1d52",
                },
              }}
            />
            {/* Other */}
            {t("selectPaymentOptionCard.other")}
          </label>
        </div>
        <button
          className={styles.submitButton}
          onClick={() => submitPaymentInitiate()}
        >
          {/* Submit */}
          {t("selectPaymentOptionCard.submit")}
        </button>
        <Backdrop
          sx={{
            color: "#1B1D52",
            zIndex: "2",
          }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default CardSelection;
