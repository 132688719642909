import React from "react";
import download from "../../../../../../../../../../src/assets/images/download.svg";
import ticket from "../../../../../../../../../../src/assets/images/ticket-01ticket.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

function Ticket({
  pdfURL,
  handleSendTicketsOrVouchersToMail,
  email
}) {
  // if (hotelRoomDetails && hotelRoomDetails.length <= 0) return;
  // const { HotelPassenger } = hotelRoomDetails[0];
  // const { Email } = HotelPassenger[0];

  // const { ticketPdf } = bookingDetails.data[0];
  const { t } = useTranslation();
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfURL;
    link.download = "ticket.pdf";
    link.target = "_blank";
    link.click();
  };

  return (
    <div
      style={{
        background: "#FFF",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        border: "1px solid #D0D5DD",
        padding: "16px",
      }}
    >
      <p
        style={{
          color: "rgba(64, 55, 172, 0.84)",
          fontSize: "16px",
          fontWeight: 700,
          fontFamily: "Lato, sans-serif",
        }}
      >
        {t("myProfile.tickets")}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            gap: "8px",
            cursor: "pointer",
          }}
        >
          <ReactSVG
            src={download}
            style={{ marginTop: "2px" }}
            onClick={handleDownload}
          />
          <p
            style={{
              color: "rgba(64, 55, 172, 0.84)",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Lato, sans-serif",
            }}
            onClick={handleDownload}
          >
            {t("myProfile.downloadVoucher")}
          </p>
        </div>
        <div
          style={{
            display: "inline-flex",
            gap: "8px",
            cursor: "pointer",
          }}
        >
          <ReactSVG
            src={ticket}
            style={{ marginTop: "2px" }}
            onClick={() => handleSendTicketsOrVouchersToMail(email)}
          />
          <p
            style={{
              color: "rgba(64, 55, 172, 0.84)",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Lato, sans-serif",
            }}
            onClick={() => handleSendTicketsOrVouchersToMail(email)}
          >
            {t("myProfile.emailVoucher")}
          </p>
        </div>
      </div>
    </div>
  );
}
export default Ticket;
