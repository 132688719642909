import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { Box, Typography } from "@mui/material";
import LogoutSvg from "../../../../assets/images/logout.svg";
import ProfileEditSvg from "../../../../assets/images/profile-edit.svg";
import ProfileUserSvg from "../../../../assets/images/profile-user.svg";
import ProfileUserNonActiveSvg from "../../../../assets/images/profile-user-non-active.svg";
import ProfileBookingsSvg from "../../../../assets/images/profile-bookings.svg";
import ProfileBookingsNonActiveSvg from "../../../../assets/images/profile-bookings-non-active.svg";
import ProfileImageDefaultSvg from "../../../../assets/images/profile-image-default.svg";
import { useDispatch, useSelector } from "react-redux";
import { editUserProfile, uploadFile } from "../../../../modules/auth";
import { setUserInfo } from "../../../../utils/slices/userSlice";
import useStyles from "./styles";
import handleUnauthorisedLogout from "../../../../modules/unauthorised";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const ProfileSidebar = ({
  isProfileActive,
  handleIsProfileActive,
  isProfileBookingsActive,
  handleIsProfileBookingsActive,
  handleIsProfileLogoutActive,
  userData
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { token, firstName, lastName, profilePic, email, phoneNo } =
  //   useSelector((state) => state.user);
  const { token, firstName, lastName, profilePic, email, phoneNo } = userData
  // const [profile, setProfile] = useState(profilePic);
  const S3Bucket = process.env.REACT_APP_S3_BUCKET;
  const classes = useStyles();

  const handleImage = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    handleUploadFile(file);
  };

  const handleUploadFile = async (formData) => {
    const { data, statusCode, message } = await uploadFile({
      file: formData,
      fileType: "PROFILEPIC",
      token,
    });

    if (statusCode == 401) {
      handleUnauthorisedLogout(message);
      dispatch(setUserInfo({}));
      navigate("/");
    }

    if (statusCode === 200) {
      // setProfile(data?.location);
      const res = await editUserProfile({
        token,
        firstName: firstName,
        lastName: lastName,
        phoneNo: phoneNo,
        email: email,
        profilePic: data?.location,
      });

      const statusCode = res?.statusCode;
      const message = res?.message;
      if (statusCode == 401) {
        handleUnauthorisedLogout(message);
        dispatch(setUserInfo({}));
        navigate("/");
      }

      const {
        firstName: fName,
        lastName: lName,
        email: uEmail,
        accessToken,
      } = res;
      if (window.localStorage) {
        const userDetails =
          window.localStorage &&
          JSON.parse(window.localStorage.getItem("userDetails"));
        userDetails.newCustomer = false;
        userDetails.firstName = fName;
        userDetails.token = accessToken;
        userDetails.lastName = lName;
        userDetails.email = uEmail;
        userDetails.profilePic = data?.location;
        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
        dispatch(setUserInfo(userDetails));
        toast.success(t("myProfile.profilePictureUpdatedSuccesfully"))
        location.reload()
      }
    }else{
      toast.error(t("myProfile.errorInUplodingImage"))
    }
  };

  const handleUploadClick = () => {
    document.getElementById("file_upload_id").click();
  };

  return (
    <div className={classes.mainSideBarContainer}>
      <div className={classes.innerSideBarContainer}>
        <div className={classes.profileImageNameAndEmailContainer}>
          <div className={classes.profileImageContainer}>
            {profilePic ? (
              <img
                className={classes.profileImage}
                src={`${S3Bucket}/${profilePic}`}
              />
            ) : (
              <div className={classes.avatarCharacterContainer}>
                <p className={classes.avatarCharacter}>
                  {firstName?.charAt(0)}
                </p>
                <ReactSVG src={ProfileImageDefaultSvg} />
              </div>
            )}
            <div
              className={classes.uploadPicContainer}
              onClick={handleUploadClick}
            >
              <input
                type="file"
                name="image"
                accept="image/*"
                id="file_upload_id"
                onChange={handleImage}
                style={{ display: "none" }}
              />
              <ReactSVG src={ProfileEditSvg} />
            </div>
          </div>
          <div className={classes.nameAndEmailContainer}>
            <p className={classes.fullName}>
              {firstName} {lastName}
            </p>
            <p className={classes.email}>{email}</p>
          </div>
        </div>
        <div className={classes.profileBookingsAndLogoutContainer}>
          <Box onClick={handleIsProfileActive} sx={{ cursor: "pointer" }}>
            {isProfileActive ? (
              <div className={classes.profileContainer}>
                <ReactSVG src={ProfileUserSvg} />
                <p className={classes.profileText}>{t("myProfile.profile")}</p>
              </div>
            ) : (
              <div className={classes.profile2ndContainer}>
                <ReactSVG src={ProfileUserNonActiveSvg} />
                <p className={classes.profile2ndText}>
                  {t("myProfile.profile")}
                </p>
              </div>
            )}
          </Box>
          <Box
            onClick={handleIsProfileBookingsActive}
            sx={{ cursor: "pointer" }}
          >
            {isProfileBookingsActive ? (
              <div className={classes.bookingsContainer}>
                <ReactSVG src={ProfileBookingsSvg} />
                <p className={classes.bookingsText}>
                  {t("myProfile.myBookings")}
                </p>
              </div>
            ) : (
              <div className={classes.bookings2Container}>
                <ReactSVG
                  src={ProfileBookingsNonActiveSvg}
                  fill="red"
                  style={{ fill: "yellow" }}
                />
                <p className={classes.bookings2Text}>
                  {t("myProfile.myBookings")}
                </p>
              </div>
            )}
          </Box>
          <Box onClick={handleIsProfileLogoutActive} sx={{ cursor: "pointer" }}>
            <div className={classes.logoutContainer}>
              <ReactSVG src={LogoutSvg} />
              <p className={classes.logoutText}>{t("myProfile.logout")}</p>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ProfileSidebar;

ProfileSidebar.propTypes = {
  isProfileActive: PropTypes.bool,
  handleIsProfileActive: PropTypes.func,
  isProfileBookingsActive: PropTypes.bool,
  handleIsProfileBookingsActive: PropTypes.func,
  handleIsProfileLogoutActive: PropTypes.func,
};
