import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectDomesticRoundTripMobileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "12px 16px",
    backgroundColor: "#FFF"
  },

  selectDomesticRoundTripMobile: {
    display: "flex",
    gap: "4px",
    padding: "16px 16px",
    justifyContent: "center",
    alignItems: "center",
  },

  selectDomesticRoundTripMobileLayout: {
    display: "inline-flex",
    justifyContent: "space-between",
    padding: "8px 20px",
    borderRadius: "10px",
    width: "100%",
    border: "1px solid #D0D5DD",
    background: "#FBFDFF",
  },

  flightDestinationDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  airLineDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  airlineName: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#344054",
  },
  imageLayout: {
    width: "18px",
    height: "16px",
  },
  flightTime: {
    display: "inline-flex",
    alignItems: "center",
    gap: "14px",
  },
  info: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 500,
  },
  info1: {
    color: "#344053",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
  },
  depTime: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
  },
  flightDuration: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
  },
  flightDurationText: {
    color: "#344054",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
  },
  clock: {
    display: "inline-flex",
    gap: "4px",
  },
  dashedLine: {
    background: "#D0D5DD",
    width: "150px",
    height: "1px",
  },
  fligthTravelType: {
    color: "#1DAC08",
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  flightDetails: {
    display: "flex",
    flexDirection: "column",
  },
  arrvTime: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
  },
}));

export default useStyles;
