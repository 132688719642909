import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Divider } from "@mui/material";
import ProfileSvg from "../../../../assets/images/profile.svg";
import BookingSvg from "../../../../assets/images/luggage.svg";
import LogoutSvg from "../../../../assets/images/logout.svg";
import CloseIcon from "@mui/icons-material/Close";
import eventBus from "../../../../core/eventBus";
import Logout from "../../../../Auth/logout";
import { useState } from "react";

function MobileLogoutPopup({ handleMobileLogout, display }) {
  const S3Bucket = process.env.REACT_APP_S3_BUCKET;
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { firstName, lastName, email, profilePic } = useSelector(
    (state) => state.user
  );

  return (
    <div
      style={{
        display: display,
        position: "absolute",
        top: "20",
        right: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "300px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            gap: "16px",
            borderRadius: "10px 10px 0px 0px",
            background: "#F2F7FB",
            padding: "16px 16px",
          }}
        >
          {profilePic ? (
            <img
              src={`${S3Bucket}/${profilePic}`}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                position: "relative",
              }}
            />
          ) : (
            <div style={{ position: "relative" }}>
              <p
                style={{
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  fontStyle: "normal",
                  fontWeight: "700",
                  width: "40px",
                  height: "40px",
                  color: "#08F",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "22px",
                  marginTop: "10%",
                }}
              >
                {firstName?.charAt(0)}
              </p>
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#F8F8F8",
                }}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              {firstName} {lastName}
            </p>
            <p
              style={{
                color: "#344054",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "italic",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              {email}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            padding: "16px",
            gap: "8px",
            cursor: "pointer",
            background: "#FFF",
          }}
          onClick={() => navigate("/profile")}
        >
          <ReactSVG src={ProfileSvg} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <p
              style={{
                color: "#24272A",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Profile
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Manage your profile, traveler details, login details and password
            </p>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "inline-flex",
            padding: "16px",
            gap: "8px",
            cursor: "pointer",
            background: "#FFF",
          }}
          onClick={() => navigate("/bookings")}
        >
          <ReactSVG src={BookingSvg} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <p
              style={{
                color: "#24272A",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              My Bookings
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              See booking details, Print e-ticket, Cancel Booking, Modify
              Booking, Check Refund Status & more.
            </p>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "inline-flex",
            padding: "16px",
            gap: "8px",
            cursor: "pointer",
            background: "#FFF",
          }}
          onClick={() => {
            setShowLogoutModal(true)
          }}
          //   onClick={() => handleMobileLogout()}
        >
          <ReactSVG src={LogoutSvg} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <p
              style={{
                color: "#24272A",
                fontFamily: "Lato",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
              }}
            >
              Logout
            </p>
            <p
              style={{
                color: "#667085",
                fontFamily: "Lato",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "16px",
              }}
            >
              Logout of your account
            </p>
          </div>
        </div>
        <Logout
          showLogoutModal={showLogoutModal}
          setShowLogoutModal={setShowLogoutModal}
        />
      </div>
      {/* <CloseIcon
        onClick={handleClose}
        style={{
          position: "absolute",
          top: "15px",
          right: "5px",
          height: "20px",
        }}
      /> */}
    </div>
  );
}

export default MobileLogoutPopup;
