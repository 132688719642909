import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Divider } from "@mui/material";
import ArrowDownSvg from "../../../../../../assets/images/arrow-down-black.svg";
import ArrowUpSvg from "../../../../../../assets/images/arrow-up.svg";
import { ReactSVG } from "react-svg";
// import DOMPurify from "dompurify";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;
import DOMPurify from "dompurify";
import useIsMobile from "../../../../../../hooks/useIsMobile.hook";
import useStyles from "./style";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PackageDetail = ({ packageDetails }) => {
  const { name, description, benefits, itinerary } = packageDetails;
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const getLocale = useSelector((state) => state.locale);
  const accordians = [];
  if (itinerary) {
    for (let i = 0; i < itinerary.length; i++) {
      if (i == 0) accordians.push(true);
      else accordians.push(false);
    }
  }
  const [showDetails, setShowDetails] = useState(accordians);

  const handleClick = (index) => {
    showDetails[index] = !showDetails[index];
    setShowDetails([...showDetails]);
  };

  useEffect(() => {}, [showDetails, setShowDetails]);
  const styles = useStyles();

  return (
    <Box className={styles.packageDetailsMain}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Typography
          sx={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(name || ""),
            }}
          />
          {/* {name} */}
        </Typography>
        <Typography
          sx={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description || ""),
            }}
          />

          {/* {description} */}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            marginBottom: "24px",
          }}
        >
          {t("packages.itinerary")}
        </Typography>
        {itinerary &&
          itinerary.map((day, i) => {
            const { title, details, image } = day;
            return (
              <Box
                sx={{
                  display: "inline-flex",
                  gap: isMobile ? "30px" : "50px",
                  width: "100%",
                }}
                key={i}
              >
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    width: isMobile ? "20%" : "10%",
                  }}
                >
                  {t("packages.day")} {i + 1}
                </Typography>
                <Box
                  className={
                    localStorage.getItem("language") == "AR"
                      ? styles.ItineraryMainContainerArabic
                      : styles.ItineraryMainContainer
                  }
                >
                  <Box
                    className={
                      localStorage.getItem("language") == "AR"
                        ? styles.ItineraryContentContainerArabic
                        : styles.ItineraryContentContainer
                    }
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClick(i)}
                    >
                      <Box>
                        {showDetails && showDetails[i] ? (
                          <Box
                            className={
                              localStorage.getItem("language") == "AR"
                                ? styles.itineraryDotOpenArabic
                                : styles.itineraryDotOpen
                            }
                          ></Box>
                        ) : (
                          <Box
                            className={
                              localStorage.getItem("language") == "AR"
                                ? styles.ItineraryBluePointersArabic
                                : styles.ItineraryBluePointers
                            }
                          ></Box>
                        )}
                        {showDetails && showDetails[i] ? (
                          <Typography
                            sx={{
                              color: "#4037acd6",
                              fontFamily: "Lato, sans-serif",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {title}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              color: "#344054",
                              fontFamily: "Lato, sans-serif",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {title}
                          </Typography>
                        )}
                      </Box>
                      {showDetails && showDetails[i] ? (
                        <ReactSVG
                          src={ArrowUpSvg}
                          style={{
                            width: "20px",
                            height: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      ) : (
                        <ReactSVG
                          src={ArrowDownSvg}
                          style={{
                            width: "20px",
                            height: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      )}
                    </Box>
                    {showDetails && showDetails[i] && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#344054",
                            fontFamily: "Lato, sans-serif",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                          }}
                        >
                          <div style={{ width: isMobile ? "200px" : "400px" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  details || "<h1>Page not found</h1>"
                                ),
                              }}
                            />
                          </div>
                        </Typography>
                        {image &&
                          image.map((value, i) => {
                            return (
                              <img
                                src={`${S3Bucket}/${value}`}
                                key={i}
                                style={{
                                  width: isMobile ? "100%" : "395px",
                                  height: "170px",
                                }}
                              />
                            );
                          })}
                      </Box>
                    )}
                    <Divider sx={{ marginBottom: "16px" }} />
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            marginBottom: "24px",
          }}
        >
          {t("packages.inclusions")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {benefits &&
            benefits?.map((benefit, i) => {
              return (
                <Typography
                  sx={{
                    color: "#344054",
                    fontFamily: "Lato, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                  }}
                  key={i}
                >
                  <li>{benefit}</li>
                </Typography>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default PackageDetail;

PackageDetail.propTypes = {
  packageDetails: PropTypes.object,
};
