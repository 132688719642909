const url = process.env.REACT_APP_CUSTOMER_URL;
const newsLetterUrl = process.env.REACT_APP_NEWSLETTER_URL;
const language = localStorage.getItem("language") == "AR" ? "ar" : "en";
const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"))?.value
const country = selectedCountry ? selectedCountry : "SA";

const APIs = {
  searchFlight: `${url}/flight/search/noAuth/${language}/${country}`,
  login: `${url}/login/phoneOtp/${language}/${country}`,
  setPassWord: `${url}/setPassword/${language}/${country}`,
  forgotPassword: `${url}/forgotPassword/${language}/${country}`,
  loginWithEmail: `${url}/login/email/${language}/${country}`,
  verifyOtp: `${url}/login/verifyOtp/${language}/${country}`,
  signup: `${url}/register/email/${language}/${country}`,
  logout: `${url}/logout/${language}/${country}`,
  getAirports: `${url}/airports/${language}/${country}`,
  getFlightDetails: `${url}/flight/fare-quote/noAuth/${language}/${country}`,
  getInsurance: `${url}/insurance/${language}/${country}`,
  getHotelCities: `${url}/hotelcities/${language}/${country}`,
  getSpecialServiceRequest: `${url}/flight/ssr/noAuth/${language}/${country}`,
  getPackages: `${url}/packages/${language}/${country}`,
  getPackage: `${url}/package/${language}/${country}`,
  enguirePackage: `${url}/package/enquire/${language}/${country}`,
  getFaqs: `${url}/faqs/noAuth/${language}/${country}`,
  getBanners: `${url}/banners/noAuth/${language}/${country}`,
  getUsps: `${url}/usps/${language}/${country}`,
  subscribeToNewsLetter: `${newsLetterUrl}/subscribe/${language}/${country}`,
  getBlogs: `${url}/blogs/noAuth/${language}/${country}`,
  getOffers: `${url}/coupon/offers/noAuth/${language}/${country}`,
  getUserProfile: `${url}/profile/${language}/${country}`,
  editUserProfile: `${url}/profile/${language}/${country}`,
  uploadFile: `${url}/auth/file/${language}/${country}`,
  searchHotel: `${url}/hotel/search/noAuth/${language}/${country}`,
  getCustomerPolicy: `${url}/policy/noAuth/${language}/${country}`,
  hotelRoomsDetails: `${url}/hotel/rooms/noAuth/${language}/${country}`,
  hotelDetails: `${url}/hotel/info/noAuth/${language}/${country}`,
  getClubbedFlights: `${url}/flight/search/multiCity/noAuth/${language}/${country}`,
  createFlightBooking: `${url}/flight/booking/initiate/${language}/${country}`,
  getBookingDetails: `${url}/flight/booking/${language}/${country}`,
  getMarkup: `${url}/price-markup/${language}/${country}`,
  getHotelBookingDetails: `${url}/hotel/booking-details/auth/${language}/${country}`,
  blockRooms: `${url}/hotel/preBook/${language}/${country}`,
  bookHotel: `${url}/hotel/Book`,
  convenienceFee: `${url}/hotel/convenienceFee/${language}/${country}`,
  searchFlightAuth: `${url}/flight/search/auth/${language}/${country}`,
  getClubbedFlightsAuth: `${url}/flight/search/multiCity/auth/${language}/${country}`,
  getFlightDetailsAuth: `${url}/flight/fare-quote/auth/${language}/${country}`,
  getSpecialServiceRequestAuth: `${url}/flight/ssr/auth/${language}/${country}`,
  getFaqsAuth: `${url}/faqs/auth/${language}/${country}`,
  getBannersAuth: `${url}/banners/auth/${language}/${country}`,
  getBlogsAuth: `${url}/blogs/auth/${language}/${country}`,
  getOffersAuth: `${url}/coupon/offers/auth/${language}/${country}`,
  searchHotelAuth: `${url}/hotel/search/auth/${language}/${country}`,
  getCustomerPolicyAuth: `${url}/policy/auth/${language}/${country}`,
  hotelRoomsDetailsAuth: `${url}/hotel/rooms/auth/${language}/${country}`,
  hotelDetailsAuth: `${url}/hotel/info/auth/${language}/${country}`,
  getHotelCouponNoAuth: `${url}/coupon/hotel/noAuth/${language}/${country}`,
  getHotelCouponAuth: `${url}/coupon/hotel/auth/${language}/${country}`,
  validateNoAuthCoupon: `${url}/coupon/validity/noAuth/${language}/${country}`,
  validateAuthCoupon: `${url}/coupon/validity/auth/${language}/${country}`,
  getFlightCouponsNoAuth: `${url}/coupon/flight/noAuth/${language}/${country}`,
  getFlightCouponsAuth: `${url}/coupon/flight/auth/${language}/${country}`,
  getRecentSearches: `${url}/searches/recent/${language}/${country}`,
  getRecentSearchesNoAuth: `${url}/searches/recent/noAuth/${language}/${country}`,
  getAirport: `${url}/airport/${language}/${country}`,
  getAirportByAirportCode: `${url}/airport/${language}/${country}`,
  validateCoupon: `${url}/coupon/validity/noAuth/${language}/${country}`,
  validateCouponAuth: `${url}/coupon/validity/auth/${language}/${country}`,
  applyCoupon: `${url}/coupon/apply/${language}/${country}`,
  getPrivacyPolicyAuth: `${url}/policy/auth/${language}/${country}`,
  getPrivacyPolicyNoAuth: `${url}/policy/noAuth/${language}/${country}`,
  getUserBookings: `${url}/bookings/${language}/${country}`,
  getTermsAndConditionsNOAuth: `${url}/termsConditions/noAuth/${language}/${country}?skip=0&limit=50`,
  getTermsAndConditionsAuth: `${url}/termsConditions/auth/${language}/${country}?skip=0&limit=50`,
  cancelHotel: `${url}/hotel/booking/cancel/${language}/${country}`,
  reissueHotel: `${url}/hotel/booking/reissue/${language}/${country}`,
  cancelFlight: `${url}/flight/booking/cancel/${language}/${country}`,
  sendTicketOrVoucherToMail: `${url}/auth/email/ticket/${language}/${country}`,
  releasePNR: `${url}/flight/booking/releasePNR/${language}/${country}`,
  addDetailsFlightAuth: `${url}/flight/personal-details/auth/${language}/${country}`,
  addDetailsFlightNoAuth: `${url}/flight/personal-details/noAuth/${language}/${country}/`,
  addDetailsHotelAuth: `${url}/hotel/personal-details/auth/${language}/${country}`,
  addDetailsHotelNoAuth: `${url}/hotel/personal-details/noAuth/${language}/${country}`,
  getStrings: `${url}/getStrings`,
  getAboutUsNOAuth: `${url}/aboutus/noAuth/${language}/${country}?skip=0&limit=50`,
  getAboutUsAuth: `${url}/aboutus/auth/${language}/${country}?skip=0&limit=50`,
  getTicket: `${url}/flight/booking/ticket`,
  reissueFlight: `${url}/flight/booking/reissue/${language}/${country}`,
  resendVerificationLink: `${url}/sendVerificationEmail/${language}/${country}`,
  verifyEmail: `${url}/verifyEmail/${language}/${country}`,
  paymentInitiate: `${url}/hyperpay/${language}/${country}`,
  paymentStatus: `${url}/payment/status/${language}/${country}`,
};

export default APIs;
