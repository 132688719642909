import { makeStyles } from "@mui/styles";
import bookingConfirmationTop from "../../../assets/images/booking-confirmation-top.svg";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "0px 150px",
    backgroundColor: "#FAFAFA",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
  },
  bookingConfirmedText: {
    fontFamily: "Lato",
    fontSize: "40px",
    fontWeight: 700,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
  bookingConfirmedLogoAndTextContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${bookingConfirmationTop})`,
    height: "234px",
  },
  tableHeaderCell: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "bolder",
  },
  tableDataCell: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
  },

  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    overflow: "scroll",
  },
  guestDetailsContainer: {
    display: "flex",
    padding: "16px",
    alignItems: "flex-start",
    gap: "71px",
    background: "#F4F4F4",
  },
  guestDetailText: {
    color: " #344054",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  table: {
    overflow: "scroll",
  },
  bookingIdAndbookDateContainer: {
    // background: "#FFFFFF",
    margin: "20px 0",
  },
  hotelBookinghasbeenCompletedText: {
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  bookingIdAndDate: {
    display: "flex",
    justifyContent: "space-between",
  },
  paymentGreyContainer: {
    display: "flex",
    padding: "16px",
    alignItems: "flex-start",
    gap: "71px",
    background: " #F4F4F4",
  },
  margin20: {
    margin: "20px 0px ",
  },
  errorContainer: {
    width: "50%",
    margin: "0 auto",
    marginBottom: "30px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "20px 15px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  paymentCompletedText: {
    color: "#1DAC08",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    // marginRight: "20px",
    outline: "none",
    border: "none",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  bookingIdText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  printDownloadAndShareButtons: {
    display: 'flex',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column'
    }
  },
  printShareDownloadButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "86px",
    height: "41px",
    borderRadius: "10px",
    border: "1px solid #1B1D52",
    padding: "12px 16px 12px 16px",
    gap: "8px",
    color: "#1B1D52",
    background: "white",
    borderColor: "#1B1D52",
  },
  printDownlodSharebuttonsContainer:{
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
    paddingBottom: "30px",
    [theme.breakpoints.down('sm')]:{
      justifyContent: 'flex-start'
    }
  }

}));

export default useStyles;
