import React, { useEffect, useState } from "react";
import { HotelListingView } from "../../views/HotelListing/HotelListingView";
import { get, orderBy, values, map, filter } from "lodash";
import moment from "moment";
import { searchHotels } from "../../../modules/hotels";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMinMaxPrice } from "../../../utils/slices/localFiltersSlice";
import axios from "axios";
import { formatSARCurrency } from "../../../utils/helpers";
import { changeHotelSearchPageSearchGoingOn } from "../../../utils/slices/hotelSearchingSlice";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { flightValue, hotelValue, moduleValue } from "../../../config";
import CryptoJS from "crypto-js";
import { handleDecrypt } from "../../../components/flight-module/search-flight/components/helper";

const HotelListingContainer = (props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [ipAddress, setIpAddress] = useState("");
  const uuid = localStorage.getItem("uuid");
  const [networkState, setNetworkState] = useState({
    isLoading: true,
    data: {},
    initialApiData: [],
    filteredData: [],
    minMaxPrice: [],
    markup: {},
    error: false,
  });

  const dispatch = useDispatch();
  // const hotelFilters = get(props, "globalFilters.hotelFilters", {});
  const hotelFilters = get(props, "globalFilters", {});
  const searchURL = get(props, "searchURL", "");

  let hotelCheckInDate = get(hotelFilters, "hotelCheckInDate", "");
  let hotelCheckOuDate = get(hotelFilters, "hotelCheckoutDate", "");
  if (hotelCheckInDate) {
    const parts = hotelCheckInDate.split("/");
    if (parts.length === 3) {
      hotelCheckInDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
  }

  if (hotelCheckOuDate) {
    const parts = hotelCheckOuDate.split("/");
    if (parts.length === 3) {
      hotelCheckOuDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    }
  }
  const hotelCityObj = get(hotelFilters, "hotelCity", {});
  const sharedUUId = get(hotelFilters, "sharedUUId", "");
  const [nights, setNights] = useState(1);
  const setShowFilters = props?.setShowFilters;
  const setModifySearchEnabled = props?.setModifySearchEnabled;
  const showFilters = props?.showFilters;
  const modifySearchEnabled = props?.modifySearchEnabled;
  // showFilters={showFilters}
  //           modifySearchEnabled={modifySearchEnabled}
  const hotelLocalFilters = useSelector(
    (state) => state.localFilters.hotelLocalFilters
  );
  const filterHotelByName = useSelector(
    (state) => state.localFilters.filterHotelByName
  );
  const priceFilter = useSelector((state) => state.localFilters.priceFilter);
  const user = useSelector((state) => state.user);
  const handleEncrypt = (payloadData) => {
    try {
      const payload = JSON.stringify(payloadData);
      const REACT_APP_SECRETKEY = moduleValue + flightValue + hotelValue + "e3n";
      const encryptedData = CryptoJS.AES.encrypt(payload, REACT_APP_SECRETKEY, {
        mode: CryptoJS.mode.ECB,
      }).toString();
      return encryptedData;
    } catch (error) {
      console.error("Encryption error:", error);
      return null;
    }
  };
  const handleCardClick = async (payload) => {
    const { hotelCode, _hotel, searchId, markup_id } = payload;
    const encryptedHotelData = handleEncrypt(_hotel);
    localStorage.setItem("roomDetails", JSON.stringify(_hotel));
    if (isMobile) {
      navigate(
        `/hotel/details?hotelCode=${hotelCode}&hotelFilters=${JSON.stringify(
          hotelFilters
        )}
        &sharedUUId=${uuid}&searchId=${searchId}&mi=${markup_id}`
      );
    } else {
      window.open(
        `/hotel/details?hotelCode=${hotelCode}&hotelFilters=${JSON.stringify(
          hotelFilters
        )}
        &sharedUUId=${uuid}&searchId=${searchId}&mi=${markup_id}`,
        "_blank"
      );
    }

    localStorage.setItem("hotelName", payload.hotelName);
  };
  const priceRange = useSelector((state) => state.localFilters.minMaxPrice);
  const token = useSelector((state) => state.user?.token);
  const nationality = useSelector((state) => state.locale.nationality);
  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIpAddress(response.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, []);
  const getPayload = () => {
    const totalGuests = hotelFilters?.totalGuests;
    const roomGuests = totalGuests?.map((room) => {
      return {
        adults: room?.adultCounter,
        children: room?.childrenCounter,
        childrenAges: room?.childrenAges,
      };
    });

    const rooms = totalGuests?.length;

    const date1 = moment(hotelCheckInDate);
    const date2 = moment(hotelCheckOuDate);

    const noOfNights = date2.diff(date1, "days");
    setNights(noOfNights);

    return {
      checkIn: hotelCheckInDate,
      checkOut: hotelCheckOuDate,
      noOfNights: noOfNights,
      countryCode: get(hotelCityObj, "country", "IN"),
      cityCode: get(hotelCityObj, "cityId", 0),
      guestNationality: nationality,
      responseTime: 25,
      isDetailedResponse: true,
      filters: {
        refundable: true,
        noOfRooms: 2,
        mealType: "All",
      },
      paxRooms: roomGuests,
      additionalDetails: {
        ipAddress: ipAddress,
        deviceType: "WEB",
        browserType: "CHROME",
        coordinates: [0, 0],
        uuid: uuid ? uuid : sharedUUId,
      },
    };
  };
  const getMarkup = (markupDetails, publishPrice, tax) => {
    let value = 0;

    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      // amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amount * publishPrice - tax) / 100;
      } else {
        amt = (amount * publishPrice) / 100;
      }
      amt = maxAmount < amt ? maxAmount : amt;
    }
    // value = value < amt ? amt : value;
    return amt;
    // setMarkupPrice(Math.ceil(amt));
  };
  const fetchData = async () => {
    // setNetworkState((prev) => ({ ...prev, isLoading: true }));
    dispatch(changeHotelSearchPageSearchGoingOn(true));
    if (showFilters) {
      setShowFilters(false);
    }

    if (modifySearchEnabled) {
      setModifySearchEnabled(false);
    }
    const response = await searchHotels(getPayload(), user?.token);
    const searchId = response?._id;
    setNetworkState((prev) => ({
      ...prev,
      isLoading: false,
      data: response?.searchResponse,
      filteredData: response?.searchResponse,
      initialApiData: response?.searchResponse,
      minMaxPrice: values(response?.filterResponse),
      markup: response?.searchResponse?.results?.markup,
      searchId: response?._id,
    }));
    setShowFilters(true);
    setModifySearchEnabled(true);

    dispatch(
      getMinMaxPrice({
        minPrice: response?.filterResponse?.minbaseFare,
        maxPrice: response?.filterResponse?.maxbaseFare,
      })
    );
    dispatch(changeHotelSearchPageSearchGoingOn(false));
  };

  useEffect(() => {
    setNetworkState((prev) => {
      return { ...prev, isLoading: true };
    });
    fetchData();
  }, [searchURL]);

  useEffect(() => {
    sortByPriceAscending();
  }, [priceFilter.value]);

  const sortByPriceAscending = () => {
    if (priceFilter.value === "desc") {
      const sortedFilteredDescData = orderBy(
        networkState.filteredData,
        ["leastTotalFare"],
        ["desc"]
      );
      setNetworkState((prev) => ({
        ...prev,
        filteredData: sortedFilteredDescData,
      }));
    } else if (priceFilter.value === "asc") {
      const sortedFilteredAscecData = orderBy(
        networkState.filteredData,
        ["leastTotalFare"],
        ["asc"]
      );

      setNetworkState((prev) => ({
        ...prev,
        filteredData: sortedFilteredAscecData,
      }));
    } else if (priceFilter.value === "starRatingHighest") {
      const sortedFilteredStarData = orderBy(
        networkState.filteredData,
        ["starRating"],
        ["desc"]
      );
      setNetworkState((prev) => ({
        ...prev,
        filteredData: sortedFilteredStarData,
      }));
    } else if (priceFilter.value === "starRatingLowest") {
      const sortedFilteredStarData = orderBy(networkState.filteredData, [
        "starRating",
      ]);
      setNetworkState((prev) => ({
        ...prev,
        filteredData: sortedFilteredStarData,
      }));
    } else {
      setNetworkState((prev) => ({
        ...prev,
        filteredData: get(prev, "data", []),
      }));
    }
  };

  const sortAfterFilter = (filteredData) => {
    if (priceFilter.value === "desc") {
      const sortedFilteredDescData = orderBy(
        filteredData,
        ["leastTotalFare"],
        ["desc"]
      );
      
      return sortedFilteredDescData
    } else if (priceFilter.value === "asc") {
      const sortedFilteredAscecData = orderBy(
        filteredData,
        ["leastTotalFare"],
        ["asc"]
      );

      return sortedFilteredAscecData
    
    } else if (priceFilter.value === "starRatingHighest") {
      const sortedFilteredStarData = orderBy(
        filteredData,
        ["starRating"],
        ["desc"]
      );
      return sortedFilteredStarData
    } else if (priceFilter.value === "starRatingLowest") {
      const sortedFilteredStarData = orderBy(
        filteredData, 
        ["starRating",]
      );
      
      return sortedFilteredStarData
    } else {
      return filteredData
    }
  };

  const handleHotelByPrice = () => {
    let filteredHotelsAll = networkState.data;
    
    if (priceRange.currentPrice && priceRange.maxPrice != priceRange.currentPrice) {
      const priceRangeFilter = filter(networkState.data, (_hotel) => {
        const markup = get(_hotel, "markup", "");
        const Rooms = get(_hotel, "Rooms", []);
        let leastTotalFare;
        let leastTax;
        Rooms.map((room) => {
          const totalFare = room?.TotalFare;
          const totalTax = room?.TotalTax;
          if (leastTotalFare && totalFare && totalFare < leastTotalFare) {
            leastTotalFare = totalFare;
            leastTax = totalTax;
          } else if (!leastTotalFare && totalFare) {
            leastTotalFare = totalFare;
            leastTax = totalTax;
          }
        });
        let publishPrice = leastTotalFare;
        const taxes = leastTax;
        let BasePrice = publishPrice - taxes;
        if (markup) {
          let amt = 0;
          const { amount, amountType, appliedOn, maxAmount } = markup;
          if (amountType === "FLAT") {
            amt = amount < maxAmount ? amount : maxAmount;
          } else {
            // amt = amount < maxAmount ? amount : maxAmount;
            if (appliedOn === "BASEFARE") {
              amt = (amount * BasePrice) / 100;
            } else {
              amt = (amount * publishPrice) / 100;
            }
            amt = maxAmount < amt ? maxAmount : amt;
          }
          publishPrice += amt;
          BasePrice = publishPrice - taxes;
        }

        _hotel.leastTotalFare = leastTotalFare
        
        if (
          BasePrice >= priceRange.currentPrice[0] &&
          BasePrice <= priceRange.currentPrice[1]
        ) {
          return _hotel;
        }
      });

      filteredHotelsAll = priceRangeFilter;
    }

    if (hotelLocalFilters.length > 0) {
      const filterStarRating = filteredHotelsAll;
      const filteredHotels = [];

      filterStarRating?.map((hotels) => {
        hotelLocalFilters?.map((starRating) => {
          if (starRating == "5") {
            starRating = "FiveStar";
          } else if (starRating == "4") {
            starRating = "FourStar";
          } else if (starRating == "3") {
            starRating = "ThreeStar";
          } else if (starRating == "2") {
            starRating = "TwoStar";
          } else if (starRating == "1") {
            starRating = "OneStar";
          }

          if (starRating == hotels.HotelRating) {
            filteredHotels.push(hotels);
          }
        });
      });

      filteredHotelsAll = filteredHotels;
    }

    if (filterHotelByName) {
      const filterName = filteredHotelsAll.filter((filterByName) =>
        filterByName?.HotelName?.toLowerCase().includes(
          filterHotelByName.toLowerCase()
        )
      );

      filteredHotelsAll = filterName;
    }

    filteredHotelsAll = sortAfterFilter(filteredHotelsAll)

    setNetworkState((prev) => ({
      ...prev,
      filteredData: filteredHotelsAll,
    }));
  };

  useEffect(() => {
    if (!networkState.isLoading) {
      handleHotelByPrice();
    }
  }, [priceRange.currentPrice, filterHotelByName, hotelLocalFilters]);

  const results = networkState?.filteredData?.results;
  if (results?.length > 0) {
    setModifySearchEnabled(true);
    setShowFilters(true);
  } else if (!networkState?.filteredData) {
    setModifySearchEnabled(true);
  }
  console.log("networkState", networkState);
  console.log('priceFilter', priceFilter)
  return (
    <HotelListingView
      {...props}
      state={{ networkState }}
      handlers={{ handleCardClick }}
    />
  );
};

export default HotelListingContainer;
