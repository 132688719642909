import React, { useState } from "react";
import useStyles from "./style";
import arrowBack from "../../../assets/images/arrow-left.svg";
import { ReactSVG } from "react-svg";
import calendarIcon from "../../../assets/images/calendar-modify.svg";
import personIcon from "../../../assets/images/modify-profile.svg";
import editIcon from "../../../assets/images/edit-icon.svg";
import { useSelector } from "react-redux";
import { get, map, sum } from "lodash";
import moment from "moment";
import eventBus from "../../../core/eventBus";
import { MobileHotelFilterView } from "../../views/MobileHotelFilter/MobileHotelFilterView";
import { useLocation, useNavigate } from "react-router-dom";
import MobileHotelModifyWIdget from "../../views/modifySearchWidgetHotelMobile";
import SearchWidgetMobileModify from "../../../components/flight-module/search-flight/components/cards/modify-search-mobile";
import OneWaySvg from "../../../assets/images/arrow-one-way.svg";
import RoundWaySvg from "../../../assets/images/arrow-round-trip.svg";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { formateDateToDayAndDate } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const ModifySearchFlightMobile = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const {t}  = useTranslation()

  const {
    flightTravelType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
    flightTotalTravellers: {
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
    flightClassType,
    multiCityDates,
  } = useSelector((state) => state.searchFlightQuery);

  const { loading } = useSelector((state) => state.modifySearchFlight);
  const [showModifySearch, setShowModifySearch] = useState(false);

  const { indexOfMultiCity } = useSelector(
    (state) => state.multiCityTripDetails
  );

  function formatDate(dateString) {
    if (dateString) {
      const date = moment(dateString, "DD/MM/YYYY");
      return date.format("D MMM");
    }
  }
  const formattedDepartureDate = formatDate(flightDepartureDate);
  const formattedReturnDate = formatDate(flightReturnDate);
  const handleOpenHotelSearchWidget = () => {
    eventBus.emit(
      "open-bottomSheet",
      <div style={{ padding: "14px", height: "560px" }}>
        <SearchWidgetMobileModify />
      </div>
    );
  };
  return (
    <div className={styles.modifySearchHotelMobileContainer}>
      <div onClick={() => navigate("/")}>
        <ReactSVG src={arrowBack} />
      </div>

      <div className={styles.modifySearchHotelDetailsContainer}>
        <div className={styles.cityContainer}>
          <p className={styles.cityName}>
            {flightFrom?.[indexOfMultiCity].code}{" "}
          </p>
          {flightTravelType == 2 ? <CompareArrowsIcon /> : "-"}
          <p className={styles.cityName}>{flightTo?.[indexOfMultiCity].code}</p>
        </div>
        <div className={styles.roomDetails}>
          <div className={styles.checkInCheckoutDate}>
            <ReactSVG src={calendarIcon} />
            {flightTravelType == 1 && (
              <div className={styles.dates}>{flightDepartureDate}</div>
            )}
            {flightTravelType == 2 && (
              <div className={styles.dates}>
                {formattedDepartureDate} - {formattedReturnDate}
              </div>
            )}
            {flightTravelType == 3 && (
              <div className={styles.dates}>
                {indexOfMultiCity !== 0
                  ? formateDateToDayAndDate(
                      get(multiCityDates, `[${indexOfMultiCity - 1}].date`, "")
                    )
                  : formateDateToDayAndDate(flightDepartureDate)}
              </div>
            )}
          </div>
          <div className={styles.guestDetails}>
            <ReactSVG src={personIcon} />
            <div className={styles.guestNumber}>
              {flightAdultsCount}{t("codes.adultCode")}
              {flightChildrenCount > 0 && <>,{flightChildrenCount} {t("codes.childrenCode")}</>}
              {flightInfantsCount > 0 && <>,{flightInfantsCount} {t("codes.infantCode")}</>}
            </div>
            {flightClassType == 1 && (
              <div className={styles.guestNumber}>- {t("flightTravelClasses.economy")}</div>
            )}
            {flightClassType == 2 && (
              <div className={styles.guestNumber}>- {t("flightTravelClasses.economy")}</div>
            )}
            {flightClassType == 3 && (
              <div className={styles.guestNumber}>-{t("flightTravelClasses.premiumEconomy")}</div>
            )}
            {flightClassType == 4 && (
              <div className={styles.guestNumber}>- {t("flightTravelClasses.bussiness")}</div>
            )}
            {flightClassType == 5 && (
              <div className={styles.guestNumber}>- {t("flightTravelClasses.premiumBussiness")}</div>
            )}
            {flightClassType == 6 && (
              <div className={styles.guestNumber}>- {t("flightTravelClasses.firstClass")}</div>
            )}
          </div>
        </div>
      </div>
      {path == "/flight/search" && !loading && (
        <div onClick={handleOpenHotelSearchWidget}>
          <ReactSVG src={editIcon} />
        </div>
      )}
    </div>
  );
};

export default ModifySearchFlightMobile;
