import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mobileHotelFilter: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
  },
  headerText: {
    color: "var(--heading, #344054)",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    textTransform: "capitalize",
    flex: "1 0 0",
  },
  stayNights: {
    backgroundColor: '#E6E6FA',
    borderRadius: '1.2rem',
    padding: '0.3rem 0.9rem',
    height: '2.5rem',
    width: '8rem',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  }
}));

export default useStyles;
