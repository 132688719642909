import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  roomDetails: {
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    padding: "24px 16px",
    borderRadius: "8px",
  },
  roomDetailsBody: {
    display: "flex",
    columnGap: "32px",
  },
  roomDetailsImage: {
    width: "370px",
    height: "245px",
    borderRadius: "16px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  roomDetailsName: {
    fontSize: "16px",
    fontWeight: 700,
    width: "70%",
    minWidth: "100px",
  },
  roomDetailsDescription: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    paddingTop: "24px",
  },
  roomDetailsBullet: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },
  roomDetailsBulletWrapper: {
    display: "flex",
    columnGap: "8px",
  },
  roomDetailsAmenitiesWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  roomDetailsAmenities: {
    display: "flex",
    width: "fit-content",
    minWidth: "100px",
    justifyContent: "flex-start",
    columnGap: "160px",
  },
}));

export default useStyles;
