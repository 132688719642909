import { useState, useEffect } from "react";

function useIsTablet() {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    // Function to check if the screen width is less than a certain threshold
    const checkIsTablet = () => {
      setIsTablet(window.innerWidth <= 1024); // You can adjust the threshold as needed
    };

    // Initial check when the component mounts
    checkIsTablet();

    // Add an event listener to check when the window is resized
    window.addEventListener("resize", checkIsTablet);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsTablet);
    };
  }, []);

  return isTablet;
}

export default useIsTablet;
