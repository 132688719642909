import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import TravelDate from "../cards/travel-date";
import closeSvg from "../../../../../../../../assets/images/x-close.svg";
import SearchAirport from "../../flight-search-widget/dropdowns/search-airport";
import {
  handleMultiCityDates,
  removeError,
  updateModifyAnotherCity,
  updateModifyFlightFrom,
  updateModifyFlightTo,
} from "../../../../../../../../utils/slices/modifySearchFlightSlice";
import "./style.css";
import { get, find } from "lodash";
import {
  formatParsedDate,
  formatParsedDay,
} from "../../../../../../../../utils/helpers";
import Error from "../../../../../../error";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
const MultiCity = ({
  setShowCalendar,
  handleDateChange,
  datesList,
  setShowSourceOptions,
  setShowDestinationOptions,
  showSourceOptions,
  showDestinationOptions,
  setShowFlightClassDropdown,
  setShowPassengersComponent,
}) => {
  const { t } = useTranslation();
  const title = `${t("flightSearchWidget.departureDate")}`;
  const metaTitle = `${t("flightSearchWidget.selectDepartureDate")}`;

  const {
    flightTo,
    multiCityDates,
    errors,
    flightDepartureDate,
    totalSelectionForMultiCity,
  } = useSelector((state) => state.modifySearchFlight);

  const dispatch = useDispatch();
  const [multiCity, setMultiCity] = useState(
    totalSelectionForMultiCity == 3 ? [1, 2] : [1]
  );
  const flightDepartureDateParts = flightDepartureDate.split("/");
  const [firstDate] = multiCityDates.filter((date) => date.key === 1);
  const firstDateParts = firstDate?.date.split("/");
  const styles = useStyles();
  return (
    <>
      {multiCity.map((key) => {
        const dateObj = find(datesList, (_d) => _d.key == key);
        const dateValue = get(dateObj, "date", "");
        return (
          <div className="multi-city-content2" key={key}>
            <div className={styles.multiCitySearch}>
              <SearchAirport
                searchAirportId={key}
                setShowSourceOptions={setShowSourceOptions}
                setShowDestinationOptions={setShowDestinationOptions}
                showSourceOptions={showSourceOptions}
                showDestinationOptions={showDestinationOptions}
              />
              {key === 1 &&
                errors &&
                errors
                  .filter((error) => error.id === "multi-city-2-city")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
            </div>
            <div className={styles.multiCityTravelDate}>
              <div style={{ height: "112px" }}>
                <TravelDate
                  title={title}
                  date={dateValue ? formatParsedDate(dateValue) : ""}
                  day={dateValue ? formatParsedDay(dateValue) : ""}
                  minDate={
                    key == 1
                      ? flightDepartureDateParts &&
                        new Date(
                          parseInt(flightDepartureDateParts[2], 10),
                          parseInt(flightDepartureDateParts[1], 10) - 1,
                          parseInt(flightDepartureDateParts[0], 10)
                        )
                      : key == 2
                      ? firstDateParts
                        ? new Date(
                            parseInt(firstDateParts[2], 10),
                            parseInt(firstDateParts[1], 10) - 1,
                            parseInt(firstDateParts[0], 10)
                          )
                        : flightDepartureDateParts &&
                          new Date(
                            parseInt(flightDepartureDateParts[2], 10),
                            parseInt(flightDepartureDateParts[1], 10) - 1,
                            parseInt(flightDepartureDateParts[0], 10)
                          )
                      : ""
                  }
                  metaTitle={metaTitle}
                  setShowCalendar={setShowCalendar}
                  value={dateValue}
                  onDateChange={(date) => handleDateChange(date, key)}
                  setShowSourceOptions={setShowSourceOptions}
                  setShowDestinationOptions={setShowDestinationOptions}
                  setShowFlightClassDropdown={setShowFlightClassDropdown}
                  setShowPassengersComponent={setShowPassengersComponent}
                />
              </div>
              {key === 1 &&
                errors &&
                errors
                  .filter((error) => error.id === "multi-city-1")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
              {key === 2 &&
                errors &&
                errors
                  .filter((error) => error.id === "multi-city-2")
                  .map((error) => {
                    const { id, errorMessage } = error;
                    return <Error key={id} errorMessage={errorMessage} />;
                  })}
            </div>
            <div style={{ flex: 2.6, display: "flex" }}>
              {key === 1 ? (
                multiCity?.length === 1 && (
                  <div
                    className="multi-city-add-another-city"
                    onClick={() => {
                      setMultiCity([1, 2]);
                      dispatch(updateModifyAnotherCity(true));
                      flightTo.length > key &&
                        dispatch(
                          updateModifyFlightFrom({ id: 2, data: flightTo[1] })
                        );
                    }}
                  >
                    <div className="another-city-text">
                      <span className="plus-icon">+</span>{" "}
                      {t("flightSearchWidget.addAnotherCity")}
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div
                    className={
                      localStorage.getItem("language") == "AR"
                        ? "multi-city-dividers-Arabic"
                        : "multi-city-dividers"
                    }
                  ></div>
                  <div
                    className={
                      localStorage.getItem("language") == "AR"
                        ? "multi-city-cross-icon-Arabic"
                        : "multi-city-cross-icon"
                    }
                    onClick={() => {
                      const multicityDatesLocal =
                        multiCityDates.length === 0
                          ? []
                          : multiCityDates[0].key === 1
                          ? [multiCityDates[0]]
                          : [];
                      setMultiCity([1]);
                      dispatch(updateModifyAnotherCity(false));
                      dispatch(updateModifyFlightFrom({ id: 2, data: {} }));
                      dispatch(updateModifyFlightTo({ id: 2, data: {} }));
                      dispatch(handleMultiCityDates(multicityDatesLocal));
                      dispatch(removeError({ id: 2 }));
                      dispatch(removeError({ id: "multi-city-2" }));
                      const modifySearchFlightDetails = JSON.parse(
                        window.localStorage.getItem("modifySearchFlightDetails")
                      );
                      if (modifySearchFlightDetails) {
                        modifySearchFlightDetails.multiCityDates =
                          multicityDatesLocal;
                        window.localStorage.setItem(
                          "modifySearchFlightDetails",
                          JSON.stringify(modifySearchFlightDetails)
                        );
                      }
                    }}
                  >
                    <ReactSVG src={closeSvg} />
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MultiCity;

MultiCity.propTypes = {
  setShowCalendar: PropTypes.func,
  showCalendar: PropTypes.bool,
  handleDateChange: PropTypes.func,
  datesList: PropTypes.array,
};
