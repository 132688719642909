import React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { chunk, get, split } from "lodash";
import useStyles from "./styles";
import roomImage from "../../../assets/images/room_image.jpg";
import "./style.css";
import { Divider } from "@mui/material";

const RoomPrice = ({ price }) => {
  const { body, value, header, included } = price;
  return (
    <Grid item xs={3} style={{ margin: "15px" }}>
      <div className="price-box">
        <div>
          <h3>{header}</h3>
        </div>
        <div className="price-body">{body}</div>
        <div className="price-value">{value}</div>
        <div className="price-footer">{included}</div>
        <div>
          <Button variant="contained" style={{ width: "100%" }}>
            Book Now
          </Button>
        </div>
      </div>
    </Grid>
  );
};

const RoomDetail = (props) => {
  const styles = useStyles();

  const roomDescription = get(props, "details.RoomDescription", "");
  const [roomName, ...restDetails] = split(roomDescription, ",");
  let amenities = get(props, "details.Amenities", []);
  if (amenities.length === 1) {
    amenities = split(amenities[0], ",");
  }
  const [amenities1] = chunk(amenities, 3);
  return (
    <div className={styles.roomDetails}>
      <div className={styles.roomDetailsBody}>
        <img src={roomImage} className={styles.roomDetailsImage} />
        <div className={styles.roomDetailsDescription}>
          <h2 className={styles.roomDetailsName}>{roomName}</h2>
          <span className={styles.roomDetailsBulletWrapper}>
            {restDetails.map((detail, index) => {
              return (
                <span key={index} className={styles.roomDetailsBullet}>
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: "black",
                    }}
                  />
                  <p key={index}>{detail}</p>
                </span>
              );
            })}
          </span>
          <Divider />
          <div className={styles.roomDetailsAmenitiesWrapper}>
            <div className={styles.roomDetailsAmenities}>
              {amenities1.map((_amenity) => {
                return <span key={_amenity}>{_amenity}</span>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RoomDetail.propTypes = {
  hotel: PropTypes.any,
};

RoomPrice.propTypes = {
  price: PropTypes.any,
};

export default RoomDetail;
