import React, { useState } from "react";
import { InputAdornment, Modal, TextField } from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { signupIn } from "../../../utils/validations/signup";
// import {signupIn} from '../../'
import { signupIn } from "../../../../../utils/validations/signup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
// import {
//   setSignupPopup,
//   setLoginPopup,
// } from "../../../utils/slices/popupSlice";
import PropTypes from "prop-types";
import "./style.css";
// import Login from "../../Login";
import { MobileOtpEnter } from "../../mobile-otp-enter";
// import eventBus from "../../../core/eventBus";
import eventBus from "../../../../../core/eventBus";
// import useIsMobile from "../../../hooks/useIsMobile.hook";
import useIsMobile from "../../../../../hooks/useIsMobile.hook";
import Signup from "..";
import { setLoginPopup } from "../../../../../utils/slices/popupSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";

const SignupForm = ({ submitForm, redirection }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  // const { signup } = useSelector((state) => state.popup);
  const [openRegistration, setRegistration] = useState(true);
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const userDetails =
    window.localStorage &&
    JSON.parse(window.localStorage.getItem("userDetails"));
  const phoneNo = userDetails ? userDetails.phoneNo : "";

  const initialValues = {
    email: "",
    number: phoneNo,
    firstName: "",
    lastName: "",
    password: ""
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mobileSignupMainContainer">
      <div
        className="card-signup-secondary-mobile" >
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          validationSchema={signupIn(t)}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
            } = formik;
            return (
              <>
                <div className="card-title">{t("signup.registrationTitle")}</div>
                <div className="card-meta-title">
                  {t("signup.registrationMetaTitle")}
                </div>
                <form onSubmit={handleSubmit}>

                  <div className="input-title-signup">
                    {t("signup.InputTitleFirstName")}
                  </div>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("signup.placeholderFirstName")}
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-field"
                  />
                  {errors.firstName && touched.firstName && (
                    <span className="error">{errors.firstName}</span>
                  )}
                  <div className="input-title-signup">
                    {t("signup.InputTitleLastName")}
                  </div>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("signup.placeholderLastName")}
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-field"
                  />
                  {errors.lastName && touched.lastName && (
                    <span className="error">{errors.lastName}</span>
                  )}
                  <div className="input-title-signup">
                    {t("signup.InputTitleMobile")}
                  </div>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("signup.placeholderMobile")}
                    name="number"
                    value={values.number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-field"
                  />
                  {errors.number && touched.number && (
                    <span className="error">{errors.number}</span>
                  )}
                  <div className="input-title-signup">
                    {t("signup.InputTitleEmailId")}
                  </div>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("signup.placeholderEmailId")}
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-field"
                  />
                  {errors.email && touched.email && (
                    <span className="error">{errors.email}</span>
                  )}
                  <div className="input-title-signup">
                    {t("signup.InputTitlePassword")}
                  </div>
                  <TextField
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    password={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <VisibilityIcon
                              onClick={() => setShowPassword(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() => setShowPassword(true)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("signup.placeholderPassword")}
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-field"
                  />
                  {errors.password && touched.password && (
                    <span className="error">{errors.password}</span>
                  )}
                  <div className="input-title-signup">
                    {t("signup.InputTitleConfirmPassword")}
                  </div>
                  <TextField
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    password={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmPassword ? (
                            <VisibilityIcon
                              onClick={() => setShowConfirmPassword(false)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() => setShowConfirmPassword(true)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("signup.placeholderPassword")}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text-field"
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <span className="error">{errors.confirmPassword}</span>
                  )}
                  <button className="card-button-register" type="submit">
                    {t("signup.registerButton")}
                  </button>
                  <div
                    className="card-bottom-title"
                    // onClick={() => navigate("/login")}
                    onClick={() => {
                      // eventBus.emit("close-dialog", <Signup />);
                      // dispatch(setLoginPopup(true));
                      navigate('/mobile/login', {
                        state: {
                          redirection: redirection
                        }
                      })
                    }}
                  >
                    <ArrowBackIcon
                      className="login-register"
                      style={{ marginRight: "3px", fontSize: "14px" }}
                    />
                    <span style={{ width: "100px" }} className="login-register">
                      {t("signup.backToLoginButton")}
                    </span>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>

  );
};

export default SignupForm;

SignupForm.propTypes = {
  submitForm: PropTypes.func,
};
