import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  offerHome: {
    background: " #FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "70px 12px",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "70px 125px",
    },
  },
  container: {
    background: " #FAFAFA",
    display: "flex",
    flexDirection: "column",
    padding: "20px 150px",
    alignSelf: "flex-start",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 1px 0px 5px",
    },
    border: "0px solid #000000",
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      background: "#FAFAFA",
    },
  },
  subContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "1434px",
    },
  },
  innerContainer: {
    flexDirection: "column",
    width: "100%",
  },

  offerCard: {
    // width: "370px",
    gap: "15px",
    marginTop: "15px",
    background: "white",
    borderRadius: "5px",
    // cursor: "pointer",
    border: "0px solid #000000",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // [theme.breakpoints.up("md")]: {
    //   width: "330px",
    // },

    // [theme.breakpoints.up("lg")]: {
    //   width: "380px",
    // },
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },

  cardContent: {
    display: "flex",
    // width: "338px",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  offerCode: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      width: "14px",
    },
  },

  viewDetails: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    cursor: "pointer",
    textAlign: "center",
  },

  offerCodeLayout: {
    marginTop: "10px",
    display: "inline-flex",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    backgroundColor: "#E9F5FF",
    border: "1px dashed #08F",
    padding: "4px 8px",
  },

  moreOfferContainer: {
    // display: "flex",
    // flexWrap: "wrap",

    gap: "24px",
    // alignItems: "center",
    width: "100%",
    padding: "20px 150px",
    // justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 50px 20px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px !important",
      display: "flex",
      flexDirection: "column",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3,minmax(200px,1fr))",
      display: "grid",
      gridTemplateRows: "1fr",
    },
  },

  couponContentsContainer: {
    display: "flex",
    padding: "16px 16px 24px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    borderRadius: "0px 0px 5px 5px",
    borderRight: "1px solid #D0D5DD",
    borderBottom: "1px solid #D0D5DD",
    borderLeft: "1px solid #D0D5DD",

    // [theme.breakpoints.up("md")]: {
    //   width: "370px",
    // },
  },
}));

export default useStyles;
