import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  FlightLand,
  FlightTakeoff,
  LocationCity,
  SwapVert,
} from "@mui/icons-material";
import { get, isEmpty, map, noop } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import eventBus from "../../../../../core/eventBus";
import { getAirports } from "../../../../../modules/flight";
import { getHotelCities } from "../../../../../modules/hotels";
import {
  updateFlightFrom,
  updateFlightTo,
} from "../../../../../utils/slices/searchFlightSlice";
import {
  updateModifyFlightFrom,
  updateModifyFlightTo,
} from "../../../../../utils/slices/modifySearchFlightSlice";
import { updateHotelCity } from "../../../../../utils/slices/searchHotelSlice";

const CityOptionsList = (props) => {
  const styles = useStyles();

  const type = get(props, "type", "");
  const options = get(props, "options", []);
  const onCitySelection = get(props, "onCitySelection", noop);
  const module = get(props, "module", "flight");

  return (
    <div className={styles.airportOptions}>
      {/* <h2 className={styles.airportOptionsTitle}>Suggestions</h2> */}
      <ul className={styles.airportOptionsList}>
        {map(options, (option) => {
          // return (
          //   <li
          //     className={styles.airportOptionsListOption}
          //     key={get(option, "name", "")}
          //     onClick={() => onCitySelection({ type, value: option })}
          //   >
          //     <div className={styles.airportOptionsListOptionLeft}>
          //       {module === "hotel" ? <LocationCity /> : <FlightTakeoff />}
          //       <div className={styles.airportOptionAirportInfo}>
          //         <p className={styles.airportOptionAirportName}>
          //           {get(option, "name", "")}
          //         </p>
          //         <p className={styles.airportOptionAirportCityName}>
          //           {get(option, "country", "")}
          //         </p>
          //       </div>
          //     </div>
          //     <div className={styles.airportOptionsListOptionRight}>
          //       {get(option, "code", "")}
          //     </div>
          //   </li>
          // );
        })}
        {options?.map((option, index) => {
          if (index < 15) {
            return (
              <li
                className={styles.airportOptionsListOption}
                key={get(option, "_id", "")}
                onClick={() => onCitySelection({ type, value: option })}
              >
                <div className={styles.airportOptionsListOptionLeft}>
                  {module === "hotel" ? <LocationCity /> : <FlightTakeoff />}
                  <div className={styles.airportOptionAirportInfo}>
                    <p className={styles.airportOptionAirportName}>
                      {get(option, "name", "")}
                    </p>
                    <p className={styles.airportOptionAirportCityName}>
                      {get(option, "country", "")}
                    </p>
                  </div>
                </div>
                <div className={styles.airportOptionsListOptionRight}>
                  {get(option, "code", "")}
                </div>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

const MobileCitySelectionFieldComp = (props) => {
  const dispatch = useDispatch();
  const onChange = get(props, "onChange", noop);
  const placeholder = get(props, "placeholder", "");
  const getValuesFor = get(props, "for", "flights");
  const index = get(props, "index", 0);
  const styles = useStyles();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [showFromCities, setShowFromCities] = useState(false);
  const [showToCities, setShowToCities] = useState(false);

  const [optionsState, setOptionsState] = useState({
    showFromOptions: false,
    showToOptions: false,
    options: [],
  });

  const { flightFrom, flightTo } = useSelector(
    (state) => state.modifySearchFlight
  );
  const { hotelCity } = useSelector((state) => state.searchHotel);

  const [cities, setCities] = useState({
    from: flightFrom[index] ? flightFrom[index] : {},
    to: flightTo[index] ? flightTo[index] : {},
  });

  const handleCitySelection = ({ type, value }) => {
    const newCitiesData = { ...cities, [type]: value };
    onChange(newCitiesData);
    setCities(newCitiesData);
    setShowFromCities(false);
    setShowToCities(false);
    handleGetAirports();
  };

  const handleGetAirports = async (search = "") => {
    const data = await getAirports({ limit: 100, skip: 0, search });
    setOptionsState((prev) => ({ ...prev, options: data }));
  };

  const handleGetHotelCities = async (search = "") => {
    const data = await getHotelCities({ limit: 100, skip: 0, search });
    setOptionsState((prev) => ({ ...prev, options: data }));
  };

  const swapValues = () => {
    if (
      flightFrom.length > index &&
      flightTo.length > index &&
      Object.keys(flightFrom[index]).length &&
      Object.keys(flightTo[index]).length
    ) {
      const temp = flightTo[index];
      dispatch(
        updateModifyFlightTo({
          id: index,
          data: flightFrom[index],
        })
      );
      dispatch(updateModifyFlightFrom({ id: index, data: temp }));
    }
  };

  useEffect(() => {
    if (getValuesFor === "hotel") {
      handleGetHotelCities();
    } else {
      handleGetAirports();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (showFromCities && !event.target.closest(".from-component")) ||
        from
      ) {
        setFrom("");
        setShowFromCities(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showFromCities, from]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if ((showToCities && !event.target.closest(".to-component")) || to) {
        setTo("");
        setShowToCities(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showToCities, to]);

  return (
    <div className={styles.mobileCityField}>
      {getValuesFor === "flights" ? (
        <>
          <div
            className={styles.textFieldFromWrapper}
          >
            <FlightTakeoff />
            <input
              placeholder="From"
              type="text"
              value={
                flightFrom &&
                flightFrom.length > index &&
                Object.keys(flightFrom[index]).length
                  ? flightFrom[index].code +
                    " - " +
                    flightFrom[index].city +
                    ", " +
                    flightFrom[index].country
                  : from
              }
              className={`${styles.textField} from-component`}
              onChange={(e) => {
                setFrom(e.target.value);
                handleGetAirports(e.target.value);
                dispatch(updateFlightFrom({ id: index, data: {} }));
                dispatch(updateModifyFlightFrom({ id: index, data: {} }));
              }}
              onFocus={() => {
                handleGetAirports();
                setShowFromCities(!showFromCities);
              }}
            />
          </div>
          {showFromCities && (
            <CityOptionsList
              type={"from"}
              options={optionsState.options}
              onCitySelection={handleCitySelection}
            />
          )}
          <div className={styles.swapIconWrapper} onClick={swapValues}>
            <SwapVert style={{ fontSize: "24px" }} />
          </div>
          <div className={styles.textFieldToWrapper}>
            <FlightLand />
            <input
              placeholder="To"
              type="text"
              value={
                Object.keys(flightTo?.length > index && flightTo[index]).length
                  ? flightTo[index].code +
                    " - " +
                    flightTo[index].city +
                    ", " +
                    flightTo[index].country
                  : to
              }
              className={`${styles.textField} to-component`}
              onChange={(e) => {
                setTo(e.target.value);
                handleGetAirports(e.target.value);
                dispatch(updateFlightTo({ id: index, data: {} }));
                dispatch(updateModifyFlightTo({ id: index, data: {} }));
              }}
              onFocus={() => {
                handleGetAirports();
                setShowToCities(!showToCities);
              }}
            />
          </div>
          {showToCities && (
            <CityOptionsList
              type={"to"}
              options={optionsState.options}
              onCitySelection={handleCitySelection}
            />
          )}
        </>
      ) : (
        <>
          <div className={styles.textFieldFromSingleWrapper}>
            <LocationCity />
            <input
              placeholder={placeholder}
              type="text"
              value={
                hotelCity && Object.keys(hotelCity).length > 0
                  ? hotelCity.name
                  : from
              }
              className={`${styles.textField} from-component`}
              onChange={(e) => {
                setFrom(e.target.value);
                handleGetHotelCities(e.target.value);
                dispatch(updateHotelCity({}));
              }}
              onFocus={() => {
                setShowFromCities(!showFromCities);
              }}
              style={{
                width: "inherit",
              }}
            />
          </div>
          {showFromCities && (
            <CityOptionsList
              type={"from"}
              options={optionsState.options}
              onCitySelection={handleCitySelection}
              module={"hotel"}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MobileCitySelectionFieldComp;
