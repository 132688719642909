import { createSlice } from "@reduxjs/toolkit";

const internationalRoundTripSlice = createSlice({
  name: "internationalRoundTrip",
  initialState: {
    searchedFlights: [],
    selectedFlight: [],
    clubbedFlights: []
  },
  reducers: {
    handleSearchedFlightsForInternationalRoundTrip: (state, action) => {
      state.searchedFlights = action.payload;
    },
    handleSelectedFlightForInternationalRoundTrip: (state, action) => {
      state.selectedFlight = action.payload;
    },
    handleClubbedFlightForInternationalRoundTrip: (state, action) => {
      state.clubbedFlights = action.payload;
    }
  },
});

export const {
  handleSearchedFlightsForInternationalRoundTrip,
  handleClubbedFlightForInternationalRoundTrip,
  handleSelectedFlightForInternationalRoundTrip,
} = internationalRoundTripSlice.actions;

export default internationalRoundTripSlice.reducer;
