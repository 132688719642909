import { createSlice } from "@reduxjs/toolkit";

const getFlightDetailsFromLocalStorage = () => {
  const flight = JSON.parse(window.localStorage.getItem("flightDetails"));
  return flight;
};

const flight = getFlightDetailsFromLocalStorage();

const searchFlightSlice = createSlice({
  name: "searchFlight",
  initialState: {
    bookingType: "Flight",
    flightTravelType: flight ? flight?.flightTravelType : Number(1),
    addAnotherCity: false,
    flightType: flight ? flight?.flightType : "",
    flightFrom: flight ? flight?.flightFrom : [{}, {}, {}],
    flightTo: flight ? flight?.flightTo : [{}, {}, {}],
    flightDepartureDate: flight ? flight?.flightDepartureDate : "",
    flightReturnDate: flight ? flight?.flightReturnDate : "",
    flightClassType: flight ? flight?.flightClassType : Number(2),
    flightTotalTravellers: {
      totalCount: flight ? flight?.totalCount : 1,
      flightAdultsCount: flight ? flight?.flightAdultsCount : 1,
      flightChildrenCount: flight ? flight?.flightChildrenCount : 0,
      flightInfantsCount: flight ? flight?.flightInfantsCount : 0,
    },
    totalSelectionForMultiCity: 0,
    errors: [],
    searchedFlights: [],
    multiCityDates: flight ? flight?.multiCityDates : [],
  },
  reducers: {
    updateTravellersCount: (state, action) => {
      const { totalTravellers, adultCount, childrenCount, infantCount } =
        action.payload;
      Object.assign(state.flightTotalTravellers, {
        totalCount: totalTravellers,
        flightAdultsCount: adultCount,
        flightChildrenCount: childrenCount,
        flightInfantsCount: infantCount,
      });
    },
    updateFlightClassType: (state, action) => {
      state.flightClassType = action.payload;
    },
    updateFlightTravelType: (state, action) => {
      state.flightTravelType = action.payload;
    },
    updateFlightType: (state, action) => {
      state.flightType = action.payload;
    },
    updateFlightTo: (state, action) => {
      const { id, data } = action.payload;
      state.flightTo[id] = data;
    },
    updateFlightFrom: (state, action) => {
      const { id, data } = action.payload;
      state.flightFrom[id] = data;
    },
    updateDates: (state, action) => {
      const { departureDate, returnDate } = action.payload;
      state.flightDepartureDate = departureDate;
      state.flightReturnDate = returnDate;
    },
    addError: (state, action) => {
      const { id } = action.payload;
      const isErrorExists = state.errors.find((error) => error.id === id);
      if (!isErrorExists) state.errors.push(action.payload);
    },
    removeError: (state, action) => {
      state.errors = state.errors.filter(
        (error) => error.id !== action.payload.id
      );
    },
    updateAnotherCity: (state, action) => {
      state.addAnotherCity = action.payload;
    },
    updateTotalSelectionForMultiCity: (state, action) => {
      state.totalSelectionForMultiCity = action.payload;
    },
    addSearchedFlights: (state, action) => {
      state.searchedFlights = action.payload;
    },
    handleMultiCityDatesHome: (state, action) => {
      state.multiCityDates = action.payload;
    }
  },
});

export const {
  updateTravellersCount,
  updateFlightClassType,
  updateFlightTravelType,
  updateFlightType,
  updateFlightFrom,
  updateFlightTo,
  updateDates,
  addError,
  removeError,
  updateAnotherCity,
  updateTotalSelectionForMultiCity,
  addSearchedFlights,
  handleMultiCityDatesHome,
} = searchFlightSlice.actions;

export default searchFlightSlice.reducer;
