import {
  arabicDayFullNames,
  arabicMonthShortNames,
  dayFullNames,
  monthShortNames,
} from "../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const formatDate = (date) => {
  const t = new Date(date);
  if (localStorage.getItem("language") === "AR") {
    return (
      t.getDate() +
      " " +
      arabicMonthShortNames[t.getMonth()] +
      ", " +
      t.getFullYear()
    );
  } else {
    return (
      t.getDate() + " " + monthShortNames[t.getMonth()] + ", " + t.getFullYear()
    );
  }
};

export const formatParsedDate = (date) => {
  const parsedDate = moment(date, "DD/MM/YYYY");
  let formattedDate;
  const language = localStorage.getItem("language");
  if (language == "AR") {
    formattedDate = convertToArabicDateInDDMMYYY(date);
  } else {
    formattedDate = parsedDate.format("DD MMM, YYYY");
  }

  return formattedDate;
};

export const formatParsedDay = (date) => {
  if(!date) return "";
  console.log('date', date)
  const language = localStorage.getItem("language");
  if (language == "AR") {
    return getArabicDayName(date)
  } else {
    const parsedDate = moment(date, "DD/MM/YYYY");
    const day = parsedDate.format("dddd");
    return day;
  }
};

export const formatDay = (date) => {
  const t = new Date(date);
  if (localStorage.getItem("language") === "AR") {
    return arabicDayFullNames[t.getDay()];
  }
  return dayFullNames[t.getDay()];
};

export const formatDateToDdMmYyyy = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDateToDdMmYyyyForBooking = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = String(date.getFullYear()).slice(-2); // Extract the last two digits of the year
  return `${day} ${month}, ${year}`;
};

export const formatDateToDdMmmYyyy = (date) => {
  if (date === "") return "";
  const originalDate = moment(date, "DD/MM/YYYY");
  const formattedDate = originalDate.format("DD MMM, YYYY");
  return formattedDate;
};

function convertToArabicMonth(month) {
  var arabicMonths = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  return arabicMonths[month - 1];
}

export function convertToArabicDateInDDMMYYY(dateStr) {
  if (dateStr === "") return "";

  // Split the date string into day, month, and year
  var parts = dateStr.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month - 1, day);

  // Get the Arabic month name
  var arabicMonth = convertToArabicMonth(month);

  // Format the date string with Arabic month
  var arabicDate = day + " " + arabicMonth + ", " + year;

  return arabicDate;
}

export function convertISOToArabicDateInDDMMYYY(dateStr) {
  if (dateStr === "" || !dateStr) return "";

  // Split the date string into year, month, and day
  var isoDateParts = dateStr.split("T")[0].split("-");
  var year = parseInt(isoDateParts[0], 10);
  var month = parseInt(isoDateParts[1], 10);
  var day = parseInt(isoDateParts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month - 1, day);

  // Get the Arabic month name
  var arabicMonth = convertToArabicMonth(month);

  // Format the date string with Arabic month
  var arabicDate = day + " " + arabicMonth + ", " + year;

  return arabicDate;
}

export const formatDateToYyyyMmDd = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}/${month}/${day}`;
};

export const formatDayOfFormattedDate = (date) => {
  if (date === "") return "";
  const originalDate = moment(date, "DD/MM/YYYY");
  const day = originalDate.format("dddd");
  return day;
};

function convertToArabicDay(day) {
  var arabicDays = [
    "الأحد",
    "الإثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];
  return arabicDays[day];
}

export function getArabicDayName(dateStr) {
  if (dateStr === "") return "";
  // Split the date string into day, month, and year
  var parts = dateStr.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1;
  var year = parseInt(parts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month, day);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = gregorianDate.getDay();

  // Get the Arabic day name
  var arabicDay = convertToArabicDay(dayOfWeek);

  return arabicDay;
}

export function getArabicDayNameFromISODate(dateStr) {
  if (dateStr === "" || !dateStr) return "";

  // Extract the date components from the ISO 8601 date string
  var isoDateParts = dateStr.split("T")[0].split("-");
  var year = parseInt(isoDateParts[0], 10);
  var month = parseInt(isoDateParts[1], 10) - 1;
  var day = parseInt(isoDateParts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month, day);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = gregorianDate.getDay();

  // Get the Arabic day name
  var arabicDay = convertToArabicDay(dayOfWeek);

  return arabicDay;
}

export const formateDateToDayAndDate = (date) => {
  if (localStorage.getItem("language") != "AR") {
    const parsedDate = moment(date, "DD/MM/YYYY");
    const formattedDate = parsedDate.format("ddd, DD/MM");
    return formattedDate;
  } else {
    if (!date) return "";
    var parts = date.split("/");
    var day = parseInt(parts[0])
    var month = parseInt(parts[1])
    return `${getArabicDayName(date)}, ${day}/${month}`;
  }
};

export const timeString = (dateTime) => {
  const dateTimeMoment = moment(dateTime);
  const formattedTime = dateTimeMoment.format("HH:mm");
  return formattedTime;
};

export const TimeFormat = (time) => {
  // const {t}  = useTranslation()
  const parsedTime = moment(time, "HH:mm:ss");
  let formattedTime = "";

  const hours = parsedTime.hours();
  const minutes = parsedTime.minutes();

  if (hours > 0) {
    // formattedTime += `${hours}${t("clockTimingsCodes.h")} `;
    formattedTime += `${hours}h `;
  }

  if (minutes > 0 || hours === 0) {
    // formattedTime += `${minutes}${t("clockTimingsCodes.m")} `;
    formattedTime += `${minutes}m`;
  }

  return formattedTime;
};

export const convertAccumulatedTime = (timeString) => {
  const { t } = useTranslation();

  const duration = moment.duration(timeString);

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.minutes());

  let resultString = "";

  if (hours > 0) {
    resultString += `${hours}${t("clockTimingsCodes.h")} `;
  }

  if (minutes > 0 || hours === 0) {
    resultString += `${minutes}${t("clockTimingsCodes.m")} `;
  }

  return resultString;
};

export const convertTimeToMinutes = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
};

export const convertTimeToHoursAndMinutes = (timeString) => {
  const { t } = useTranslation();
  const [days, hours, minutes, seconds] = timeString.split(":").map(Number);

  const totalHours = days * 24 + hours;

  return `${totalHours} ${t("clockTimingsCodes.h")} ${minutes} ${t(
    "clockTimingsCodes.m"
  )}`;
};

export const calculateFlightDurationInHoursAndMinutes = (
  departureDateTime,
  arrivalDateTime
) => {
  const { t } = useTranslation();
  const departureMoment = moment(departureDateTime);
  const arrivalMoment = moment(arrivalDateTime);

  const duration = moment.duration(arrivalMoment.diff(departureMoment));
  const hoursDifference = duration.hours();
  const minutesDifference = duration.minutes();
  return `${hoursDifference}${t("clockTimingsCodes.h")} ${minutesDifference}${t(
    "clockTimingsCodes.m"
  )}`;
};

export const calculateFlightDuration = (departureDateTime, arrivalDateTime) => {
  const { t } = useTranslation();
  const departureMoment = moment(departureDateTime);
  const arrivalMoment = moment(arrivalDateTime);

  const duration = moment.duration(arrivalMoment.diff(departureMoment));
  const hoursDifference = duration.hours();
  const minutesDifference = duration.minutes();
  if (minutesDifference > 0 && hoursDifference > 0)
    return `${hoursDifference}${t(
      "clockTimingsCodes.h"
    )} ${minutesDifference}${t("clockTimingsCodes.m")}`;
  if (hoursDifference <= 0)
    return `${minutesDifference}${t("clockTimingsCodes.m")}`;
  return `${hoursDifference}${t("clockTimingsCodes.h")}`;
};

export const flightDurationInHoursAndMinutes = (timeInMinutes) => {
  const { t } = useTranslation();
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  if (hours !== 0 && minutes !== 0)
    return `${hours}${t("clockTimingsCodes.h")} ${minutes}${t(
      "clockTimingsCodes.m"
    )}`;
  else if (hours !== 0) return `${hours}${t("clockTimingsCodes.h")}`;
  else if (minutes !== 0) return `${minutes}${t("clockTimingsCodes.m")}`;
};

export const flightDuration = (timeInMinutes) => {
  const { t } = useTranslation();

  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  if (hours !== 0 && minutes !== 0)
    return `${hours}${t("clockTimingsCodes.h")} ${minutes}${t(
      "clockTimingsCodes.m"
    )}`;
  else if (hours !== 0) return `${hours}${t("clockTimingsCodes.h")}`;
  else if (minutes !== 0) return `${minutes}${t("clockTimingsCodes.m")}`;
};

export const timeStringToMinutes = (timeString) => {
  const parts = timeString.split(" ");
  let totalMinutes = 0;

  for (const part of parts) {
    if (part.includes("h")) {
      const hours = parseInt(part, 10);
      totalMinutes += hours * 60;
    } else if (part.includes("m")) {
      const minutes = parseInt(part, 10);
      totalMinutes += minutes;
    }
  }

  return totalMinutes;
};

export const addDurationStrings = (duration1, duration2) => {
  const { t } = useTranslation();
  if (duration1 && duration2) {
    const totalDuration1 = parseDuration(duration1);
    const totalDuration2 = parseDuration(duration2);

    const totalMinutes =
      totalDuration1?.hours() * 60 +
      totalDuration1?.minutes() +
      totalDuration2?.hours() * 60 +
      totalDuration2?.minutes();

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours > 0 && minutes > 0)
      return `${hours}${t("clockTimingsCodes.h")} ${minutes}${t(
        "clockTimingsCodes.m"
      )}`;
    else if (hours > 0) return `${hours}${t("clockTimingsCodes.h")}`;
    else if (minutes > 0) return `${minutes}${t("clockTimingsCodes.m")}`;
  }
};

const parseDuration = (durationString) => {
  const parts = durationString.split(" ");
  if (parts?.length >= 2) {
    const hours = parseInt(parts[0].replace("h", ""), 10);
    const minutes = parseInt(parts[1].replace("m", ""), 10);
    return moment.duration({ hours, minutes });
  }
};

export function formatSARCurrency(original) {
  let formattedPrice = parseFloat(original).toFixed(2);

  // Skip formatting if it's not a numeric value
  if (!isFinite(formattedPrice)) {
    return original;
  }
  // Convert to SAR currency format with comma separation
  formattedPrice = formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Add SAR currency symbol
  return formattedPrice;
}

function formatFixed(original, decimals) {
  var originalFloat = parseFloat(original);

  // Skip formatting if it's not a numeric value
  if (!isFinite(originalFloat)) {
    return original;
  }

  return _formatAfterCleanup(originalFloat.toFixed(decimals));
}

function _formatAfterCleanup(originalNumber) {
  var originalMag;
  var negative;

  if (originalNumber < 0) {
    originalMag = -1 * originalNumber;
    negative = true;
  } else {
    originalMag = originalNumber;
    negative = false;
  }

  var arr = String(originalMag).split("").reverse();
  var start = arr.indexOf(".") + 1; // start of full part
  var i; // source index
  var result = []; // array holder of the result

  // copy the fractional part and the decimal if present
  for (i = 0; i < start; i++) {
    result.push(arr[i]);
  }

  // main loop
  var c = 0; // digit counter
  for (i = start; i < arr.length; i++) {
    result.push(arr[i]);
    c++;
    if ((c === 3 || c === 5 || c === 7) && i < arr.length - 1) {
      result.push(",");
    }
    if (c === 7) {
      c = 0;
    }
  }

  if (negative) {
    result.push("-");
  }

  return result.reverse().join("");
}

export function calculateNumberOfNights(hotelCheckInDate, hotelCheckOutDate) {
  const formattedHotelCheckoutDate = new Date(
    hotelCheckOutDate.split("/").reverse().join("-")
  );
  const currentDate = new Date();
  if (
    !hotelCheckInDate ||
    !hotelCheckOutDate ||
    formattedHotelCheckoutDate <= currentDate
  ) {
    return 0;
  } else {
    const [checkInDay, checkInMonth, checkInYear] = hotelCheckInDate.split("/");
    const [checkOutDay, checkOutMonth, checkOutYear] =
      hotelCheckOutDate.split("/");

    const checkIn = new Date(checkInYear, checkInMonth - 1, checkInDay);
    const checkOut = new Date(checkOutYear, checkOutMonth - 1, checkOutDay);
    const timeDifference = checkOut - checkIn;
    const nights = timeDifference / (1000 * 60 * 60 * 24);

    return Math.round(nights);
  }
}

export function getTimeDifferenceInArabic(earlierDate) {
  const cardCreatedAt = new Date(earlierDate);
  const now = new Date();

  const timeDifference = now.getTime() - cardCreatedAt.getTime();

  // Convert milliseconds to seconds
  const secondsDifference = Math.floor(timeDifference / 1000);

  // Define localized strings for Arabic
  const arabicStrings = {
    seconds: "ثانية",
    minutes: "دقيقة",
    hours: "ساعة",
    days: "يوم",
  };

  // Calculate the time ago based on the seconds difference
  let timeAgo;
  if (secondsDifference < 60) {
    timeAgo = secondsDifference + " " + arabicStrings.seconds + " مضت";
  } else if (secondsDifference < 3600) {
    const minutes = Math.floor(secondsDifference / 60);
    timeAgo = minutes + " " + arabicStrings.minutes + " مضت";
  } else if (secondsDifference < 86400) {
    const hours = Math.floor(secondsDifference / 3600);
    timeAgo = hours + " " + arabicStrings.hours + " مضت";
  } else {
    const days = Math.floor(secondsDifference / 86400);
    timeAgo = days + " " + arabicStrings.days + " مضت";
  }

  return timeAgo;
}

export function formatDateToArabic(dateStr) {
  // Split the date string into day, month, and year
  var parts = dateStr.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10) - 1;
  var year = parseInt(parts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month, day);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = gregorianDate.getDay();

  // Get the Arabic day name
  var arabicDay = convertToArabicDay(dayOfWeek);

  // Format the date string as "dddd, DD/MM"
  var formattedDate =
    arabicDay + ", " + day + "/" + (month + 1).toString().padStart(2, "0");

  return formattedDate;
}

export function formatDateToArabicDayDateAndMonthFormat(dateStr) {
  if (!dateStr) return "";

  // Extract the date components from the ISO 8601 date string
  var isoDateParts = dateStr.split("T")[0].split("-");
  var year = parseInt(isoDateParts[0], 10);
  var month = parseInt(isoDateParts[1], 10) - 1;
  var day = parseInt(isoDateParts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month, day);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = gregorianDate.getDay();

  // Get the Arabic day name
  var arabicDay = convertToArabicDay(dayOfWeek);

  // Get the Arabic month name
  var arabicMonths = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  var arabicMonth = arabicMonths[month];

  // Format the date string as "dddd, DD/MM"
  var formattedDate = arabicDay + ", " + day + " " + arabicMonth;

  return formattedDate;
}

export function formatDateToArabicDayDateAndMonthAndYearFormat(dateStr) {
  if (!dateStr) return "";

  // Extract the date components from the ISO 8601 date string
  var isoDateParts = dateStr.split("T")[0].split("-");
  var year = parseInt(isoDateParts[0], 10);
  var month = parseInt(isoDateParts[1], 10) - 1;
  var day = parseInt(isoDateParts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month, day);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = gregorianDate.getDay();

  // Get the Arabic day name
  var arabicDay = convertToArabicDay(dayOfWeek);

  // Get the Arabic month name
  var arabicMonths = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  var arabicMonth = arabicMonths[month];

  // Format the date string as "dddd, DD/MM"
  var formattedDate = arabicDay + ", " + day + " " + arabicMonth + " " + year;

  return formattedDate;
}

export function formatDateToArabicDateAndMonthAndYearFormat(dateStr) {
  if (!dateStr) return "";

  // Extract the date components from the ISO 8601 date string
  var isoDateParts = dateStr.split("T")[0].split("-");
  var year = parseInt(isoDateParts[0], 10);
  var month = parseInt(isoDateParts[1], 10) - 1;
  var day = parseInt(isoDateParts[2], 10);

  // Create a Date object with the Gregorian date
  var gregorianDate = new Date(year, month, day);

  // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  var dayOfWeek = gregorianDate.getDay();

  // Get the Arabic day name
  var arabicDay = convertToArabicDay(dayOfWeek);

  // Get the Arabic month name
  var arabicMonths = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  var arabicMonth = arabicMonths[month];

  // Format the date string as "dddd, DD/MM"
  var formattedDate = day + " " + arabicMonth + " " + year;

  return formattedDate;
}
