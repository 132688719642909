import React from "react";
import { Divider } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import { useState } from "react";
import useStyles from "./styles";
import Tooltip from "./Tooltip";
import moment from "moment";
import { useEffect } from "react";
import { formatSARCurrency } from "../../../../../../../../../../src/utils/helpers";
import { ReactSVG } from "react-svg";
import Tippy from "@tippy.js/react";
import InfoSVG from "../../../../../../../../../assets/images/info-circle.svg";
import { useTranslation } from "react-i18next";
let reRenderCount = 0;

const FareBreakDown = (props) => {
  const {
    noOfNights,
    totalPayableAmount,
    couponDiscount,
    totalBaseFare,
    taxAmount,
    convenienceFees,
    bookedRooms } = props.details
  const classes = useStyles();



  const details = props?.details;
  const cancellationPolicies = bookedRooms && bookedRooms[0] && bookedRooms[0].CancelPolicies;

  const [showFare, setShowFare] = useState(false);

  const handleShowFareClick = (index) => {

    setShowFare(!showFare);
  };
  const { t } = useTranslation();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.fareBreakdownAndPolicyContainer}>
        <p className={classes.fareBreakdownText}>
          {t("fareRuleCard.fareBreakdown")}
        </p>
        <Tooltip
          text={
            <>
              <table
                style={{
                  margin: "10px",
                  // width: "430px",
                  borderCollapse: "collapse", // Add this to collapse table borders
                }}
                className={(classes.table, classes.cancellationTable)}
              >
                <thead
                  className={classes.cancellationTable}
                  style={{
                    background: "#EAF5FF",
                    // width: "430px",
                    margin: "50px 0",
                  }}
                >
                  <tr
                    style={{
                      // width: "430px",
                      margin: "50px",
                    }}
                  >
                    <th
                      align="left"
                      style={{
                        width: "100px",
                        border: "2px solid grey",
                        // Add border style here
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      className={classes.tableHeaderCell}
                    >
                      {t("myProfile.cancelledOnOrAfter")}
                    </th>

                    <th
                      align="left"
                      style={{
                        width: "100px",
                        border: "2px solid grey", // Add border style here
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      className={classes.tableHeaderCell}
                    >
                      {t("myProfile.cancellationCharges")}
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={classes.cancellationTable}
                // style={{ width: "430px" }}
                >

                  {cancellationPolicies &&
                    cancellationPolicies.map((policy, index) => {
                      let { FromDate, CancellationCharge, ChargeType } = policy;

                      return (
                        <tr
                          // style={{ width: "430px" }}
                          className={classes.tableRow}
                          key={index}
                        >
                          <td
                            className={classes.tableDataCell}
                            align="left"
                            style={{
                              width: "100px",
                              border: "2px solid grey", // Add border style here
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {FromDate}
                          </td>

                          <td
                            className={classes.tableDataCell}
                            align="left"
                            style={{
                              width: "100px",
                              border: "2px solid grey", // Add border style here
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {CancellationCharge} {ChargeType}

                          </td>
                        </tr>
                      );
                    })}
                  <tr style={{ border: "2px solid grey" }}>
                    <td
                      align="left"
                      colSpan="3"
                      style={{
                        border: "2px solid grey", // Add border style here
                        textAlign: "left",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {t("myProfile.noShowPolicy")}
                    </td>
                  </tr>
                  <tr style={{ border: "2px solid grey" }}>
                    <td
                      align="left"
                      colSpan="3"
                      style={{
                        border: "2px solid grey", // Add border style here
                        textAlign: "left",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      {/* Early check out will attract full cancellation charge
                      unless otherwise specified */}
                      {t("myProfile.noShowPolicy")}

                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        >
          <p className={classes.cancellationPolicyText}>
            {t("myProfile.cancellationPolicy")}
          </p>
        </Tooltip>
      </div>
      <div className={classes.roomPriceContainer}>
        <p className={classes.roomText}>
          {0 + 1}  {t("myProfile.room")}  &nbsp; x &nbsp;
          {details.noOfNights}{" "}
          {details.noOfNights > 1
            ? `${t("myProfile.nights")}`
            : `${t("myProfile.night")}`}
          {showFare === true ? (
            <ExpandLess
              style={{ color: "#1B1D52", cursor: "pointer" }}
              onClick={() => handleShowFareClick()}
            />
          ) : (
            <ExpandMore
              style={{ color: "#1B1D52", cursor: "pointer" }}
              onClick={() => handleShowFareClick()}
            />
          )}
        </p>
        <p className={classes.roomPrice}>
          {t("hotelDetails.currencySymbol")} {" "}
          {`${formatSARCurrency(totalBaseFare + taxAmount)} `}
        </p>
      </div>
      {showFare === true ? (
        <>
          <div className={classes.baseFarePriceContainer}>
            <p className={classes.baseFareText}>
              {t("myProfile.baseFare")}
            </p>
            <p className={classes.baseFare}>
              {t("hotelDetails.currencySymbol")}
              {" "}
              {`${formatSARCurrency(totalBaseFare)}`}
            </p>
          </div>
          <div className={classes.taxFeeContainer}>
            <p className={classes.taxFeesText}>
              {t("myProfile.taxesAndFees")}
            </p>
            <p className={classes.taxFee}>
              {t("hotelDetails.currencySymbol")}
              {" "}
              {`${formatSARCurrency(taxAmount)}`}
            </p>
          </div>
        </>
      ) : (
        ""
      )}

      {
        couponDiscount ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className={classes.conveniencefeetext}>Discount {"(-)"}</p>
            <p className={classes.roomPrice} style={{ color: "green" }}>
              {t("hotelDetails.currencySymbol")}
              {" "}
              {`${formatSARCurrency(Math.ceil(couponDiscount))}`}</p>
          </div>
        ) : (
          ""
        )
      }
      {
        convenienceFees ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tippy
              arrow={true}
              placement="bottom-start"
              animation="shift-away"
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    padding: "16px",
                    background: "#344054",
                    borderRadius: "4px",
                    width: "250px",
                    marginTop: "-20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#FFF",
                      fontWeight: 500,
                    }}
                  >
                    {t("myProfile.nonRefundableConvenienceFee")}{" "}
                    {t("hotelDetails.currencySymbol")}
                    {" "} {parseFloat(convenienceFees).toFixed(2)}{" "}
                    {t("myProfile.perRoom")}
                  </p>
                </div>
              }
            >
              <p
                className={classes.conveniencefeetext}
                style={{
                  display: "inline-flex",
                  gap: "4px",
                  marginTop: "2px",
                }}
              >
                {t("fareRuleCard.convenienceFee")}
                <ReactSVG src={InfoSVG} />
              </p>
            </Tippy>
            <p className={classes.roomPrice}>
              {t("hotelDetails.currencySymbol")}
              {" "}
              {`${formatSARCurrency(
                convenienceFees
              )}`}</p>
          </div>
        ) : (
          ""
        )
      }
      <Divider style={{ borderStyle: "dashed" }} />
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "8px",
        }}
      >
        <p className={classes.totalPriceText}>{t("fareRuleCard.totalPrice")}</p>
        <p className={classes.totalPrice}>
          {t("hotelDetails.currencySymbol")}
          {" "} {formatSARCurrency(totalPayableAmount)}
        </p>
      </div>
    </div >
  );
};

export default FareBreakDown;
