import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
  editProfileMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    padding: "24px 16px",
    backgroundColor: "#fff",
    marginTop: "13%",
    marginLeft: "35%",
    width: "40%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginToP: "20%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginToP: "20%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  editProfileMainContainerArabic: {
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    padding: "24px 16px",
    backgroundColor: "#fff",
    marginTop: "13%",
    marginRight: "35%",
    width: "40%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginToP: "20%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginToP: "20%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  editProfileTextContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  editProfileText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
  },
  nameMobileEmailContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  },
  firstNameAndLastNameContainer: {
    display: "inline-flex",
    gap: "20px",
  },
  firstNameLable: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  firstNameContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "50%",
  },
  lastNameContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "50%",
  },
  lastNameLable: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  mobileNumberAndEmailContainer: {
    display: "inline-flex",
    gap: "20px",
  },
  mobileNumberContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "50%",
  },
  mobileNumberLable: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  emailIdContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "50%",
  },
  emailIdLable: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  saveOuterContainer: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
  },
  saveContainer: {
    borderRadius: "8px",
    background: "#1b1d52",
    padding: "8px 24px",
    display: "flex",
    cursor: "pointer",
  },
  saveText: {
    color: "#FFF",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
  },
}));

export default useStyles;
