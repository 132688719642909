import React, { useEffect, useState } from "react";
import SearchFlight from "../../../components/flight-module/search-flight";
import useResponsive from "../../../hooks/responsive.hook";
import FlightResultHeader from "../../../components/flight-module/search-flight/components/flight-result-header";
import { Box, SwipeableDrawer, Typography } from "@mui/material";
import SearchingSortMobile from "../../../components/flight-module/search-flight/components/search-sort-mobile";
import useStyles from "./style";
import filterIcon from "../../../assets/images/arrow-3.svg";
import sortIcon from "../../../assets/images/sort.svg";
import { ReactSVG } from "react-svg";
import SearchFlightSidebar from "../../../components/flight-module/search-flight/components/search-flight-sidebar";
import "./style.css";
import eventBus from "../../../core/eventBus";
import { useDispatch, useSelector } from "react-redux";
import { handleQuickFilters } from "../../../utils/slices/flightFiltersSlice";
import { airlines } from "../../../config";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let flightFrom, flightTo, flightTravelType;
  if (window.localStorage.getItem("modifySearchFlightDetails")) {
    const {
      flightFrom: from,
      flightTo: to,
      flightTravelType: travelType,
    } = JSON.parse(window.localStorage.getItem("modifySearchFlightDetails"));

    flightFrom = from;
    flightTo = to;
    flightTravelType = travelType;
  }

  const { indexOfRoundTrip } = useSelector((state) => state.bookedFlight);
  const { indexOfMultiCity } = useSelector(
    (state) => state.multiCityTripDetails
  );

  const {
    outboundFilters,
    inboundFilters,
    clubbedFiltersForThirdCity,
    clubbedFiltersForSecondCity,
  } = useSelector((state) => state.flightFilters);

  const { airlineCount = [] } = outboundFilters;

  useEffect(() => {
    handleQickFiltersAirlines();
  }, [airlines]);

  const handleQickFiltersAirlines = () => {
    const airlines = Object.keys(airlineCount)
      .sort((a, b) => airlineCount[b] - airlineCount[a])
      .slice(0, 3);

    dispatch(handleQuickFilters([...airlines]));
  };

  return (
    <>
      {flightTravelType == 1 && (
        <SearchFlightSidebar
          outboundFilters={outboundFilters}
          from={flightFrom[0]}
          to={flightTo[0]}
          showPriceFilter={true}
        />
      )}
      {flightTravelType == 2 && flightFrom[0].country != flightTo[0].country ? (
        indexOfRoundTrip == 0 ? (
          <SearchFlightSidebar
            outboundFilters={outboundFilters}
            from={flightFrom[0]}
            to={flightTo[0]}
            showPriceFilter={false}
          />
        ) : (
          <SearchFlightSidebar
            outboundFilters={clubbedFiltersForSecondCity}
            from={flightTo[0]}
            to={flightFrom[0]}
            showPriceFilter={true}
          />
        )
      ) : (
        <></>
      )}
      {flightTravelType == 2 &&
        flightFrom[0].country == flightTo[0].country && (
          <SearchFlightSidebar
            outboundFilters={outboundFilters}
            inboundFilters={inboundFilters}
            from={flightFrom[0]}
            to={flightTo[0]}
            showPriceFilter={true}
          />
        )}
      {flightTravelType == 3 ? (
        indexOfMultiCity == 0 ? (
          <SearchFlightSidebar
            outboundFilters={outboundFilters}
            from={flightFrom[0]}
            to={flightTo[0]}
            showPriceFilter={false}
          />
        ) : indexOfMultiCity == 1 ? (
          <SearchFlightSidebar
            outboundFilters={clubbedFiltersForSecondCity}
            from={flightFrom[1]}
            to={flightTo[1]}
            showPriceFilter={!flightFrom[2].code ? true : false}
          />
        ) : indexOfMultiCity == 2 ? (
          <SearchFlightSidebar
            outboundFilters={clubbedFiltersForThirdCity}
            from={flightFrom[2]}
            to={flightTo[2]}
            showPriceFilter={true}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

const SearchFlightPage = () => {
  const { t } = useTranslation();

  const { loading } = useSelector((state) => state.modifySearchFlight);
  const { searchedFlights: oneWayFlights } = useSelector(
    (state) => state.oneWayTripDetails
  );
  const { searchedFlights: domesticRoundFlights } = useSelector(
    (state) => state.domesticRoundTripDetails
  );
  const { searchedFlights: internationalRoundFlights } = useSelector(
    (state) => state.internationalRoundTripDetails
  );
  const { searchedFlights: multiCityFlights } = useSelector(
    (state) => state.multiCityTripDetails
  );
  const currentBreakpoint = useResponsive();
  const style = useStyles();

  const handleFiltersBottmoSheet = () => {
    eventBus.emit("open-bottomSheet", <Sidebar />);
  };

  const handleSortByBottomSheet = () => {
    eventBus.emit("open-bottomSheet", <SearchingSortMobile />);
  };

  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        {(currentBreakpoint === "large" ||
          currentBreakpoint === "extraLarge") && <FlightResultHeader />}
        <SearchFlight />
      </div>
      {(currentBreakpoint === "small" || currentBreakpoint === "medium") &&
        !loading &&
        (oneWayFlights?.length > 0 ||
          domesticRoundFlights?.length > 0 ||
          internationalRoundFlights?.length > 0 ||
          multiCityFlights?.length > 0) && (
          <Box
            className={
              domesticRoundFlights?.length > 0
                ? style.bottomButtonsDRT
                : style.bottomButtons
            }
          >
            <Box
              className={
                localStorage.getItem("language") == "AR"
                  ? style.filterLayoutArabic
                  : style.filterLayout
              }
              onClick={handleSortByBottomSheet}
            >
              <ReactSVG src={filterIcon} />
              <Typography>{t("flightSearch.sortBy")}</Typography>
            </Box>
            <Box
              className={
                localStorage.getItem("language") == "AR"
                  ? style.sortLayoutArabic
                  : style.sortLayout
              }
              onClick={handleFiltersBottmoSheet}
            >
              <ReactSVG src={sortIcon} />
              <Typography>{t("flightSearch.filter")}</Typography>
            </Box>
          </Box>
        )}
    </div>
  );
};

export default SearchFlightPage;
