import axios from "axios";
import APIs from "../api";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const login = async (data) => {
  try {
    const { data: res } = await axios.post(APIs.login, data, config);
    return res;
  } catch (error) {
    console.log("Login API Error ", error);
  }
};

export const loginWithEmail = async (data) => {
  try {
    const { data: res } = await axios.post(APIs.loginWithEmail, data, config);
    return { statusCode: res.statusCode, data: res.data, message: res.message };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized: ", error.response.data.message);
      return {
        statusCode: 401,
        data: null,
        message: error.response.data.message,
      };
    } else if (error.response && error.response.status === 400) {
      console.log("Unauthorized: ", error.response.data.message);
      return {
        statusCode: 400,
        data: null,
        message: error.response.data.message,
      };
    } else {
      console.log("Login API Error: ", error.message);
      return { statusCode: 500, data: null, message: "Something went wrong" };
    }
  }
};

export const verifyOtp = async (data) => {
  return await axios.post(APIs.verifyOtp, data, config);
};

export const signup = async (data) => {
  try {
    const { data: res } = await axios.post(APIs.signup, data, config);
    return res;
  } catch (error) {
    console.log("Signup API Error ", error);
    return error.response
  }
};
export const setPassWordApi = async (data) => {
  try {
    const { data: res } = await axios.put(APIs.setPassWord, data, config);
    return res;
  } catch (error) {
    console.log("Set Password API Error ", error);
    return error.response.data
  }
};

export const forgotPasswordApi = async (data) => {
  try {
    const { data: res } = await axios.post(APIs.forgotPassword, data, config);
    return res;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized: ", error.response.data.message);
      return {
        statusCode: 401,
        data: null,
        message: error.response.data.message,
      };
    } else {
      console.log("Login API Error: ", error.message);
      return { statusCode: 500, data: null, message: "Something went wrong" };
    }
  }
};

export const logout = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data: res } = await axios.post(APIs.logout, {}, config);
    return res;
  } catch (error) {
    console.log("Logout API Error ", error);
  }
};

export const getUserProfile = async (token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const {
      data: { data },
    } = await axios.get(APIs.getUserProfile, config);
    return data;
  } catch (error) {
    console.log("Get Profile API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const editUserProfile = async ({
  token,
  firstName,
  lastName,
  email,
  phoneNo,
  profilePic = "",
}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const {
      data: { data },
    } = await axios.put(
      APIs.editUserProfile,
      { firstName, lastName, email, phoneNo, profilePic },
      config
    );
    return data;
  } catch (error) {
    console.log("Edit Profile API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const uploadFile = async ({ token, file, fileType }) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  };

  try {
    const { data } = await axios.post(
      APIs.uploadFile,
      { file, fileType },
      config
    );
    return data;
  } catch (error) {
    console.log("Upload Profile API Error ", error);
    const response = error?.response?.data;
    return response;
  }
};

export const getPrivacyPolicy = async (token = "") => {
  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    return handleGetPrivacyPolicyAuth(config);
  } else {
    return handleGetPrivacyPolicyNoAuth();
  }
};

export const resendEmailVerificationLink = async ({ token, email }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(`${APIs.resendVerificationLink}`, { accessToken: token, email }, config)
    return response.data;
  } catch (err) {
    return err.response.data
  }
}

export default async function verifyEmail(token) {
  try {
    const response = await axios.post(`${APIs.verifyEmail}`, { verificationToken: token })
    return response?.data
  } catch (err) {
    return err?.response?.data
  }
}

const handleGetPrivacyPolicyAuth = async (config) => {
  try {
    const { data } = await axios.get(`${APIs.getCustomerPolicyAuth}`, config);
    return data;
  } catch (error) {
    console.log("Get Privacy Policy Auth API Error: ", error);
    const response = error?.response?.data;
    return response;
  }
};

const handleGetPrivacyPolicyNoAuth = async () => {
  try {
    const { data } = await axios.get(`${APIs.getCustomerPolicy}`);
    return data;
  } catch (error) {
    console.log("Get Privacy Policy No Auth API Error: ", error);
  }
};


export const applyCoupon = async (token, couponId, amount=0) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const payload = {
    couponId: couponId,
    amount: amount
  }
  try {
    const { data } = await axios.post(`${APIs.applyCoupon}`, payload, config);
    console.log('apply coupon data', data)
    return data;
  } catch (error) {
    console.log('Apply coupon error')
  }
}
