import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { Box } from "@mui/material";
import moment from "moment";
import PlaceholderImage from "../../../../../../../assets/images/logo.png";
import { ReactSVG } from "react-svg";
import ClockSvg from "../../../../../../../assets/images/clock.svg";
import PropTypes from "prop-types";
import planelineSvg from "../../../../../../../assets/images/plane-line.svg";
import BaggageSvg from "../../../../../../../assets/images/baggage.svg";
import FlightDetailsMobile from "../../../cards/flight-details-mobile";
import eventBus from "../../../../../../../core/eventBus";
import CheckPrice from "../check-price";
import {
  addDurationStrings,
  calculateFlightDurationInHoursAndMinutes,
  convertAccumulatedTime,
  flightDurationInHoursAndMinutes,
} from "../../../../../../../utils/helpers";
import FlightDetails from "../../../cards/flight-details";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const S3Bucket = process.env.REACT_APP_S3_BUCKET;

const BookFlightMobile = ({ flight, handleBookNow }) => {
  const style = useStyles();
  const { t } = useTranslation();
  const [markup, setMarkup] = useState(0);
  const [showFlightDetails, setShowFlightDetails] = useState(false);
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);

  const {
    Segments,
    Fare: { TotalFare, BaseFare, Tax, OtherCharges, VATAmount },
  } = flight[0];

  let BaseFareSAR =
    Math.ceil(TotalFare * rateOfExchange) -
    Math.ceil(Tax * rateOfExchange) -
    Math.ceil(VATAmount * rateOfExchange);
  const {
    AirlineDetails: { AirlineCode, AirlineName, FlightNumber },
    Origin: {
      // Airport: { CityName: origin },
      CityName: origin,
      // DepTime,
    },
    DepartureTime: DepTime,
    CabinBaggage,
    Baggage,
    Duration: departureDuration,
    Destination: { ArrTime: layoverDepTime },
    AccumulatedDuration: firstAccumulatedDuration,
  } = Segments[0][0];
  const {
    Destination: {
      // Airport: { CityName: destination },
      CityName: destination,
      // ArrTime,
    },
    ArrivalTime: ArrTime,
    Duration: arrivalDuration,
    Origin: { DepTime: layoverArrTime },
    AccumulatedDuration: lastAccumulatedDuration,
  } = Segments[0][Segments[0]?.length - 1];

  const timeString = (dateTime) => {
    const dateTimeMoment = moment(dateTime);
    const formattedTime = dateTimeMoment.format("HH:mm");
    return formattedTime;
  };
  const length = Segments[0]?.length;
  const flightTravelType =
    length === 1
      ? `${t("flightSearch.direct")}`
      : length === 2
      ? `${length - 1} ${t("flightSearch.stop")}`
      : `${length - 1} ${t("flightSearch.stops")}`;

  const handleCheckPriceBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <CheckPrice
        flights={flight}
        handleBookNow={handleBookNow}
        markupDetails={flight[0].markup}
      />
    );
  };

  const handleFlightDetailsBottomSheet = () => {
    eventBus.emit(
      "open-bottomSheet",
      <FlightDetailsMobile
        flight={flight[0]}
        handleBookNow={() => {
          handleBookNow(selected);
        }}
        markup={markup}
      />
    );
  };

  useEffect(() => {
    getMarkup(flight[0]?.markup);
  }, [BaseFare, TotalFare, OtherCharges]);

  const getMarkup = (markupDetails) => {
    if (!markupDetails || Object.keys(markupDetails).length === 0) {
      return;
    }

    let amt = 0;
    const { amount, amountType, appliedOn, maxAmount } = markupDetails;
    if (amountType === "FLAT") {
      amt = amount < maxAmount ? amount : maxAmount;
    } else {
      amt = amount < maxAmount ? amount : maxAmount;
      if (appliedOn === "BASEFARE") {
        amt = (amt * (BaseFareSAR + OtherCharges)) / 100;
      } else {
        amt = (amt * TotalFare * rateOfExchange) / 100;
      }
    }
    amt = maxAmount < amt ? maxAmount : amt;
    setMarkup(Math.ceil(amt));
  };

  const layoverTime = calculateFlightDurationInHoursAndMinutes(
    layoverDepTime,
    layoverArrTime
  );
  const totalFlightDuration = flightDurationInHoursAndMinutes(
    departureDuration + arrivalDuration
  );
  return (
    <Box className={style.bookFlightMobile}>
      <div className={style.dynamicContainer}>
        <div className={style.flightDetailsCard}>
          <div className={style.airLinesDetails}>
            <img
              src={`${S3Bucket}/${process.env.REACT_APP_ENVIRONMENT == "development"? "Dev" : "Prod"}/AIRLINE_ICON/${AirlineCode}`}
              onError={(e) => {
                e.target.src = PlaceholderImage;
              }}
              style={{ height: "36px", width: "42px" }}
            ></img>
            <div></div>
            <div className={style.airlineNameLayout}>
              <div className={style.airLinesName}>{AirlineName}</div>
              <div className={style.airLineCode}>
                {AirlineCode}-{FlightNumber}
              </div>
            </div>
          </div>
          <div className={style.flightFromDetails}>
            <div className={style.departureDate}>
              {DepTime && timeString(DepTime)}
            </div>
            <div className={style.flightFrom}>{origin}</div>
          </div>
          <div className={style.flightTime}>
            <div className={style.flightDuration}>
              <ReactSVG
                src={ClockSvg}
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
              <div className={style.durationTime}>
                {firstAccumulatedDuration > 0
                  ? flightDurationInHoursAndMinutes(firstAccumulatedDuration)
                  : lastAccumulatedDuration > 0
                  ? flightDurationInHoursAndMinutes(lastAccumulatedDuration)
                  : convertAccumulatedTime(lastAccumulatedDuration)}
                {/* {addDurationStrings(totalFlightDuration, layoverTime)} */}
              </div>
            </div>
            <div className={style.flightTravelType}>
              <div
                style={{
                  width: "24px",
                  height: "1px",
                  background: "#D0D5DD",
                }}
              ></div>
              <div className={style.flightTravelTypeText}>
                {flightTravelType}
              </div>
              <div
                style={{
                  width: "24px",
                  height: "1px",
                  background: "#D0D5DD",
                }}
              ></div>
              <ReactSVG src={planelineSvg} style={{ marginTop: "4px" }} />
            </div>
          </div>
          <div className={style.flightToDetails}>
            <div className={style.departureDate}>
              {ArrTime && timeString(ArrTime)}
            </div>
            <div className={style.flightFrom}>{destination}</div>
          </div>
        </div>
        <Box className={style.fairDetailscard}>
          <Box className={style.baggageOption}>
            <Box
              className={style.baggageOptionLayout}
              onClick={() => handleFlightDetailsBottomSheet()}
            >
              <div className={style.baggageText}>
                {t("flightSearch.flightDetails")}
              </div>
            </Box>
          </Box>
          <Box className={style.fairText}>
            <div className={style.fairTextLayout}>
              <div>{t("flightSearch.currencySymbol")}</div>{" "}
              {(Math.ceil(TotalFare * rateOfExchange) + markup).toLocaleString(
                "en-IN"
              )}
            </div>
          </Box>
        </Box>
      </div>

      {showFlightDetails && (
        <FlightDetails
          setShowFlightDetailsModal={setShowFlightDetails}
          showFlightDetailsModal={showFlightDetails}
          flight={flight[0]}
          markup={markup}
        />
      )}
      {flight?.length > 1 ? (
        <button
          className={style.buttonLayout}
          onClick={handleCheckPriceBottomSheet}
        >
          {t("flightSearch.checkPrice")}
        </button>
      ) : (
        <button
          className={style.buttonLayout}
          onClick={() => handleBookNow(flight[0], flight[0]?.markup)}
        >
          {t("flightSearch.bookNow")}
        </button>
      )}
    </Box>
  );
};

export default BookFlightMobile;
BookFlightMobile.propTypes = {
  flight: PropTypes.array,
  handleBookNow: PropTypes.func,
};
