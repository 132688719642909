import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  articleCard: {
    width: "100%",
    height: "450px",
    borderRadius: "10px",
    position: "relative",
    zIndex: "10",
    margin: "10px",
    marginTop: "-20px",
  },
  articleCardImageWrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "10px",
  },
  articleCardImage: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    "-webkit-transition": "all .5s",
    "-moz-transition": "all .5s",
    "-o-transition": "all .5s",
    transition: "all .5s",
    cursor: "pointer",

    "&:hover": {
      transform: "scale(1.2)",
    },

    "&:hover + $articleCardDescription": {
      transform: "translateY(0)", // Move the description div upward on image hover
    },
  },
  articleCardDescription: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "auto",
    background: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    padding: "10px",
    boxSizing: "border-box",
    transform: "translateY(100%)",
    transition: "transform .5s",
  },
  articleCardTime: {
    padding: "12px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    position: "absolute",
    top: "40px",
    left: "40px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  articleCardTimeMonth: {
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
    textTransform: "uppercase",
  },
  articleCardTimeDay: {
    fontSize: theme.fontSizes.fontSize1,
    fontWeight: "700",
  },
  articleCardBody: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    padding: "12px",
    position: "absolute",
    bottom: "-50px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    zIndex: "100",
    left: "32px",
    height: "110px",
    width: "calc(100% - 64px)",
    transition: "all .5s",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
    // border: "1px solid #D0D5DD",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "40px",
    },
  },

  articleCardBodyTitle: {
    color: theme.palette.entityColors.entityColorPrimary,
    fontSize: "16px",
    height: "40px",
    lineHeight: "normal",
    marginBottom: "10px",
    fontWeight: "500",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },

    [theme.breakpoints.down("lg")]: {
      fontSize: "14px",
    },
  },
  articleCardBodyDesc: {
    color: theme.palette.textColors.textPrimaryColor,
    fontSize: theme.fontSizes.fontSize2,
    maxHeight: "3.6em",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },

    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  moveBodyUp: {
    [theme.breakpoints.up("md")]: {
      transform: "translateY(-90px)",
    },
  },
}));

export default useStyles;
