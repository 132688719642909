import React from "react";
import { ReactSVG } from "react-svg";
import FlightSearchWidget from "./flight-search-widget";
import CrossSvg from "../../../../../../assets/images/x-close.svg";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "./style.css";
import { t } from "i18next";

const ModifySearch = ({ setShowModifySearch }) => {
  const { flightTravelType } = useSelector((state) => state.searchFlight);
  return (
    <div
      className={
        flightTravelType === 3
          ? "modify-search-container-multi-city"
          : "modify-search-container"
      }
    >
      <div className="multi-city-component" style={{ width: "100%" }}>
        <div className="modify-search-header">
          <div className="modify-search-title">
            {t("flightSearch.modifySearch")}
          </div>
          <ReactSVG
            src={CrossSvg}
            className="modify-search-close-icon"
            onClick={() => setShowModifySearch(false)}
          />
        </div>
        <FlightSearchWidget
          activeColor={"#E6E6FA"}
          nonActiveColor={"#fff"}
          setShowModifySearch={setShowModifySearch}
        />
      </div>
    </div>
  );
};

export default ModifySearch;

ModifySearch.propTypes = {
  setShowModifySearch: PropTypes.func,
};
