import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import AlertSvg from "../../../../assets/images/alertSvg.svg";
import { useTranslation } from "react-i18next";

const ChangePrice = ({
  showChangePriceModal,
  setShowChangePriceModal,
  prevFare = [],
  updatedFare = [],
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const previousFare = JSON.parse(prevFare);
  const styles = useStyles();
  console.log("updatedPrice", updatedFare, prevFare);
  const handleChooseNewFlight = () => {
    navigate("/");
  };

  const handleContinueWithUpdatedFare = () => {
    setShowChangePriceModal(false);
  };

  const {t}  = useTranslation()
  return (
    <Dialog
      fullScreen={fullScreen}
      open={showChangePriceModal}
      onClose={() => setShowChangePriceModal(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={styles.mainContainer}>
        <DialogTitle id="responsive-dialog-title" className={styles.title}>
          <div className={styles.titleContainer}>
            <img src={AlertSvg} className={styles.titleIcon} />
            <p className={styles.title}>
              {/* Please take a moment to review the changes to the flight price. */}
              {t("addContactDetailsScreen.takeAMomentToReviewPrice")}
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={styles.contentContainer}>
            {previousFare.map((fare, i) => {
              return (
                Math.ceil(fare) != Math.ceil(updatedFare[i]) && (
                  <div className={styles.fareContainer}>
                    <DialogContentText>
                      <div className={styles.fareText}>
                        {/* Previous Amount: */}
                        {t("addContactDetailsScreen.previousAmount")}
                        <p className={styles.previousFareValue}>
                          {Math.ceil(fare)}
                        </p>
                      </div>
                    </DialogContentText>
                    <DialogContentText>
                      <div className={styles.fareText}>
                        {/* Updated Amount: */}
                        {t("addContactDetailsScreen.updatedAmount")}
                        <p className={styles.updatedFareValue}>
                          {Math.ceil(updatedFare[i])}
                        </p>
                      </div>
                    </DialogContentText>
                  </div>
                )
              );
            })}
            <p className={styles.info}>
              {/* Due to dynamic pricing and demand fluctuations, flight prices can
              change frequently. */}
              {t("addContactDetailsScreen.dueToDynamicPricing")}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <div onClick={handleChooseNewFlight} className={styles.goBackButton}>
            {/* Cancel Booking */}
            {t("addContactDetailsScreen.cancelBooking")}
          </div>
          <div
            onClick={handleContinueWithUpdatedFare}
            className={styles.successButton}
          >
            {/* Continue */}
            {t("addContactDetailsScreen.continue")}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ChangePrice;
