import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D0D5DD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
  mainContainer: {
    borderRadius: "12px",
    border: "1px solid #D0D5DD",
    height: 'fit-content',
    // height: "200px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "12px",
      flex: 1,
      margin: "0px 10px 10px 10px",
    },
  },
  applyCouponContainer: {
    borderRadius: "12px 12px 0px 0px",
    background: "#E6E6FA",
    display: "inline-flex",
    gap: "8px",
    padding: "24px 16px",
    width: "100%",
  },
  applyCoupon: {
    color: "#344054",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  couponInputContainer: {
    padding: "12px 16px 24px 16px",
  },
  viewAllContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
    gap: "8px",
    marginTop: "24px",
    width: "100%",
  },
  viewAllText: {
    color: "#08F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    cursor: "pointer",
  },
  couponInput: {
    padding: "4px 16px 4px 0px",
    cursor: "pointer",
  },
  successText: {
    color: "green",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    marginTop: "8px",
  },
  applyText:{
    color: "#4037acd6",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    cursor: "pointer",
    display: 'flex',
    justifyContent: 'flex-end',
    // marginTop: '15px'
  },
}));

export default useStyles;