import React, { useState } from "react";
import useStyles from "./styles";
import planeTakeOffBlue from "../../../assets/images/plane-take-off-blue.svg";
import hotelIcon from "../../../assets/images/hotel.svg";
import SearchAirport from "../../search-widget/components/flight-search-widget/dropdowns/search-airport";
import { Box, Button, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import dayjs from "dayjs";
import FlightClass from "../../search-widget/components/flight-search-widget/dropdowns/flight-class";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TotalTraveller from "../../search-widget/components/flight-search-widget/dropdowns/total-persons";

import { FlightTakeoff, Hotel } from "@mui/icons-material";
import { MobileCitySelectionField } from "../../../ui/components/MobileCitySelectionField";
import { MobileHotelFilterView } from "../../../ui/views/MobileHotelFilter";
import { MobileOneWayFlightFilterView } from "../../../ui/views/MobileOneWayFlightFilter";
import { MobileRoundTripFlightFilterView } from "../../../ui/views/MobileRoundTripFlightFilter";
import { MobileMultiCityFlightFilterView } from "../../../ui/views/MobileMultiCityFlightFilter";
import { useDispatch, useSelector } from "react-redux";
import { updateFlightTravelType } from "../../../utils/slices/searchFlightSlice";
import { changeSearchWidgetType } from "../../../utils/slices/globalSlice";
import { useNavigate } from "react-router-dom";
import DeckIcon from "@mui/icons-material/Deck";
import { useTranslation } from "react-i18next";

const SearchWidgetMobile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();

  const { flightTravelType } = useSelector((state) => state.searchFlight);

  const MOBILE_FIGHT_FILTERS_MAP = {
    oneWay: MobileOneWayFlightFilterView,
    roundTrip: MobileOneWayFlightFilterView,
    multiCity: MobileMultiCityFlightFilterView,
  };

  const [tabsState, setTabsState] = useState({
    primaryTabValue: "flight",
    secondaryTabValue:
      flightTravelType == 1
        ? "oneWay"
        : flightTravelType == 2
        ? "roundTrip"
        : "multiCity",
  });
  const [isHotelCLicked, setHotelClicked] = useState(false);
  const [isFlightClicked, setFlightClicked] = useState(false);
  const [isOneWayClicked, setOneWayClicked] = useState(false);
  const [isRoundClicked, setRoundClicked] = useState(false);
  const searchWidgetType = useSelector(
    (state) => state.global.searchWidgetType
  );
  const handelSetHotelClicked = () => {
    setHotelClicked(!isHotelCLicked);
    setFlightClicked(false);
  };

  const handelSetFligtClicked = () => {
    setHotelClicked(!isFlightClicked);
    setHotelClicked(false);
  };
  const handelOneWayClicked = () => {
    setOneWayClicked(!isOneWayClicked);
    setRoundClicked(false);
  };

  const handelRoundClicked = () => {
    setRoundClicked(!isRoundClicked);
    setOneWayClicked(false);
  };

  const setPrimaryTab = (name) => {
    setTabsState((prev) => ({ ...prev, primaryTabValue: name }));
  };

  const setSecondaryTab = (name) => {
    setTabsState((prev) => ({ ...prev, secondaryTabValue: name }));
  };

  const FlightFilterView =
    MOBILE_FIGHT_FILTERS_MAP[tabsState.secondaryTabValue];

  return (
    <div className={styles.mobileSearchWidget}>
      <div className={styles.mobileSearchWidgetTabs}>
        <button
          onClick={() => dispatch(changeSearchWidgetType(1))}
          className={
            searchWidgetType === 1
              ? styles.primaryTabActive
              : styles.primaryTabInActive
          }
        >
          <FlightTakeoff />
          {t("widgetTypeMobile.flight")}
        </button>
        <button
          onClick={() => dispatch(changeSearchWidgetType(0))}
          className={
            searchWidgetType === 0
              ? styles.primaryTabActive
              : styles.primaryTabInActive
          }
        >
          <Hotel />
          {t("widgetTypeMobile.hotel")}
        </button>
        <button
          onClick={() => navigate("/packages/search")}
          className={styles.primaryTabInActive}
        >
          <DeckIcon />
          {t("widgetTypeMobile.packages")}
        </button>
      </div>

      {searchWidgetType === 1 ? (
        <div className={styles.mobileSearchWidgetWrapper}>
          <div className={styles.mobileSearchWidgetSecondaryTabs}>
            <button
              onClick={() => {
                setSecondaryTab("oneWay");
                dispatch(updateFlightTravelType(1));
              }}
              className={`${
                localStorage.getItem("language") == "AR"
                  ? styles.secondaryTab1Arabic
                  : styles.secondaryTab1
              } ${
                tabsState.secondaryTabValue === "oneWay"
                  ? styles.activeSecondaryTab
                  : ""
              }`}
            >
              {t("flightSearchWidgetMobile.oneWay")}
            </button>
            <button
              onClick={() => {
                {
                  setSecondaryTab("roundTrip");
                  dispatch(updateFlightTravelType(2));
                }
              }}
              className={`${styles.secondaryTab2} ${
                tabsState.secondaryTabValue === "roundTrip"
                  ? styles.activeSecondaryTab
                  : ""
              }`}
            >
              {t("flightSearchWidgetMobile.roundTrip")}
            </button>
            {/* <button
              onClick={() => {
                setSecondaryTab("multiCity");
                dispatch(updateFlightTravelType(3));
              }}
              className={`${
                localStorage.getItem("language") == "AR"
                  ? styles.secondaryTab3Arabic
                  : styles.secondaryTab3
              } ${
                tabsState.secondaryTabValue === "multiCity"
                  ? styles.activeSecondaryTab
                  : ""
              }`}
            >
              {t("flightSearchWidgetMobile.multiCity")}
            </button> */}
          </div>

          <FlightFilterView
            isDisabled={tabsState.secondaryTabValue === "oneWay" ? true : false}
          />
        </div>
      ) : (
        <MobileHotelFilterView />
      )}
    </div>
    // <Box className={styles.searchWidgetMobile}>
    //   <Box className={styles.searchWidgetCard}>
    //     <Box className={styles.layoutOne}>
    //       <Box className={styles.searchType}>
    //         <Box className={isHotelCLicked ?styles.searchTypeLayout:styles.searchTypeLayoutActive} onClick={handelSetFligtClicked} >
    //           <ReactSVG src={planeTakeOffBlue}/>
    //           <Typography>Flight</Typography>
    //         </Box>
    //         <Box className={isHotelCLicked ?styles.searchTypeLayoutActive:styles.searchTypeLayout} onClick={handelSetHotelClicked}>
    //           <ReactSVG src={hotelIcon} />
    //           <Typography>Hotel</Typography>
    //         </Box>
    //       </Box>

    //       <Box className={styles.tripType}>
    //         <Box className={ isRoundClicked ? styles.tripTypeLayout:styles.tripTypeLayoutActive} onClick={handelOneWayClicked}>
    //           <Typography>One Way</Typography>
    //         </Box >
    //         <Box className={isRoundClicked ? styles.tripTypeLayoutActive: styles.tripTypeLayout} onClick={handelRoundClicked}>
    //           <Typography>Round Trip</Typography>
    //         </Box>
    //       </Box>
    //     </Box>
    //     <Box  className={styles.layoutTwo} >
    //       <Box >
    //         <SearchAirport searchAirportId={0} />
    //       </Box>
    //       {isRoundClicked ?

    //         <Box sx={{
    //           display:"flex"
    //         }}>
    //           <Box sx={{
    //             marginTop:"16px",
    //           }}>
    //             <LocalizationProvider dateAdapter={AdapterDayjs} >
    //               <DesktopDatePicker
    //                 format="DD/MM/YYYY"
    //                 className={styles.datePickerRoundTrip}
    //                 // onChange={(date) => {
    //                 //   const value = formatDateToYyyyMmDd(date);
    //                 //   setValues({ ...values, departureDate: value });
    //                 // }}
    //                 slotProps={{
    //                   textField: {
    //                     placeholder: "Select date",
    //                   },
    //                 }}
    //                 minDate={dayjs()}

    //               />
    //             </LocalizationProvider>
    //           </Box>
    //           <Box sx={{
    //             marginTop:"16px",
    //             marginLeft:"20px"
    //           }}>
    //             <LocalizationProvider dateAdapter={AdapterDayjs} >
    //               <DesktopDatePicker
    //                 format="DD/MM/YYYY"
    //                 className={styles.datePickerRoundTrip}
    //                 // onChange={(date) => {
    //                 //   const value = formatDateToYyyyMmDd(date);
    //                 //   setValues({ ...values, departureDate: value });
    //                 // }}
    //                 slotProps={{
    //                   textField: {
    //                     placeholder: "Select date",
    //                   },
    //                 }}
    //                 minDate={dayjs()}

    //               />
    //             </LocalizationProvider>
    //           </Box>
    //         </Box>
    //         :
    //         <Box sx={{
    //           marginTop:"16px",
    //           marginRight:"60px"
    //         }}>
    //           <LocalizationProvider dateAdapter={AdapterDayjs} >
    //             <DesktopDatePicker
    //               format="DD/MM/YYYY"
    //               className={styles.datePicker}
    //               // onChange={(date) => {
    //               //   const value = formatDateToYyyyMmDd(date);
    //               //   setValues({ ...values, departureDate: value });
    //               // }}
    //               slotProps={{
    //                 textField: {
    //                   placeholder: "Select date",
    //                 },
    //               }}
    //               minDate={dayjs()}

    //             />
    //           </LocalizationProvider>
    //         </Box>
    //       }
    //       <Box sx={{
    //         marginTop:"16px", marginRight:"60px"
    //       }} >
    //         <FlightClass/>
    //       </Box>

    //       <Box sx={{
    //         marginTop:"16px",
    //         marginRight:"60px"
    //       }}>
    //         <TotalTraveller />
    //       </Box>
    //     </Box>
    //     <button className={styles.button}> Search</button>
    //   </Box>
    // </Box>
  );
};

export default SearchWidgetMobile;
