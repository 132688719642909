import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 24px",
    backgroundColor: "#fff",
    marginTop: "18%",
    marginLeft: "34%",
    width: "32%",
    alignItems: "center",
    borderRadius: "10px",
    outline: "none",

    [theme.breakpoints.down("sm")]: {
      width: "70%",
      marginTop: "35%",
      marginLeft: "15%",
    },
  },
}));

export default useStyles;
