import { createSlice } from "@reduxjs/toolkit";

const localeSlice = createSlice({
  name: "locale",
  initialState: {
    nationality: "IN",
    language: "EN",
  },
  reducers: {
    setNationality: (state, action) => {
      state.nationality = action.payload;
    },

    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setNationality, setLanguage } = localeSlice.actions;

export default localeSlice.reducer;
