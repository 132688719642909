import React from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

const PhoneNumberInputField = ({
  classes,
  values,
  setValues,
  valid,
  setValid,
  customStyles,
  isMobile,
  countryInfoList,
  MobileSvg,
  countryInputStyleMobile,
}) => {
  const { t } = useTranslation();

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const truncatedValue = inputValue.substring(0, 15);
    const reg = /^[0-9-()+ ]+$/;
    const isLengthValid = truncatedValue.length >= 8;
    const isFormatValid = reg.test(truncatedValue);

    let validStatuses = [...valid];
    validStatuses[2] = isLengthValid && isFormatValid;
    setValid(validStatuses);
    setValues({ ...values, phoneNo: truncatedValue });
  };

  const handleCountryCodeChange = (e) => {
    setValues({ ...values, countryCode: e.target.value });
  };

  return (
    <div
      className="phoneNumberInputContainer"
      style={{ display: "inline-flex", gap: "16px" }}
    >
      <div
        className={""}
        style={{ position: "relative", width: isMobile ? "30%" : "21%" }}
      >
        <select
          value={values.countryCode}
          onChange={handleCountryCodeChange}
          style={{
            ...customStyles,
            ...(isMobile ? countryInputStyleMobile : {}),
            width: "100%",
            height: "100%",
            minHeight: "0px",
            alignItems: "normal",
            fontSize: "12px",
            padding: "12px 10px",
            border: '1px solid #D1D5DD'
        }}
        >
          <option value="+966">+966</option>
          {Object.keys(countryInfoList).map((countryCode) => (
            <option
              key={countryCode}
              value={countryInfoList[countryCode].callingCode}
            >
              {countryInfoList[countryCode].callingCode}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{ position: "relative", width: isMobile ? "70%" : "79%" }}
      >
        <div
          style={{
            position: "absolute",
            top: "8px",
            left: "12px",
            padding: "0px 4px 0px 0px",
          }}
        >
          <ReactSVG src={MobileSvg} />
        </div>
        <input
          type="tel"
          placeholder={t("packages.enterPhoneNumber")}
          value={values.phoneNo}
          style={{
            ...customStyles,
            minHeight: "0px",
            alignItems: "normal",
            width: "100%",
            paddingLeft: "36px", // Adjust padding to make room for the icon
            border: '1px solid #D1D5DD'

          }}
          onChange={handlePhoneNumberChange}
          className={!valid[2] ? "input-error" : ""}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInputField;
