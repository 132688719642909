import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#FAFAFA",
    display: "inline-flex",
    gap: "20px",
    justifyContent: "center",
    width: "100%",
    minHeight: "600px",
    padding: "48px 128px",

    [theme.breakpoints.down("sm")]: {
      padding: "24px 0px",
      minHeight: "400px",
    },
  },
  ringsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flightMainMapContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#E8F4FF",
    minHeight: "930px",
  },
  flightMapCotainer: {
    display: "inline-flex",
    gap: "120px",
  },
  flightImage: {
    height: "500px",
    marginLeft: "60px",
    marginTop: "10px",
  },
  skipSeatAndContinueBtnContainer: {
    display: "inline-flex",
    gap: "6px",
    alignItems: "center",
    justifyItems: "flex-end",

    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      justiyItems: "flex-end",
    },
  },
  skipSeatText: {
    color: "#1b1d52",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  continueButtonCotainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    padding: "10px 12px",
    borderRadius: "8px",
    border: "1px solid #1b1d52",
    background: "#1b1d52",
    cursor: "pointer",
  },
  continueText: {
    color: "#fff",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  previousTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    cursor: "pointer",
  },
  previousText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  previousSkipContinueContainer: {
    display: "inline-flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      marginBottom: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "80px",
    },
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      padding: "0px 10px",
    },
  },
  rightCotainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "40%",
  },
  totalPriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  totalPriceText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  price: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
  },
  cartFairRulesAndPriceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px",
  },
  couponContainer: {
    borderRadius: "9px 9px 0px 0px",
    border: "1px solid #D0D5DD",
  },
  drtMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    padding: "24px 20px",
  },
  drtPriceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  drtTotalPriceText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
  },
  drtPrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
  },
  segmentSeatsContainer: {
    display: "inline-flex",
    gap: "18px",
    overflowX: "scroll",
    overflowY: "scroll",
    zIndex: "80",
    // position: "absolute",
  },
  seatMainContainer: {
    display: "inline-flex",
    gap: "18px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#E6E6FA",
    minWidth: "100%",
    height: "800px",
    overflowY: "scroll",

    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  seatInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    minWidth: "100%",
  },
  plainFrontContainer: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      margin: "40px",
    },
  },
  plainFrontImage: {
    width: "250px",
  },
  rowSeatsContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "25px",
    width: "250px",
    backgroundColor: "#FFF",
    gap: "4px",
  },
  seatsContainer: {
    display: "inline-flex",
    gap: "4px",
    // justifyContent: "center",
  },
  tippyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "160px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
  },
  priceContainerNew: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  priceNew: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  priceCurrency: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  codeContainer: {
    fontSize: "10px",
    fontFamily: "Lato, sans-serif",
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    marginLeft: "30px",
    color: "#344054",
    borderRadius: "4px",
    fontWeight: 400,
    padding: "4px 6px",
    cursor: "pointer",
  },
  priceThirdContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "160px",
    padding: "16px",
    background: "#344054",
    borderRadius: "4px",
  },
  priceThird: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "8px",
  },
  priceCurrencyThird: {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
  },
  codeThirdContainer: {
    fontWeight: 400,
    padding: "4px",
    fontSize: "10px",
    fontFamily: "Lato, sans-serif",
    height: "24px",
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
    color: "#344054",
    borderRadius: "4px",
    cursor: "pointer",
  },
  previousContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    padding: "8px 12px",
    borderRadius: "8px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "4px 6px",
    },
  },
  insuranceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tripInsurance: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tripInsuranceAmount: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baggageContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  extraBaggage: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  extraBaggagePrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  seatsDetailsContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px 28px",
    minWidth: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "0px",
      alignItems: "flex-start",
      minWidth: "100%",
      padding: "0px 16px",
    },
  },
  seatsDetailsSideContainer: {
    borderRadius: "5px",
    backgroundColor: "white",
    padding: "16px 24px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: "80px",
    position: "sticky",

    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      padding: "16px",
      marginTop: "0px",
    },
  },
  seatsDetailsSideInfo: {
    display: "inline-flex",
    gap: "4px",
  },
  seatsDetailsInfoText: {
    fontSize: "14px",
    fontFamily: "lato, sans-serif",
    color: "#344054",
    fontWeight: "400",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  seatsDetailsSideCheckbox: {
    height: "16px",
    width: "16px",
    borderRadius: "2px",
  },
  container: {
    position: "fixed",
    bottom: "0",
    zIndex: 11,
  },

  fullFairDetailsContainer: {
    marginTop: "-120px",
    background: "#E6E6FA",
    boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.14)",
    width: "100%",
  },

  FairDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  taxesAndFeesText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFareAndTaxesFessContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },

  taxesAndFees: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFessContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },

  baseFare: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  expandIcon: {
    marginRight: "10px",
    color: "#4037acd6",
    padding: "1px",
  },

  fareDetails: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #D0D5DD",
  },

  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  baseFareText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  bookNowButton: {
    display: "inline-flex",
    padding: "10px 14px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    borderRadius: "8px",
    background: "#1b1d52",
    border: "none",
    color: "#FFF",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  fair: {
    color: "#344054",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "normal",
  },

  fairLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  totalText: {
    color: "#667085",
    textAlign: "right",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  fairContainer: {
    display: "flex",
    width: "100vW",
    padding: "16px",
    background: "#FFF",
    boxShadow: "0px -8px 12px 0px rgba(0, 0, 0, 0.12)",
    justifyContent: "space-between",
  },
  fairCardLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
  },
  arrowsContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    position: "absolute",
    width: "100%",
    alignItems: "center",
    marginTop: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "95vw",
      marginTop: "60%",
    },
  },
  arrowIcons: {
    width: "42px",
    height: "42px",
    borderRadius: "50%",
    background: "#FFF",
    zIndex: "102",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 2px 32px 0px rgba(0, 0, 0, 0.40)",
    cursor: "pointer",
    animation: "$hoverAnimation 1s infinite",
  },
  outerArrowContainer: {
    zIndex: "102",
  },
  "@keyframes hoverAnimation": {
    "0%": {
      backgroundColor: "#FFF",
      transform: "scale(1)",
    },
    "50%": {
      backgroundColor: "#F0F0F0",
      transform: "scale(1.1)",
    },
    "100%": {
      backgroundColor: "#FFF",
      transform: "scale(1)",
    },
  },
}));

export default useStyles;
