import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    padding: "40px",
  },
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    width: '500px',
    maxWidth: '90vw',
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    padding: '25px',
    margin: '25px'
  },

  passwordInput: {
    width: "400px",
    maxWidth: '80vw',
    height: "40px",
    border: "1px solid lightgrey",
    borderRadius: "6px",
    padding: "6px",
  },

  passwordText: {
    fontSize: "14px",
    fontFamily: "Lato",
    margin: "4px 0px",
  },

  button: {
    padding: "8px 10px",
    color: "#fff",
    borderRadius: "6px",
    border: "1px solid #1b1d52",
    background: "#1b1d52",
    marginTop: '20px'
  },
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "14px",
    width: "400px",
    maxWidth: '80vw',
  },
  passwordContainer: {
    display: "flex",
  },
}));

export default useStyles;
