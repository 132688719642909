import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputFieldWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "12px",
    },
  },
  inputField: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    border: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    borderRadius: "10px",
  },
  inputFieldEntity: {
    width: "100%",
    border: "none",
    fontSize: theme.fontSizes.fontSize1,
    backgroundColor: theme.palette.entityColors.entityColor1,
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize1,
    },
  },
}));

export default useStyles;
