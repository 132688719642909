import { makeStyles } from "@mui/styles";

const useStyles2 = makeStyles((theme) => ({
  countAndRoomDetailsContainer: {
    display: 'flex',
  },
  roomDetails: {
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    padding: "10px 16px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    // border: "1px solid black",
    margin: "10px 0",
  },
  paddingRight: "0px",
  roomDetailsBody: {
    display: "flex",
    columnGap: "32px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  roomDetailsImage: {
    width: "370px",
    height: "245px",
    borderRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  roomDetailsName: {
    fontSize: "20px",
    fontWeight: 700,
    width: "70%",
    minWidth: "100px",
  },
  roomDetailsDescription: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    paddingTop: "0px",
  },
  roomDetailsBullet: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },
  roomDetailsBulletWrapper: {
    display: "flex",
    columnGap: "8px",
  },
  roomDetailsAmenitiesWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  roomDetailsAmenities: {
    display: "flex",
    width: "fit-content",
    minWidth: "100px",
    flexWrap: "wrap",
    rowGap: "14px",
    justifyContent: "flex-start",
    columnGap: "160px",
  },
  roomPriceOptions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  roomDetailsAmenitiesValue: {
    fontSize: "14px",
  },
  roomDetailsAmenitiesPrice: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  roomDetailsAmenitiesPriceValue: {
    fontSize: theme.fontSizes.fontSize1,
    fontWeight: "700",
  },
  roomDetailsAmenitiesBookNow: {
    padding: "12px 16px",
    borderRadius: "10px",
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    color: theme.palette.entityColors.entityColorBackground1,
    cursor: "pointer",
    border: "none",
    fontWeight: "400",
    fontSize: theme.fontSizes.fontSize2,
  },
  roomDetailsAndCancelationPolicyContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cancelationPolicyTooltip: {
    fontSize: "16px",
  },
  roomNameAndDescriptionContainer: {
    width: "80%",
  },
  cancellationPolicyText: {
    color: "#4037acd6",
    cursor: "pointer",
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "Lato, sans-serif",
    fontWeight: "400",
    lineHeight: "normal",
  },
  tableHeaderCell: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "bolder",
    border: '2px solid grey'
  },
  tableDataCell: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    border: '2px solid grey'
  },

  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    overflow: "scroll",
  },
  tippyTable: {
    width: '300px',
    maxWidth: '90vw'
  }
}));

export default useStyles2;
