import React, { useState } from "react";
import useStyles from "./styles";
import { get } from "lodash";
// import HotelSearchWidget from "../../../components/hotel-module/cards/modify-search/hotel-search-widget";
import HotelSearchWidget from "../../../components/search-widget/components/hotel-search-widget";
import eventBus from "../../../core/eventBus";
import { Modal } from "@mui/material";
import Close from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/Close";
import useIsTablet from "../../../hooks/useIsTablet.hook";
import { MobileHotelFilterView } from "../MobileHotelFilter/MobileHotelFilterView";
import { useTranslation } from "react-i18next";
const ModifySearch = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ width: "700px", padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <p>{t("hotelSearchResult.modifySearch")}</p>
          <CloseIcon
            onClick={() => {
              eventBus.emit("close-dialog");
            }}
          />
        </div>
        <MobileHotelFilterView />
      </div>
    </>
  );
};
export const SearchPageHeaderView = (props) => {
  const LeftView = get(props, "leftView", <></>);
  const { t } = useTranslation();
  const modifySearchEnabled = get(props, "modifySearchEnabled", true);
  const setModifySearchEnabled = get(
    props,
    "setModifySearchEnabled",
    function () {}
  );
  const setShowFilters = get(props, "setShowFilters", function () {});

  const styles = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const isTablet = useIsTablet();
  // const handleModifySearch = ()=>{
  //   eventBus.emit("open-dialog", <HotelSearchWidget/>);
  // };

  const handleModifySearch = () => {
    if (isTablet) {
      eventBus.emit("open-dialog", <ModifySearch />);
    } else {
      setOpenModal(true);
    }
  };
  return (
    <div className={styles.searchPageHeader}>
      <LeftView filterValues={get(props, "globalFilters", {})} />
      {/* <button className={styles.searchPageHeaderButton} onClick={handleModifySearch}>Modify Search</button> */}
      {modifySearchEnabled && (
        <button
          className={styles.searchPageHeaderButton}
          onClick={() => {
            if (modifySearchEnabled) {
              handleModifySearch();
            }
          }}
          disabled={modifySearchEnabled ? false : true}
        >
          {t("hotelSearchResult.modifySearch")}
        </button>
      )}

      {/* {!loading && showModifySearch && (
        <Modal open={showModifySearch}>
          <ModifySearch setShowModifySearch={setShowModifySearch} />
        </Modal>
      )} */}
      <Modal open={openModal} style={{ maxWidth: "content-width" }}>
        <div
          style={{
            maxWidth: "100%",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
            top: "90px",
            margin: "30px 150px 0px 150px",
          }}
        >
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "30px 30px 0px 30px",
            }}
          >
            <div
              style={{
                color: "#344054",
                fontFamily: "'Lato', sans-serif",
                fontSize: "16px",
                fontSstyle: "normal",
                fontWeight: 700,
              }}
            >
              Modify Search
            </div>
            <CloseIcon
              fontSize="medium"
              onClick={() => setOpenModal(false)}
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px",
              }}
            ></CloseIcon>
          </div> */}
          <HotelSearchWidget
            setOpenModal={setOpenModal}
            setModifySearchEnabled={setModifySearchEnabled}
            setShowFilters={setShowFilters}
          />
        </div>
      </Modal>
    </div>
  );
};
