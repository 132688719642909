import { createSlice } from "@reduxjs/toolkit";

const oneWayTripSlice = createSlice({
  name: "oneWayTrip",
  initialState: {
    searchedFlights: [],
    selectedFlight: []
  },
  reducers: {
    handleSearchedFlightsForOneWay: (state, action) => {
      state.searchedFlights = action.payload;
    },
    handleSelectedFlightForOneWay: (state, action) => {
      state.selectedFlight = action.payload;
    }
  },
});

export const {
  handleSearchedFlightsForOneWay,
  handleSelectedFlightForOneWay,
} = oneWayTripSlice.actions;

export default oneWayTripSlice.reducer;
