import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  hotelDetailsAboutCaption: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "16px",
  },
  hotelDetailsAboutDescriptionWrapper: {
    lineHeight: "1.6",
    listStylePosition: "inside",
  },
}));

export default useStyles;
