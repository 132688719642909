import React from "react";
import useStyles from "./style";
import { Box, Typography } from "@mui/material";
import crossIcon from "../../../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";
import eventBus from "../../../../../core/eventBus";
import { handleSortFilter } from "../../../../../utils/slices/flightFiltersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SearchingSortMobile = () => {
  const style = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sort } = useSelector((state) => state.flightFilters);
  return (
    <Box className={style.sortBottomDrawer}>
      <Box className={style.sortCard}>
        <Box className={style.title}>
          <ReactSVG
            src={crossIcon}
            onClick={() => eventBus.emit("close-bottomSheet")}
          />
          <Typography>{t("flightSearch.sortBy")}</Typography>
        </Box>

        <div className={style.sortList}>
          <div
            className={style.sortListLayout1}
            onClick={() => {
              dispatch(handleSortFilter(true));
              eventBus.emit("close-bottomSheet");
            }}
          >
            <p>{t("flightSearch.lowestPriceFirst")}</p>
          </div>
          <div
            className={style.sortListLayout}
            onClick={() => {
              dispatch(handleSortFilter(false));
              eventBus.emit("close-bottomSheet");
            }}
          >
            <p>{t("flightSearch.highestPriceFirst")}</p>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default SearchingSortMobile;
