import SunIcon from "../assets/images/sun.svg";
import SunsetIcon from "../assets/images/sunset.svg";
import EveningIcon from "../assets/images/evening.svg";
import NightIcon from "../assets/images/night.svg";

export const flightClassData = {
  2: "Economy",
  3: "Premium Economy",
  4: "Business",
  5: "Premium Business",
  6: "First Class",
};

export const flightClassDataArabic = {
  2: "الاقتصادية",
  3: "الاقتصادية الممتازة",
  4: "رجال الأعمال",
  5: "أعمال ممتازة",
  6: "تذكرة طيران",
};

export const flightTravelTypeData = {
  1: "One Way",
  2: "Round Trip",
  // 3: "Multi-City",
};

export const flightTravelTypeDataArabic = {
  1: "ذهاب فقط",
  2: "ذهاب وعودة",
  // 3: "متعددة المدن",
};

export const flightTypeData = {
  1: "Non Stop",
};
export const flightTypeDataArabic = {
  1: "مباشر",
};
export const moduleValue = "Z49sT";

export const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const arabicMonthShortNames = [
  "يناير",
  "فبراير",
  "مارس",
  "أبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const dayFullNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const arabicDayFullNames = [
  "الأحد",
  "الإثنين",
  "الثلاثاء",
  "الأربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];
export const adultsCountData = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const childrenCountData = [0, 1, 2, 3, 4, 5, 6];

export const InfantsCountData = [0, 1, 2, 3, 4, 5, 6];

export const stops = [
  {
    id: 0,
    key: "nonStop",
    name: "Non-stop",
  },
  {
    id: 1,
    key: "1Stop",
    name: "1 stop",
  },
  {
    id: 2,
    key: "2Stop",
    name: "2 stop",
  },
];

export const stopsArabic = [
  {
    id: 0,
    key: "nonStop",
    name: "مباشر",
  },
  {
    id: 1,
    key: "1Stop",
    name: "توقف واحد",
  },
  {
    id: 2,
    key: "2Stop",
    name: "توقفين",
  },
];

export const flightValue = "r47eL";

export const refundStatus = [
  {
    id: 1,
    key: "refundable",
    name: "Refundable",
  },
  {
    id: 2,
    key: "nonRefundable",
    name: "Non Refundable",
  },
  {
    id: 3,
    key: "partiallyRefundable",
    name: "Partially Refundable",
  },
];

export const refundStatusArabic = [
  {
    id: 1,
    key: "refundable",
    name: "قابل للاسترجاع",
  },
  {
    id: 2,
    key: "nonRefundable",
    name: "غير قابل للاسترجاع",
  },
  {
    id: 3,
    key: "partiallyRefundable",
    name: "قابل للاسترجاع جزئياً",
  },
];
export const airlines = [
  {
    id: 1,
    key: "airAsia",
    name: "Air Asia",
    total: 9,
  },
  {
    id: 2,
    key: "airIndia",
    name: "Air India",
    total: 5,
  },
  {
    id: 3,
    key: "indigo",
    name: "Indigo",
    total: 10,
  },
  {
    id: 4,
    key: "vistara",
    name: "Vistara",
    total: 3,
  },
  {
    id: 5,
    key: "spiceJet",
    name: "SpiceJet",
    total: 5,
  },
  {
    id: 6,
    key: "akasaAir",
    name: "Akasa Air",
    total: 10,
  },
  {
    id: 7,
    key: "vistara",
    name: "Vistara",
    total: 3,
  },
];

export const timings = [
  {
    id: 1,
    key: "before6",
    name: "Before 6 AM",
    icon: SunsetIcon,
  },
  {
    id: 2,
    key: "6To12",
    name: "6 AM - 12 PM",
    icon: SunIcon,
  },
  {
    id: 3,
    key: "12To6",
    name: "12 PM - 6 PM",
    icon: EveningIcon,
  },
  {
    id: 4,
    key: "after6",
    name: "After 6 PM",
    icon: NightIcon,
  },
];

export const timingsArabic = [
  {
    id: 1,
    key: "before6",
    name: "قبل الساعة 6 صباحًا ",
    icon: SunsetIcon,
  },
  {
    id: 2,
    key: "6To12",
    name: "6 صباحًا - 12 ظهرًا",
    icon: SunIcon,
  },
  {
    id: 3,
    key: "12To6",
    name: "12 نخلة - 6 مساءً",
    icon: EveningIcon,
  },
  {
    id: 4,
    key: "after6",
    name: "بعد الساعة 6 مساءً",
    icon: NightIcon,
  },
];

export const hotelValue = "2o2";

export const nationality = [
  {
    label: "",
  },

  {
    value: "India",
    label: "India",
  },
  {
    value: "Usa",
    label: "Usa",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
];

export const coupons = [
  {
    id: 1,
    name: "CTKOTAK",
    desc: "Flat 12% off with Kotak Bank Credit Cards & EMI Transaction",
  },
  {
    id: 2,
    name: "CTKOTAK",
    desc: "Flat 12% off with Kotak Bank Credit Cards & EMI Transaction",
  },
];

export const packagesFlightCategories = ["with flight", "without flight"];

export const childrenAge = [
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 11,
    label: 11,
  },
  {
    value: 12,
    label: 12,
  },
];
