import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Otp from "../../Auth/Otp";
import Login from "../../Auth/Login";
import Signup from "../../Auth/Signup";
import Logout from "../../Auth/logout";
import CustomerSupport from "./components/customer-support";
import {
  setLoginPopup,
  handleRedirectUrl,
  setEmailConfirmation,
} from "../../utils/slices/popupSlice";
import { Divider, Menu, Modal, SwipeableDrawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/images/joyus logo1.svg";
import FlagSvg from "../../assets/images/india-flag.svg";
import LoginSvg from "../../assets/images/login-icon.svg";
import ProfileSvg from "../../assets/images/profile.svg";
import BookingSvg from "../../assets/images/luggage.svg";
import LogoutSvg from "../../assets/images/logout.svg";
import useIsMobile from "../../hooks/useIsMobile.hook";
import userIconBlue from "../../assets/images/profile_icon.svg";
import useStyles from "./styles";
import NavigationDrawer from "./components/navigation-drawer";
import eventBus from "../../core/eventBus";
import { MobileOtpEnter } from "./components/mobile-otp-enter";
import MobileLogoutPopup from "./components/mobile-log-out-popup";
import "./style.css";
import { useLocation } from "react-router-dom";
import closeIcon from "../../assets/images/x-close.svg";
import KSAFlag from "../../assets/images/saudi-arabia-flag.svg";
import UKFlag from "../../assets/images/united-kingdom-flag.svg";
import { MobileSubmitOtp } from "./components/mobile-submit-otp";
import { useTranslation } from "react-i18next";
import { setLanguage, setNationality } from "../../utils/slices/localeSlice";
import countryList from "react-select-country-list";
import ReactCountryFlag from "react-country-flag";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { changeSelectedCountry } from "../../utils/slices/globalSlice";
import axios from "axios";
import EmailConfirmation from "../../Auth/email-confirmation";
const Header = () => {
  const options = countryList().getData();
  const countryVal = useSelector((state) => state.global.selectedCountry);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const S3Bucket = process.env.REACT_APP_S3_BUCKET;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSignupPopup, setOpenSignupPopup] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(null);
  const [showCountryDropdown, setShowCountryDropdown] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showCountryDropdownEL, setShowCountryDropdownEL] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [toggleMobileLogOutPopup, setToggleMobileLogOutPopup] = useState(false);
  const [showCustomerSupportModal, setShowCustomerSupportModal] =
    useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showMobileLogoutModal, setShowMobileLogoutModal] = useState(false);
  const [openCountrySelectPopup, setOpenCountrySelectPopup] = useState(false);
  const [showCountrySelection, setShowCountrySelection] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [applySelectedCountry, setApplySelectedCountry] = useState();
  const [applySelectedLanguage, setApplySelectedLanguage] = useState();
  const [mobileLogOutPopupDisplay, setMobileLogOutPopupDisplay] =
    useState("none");
  const toggleMobileLogOutPopupRef = useRef(null);
  const { pathname } = useLocation();
  const { login, otp, signup } = useSelector((state) => state.popup);
  const { firstName, lastName, email, profilePic } = useSelector(
    (state) => state.user
  );
  const { emailConfirmation: showModal } = useSelector((state) => state.popup);
  const phoneNumber = useSelector((state) => state.user.phoneNo);
  const newCustomer = useSelector((state) => state.user.newCustomer);
  const data = {
    phoneNo: phoneNumber,
    newCustomer: newCustomer,
  };
  const styles = useStyles();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const getLocale = useSelector((state) => state.locale);

  const open = Boolean(showProfileDropdown);
  const openCountry = Boolean(showCountryDropdown);
  const handleClick = (event) => {
    setShowProfileDropdown(event.currentTarget);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  let initialCountry = JSON.parse(localStorage.getItem("selectedCountry"));
  const handleCountryClick = (option) => {
    dispatch(changeSelectedCountry(option));
    localStorage.setItem("selectedCountry", JSON.stringify(option));
    setShowCountryDropdown(false);
    window.location.reload();
  };

  const openLoginInBottomSheet = () => {
    // eventBus.emit("open-dialog", <MobileOtpEnter />);
  };

  const handleCountrySearch = (e) => {
    const term = e.target.value.toLowerCase();

    if (term == "") {
      setSearchTerm(term);
      setFilteredOptions(options);
    } else {
      let filteredOptions = options.filter((option) => {
        return option.label.toLowerCase().includes(term);
      });
      setFilteredOptions(filteredOptions);
      setSearchTerm(term);
    }
  };
  const handleClose = () => {
    setShowProfileDropdown(null);
  };

  const handleCountryClose = () => {
    setShowCountryDropdown(null);
  };
  const handleLogout = async () => {
    setShowLogoutModal(true);
  };
  const handleMobileLogout = async (value) => {
    setShowMobileLogoutModal(value);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOpen = () => {
    navigate('/mobile/login', {
      state: {
        redirection: '/'
      }
    })
  };

  const handleSignupOpen = () => {
    setOpenSignupPopup(true);
  };
  const handleCloses = () => {
    setOpenPopup(false);
  };

  const handleOpenCountrySelect = () => {
    setOpenCountrySelectPopup(true);
  };

  const handleCloseCountrySelect = () => {
    setOpenCountrySelectPopup(false);
  };

  const handleSelectedCountry = (event) => {
    let value = event.target.value;
    setSelectedCountry(value);
    dispatch(setNationality());
    localStorage.setItem("country", "");
  };

  const handleSelectedLanguage = (event) => {
    console.log('handleSelectedLanguage called')
    let value = event.target.value;
    setSelectedLanguage(value);
    dispatch(setLanguage());
    localStorage.setItem("language", "");
  };

  const handleApplyButton = () => {
    if (selectedLanguage == "EN") {
      changeLanguage("en");
      setApplySelectedLanguage("EN");
      dispatch(setLanguage("EN"));
      localStorage.setItem("language", "EN");
    } else if (selectedLanguage == "AR") {
      changeLanguage("ar");
      setApplySelectedLanguage("AR");
      dispatch(setLanguage("AR"));
      localStorage.setItem("language", "AR");
    }

    if (selectedCountry == "IND") {
      setApplySelectedCountry("IND");
      dispatch(setNationality("IN"));
      localStorage.setItem("country", "IND");
    } else if (selectedCountry == "KSA") {
      setApplySelectedCountry("KSA");
      dispatch(setNationality("SA"));
      localStorage.setItem("country", "KSA");
    }

    handleCloseCountrySelect();
    window.location.reload();
  };

  const handleSignupPopupClose = () => {
    setOpenSignupPopup(false);
  };
  const handleMobileLogOutPopup = () => {
    if (!toggleMobileLogOutPopup) {
      eventBus.emit(
        "open-dialog",
        <MobileLogoutPopup handleMobileLogout={handleMobileLogout} />
      );
      setToggleMobileLogOutPopup(false);
    } else {
      eventBus.emit(
        "close-dialog",
        <MobileLogoutPopup handleMobileLogout={handleMobileLogout} />
      );
      setToggleMobileLogOutPopup(true);
    }
  };
  const language = localStorage.getItem("language");
  const [selectedCountryCode, setSelectedCountryCode] = useState(language);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(language);

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
    window.location.reload();
    changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);
  };
  const handleCurrencyChange = (event) => {
    setSelectedCurrencyCode(event.target.value);
    window.location.reload();
  };
  useEffect(() => { }, [localStorage, handleLogout]);

  const isMobile = useIsMobile();
  useEffect(() => {
    function handleDocumentClick(event) {
      if (!toggleMobileLogOutPopupRef?.current?.contains(event?.target)) {
        if (
          pathname == "/" ||
          pathname == "/hotel/payment" ||
          pathname.startsWith("/hotel/add-details/") ||
          pathname.startsWith("/hotel/booking-details/")
        ) {
          setMobileLogOutPopupDisplay("none");
        }
      }
    }
    if (
      isMobile &&
      (pathname == "/" ||
        pathname == "/hotel/payment" ||
        pathname.startsWith("/hotel/add-details/") ||
        pathname.startsWith("/hotel/booking-details/"))
    ) {
      document.addEventListener("click", handleDocumentClick);
      return function () {
        document.addEventListener("click", handleDocumentClick);
      };
    }
  });

  const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
  return (
    <div className={styles.header}>
      <div className={styles.headerLeftSection}>
        {isMobile && (
          <div className={styles.menuIcon} onClick={toggleMenu}>
            <MenuIcon />
          </div>
        )}
        <img
          src={Logo}
          alt="logo"
          className={styles.headerLogo}
          onClick={() =>
            path == "/" ? window.location.reload() : navigate("/")
          }
        />
        {isMobile && (
          <SwipeableDrawer
            anchor="left"
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            onOpen={() => setIsMenuOpen(true)}
          >
            <NavigationDrawer setIsMenuOpen={setIsMenuOpen} />
          </SwipeableDrawer>
        )}
      </div>
      {isMobile ? (
        <>
          {firstName ? (
            <div style={{ position: "relative" }}>
              <div
                style={{ display: "inline-flex", alignItems: "center" }}

                onClick={() => {
                  if (mobileLogOutPopupDisplay == "none") {
                    setMobileLogOutPopupDisplay("flex");
                  } else {
                    setMobileLogOutPopupDisplay("none");
                  }
                }}
                ref={toggleMobileLogOutPopupRef}
              >
                <div className="login-icon">👋</div>
                <div className="login-text">
                  {t("myProfile.hi")} {firstName}
                </div>
              </div>
              <MobileLogoutPopup
                display={mobileLogOutPopupDisplay}
              />
            </div>
          ) : (
            <button className={styles.loginButton} onClick={handleOpen}>
              <ReactSVG src={userIconBlue} />
              <span className={styles.loginButtonText}>
                {t("myProfile.loginTitle")}
              </span>
            </button>
          )}
        </>
      ) : (
        <div className={styles.headerRightSection}>
          <span
            className={styles.headerFAQAction}
            onClick={() => navigate("/about-us")}
          >
            {t("header.aboutUs")}
          </span>
          <span
            className={styles.headerCustomerSupportAction}
            onClick={() => setShowCustomerSupportModal(true)}
          >
            {t("header.customerSupport")}
          </span>
          <span
            className={styles.headerFAQAction}
            onClick={() => navigate("/faq")}
          >
            {t("header.faq")}
          </span>
          <div className="headerCountryDropdownContainer">
            <div className={styles.headerCountryDropdown}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <div className={styles.headerCountryDropdownFlag}
                style={{ marginBottom: '10px' }}
              >
                <ReactCountryFlag
                  countryCode={
                    countryVal ? countryVal.value : initialCountry.value
                  }
                  svg
                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  cdnSuffix="svg"
                  title={countryVal ? countryVal.value : ""}
                />
              </div>
              {path == "/" && (
                <>
                  <select
                    className={styles.selectBackgrundColor}
                    onClick={() => {
                      setShowCountryDropdown(true);
                      const element = document.querySelector(
                        ".headerCountryDropdownContainer"
                      );
                      setShowCountryDropdownEL(element);
                    }}
                  >

                  </select>
                </>
              )}
              <div className={styles.headerCountryDropdownDivider} />
              <select
                className={styles.selectBackgrundColor}
                value={selectedCountryCode}
                onChange={handleCountryChange}
              >
                <option value="EN">EN</option>
                <option value="AR">العربية</option>
              </select>
              <div className={styles.headerCountryDropdownDivider} />

              <p>SAR</p>
            </div>
          </div>
          {firstName ? (
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={handleClick}
            >
              <div className="login-icon">👋</div>
              <div className="login-text">
                {t("myProfile.hi")} {firstName}
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                dispatch(setLoginPopup(true));
                dispatch(handleRedirectUrl({ url: "/", state: {} }));
              }}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <ReactSVG src={LoginSvg} style={{ marginTop: "2px" }} />
              <p
                style={{
                  color: "#344054",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                {t("header.login/signup")}
              </p>
            </div>
          )}

          {login && <Login />}
          {signup && <Signup />}
          {showCustomerSupportModal && (
            <CustomerSupport
              showCustomerSupportModal={showCustomerSupportModal}
              setShowCustomerSupportModal={setShowCustomerSupportModal}
            />
          )}
          {!isMobile && showLogoutModal && (
            <Logout
              showLogoutModal={showLogoutModal}
              setShowLogoutModal={setShowLogoutModal}
            />
          )}
          {isMobile && showProfileDropdown ? (
            <div style={{ position: "absolute", top: "0", right: "0" }}>
              <div
                className={styles.logoutPopup}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  position: "absolute",
                  top: "0",
                  right: "0",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gap: "16px",
                    borderRadius: "10px 10px 0px 0px",
                    background: "#F2F7FB",
                    padding: "16px 16px",
                  }}
                >
                  {profilePic ? (
                    <img
                      src={`${S3Bucket}/${profilePic}`}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                    />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <p
                        style={{
                          position: "absolute",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontStyle: "normal",
                          fontWeight: "700",
                          width: "40px",
                          height: "40px",
                          color: "#4037acd6",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "22px",
                          marginTop: "10%",
                        }}
                      >
                        {firstName?.charAt(0)}
                      </p>
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#F8F8F8",
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#344054",
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                      }}
                    >
                      {firstName} {lastName}
                    </p>
                    <p
                      style={{
                        color: "#344054",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "italic",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {email}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    padding: "16px",
                    gap: "8px",
                    cursor: "pointer",
                    background: "#FFF",
                  }}
                  onClick={() => navigate("/profile")}
                >
                  <ReactSVG src={ProfileSvg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#24272A",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {t("myProfile.profile")}
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {t("myProfile.manageProfileDetails")}
                    </p>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "inline-flex",
                    padding: "16px",
                    gap: "8px",
                    cursor: "pointer",
                    background: "#FFF",
                  }}
                  onClick={() => navigate("/bookings")}
                >
                  <ReactSVG src={BookingSvg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#24272A",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {t("myProfile.myBookings")}
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {t("myProfile.bookingOptions")}
                    </p>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "inline-flex",
                    padding: "16px",
                    gap: "8px",
                    cursor: "pointer",
                    background: "#FFF",
                  }}
                  onClick={() => handleLogout()}
                >
                  <ReactSVG src={LogoutSvg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#24272A",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {t("myProfile.logout")}
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {t("myProfile.logoutAccount")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Menu
              className={styles.logoutPopup}
              anchorEl={showProfileDropdown}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                style: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&:before": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "#F2F7FB",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                  borderRadius: "10px",
                  background: "#F2F7FB",
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div
                className={styles.logoutPopup}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    gap: "16px",
                    borderRadius: "10px 10px 0px 0px",
                    background: "#F2F7FB",
                    padding: "16px 16px",
                  }}
                >
                  {profilePic ? (
                    <img
                      src={`${S3Bucket}/${profilePic}`}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        position: "relative",
                      }}
                    />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <p
                        style={{
                          position: "absolute",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          fontStyle: "normal",
                          fontWeight: "700",
                          width: "40px",
                          height: "40px",
                          color: "#4037acd6",
                          fontFamily: "Lato, sans-serif",
                          fontSize: "22px",
                          marginTop: "10%",
                        }}
                      >
                        {firstName?.charAt(0)}
                      </p>
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#F8F8F8",
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#344054",
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        textTransform: "capitalize",
                      }}
                    >
                      {firstName} {lastName}
                    </p>
                    <p
                      style={{
                        color: "#344054",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "italic",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {email}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    padding: "16px",
                    gap: "8px",
                    cursor: "pointer",
                    background: "#FFF",
                  }}
                  onClick={() => navigate("/profile")}
                >
                  <ReactSVG src={ProfileSvg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#24272A",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {t("myProfile.profile")}
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {t("myProfile.manageProfileDetails")}
                    </p>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "inline-flex",
                    padding: "16px",
                    gap: "8px",
                    cursor: "pointer",
                    background: "#FFF",
                  }}
                  onClick={() => navigate("/bookings")}
                >
                  <ReactSVG src={BookingSvg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#24272A",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {t("myProfile.myBookings")}
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {t("myProfile.bookingOptions")}
                    </p>
                  </div>
                </div>
                <Divider />
                <div
                  style={{
                    display: "inline-flex",
                    padding: "16px",
                    gap: "8px",
                    cursor: "pointer",
                    background: "#FFF",
                  }}
                  onClick={() => handleLogout()}
                >
                  <ReactSVG src={LogoutSvg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p
                      style={{
                        color: "#24272A",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      {t("myProfile.logout")}
                    </p>
                    <p
                      style={{
                        color: "#667085",
                        fontFamily: "Lato",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      {t("myProfile.logoutAccount")}
                    </p>
                  </div>
                </div>
              </div>
            </Menu>
          )}
          {!isMobile && showCountryDropdown && (
            <Menu
              className={styles.countryShowPopup}
              anchorEl={showCountryDropdownEL}
              open={openCountry}
              onClose={handleCountryClose}
              // onClick={handleCountryClose}
              PaperProps={{
                elevation: 0,
                style: {
                  overflow: 'hidden',
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&:before": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "#F2F7FB",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                  borderRadius: "10px",
                  background: "#F2F7FB",
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <>
                <input
                  type="text"
                  onChange={handleCountrySearch}
                  className="searchCountryInput"
                  value={searchTerm}
                  placeholder={selectedCountry?.label}
                  style={{
                    margin: "15px",
                    width: "280px",
                    height: "25px",
                    borderRadius: "5px",
                    border: "1px solid grey",
                    paddingLeft: "5px",
                  }}
                />

                <ul
                  style={{
                    maxHeight: "90vh",
                    height: "400px",
                    listStyle: "none",
                    paddingLeft: "15px",
                    paddingRight: '15px',
                    overflowY: "auto",
                    width: "300px",
                    // overflow: 'hidden'
                  }}
                >
                  {filteredOptions.map((option) => {
                    return (
                      <li
                        style={{
                          cursor: "pointer",
                          marginBottom: "5px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        key={option}
                        onClick={() => {
                          handleCountryClick(option);
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={option ? option.value : ""}
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          cdnSuffix="svg"
                          title={option ? option.value : ""}
                        />
                        {option.label} {", "} {option.value}
                      </li>
                    );
                  })}
                </ul>
              </>
            </Menu>
          )}
        </div>
      )}
      <Modal
        open={openPopup}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <MobileOtpEnter
            handleCloses={handleCloses}
            handleSignupOpen={handleSignupOpen}
          />
          {/* <Login /> */}
        </div>
      </Modal>

      <Modal
        open={openSignupPopup}
        style={{ display: "flex", alignItems: "center", padding: "12px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <MobileSubmitOtp
            data={data}
            handleSignupPopupClose={handleSignupPopupClose}
            handleOpen={handleOpen}
          />
          {/* {(() => {
            console.group('login is about to open ')
          })()} */}
          {/* <Login /> */}
        </div>
      </Modal>

      <Modal
        open={showModal}
        onClose={() => dispatch(setEmailConfirmation(false))}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "12px",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "400px",
          }}
        >
          <EmailConfirmation />
        </div>
      </Modal>
      <Modal open={openCountrySelectPopup} className={styles.modal}>
        <div
          style={{
            background: "#fff",
            padding: "20px",
            outline: "none",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div className={styles.selectCountries}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div>{t("myProfile.selectCountry")}</div>
              <div className={styles.countries}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>
                    <input
                      type="radio"
                      value="IND"
                      checked={
                        selectedCountry === "IND" ||
                        localStorage.getItem("country") === "IND"
                      }
                      onChange={handleSelectedCountry}
                      style={{ width: "8px", height: "8px", color: "#1b1d52" }}
                    // name="country-select"
                    />
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "12px",
                        fontFamily: "Lato",
                        fontWeight: 400,
                      }}
                    >
                      India
                    </span>
                  </label>
                  <ReactSVG src={FlagSvg} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>
                    <input
                      type="radio"
                      value="KSA"
                      checked={
                        selectedCountry === "KSA" ||
                        localStorage.getItem("country") === "KSA"
                      }
                      onChange={handleSelectedCountry}
                      style={{ width: "8px", height: "8px" }}
                    // name="country-select"
                    />
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "12px",
                        fontFamily: "Lato",
                        fontWeight: 400,
                      }}
                    >
                      Saudi Arabia
                    </span>
                  </label>
                  <ReactSVG src={KSAFlag} />
                </div>
              </div>
            </div>
            <div style={{ width: "1px", background: "#808080" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div>{t("myProfile.selectLanguage")}</div>
              <div className={styles.languages}>
                <label>
                  <input
                    type="radio"
                    value="EN"
                    checked={
                      localStorage.getItem("language") === "EN" ||
                      selectedLanguage === "EN"
                    }
                    onChange={handleSelectedLanguage}
                    style={{ width: "8px", height: "8px" }}
                  />
                  <span
                    style={{
                      marginLeft: "5px",
                      fontSize: "12px",
                      fontFamily: "Lato",
                      fontWeight: 400,
                    }}
                  >
                    English
                  </span>
                </label>

                <label>
                  <input
                    type="radio"
                    value="AR"
                    checked={
                      localStorage.getItem("language") === "AR" ||
                      selectedLanguage === "AR"
                    }
                    onChange={handleSelectedLanguage}
                    style={{ width: "8px", height: "8px" }}
                  />
                  <span
                    style={{
                      marginLeft: "5px",
                      fontSize: "12px",
                      fontFamily: "Lato",
                      fontWeight: 400,
                    }}
                  >
                    العربية
                  </span>
                </label>
              </div>
            </div>
          </div>
          <button
            style={{
              width: "100%",
              padding: "6px 0px",
              borderRadius: "8px",
              background: "#1b1d52",
              border: "none",
              color: "#fff",
              fontSize: "12px",
              fontFamily: "sans-serif",
            }}
            onClick={() => handleApplyButton()}
          >
            {t("header.apply")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
