import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardSelectionMain: {
    background: "white",
    display: "flex",
    flexDirection: "column",
    padding: "12px 12px",
    gap: "10px",
    width: "400px",
    maxWidth: '90vw'
  },

  submitButton: {
    background: "#1b1d52",
    border: "none",
    color: "white",
    borderRadius: "8px",
    padding: "10px",
  },

  selectCardText: {
    fontSize: "22px",
    fontFamily: "Lato",
  },
}));

export default useStyles;
