import { createSlice } from "@reduxjs/toolkit";

const hotelSearchingSlice = createSlice({
  name: "hotelSearchState",
  initialState: {
    hotelSearchPageSearchGoingOn: false,
    hotelDetailsPageSearchGoingOn: false ,
  },
  reducers: {
    changeHotelSearchPageSearchGoingOn: (state, action) => {
      state.hotelSearchPageSearchGoingOn = action.payload;
    },
    changeHotelDetailsPageSearchGoingOn: (state, action) => {
      state.hotelDetailsPageSearchGoingOn = action.payload;
    },
  },
});

export const { changeHotelSearchPageSearchGoingOn, changeHotelDetailsPageSearchGoingOn } = hotelSearchingSlice.actions;

export default hotelSearchingSlice.reducer;
