import { createSlice } from "@reduxjs/toolkit";

const searchHotelSlice = createSlice({
  name: "searchPackages",
  initialState: {
    categoryIds: [],
    withFlight: true,
    withoutFlight: true,
    minDaysFilter: 0,
    maxDaysFilter: 0,
    minNightsFilter: 0,
    maxNightsFilter: 0,
    minPersonPriceFilter: 0,
    maxPersonPriceFilter: 0,
    cities: [],
    filteredPackages: [],
    packagesSearchGoingOn: false,
  },
  reducers: {
    updateCategories: (state, action) => {
      let categories = state.categoryIds;
      const { id, name } = action.payload;
      const index = categories.findIndex((category) => category.id === id);
      if (index === -1) {
        categories.push({ id, name });
        state.categoryIds = categories;
      } else {
        const updatedCategory = categories.filter((filter) => filter.id !== id);
        state.categoryIds = updatedCategory;
      }
    },
    updateWithFlights: (state, action) => {
      state.withFlight = action.payload;
    },
    updateWithoutFlights: (state, action) => {
      state.withoutFlight = action.payload;
    },
    updateDays: (state, action) => {
      const { start, end } = action.payload;
      state.minDaysFilter = start;
      state.maxDaysFilter = end;
    },
    updateNights: (state, action) => {
      const { start, end } = action.payload;
      state.minNightsFilter = start;
      state.maxNightsFilter = end;
    },
    updateBudget: (state, action) => {
      const { start, end } = action.payload;
      state.minPersonPriceFilter = start;
      state.maxPersonPriceFilter = end;
    },
    updateCities: (state, action) => {
      let cities = state.cities;
      const { id, name } = action.payload;
      const index = cities.findIndex((city) => city.id === id);
      if (index === -1) {
        cities.push({ id, name });
        state.cities = cities;
      } else {
        const updatedCategory = cities.filter((filter) => filter.id !== id);
        state.cities = updatedCategory;
      }
    },
    updateClearAll: (state) => {
      state.withFlight = true;
      state.withoutFlight = true;
      state.minDaysFilter = 0;
      state.maxDaysFilter = 0;
      state.minNightsFilter = 0;
      state.maxNightsFilter = 0;
      state.minPersonPriceFilter = 0;
      state.maxPersonPriceFilter = 0;
      state.cities = [];
      state.categoryIds = [];
    },
    updateFilteredPackage: (state, action) => {
      state.filteredPackages = action.payload;
    },
    updatePackagesSearchGoingOn :(state, action)=>{
      state.packagesSearchGoingOn = action.payload
    }
  },
});

export const {
  updateCategories,
  updateWithFlights,
  updateWithoutFlights,
  updateDays,
  updateNights,
  updateBudget,
  updateCities,
  updateClearAll,
  updateFilteredPackage,
  updatePackagesSearchGoingOn
} = searchHotelSlice.actions;

export default searchHotelSlice.reducer;
