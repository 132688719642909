import { makeStyles } from "@mui/styles";

export const useHeaderLeftViewStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    columnGap: "48px",
  },
  location: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderRight: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    paddingRight: "48px",
  },

  locationArabic: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderLeft: `1px solid ${theme.palette.entityColors.entityColorBorder}`,
    paddingLeft: "48px",
  },
  locationLabel: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "700",
  },
  locationValue: {
    color: theme.palette.textColors.textColorSecondary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
  },
  checkIn: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  checkInLabel: {
    color: theme.palette.textColors.textColorSecondary,
    fontSize: theme.fontSizes.fontSize3,
    fontWeight: "400",
  },
  checkInValue: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "700",
  },
}));

const useStyles = makeStyles((theme) => ({
  hotelSearchPage: {
    display: "flex",
    flexDirection: "column",
    rowGap: "42px",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.entityColors.entityColorBackground2,
    padding: "16px 150px 48px 150px",

    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      minHeight: "100vH",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "16px 20px 48px 20px",
      minHeight: "100vH",
    },
  },
  hotelSearchPageBody: {
    display: "flex",
    columnGap: "24px",
  },
  hotelSearchPageBodyFilters: {
    flex: "1",
  },
  hotelSearchPageBodyListing: {
    flex: "3",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  bottomButtons: {
    display: "inline-flex",
    alignItems: "flex-start",
    alignSelf: "center",
    marginBottom: "20px",
    position: "fixed",
    bottom: "30px",
    left: "calc(50vW - 95px)",
    boxShadow: "0px 1px 48px 0px rgba(0, 0, 0, 0.24)",
    borderRadius: "24px",
  },

  filterLayout: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px 0px 0px 24px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },
  filterLayoutArabic: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "0px 24px 24px 0px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },
  sortLayout: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "0px 24px 24px 0px",
    border: "1px solid #1b1d52",
    background: "#FBFDFF",
  },
  sortLayoutArabic: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "8px",
    borderRadius: "24px 0px 0px 24px",
    borderTop: "1px solid #08F",
    borderLeft: "1px solid #08F",
    borderBottom: "1px solid #08F",
    background: "#FBFDFF",
  },
  subContainer: {
    margin: "0px 150px",

    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },

  container: {
    background: " #FAFAFA",
    height: "auto",
  },
}));

export default useStyles;
