import { createSlice } from "@reduxjs/toolkit";

const getHotelDetailsFromLocalStorage = () => {
  const hotel = JSON.parse(
    window.localStorage.getItem("modifySearchHotelDetails")
  );
  return hotel;
};

const hotel = getHotelDetailsFromLocalStorage();

const initialState = {
  rooms:
    hotel && hotel.roomGuests
      ? hotel.roomGuests
      : [{ adultCounter: 1, childrenCounter: 0, childrenAges: [] }],
};

const modifyHotelRoomSlice = createSlice({
  name: "modifyHotelRooms",
  initialState,
  reducers: {
    addModifyRoom: (state) => {
      if (state.rooms.length >= 5) {
        return;
      }
      const newRoom = { adultCounter: 1, childrenCounter: 0, childrenAges: [] }; // Initialize childrenAges array
      state.rooms.push(newRoom);
    },
    removeModifyRoom: (state, action) => {
      if (action.payload === 0 || state.rooms.length <= 1) {
        return;
      }
      state.rooms.splice(action.payload, 1);
    },
    updateModifyAdultCounter: (state, action) => {
      const { roomIndex, newAdultCounter } = action.payload;
      state.rooms[roomIndex].adultCounter = newAdultCounter;
    },
    updateModifyChildrenCounter: (state, action) => {
      const { roomIndex, newChildrenCounter } = action.payload;
      state.rooms[roomIndex].childrenCounter = newChildrenCounter;
    },
    updateModifyChildrenAges: (state, action) => {
      const { roomIndex, newChildrenAges } = action.payload;
      state.rooms[roomIndex].childrenAges = newChildrenAges;
    },
    updateModifyHotelRoomObject: (state, action) => {
      const { totalGuests } = action.payload;
      state.rooms = totalGuests;
    },
  },
});

export const {
  addModifyRoom,
  removeModifyRoom,
  updateModifyAdultCounter,
  updateModifyChildrenCounter,
  updateModifyChildrenAges,
  updateModifyHotelRoomObject,
} = modifyHotelRoomSlice.actions;

export default modifyHotelRoomSlice.reducer;
