import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: "#667085",
    fontSize: "12px",
    fontWeight: "400",

    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
    },
  },
}));

export default useStyles;
