import React from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import useStyles from "./styles";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { useLocation } from "react-router";
import ModifySearchHotelMobile from "../../components/modifySearchMobile";
import ModifySearchFlightMobile from "../../components/modifySearchFlightMobile";

import { useSelector } from "react-redux";

export const withHeaderAndFooter = (Component) => {
  const Wrapper = (props) => {
    const styles = useStyles();
    const isMobile = useIsMobile();
    const location = useLocation();
    const searchWidgetTypLocal = localStorage.getItem("searchWidgetType");
    const searchWidgetType = useSelector(
      (state) => state.global.searchWidgetType
    );
    const path = location.pathname;
    return (
      <div className={styles.wrapper}>
        {(!isMobile ||
          (isMobile &&
            path !== "/hotel/search" &&
            path !== "/hotel/details" &&
            path !== "/flight/search")) && <Header />}

        {isMobile &&
          (path == "/hotel/search" || path == "/hotel/details") &&
          searchWidgetTypLocal == 0 && <ModifySearchHotelMobile />}
        {isMobile && path == "/flight/search" && <ModifySearchFlightMobile />}
        <main className={styles.contentWrapper}>
          <Component {...props} />
        </main>
        {/* {(!isMobile || (isMobile && (path != "/hotel/search" || path != "/hotel/details"))) &&
            <Footer/>
        } */}

        {/* {(!isMobile || (isMobile && (path != "/hotel/search" && path != "/hotel/details"))) &&
    <Footer />
        } */}

        {(!isMobile ||
          (isMobile &&
            path !== "/flight/search" &&
            !path.startsWith("/flight/seat-details/") &&
            path !== "/hotel/search" &&
            path !== "/hotel/details" &&
            !path.startsWith("/flight/review-details") &&
            path !== "/flight/final-details" &&
            !path.startsWith("/flight/bookingConfirmation/") &&
            !path.startsWith("/hotel/details") &&
            !path.startsWith("/hotel/add-details") &&
            !path.startsWith("/hotel/payment") &&
            !path.startsWith("/hotel/booking-details"))) && <Footer />}
      </div>
    );
  };

  return Wrapper;
};

// import React from "react";
// import Footer from "../../../components/footer";
// import Header from "../../../components/header";
// import useStyles from "./styles";
// import useIsMobile from "../../../hooks/useIsMobile.hook";
// import { useLocation } from "react-router";
// import ModifySearchHotelMobile from "../../components/modifySearchMobile";
// import ModifySearchFlightMobile from "../../components/modifySearchFlightMobile";

// import { useSelector } from "react-redux";

// export const withHeaderAndFooter = ( Component )=>{

//   const Wrapper = (props)=>{

//     const styles = useStyles();
//     const isMobile = useIsMobile();
//     const location = useLocation();
//     const searchWidgetTypLocal = localStorage.getItem("searchWidgetType");
//     const searchWidgetType = useSelector((state)=>state.global.searchWidgetType);
//     const path = location.pathname;
//     return (
//       <div className={styles.wrapper}>
//         {
//           (!isMobile || (isMobile && (path !== "/hotel/search" && path !== "/hotel/details"))) &&
//             <Header />
//         }
//         {
//           isMobile && (path == "/hotel/search" || path == "/hotel/details" )&& searchWidgetTypLocal == 0 &&
//             <ModifySearchHotelMobile/>
//         }
//          {
//           isMobile && path != "/" && searchWidgetTypLocal == 1 &&
//             <ModifySearchFlightMobile/>
//         }
//         <main className={styles.contentWrapper}>
//           <Component {...props}/>
//         </main>
//         {(!isMobile || (isMobile && (path !== "/hotel/search" && path !== "/hotel/details"))) &&
//             <Footer/>
//         }
//       </div>
//     );
//   };

//   return Wrapper;

// };
