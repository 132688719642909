import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  addBaggageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  innerAllowanceContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  originText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#344054",
  },
  destinationText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    color: "#344054",
  },
  addExtraBaggageText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    color: "#1b1d52",
    cursor: "pointer",
  },
  baggageAllowanceContainer: {
    padding: "16px",
    borderRadius: "10px",
    border: "1px solid #D0D5DD",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "35%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  baggageAllowanceHead: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#344054",
  },
  baggageAllowedBody: {
    display: "inline-flex",
    gap: "4px",
  },
  baggageAllowedBodyText: {
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#525A6A",
    // background: "green",
  },
  cabinBaggageAllowedBodyText: {
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#525A6A",
  },

  baggageInfoPopUpBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundCcolor: "white",
    display: "flex",
    padding: "24px 16px",
    flediDrection: "column",
    gap: "16px",
    borderradius: "10px",
    background: "#FFF",
    outline: "0",
    maxHeight: "70vh",
    overflowY: "auto",
  },

  addExtraBaggageLayout: {
    display: "inline-flex",
    gap: "4px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
    },
  },
}));

export default useStyles;
