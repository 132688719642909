import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: "10px",
    outline: "0",
  },
  logoutAndAreYouSureTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
  },
  headingContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
  },
  logoutText: {
    fontFamily: "lato",
    fontSize: "16px",
    fontWeight: "600",
    color: "#1b1d52",
  },
  areYouSureText: {
    fontFamily: "lato",
    fontSize: "15px",
    fontWeight: "400",
    color: "#525A6A",
  },
  confirmContainer: {
    borderRadius: "10px",
    background: "#1b1d52",
    textAlign: "center",
    padding: "16px 16px",
    width: "100%",
    cursor: "pointer",
  },
  confirmText: {
    fontFamily: "lato",
    fontSize: "14px",
    fontWeight: "400",
    color: "#FFF",
  },
  cancelText: {
    fontFamily: "lato",
    fontSize: "14px",
    fontWeight: "400",
    color: "#1b1d52",
    cursor: "pointer",
  },
  content2: {
    display: "inline-flex",
    gap: "8px",
    width: "100%",
  },
}));

export default useStyles;
