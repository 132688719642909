import { Modal } from "@mui/material";
import React from "react";
import useStyles from "./style";
import TickGif from "../../../../assets/images/tick-gif.gif";
import useIsMobile from "../../../../hooks/useIsMobile.hook";

const SuccessModal = ({ showSuccessModal, setShowSuccessModal, desc }) => {
  const styles = useStyles();
  const isMobile = useIsMobile()
  return (
    <Modal
      open={showSuccessModal}
      onClose={() => setShowSuccessModal(false)}
      sx={{ outline: "none" }}
    >
      <div
        className={styles.modalContainer}
        style={{
          marginRight: localStorage.getItem("language") == "AR"
            ? isMobile
              ? '15%'
              : "34%"
            : "0"
        }}
      >
        <img src={TickGif} />
        <p
          style={{
            color: "#344054",
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center"
          }}
        >
          {desc}
        </p>
      </div>
    </Modal>
  );
};

export default SuccessModal;
