import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  hotelDetails: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  hotelDetailsBody: {
    display: "grid",
    // gap: "10px",
    rowGap: '10px',
    gridTemplateColumns: "repeat(2,minmax(200px, 1fr))",
    // gridTemplateRows:
    //   "minmax(200px, max-content) minmax(200px, max-content)  minmax(300px, max-content)  minmax(300px, max-content) minmax(300px, max-content)",
    gridTemplateAreas: `
      "info map"
      "amenities amenities"
      "propertyRules propertyRules"
      "about about"
      "roomList roomList"
      `,
    // [theme.breakpoints.down("sm")]: {
    //   gridTemplateRows: "none",
    //   gridTemplateColumns: "1fr",
    //   gridTemplateAreas: `
    //     "info "
    //     "amenities"
    //     "propertyRules"
    //     "about"
    //     "roomList"
    //     `,
    // },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  hotelDetailsBodyInfo: {
    gridArea: "info",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  hotelDetailsBodyMap: {
    gridArea: "map",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // maxWidth:'400px'
    },
  },
  hotelDetailsBodyAmenities: {
    gridArea: "amenities",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  hotelDetailsBodyPropertyRules: {
    gridArea: "propertyRules",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  hotelDetailsBodyAbout: {
    gridArea: "about",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    padding: "24px 16px",
    borderRadius: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  hotelDetailsBodyRoomsList: {
    gridArea: "roomList",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  hotelDetailsLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      height: '80vh'
    }
  },
  hotelDetailsNoPropertyRules: {
    gridTemplateAreas: `
    "info map"
    "amenities amenities"
    "about about"
    "roomList roomList"
    `,
  },
  roomsCombinationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // border: "2px solid red",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  roomsContainer: {
    flexFlow: 2,
    width: "80%",
    backgroundColor: "#FFFFFF",
    // borderRight: '1px solid grey',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  combinationPriceAndBookNowContainer: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  roomDetailsAmenitiesValue: {
    fontSize: "14px",
  },
  roomDetailsAmenitiesPrice: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    gap: "12px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "5px 15px",
    },
    // justifyContent: "space-between",
  },
  roomDetailsAmenitiesPriceValue: {
    fontSize: theme.fontSizes.fontSize1,
    fontWeight: "700",
    display: "inline-flex",
  },
  roomDetailsAmenitiesBookNow: {
    padding: "8px 16px",
    borderRadius: "10px",
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    color: theme.palette.entityColors.entityColorBackground1,
    cursor: "pointer",
    border: "none",
    fontWeight: "400",
    fontSize: theme.fontSizes.fontSize2,
  },
  displayNone: {
    position: "absolute",
    display: "none",
  },
  amenitiesInnerPopup: {
    maxHeight: '80vh',
    maxWidth: '90vw',
    background: "white",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    margin: "0px auto",
    paddingBottom: "20px",
    borderRadius: "10px",
    width: '500px',
    [theme.breakpoints.down("md")]: {
      width: '500px'
    },

    [theme.breakpoints.down("xxs")]: {
      width: '300px'
    }
  }
}));

export default useStyles;
