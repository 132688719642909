import React, { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import { useSelector, useDispatch } from "react-redux";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import HotelStepper from "../../../components/hotel-module/stepper";
import Coupon from "../../../components/hotel-module/cards/coupons";
import AlreadyMember from "../../../components/hotel-module/personal-details/already-member";
import AddContactDetails from "../../../components/hotel-module/personal-details/add-contact-details";
import SelectedHotelDetails from "../../../components/hotel-module/cards/selected-hotel-details";
import SelectedHotelFareBreakdown from "../../../components/hotel-module/cards/selected-hotel-fare-breakdown";
import AddPersonalDetails from "../../../components/hotel-module/personal-details/add-personal-details";
import ArrowSvg from "../../../assets/images/Line arrow-left.svg";
import { cloneDeep, get, set, times } from "lodash";
import {
  handleRedirectUrl,
  setLoginPopup,
} from "../../../utils/slices/popupSlice";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HotelChangePrice from "../../../components/hotel-module/cards/change-price-modal";
import useStyles from "./style";
import useIsMobile from "../../../hooks/useIsMobile.hook";
import { ExpandMore } from "@mui/icons-material";
import BookingSummary from "../../../components/flight-module/personal-details/booking-summary-mobile";
import eventBus from "../../../core/eventBus";
import {
  getRoomsDetails,
  getHotelDetails,
  addPersonalDetails,
  getMarkup
} from "../../../modules/hotels";
import calculateMarkup from "./calculateMarkupPrice";
import getMarkupDetails from "./getMarkup";
import { MobileOtpEnter } from "../../../components/header/components/mobile-otp-enter";
import handleUnauthorisedLogout from "../../../modules/unauthorised";
import { setUserInfo } from "../../../utils/slices/userSlice";
import HotelSessionExpired from "../../../components/hotel-module/cards/session-expired";
import { Rings } from "react-loader-spinner";
import APIs from "../../../api";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { parse } from "date-fns";
import dataNotFound from '../../../../src/assets/images/datanotfound.svg'

export function BookingSummaryMobile({
  hotelDetails,
  filterValues,
  hotelFiltersState,
}) {
  const { t } = useTranslation();
  return (
    <div style={{ padding: "10px" }}>
      <h3 style={{ marginBottom: "10px", marginTop: "10px" }}>
        {t("addContactDetailsScreenHotel.bookingSummary")}
      </h3>
      <div>
        <SelectedHotelDetails
          details={{ hotelDetails, filterValues, hotelFiltersState }}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        {/* <p>Coupon Component</p> */}
        {/* <Coupon
              combinationRoomsPrices={combinationRoomsPrices}
              hotelDetails={hotelDetails}
              couponValue={couponValue}
              setCouponValue={setCouponValue}
              traceId={traceId}
              isCouponApplied={isCouponApplied}
              setIsCouponApplied={setIsCouponApplied}
              couponDetails={couponDetails}
              setCouponDetails={setCouponDetails}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
            /> 
        */}
      </div>
    </div>
  );
}

const PersonalDetails = (props) => {
  const { state } = useLocation();
  const location = useLocation();
  console.log('location ', location)
  const { t } = useTranslation();
  const searchURL = location?.search;
  const searchParams = new URLSearchParams(searchURL);
  const hotelCode = searchParams.get("hotelCode");
  const markupId = searchParams.get('mi')
  const searchId = searchParams.get("searchId");
  const selectedRoomIndex = searchParams.get("sr")
  const resultIndex = searchParams.get("resultIndex");
  const combinationIndex = searchParams.get("combinationIndex");
  const traceId = searchParams.get("traceId");

  const hotelFiltersState = JSON.parse(searchParams.get("hotelFilters"));
  const totalGuests = hotelFiltersState.totalGuests;
  const myRef = useRef(null);
  const accRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const executeAccordianScroll = () => accRef.current.scrollIntoView();

  const [roomsDetails, setRoomsDetails] = useState("");
  const [hotelDetails, setHotelDetails] = useState("");
  const [loading, setLoading] = useState(false)
  const [showScreenError, setShowScreenError] = useState(false)
  const [markup, setMarkup] = useState();
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");


  const combinationRooms = [];
  const RequireAllPaxDetailsRooms = [];
  const allRoomsIsPanMandatory = [];
  const combinationRoomsPrices = [];

  let appliedMarkupAmount = 0;


  const [newCombinationRoomsPrices, setNewCombinationRoomsPrices] = useState(
    []
  );
  const [couponDetails, setCouponDetails] = useState("");
  const noOfRooms = useSelector((state) => state.hotelRooms.rooms.length)
  const [newCombinationPrice, setNewCombinationPrice] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [showChangePriceModal, setShowChangePriceModal] = useState(false);
  const [newHotelRoomsDetails, setNewHotelRoomsDetails] = useState([]);
  const [couponValue, setCouponValue] = useState({});
  const [convenienceFeeId, setConvenienceFeeId] = useState("");
  const user = useSelector((state) => state.user);
  const token = user.token;
  const hotelRooms = useSelector((state) => state.hotelRooms);
  const dispatch = useDispatch();
  const filterValues = get(props, "globalFilters.hotelFilters", {});
  const rooms = get(filterValues, "roomGuests", []);
  const isMobile = useIsMobile();
  const styles = useStyles();
  const [showPriceAccordian, setShowPriceAccordian] = useState(false);
  const [couponId, setCouponId] = useState("");
  const totalNumberOfAdults =
    rooms && rooms.reduce((acc, curr) => acc + curr.adultCounter, 0);
  const totalNumberOfChildren =
    rooms && rooms.reduce((acc, curr) => acc + curr.childrenCounter, 0);

  const ages = [];
  let agesIndex = 0;
  for (let i = 0; i < hotelRooms.rooms.length; i++) {
    const { childrenAges } = hotelRooms.rooms[i];
    ages.push(...childrenAges);
  }
  const [isPriceChanged, setIsPriceChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const hotelGuestDetails = JSON.parse(
    localStorage.getItem("hotelGuestDetails")
  );
  const [detailsFormState, setDetailsFormState] = useState(() => {
    const initialState = {};
    const rooms = hotelRooms?.rooms;
    rooms.map((room, index) => {
      const adultCounter = room.adultCounter;
      const childrenCounter = room.childrenCounter;
      const childrenAges = room.childrenAges;
      initialState[`room${index + 1}`] = {};
      times(adultCounter, (num) => {
        initialState[`room${index + 1}`][`adult${num + 1}`] = {
          title: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`].title
            : "",
          firstName: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`].firstName
            : "",
          lastName: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`].lastName
            : "",
          courtesyTitle: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`]
              .courtesyTitle
            : "",
          // age: hotelGuestDetails
          //   ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`].age
          //   : 0,
          // panCard: hotelGuestDetails
          //   ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`].panCard
          //   : "",
          // passport: hotelGuestDetails
          //   ? hotelGuestDetails[`room${index + 1}`][`adult${num + 1}`].passport
          //   : "",
        };
      });
      times(childrenCounter, (num) => {
        initialState[`room${index + 1}`][`children${num + 1}`] = {
          title: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`children${num + 1}`].title
            : "",
          firstName: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`children${num + 1}`]
              .firstName
            : "",
          lastName: hotelGuestDetails
            ? hotelGuestDetails[`room${index + 1}`][`children${num + 1}`]
              .lastName
            : "",
          // age: hotelGuestDetails
          //   ? hotelGuestDetails[`room${index + 1}`][`children${num + 1}`].age
          //   : childrenAges[num],
          // PAN: hotelGuestDetails
          //   ? hotelGuestDetails[`room${index + 1}`][`children${num + 1}`].PAN
          //   : "",
          // passport: hotelGuestDetails
          //   ? hotelGuestDetails[`room${index + 1}`][`children${num + 1}`]
          //     .passport
          //   : "",
        };
      });
    });

    return initialState;
  });

  const contactDetailsFromLocalStorage = JSON.parse(
    window.localStorage.getItem("contactDetailsForHotel")
  );

  const userDetails = JSON.parse(localStorage.getItem("userDetails"))
  const contactDetails = {
    email: contactDetailsFromLocalStorage?.email
      ? contactDetailsFromLocalStorage?.email
      : userDetails?.email
        ? userDetails.email
        : "",
    mobileNumber: contactDetailsFromLocalStorage?.mobileNumber
      ? contactDetailsFromLocalStorage.mobileNumber
      : userDetails?.phoneNo
        ? userDetails.phoneNo
        : "",
    emailErrorExists: contactDetailsFromLocalStorage || userDetails?.email ? false : true,
    mobileNumberErrorExists: contactDetailsFromLocalStorage || userDetails?.phoneNo ? false : true,
  };
  const [contactDetailsValues, setContactDetailsValues] =
    useState(contactDetails);

  const checkGuestsDetails = () => {
    const rooms = Object.keys(detailsFormState);
    const errorResult = rooms.map((room, index) => {
      const guests = Object.keys(detailsFormState[room]);
      const isRequireAllPaxDetails = RequireAllPaxDetailsRooms[index];
      const isPanMandatory = allRoomsIsPanMandatory[index];
      if (isRequireAllPaxDetails) {
        for (let i = 0; i < guests.length; i++) {
          const { firstName, lastName, age, title, courtesyTitle } =
            detailsFormState[room][guests[i]];


          if (!firstName || !lastName) {
            return [true, guests[i], room];
          }

          const nameRegex = /^[a-zA-Z\u0600-\u06FF ]+$/;
          if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
            return [true, guests[i], room];
          }

          const guest = detailsFormState[room][guests[i]];

          const panCardRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;

          if (
            "courtesyTitle" in guest &&
            isPanMandatory &&
            i == 0 &&
            !panCardRegex.test(guest.panCard)
          ) {
            return [true, guests[i], room];
          }


          if ("courtesyTitle" in guest && !courtesyTitle) {
            return [true, guests[i], room];
          }
          if (!("courtesyTitle" in guest) && !title) {
            return [true, guests[i], room];
          }
        }
      } else {
        for (let i = 0; i < guests.length; i++) {
          const { firstName, lastName, age, title, courtesyTitle } =
            detailsFormState[room][guests[i]];

          if (!firstName || !lastName) {
            return [true, guests[i], room];
          }



          if (guests[i] == "adult1" && (!firstName || !lastName)) {
            return [true, guests[i], room];
          }

          const nameRegex = /^[a-zA-Z\u0600-\u06FF ]+$/;
          if (
            guests[i] == "adult1" &&
            (!nameRegex.test(firstName) || !nameRegex.test(lastName))
          ) {
            return [true, guests[i], room];
          }
          if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
            return [true, guests[i], room];
          }
          const guest = detailsFormState[room][guests[i]];

          const panCardRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;

          if (
            guests[i] == "adult1" &&
            "courtesyTitle" in guest &&
            isPanMandatory &&
            !panCardRegex.test(guest.panCard)
          ) {
            return [true, guests[i], room];
          }

          if (
            guests[i] == "adult1" &&
            "courtesyTitle" in guest &&
            !courtesyTitle
          ) {
            return [true, guests[i], room];
          }

          if ("courtesyTitle" in guest && !courtesyTitle) {
            return [true, guests[i], room];
          }
          if (!("courtesyTitle" in guest) && !title) {
            return [true, guests[i], room];
          }
        }
      }

      return [false, "EveryThingOkay", room];
    });
    for (let i = 0; i < errorResult.length; i++) {
      const error = errorResult[i];
      if (error[0]) {
        return error;
      }
    }
    return [false, "EveryThingOkay"];
  };

  const createPayload = () => {
    const searchDetails = JSON.parse(localStorage.getItem("hotelDetails"));

    const roomDetails = [];
    for (let i = 0; i < searchDetails?.roomGuests.length; i++) {
      roomDetails.push({
        noOfAdults: searchDetails?.roomGuests[i].adultCounter,
        noOfChildren: searchDetails?.roomGuests[i].childrenCounter,
        childrenAges: searchDetails?.roomGuests[i].childrenAges,
      });
    }

    const passengers = [];
    for (const details in detailsFormState) {
      passengers.push(detailsFormState[details]);
    }

    const payload = {
      searchId,
      contactNumber: contactDetailsValues.mobileNumber,
      contactName: detailsFormState?.room1?.adult1?.firstName,
      contactEmail: contactDetailsValues.email,
      hotelDetails: {
        // resultIndex,
        hotelCode,
        city: searchDetails?.hotelCity?.name,
        roomDetails,
      },
      markup: localStorage.getItem("markup_id"),
      markupPrice: roomDetails?.markupAmount,
      couponApplicable: couponId,
      isCouponApplied: isCouponApplied.toString(),
      couponDiscount: couponValue?.amount ? couponValue.amount : 0,
      convenienceFee: convenienceFeeId,
      passenger: passengers,
    };

    return payload;
  };

  async function getConvenienceFeeDetail(details) {
    try {
      const response = await fetch(`${APIs.convenienceFee}`, {
        method: "POST",
        body: JSON.stringify({
          searchId: searchId,
          hotelStarRating: hotelDetails?.HotelRating,
          hotelName: hotelDetails?.HotelName,
          transactionAmount: roomsDetails?.TotalFareRoundOff,
        }),
      });
      const data = await response.json();
      if (data?.statusCode == 400 || data?.data?.statusCode == 400) {
        setSessionExpired(true);
        setStatusMessage(data?.data?.customMessage);
      }
      setConvenienceFeeId(data?.data?._id);
    } catch (err) {
      console.log("error in conveneince fee api ->", err);
    }
  }

  const handleContinueToPayment = async () => {
    let detailsFormStateCopy = cloneDeep(detailsFormState);
    localStorage.setItem("hotelGuestDetails", JSON.stringify(detailsFormState));
    localStorage.setItem(
      "contactDetailsForHotel",
      JSON.stringify(contactDetailsValues)
    );
    let guests = Object.keys(detailsFormStateCopy);
    let isGuestDataErrorExists = checkGuestsDetails();
    const guestTypeAndNumber = isGuestDataErrorExists[1];
    const eroorRoomNumber = isGuestDataErrorExists[2];
    isGuestDataErrorExists = isGuestDataErrorExists[0];
    const { emailErrorExists, mobileNumberErrorExists } = contactDetailsValues;
    function capitalizeFirstLetter(str) {
      if (typeof str !== "string" || str.length === 0) {
        return str;
      }
      return (
        str.charAt(0).toUpperCase() +
        str.slice(1, str.length - 1) +
        " " +
        str.slice(str.length - 1)
      );
    }

    if (emailErrorExists || mobileNumberErrorExists) {
      executeScroll();
      toast.error(`${t("errorMessages.enterValidContactDetails")}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (isGuestDataErrorExists) {
      executeAccordianScroll();
      console.log('eroorRoomNumber',eroorRoomNumber)
      console.log('guestTypeAndNumber',guestTypeAndNumber)
      toast.error(
        `${t("errorMessages.enterValid")} 
        ${t(`errorMessages.${eroorRoomNumber}`)}
        ${t(`errorMessages.${guestTypeAndNumber}`)}
        ${t("errorMessages.details")}!`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (!user || user.token == "" || user.token == undefined) {
      const payload = createPayload();
      await addPersonalDetails({ payload });
      if (isMobile) {
        // eventBus.emit("open-dialog", <MobileOtpEnter />);
        navigate('/mobile/login', {
          state: {
            redirection: location.pathname + location.search
          }
        })
      } else {
        dispatch(setLoginPopup(true));
        dispatch(
          handleRedirectUrl({
            url: "/",
            values: {
              props,
              // combinationRoomsPrices,
              // combinationPrice,
              hotelDetails,
              detailsFormState,
            },
          })
        );
      }
    } else {
      const payload = createPayload();
      await addPersonalDetails({ token: user.token, payload });
      navigate(
        `/hotel/payment?hotelFilters=${JSON.stringify(hotelFiltersState)}`,
        {
          state: {
            hotelDetails,
            filterValues,

            token,
            detailsFormState,
            contactDetailsValues,
            detailsFormStateCopy,
            hotelRooms,
            resultIndex: Number(resultIndex),
            TraceId: traceId,
            hotelCode: hotelCode,
            couponValue,
            isCouponApplied,
            couponDetails,
            successMessage,

            hotelFiltersState,
            roomsDetails,
            searchId: searchId,
            markupId: markupId
          },
        }
      );
    }
  };

  const handleAdultsDetailsUpdate = (payload, index, roomIndex) => {
    const copiedState = cloneDeep(detailsFormState);
    set(
      copiedState,
      `room${roomIndex + 1}.adult${index}.${get(payload, "name", "")}`,
      get(payload, "value", "")
    );
    setDetailsFormState(copiedState);
  };
  const handleChildrenDetailsUpdate = (payload, index, roomIndex) => {
    const copiedState = cloneDeep(detailsFormState);
    set(
      copiedState,
      `room${roomIndex + 1}.children${index}.${get(payload, "name", "")}`,
      get(payload, "value", "")
    );
    setDetailsFormState(copiedState);
  };

  useEffect(() => {
    const HotelCode = hotelCode;
    async function getData() {
      try {
        setLoading(true)
        const [hotelDetails, roomsDetails, markupDetails] = await Promise.all([
          getHotelDetails({
            hotelCode: HotelCode,
            token,
            searchId
          }),

          getRoomsDetails({
            hotelCode,
            searchId,
            token: user?.token,
          }),

          getMarkup(markupId)
        ]);
        setLoading(false)
        if (hotelDetails.statusCode != 200
          || roomsDetails.statusCode != 200
          || markupDetails.statusCode != 200
          || !selectedRoomIndex) {
          setShowScreenError(true)
        }



        const statusCode = hotelDetails?.statusCode;
        const message = hotelDetails?.message;
        if (statusCode == 401) {
          handleUnauthorisedLogout(message);
          dispatch(setUserInfo({}));
          navigate("/");
        }

        if (statusCode == 400) {
          setSessionExpired(true);
          setStatusMessage(hotelDetails?.data?.customMessage);
        }
        if (hotelDetails?.data?.statusCode == 400) {
          setSessionExpired(true);
          setStatusMessage(hotelDetails?.data?.customMessage);
        }
        if (hotelDetails?.data?.detailsResponse) {
          setHotelDetails(hotelDetails?.data?.detailsResponse[0]);
        }

        if (roomsDetails?.data && roomsDetails.data[0] && selectedRoomIndex) {
          if (markupDetails?.data) {
            const { amount, amountType, appliedOn, maxAmount } = markupDetails.data
            const markup = { amountType, amount, appliedOn, maxAmount }
            roomsDetails.data[0].Rooms[Number(selectedRoomIndex)].markup = markup

            let TotalFareRoundOff = Number(parseFloat(get(roomsDetails.data[0].Rooms[Number(selectedRoomIndex)], "TotalFare", 0)).toFixed(2));
            let TotalTaxRoundOff = Number(parseFloat(get(roomsDetails.data[0].Rooms[Number(selectedRoomIndex)], "TotalTax", 0).toFixed(2)));
            let TotalBaseFare = Number(parseFloat(TotalFareRoundOff - TotalTaxRoundOff).toFixed(2))
            if (markup) {
              let amt = 0;
              const { amount, amountType, appliedOn, maxAmount } = markup;
              if (amountType === "FLAT") {
                amt = amount < maxAmount ? amount : maxAmount;
                amt = Number(parseFloat(noOfRooms * amt).toFixed(2))
              } else {
                // amt = amount < maxAmount ? amount : maxAmount;
                if (appliedOn === "BASEFARE") {
                  amt = (amount * TotalBaseFare) / 100;
                } else {
                  amt = (amount * TotalFareRoundOff) / 100;
                }
                amt = maxAmount < amt ? maxAmount : amt;
              }
              // value = value < amt ? amt : value;
              amt = Number(parseFloat(amt).toFixed(2))
              TotalFareRoundOff = parseFloat(TotalFareRoundOff + amt).toFixed(2)
              TotalBaseFare = parseFloat(TotalFareRoundOff - TotalTaxRoundOff).toFixed(2)
              roomsDetails.data[0].Rooms[Number(selectedRoomIndex)].markupAmount = amt
            }
            roomsDetails.data[0].Rooms[Number(selectedRoomIndex)].TotalFareRoundOff = TotalFareRoundOff
            roomsDetails.data[0].Rooms[Number(selectedRoomIndex)].TotalTaxRoundOff = TotalTaxRoundOff
            roomsDetails.data[0].Rooms[Number(selectedRoomIndex)].TotalBaseFare = TotalBaseFare
            setRoomsDetails(roomsDetails.data[0].Rooms[Number(selectedRoomIndex)])

          } else {
            setRoomsDetails(roomsDetails.data[0].Rooms[Number(selectedRoomIndex)])
          }
        }
      } catch (err) {
        console.log("error in roomsDetails api");
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (hotelDetails && roomsDetails && !convenienceFeeId) {
      getConvenienceFeeDetail();
    }
  },);

  useEffect(() => {
    eventBus.emit("close-bottomSheet");
  }, []);
  const handleDecrypt = async (encryptedData) => {
    try {
      const REACT_APP_SECRETKEY = moduleValue + flightValue + hotelValue + "e3n";
      const key = CryptoJS.enc.Utf8.parse(REACT_APP_SECRETKEY);
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, {
        mode: CryptoJS.mode.ECB,
      });
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const decryptedObject = JSON.parse(decryptedText);
      return decryptedObject;
    } catch (error) {
      return null;
    }
  };


  return (
    <>
      {/* {sessionExpired &&
        <HotelSessionExpired statusMessage={statusMessage} />
      } */}

      {roomsDetails && !loading && !showScreenError &&
        <div className={styles.personalScreenPage}>
          <Divider />

          <HotelStepper activeStep={1} />
          <div className={styles.personalDetailsContainer}>
            <div className={styles.personalDetailsLayout}>
              {user && !user?.token && <AlreadyMember />}
              {isMobile && (
                <Box
                  sx={{
                    padding: "0px 10px",
                  }}
                  onClick={() =>
                    eventBus.emit(
                      "open-bottomSheet",
                      <BookingSummaryMobile
                        filterValues={filterValues}
                        hotelDetails={hotelDetails}
                        hotelFiltersState={hotelFiltersState}
                      />
                    )
                  }
                >
                  <BookingSummary />
                </Box>
              )}

              <div
                style={{
                  padding: "0px 10px",
                }}
                ref={myRef}
              >
                <AddContactDetails
                  contactDetailsValues={contactDetailsValues}
                  setContactDetailsValues={setContactDetailsValues}
                />
              </div>
              {totalGuests.length > 0 &&
                totalGuests?.map((guest, index) => {

                  const adultCounter = guest?.adultCounter;
                  const childrenCounter = guest?.childrenCounter;
                  const childrenAges = guest?.childrenAges;

                  return (
                    <>
                      <Box
                        sx={{
                          padding: "0px 10px",
                        }}
                        ref={accRef}
                      >
                        <span
                          style={{
                            margin: "20px 10px",
                            fontWeight: "bolder",
                          }}
                        >
                          {t("addContactDetailsScreenHotel.room")}{" "}
                          {index + 1}
                        </span>
                        <AddPersonalDetails
                          // isPanMandatory={IsPANMandatory}
                          // isPassportMandatory={IsPassportMandatory}
                          key={0}
                          label={`${t(
                            "addContactDetailsScreenHotel.adult"
                          )}  ${1} :`}
                          isForAdult={true}
                          onDetailsChange={(payload, index) => {
                            handleAdultsDetailsUpdate(payload, 1, index);
                          }}
                          num={0}
                          index={index}
                          data={
                            detailsFormState[`room${index + 1}`][
                            `adult${0 + 1}`
                            ]
                          }
                          isPrimaryAdult={true}
                        // RequireAllPaxDetails={RequireAllPaxDetails}
                        />
                        {times(adultCounter, (num) => {
                          if (num > 0) {
                            return (
                              <AddPersonalDetails
                                // isPanMandatory={IsPANMandatory}
                                // isPassportMandatory={IsPassportMandatory}
                                key={num}
                                label={`${t(
                                  "addContactDetailsScreenHotel.adult"
                                )}  ${num + 1} :`}
                                isForAdult={true}
                                notPrimaryAdult={true}
                                onDetailsChange={(payload, index) =>
                                  handleAdultsDetailsUpdate(
                                    payload,
                                    num + 1,
                                    index
                                  )
                                }
                                index={index}
                                num={num}
                                data={
                                  detailsFormState[`room${index + 1}`][
                                  `adult${num + 1}`
                                  ]
                                }
                              />
                            );
                          }
                        })}
                        {times(childrenCounter, (num) => {
                          const age = childrenAges[num];
                          return (
                            <AddPersonalDetails
                              age={age}
                              // isPanMandatory={IsPANMandatory}
                              // isPassportMandatory={IsPassportMandatory}
                              key={num}
                              label={`${t(
                                "addContactDetailsScreenHotel.children"
                              )}  ${num + 1} :`}
                              isForAdult={false}
                              onDetailsChange={(payload, index) =>
                                handleChildrenDetailsUpdate(
                                  payload,
                                  num + 1,
                                  index
                                )
                              }
                              index={index}
                              data={
                                detailsFormState[`room${index + 1}`][
                                `children${num + 1}`
                                ]
                              }
                              notAllChildDetailsRequired={true}
                            />
                          );
                        })}
                      </Box>
                    </>
                  );
                  // }
                })}

              {!isMobile && (
                <div
                  style={{
                    display: "inline-flex",
                    // justifyContent: "space-between",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "4px",
                      padding: "10px 12px",
                      borderRadius: "8px",
                      border: "1px solid rgb(27, 29, 82)",
                      background: "#1b1d52",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      onClick={handleContinueToPayment}
                      style={{
                        color: "#fff",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        // zIndex: "1500",
                      }}
                    >
                      {t("addContactDetailsScreenHotel.continueToPayment")}
                      <Backdrop
                        sx={{
                          color: "#1B1D52",
                          zIndex: "2",
                        }}
                        open={open}
                        onClick={handleClose}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Typography>
                  </div>
                </div>
              )}
            </div>

            {!isMobile && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  width: "40%",
                }}
              >
                <SelectedHotelDetails
                  details={{
                    hotelDetails,
                    filterValues,
                    hotelFiltersState,
                  }}
                />
                <SelectedHotelFareBreakdown
                  details={{
                    hotelDetails,
                    filterValues,

                    couponValue,
                    roomsDetails,
                  }}
                />
                <Coupon
                  hotelDetails={hotelDetails}
                  couponValue={couponValue}
                  setCouponValue={setCouponValue}
                  traceId={traceId}
                  isCouponApplied={isCouponApplied}
                  setIsCouponApplied={setIsCouponApplied}
                  couponDetails={couponDetails}
                  setCouponDetails={setCouponDetails}
                  successMessage={successMessage}
                  setSuccessMessage={setSuccessMessage}
                  setCouponId={setCouponId}
                  roomsDetails={roomsDetails}
                />
              </div>
            )}
            {isMobile && (
              <div style={{ margin: "12px 10px 100px 10px" }}>
                <Coupon
                  hotelDetails={hotelDetails}
                  couponValue={couponValue}
                  setCouponValue={setCouponValue}
                  traceId={traceId}
                  isCouponApplied={isCouponApplied}
                  setIsCouponApplied={setIsCouponApplied}
                  couponDetails={couponDetails}
                  setCouponDetails={setCouponDetails}
                  successMessage={successMessage}
                  setSuccessMessage={setSuccessMessage}
                  setCouponId={setCouponId}
                  roomsDetails={roomsDetails}
                />
              </div>
            )}
            {isMobile && (
              <div className={styles.container}>
                {showPriceAccordian && (
                  <div className={styles.fullFairDetailsContainer}>
                    <div className={styles.FairDetailsContainer}>
                      <p className={styles.fareDetails}>
                        {/* Fare details */}
                        {t('fareRuleCard.fareDetails')}

                        </p>
                      <div className={styles.baseFareAndTaxesFessContainer}>
                        <div className={styles.baseFareContainer}>
                          <p className={styles.baseFareText}>
                            {/* Base Fare */}
                            {t('fareRuleCard.baseFare')}

                            </p>
                          <p className={styles.baseFare}>
                            {`${t("hotelDetails.currencySymbol")} `}
                            {parseFloat(
                              roomsDetails.TotalBaseFare
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div className={styles.taxesAndFessContainer}>
                          <p className={styles.taxesAndFeesText}>
                            {/* Taxes & fees{" "} */}
                            {t('fareRuleCard.taxesAndFees')}

                          </p>
                          <p className={styles.taxesAndFees}>
                            {`${t("hotelDetails.currencySymbol")} `}
                            {parseFloat(
                              roomsDetails.TotalTaxRoundOff
                            ).toFixed(2)}
                          </p>
                        </div>
                        {isCouponApplied && (
                          <div className={styles.taxesAndFessContainer}>
                            <p className={styles.taxesAndFeesText}>
                              {/* Discount */}
                              {t('fareRuleCard.discount')}

                            </p>
                            <p className={styles.taxesAndFees}>
                              {`${t("hotelDetails.currencySymbol")} `}
                              {parseFloat(couponValue.amount).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.fairContainer}>
                  <div style={{ display: "flex" }}>
                    <ExpandMore
                      className={styles.expandIcon}
                      onClick={() =>
                        setShowPriceAccordian(!showPriceAccordian)
                      }
                    />
                    <div className={styles.fairCardLayout}>
                      <div className={styles.fairLayout}>
                        <div className={styles.totalText}>
                          {/* Total (incl.tax) */}
                          {t('fareRuleCard.totalIncludingTax')}
                        </div>
                        <div className={styles.fair}>
                          {`${t("hotelDetails.currencySymbol")} `}
                          {isCouponApplied
                            ? Number((parseFloat(roomsDetails.TotalFareRoundOff -
                              parseFloat(couponValue.amount).toFixed(2))).toFixed(2))
                            : roomsDetails.TotalFareRoundOff}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={handleContinueToPayment}
                      className={styles.bookNowButton}
                    >
                      {/* Book Now */}
                      {t("addContactDetailsScreenHotel.bookNow")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      {loading &&
        <div
          className={styles.personalScreenLoader}

        >
          <Rings
            height="150"
            width="150"
            color="#1B1D52"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      }
      {showScreenError && <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          width: '100vw'
        }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img src={dataNotFound} />
          <p>Room details not found </p>

        </div>
      </div>}
    </>
  );
};

export default PersonalDetails;
