import React, { useState } from "react";
import useStyles from "./styles";
import { MobileCitySelectionField } from "../../../ui/components/modifySearchFlightMobile/components/MobileSearchFlightMobile";
import { MobileCalender } from "../../components/MobileCalender";
import FlightClass from "../../../ui/components/modifySearchFlightMobile/components/dropdown/flight-class";
import TotalTravellers from "../../../ui/components/modifySearchFlightMobile/components/dropdown/total-persons";
import moment from "moment";
import { Button } from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import eventBus from "../../../core/eventBus";

import {
  addModifySearchedFlights,
  handleLoader,
  updateModifyDates,
  updateModifyFlightTravelType,
  updateModifySearchFlightObject,
  updateModifyFlightFrom,
  updateModifyFlightTo,
  updateModifyTotalSelectionForMultiCity,
  addError,
  removeError,
  handleSearchApplicable,
} from "../../../utils/slices/modifySearchFlightSlice";
import { get } from "lodash";
import { clearBookedFlights } from "../../../utils/slices/bookedFlightSlice";
import {
  updateQueryFlightFrom,
  updateQueryFlightTo,
} from "../../../utils/slices/searchFlightQuerySlice";
import Error from "../../../components/flight-module/error";
import { useTranslation } from "react-i18next";

export const MobileOneWayFlightFilterView = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const isDisabled = get(props, "isDisabled", false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();

  const {
    flightTravelType,
    flightType,
    flightFrom,
    flightTo,
    flightDepartureDate,
    flightReturnDate,
    flightClassType,
    flightTotalTravellers: {
      totalCount,
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
    },
    errors,
    multiCityDates,
    searchApplicable,
  } = useSelector((state) => state.modifySearchFlight);

  const addSearchFlightDetailsToLocalStorage = (value, returnDate) => {
    const data = {
      flightTravelType: value ? value : flightTravelType,
      flightType,
      flightFrom,
      flightTo,
      flightDepartureDate,
      flightReturnDate: returnDate ? returnDate : flightReturnDate,
      totalCount,
      flightAdultsCount,
      flightChildrenCount,
      flightInfantsCount,
      flightClassType,
      multiCityDates,
    };
    window.localStorage.setItem("flightDetails", JSON.stringify(data));
    window.localStorage.setItem(
      "modifySearchFlightDetails",
      JSON.stringify(data)
    );
    dispatch(updateModifySearchFlightObject(data));
    // navigate("/flight/search");
  };

  const [flightFilterState, setFlightFilterState] = useState({
    cities: {},
    checkInDate: "",
    checkOutDate: "",
  });

  const handleCitySelection = (payload) => {
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(updateModifyFlightFrom({ id: 0, data: payload.from }));
    dispatch(updateModifyFlightTo({ id: 0, data: payload.to }));
  };

  const formatDate = (rawDate) => {
    const formattedValue = moment(rawDate).format("DD/MM/YYYY");
    return formattedValue;
  };

  const handleDepartureDateChange = (checkInDate) => {
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    setFlightFilterState((prev) => ({ ...prev, checkInDate }));
    setCheckInDate(checkInDate.formattedValue);
    dispatch(
      updateModifyDates({
        returnDate: "",
        departureDate: checkInDate.formattedValue,
      })
    );
    dispatch(updateModifyFlightTravelType(1));
  };
  const handleArrivalDateChange = (checkOutDate) => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    setFlightFilterState((prev) => ({ ...prev, checkOutDate }));
    dispatch(
      updateModifyDates({
        returnDate: checkOutDate.formattedValue,
        departureDate: flightDepartureDate,
      })
    );
    setCheckOutDate(checkOutDate.formattedValue);

    dispatch(updateModifyFlightTravelType(2));
  };

  const handleClick = () => {
    dispatch(removeError({ id: "one-way" }));
    dispatch(removeError({ id: "one-way-search" }));
    dispatch(removeError({ id: "round-trip" }));
    dispatch(removeError({ id: "multi-city-1" }));
    dispatch(removeError({ id: "multi-city-2" }));
    for (let i = 0; i < flightFrom.length; i++) {
      if (
        flightFrom[i]?._id &&
        flightTo[i]?._id &&
        flightFrom[i]?._id === flightTo[i]?._id
      ) {
        dispatch(
          addError({
            id: "one-way-search",
            errorMessage: `${t("errorMessages.departureDifferentFromArrival")}`,
          })
        );
        return;
      }

      if (errors.length === 0) {
        dispatch(clearBookedFlights());
        if (
          Object.keys(flightFrom?.[0]).length !== 0 &&
          Object.keys(flightTo?.[0]).length !== 0
        ) {
          if (flightTravelType === 1) {
            if (flightDepartureDate.length === 0) {
              dispatch(
                addError({
                  id: "one-way",
                  errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                })
              );
            } else {
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateModifyTotalSelectionForMultiCity(0));
              dispatch(handleLoader(true));
              addSearchFlightDetailsToLocalStorage(flightTravelType);
              dispatch(handleSearchApplicable(!searchApplicable));
              const nonStop = flightType == 1 ? true : false;
              navigate(
                `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}&tt=O&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
              );
              eventBus.emit("close-bottomSheet");
            }
          } else if (flightTravelType === 2) {
            if (flightDepartureDate.length === 0) {
              dispatch(
                addError({
                  id: "one-way",
                  errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                })
              );
            } else if (flightReturnDate.length === 0) {
              dispatch(
                addError({
                  id: "round-trip",
                  errorMessage: `${t("errorMessages.selectReturnDate")}`,
                })
              );
            } else {
              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateModifyTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(flightTravelType);
              dispatch(handleLoader(true));
              dispatch(handleSearchApplicable(!searchApplicable));
              const nonStop = flightType == 1 ? true : false;
              navigate(
                `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightTo[0].code}-${flightFrom[0].code}-${flightReturnDate}&tt=R&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
              );
              eventBus.emit("close-bottomSheet");
            }
          } else {
            if (
              Object.keys(flightFrom?.[2]).length !== 0 &&
              Object.keys(flightTo?.[2]).length !== 0
            ) {
              if (
                Object.keys(flightFrom?.[1]).length !== 0 &&
                Object.keys(flightTo?.[1]).length !== 0
              ) {
                if (flightDepartureDate.length === 0) {
                  dispatch(
                    addError({
                      id: "one-way",
                      errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                    })
                  );
                } else {
                  const isFirstDatePresent = multiCityDates.find(
                    (date) => date.key === 1
                  );
                  if (!isFirstDatePresent) {
                    dispatch(
                      addError({
                        id: "multi-city-1",
                        errorMessage: `${t(
                          "errorMessages.selectDepartureDate"
                        )}`,
                      })
                    );
                    return;
                  }

                  const isSecondDatePresent = multiCityDates.find(
                    (date) => date.key === 2
                  );
                  if (!isSecondDatePresent) {
                    dispatch(
                      addError({
                        id: "multi-city-2",
                        errorMessage: `${t(
                          "errorMessages.selectDepartureDate"
                        )}`,
                      })
                    );
                    return;
                  }

                  const [firstDate] = multiCityDates.filter(
                    (date) => date.key === 1
                  );
                  const [secondDate] = multiCityDates.filter(
                    (date) => date.key === 2
                  );

                  dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                  dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                  dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                  dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                  dispatch(updateModifyTotalSelectionForMultiCity(3));
                  addSearchFlightDetailsToLocalStorage(3);
                  dispatch(handleLoader(true));
                  dispatch(handleSearchApplicable(!searchApplicable));
                  const nonStop = flightType == 1 ? true : false;
                  navigate(
                    `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightFrom[1].code}-${flightTo[1].code}-${firstDate.date}_${flightFrom[2].code}-${flightTo[2].code}-${secondDate.date}&tt=M&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
                  );
                  eventBus.emit("close-bottomSheet");
                }
              } else {
                dispatch(
                  addError({
                    id: "multi-city-2-city",
                    errorMessage: `${t(
                      "errorMessages.enterSourceDestination"
                    )}`,
                  })
                );
              }
            } else if (
              Object.keys(flightFrom?.[1]).length !== 0 &&
              Object.keys(flightTo?.[1]).length !== 0
            ) {
              if (flightDepartureDate.length === 0) {
                dispatch(
                  addError({
                    id: "one-way",
                    errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                  })
                );
                return;
              } else {
                const isFirstDatePresent = multiCityDates.find(
                  (date) => date.key === 1
                );
                if (!isFirstDatePresent) {
                  dispatch(
                    addError({
                      id: "multi-city-1",
                      errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                    })
                  );
                  return;
                }
              }
              if (
                flightFrom[1]._id === flightTo[0]._id &&
                flightFrom[0]._id === flightTo[1]._id
              ) {
                const index = multiCityDates.findIndex(
                  (date) => date.key === 1
                );
                dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                dispatch(updateFlightTravelType(Number(2)));
                dispatch(updateModifyFlightTravelType(Number(2)));

                dispatch(
                  updateModifyDates({
                    departureDate: flightDepartureDate,
                    returnDate: multiCityDates[index].date,
                  })
                );

                dispatch(
                  updateModifyDates({
                    departureDate: flightDepartureDate,
                    returnDate: multiCityDates[index].date,
                  })
                );

                addSearchFlightDetailsToLocalStorage(
                  2,
                  multiCityDates[index].date
                );
                const nonStop = flightType == 1 ? true : false;
                dispatch(handleLoader(true));
                dispatch(handleSearchApplicable(!searchApplicable));
                navigate(
                  `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightTo[0].code}-${flightFrom[0].code}-${multiCityDates[index].date}&tt=R&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
                );
                eventBus.emit("close-bottomSheet");
              } else {
                const [firstDate] = multiCityDates.filter(
                  (date) => date.key === 1
                );
                dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
                dispatch(updateQueryFlightTo({ id: 0, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
                dispatch(updateQueryFlightTo({ id: 1, data: {} }));
                dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
                dispatch(updateQueryFlightTo({ id: 2, data: {} }));
                dispatch(updateModifyTotalSelectionForMultiCity(2));
                addSearchFlightDetailsToLocalStorage(3);
                dispatch(handleLoader(true));
                dispatch(handleSearchApplicable(!searchApplicable));
                const nonStop = flightType == 1 ? true : false;
                navigate(
                  `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}_${flightFrom[1].code}-${flightTo[1].code}-${firstDate.date}&tt=M&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
                );
                eventBus.emit("close-bottomSheet");
              }
            } else {
              if (flightDepartureDate.length === 0) {
                dispatch(
                  addError({
                    id: "one-way",
                    errorMessage: `${t("errorMessages.selectDepartureDate")}`,
                  })
                );
                return;
              }

              dispatch(updateQueryFlightFrom({ id: 0, data: {} }));
              dispatch(updateQueryFlightTo({ id: 0, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 1, data: {} }));
              dispatch(updateQueryFlightTo({ id: 1, data: {} }));
              dispatch(updateQueryFlightFrom({ id: 2, data: {} }));
              dispatch(updateQueryFlightTo({ id: 2, data: {} }));
              dispatch(updateModifyFlightTravelType(Number(1)));
              dispatch(updateModifyTotalSelectionForMultiCity(0));
              addSearchFlightDetailsToLocalStorage(1);
              const nonStop = flightType == 1 ? true : false;
              dispatch(handleLoader(true));
              dispatch(handleSearchApplicable(!searchApplicable));
              navigate(
                `/flight/search?itinerary=${flightFrom[0].code}-${flightTo[0].code}-${flightDepartureDate}&tt=O&pt=A-${flightAdultsCount}_C-${flightChildrenCount}_I-${flightInfantsCount}&cc=${flightClassType}&ns=${nonStop}`
              );
              eventBus.emit("close-bottomSheet");
            }
          }
        } else {
          dispatch(
            addError({
              id: "one-way-search",
              errorMessage: `${t("errorMessages.enterSourceDestination")}`,
            })
          );
        }
      }
    }
  };
  return (
    <div className={styles.mobileOneWayFlight}>
      <MobileCitySelectionField
        onChange={handleCitySelection}
        for="flights"
        index={0}
      />
      {errors &&
        errors
          .filter((error) => error.id === "one-way-search")
          .map((error) => {
            const { id, errorMessage } = error;
            return <Error key={id} errorMessage={errorMessage} />;
          })}
      <MobileCalender
        placeholder={t("flightSearchWidgetMobile.departureDate")}
        formatDate={formatDate}
        onDateChange={handleDepartureDateChange}
        selectedDate={flightDepartureDate}
      />
      {errors &&
        errors
          .filter((error) => error.id === "one-way")
          .map((error) => {
            const { id, errorMessage } = error;
            return <Error key={id} errorMessage={errorMessage} />;
          })}
      {!isDisabled && (
        <>
          <MobileCalender
            disabled={isDisabled}
            placeholder={t("flightSearchWidgetMobile.returnDate")}
            formatDate={formatDate}
            onDateChange={handleArrivalDateChange}
            selectedDate={flightReturnDate}
            minDate={flightDepartureDate}
          />
          {errors &&
            errors
              .filter((error) => error.id === "round-trip")
              .map((error) => {
                const { id, errorMessage } = error;
                return <Error key={id} errorMessage={errorMessage} />;
              })}
        </>
      )}
      <FlightClass />
      <TotalTravellers />
      <Button
        onClick={handleClick}
        label={`${t("flightSearchWidgetMobile.search")}`}
      />
    </div>
  );
};
