import { makeStyles } from "@mui/styles";
import findTravelPerfectionBackGround from "../../assets/images/travel-preference.svg";

const useStyles = makeStyles((theme) => ({
  homeCarouselAndSearchWidgetContainer: {
    position: "relative",
  },
  homeSearchWidgetWrapper: {
    // position: "absolute",
    // top: "70%",
    // top: "15%",
    // top: "-50%",
    marginTop: "-14%",
    zIndex: 10,
    [theme.breakpoints.down("md")]: {
      top: "10%",
    },
  },
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      // background: "#FAFAFA",
    },
    [theme.breakpoints.up("xl")]: {
      background: "#FAFAFA",
    },
  },
  subContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "1434px",
    },
  },
  ContainerHome: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  homeMidSection: {
    display: "flex",
    flexDirection: "column",
    rowGap: "50px",
    boxSizing: "border-box",
    padding: "0px 150px 0px 150px",

    [theme.breakpoints.down("sm")]: {
      padding: "12px !important",
      rowGap: "40px",
    },

    [theme.breakpoints.down("lg")]: {
      padding: "0px 100px 0px 100px",
    },
  },
  offers: {
    width: "1130px",
    marginTop: "100px",
    marginLeft: "140px",
    zIndex: 100,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      marginLeft: "20px",
    },
  },

  packages: {
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },

  adsCarousel: {
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      marginLeft: "0px",
      marginRight: "0px",
      marginBottom: "0px",
    },
  },

  imageCarousel: {
    padding: "0px 32px",

    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },

  recentArticleAndBlog: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
      marginRight: "0px",
    },
    marginRight: "100px",
    marginLeft: "20px",
  },

  recentSearches: {
    marginTop: "50px",
  },

  newsletter: {
    marginTop: "400px",
  },

  travelPerfectionBackground: {
    width: "800px",
    height: "420px",
    marginTop: "-191px",
    background: `url(${findTravelPerfectionBackGround}) 50% / cover no-repeat`,
    position: "absolute",
    marginLeft: "287px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      width: "100vW",
      height: "300px",
      marginTop: "0px",
    },
  },

  blurDiv1: {
    width: "350px",
    height: "380px",
    borderRadius: "50%",
    background: "rgba(1, 158, 236, 0.30)",
    filter: "blur(142px)",
    position: "absolute",
    right: "0px",
    zIndex: 1,
    [theme.breakpoints.down("xl")]: {
      top: "560px",
    },

    [theme.breakpoints.down("lg")]: {
      top: "470px",
    },
    [theme.breakpoints.down("md")]: {
      top: "380px",
    },

    [theme.breakpoints.down("sm")]: {
      top: "250px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "150px",
    },
  },
  blurDiv2: {
    // width: "350px",
    // height: "380px",
    // borderRadius: "50%",
    // background: "rgba(1, 158, 236, 0.30)",
    // filter: "blur(142px)",
    // position: "absolute",
    // right: "0px",
    // zIndex: 1,
    // [theme.breakpoints.down("xl")]: {
    //   top: "2080px",
    // },

    // [theme.breakpoints.down("lg")]: {
    //   top: "1980px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   top: "1760px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   top: "700",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   top: "180",
    // },
    width: "350px",
    height: "380px",
    borderRadius: "50%",
    background: "rgba(1, 158, 236, 0.30)",
    filter: "blur(142px)",
    position: "absolute",
    left: "0px",
    zIndex: 0,
    [theme.breakpoints.down("xl")]: {
      top: "calc(100vh - 2080px)",
    },

    [theme.breakpoints.down("lg")]: {
      top: "calc(100vh - 1980px)",
    },
    [theme.breakpoints.down("md")]: {
      top: "calc(100vh - 1760px)",
    },

    [theme.breakpoints.down("sm")]: {
      top: "calc(100vh - 700px)",
    },
    [theme.breakpoints.down("xs")]: {
      top: "calc(100vh - 180px)",
    },
  },
  blurDiv3: {
    width: "350px",
    height: "380px",
    borderRadius: "50%",
    background: "rgba(0, 255, 238, 0.30)",
    filter: "blur(142px)",
    position: "absolute",
    right: "0px",
    zIndex: 0,
    [theme.breakpoints.down("xl")]: {
      top: "calc(100vh - 2480px)",
    },

    [theme.breakpoints.down("lg")]: {
      top: "calc(100vh - 2580px)",
    },
    [theme.breakpoints.down("md")]: {
      top: "calc(100vh - 1760px)",
    },

    [theme.breakpoints.down("sm")]: {
      top: "calc(100vh - 1900px)",
    },
    [theme.breakpoints.down("xs")]: {
      top: "calc(100vh - 600px)",
    },
  },
  blurDiv4: {
    width: "350px",
    height: "380px",
    borderRadius: "50%",
    background: "rgba(61, 88, 219, 0.30)",
    filter: "blur(142px)",
    position: "absolute",
    left: "0px",
    zIndex: -1,

    [theme.breakpoints.down("xl")]: {
      top: "calc(100vh - 3500px)",
    },

    // [theme.breakpoints.down("lg")]: {
    //   bottom: "280px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   bottom: "580px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   bottom: "580px",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   bottom: "680px",
    // },
  },

  findTravelPerfection: {
    position: "absolute",
    width: "1140px",
    height: "350px",
    marginTop: "-160px",
    marginLeft: "125px",

    [theme.breakpoints.down("sm")]: {
      width: "100vW",
      height: "280px",
      marginLeft: "5px",
      paddingRight: "5px",
      marginTop: "40px",
    },
  },
}));

export default useStyles;
