import { Modal } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./style";
import { forgotPasswordApi } from "../../modules/auth";
import { setEmailConfirmation } from "../../utils/slices/popupSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router-dom";

const EmailConfirmation = () => {
  const styles = useStyles();
  const [email, setEmail] = useState();
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setIsEmptyError(false);
    setInvalidEmailError(false);
  };

  const submit = async () => {
    setIsEmptyError(false);
    setInvalidEmailError(false);

    if (!email) {
      setIsEmptyError(true);
    } else if (
      !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      setInvalidEmailError(true);
    } else {
      const data = {
        email: email,
      };

      const response = await forgotPasswordApi(data);
      if (response?.statusCode == 200) {
        dispatch(setEmailConfirmation(false));
        toast.success(t("successMessages.emailSentSuccessfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log('location', location)
        if (location.pathname == "/mobile/forgot-password") {
          navigate('/')
        }
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  return (
    <>
      <div className={styles.emailConfirmationView} open={true}>
        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
          <h1 className={styles.emailVerificationText}>
            {t("login.forgotPassword")}
            {/* Forgot Password? */}
          </h1>
          {location.pathname != "/mobile/forgot-password"
            && <CloseIcon
              fontSize="50px"
              style={{ position: 'absolute', top: '5px', right: '-90px' }}
              onClick={() => { dispatch(setEmailConfirmation(false)); }}
            />
          }
        </div>
        <div className={styles.emailBottomContent}>
          <p className={styles.enterEmailText}>
            {/* Please enter email address to forgot your password */}
            {t("forgotPassword.enterEmailToForgotPassword")}
          </p>
          <input
            className={styles.emailInput}
            placeholder={t("newsletter.pleaseEnterEmailAddress")}
            type="email"
            onChange={handleEmail}
          ></input>
          {isEmptyError && (
            <div className={styles.invalid}>
              {t("newsletter.pleaseEnterEmailAddress")}
            </div>
          )}
          {invalidEmailError && (
            <div className={styles.invalid}>
              {t("newsletter.pleaseEnterValidEmailAddress")}
            </div>
          )}
          <p className={styles.notificationText}>
            {/* *A verification link will be sent to your email address. */}
            {t("forgotPassword.verificationLinkWillBeSent")}
          </p>
        </div>
        <button onClick={submit} className={styles.submitButton}>
          {t('forgotPassword.submit')}
          {/* Submit */}
        </button>
      </div>
    </>
  );
};

export default EmailConfirmation;
