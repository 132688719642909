import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  sortBottomDrawer: {
    display: "inline-flex",
    width: "100%",
    height: "280px",
    padding: "16px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    flexShrink: 0,
    borderRadius: "16px 16px 0px 0px",
    background: " #FFF",
    boxShadow: "0px -4px 12px 0px rgba(0, 0, 0, 0.12)",
  },

  sortCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    width: "100%",
  },
  title: {
    display: "flex",
    padding: "8px 8px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    justifyContent: "space-between",
  },
  sortList: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  sortListLayout1: {
    display: "flex",
    padding: "16px 8px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderBottom: "1px solid  #D0D5DD",
    cursor: "pointer",
  },
  sortListLayout: {
    display: "flex",
    padding: "16px 8px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    cursor: "pointer",
  },
  sortListLayoutSelected1: {
    display: "flex",
    padding: "16px 8px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderBottom: "1px solid #D0D5DD",
    background: "#E6E6FA",
  },
  sortListLayoutSelected: {
    display: "flex",
    padding: "16px 8px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    background: "#E6E6FA",
  },

  clearAll: {
    color: "#4037acd6",
  },

  sortBy: {
    display: "flex",
    gap: "20px",
  },
}));

export default useStyles;
