import { createSlice } from "@reduxjs/toolkit";

const localFilterSlice = createSlice({
  name: "localFilters",
  initialState: {
    flightLocalFilters: [],
    hotelLocalFilters: [],
    filterHotelByName: "",
    checkBoxCheckedFilter: "",
    priceFilter: {
      key: "price",
      value: "",
    },
    minMaxPrice: {
      minPrice: 0,
      maxPrice: 0,
      currentPrice: [],
    },
  },
  reducers: {
    updateFlightLocalFilters: (state, action) => {
      state.flightLocalFilters = action.payload;
    },
    updateHotelLocalFilters: (state, action) => {
      state.hotelLocalFilters = action.payload;
    },

    searchHotelByName: (state, action) => {
      state.filterHotelByName = action.payload;
    },

    updateCheckBoxChecked: (state, action) => {
      state.checkBoxCheckedFilter = action.payload;
    },

    updatePriceSorting: (state, action) => {
      state.priceFilter = action.payload;
    },

    getMinMaxPrice: (state, action) => {
      state.minMaxPrice = action.payload;
    },
  },
});

export const {
  updateFlightLocalFilters,
  updateHotelLocalFilters,
  searchHotelByName,
  updateCheckBoxChecked,
  updatePriceSorting,
  getMinMaxPrice,
} = localFilterSlice.actions;

export default localFilterSlice.reducer;
