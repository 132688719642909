import React, { useState } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Box, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BaggageAccordianContant = ({
  title,
  baggage,
  selected,
  type,
  setExtraBaggage,
  extraBaggage,
  index,
  toggle,
  setToggle,
}) => {
  const [checkedBaggage, setCheckedBaggage] = useState();
  const { t } = useTranslation();
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  const selectedExtraBaggage = (data, i) => {
    let extraBaggageArray = extraBaggage;
    let updatedArray = extraBaggage[index];

    if (checkedBaggage == i) {
      setCheckedBaggage(-1);
      const isExistsIndex = updatedArray.findIndex(
        (baggage) => baggage.id == selected && baggage.type == type
      );
      if (isExistsIndex !== -1) {
        updatedArray.splice(isExistsIndex, 1);
        extraBaggageArray[index] = updatedArray;
        setExtraBaggage(extraBaggageArray);
      }
      extraBaggageArray[index] = [...updatedArray];
      setExtraBaggage(extraBaggageArray);
      setToggle(!toggle);
      return;
    }
    setCheckedBaggage(i);

    const isExistsIndex = updatedArray.findIndex(
      (baggage) => baggage.id == selected && baggage.type == type
    );

    if (isExistsIndex !== -1) {
      updatedArray.splice(isExistsIndex, 1);
      extraBaggageArray[index] = updatedArray;
      setExtraBaggage(extraBaggageArray);
    }

    extraBaggageArray[index] = [
      ...updatedArray,
      {
        ...data,
        id: selected,
        type,
      },
    ];

    setExtraBaggage(extraBaggageArray);
    setToggle(!toggle);
  };

  const isSelectedBaggage = (weight, price) => {
    let isSelected = false;
    extraBaggage &&
      extraBaggage[index] &&
      extraBaggage[index].map((baggage, i) => {
        if (
          baggage.id == selected &&
          baggage.type == type &&
          baggage.Weight == weight &&
          baggage.Price == price
        ) {
          isSelected = true;
          return;
        }
      });

    return isSelected;
  };

  return (
    <>
      <div style={{ marginBottom: "16px", marginTop: "28px" }}>
        <Accordion>
          <AccordionSummary
            sx={{ background: "#E6E6FA" }}
            expandIcon={<ExpandMoreIcon style={{ color: "#1b1d52" }} />}
          >
            <Typography className="baggage-accordian-heading">
              <div className="selected-wheight">{title}</div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ justifyContent: "center" }}>
            <div className="baggage-details-accordian-content-parent-div">
              {baggage &&
                baggage &&
                baggage.map((data, i) => {
                  const { Price, Weight } = data;

                  return (
                    <div className="baggage-details-accordion-content-div">
                      <Box sx={{ display: "flex", gap: "8px " }}>
                        <Checkbox
                          checked={
                            i == checkedBaggage ||
                            isSelectedBaggage(Weight, Price)
                          }
                          onClick={() => selectedExtraBaggage(data, i)}
                          sx={{
                            color: "#1b1d52",
                            "&.Mui-checked": {
                              color: "#1b1d52",
                            },
                          }}
                        />
                        {/* <input
                          type="checkbox"
                          checked={
                            i == checkedBaggage ||
                            isSelectedBaggage(Weight, Price)
                          }
                          onClick={() => selectedExtraBaggage(data, i)}
                        /> */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Lato",
                              fontSize: "12px",
                              fontWeight: " 400px",
                            }}
                          >
                            {t("addBaggagePopUp.weight")} {Weight}
                            {t("addBaggagePopUp.kg")}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Lato",
                              fontSize: "12px",
                              fontWeight: " 400px",
                            }}
                          >
                            {t("addBaggagePopUp.currencySymbol")}{" "}
                            {Math.ceil(Price * rateOfExchange)}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  );
                })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default BaggageAccordianContant;

BaggageAccordianContant.propTypes = {
  title: PropTypes.string,
  baggage: PropTypes.any,
  selected: PropTypes.any,
  type: PropTypes.any,
};
