import { createSlice } from "@reduxjs/toolkit";

const getHotelDetailsFromLocalStorage = () => {
  const hotel = JSON.parse(window.localStorage.getItem("hotelDetails"));
  return hotel;
};

const hotel = getHotelDetailsFromLocalStorage();

const initialState = {
  rooms:
    hotel && hotel.roomGuests
      ? hotel.roomGuests
      : [{ adultCounter: 1, childrenCounter: 0, childrenAges: [] }],
};

const hotelRoomSlice = createSlice({
  name: "hotelRooms",
  initialState,
  reducers: {
    addRoom: (state) => {
      if (state.rooms.length > 5) {
        return;
      }
      const newRoom = { adultCounter: 1, childrenCounter: 0, childrenAges: [] }; // Initialize childrenAges array
      state.rooms.push(newRoom);
    },
    removeRoom: (state, action) => {
      if (action.payload === 0 || state.rooms.length <= 1) {
        return;
      }
      state.rooms.splice(action.payload, 1);
    },
    updateAdultCounter: (state, action) => {
      const { roomIndex, newAdultCounter } = action.payload;
      state.rooms[roomIndex].adultCounter = newAdultCounter;
    },
    updateChildrenCounter: (state, action) => {
      const { roomIndex, newChildrenCounter } = action.payload;
      state.rooms[roomIndex].childrenCounter = newChildrenCounter;
    },
    updateChildrenAges: (state, action) => {
      const { roomIndex, newChildrenAges } = action.payload;
      const updatedRoom = {
        ...state.rooms[roomIndex],
        childrenAges: newChildrenAges,
      };
      state.rooms[roomIndex] = updatedRoom;
    },
  },
});

export const {
  addRoom,
  removeRoom,
  updateAdultCounter,
  updateChildrenCounter,
  updateChildrenAges,
} = hotelRoomSlice.actions;

export default hotelRoomSlice.reducer;
