import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "inline-flex",
    gap: "16px",
    flex: "2"
  },
  subheaderContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    flex: "1"
  },
  flightResultSubheaderContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    flex: "1"
  },
}));

export default useStyles;
