import React from "react";
import SignupInForm from "./IN";
// import { signup } from "../../modules/auth";
import { signup } from "../../../../modules/auth";
import { useDispatch, useSelector } from "react-redux";
// import { setSignupPopup } from "../../utils/slices/popupSlice";
// import { setUserInfo } from "../../utils/slices/userSlice";
import { setUserInfo } from "../../../../utils/slices/userSlice";
import { useNavigate } from "react-router-dom";
import eventBus from "../../../../core/eventBus";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const dispatch = useDispatch();
  const {t}  = useTranslation()
  const navigate = useNavigate();
  const { redirectUrl } = useSelector((state) => state.popup);
  const submitForm = async (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNo: values.number,
      password: values.password,
      deviceToken: "test",
      deviceType: "WEB",
    };
    const response = await signup(data)
    const {
      statusCode,
      data: {
        newCustomer,
        firstName,
        accessToken,
        lastName,
        email,
        profilePic,
        message,
        phoneNo
      },
    } = response
    console.log('statusCode ', statusCode)
    if (statusCode === 200) {
      const userDetails = {}
      userDetails.newCustomer = newCustomer;
      userDetails.firstName = firstName;
      userDetails.token = accessToken;
      userDetails.lastName = lastName;
      userDetails.email = email;
      userDetails.profilePic = profilePic;
      userDetails.phoneNo = phoneNo
      window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
      //   dispatch(setUserInfo(userDetails));
      //   if (redirectUrl.url !== "/") {
      //     navigate(redirectUrl.url, {
      //       state: redirectUrl.values,
      //     });
      //   }

      eventBus.emit("close-dialog", <Signup />);
      location.reload()
      toast.success(t("successMessages.signupSuccesffullVerifyYourEmail"))
      // dispatch(setSignupPopup(false));
    } else {
      toast.error(message)
    }
  };

  return (
    <>
      <SignupInForm submitForm={submitForm} />
    </>
  );
};

export default Signup;
