import { createSlice } from "@reduxjs/toolkit";

const domesticRoundTripSlice = createSlice({
  name: "domesticRoundTrip",
  initialState: {
    searchedFlights: [],
    selectedFlight: [],
  },
  reducers: {
    handleSearchedFlightsForDomesticRoundTrip: (state, action) => {
      state.searchedFlights = action.payload;
    },
    handleSelectedFlightForDomesticRoundTrip: (state, action) => {
      state.selectedFlight = action.payload;
    },
  },
});

export const {
  handleSearchedFlightsForDomesticRoundTrip,
  handleSelectedFlightForDomesticRoundTrip,
} = domesticRoundTripSlice.actions;

export default domesticRoundTripSlice.reducer;
