import React, { useEffect, useState } from "react";
import { getBookingDetails } from "../../../../../../../modules/flight";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { ReactSVG } from "react-svg";
import ArrowRightSvg from "../../../../../../../assets/images/arrow-right.svg";
import SuccessContainer from "./components/success-container";
import ContactInformation from "./components/contact-information";
import FareRules from "./components/fare-rules";
import { Divider } from "@mui/material";
import PaymentDetails from "./components/payment-details";
import FlightDetailsContainer from "./components/flight-details";
import TicketOptions from "./components/ticket-options";
import AirlineContact from "./components/airline-contact";
import { useTranslation } from "react-i18next";
import { setRate } from "../../../../../../../utils/slices/rateOfExchangeSlice";

const FlightBookingDetails = () => {
  const { id } = useParams();
  const {
    state: { bookingIds },
  } = useLocation();
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);
  const [bookingDetails, setBookingdetails] = useState();
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [tickets, setTickets] = useState();
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [baggageFee, setBaggageFee] = useState(0);
  const [seatFee, setSeatFee] = useState(0);
  const [insuranceFee, setInsuranceFee] = useState(0);
  const [bookingStatus, setBookingStatus] = useState("");
  const [totalFee, setTotalFee] = useState(0);
  const [couponFee, setCouponFee] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState();
  const [pdfUrl, setPdfUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [seatDetails, setSeatDetails] = useState();
  const [baggageDetails, setBaggageDetails] = useState();
  const [Passenger, setPassenger] = useState();
  const [flightSearch, setFlightSearch] = useState();
  const [fareDetailsInSAR, setFareDetailsInSAR] = useState({})
  const rateOfExchange = useSelector((state) => state.rateOfExchange.rate);
  // console.log("flightSearch123", flightSearch[0].tboResponse.LocalCurrencyROE);
  const handleGetBookingDetailsByBookingId = async () => {
    const data = await getBookingDetails(id, token);
    if (data.statusCode != 200) {
      navigate(`/flight/booking/${id}/invalid`);
    } else {
      if (data.data.length === 0) {
        navigate(`/flight/booking/${id}/invalid`);
      } else {
        setBookingdetails(data.data[0]);
        const {
          flightSearch,
          flightTickets,
          baggageAmount,
          // convenienceFeeAmount,
          // couponDiscount,
          seatAmount,
          insuranceAmount,
          totalPayableAmount,
          billdeskPaymentResponse,
          status,

          TotalAmountSAR,
          taxSAR,
          convenienceFeeAmount,
          markupPrice,
          baseFareSAR,
          vatAmountSAR,
          seatAmountSAR,
          baggageAmountSAR,
          couponDiscount,
        } = data.data[0];

        const fareDetailsInSAR = {
          TotalAmountSAR,
          taxSAR,
          convenienceFeeAmount,
          markupPrice,
          baseFareSAR,
          vatAmountSAR,
          seatAmountSAR,
          baggageAmountSAR,
          couponDiscount
        }
        setFareDetailsInSAR(fareDetailsInSAR)
        setSeatDetails(seatAmount);
        setBaggageDetails(baggageAmount);
        setBookingStatus(status);
        setFlightSearch(flightSearch);

        dispatch(setRate(flightSearch[0].tboResponse.LocalCurrencyROE));

        const {
          ticket: {
            Results: {
              Itinerary: { Passenger },
            },
          },
        } = flightTickets[0];
        setPassenger(Passenger);
        const vatAmount = Passenger[0].Fare.VATAmount;
        const { flightSegments } = flightSearch[0];
        setTickets(flightTickets);
        setOrigin(flightSegments[0].origin);
        setDestination(flightSegments[flightSegments.length - 1].destination);
        setBaggageFee(Math.ceil(baggageAmount));
        setConvenienceFee(Math.ceil(convenienceFeeAmount));
        setTotalFee(Math.ceil(totalPayableAmount));
        setCouponFee(Math.ceil(couponDiscount));
        setSeatFee(Math.ceil(seatAmount));
        setInsuranceFee(Math.ceil(insuranceAmount));
        setPaymentDetails(billdeskPaymentResponse);
        if (data.data[0]?.ticketPdf) {
          setPdfUrl(data.data[0].ticketPdf);
        }
      }
    }
  };

  useEffect(() => {
    handleGetBookingDetailsByBookingId();
  }, []);

  const airlineContactNumber =
    bookingDetails?.flightTickets[0]?.ticket?.Results?.Itinerary
      ?.AirlineTollFreeNo;
  const airLineCode =
    bookingDetails?.flightTickets[0]?.ticket?.Results?.Itinerary?.AirlineCode;
  return (
    <div className={styles.mainContainer}>
      <div className={styles.breadCrumbContainer}>
        <p className={styles.nonActiveBreadCrumb} onClick={() => navigate("/")}>
          {/* Home */}
          {t("myBookings.home")}
        </p>
        <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
        <p
          className={styles.nonActiveBreadCrumb}
          onClick={() => navigate("/bookings")}
        >
          {/* My Bookings */}
          {t("myBookings.myBookings")}
        </p>
        <ReactSVG src={ArrowRightSvg} style={{ marginTop: "3px" }} />
        <p className={styles.activeBreadCrumb}>{bookingIds}</p>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          <SuccessContainer
            id={bookingIds}
            date={bookingDetails?.createdAt}
            bookingStatus={bookingStatus}
          />
          {bookingDetails &&
            bookingDetails?.flightTickets &&
            bookingDetails?.flightTickets.map((flightTicket, i) => {
              return (
                <FlightDetailsContainer
                  flightSearch={bookingDetails.flightSearch}
                  flightTicket={flightTicket}
                  index={i}
                  key={i}
                />
              );
            })}
          <div className={styles.informationContainer}>
            <p className={styles.infoHeading}>
              {/* Important Information */}
              {t("bookingConfirmationFlight.importantInformation")}
            </p>
            <ul
              style={{
                color: "#667085",
                fontSize: "12px",
              }}
            >
              <li>
                {/* If you require any help with your booking, please don&apos;t
                hesitate to reach out to us at support@joyustrips.com. */}
                {t("bookingConfirmationFlight.supportMessage")}

              </li>
              <li>
                {/* Please note that it is the sole responsibility of each customer
                traveling internationally to ensure they have all the required
                travel documents listed below. This ticket is in electronic
                format, so please ensure you have a valid form of identification
                with you for the check-in process. */}
                {t("bookingConfirmationFlight.importantNote")}
              </li>
              <li>
                {/* Visa: You must obtain the necessary visas in accordance with the
                legal requirements of each country you will be departing from
                (the departure country), transiting through (any transit
                country), and entering (the destination country). */}
                {t("bookingConfirmationFlight.visaRequirementsNote")}

              </li>
              <li>
                {/* Passport: The crucial document for air travel must have a
                validity of at least 6 months beyond the planned stay duration. */}
                {t("bookingConfirmationFlight.passportValidityNote")}

              </li>
              <li>
                {/* Tickets: Hard copy / soft copy based on the Airline’s policy. */}
                {t("bookingConfirmationFlight.ticketFormatNote")}

              </li>
              <li>
                {/* Airline baggage allowances differ depending on the ticket
                category and type of fare purchased. Therefore, we kindly ask
                you to verify this information. */}
                {t("bookingConfirmationFlight.baggageAllowanceNote")}

              </li>
              <li>
                {/* Boarding Pass: You can either use an available one or print it
                at the check-in counters or self-service kiosks at the airport. */}
                {t("bookingConfirmationFlight.boardingPassNote")}

              </li>
              {/* <li>Mobile phones -with all the necessary travel apps.</li> */}
              {t("bookingConfirmationFlight.mobilePhonesNote")}

              <li>
                {/* Immigration authorities mandate that airlines furnish advance
                passenger information before departure, so please make sure your
                bookings are updated before your travel. */}
                {t("bookingConfirmationFlight.immigrationInformationNote")}

              </li>
              <li>
                {/* The Inclusion of &apos;Ok to Board&apos; is Mandated by Specific
                Airlines Based on Their Criteria. */}
                {t("bookingConfirmationFlight.okToBoardNote")}

              </li>
            </ul>
            <p
              style={{
                color: "#344054",
                fontSize: "14px",
              }}
            >
              {/* Terms and Conditions */}
              {t("bookingConfirmationFlight.termsAndConditions")}

            </p>
            <ul
              style={{
                color: "#667085",
                fontSize: "12px",
              }}
            >
              <li>
                {/* DGCA mandates all passengers to wear masks and to maintain
                social distancing norms at all times during their travel. All
                passengers must ensure strict compliance of Covid-19 protocols. */}
                {t("bookingConfirmationFlight.covidProtocolsNote")}

              </li>
              <li>
                {/* Check - in 3 hours prior for international travel and 2 hours
                prior for domestic travel.{" "} */}
                {t("bookingConfirmationFlight.checkInTimingNote")}

              </li>
              <li>
                {/* The passenger needs to check-in at least 2 hrs prior departure
                for Air India and Air India Express domestic flights else will
                be considered as a no show. */}
                {t("bookingConfirmationFlight.airIndiaCheckInNote")}

              </li>
              <li>
                {/* Infants must have valid proof-of-age documents showing that the
                infant is less than two years old.{" "} */}
                {t("bookingConfirmationFlight.infantProofOfAgeNote")}

              </li>
              <li>
                {/* Name changes on a confirmed reservation are not permissible.
                Hence request you to reconfirm the name before issuance. */}
                {t("bookingConfirmationFlight.nameChangeNote")}

              </li>
              <li>
                {/* For Code share, internline and feeder flights baggage conditions
                of the operating airline are applicable. Please contact
                operating airline for detailed information. If your itinerary
                includes flights operated by more than one airline, please read
                carefully each such airline&apos;s terms and conditions, which
                can be found on each such airline&apos;s website. Each such
                airline will have its own restrictions, rules and fees. If one
                of these flights is affected by an airline change (e.g.,
                cancellation or rescheduling) that causes a customer to make
                changes to the other flight, the Customer may be responsible for
                any fees or ticket cost incurred for making changes to the
                unaffected flight. Such airlines may charge their own fees for
                changes, refunds, or if exchanges are requested. You are
                responsible for complying with each airline&apos;s terms and
                conditions, which may differ (for example, check-in times and
                limits on baggage size/weight). It is advisable you print your
                outbound and return portions of your e-ticket confirmation for
                all flights prior to travelling. You may be asked for proof of
                your return ticket at check-in. */}
                {t("bookingConfirmationFlight.codeShareNote")}

              </li>
              <li>
                {/* Joyus does not assume any liability whatsoever for cancelled
                flights, flights that are missed, or flights not connecting due
                to any scheduled changes made by the airlines. */}
                {t("bookingConfirmationFlight.joyusDoesNotAssueLiabilityNote")}

              </li>
              <li>
                {/* Valuable and Fragile Items- Customers are strongly advised not
                to check in such items as Baggage which by their very nature are
                valuable and/ or fragile. If such items are checked in as
                Baggage, Customers agree that they send for carriage of such
                items, at their own risk. Such items include, without
                limitation, money, jewellery, silverware, electronic devices,
                medicines, perishable goods, computers, cameras, video
                equipment, negotiable papers, securities and / or other
                valuables, electronic items, passports and other identification
                documents. Joyus shall not accept any responsibility for such
                items carried by the Customers in their Baggage. */}
                {t("bookingConfirmationFlight.valuableItemsNote")}

              </li>
              <li>
                {/* Passengers who book a series of one-way tickets (e.g. separate
                ticketing), need to check if the Minimum Connection Time (MCT)
                is enough between flights. Joyus might provide different
                airlines as a cost-effective option but Joyus would not be
                responsible if any one of the airlines rescheduled the itinerary
                that would result in a shorter connection time, thereby risking
                a missed flight. To avoid any issues be sure to either book a
                return flight (and the airline will manage the MCT for you) or
                that you have adequate time during your connection or layover to
                catch your next flight. */}
                {t("bookingConfirmationFlight.seriesOfOneWayTicketsNote")}

              </li>
              <li>
                {/* All Bookings are subject to the Conditions of Carriage. However,
                our special airfare charges have certain additional terms and
                conditions attached to them. In the event Booking is made on the
                special airfare charges, except to the extent specified
                otherwise in the additional terms and conditions governing the
                special air fares charges, these Conditions of Carriage shall
                apply in its entirety. */}
                {t("bookingConfirmationFlight.conditionsOfCarriageNote")}

              </li>
              <li>
                {/* All seat requests will be forwarded to the airlines. Please be
                advised that not all seat requests are guaranteed. If you want
                to receive immediate confirmation on your seat or if you have
                any special requirement such as stretcher assistance or
                wheelchair, please contact the airline directly. */}
                {t("bookingConfirmationFlight.seatRequestNote")}

              </li>
              <li>
                {/* Passengers are advised to compulsorily retain the boarding pass
                until exiting the terminal for security reasons. Passengers on
                via and connecting flights should keep their boarding pass handy
                for physical check at transit points.{" "} */}
                {t("bookingConfirmationFlight.boardingPassRetainNote")}

              </li>
            </ul>
          </div>
          {tickets && tickets.length > 0 && (
            <ContactInformation
              ticket={tickets[0]}
              id={id}
              setLoader={setLoader}
              loader={loader}
              bookingStatus={bookingStatus}
            />
          )}
        </div>
        <div className={styles.rightContainer}>
          {tickets && tickets.length > 0 && bookingStatus != "CANCELLED" && (
            <TicketOptions
              pdfUrl={pdfUrl}
              ticket={tickets[0]}
              id={id}
              setLoader={setLoader}
              loader={loader}
            />
          )}
          <AirlineContact
            airlineContactNumber={airlineContactNumber}
            airLineCode={airLineCode}
          />
          <div className={styles.fareRuleContainer}>
            {tickets &&
              tickets.length > 0 &&
              tickets.map((flightTicket) => {
                const {
                  ticket: { Results: Itinerary },
                  status,
                } = flightTicket;

                if (status !== "CONFIRMED") return;
                return (
                  <FareRules
                    flight={Itinerary}
                    seatDetails={seatDetails}
                    baggageDetails={baggageDetails}
                    flightSearch={flightSearch}
                    showWay={tickets.length === 2 ? true : false}
                    markup={bookingDetails.markupPrice}
                    fareDetailsInSAR={fareDetailsInSAR}
                  />
                );
              })}
            <div className={styles.mainFeeContainer}>
              <div className={styles.extraFeeContainer}>
                {fareDetailsInSAR?.seatAmountSAR ? (
                  <div className={styles.feeContainer}>
                    <p className={styles.feeContainerTitle}>
                      {t("fareRuleCard.extraSeatPrice")}
                    </p>
                    <p className={styles.feeContainerPrice}>
                      {t("fareRuleCard.currencySymbol")}{" "}
                      {/* {Math.ceil(seatDetails * rateOfExchange).toLocaleString(
                        "en-IN"
                      )} */}
                      {fareDetailsInSAR?.seatAmountSAR}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {fareDetailsInSAR?.baggageAmountSAR ? (
                  <div className={styles.feeContainer}>
                    <p className={styles.feeContainerTitle}>
                      {t("fareRuleCard.extraBaggage")}
                    </p>
                    <p className={styles.feeContainerPrice}>
                      SAR{" "}
                      {/* {Math.ceil(
                        baggageDetails * rateOfExchange
                      ).toLocaleString("en-IN")} */}
                      {fareDetailsInSAR?.baggageAmountSAR}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {insuranceFee ? (
                  <div className={styles.feeContainer}>
                    <p className={styles.feeContainerTitle}>
                      {t("fareRuleCard.insurance")}
                    </p>
                    <p className={styles.feeContainerPrice}>
                      SAR {insuranceFee.toLocaleString("en-us")}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {fareDetailsInSAR?.couponDiscount ? (
                  <div className={styles.feeContainer}>
                    <p className={styles.feeContainerTitle}>
                      {t("fareRuleCard.discount")}
                    </p>
                    <p className={styles.feeContainerPrice}>
                      - SAR
                      {/* {couponFee.toLocaleString("en-us")} */}
                      {fareDetailsInSAR?.couponDiscount}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {fareDetailsInSAR?.convenienceFeeAmount ? (
                  <div className={styles.feeContainer}>
                    <p className={styles.feeContainerTitle}>
                      {t("fareRuleCard.convenienceFee")}
                    </p>
                    <p className={styles.feeContainerPrice}>
                      {t("fareRuleCard.currencySymbol")}{" "}
                      {/* {convenienceFee.toLocaleString("en-us")} */}
                      {fareDetailsInSAR?.convenienceFeeAmount}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Divider sx={{ borderStyle: "dashed" }} />
              {fareDetailsInSAR?.TotalAmountSAR ? (
                <div className={styles.feeContainer}>
                  <p className={styles.feeContainerTitleMain}>
                    {t("fareRuleCard.totalPrice")}
                  </p>
                  <p className={styles.feeContainerPriceMain}>
                    {t("fareRuleCard.currencySymbol")}{" "}
                    {/* {Math.ceil(
                      (Passenger[0].Fare.TotalFare -
                        seatDetails -
                        baggageDetails) *
                      rateOfExchange
                    ) +
                      bookingDetails.markupPrice +
                      Math.ceil(seatDetails * rateOfExchange) +
                      Math.ceil(baggageDetails * rateOfExchange) +
                      Math.ceil(convenienceFee) -
                      Math.ceil(couponFee)} */}
                    {fareDetailsInSAR?.TotalAmountSAR}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {paymentDetails && <PaymentDetails details={paymentDetails} />}
        </div>
      </div>
    </div>
  );
};

export default FlightBookingDetails;
