import React from "react";
import useStyles from "./style";
import arrowBack from "../../../assets/images/arrow-left.svg";
import { ReactSVG } from "react-svg";
import calendarIcon from "../../../assets/images/calendar-modify.svg";
import personIcon from "../../../assets/images/modify-profile.svg";
import editIcon from "../../../assets/images/edit-icon.svg";
import { useSelector } from "react-redux";
import { get, map, sum } from "lodash";
import moment from "moment";
import eventBus from "../../../core/eventBus";
import { MobileHotelFilterView } from "../../views/MobileHotelFilter/MobileHotelFilterView";
import { useLocation, useNavigate } from "react-router-dom";
import MobileHotelModifyWIdget from "../../views/modifySearchWidgetHotelMobile";
import SearchWidgetMobileModify from "../../../components/flight-module/search-flight/components/cards/modify-search-mobile";
import mobileMoonIcon from "../../../../src/assets/images/mobile-moon-icon.svg";
import { calculateNumberOfNights } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const ModifySearchHotelMobile = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let hotelFilters = useSelector((state) => state.globalFilters.hotelFilters);
  const location = useLocation();
  const searchURL = location?.search;

  const path = location.pathname;

  const searchParams = new URLSearchParams(searchURL);
  const hotelCheckInDate = searchParams.get("hi"); // Returns "07/11/2023"
  const hotelCheckoutDate = searchParams.get("hco"); // Returns "09/11/2023"
  const hotelCity = JSON.parse(searchParams.get("hc")); // Returns "09/11/2023"
  const totalGuests = JSON.parse(searchParams.get("tg")); //
  const hotelFiltersFromURL = JSON.parse(searchParams.get("hotelFilters"));

  const hotelFiltersState = {
    hotelCity,
    totalGuests,
    hotelCheckInDate,
    hotelCheckoutDate,
  };
  if (path == "/hotel/details") {
    hotelFilters = hotelFiltersFromURL;
  } else {
    hotelFilters = hotelFiltersState;
  }
  const { hotelSearchPageSearchGoingOn } = useSelector(
    (state) => state.hotelSearchState
  );
  const city = get(hotelFilters.hotelCity, "name", "");
  const country = get(hotelFilters.hotelCity, "country", "");
  const checkInDate = get(hotelFilters, "hotelCheckInDate", 0);
  const checkOutDate = get(hotelFilters, "hotelCheckoutDate", 0);

  // const hotelName = localStorage.getItem("hotelName");
  const adultCounter = map(hotelFilters.totalGuests, (roomGuest) =>
    get(roomGuest, "adultCounter", 0)
  );

  const totalAdultCount = sum(adultCounter);
  const childrenCounter = map(hotelFilters.totalGuests, (roomGuest) =>
    get(roomGuest, "childrenCounter", 0)
  );

  const totalChildrenCount = sum(childrenCounter);

  function formatDate(dateString) {
    if (dateString) {
      const date = moment(dateString, "DD/MM/YYYY");
      return date.format("D MMM");
    }
  }

  function formatDateToArabic(dateString) {
    if(!dateString){
      return ""
    }
    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split("/");

    // Define an array of Arabic month names
    const arabicMonths = [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];

    // Convert the month to Arabic
    const arabicMonth = arabicMonths[parseInt(month) - 1];

    // Return the formatted date with Arabic month
    return `${day} ${arabicMonth}`;
  }

  let formattedCheckInDate;
  let formattedCheckOutDate;

  if (localStorage.getItem("language") == "AR") {
    formattedCheckInDate = formatDateToArabic(checkInDate);
    formattedCheckOutDate = formatDateToArabic(checkOutDate);
  } else {
    formattedCheckInDate = formatDate(checkInDate);
    formattedCheckOutDate = formatDate(checkOutDate);
  }

  const handleOpenHotelSearchWidget = () => {
    eventBus.emit(
      "open-bottomSheet",
      <div style={{ padding: "14px", height: "650px" }}>
        <MobileHotelModifyWIdget />
      </div>
    );
  };
  return (
    <div className={styles.modifySearchHotelMobileContainer}>
      <div onClick={() => navigate(-1)}>
        <ReactSVG src={arrowBack} />
      </div>

      <div className={styles.modifySearchHotelDetailsContainer}>
        <div className={styles.cityContainer}>
          <div className={styles.cityName}>
            {/* {path == "/hotel/search" ? `${city}, ${country}` : hotelName} */}
            {city} {country}
          </div>
        </div>
        <div className={styles.roomDetails}>
          <div className={styles.checkInCheckoutDate}>
            <ReactSVG src={calendarIcon} />
            <div className={styles.dates}>
              {formattedCheckInDate} - {formattedCheckOutDate}
            </div>
          </div>
          <div className={styles.guestDetails}>
            <ReactSVG src={personIcon} />
            <div className={styles.guestNumber}>
              {totalAdultCount}
              {t("codes.adultCode")}
              {totalChildrenCount > 0 && (
                <>
                  ,{totalChildrenCount}{t("codes.childrenCode")}
                </>
              )}
            </div>
          </div>
          <div className={styles.stayNights}>
            <ReactSVG
              style={{
                color: "#1B1D52",
                marginRight: "5px",
                marginTop: "2px",
                rotate: "270deg",
              }}
              src={mobileMoonIcon}
            />
            {calculateNumberOfNights(checkInDate, checkOutDate)}{" "}
            {calculateNumberOfNights(checkInDate, checkOutDate) > 1
              ? t("hotelSearchWidget.nights")
              : t("hotelSearchWidget.night")}
          </div>
        </div>
      </div>
      {path == "/hotel/search" && !hotelSearchPageSearchGoingOn && (
        <div onClick={handleOpenHotelSearchWidget}>
          <ReactSVG src={editIcon} />
        </div>
      )}
    </div>
  );
};

export default ModifySearchHotelMobile;
