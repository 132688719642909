import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  blogs: {
    borderRadius: "10px",
    borderRight: "1px solid #D0D5DD",
    borderBottom: "1px solid #D0D5DD",
    borderLeft: "1px solid #D0D5DD",
    background: "#FFF",
    cursor: "pointer",
  },

  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
      background: "#FAFAFA",
    },
  },
  subContainer: {
    [theme.breakpoints.up("lg")]: {
      width: "1434px",
    },
  },

  blogImage: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    objectFit: "cover",
  },

  blogContainer: {
    background: " #FAFAFA",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "20px 150px",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 50px",
    },
  },

  blogsContainer: {
    display: "grid",
    gridTemplateRows: "1fr",
    gap: "24px",
    marginBottom: "80px",
    // padding: "0px 150px",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3,minmax(200px,1fr))",
    },
    // [theme.breakpoints.down('lg')]: {
    //   padding: '0px 20px'
    // },
  },
  blogsBelowSection: {
    display: "flex",
    //width: "1150px",
    padding: "10px",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  previousNextContainer: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  showingText: {
    color: "rgba(36, 39, 42, 0.56)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
