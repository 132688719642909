import { makeStyles } from "@mui/styles";
import bgImage from "../../../assets/images/newsletter_image_joyus.jpg";
import mobileBgImage from "../../../assets/images/Group 427319449.png";

const useStyles = makeStyles((theme) => ({
  newsLetter: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "370px",
    margin: "80px 0px",
    backgroundImage: `url(${bgImage})`,
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    [theme.breakpoints.down("md")]: {
      height: "200px",
      margin: "20px 0px",
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: "50% 50%", // Adjust the position as needed
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      //   backgroundImage: `url(${mobileBgImage}) 50% / cover no-repeat`,
    },
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      margin: "20px 0px",
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: "57% 50%", // Adjust the position as needed
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      //   backgroundImage: `url(${mobileBgImage}) 50% / cover no-repeat`,
    },
  },

  newsLetterHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: "4px",
  },
  newsLetterSubTitle: {
    color: theme.palette.textColors.textColorPrimary,
    fontSize: theme.fontSizes.fontSize2,
    fontWeight: "400",
  },
  newsLetterTitle: {
    fontSize: theme.fontSizes.fontSizeJumbo1,
    color: theme.palette.textColors.textColorPrimary,
    fontWeight: "700",
  },
  newsLetterForm: {
    display: "flex",
    columnGap: "16px",
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
  },
  newsLetterEmailFieldWrapper: {
    display: "flex",
    alignItems: "flex-start",
    columnGap: "8px",
    padding: "16px",
    borderRadius: "10px",
    backgroundColor: theme.palette.entityColors.entityColorBackground1,
    [theme.breakpoints.up("md")]: {
      width: "396px",
    },
  },
  newsLetterEmailField: {
    border: "none",
    background: theme.palette.entityColors.entityColorBackground1,
    width: "100%",

    "&::placeholder": {
      fontSize: theme.fontSizes.fontSize2,
      color: theme.palette.textColors.textColorSecondary,
    },
    "&:focus": {
      outline: "none",
    },
  },
  newsLetterSubmitButton: {
    padding: "16px",
    color: theme.palette.entityColors.entityColorBackground1,
    backgroundColor: theme.palette.entityColors.entityColorPrimary,
    border: "none",
    borderRadius: "10px",
    cursor: "pointer",
  },
  newsLetterSubmissionDialog: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px 24px",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: "10px",
  },

  emailInputError: {
    borderRadius: "12px",
    background: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    display: "inline-flex",
    gap: "8px",
    border: "1px solid red",
  },

  emailInput: {
    borderRadius: "12px",
    background: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    display: "inline-flex",
    gap: "8px",
  },

  invalid: {
    alignSelf: "center",
    marginTop: "4px",
    fontSize: theme.fontSizes.fontSize2,
    color: "red",

    // [theme.breakpoints.up("md")]: {
    //   marginRight: "300px",
    // },
  },
}));

export default useStyles;
