import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/joyus logo1.svg";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import "./style.css";
import useStyles from "./style";
import CustomerSupport from "../header/components/customer-support";
import { getPrivacyPolicy } from "../../modules/auth";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import CustomerSupportPhone from "../../../src/assets/images/customer-support-phone.svg";
import landLine from "../../../src/assets/images/landline1(25).svg";
import mobile from "../../../src/assets/images/mobile_icon.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const [showCustomerSupportModal, setShowCustomerSupportModal] =
    useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [terms, setTerms] = useState();
  const style = useStyles();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  useEffect(() => {
    handlePrivacyPolicy();
  }, []);

  const openSocialLink = (url) => {
    window.open(url, "_blank");
  };
  const handlePrivacyPolicy = async () => {
    const data = await getPrivacyPolicy(user?.token);
    setPrivacyPolicy(data?.data?.privyPolicy);
    setTerms(data?.data?.termsAndConditions);
  };

  const handleClickPrivacyPolicy = () => {
    navigate("/privacy-policy", { state: { privacyPolicy } });
  };

  const handleClickTerms = () => {
    navigate("/terms", { state: { terms } });
  };

  return (
    <div className={style.footerContainer}>
      <div className={style.footerContent}>
        <div style={{ flex: 1 }}>
          <div className={style.logo} onClick={() => navigate("/")}>
            <img src={Logo} alt="logo"></img>
          </div>
          <p
            style={{
              color: "#1b1d52",
              fontFamily: "Lato",
              fontWeight: "500",
              opacity: "0.7",
              marginTop: "2px",
              marginLeft: "8px",
            }}
          >
            {/* Mohamed Bin salman Street, Rabya Center, Saudi Arabia */}
            {t("footer.joyusAddress")}
          </p>
        </div>
        <div className={style.line}></div>
        <div className={style.needAnyHelp}>
          <div className={style.needAnyHelpTitle}>
            {" "}
            {t("footer.needAnyHelp")}
          </div>
          <div className={style.contactList}>
            <div className={style.contactMessage}>
              {t("footer.callForHelp")} <br />
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <ReactSVG src={landLine} style={{ marginTop: "2px" }} />{" "}
                <span className={style.contactNumber}>040 23292786</span>
              </div> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <ReactSVG src={mobile} style={{ marginTop: "4px" }} />{" "}
                <span className={style.contactNumber}>+966 595558753</span>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <ReactSVG
                  src={CustomerSupportPhone}
                  style={{ height: "25px", width: "24px", marginTop: "2px" }}
                />{" "}
                <span className={style.contactNumber}>+966 9550007214</span>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <ReactSVG
                  src={CustomerSupportPhone}
                  style={{ height: "25px", width: "24px", marginTop: "2px" }}
                />{" "}
                <span className={style.contactNumber}>+966 9550007248</span>
              </div> */}
            </div>
            <div className={style.contactMessage}>
              {t("footer.mailToSupportTeam")} <br />
              <span className={style.contactNumber}>
                support@joyustrips.com
              </span>
            </div>
          </div>
        </div>
        <div className={style.line}></div>
        <div className={style.ourServices}>
          <div className={style.ourServiceTitle}>{t("footer.ourServices")}</div>
          <div className={style.serviceList}>
            <div
              className={style.service}
              onClick={() => navigate("/about-us")}
            >
              {t("footer.aboutUs")}
            </div>
            <div className={style.service} onClick={() => navigate("/faq")}>
              {t("footer.faq")}
            </div>
            <div
              className={style.service}
              onClick={() => setShowCustomerSupportModal(true)}
            >
              {t("footer.customerSupport")}
            </div>
            <div
              className={style.service}
              onClick={() => handleClickPrivacyPolicy()}
            >
              {t("footer.privacyPolicy")}
            </div>
            <div className={style.service} onClick={() => handleClickTerms()}>
              {t("footer.termsAndConditions")}
            </div>
          </div>
        </div>
        <div className={style.line}></div>
        <div className={style.followUsOn}>
          <div className={style.followUsOnTitle}>{t("footer.followUsOn")}</div>
          <div className={style.socialIcons}>
            <div
              onClick={() =>
                openSocialLink(
                  "https://www.facebook.com/people/Joyustrips/100076126635488/"
                )
              }
              style={{ cursor: "pointer" }}
            >
              <FacebookRoundedIcon className={style.SocialIcon} />
            </div>
            <div
              onClick={() =>
                openSocialLink(
                  "https://twitter.com/i/flow/login?redirect_after_login=%2FJoyustrips"
                )
              }
              style={{ cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="20"
                fill="none"
                viewBox="0 0 512 512"
                id="twitter"
              >
                <g clipPath="url(#clip0_84_15698)">
                  <rect width="25" height="20" fill="#E6E6FA" rx="60"></rect>
                  <path
                    fill="#1b1d52"
                    d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_84_15698">
                    <rect width="512" height="512" fill="#1b1d52"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              onClick={() =>
                openSocialLink("https://www.instagram.com/joyustrips/")
              }
              style={{ cursor: "pointer" }}
            >
              <InstagramIcon className={style.SocialIcon} />
            </div>
            <div
              onClick={() => openSocialLink("https://www.linkedin.com/")}
              style={{ cursor: "pointer" }}
            >
              <LinkedInIcon className={style.SocialIcon} />
            </div>
          </div>
        </div>
      </div>

      <div className={style.copyrightContent}>
        <Divider className={style.divider}></Divider>
        <div className={style.copyrightText}>
          {/* Copyright © 2024 All Rights Reserved */}
          {t("footer.copyRight")}
        </div>
      </div>
      {showCustomerSupportModal && (
        <CustomerSupport
          showCustomerSupportModal={showCustomerSupportModal}
          setShowCustomerSupportModal={setShowCustomerSupportModal}
        />
      )}
    </div>
  );
};

export default Footer;
