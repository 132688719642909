import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    marginTop: "5%",
    marginLeft: "33%",
    width: "480px",
    maxHeight: "600px",
    overflowY: "auto",
    outline: "none",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      marginRight: "5%",
      marginTop: "25%",
      width: "90%",
    },
  },
  modalArabic: {
    marginTop: "5%",
    marginRight: "33%",
    width: "480px",
    maxHeight: "600px",
    overflowY: "auto",
    outline: "none",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      marginRight: "5%",
      marginTop: "25%",
      width: "90%",
    },
  },
  mainContainer: {
    borderRadius: "5px",
    background: " #FFF",
    boxShadow: "0px 2px 24px 0px rgba(0, 0, 0, 0.12)",
    position: "relative",
    outline: "none",
  },
  flightDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  flightDetailsText: {
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  originDestinationContainer: {
    display: "inline-flex",
    gap: "8px",
  },
  origin: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  destination: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },
  totalDurationContainer: {
    display: "inline-flex",
    gap: "3px",
  },
  totalDurationText: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  totalDuration: {
    color: "#525A6A",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  refundableDeptTimeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    textAlign: "end",
  },
  refundableNonRefundableText: {
    color: "#525A6A",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  deptTime: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  originDestinationAndTotalDurationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  originDestAndRefundableDeptTimeContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  flightDetailsAndRefundableDeptTimeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  emptyContainer: {
    width: "auto",
    height: "1px",
    background: "#D0D5DD",
    marginTop: "6px",
  },
  flightDetailContainer: {
    display: "inline-flex",
    gap: "8px",
    width: "100%",
    padding: "18px 18px",
  },
  changeOfPlansAndLayoverContainer: {
    display: "inline-flex",
    justifyItems: "center",
    gap: "4px",
    flexWrap: "wrap",
    alignItems: "center",
    borderRadius: "2px",
    background: "#FFF7EA",
    padding: "8px 14px",
    textAlign: "center",
    width: "100%",
    justifyContent: "center",
  },
  changeOfPlansText: {
    textAlign: "center",
    color: "#FFA400",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  layover: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  deptTimeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    height: "61px",
  },
  deptTimeText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  deptTime2: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  flightDuration: {
    display: "inline-flex",
    padding: "6px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "6px",
    background: "#E6E6FA",
    color: "#1b1d52",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    width: "100%",
  },
  arrivalTimeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    height: "61px",
    marginTop: "12px",
  },
  arrivalTimeText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  arrivalTime: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  deptTimeFlighDurationArrivalTimeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  originAirportName: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  originalTerminal: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  originCityName: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  airportTerminalContainer: {
    display: "flex",
    flexDirection: "column",
    height: "61px",
  },
  airLineName: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
  },
  emptyContainer2: {
    width: "1px",
    height: "auto",
    background: "#D0D5DD",
  },
  airLineCode: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  airLineNameAndCodeContainer: {
    display: "inline-flex",
    gap: "6px",
  },
  flightClassAndCabinClass: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  airLineNamesAndFlighClassContainer: {
    display: "flex",
    flexDirection: "column",
  },
  airLineDetail: {
    display: "inline-flex",
    height: "41px",
    gap: "16px",
  },
  destAirPortName: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  destTerminal: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  destCityName: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  destAirPortNameAndTerminalContainer: {
    display: "flex",
    flexDirection: "column",
    height: "61px",
    marginTop: "12px",
  },
  airportArrivalandDestAndTerminalDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  flightFullDetail: {
    padding: "24px 18px",
    display: "inline-flex",
    gap: "48px",
    width: "100%",
  },
  checkInBaggage: {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato, sans-serif",
    lineHeight: "20px",
  },
  adult: {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Lato, sans-serif",
    lineHeight: "20px",
  },
  checkInBaggageAndAdult: {
    display: "flex",
    flexDirection: "column",
    fontStyle: "normal",
  },
  checkSvgContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
  },
  checkInBaggageAdultContainer: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
  cabinBaggageOuterContainer: {
    padding: "0px 18px 8px 18px",
    display: "inline-flex",
    gap: "38px",
    width: "100%",
  },
  fareDetails: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingBottom: "12px",
    borderBottom: "1px solid #D0D5DD",
  },
  baseFareText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  baseFare: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  baseFareContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  taxesAndFeesText: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  taxesAndFees: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  taxesAndFessContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  vatContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  baseFareAndTaxesFessContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  FairDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "16px",
  },
  fullFairDetailsContainer: {
    position: "absolute",
    marginTop: "-150px",
    background: "#E6E6FA",
    boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.14)",
    width: "100%",
  },
  emptyContainer3: {
    width: "auto",
    height: "1px",
    background: "#D0D5DD",
    marginTop: "6px",
  },
  totalPriceText: {
    color: "#667085",
    fontFamily: "Lato, sans-serif",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  totalPrice: {
    color: "#344054",
    fontFamily: "Lato, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
  },
  totalPriceContainer: {
    display: "flex",
    flexDirection: "column",
  },
  totalPriceOuterContainer: {
    display: "inline-flex",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center",
  },
  continueContainer: {
    padding: "12px 16px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    background: "#1b1d52",
    alignItems: "center",
    cursor: "pointer",
  },
  continue: {
    color: "#FFF",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
  totalPriceAndContinueContainer: {
    display: "inline-flex",
    padding: "16px 18px",
    justifyContent: "space-between",
    width: "100%",
  },
  cabinBaggage: {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Lato, sans-serif",
    lineHeight: "20px",
  },
  cabinBaggageAdult: {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Lato, sans-serif",
    lineHeight: "20px",
  },
  cabinBaggeAndAdultContainer: {
    display: "flex",
    flexDirection: "column",
    fontStyle: "normal",
  },
  cabinBaggeCheckSvgContainer: {
    width: "18px",
    height: "18px",
    backgroundColor: "#EEFFE5",
    borderRadius: "50%",
  },
  cabinBaggageContainer: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
}));

export default useStyles;
