import React, { useState } from "react";
import { HotelSearchFiltersView } from "../../views/HotelSearchFilters";
import closeIcon from "../../../assets/images/x-close.svg";
import { ReactSVG } from "react-svg";

const HotelSearchFiltersContainer = props => {
  const [ accordionsState, setAccordionsState ] = useState({
    rating:false,
    price:false
  });
  const toggleAccordion = ( { name, value} )=>{
    setAccordionsState(prev=>({
      ...prev,
      [name]:value
    }));
  };
  return (
     
    <div>
      <HotelSearchFiltersView 
        {...props} 
        state={{
          accordionsState,
        }}
        handlers={{
          toggleAccordion
        }}
      />
          
    </div>
  );
};

HotelSearchFiltersContainer.propTypes = {};

export default HotelSearchFiltersContainer;
