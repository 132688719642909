import { useState, useEffect } from "react";

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the screen width is less than a certain threshold
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 821); // You can adjust the threshold as needed
    };

    // Initial check when the component mounts
    checkIsMobile();

    // Add an event listener to check when the window is resized
    window.addEventListener("resize", checkIsMobile);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return isMobile;
}

export default useIsMobile;
