import React from "react";
import { ReactSVG } from "react-svg";
import ArrowIcon from "../../../../../assets/images/flight-result-subheader.svg";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import "./style.css";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { handleSortFilter } from "../../../../../utils/slices/flightFiltersSlice";
import { useTranslation } from "react-i18next";

const FlightResultSubHeaderDomestic = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sort } = useSelector((state) => state.flightFilters);
  const { t } = useTranslation();

  return (
    <div className={classes.mainContainer}>
      <p className={classes.airLines}>{t("quickFilters.airlines")}</p>
      <p className={classes.departure}>{t("quickFilters.departure")}</p>
      <p className={classes.duration}>{t("quickFilters.duration")}</p>
      <p className={classes.arrival}>{t("quickFilters.arrival")}</p>
      <div
        className={classes.arivalContainer}
        onClick={() => dispatch(handleSortFilter(!sort))}
      >
        <p className={classes.price}>{t("quickFilters.price")}</p>
        {sort ? (
          <SouthIcon sx={{ color: "#1b1d52", height: "16px" }} />
        ) : (
          <NorthIcon sx={{ color: "#1b1d52", height: "16px" }} />
        )}
      </div>
    </div>
  );
};

export default FlightResultSubHeaderDomestic;
