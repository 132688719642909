import React, { useState } from "react";
import useStyles from "./styles";
import { get } from "lodash";
import moment from "moment";

export const RecentArticleCard = (props) => {
  const [moveBodyUp, setMoveBodyUp] = useState(false);
  const styles = useStyles();

  const image = get(props, "image", "");
  let name = get(props, "name", "");
  if (name?.length > 71) {
    name = name?.slice(0, 70) + "..."
  }
  const description = get(props, "description", "");
  const date = get(props, "date", "");
  const isoDate = new Date(date);
  const month = moment(date).format("MMM");
  const monthArabic = isoDate.toLocaleString("ar", { month: "long" });
  const day = moment(date).format("DD");
  return (
    <div className={styles.articleCard} onMouseEnter={() => setMoveBodyUp(true)} onMouseLeave={() => setMoveBodyUp(false)} onClick={props.onClick}>
      <div className={styles.articleCardImageWrapper}>
        <img className={styles.articleCardImage} src={`${image}`} alt="article_image" />
      </div>
      <div className={styles.articleCardTime}>
        <span className={styles.articleCardTimeMonth}>{
          localStorage.getItem("language") != "AR"
            ? month
            : monthArabic
        }</span>
        <span className={styles.articleCardTimeDay}>{day}</span>
      </div>
      <div className={`${styles.articleCardBody} ${moveBodyUp ? styles.moveBodyUp : ""}`}>
        <h3 className={styles.articleCardBodyTitle}>{name}</h3>
        <p className={styles.articleCardBodyDesc}>{description}</p>
      </div>
    </div>
  );
};

